import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { MoreOptionsMenu } from "common/molecules/more-options-menu";

import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { CreateTagBasedPolicyDrawer } from "modules/create-tag-based-policy-drawer";
import { decodeThrowable } from "modules/facets/hooks/useFacetQueryConnector";
import { FacetState } from "modules/facets/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTagPolicyStore } from "../../store";
import { TagPolicy } from "../../types";
import { PolicyAutomationDrawer } from "../policy-automation-drawer";
import { AssignedNetworksTags } from "../tag-networks/components/assigned-networks-tags";
import {
  RecommendationType,
  TagPolicyRecommendationsDrawer,
} from "../tag-policy-recommendations/TagPolicyRecommendations";
import { AssignedTemplatesTags } from "../tag-templates/components/assigned-templates-tags";
import { AssetVisualizeButton } from "pages/assets/components/asset-visualize-button";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";

export function TagPolicyActions({ policy }: { policy: TagPolicy }) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { isFeatureEnabled: isSegmentsEnabled } = useFeatureFlagControl(
    FEATURES.SEGMENTS
  );

  const navigate = useNavigate();

  const [showPolicyCreator, setShowPolicyCreator] = useState(false);
  const [isRingFence, setRingFence] = useState(false);
  const [policyFacets, setPolicyFacets] = useState<FacetState>(undefined);

  const policyAsFacets = () => {
    let urlParams = new URLSearchParams(policy.criteriaAsParams);
    let filters = decodeURIComponent(urlParams.get("filters") ?? "");

    let facets = decodeThrowable(filters);
    return facets;
  };

  const requestAPIRefresh = useTagPolicyStore(state => state.requestAPIRefresh);

  const [automationDrawerVisibility, setAutomationDrawerVisibility] =
    useState(false);
  const [showAssignedTemplates, setShowAssignedTemplates] = useState(false);
  const [showAssignedNetworks, setShowAssignedNetworks] = useState(false);

  const onCloseAutomationDrawer = (refresh: boolean) => {
    setAutomationDrawerVisibility(false);
    refresh && requestAPIRefresh();
  };

  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const [selectedRecommendationType, setSelectedRecommendationType] = useState<
    RecommendationType | undefined
  >();

  const menuOptions = [];

  if (userPermissions.has("UPDATE_TAGBASEDPOLICY")) {
    menuOptions.push({
      label: "Create Access Policy",
      handler: () => {
        setPolicyFacets(policyAsFacets());
        setShowPolicyCreator(true);
      },
    });
    menuOptions.push({
      label: "Create Ring Fence",
      handler: () => {
        setPolicyFacets(policyAsFacets());
        setRingFence(true);
        setShowPolicyCreator(true);
      },
    });
  }

  menuOptions.push({
    label: "View history",
    handler: () => {
      navigate(
        `/monitoring?tab=audit_logs&filters=resourceid%3D${policy.tagBasedPolicyId}`
      );
    },
  });

  if (userPermissions.has("UPDATE_TAGBASEDPOLICY")) {
    menuOptions.push({
      label: isSegmentsEnabled ? "EditSegment" : "Edit Policy",
      handler: () => {
        navigate(
          isSegmentsEnabled
            ? `/segment/edit/${policy.tagBasedPolicyId}`
            : `/tag-policy/edit-policy/${policy.tagBasedPolicyId}`
        );
      },
    });
  }

  if (userPermissions.has("CREATE_TAGBASEDPOLICY")) {
    menuOptions.push({
      label: isSegmentsEnabled ? "CloneSegment" : "Clone Policy",
      handler: () => {
        navigate(
          isSegmentsEnabled
            ? `/segment/clone/${policy.tagBasedPolicyId}`
            : `/tag-policy/clone-policy/${policy.tagBasedPolicyId}`
        );
      },
    });
  }

  menuOptions.push({
    label: "Port Recommendations",
    handler: () => {
      setSelectedRecommendationType(RecommendationType.Port);
    },
  });

  menuOptions.push({
    label: "Path Recommendations",
    handler: () => {
      setSelectedRecommendationType(RecommendationType.Path);
    },
  });

  if (userPermissions.has("UPDATE_TAGBASEDPOLICY")) {
    menuOptions.push({
      label: "Manage Templates",
      handler: () => setShowAssignedTemplates(true),
    });

    menuOptions.push({
      label: "Manage Named Networks",
      handler: () => setShowAssignedNetworks(true),
    });
  }

  if (userPermissions.has("DELETE_TAGBASEDPOLICY")) {
    menuOptions.push({
      label: isSegmentsEnabled ? "DeleteSegment" : "Delete Policy",
      handler: () => {
        setShowDeleteDialog(true);
      },
    });
  }

  if (
    userPermissions.has("UPDATE_POLICY_AUTOMATION") &&
    policy.policyAutomationConfigurable
  ) {
    menuOptions.unshift({
      label: "Configure policy automation",
      handler: () => {
        setAutomationDrawerVisibility(true);
      },
    });
  }

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
        <AssetVisualizeButton
          useAsIcon={true}
          paramsURL={policy.criteriaAsParams}
        />
        <MoreOptionsMenu menuOptions={menuOptions} />
      </Stack>
      {showDeleteDialog && (
        <DeleteTagPolicyDialog
          policy={policy}
          onClose={() => {
            setShowDeleteDialog(false);
          }}
        />
      )}

      {showPolicyCreator && (
        <CreateTagBasedPolicyDrawer
          isOpen={showPolicyCreator}
          defaultSourceFacets={policyFacets}
          defaultDestinationFacets={isRingFence ? policyFacets : undefined}
          templateRules={[]}
          isRingFence={isRingFence}
          defaultSourceTagBasedPolicyId={policy.tagBasedPolicyId}
          onClose={() => {
            requestAPIRefresh();
            setRingFence(false);
            setShowPolicyCreator(false);
          }}
        />
      )}

      {selectedRecommendationType !== undefined && Boolean(policy.criteria) && (
        <TagPolicyRecommendationsDrawer
          criteria={policy.criteria!}
          onClose={() => {
            requestAPIRefresh();
            setSelectedRecommendationType(undefined);
          }}
          selectedTab={selectedRecommendationType}
          policyId={policy.tagBasedPolicyId}
        />
      )}

      {automationDrawerVisibility && (
        <PolicyAutomationDrawer
          isOpen={automationDrawerVisibility}
          onClose={onCloseAutomationDrawer}
          id={policy.tagBasedPolicyId}
          criteria={policy.criteria}
          isZeroTrustAutomationEditable={policy?.policyAutomationConfigurable}
        />
      )}

      {showAssignedTemplates && (
        <AssignedTemplatesTags
          criteria={policy?.criteria}
          multiselect={true}
          policyId={policy?.tagBasedPolicyId}
          open={showAssignedTemplates}
          onClose={() => setShowAssignedTemplates(false)}
        />
      )}

      {showAssignedNetworks && (
        <AssignedNetworksTags
          criteria={policy?.criteria}
          multiselect={true}
          policyId={policy?.tagBasedPolicyId}
          open={showAssignedNetworks}
          onClose={() => setShowAssignedNetworks(false)}
        />
      )}
    </>
  );
}

function useDeletePolicy(tagBasedPolicyId: string) {
  const path = `tagbasedpolicies/${tagBasedPolicyId}`;
  return useMutation<any, Error>(["tagbasedpolicies", path, "delete"]);
}

function DeleteTagPolicyDialog({
  policy,
  onClose,
}: {
  policy: TagPolicy;
  onClose: VoidFunction;
}) {
  const deleteMutation = useDeletePolicy(policy?.tagBasedPolicyId);
  const notify = useEventSubscriptionStore(state => state.notify);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const refreshRequest = useTagPolicyStore(state => state.requestAPIRefresh);
  const { isFeatureEnabled: isSegmentsEnabled } = useFeatureFlagControl(
    FEATURES.SEGMENTS
  );

  const closeRef = useRef(onClose);
  useEffect(() => {
    if (deleteMutation.isSuccess) {
      notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
        label: isSegmentsEnabled
          ? "DeleteSegmentRequestSubmittedSuccessfully"
          : "TagBasedPolicyDeletedSuccessfully",
      });
      closeRef.current();
      refreshRequest();
    }
  }, [
    deleteMutation.isSuccess,
    notify,
    refreshRequest,
    setSnackbar,
    isSegmentsEnabled,
  ]);

  return (
    <Dialog
      open={true}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="delete-tag-policy"
    >
      <DialogTitle id="delete-tag-policy">
        {window.getCTTranslatedText(
          isSegmentsEnabled ? "DeleteSegment" : "Delete Tag Based Policy"
        )}
      </DialogTitle>
      <DialogContent>
        {window.getCTTranslatedText("Are you sure you want to delete?", {
          type: window.getCTTranslatedText(
            isSegmentsEnabled ? "Segment" : "tagBasedPolicy"
          ),
        })}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            onClose();
          }}
        >
          {window.getCTTranslatedText("Cancel")}
        </Button>
        <LoadingButton
          loading={deleteMutation.isLoading}
          variant="contained"
          color="error"
          onClick={() => {
            deleteMutation.mutate();
          }}
        >
          {window.getCTTranslatedText("Delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
