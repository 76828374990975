import ClearIcon from "@mui/icons-material/Clear";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { InputBaseCompact } from "common/atoms/input-base";
import { SearchProps } from "../../../search/types";
import { SearchContainerCompact } from "./SearchContainerCompact";

export interface SuggestionSearchProps extends SearchProps {
  suggestions?: Array<string>;
}

export function FacetSuggestions({
  search,
  setSearch,
  readOnly,
  suggestions = [],
  placeholder = "Search...",
}: SuggestionSearchProps) {
  const theme = useTheme();
  return (
    <SearchContainerCompact>
      <Autocomplete
        size="small"
        freeSolo
        onChange={(e, value) => setSearch(value || "")}
        value={search}
        options={suggestions}
        disableClearable
        renderInput={params => (
          <InputBaseCompact
            {...params.InputProps}
            size="small"
            onChange={e => setSearch(e.target.value)}
            readOnly={readOnly || false}
            placeholder={placeholder}
            inputProps={{
              "aria-label": "search",
              ...params.inputProps,
            }}
            startAdornment={
              <InputAdornment position="end">
                <ViewStreamIcon
                  sx={{
                    fontSize: 12,
                    color: theme.palette.grey[600],
                    pointerEvents: "none",
                  }}
                />
              </InputAdornment>
            }
            endAdornment={
              readOnly ? undefined : (
                <InputAdornment position="end" sx={{ paddingRight: "6px" }}>
                  <IconButton
                    size="small"
                    color="inherit"
                    aria-label="clear search"
                    onClick={() => {
                      setSearch("");
                      // @ts-ignore
                      if (params.inputProps.ref.current) {
                        // @ts-ignore
                        params.inputProps.ref.current.value = "";
                      }
                    }}
                    onMouseDown={() => {}}
                    edge="end"
                  >
                    {(search?.length > 0 ||
                      // @ts-ignore
                      params.inputProps.ref.current?.value?.length > 0) && (
                      <ClearIcon fontSize="small" color="inherit" />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        )}
      />
    </SearchContainerCompact>
  );
}
