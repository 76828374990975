import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { UseMutationResult } from "@tanstack/react-query";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { FacetOpenCloseControllerType } from "common/atoms/facet-open-close-controller/FacetOpenCloseController";
import { getSelectedTimeFacetNameFromScope } from "common/atoms/facet-open-close-controller/helpers";
import { useCommonStore } from "common/store";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { CustomCommonStoreType } from "common/types/types";
import { getRelativeLastObserved } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { Annotation } from "modules/annotation";
import { useCore } from "modules/core";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { FacetControllerGroup } from "modules/facets";
import { mergeFacetStates } from "modules/facets/FacetUtils";
import { FacetOptionState, FacetState, Operator } from "modules/facets/types";
import { Scope } from "modules/scope-metadata/types";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import { useUpdateTotalCount } from "pages/paths/components/path-data-grid/hooks";
import { StatusOptionsText as PathStatusOptionsText } from "pages/paths/constants";
import { PathFilters, PathStatus } from "pages/paths/types";
import { PortColumnConfig } from "pages/ports/components/port-data-grid/constants";
import { usePortsAPI } from "pages/ports/components/port-data-grid/hooks";
import { usePortsFacetsOrder } from "pages/ports/constants";
import { usePortStore } from "pages/ports/store";
import { PortStoreType } from "pages/ports/store/types";
import { Port, PortStatus } from "pages/ports/types";
import prettyBytes from "pretty-bytes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PortStatusToolbar } from "./components/port-status-toolbar";
import { PATHS_REVIEW_DATA_GRID_ID, getPortReviewColumns } from "./constants";

interface PortsReviewProps {
  preSelectedPorts?: GridRowId[] | undefined;
  setPortSelection?: (selectionModel: GridRowId[]) => void;
  portFilter?: PathFilters | string | undefined;
  updatePortstatus?: ((status: PortStatus | undefined) => void) | undefined;
  useFacetStore?: CustomCommonStoreType;
  useStore?: PortStoreType;
  includeCommonFacet?: boolean;
  preSelectedPortStatus?: PortStatus | undefined;
  updatePathstatus?: ((status: PathStatus | undefined) => void) | undefined;
  setComment?: React.Dispatch<React.SetStateAction<string | undefined>>;
  comment?: string;
  api?: () => UseMutationResult<any, Error, any, unknown>;
  mode: AssetReviewModeType;
  readOnlyDimensions?: string[];
  facetState?: FacetState;
}

export const PortsReview = ({
  preSelectedPorts,
  setPortSelection,
  portFilter = "",
  updatePortstatus,
  useFacetStore = useCommonStore,
  useStore = usePortStore,
  includeCommonFacet = false,
  preSelectedPortStatus,
  updatePathstatus,
  setComment,
  comment,
  api,
  mode,
  readOnlyDimensions,
  facetState,
}: PortsReviewProps) => {
  const selectedTime = useUserPreferencesStore(state => state.timeFilter);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const metadata = useFacetStore(state => state.metadata);
  const apiCreator = api ?? usePortsAPI;
  const portsAPI = apiCreator;
  const apiRefreshPort = useStore(state => state.apiRefreshRequest);
  const requestPortAPIRefresh = useStore(state => state.requestAPIRefresh);
  const [selection, setSelection] = useState<Array<GridRowId>>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [selectedPortStatus, setSelectedPortStatus] = useState<
    PortStatus | undefined
  >(undefined);
  const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);

  const commonFacetState = useCommonStore(state => state.facets);
  const setFacets = useFacetStore(state => state.setFacets);

  let additionalCriteria;
  let sourceCriteria;
  let destinationCriteria;

  if (typeof portFilter === "string") {
    additionalCriteria = portFilter;
  } else {
    additionalCriteria = `${portFilter?.criteria}` || "";
    sourceCriteria = portFilter?.srcCriteria || "";
    destinationCriteria = portFilter?.dstCriteria || "";
  }

  const getPortFacet = useCallback((time: string) => {
    const facet: FacetState = new Map();
    const options: FacetOptionState = new Map();
    options.set(time, { isSelected: true, operator: Operator.EQUAL });
    facet.set(getSelectedTimeFacetNameFromScope(Scope.Port), options);
    return facet;
  }, []);

  const coreResponse = useCore<Port>({
    useStore: useStore,
    facetGroupInfo: usePortsFacetsOrder(),
    scope: Scope.Port,
    dataMapper: port => {
      port.bandwidthInBytes = prettyBytes(Number(port.bandwidthInBytes));
      port.listenPortLastObserved = getRelativeLastObserved(
        port.listenPortLastObserved
      );
      return port;
    },
    defaultSortOrder: [{ field: "listenportlastobserved", order: "desc" }],
    useApi: portsAPI,
    pageSize: 100,
    skipUseFacetQueryConnector: true,
    additionalCriteria,
    sourceCriteria,
    destinationCriteria,
    useFacetStore: useFacetStore,
  });

  const updateExternalCriteria = useFacetStore(
    state => state.setExternalCriteria
  );
  useEffect(() => {
    if (portFilter && typeof portFilter === "string") {
      updateExternalCriteria(portFilter);
    }
  }, [portFilter, updateExternalCriteria]);

  useEffect(() => {
    let updatedFacetState = facetState;
    if (
      mode !== AssetReviewModeType.FireWallReview &&
      mode !== AssetReviewModeType.ViewFirewallPendingChanges
    ) {
      updatedFacetState = mergeFacetStates(
        updatedFacetState,
        getPortFacet(selectedTime)
      );
    }
    if (includeCommonFacet && commonFacetState) {
      setFacets(mergeFacetStates(commonFacetState, updatedFacetState));
    } else {
      setFacets(updatedFacetState);
    }
  }, [
    mode,
    selectedTime,
    getPortFacet,
    setFacets,
    includeCommonFacet,
    commonFacetState,
    facetState,
  ]);

  useEffect(() => {
    setSelection([]);
  }, [apiRefreshPort]);

  useEffect(() => {
    if (
      preSelectedPorts &&
      preSelectedPorts.length > 0 &&
      coreResponse?.rows &&
      initialLoad
    ) {
      setSelection(preSelectedPorts);
      if (setPortSelection) {
        setPortSelection(preSelectedPorts);
      }
      setInitialLoad(true);
    }
  }, [preSelectedPorts, setPortSelection, coreResponse?.rows, initialLoad]);

  const updateSelections = (selectedPortIds: GridRowId[]) => {
    if (selectedPortIds && selectedPortIds?.length > 0) {
      setSelection(selectedPortIds);
      if (setPortSelection) {
        setPortSelection(selectedPortIds);
      }
    } else {
      setSelection([]);
      if (setPortSelection) {
        setPortSelection([]);
      }
    }
  };

  const onSelectPortStatus = (status: PortStatus | undefined) => {
    setSelectedPortStatus(status);
    if (updatePortstatus) {
      updatePortstatus(status);
    }
  };

  const updatePathStatus = (status: PathStatus | undefined) => {
    // setSelectedPathStatus(status);
    if (updatePathstatus) {
      updatePathstatus(status);
    }
  };

  const handleAddToTemplateDialogOpen = (dialogVisibility: boolean) => {
    setShowAddToTemplateDialog(dialogVisibility);
  };

  const handleClose = () => {
    setShowAddToTemplateDialog(false);
    requestPortAPIRefresh();
  };

  const selectedData: Array<Port> | undefined = useMemo(() => {
    return (coreResponse.rows || [])?.filter((row: Port) => {
      return selection.indexOf(row.lpId) !== -1;
    });
  }, [selection, coreResponse.rows]);

  const showPortStatusOption: boolean = useMemo(() => {
    return (selectedData || []).some((row: Port) => {
      return (
        row?.listenPortReviewed !== PortStatus.AllowAny &&
        row?.listenPortReviewed !== PortStatus.AllowIntranet
      );
    });
  }, [selectedData]);

  const selectedRawData: Array<Port> | undefined = useMemo(() => {
    return (coreResponse?.rawData ?? [])?.filter((row: Port) => {
      return selection.indexOf(row.lpId) !== -1;
    });
  }, [selection, coreResponse?.rawData]);

  useUpdateTotalCount({
    isLoading: coreResponse.mutation.isLoading,
    maxRowCount: coreResponse.maxRowCount,
    originalRowCount: coreResponse.rowCount,
    id: PATHS_REVIEW_DATA_GRID_ID,
  });

  const rowCount =
    coreResponse.rowCount > 0 &&
    coreResponse.rowCount === coreResponse.maxRowCount
      ? coreResponse.rowCount - 1
      : coreResponse.rowCount;

  const columns = useMemo(
    () => getPortReviewColumns({ useFacetStore }),
    [useFacetStore]
  );

  return (
    <Stack
      alignItems="flex-start"
      spacing={0}
      direction={"column"}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction="row" sx={{ width: "100%" }}>
        <Stack
          justifyItems={"flex-start"}
          justifyContent={"flex-start"}
          alignItems="flex-start"
          sx={{ flex: 1, mt: 1 }}
        >
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
            showFacetToggle={
              mode !== AssetReviewModeType.ViewFirewallPendingChanges &&
              mode !== AssetReviewModeType.EdgeReview
            }
            controllerType={FacetOpenCloseControllerType.MENU}
            useFacetStore={useFacetStore}
            disableSavedQuery={true}
            disableSearchChip={true}
            readOnlyDimensions={readOnlyDimensions}
          >
            <FacetControllerGroup
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
              useFacetStore={useFacetStore}
              readOnlyDimensions={readOnlyDimensions}
            />
          </FacetOpenCloseController>
        </Stack>

        <Stack
          justifyContent="flex-end"
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {userPermissions.has("UPDATE_PORT") && (
            <PortStatusToolbar
              updatePortstatus={onSelectPortStatus}
              useStore={useStore}
              preSelectedPortStatus={preSelectedPortStatus}
            />
          )}
        </Stack>

        {userPermissions.has("UPDATE_TEMPLATE") && (
          <Stack
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            ml={2}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleAddToTemplateDialogOpen(true)}
              disabled={selectedData?.length === 0}
            >
              {window.getCTTranslatedText("Add to template")}
            </Button>
          </Stack>
        )}
      </Stack>
      {userPermissions.has("UPDATE_PORT") &&
        selectedPortStatus === PortStatus.PathRestricted && (
          <PathStatusOptions updatePathStatus={updatePathStatus} />
        )}
      <Paper
        sx={{
          width: "100%",
          pt: 3,
          flex: 1,
          overflow: "hidden",
        }}
        id={PATHS_REVIEW_DATA_GRID_ID}
      >
        <DataGrid
          defaultPinnedColumns={PortColumnConfig.PinnedColumns}
          checkboxSelection={userPermissions.has("UPDATE_PORT")}
          rowSelectionModel={selection}
          onRowSelectionModelChange={selectionModel => {
            updateSelections(selectionModel);
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "listenportlastobserved", sort: "desc" }],
            },
          }}
          exportRowCount={coreResponse.rowCount}
          rowHeight={64}
          columns={columns}
          getRowId={({ lpId }: Port) => lpId}
          paginationMode="server"
          sortingMode="server"
          pagination
          isLoading={
            coreResponse?.mutation?.isLoading || !coreResponse.rows || false
          }
          rows={coreResponse.rows || []}
          rowCount={rowCount}
          mutation={coreResponse.mutation}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={sortModel => {
            if (sortModel?.length > 0) {
              sortModel = sortModel.map(item => {
                item.field = item.field.toLowerCase();
                return item;
              });
              coreResponse.onSortChange(sortModel);
            }
          }}
          metadata={metadata}
          triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
          getExportStatus={coreResponse?.getExportStatus}
          getUrlToDownload={coreResponse?.getUrlToDownload}
          resetDownloadUrl={coreResponse?.resetDownloadUrl}
          rawData={coreResponse?.rawData}
          selectedRawData={selectedRawData}
        />
      </Paper>

      <AddToTemplateDrawer
        isOpen={showAddToTemplateDialog}
        page={"ports"}
        title="Add to Template"
        rules={selectedData}
        showPortStatusOption={showPortStatusOption}
        onCancel={handleClose}
        onConfirm={handleClose}
        btnTitle={"add"}
        actionType={TemplateActionType.add}
      />
      {selectedData.length > 0 && (
        <Annotation setComment={setComment} comment={comment} />
      )}
    </Stack>
  );
};

interface PathStatusOptionsProps {
  updatePathStatus: (status: PathStatus) => void;
}

function PathStatusOptions({ updatePathStatus }: PathStatusOptionsProps) {
  const [option, setOption] = useState(PathStatus.Allow);

  const changeStatus = (status: PathStatus) => {
    setOption(status);
    updatePathStatus(status);
  };

  return (
    <Stack>
      <FormControl
        sx={{
          my: 2,
          width: "100%",
        }}
      >
        <Typography variant="subtitle2">
          {window.getCTTranslatedText("Set all matching paths status")}
        </Typography>

        <RadioGroup
          sx={{ flexDirection: "row" }}
          value={option}
          onChange={(_, value) => changeStatus(value as PathStatus)}
        >
          <FormControlLabel
            value={PathStatus.Allow}
            control={<Radio size="small" />}
            label={window.getCTTranslatedText(
              PathStatusOptionsText[PathStatus.Allow]
            )}
          />
          <FormControlLabel
            value={PathStatus.Deny}
            control={<Radio size="small" />}
            label={window.getCTTranslatedText(
              PathStatusOptionsText[PathStatus.Deny]
            )}
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
}
