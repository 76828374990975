import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { MetaDataItemWrapper } from "pages/asset/components/metadata-item-wrapper";
import { AssetNameInternal } from "pages/assets/components/asset-name-internal";
import { AssetSyncStatus } from "pages/assets/components/asset-sync-status";
import { AssetMetaDataEditViewProps } from "pages/assets/types";

export const AssetMetaData = ({
  asset,
  handleAllowEdit,
  handleViewMore,
}: AssetMetaDataEditViewProps) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const isSyncReq: boolean = Boolean(
    asset?.pendingAttackSurfaceChanges || asset?.pendingBlastRadiusChanges
  );
  const getUserEmail = () => {
    const signedInUser = asset?.users?.find(user => user.signedin);
    const emailOfSignedInUser = signedInUser ? signedInUser.email : "";
    return emailOfSignedInUser;
  };

  return (
    <Stack
      direction={"column"}
      spacing={0}
      borderRadius={1}
      sx={{
        border: theme =>
          theme.palette.mode === "dark"
            ? "1px solid rgba(255, 255, 255, 0.12)"
            : "1px solid #d9d9d9",
      }}
    >
      <Stack
        alignContent={"center"}
        justifyItems={"center"}
        justifyContent={"center"}
        sx={{
          px: 3,
          py: 2,
          width: "100%",
        }}
        direction="row"
        spacing={2}
      >
        <Stack
          alignContent={"center"}
          justifyItems={"center"}
          justifyContent={"center"}
          sx={{ overflow: "hidden" }}
        >
          <Typography variant={"h6"} noWrap>
            {AssetNameInternal({ asset })}
          </Typography>
          <Stack direction={"row"}>
            <Typography
              variant="caption"
              sx={{
                color: theme =>
                  theme.palette.mode === "dark"
                    ? "rgba(255, 255, 255, 0.6)"
                    : "rgba(0, 0, 0, 0.6)",
                textTransform: "capitalize",
                mr: 4,
              }}
            >
              {window.getCTTranslatedText(asset?.type || " ")}
            </Typography>

            {asset?.users && asset?.users?.length >= 1 && (
              <Typography
                variant="caption"
                sx={{
                  color: theme =>
                    theme.palette.mode === "dark"
                      ? "rgba(255, 255, 255, 0.6)"
                      : "rgba(0, 0, 0, 0.6)",
                }}
              >
                {`${getUserEmail()}`}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack flex={1} alignItems="flex-end" justifyItems={"flex-end"}>
          {userPermissions.has("UPDATE_ASSET") && (
            <>
              <Stack
                alignContent={"center"}
                justifyItems={"center"}
                justifyContent={"center"}
                direction="row"
                spacing={2}
              >
                {isSyncReq && (
                  <Stack alignItems="flex-end" flex={1} pt={0.5}>
                    <AssetSyncStatus
                      asset={asset!}
                      viewOnly={true}
                      iconOnly={true}
                    />
                  </Stack>
                )}
                {userPermissions.has("UPDATE_ASSET_ZEROTRUST") && (
                  <IconButton
                    size="small"
                    aria-label="Edit asset details"
                    onClick={() => handleAllowEdit(true)}
                  >
                    <ModeEditOutlineOutlinedIcon fontSize="medium" />
                  </IconButton>
                )}
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <Divider />
      <Stack direction={"row"} sx={{ p: 3 }}>
        <MetaDataItemWrapper asset={asset} />
      </Stack>
      <Stack direction={"row"} sx={{ p: 3, pt: 0, justifyContent: "flex-end" }}>
        <Button onClick={() => handleViewMore(true)}>View More...</Button>
      </Stack>
    </Stack>
  );
};
