import { FacetGroupInfo } from "modules/core/types";
import { COMMON_FACETS } from "modules/facets/constants";
import { FacetGroups } from "../../../../modules/facets/types";
export const USERGROUP_FACET_ORDER: FacetGroupInfo = {
  ...COMMON_FACETS,
  groupname: FacetGroups.Groups,
  usergroupstatus: FacetGroups.Groups,
  active: FacetGroups.Users,
  useremail: FacetGroups.Users,
  displayname: FacetGroups.Users,
  assetname: FacetGroups.Assets,
  idpname: FacetGroups.IdP,
};
