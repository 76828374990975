import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { DialogTitle, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface ConfirmationDialogProps {
  info: string;
  subInfo?: string;
  title: string;
  handleClose: () => void;
  onConfirmation: () => void;
  open: boolean;
  isLoading: boolean;
  primaryButtonText?: string;
}

export default function ConfirmationDialog({
  info,
  title,
  handleClose,
  open,
  onConfirmation,
  isLoading,
  primaryButtonText,
  subInfo,
}: ConfirmationDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle>{window.getCTTranslatedText(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {window.getCTTranslatedText(info)}
          {subInfo && (
            <Typography variant="body1" mt={5}>
              {window.getCTTranslatedText(subInfo)}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={handleClose}>
          {window.getCTTranslatedText("Cancel")}
        </Button>
        <LoadingButton
          variant="text"
          color="error"
          autoFocus
          type="submit"
          onClick={onConfirmation}
          loading={isLoading}
        >
          {window.getCTTranslatedText(primaryButtonText ?? "Deactivate")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
