import { useQuery } from "@tanstack/react-query";

export interface AgentVersion {
  targetPlatform: string;
  architecture: string;
  version: string;
  agentType: string;
  recommended: boolean;
  downloadLink: string;
  downloadLinkTimeToLiveSeconds: number;
  fileName: string;
}

export interface AgentVersions {
  versions: AgentVersion[];
}

export function useVersionAPI() {
  return useQuery<AgentVersions, Error>(["versions", "agents/versions"]);
}
