import { Button, IconButton, Tooltip } from "@mui/material";
import { OVERWRITE_FACETS } from "modules/facets/constants";
import { useNavigate } from "react-router-dom";
import VisualizeIcon from "@mui/icons-material/Visibility";

interface VisualizeButtonProps {
  useAsIcon?: boolean;
  paramsURL?: string;
}

export const AssetVisualizeButton = ({
  useAsIcon = false,
  paramsURL,
}: VisualizeButtonProps) => {
  const navigate = useNavigate();

  const navigateToVisualizer = () => {
    let urlParams = new URLSearchParams(
      paramsURL ? paramsURL : window.location.search
    );
    let criteria = urlParams.get("filters") || "";
    let search = urlParams.get("search") || "";

    let newURLParams = new URLSearchParams();
    if (search) {
      newURLParams.set("search", search);
    }

    if (criteria) {
      newURLParams.set("filters", criteria);
    }

    const path = `/visualizer?${newURLParams.toString()}${
      criteria || search ? "&" + OVERWRITE_FACETS : ""
    }`;
    navigate(path);
  };

  if (useAsIcon) {
    return (
      <IconButton aria-label="Visualize" onClick={() => navigateToVisualizer()}>
        <Tooltip title={window.getCTTranslatedText("Visualize")}>
          <VisualizeIcon color={"action"} />
        </Tooltip>
      </IconButton>
    );
  }

  return (
    <Button
      color="primary"
      variant="contained"
      size="medium"
      onClick={() => navigateToVisualizer()}
      sx={{ ml: 2 }}
    >
      {window.getCTTranslatedText("Visualize")}
    </Button>
  );
};
