import { create } from "zustand";
import { SearchStore } from "./types";

export const useSearchStore = create<SearchStore>()(set => ({
  search: new URLSearchParams(window.location.search).get("search") || "",
  placeholder: "",
  setPlaceholder: (placeholderText: string) =>
    set({ placeholder: placeholderText }),
  setSearch: (searchText: string) => {
    set({ search: searchText });
  },
}));
