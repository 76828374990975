import { FacetUtils } from "modules/facets";
import { FacetState } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useAggregateAPI } from "pages/tags/hooks/useAggregateAPI";
import { PortFormInt } from "pages/templates/components/template-form-drawer/components/template-form/types";
import { useEffect, useMemo } from "react";

const EMPTY: PortAgg[] = [];
export interface PortAgg {
  port: string;
  protocol: string;
  assetWithObservedActivityTrafficWithLastMonth: number;
  usedByAssets: number;
  coverage: number;
}

const HAS_BW_CRITERIA = "('listenportlastobserved' BETWEEN 0 AND 720)";

function getId(agg: PortAgg) {
  return `${agg.protocol}-${agg.port}`;
}

export function usePortRecommendationsWithDataVolumeGroup({
  criteria,
  sourceCriteriaFacets,
  destinationCriteriaFacets,
}: {
  criteria?: string;
  sourceCriteriaFacets?: FacetState;
  destinationCriteriaFacets?: FacetState;
}) {
  const { aggregates, isLoading, total } = usePortRecommendations({
    criteria,
    sourceCriteriaFacets,
    destinationCriteriaFacets,
  });
  let bwCriteria = criteria;
  if (criteria === "*") {
    bwCriteria = HAS_BW_CRITERIA;
  } else {
    bwCriteria = `${bwCriteria} AND ${HAS_BW_CRITERIA}`;
  }
  const { aggregates: withBwAggs, isLoading: isLoadingWithBw } =
    usePortRecommendations({
      criteria: bwCriteria,
      sourceCriteriaFacets,
      destinationCriteriaFacets,
    });

  const bwStats = useMemo(() => {
    if (!withBwAggs) {
      return undefined;
    }
    let map: { [key: string]: number } = {};
    withBwAggs.forEach(agg => {
      map[getId(agg)] = agg.usedByAssets;
    });
    return map;
  }, [withBwAggs]);

  const splitAggregates = useMemo(() => {
    if (!bwStats && !isLoadingWithBw) {
      return EMPTY;
    }
    if (!aggregates?.length && !isLoading) {
      return EMPTY;
    }

    aggregates.forEach(agg => {
      agg.assetWithObservedActivityTrafficWithLastMonth =
        bwStats?.[getId(agg)] ?? 0;
    });
    return aggregates;
  }, [bwStats, isLoadingWithBw, aggregates, isLoading]);

  return {
    aggregates: splitAggregates,
    total,
    isLoading: isLoading || isLoadingWithBw,
  };
}

export function usePortRecommendations({
  criteria,
  sourceCriteriaFacets,
  destinationCriteriaFacets,
}: {
  criteria?: string;
  sourceCriteriaFacets?: FacetState;
  destinationCriteriaFacets?: FacetState;
}) {
  const { mutate, data, isLoading } = useAggregateAPI();

  const { data: metadata, isLoading: metadataLoading } = useScopeMetadata({
    scope: Scope.Port,
  });

  const { sourceCriteria, destinationCriteria } = useMemo(() => {
    return {
      sourceCriteria:
        FacetUtils.getServerQueryLanguageFromFacets(
          sourceCriteriaFacets,
          metadata
        ) || undefined,
      destinationCriteria:
        FacetUtils.getServerQueryLanguageFromFacets(
          destinationCriteriaFacets,
          metadata
        ) || undefined,
    };
  }, [destinationCriteriaFacets, metadata, sourceCriteriaFacets]);

  useEffect(() => {
    let hasSourceDest = Boolean(
      sourceCriteria &&
        sourceCriteria !== "*" &&
        destinationCriteria &&
        destinationCriteria !== "*"
    );

    if (!hasSourceDest && !criteria) {
      return;
    }

    mutate({
      criteria: criteria ?? "*",
      sourceCriteria,
      destinationCriteria,
      scope: Scope.Port,
      groupBy: ["listenportprotocol", "listenport"],
      statistics: [
        // "sum(bandwidthinbytes)",
        "distinctcount(assetname)",
      ],
    });
  }, [criteria, destinationCriteria, mutate, sourceCriteria]);

  let { aggregates, total } = useMemo<{
    aggregates: PortAgg[];
    total: number;
  }>(() => {
    if (!data) {
      return { aggregates: EMPTY, total: 0 };
    }
    let agg: PortAgg[] = [];
    let protocols = Object.keys(data?.items ?? {});
    let total = 0;
    protocols.forEach(protocol => {
      let ports = Object.keys(data?.items?.[protocol]?.listenport ?? {});
      ports.forEach(port => {
        let stats = data?.items?.[protocol]?.listenport?.[port]?.statistics;
        let assetWithObservedActivityTrafficWithLastMonth =
          stats?.bandwidthinbytessum ?? 0;
        let usedByAssets = stats?.assetnamedistinctcount ?? 0;

        total = Math.max(total, usedByAssets);
        agg.push({
          port,
          protocol,
          assetWithObservedActivityTrafficWithLastMonth,
          usedByAssets,
          coverage: 0,
        });
      });
    });
    agg.sort((a, b) => b.coverage - a.coverage);
    agg.forEach(aggValue => {
      if (aggValue.usedByAssets && total) {
        aggValue.coverage = Number((aggValue.usedByAssets / total).toFixed(2));
      }
    });
    return { aggregates: agg, total };
  }, [data]);

  let portRules = useMemo<PortFormInt[]>(() => {
    return aggregates.map(agg => {
      return {
        listenPort: agg.port,
        listenPortProtocol: agg.protocol,
        listenPortReviewed: "",
        listenProcessNames: "",
      };
    });
  }, [aggregates]);

  return {
    portRules,
    total,
    aggregates,
    isLoading: isLoading || metadataLoading,
  };
}
