import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { ArcSightIcon } from "assets/svgs/ArcSightIcon";
import { CTIconSmall } from "assets/svgs/CTIconSmall";
import { ClarotyIcon } from "assets/svgs/ClarotyIcon";
import { CrowdStrikeIcon } from "assets/svgs/CrowdStrikeIcon";
import { MSTeamsIcon } from "assets/svgs/MSTeamsIcon";
import { MedigateIcon } from "assets/svgs/MedigateIcon";
import { NessusIcon } from "assets/svgs/NessusIcon";
import { OAuth2IconSmall } from "assets/svgs/OauthIconSmall";
import { QradarIcon } from "assets/svgs/QradarIcon";
import { Rapid7IconSmall } from "assets/svgs/Rapid7Small";
import { SamlIconSmall } from "assets/svgs/SamlIconSmall";
import { SCIMIconSmall } from "assets/svgs/ScimIconSmall";
import { ServiceNowIcon } from "assets/svgs/ServiceNowIcon";
import { SplunkIcon } from "assets/svgs/SplunkIcon";
import { VsphereIcon } from "assets/svgs/VsphereIcon";
import { OthersIcon } from "assets/svgs/othersIcon";
import ConfirmationDialog from "common/molecules/confirmation-dialog/ConfirmationDialog";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
  INTEGRATIONS,
  INTEGRATION_PROVIDERS,
  IntegrationConfigType,
  getIntegrationEnum,
} from "pages/Integration/constants";
import { useIntegrationActionConnection } from "pages/Integration/hooks";
import { useDeleteAuthConfigAPI } from "pages/Integration/tabs/authentication/hooks";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { useState } from "react";

interface IntegrationCardProps {
  item: IntegrationConfigType;
  onActivateClick: (data: IntegrationConfigType) => void;
  onDeactivateClick: (data: IntegrationConfigType) => void;
  onRefreshData: () => void;
  isLoading: boolean | undefined;
}
const ButtonCardText = {
  ACTIVATE: "Activate",
  INSTALL_GUIDE: "InstallGuide",
};

export function IntegrationCard({
  item,
  onActivateClick,
  onDeactivateClick,
  onRefreshData,
  isLoading,
}: IntegrationCardProps) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const theme = useTheme();
  const renderIcon = (providerIcon: string) => {
    switch (providerIcon) {
      case INTEGRATION_PROVIDERS.CLAROTY:
        return <ClarotyIcon />;
      case INTEGRATION_PROVIDERS.CROWDSTRIKE:
        return <CrowdStrikeIcon />;
      case INTEGRATION_PROVIDERS.XCLOUD:
        return <CTIconSmall />;
      case INTEGRATION_PROVIDERS.MEDIGATE:
        return <MedigateIcon />;
      case INTEGRATION_PROVIDERS.NESSUS:
        return <NessusIcon />;
      case INTEGRATION_PROVIDERS.OAUTH:
        return <OAuth2IconSmall />;
      case INTEGRATION_PROVIDERS.RAPID7:
        return <Rapid7IconSmall />;
      case INTEGRATION_PROVIDERS.SAML:
        return <SamlIconSmall />;
      case INTEGRATION_PROVIDERS.SCIM:
        return <SCIMIconSmall color={theme.palette.text.primary} />;
      case INTEGRATION_PROVIDERS.SERVICENOW:
        return <ServiceNowIcon />;
      case INTEGRATION_PROVIDERS.ARCSIGHT:
        return <ArcSightIcon />;
      case INTEGRATION_PROVIDERS.SPLUNK:
        return <SplunkIcon />;
      case INTEGRATION_PROVIDERS.QRADAR:
        return <QradarIcon />;
      case INTEGRATION_PROVIDERS.OTHERS:
        return <OthersIcon />;
      case INTEGRATION_PROVIDERS.VSPHERE:
        return <VsphereIcon />;
      case INTEGRATION_PROVIDERS.MSTEAMS:
        return <MSTeamsIcon />;
      // Add more cases for other icon types
      default:
        return null;
    }
  };

  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);

  const integrationActionMutation = useIntegrationActionConnection();
  const deleteAuthConfigAPI = useDeleteAuthConfigAPI(
    item.configData?.configID ?? ""
  );
  const queryClient = useQueryClient();

  const onDeactivateIntegrationClick = (integration: IntegrationConfigType) => {
    if (
      getIntegrationEnum(integration) === INTEGRATIONS.OAUTH_IDENTITYPROVIDER ||
      getIntegrationEnum(integration) === INTEGRATIONS.SAML_IDENTITYPROVIDER
    ) {
      deleteAuthConfigAPI.mutate(
        {},
        {
          onSuccess: response => {
            queryClient.invalidateQueries({
              predicate: q => {
                let tag: string = q.queryKey[0] as string;
                return tag.startsWith("auth");
              },
            });
            setTimeout(() => {
              setSnackbar(
                true,
                SnackBarSeverity.Success,
                "DeletedSuccessfully"
              );
            }, 500);
            setIsConfirmationDialogOpen(false);
            onRefreshData();
          },
          onError: error => {
            setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
          },
        }
      );
    } else {
      const body = [
        {
          integrationProvider: integration.integrationProvider,
          integrationType: integration.integrationType,
          status: false,
        },
      ];

      integrationActionMutation.mutateAsync(body, {
        onSuccess: response => {
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            "IntegrationConfigurationDeactivated",
            { name: capitalizeFirstLetter(integration.name) }
          );
          setIsConfirmationDialogOpen(false);
          onRefreshData();
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  };
  const getCardButtonText = (integrationKey: INTEGRATIONS) => {
    return integrationKey === INTEGRATIONS.VSPHERE_CONNECTORS
      ? ButtonCardText.INSTALL_GUIDE
      : ButtonCardText.ACTIVATE;
  };
  return (
    <Card>
      <CardContent>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" marginBottom={4}>
            <Stack
              alignItems="center"
              height="48px"
              justifyContent="center"
              sx={{}}
            >
              {renderIcon(item.integrationProvider || "default")}
            </Stack>
            <Stack sx={{ marginLeft: 3 }}>
              <Typography variant="subtitle1" fontWeight={700}>
                {item.name}
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="body2" sx={{ minHeight: 80 }}>
            {window.getCTTranslatedText(item.description)}
          </Typography>
        </Stack>
        {Boolean(userPermissions.has("UPDATE_INTEGRATION")) && (
          <Stack
            sx={{ float: "right" }}
            marginTop={4}
            marginBottom={2}
            direction="row"
            justifyContent="space-between"
          >
            {item.status ? (
              <Stack direction="row" alignItems="center">
                {getIntegrationEnum(item) !==
                  INTEGRATIONS.XCLOUD_VULNERABILITYSCANNER &&
                  getIntegrationEnum(item) !==
                    INTEGRATIONS.OAUTH_IDENTITYPROVIDER &&
                  getIntegrationEnum(item) !==
                    INTEGRATIONS.SAML_IDENTITYPROVIDER && (
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => onActivateClick(item)}
                    >
                      {window.getCTTranslatedText(
                        getIntegrationEnum(item) ===
                          INTEGRATIONS.SCIM_USERPROVISIONING
                          ? "View"
                          : "Edit"
                      )}
                    </Button>
                  )}
                {getIntegrationEnum(item) !==
                  INTEGRATIONS.SCIM_USERPROVISIONING &&
                  getIntegrationEnum(item) !==
                    INTEGRATIONS.MSTEAMS_COLLABORATION && (
                    <>
                      <LoadingButton
                        variant="text"
                        color="error"
                        type="submit"
                        onClick={() => setIsConfirmationDialogOpen(true)}
                      >
                        {window.getCTTranslatedText("Deactivate")}
                      </LoadingButton>
                      <ConfirmationDialog
                        title={window.getCTTranslatedText(
                          "DeactivateIntegration",
                          { name: item.name }
                        )}
                        info={window.getCTTranslatedText(
                          `Deactivating the integration app will disable its functionality. Are you sure you want to continue?`
                        )}
                        open={isConfirmationDialogOpen}
                        handleClose={() => setIsConfirmationDialogOpen(false)}
                        onConfirmation={() =>
                          onDeactivateIntegrationClick(item)
                        }
                        isLoading={
                          getIntegrationEnum(item) ===
                            INTEGRATIONS.OAUTH_IDENTITYPROVIDER ||
                          getIntegrationEnum(item) ===
                            INTEGRATIONS.SAML_IDENTITYPROVIDER
                            ? Boolean(deleteAuthConfigAPI.isLoading)
                            : Boolean(integrationActionMutation.isLoading)
                        }
                      />
                    </>
                  )}
              </Stack>
            ) : (
              <Tooltip
                title={
                  item.disable
                    ? `Please deactivate the other ${item.category} to activate ${item.name}`
                    : ""
                }
              >
                <span>
                  <LoadingButton
                    variant="text"
                    color="primary"
                    disabled={item.disable}
                    onClick={() => onActivateClick(item)}
                    loading={isLoading}
                  >
                    {window.getCTTranslatedText(
                      getCardButtonText(getIntegrationEnum(item))
                    )}
                  </LoadingButton>
                </span>
              </Tooltip>
            )}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
