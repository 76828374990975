import { Button, Stack } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { CreateTagBasedPolicyDrawer } from "modules/create-tag-based-policy-drawer";
import {
  GridToolbar,
  ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { usePathStore } from "pages/paths/store";
import { Path, PathStatus } from "pages/paths/types";
import { useEffect, useMemo, useState } from "react";
import { PathsViewDrawer } from "../paths-review-drawer";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";

export interface PathToolbarProps extends Omit<ToolbarProps, "children"> {
  selectedData: Array<Path> | undefined;
  showSrcDestOption: boolean;
  recommendationId?: string;
}

export default function PathToolbar(props: PathToolbarProps) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const requestAPIRefresh = usePathStore(store => store.requestAPIRefresh);
  const [selectedOperation, setSelectedOperation] = useState<
    PathStatus | undefined
  >(undefined);
  const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);

  const [openMenu, setMenuOpen] = useState(false);
  const [isCreateTagPolicyDrawerOpen, setIsCreateTagPolicyDrawerOpen] =
    useState(false);

  useEffect(() => {
    if (props.recommendationId === "templatizecoreinfraservices") {
      handleAddToTemplateDialogOpen(true);
    }
  }, [props.recommendationId]);

  const handleMenuToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const confirmPathsStatus = () => {
    setSelectedOperation(undefined);
    props.hideToolbar();
    requestAPIRefresh();
  };

  const handleAddToTemplateDialogOpen = (dialogVisibility: boolean) => {
    setShowAddToTemplateDialog(dialogVisibility);
  };

  const handleClose = () => {
    setShowAddToTemplateDialog(false);
  };

  const addToTemplate = () => {
    setShowAddToTemplateDialog(false);
    requestAPIRefresh();
    props.hideToolbar();
  };

  const isCreateTagPolicyButtonDisable = useMemo(() => {
    const selectedData = props.selectedData;
    if (!selectedData || selectedData.length !== 1) {
      return true;
    }
    return !selectedData.every(
      pathData =>
        Object.keys(pathData.destinationAsset?.coreTags ?? {}).length > 0 &&
        Object.keys(pathData.sourceAsset?.coreTags ?? {}).length > 0
    );
  }, [props.selectedData]);

  const actions = (
    <Stack direction="row">
      <Button
        variant="outlined"
        aria-label="change path status"
        aria-haspopup="menu"
        onClick={handleMenuToggle}
        sx={{ marginLeft: 2 }}
      >
        {window.getCTTranslatedText("Change Path status")}
      </Button>
    </Stack>
  );

  return (
    <GridToolbar {...props}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ width: "100%", p: 1 }}
        justifyItems="flex-end"
        alignItems={"flex-end"}
        justifyContent="flex-end"
      >
        <AddToTemplateDrawer
          isOpen={showAddToTemplateDialog}
          page={"paths"}
          title="Add to Template"
          rules={props.selectedData}
          showSrcDestOption={props.showSrcDestOption}
          onCancel={handleClose}
          onConfirm={addToTemplate}
          btnTitle={"add"}
          actionType={TemplateActionType.add}
        />
        {userPermissions.has("UPDATE_PATH") && actions}

        <Stack direction="row" alignItems={"center"}>
          {userPermissions.has("CREATE_TAGBASEDPOLICY") && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setIsCreateTagPolicyDrawerOpen(true)}
              disabled={isCreateTagPolicyButtonDisable}
            >
              {window.getCTTranslatedText("create access policy")}
            </Button>
          )}

          {userPermissions.has("UPDATE_TEMPLATE") && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleAddToTemplateDialogOpen(true)}
              sx={{ marginLeft: 2 }}
            >
              {window.getCTTranslatedText("Add to template")}
            </Button>
          )}
        </Stack>

        {openMenu && (
          <PathsViewDrawer
            isOpen={openMenu}
            selectedStatus={selectedOperation}
            selectedData={props?.selectedData}
            title={"Change Path status"}
            confirm={confirmPathsStatus}
            onClose={() => {
              setSelectedOperation(undefined);
              props.hideToolbar();
            }}
          />
        )}

        {isCreateTagPolicyDrawerOpen && props?.selectedData?.[0] && (
          <CreateTagBasedPolicyDrawer
            isOpen={isCreateTagPolicyDrawerOpen}
            onClose={() => props.hideToolbar()}
            defaultDestinationCoreTags={
              props?.selectedData?.[0]?.destinationAsset?.coreTags
            }
            defaultSourceCoreTags={
              props?.selectedData?.[0]?.sourceAsset?.coreTags
            }
            templateRules={[
              {
                listenPort: props?.selectedData?.[0]?.port,
                listenPortProtocol: props?.selectedData?.[0]?.protocol,
                listenPortReviewed: props?.selectedData?.[0]?.reviewed,
                listenProcessNames: props?.selectedData?.[0]?.process,
              },
            ]}
          />
        )}
      </Stack>
    </GridToolbar>
  );
}
