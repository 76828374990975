import { Box, Link, Stack, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import numeral from "numeral";
import { TagRule } from "../../types";
import pluralize from "pluralize";

export function MatchedAssetCount({ rule }: { rule: TagRule }) {
  if (!rule.ruleId) {
    return <Box style={{ minWidth: 120, minHeight: 45 }} />;
  }

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
      <Stack alignItems="flex-start">
        <Tooltip title={"View assets"}>
          <Link
            typography={"body2"}
            component={RouterLink}
            to={`/assets?${rule.ruleCriteriaAsParams}`}
            sx={{ color: "primary.main" }}
          >
            {numeral(rule?.matchingAssets ?? 0).format("0a")}{" "}
            {pluralize(
              window.getCTTranslatedText("asset"),
              rule.matchingAssets ?? 0
            )}
          </Link>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
