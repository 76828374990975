import {
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CTWidget } from "common/molecules/widget";
import {
  ApplianceWidgetProps,
  HealthChart,
} from "pages/appliance-detail/types";
import { Appliance } from "pages/appliances/types";
import { SecurityRiskProgressBar } from "pages/dashboard/components/security-risk/components/security-risk-progressbar";
import { getColor } from "common/constants/colors";
import { getSecurityLevelText } from "pages/dashboard/helpers/getSecurityLevelText";
import { DetailsIcon } from "assets/svgs/DetailsIcon";
import { useState } from "react";
import { ApplianceHealthDetails } from "./components/ApplianceHealthDetails";

interface WidgetProps {
  appliance?: Appliance;
}

export const getUsageValue = (value: number = 0) => {
  return value && value > 0 ? value : 0;
};

const HealthWidgetContent = ({ appliance }: WidgetProps) => {
  const theme = useTheme();

  const ApplianceHealthCharts: HealthChart[] = [
    {
      name: window.getCTTranslatedText("cpu"),
      value: getUsageValue(appliance?.cpuUtilization),
    },
    {
      name: window.getCTTranslatedText("memory"),
      value: getUsageValue(appliance?.memoryUtilization),
    },
    {
      name: window.getCTTranslatedText("Network"),
      value: getUsageValue(appliance?.lanUtilization),
    },
    {
      name: window.getCTTranslatedText("disk"),
      value: getUsageValue(appliance?.diskUtilization),
    },
  ];

  const renderUsageChart = (chartData: HealthChart) => {
    return (
      <Grid
        key={chartData.name}
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        px={4}
      >
        <Stack sx={{ position: "relative" }}>
          <SecurityRiskProgressBar
            value={chartData.value || 0}
            text={""}
            circleRatio={0.5}
            rotation={0.75}
            pathColor={getColor(getSecurityLevelText(chartData.value))}
            trailColor={theme.palette.background.default}
            strokeWidth={8}
          />
          <Stack
            sx={{
              position: "absolute",
              bottom: "50px",
              right: 0,
              left: 0,
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subtitle2">{chartData.value}%</Typography>
            <Typography variant="body2" mt={2}>
              {chartData.name}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    );
  };

  return (
    <Stack pt={4}>
      <Grid container>
        {ApplianceHealthCharts.map((chartData, index) => {
          return renderUsageChart(chartData);
        })}
      </Grid>
    </Stack>
  );
};

export const ApplianceHealth = ({
  appliance,
  isLoading,
}: ApplianceWidgetProps) => {
  const theme = useTheme();
  const [showHealthDetails, setShowHealthDetails] = useState(false);

  const renderActions = () => {
    return (
      <>
        <Tooltip title={window.getCTTranslatedText("View Details")}>
          <Stack>
            <IconButton onClick={() => setShowHealthDetails(true)}>
              <DetailsIcon color={theme.palette.primary.main} />
            </IconButton>
          </Stack>
        </Tooltip>
        <ApplianceHealthDetails
          open={showHealthDetails}
          onClose={() => setShowHealthDetails(false)}
          appliance={appliance}
        />
      </>
    );
  };

  return (
    <CTWidget
      title={window.getCTTranslatedText("health")}
      isLoading={isLoading}
      actions={renderActions()}
      children={<HealthWidgetContent appliance={appliance} />}
    />
  );
};
