import { AssetAttributesBlock } from "modules/attributes-block";
import { useState } from "react";
import { useUserGroupsAPI } from "pages/users-segmentation/components/user-groups/hooks";
import { BASE_USERGROUPS_COLUMNS } from "pages/users-segmentation/components/user-groups/components/user-group-data-grid/constants";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { CTDrawer } from "common/molecules/drawer";
import { UserGroup } from "pages/users-segmentation/components/user-groups/types";
import { useCore } from "modules/core";
import { Scope } from "modules/scope-metadata/types";
import { USERGROUP_FACET_ORDER } from "pages/users-segmentation/components/user-groups/constants";
import { useUserGroupStore } from "pages/users-segmentation/components/user-groups/store";
import { FACET_OPTION_MAPPING } from "pages/users-segmentation/components/users/constants";
import { useAssetCustomUserStore } from "pages/users-segmentation/components/users/store/useUserStore";

export function AssetMetaDataUserGroup({
  criteria,
}: {
  readonly criteria: string;
}) {
  const [showUserGroupDrawer, setShowUserGroupDrawer] = useState(false);

  const coreResponse = useCore<UserGroup>({
    useStore: useUserGroupStore,
    facetGroupInfo: USERGROUP_FACET_ORDER,
    scope: Scope.SCIM_GROUP,
    facetOptionsDisplayMapping: FACET_OPTION_MAPPING,
    useApi: useUserGroupsAPI,
    additionalCriteria: criteria,
    pageSize: 100,
    skipSearch: true,
    useFacetStore: useAssetCustomUserStore,
    skipUseFacetQueryConnector: true,
  });

  const columns = BASE_USERGROUPS_COLUMNS.filter(
    col => col.field !== "usersCount"
  );
  return (
    <>
      <AssetAttributesBlock
        assign={() => {}}
        count={coreResponse?.rowCount ?? 0}
        canAssign={false}
        isLoading={coreResponse.mutation.isLoading}
        showAssigned={() => {
          setShowUserGroupDrawer(true);
        }}
        title={"User_Groups"}
      />

      <CTDrawer
        open={showUserGroupDrawer}
        onClose={() => {
          setShowUserGroupDrawer(false);
        }}
        title={window.getCTTranslatedText("User Groups")}
      >
        <DataGrid
          rows={coreResponse.rows}
          rowCount={coreResponse.rowCount}
          mutation={coreResponse.mutation}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={coreResponse.onSortChange}
          columns={columns}
          pagination={true}
          getRowId={(user: UserGroup) => `${user.groupID}`}
          paginationMode="server"
          sortingMode="server"
        />
      </CTDrawer>
    </>
  );
}
