import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { TemplateTypeOptionsText } from "pages/templates/constants";
import { TemplateType } from "pages/templates/types";
import {
  PathFieldDisplayText,
  PathFieldKeys,
  RequiredTemplateFields,
  TemplateFieldDisplayText,
  TemplateFieldKeys,
  TemplateFieldOptions,
  TemplateFormData,
  TemplateFormMode,
} from "../../types";
import { Scope } from "modules/scope-metadata/types";
import { CTScopeAutoSuggest } from "modules/ct-scope-auto-suggest";
import { useState } from "react";
import { AllowedField, Suggestion } from "modules/ct-scope-auto-suggest/types";

export interface RenderTemplateFormProps {
  mode: TemplateFormMode;
  formData: TemplateFormData;
  setFormData: React.Dispatch<React.SetStateAction<TemplateFormData>>;
  handleResetPortRuleStatus: (type: TemplateType) => void;
}

export const RenderTemplateForm = ({
  mode,
  formData,
  setFormData,
  handleResetPortRuleStatus,
}: RenderTemplateFormProps) => {
  const [templateSuggestion, setTemplateSuggestion] = useState<
    Suggestion | undefined
  >();

  const handleTypeChange = (e: any) => {
    if (e?.target?.value !== undefined) {
      if (formData.templateType !== e.target.value) {
        const type = e.target.value;
        handleResetPortRuleStatus(type);
        setFormData(prev => ({ ...prev, templateType: type }));
      }
    }
  };

  const handleUpdateCategory = (suggestion?: Suggestion) => {
    setTemplateSuggestion(suggestion);
    setFormData(prev => ({ ...prev, templateCategory: suggestion?.value }));
  };

  const handleUpdate = ({ field, value }: TextFieldUpdate) => {
    switch (field) {
      case TemplateFieldKeys.Name:
        if (value !== formData.templateName) {
          setFormData(prev => ({ ...prev, templateName: value }));
        }
        break;
      case TemplateFieldKeys.Description:
        if (value !== formData.templateDescription) {
          setFormData(prev => ({ ...prev, templateDescription: value }));
        }
        break;
    }
  };

  return (
    <FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
      <Stack direction={"row"} alignItems="center">
        <Grid container columnSpacing={3} sx={{ width: "100%" }}>
          {TemplateFieldOptions.map((key: TemplateFieldKeys, index: number) => {
            return (
              <Grid item xs key={`${key}`}>
                <CTInputField
                  field={key}
                  displayName={TemplateFieldDisplayText[key]}
                  value={
                    key === TemplateFieldKeys.Name
                      ? formData.templateName
                      : formData.templateDescription
                  }
                  handleUpdate={handleUpdate}
                  required={RequiredTemplateFields[key]}
                  autoFocus={index === 0}
                />
              </Grid>
            );
          })}
        </Grid>
      </Stack>

      <Grid container columnSpacing={3} sx={{ width: "100%", mt: 2 }}>
        <Grid item xs={6} width={"100%"}>
          <CTScopeAutoSuggest
            displayName={window.getCTTranslatedText(
              PathFieldDisplayText[PathFieldKeys.TemplateCategory]
            )}
            selectedValue={templateSuggestion}
            handleUpdate={handleUpdateCategory}
            scopeConfig={[
              { scope: Scope.Template, field: AllowedField.TemplateCategory },
            ]}
          />
        </Grid>
      </Grid>

      <Stack>
        {mode === TemplateFormMode.CREATE && (
          <Stack sx={{ mt: 4 }}>
            <RadioGroup
              row
              value={formData.templateType}
              onChange={handleTypeChange}
              name="templatetype"
              sx={{ flexDirection: "row", mt: 2 }}
            >
              {makeFormLabel({
                value: TemplateType.ApplicationTemplate,
              })}
              {makeFormLabel({
                value: TemplateType.BlockTemplate,
              })}
            </RadioGroup>
          </Stack>
        )}
      </Stack>
    </FormControl>
  );
};

const makeFormLabel = ({ value }: { value: TemplateType }) => (
  <FormControlLabel
    value={value}
    defaultValue={TemplateType.ApplicationTemplate}
    control={<Radio size="small" />}
    sx={{ mr: 6 }}
    label={
      <Stack>
        <Typography>
          {window.getCTTranslatedText(TemplateTypeOptionsText[value])}
        </Typography>
      </Stack>
    }
  />
);
