import styled from "@emotion/styled";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { useEffect, useRef } from "react";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { UploadIcon } from "assets/svgs/UploadIcon";

// @ts-ignore:
const getColor = props => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export function FilePicker({
  onChangeFiles,
  options,
}: {
  onChangeFiles: (files: File[]) => void;
  options?: DropzoneOptions;
}) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone(options);
  const changeFilesRef = useRef(onChangeFiles);
  changeFilesRef.current = onChangeFiles;

  useEffect(() => {
    changeFilesRef.current(acceptedFiles);
  }, [acceptedFiles]);

  const fileString = (options?.maxFiles || 0) > 1 ? "files" : "file";
  const theme = useTheme();

  return (
    <Container
      style={{
        background:
          theme.palette.mode === "dark"
            ? theme.palette.background.paper
            : "#fafafa",
      }}
      {...getRootProps({ isFocused, isDragAccept, isDragReject })}
    >
      <input {...getInputProps()} />
      <UploadIcon />
      <Typography variant="body1">
        {window.getCTTranslatedText("DragAndDropFile", { fileString })}
      </Typography>
      <Typography variant="body1">
        {window.getCTTranslatedText("or")}
      </Typography>
      <Button color="info">
        {window.getCTTranslatedText("BrowseForFile", { fileString })}
      </Button>
    </Container>
  );
}
