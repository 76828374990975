import { Stack, Typography } from "@mui/material";

interface FormSectionHeaderProps {
  header: string;
}

export function FormSectionHeader({ header }: FormSectionHeaderProps) {
  function onHeaderClick() {
    const element = document.getElementById(`id-${header}`);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  return (
    <Stack
      id={`id-${header}`}
      onClick={onHeaderClick}
      sx={{
        cursor: "pointer",
      }}
      mb={2}
    >
      <Typography variant="subtitle1">
        {window.getCTTranslatedText(header)}
      </Typography>
    </Stack>
  );
}
