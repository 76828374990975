import { Box, Typography } from "@mui/material";
import { AssignedTemplatesViewDrawer } from "modules/asset-templates/assigned-templates-view-drawer";
import { useAssetTemplates } from "modules/asset-templates/hooks/useAssetTemplates";
import { MatchedByTemplates } from "pages/paths/types";
import { useNavigate } from "react-router-dom";

export function MatchedByTemplateName({
  matchedByTemplate,
}: {
  matchedByTemplate: MatchedByTemplates;
}) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{ width: "100%", cursor: "pointer" }}
      onClick={() =>
        navigate(`/templates?templateId=${matchedByTemplate.templateId}`)
      }
    >
      <Typography variant="body1">{matchedByTemplate.templateName}</Typography>
    </Box>
  );
}

interface MatchedByTemplateDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  list: MatchedByTemplates[];
}

export const MatchedByTemplateDrawer = ({
  isOpen,
  onClose,
  list,
}: MatchedByTemplateDrawerProps) => {
  const criteria = `templateid in (${list
    .map(li => `'${li.templateId}'`)
    .join(", ")})`;

  const {
    templateList,
    isLoading,
    templateListMutation,
    onSortChange,
    sort,
    setPageSize,
    setPage,
    pageSize,
    page,
    totalCount,
  } = useAssetTemplates({ criteria: criteria, enable: isOpen });

  return (
    <AssignedTemplatesViewDrawer
      isOpen={isOpen}
      onClose={onClose}
      templateList={templateList}
      templatesSuggestMutation={templateListMutation}
      isLoading={isLoading}
      page={"assets"}
      title={"Matching Templates"}
      onSortChange={onSortChange}
      canDelete={false}
      setPageSize={setPageSize}
      setPage={setPage}
      pageSize={pageSize}
      pageCount={page}
      sort={sort}
      totalCount={totalCount}
      showSearch={false}
    />
  );
};
