import {
  RecommendationScope,
  recommendationConfig,
  timelineFilter,
} from "../constant";

export const getRecommendationUrlQuery = (
  scope: RecommendationScope,
  id: string,
  days: number | undefined
) => {
  if (id && scope) {
    let url = recommendationConfig()[scope][id]?.url;

    if (url && days) {
      const dayFilter = timelineFilter[days];
      if (dayFilter) {
        url = url.replace("v(t)", encodeURIComponent(dayFilter));
      }
    }
    return encodeURI(url);
  }
  return "";
};
