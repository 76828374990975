import { Stack, Typography } from "@mui/material";
import { KeyValueChip } from "common/atoms/key-value-chip";
import { AssetMetaDataReadonlyProps, AssetTag } from "pages/assets/types";

function NoTagsPlaceholder(tagType: string) {
  return (
    <Typography variant="body2" color="GrayText" sx={{ mt: 1 }}>
      No {tagType} Tags
    </Typography>
  );
}

function TagsList(tags: Array<AssetTag> | undefined, tagType: string) {
  return (
    <Stack
      flexDirection="column"
      sx={{
        mb: 1,
        mt: 1,
      }}
    >
      {/* <Stack flexDirection="row" alignItems="center">
        <LocalOfferOutlinedIcon fontSize="small" />
        <Typography variant="subtitle2" sx={{ ml: 1 }}>
          {tagType} Tags
        </Typography>
      </Stack> */}
      {Boolean(tags?.length) ? (
        <Stack flexDirection="row" flexWrap="wrap">
          {tags?.map((tag: AssetTag) => {
            return (
              <KeyValueChip key={tag?.id} cKey={tag?.key} cValue={tag?.value} />
            );
          })}
        </Stack>
      ) : (
        NoTagsPlaceholder(tagType)
      )}
    </Stack>
  );
}

export function TagsRenderer({ asset }: AssetMetaDataReadonlyProps) {
  return (
    <Stack flexDirection={"column"}>
      {/* {TagsList(asset?.tags, "Asset")} */}
      {TagsList(asset?.cloudTags, "Cloud")}
    </Stack>
  );
}
