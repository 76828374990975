import { Box, Stack, Typography } from "@mui/material";
import { PortColumnRenderProps } from "pages/ports/types";

export function PortLastObserved({ port }: PortColumnRenderProps) {
  if (!port.lpId) {
    return <Box style={{ width: 110 }} />;
  }

  return (
    <Stack alignItems="left" justifyContent="center">
      <Typography variant="body2">{port?.listenPortLastObserved}</Typography>
    </Stack>
  );
}
