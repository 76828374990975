import { useCriteriaBuilder } from "modules/core/Core";
import { FacetGroups, FacetState } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { useAssetsFacetsOrder } from "pages/assets/constants";
import { PATH_TRAFFIC_FACET_ORDER } from "pages/paths/constants";
import { PathDirection } from "pages/paths/types";
import { useEffect, useMemo } from "react";
import { useQueryParam } from "use-query-params";
import { ScopeMetadata } from "../../../modules/scope-metadata/types";
import { DEfAULT_TRAFFIC_FILTERS, useVisxStore } from "../store";

export const DEFAULT_HUB_CRITERIA = "NOT(assetid=NULL)";
export const TRAFFIC_FILTER_CONDITION = "(assetid=NULL)";

interface VisualizerCriteriaBuilderProps {
  sourceAssetFilters: FacetState;
  selectedDirection: Direction;
  search: string | null;
}

enum VisFacetGroups {
  Hub = "Source",
  NonHub = "Destination",
  Traffic = "Traffic",
}

const FilterTargetGroups: { [key: string]: VisFacetGroups } = {
  [FacetGroups.Network]: VisFacetGroups.NonHub,
};

Object.values(PATH_TRAFFIC_FACET_ORDER).forEach(key => {
  FilterTargetGroups[key] = VisFacetGroups.Traffic;
});

export function useVisualizerTrafficCriteriaBuilder({
  sourceAssetFilters: sourceFilters,
  selectedDirection,
  search,
}: VisualizerCriteriaBuilderProps): {
  sourceCriteria: string | undefined;
  destinationCriteria: string | undefined;
  trafficCriteria: string | undefined;
  metaData: ScopeMetadata | undefined;
  hubCriteria: string | undefined;
  isLoading: boolean;
  direction: Direction;
} {
  const [hasFiltersInQuery] = useQueryParam("filters");
  const metadataQuery = useScopeMetadata({ scope: Scope.Path });
  const metaData = metadataQuery.data;

  const assetMetadataQuery = useScopeMetadata({ scope: Scope.Asset });
  const assetMetaData = assetMetadataQuery.data;
  const assetsFacetOrder = useAssetsFacetsOrder();

  const {
    sourceAssetFilters,
    destinationAssetFilters,
    trafficFilters,
    switchedFacets,
  } = useMemo(() => {
    let sourceAssetFilters: FacetState = new Map();
    let destinationAssetFilters: FacetState = new Map();
    let trafficFilters: FacetState = new Map();

    let combined = new Map([
      ...DEfAULT_TRAFFIC_FILTERS,
      ...new Map(sourceFilters),
    ]);

    combined.forEach((value, key) => {
      let group = assetsFacetOrder[key] || PATH_TRAFFIC_FACET_ORDER[key];
      let target = FilterTargetGroups[group];
      if (target === VisFacetGroups.NonHub) {
        destinationAssetFilters && destinationAssetFilters.set(key, value);
      } else if (target === VisFacetGroups.Traffic) {
        trafficFilters && trafficFilters.set(key, value);
      } else {
        sourceAssetFilters && sourceAssetFilters.set(key, value);
      }
    });

    return {
      sourceAssetFilters,
      destinationAssetFilters,
      trafficFilters,
      switchedFacets:
        trafficFilters?.size > 2 ||
        (sourceFilters?.size &&
          sourceFilters?.size !== sourceAssetFilters?.size),
    };
  }, [sourceFilters, assetsFacetOrder]);

  const directionFromFilters = Array.from(
    trafficFilters.get("direction")?.keys() ?? []
  )[0];

  let hubCriteria: string | undefined =
    useCriteriaBuilder(search || "", sourceAssetFilters, assetMetaData) || "*";

  if (hasFiltersInQuery && hubCriteria === "*" && !switchedFacets) {
    hubCriteria = undefined;
  }

  let trafficAssetCriteriaBase: string | undefined =
    useCriteriaBuilder("", destinationAssetFilters, metaData) || "*";

  if (trafficAssetCriteriaBase === "*" && hubCriteria && hubCriteria !== "*") {
    trafficAssetCriteriaBase = `NOT (${hubCriteria})`;
  }

  let trafficAssetCriteria = trafficAssetCriteriaBase;
  if (trafficAssetCriteriaBase && trafficAssetCriteriaBase !== "*") {
    let operator = trafficAssetCriteriaBase.includes("namednetworkname")
      ? "AND"
      : "OR";
    trafficAssetCriteria = `(${trafficAssetCriteriaBase}) ${operator} ${TRAFFIC_FILTER_CONDITION}`;
  }

  if (hubCriteria === "*") {
    hubCriteria = DEFAULT_HUB_CRITERIA;
  }

  const trafficCriteria = useCriteriaBuilder("", trafficFilters, metaData);

  let sourceCriteria =
    selectedDirection === Direction.Inbound
      ? trafficAssetCriteria
      : hubCriteria;

  let destinationCriteria =
    selectedDirection === Direction.Outbound
      ? trafficAssetCriteria
      : hubCriteria;

  const setSourceCriteria = useVisxStore(state => state.setSourceCriteria);
  const setDestinationCriteria = useVisxStore(
    state => state.setDestinationCriteria
  );
  const setTrafficCriteria = useVisxStore(state => state.setTrafficCriteria);

  useEffect(() => {
    setSourceCriteria(sourceCriteria);
    setDestinationCriteria(destinationCriteria);
    setTrafficCriteria(trafficCriteria);
  }, [
    destinationCriteria,
    setDestinationCriteria,
    setSourceCriteria,
    setTrafficCriteria,
    sourceCriteria,
    trafficCriteria,
  ]);

  // do not load anything until time filter is set
  if (!trafficFilters.has("pathlastobserved")) {
    hubCriteria = undefined;
    sourceCriteria = undefined;
    destinationCriteria = undefined;
  }

  return {
    sourceCriteria,
    destinationCriteria,
    trafficCriteria,
    metaData,
    hubCriteria,
    direction:
      directionFromFilters === PathDirection.Inbound
        ? Direction.Inbound
        : Direction.Outbound,
    isLoading: metadataQuery.isLoading || assetMetadataQuery.isLoading,
  };
}
