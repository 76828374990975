import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { METHOD_TYPE } from "common/constants/methods";
import { parseErrorMessage } from "common/utils";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { IntegrationConfigType } from "pages/Integration/constants";
import {
  useIntegrationActionConnection,
  useIntegrationGetAPI,
  useIntegrationUpdate,
  useTestIntegrationConnection,
} from "pages/Integration/hooks";
import {
  CrowdStrikeConfigResult,
  IdentifierMappings,
} from "pages/Integration/type";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import { useEffect, useState } from "react";

interface CrowdStrikeConfigProps {
  open: boolean;
  onClose: () => void;
  integration: IntegrationConfigType;
}

export function CrowdStrikeConfig({
  open,
  onClose,
  integration,
}: CrowdStrikeConfigProps) {
  const rulesSectionWrapper = {
    py: 0,
    px: 3,
    borderRadius: 1,
    width: "49%",
  };

  const attributeSectionWrapper = {
    py: 1,
    px: 3,
    borderRadius: 1,
  };

  const rulesTitleWrapper = {
    py: 2,
    px: 3,
    borderRadius: 1,
  };

  const helpText = {
    lineHeight: "20px",
    fontSize: "12px",
  };

  const { data: fieldData = {} } = useIntegrationGetAPI(
    integration.integrationType,
    integration.integrationProvider
  );

  const submitConfigMutation = useIntegrationUpdate(
    integration.integrationType,
    integration.integrationProvider,
    integration.configured ? METHOD_TYPE.PUT : METHOD_TYPE.POST
  );
  const testConfigMutation = useTestIntegrationConnection(
    integration.integrationType,
    integration.integrationProvider
  );

  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  const locationOptionsList = ["us-1", "us-2", "eu-1", "us-gov-1"];
  const identifierOptionsList = ["assetname", "macaddress"];
  const crowdstrikeIdentifierKeyMapping = {
    assetname: "hostname",
    macaddress: "mac_address",
  };

  const [clientId, setClientID] = useState("");
  const [clientSecret, setClientSecretChange] = useState("");
  const [selectLocationType, setLocationType] = useState<string>("");

  const [identifierList, setIdentifierList] = useState<IdentifierMappings[]>(
    []
  );

  useEffect(() => {
    if (fieldData.integrationParameters) {
      setClientID(fieldData.integrationParameters.clientId || "");
      setClientSecretChange(fieldData.integrationParameters.clientSecret || "");
      setLocationType(fieldData.integrationParameters.location || "");

      const reconciliationIdentifier =
        fieldData.integrationParameters?.reconciliationIdentifier ?? [];
      let output: IdentifierMappings[] = [];
      if (!reconciliationIdentifier.length) {
        output = [{ ctField: "macaddress", integrationField: "mac_address" }];
      } else {
        fieldData.integrationParameters?.reconciliationIdentifier.forEach(
          (obj: any) => {
            output.push({
              ctField: obj.ctField,
              integrationField: obj.integrationField,
            });
          }
        );
      }
      setIdentifierList(output);
    } else {
    }
  }, [fieldData]);

  const handleClientIdChange = (e: TextFieldUpdate) => {
    setClientID(e.value);
  };

  const handleClientSecretChange = (e: TextFieldUpdate) => {
    setClientSecretChange(e.value);
  };

  const handleLocationChange = (e: TextFieldUpdate) => {
    setLocationType(e.value);
  };

  const integrationActionMutation = useIntegrationActionConnection();

  const handleIdentifierChange = (e: TextFieldUpdate, index: number) => {
    if (e?.value !== undefined) {
      const value = e?.value.trim() || "";
      let newIdentifierList = [...identifierList];
      newIdentifierList[index].ctField = value;
      newIdentifierList[index].integrationField =
        crowdstrikeIdentifierKeyMapping[
          value as keyof typeof crowdstrikeIdentifierKeyMapping
        ];

      const uniqueIdentiferList = newIdentifierList.filter((obj, index) => {
        return (
          index === newIdentifierList.findIndex(o => obj.ctField === o.ctField)
        );
      });
      setIdentifierList(uniqueIdentiferList);
    }
  };

  const addNewIdentifier = () => {
    const newIdentifier = [...identifierList];
    newIdentifier.push({ ctField: "", integrationField: "" });
    setIdentifierList(newIdentifier);
  };

  const removeIdentifier = (index: number) => {
    const newIdentifier = [...identifierList];
    newIdentifier.splice(index, 1);
    setIdentifierList(newIdentifier);
  };

  const processData = () => {
    const body: CrowdStrikeConfigResult = {
      integrationProvider: integration.integrationProvider,
      integrationType: integration.integrationType,
      integrationParameters: {
        clientId: clientId || "",
        clientSecret: clientSecret || "",
        location: selectLocationType || "",
        reconciliationIdentifier: identifierList,
      },
    };

    return body;
  };

  const updateIntegrationstauts = async (status: boolean) => {
    const body = [
      {
        integrationProvider: integration.integrationProvider,
        integrationType: integration.integrationType,
        status: status,
      },
    ];

    await integrationActionMutation.mutateAsync(body, {
      onSuccess: response => {
        setSnackbar(true, SnackBarSeverity.Success, "ConfigurationActivated", {
          name: capitalizeFirstLetter(integration.integrationProvider),
        });
        onClose();
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const updateCrowdStrikeDetails = () => {
    const body: CrowdStrikeConfigResult = processData();
    submitConfigMutation.mutate(body, {
      onSuccess: response => {
        setSnackbar(
          true,
          SnackBarSeverity.Success,
          "IntegrationConfigurationSaved",
          { name: capitalizeFirstLetter(integration.integrationProvider) }
        );
        updateIntegrationstauts(true);
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const testConnection = () => {
    const body: CrowdStrikeConfigResult = processData();
    testConfigMutation.mutate(body, {
      onSuccess: response => {
        setSnackbar(
          true,
          SnackBarSeverity.Success,
          "CrowdStrikeConfigurationTestSuccessfully"
        );
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  return (
    <>
      <DialogContent>
        <FormControl sx={{ m: 0, minWidth: "100%" }}>
          <Stack
            alignItems={"flex-start"}
            style={{ position: "relative", height: "100%" }}
          >
            <Typography variant="body1" sx={{ mt: 0, mb: 2 }}>
              {window.getCTTranslatedText("Configuration Details")}
            </Typography>
            <Box style={{ minWidth: "100%" }}>
              <Stack
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={4}
              >
                <Stack
                  direction="row"
                  sx={{
                    width: "100%",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Stack
                    sx={{
                      ...rulesSectionWrapper,
                    }}
                  >
                    <CTInputField
                      field="clientId"
                      displayName={window.getCTTranslatedText("Client Id")}
                      value={clientId}
                      handleUpdate={(event: TextFieldUpdate) =>
                        handleClientIdChange(event)
                      }
                    />
                    <Typography style={helpText} sx={{ mt: 0, mb: 4 }}>
                      {window.getCTTranslatedText(
                        "crowdStrikeClientIdHelperText"
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      ...rulesSectionWrapper,
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    <CTInputField
                      type="password"
                      field="clientSecret"
                      displayName={window.getCTTranslatedText("Client Secret")}
                      value={clientSecret}
                      handleUpdate={(event: TextFieldUpdate) =>
                        handleClientSecretChange(event)
                      }
                    />
                    <Typography style={helpText} sx={{ mt: 0, mb: 4 }}>
                      {window.getCTTranslatedText(
                        "crowdStrikeClientSecretHelperText"
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      ...rulesSectionWrapper,
                    }}
                  >
                    <label style={{ fontSize: "13px", opacity: "0.6" }}>
                      {window.getCTTranslatedText("Region")}
                    </label>
                    <CTSelectDropDown
                      field={"location"}
                      displayName={window.getCTTranslatedText("Location")}
                      selectedValue={selectLocationType}
                      handleUpdate={handleLocationChange}
                      data={locationOptionsList}
                      required={Boolean(false)}
                      showLabel={false}
                    />
                    <Typography style={helpText} sx={{ mt: 0, mb: 4 }}>
                      {window.getCTTranslatedText(
                        "Region of the CrowdStrike instance"
                      )}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      ...rulesSectionWrapper,
                    }}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        flexWrap: "wrap",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <LoadingButton
                        onClick={testConnection}
                        variant="contained"
                        type="submit"
                      >
                        {window.getCTTranslatedText("Test")}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Stack
            alignItems={"flex-start"}
            justifyContent="flex-start"
            spacing={0}
            style={{ position: "relative", height: "100%" }}
            sx={{
              ...rulesTitleWrapper,
              width: "100%",
              background: theme =>
                theme.palette.mode === "dark"
                  ? theme.palette.background.default
                  : theme.palette.custom.lightGreyBg,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              sx={{
                width: "100%",
              }}
            >
              <Stack
                alignItems="center"
                sx={{
                  my: "10px",
                }}
              >
                <Typography variant="body1">
                  {window.getCTTranslatedText("Identifier")}
                </Typography>
              </Stack>
              <Stack alignItems="center">
                {identifierList &&
                  identifierList.length !== identifierOptionsList.length && (
                    <Button
                      variant="text"
                      startIcon={<AddCircleOutlineIcon />}
                      color="primary"
                      onClick={() => addNewIdentifier()}
                    >
                      {window.getCTTranslatedText("Identifier")}
                    </Button>
                  )}
              </Stack>
            </Stack>
            {identifierList &&
              identifierList.length > 0 &&
              identifierList.map((item, index, values) => {
                return (
                  <Stack
                    key={`attributes-${item.ctField}`}
                    sx={{
                      width: "100%",
                      px: 1,
                      py: 2,
                      background: theme =>
                        theme.palette.mode === "dark" ? "#ffffff12" : "#fff",
                    }}
                  >
                    <Stack>
                      <Stack
                        key={`attributes-${item.ctField}`}
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        spacing={4}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            width: "100%",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                          }}
                        >
                          <Stack
                            sx={{
                              ...rulesSectionWrapper,
                            }}
                            style={{ width: "48%" }}
                          >
                            <label style={{ fontSize: "13px", opacity: "0.6" }}>
                              {window.getCTTranslatedText("companyIdentifier", {
                                companyName: "ColorTokens",
                              })}
                            </label>
                            <CTSelectDropDown
                              field={"identifer"}
                              displayName={window.getCTTranslatedText(
                                "Identifer"
                              )}
                              selectedValue={item.ctField}
                              handleUpdate={(event: TextFieldUpdate) =>
                                handleIdentifierChange(event, index)
                              }
                              data={identifierOptionsList}
                              required={Boolean(false)}
                              showLabel={false}
                              textTransform={"none"}
                            ></CTSelectDropDown>
                          </Stack>

                          <Stack
                            sx={{
                              ...attributeSectionWrapper,
                              pr: "0",
                            }}
                            style={{ width: "48%" }}
                          >
                            <label style={{ fontSize: "13px", opacity: "0.6" }}>
                              {window.getCTTranslatedText("companyIdentifier", {
                                companyName: "CrowdStrike",
                              })}
                            </label>
                            <CTInputField
                              field={"crowdStrikeIdentifer"}
                              displayName={""}
                              value={item.integrationField}
                              disabled={true}
                              handleUpdate={(event: TextFieldUpdate) =>
                                handleClientIdChange(event)
                              }
                            />
                          </Stack>
                          <Stack
                            alignItems="center"
                            style={{ width: "4%" }}
                            sx={{ mt: "30px" }}
                          >
                            <IconButton
                              sx={{ my: 0 }}
                              size="medium"
                              aria-label="remove identifier"
                              onClick={() => removeIdentifier(index)}
                            >
                              <RemoveCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })}
          </Stack>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <ToolbarAction
          loading={submitConfigMutation.isLoading}
          save={updateCrowdStrikeDetails}
          cancel={onClose}
          actionBtnText={window.getCTTranslatedText("Save")}
        ></ToolbarAction>
      </DialogActions>
    </>
  );
}
