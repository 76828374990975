import { IconsProps } from "./common";

export const MSTeamsIcon = ({
  width = "32px",
  height = "32px",
  color = "#000",
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect
        width="47"
        height="47"
        x="0.5"
        y="0.5"
        stroke="#000"
        strokeOpacity="0.12"
        rx="3.5"
      ></rect>
      <g clipPath="url(#clip0_1622_43978)">
        <path
          fill="#7B83EB"
          d="M24.903 18.667c2.534 0 4.588-2.09 4.588-4.667 0-2.578-2.054-4.667-4.588-4.667-2.535 0-4.589 2.09-4.589 4.667 0 2.578 2.054 4.667 4.589 4.667z"
        ></path>
        <path
          fill="#000"
          d="M24.575 15H20.42l.02.093.003.008.021.086a4.665 4.665 0 002.046 2.794c1.01.628 2.22.835 3.377.578v-2.226a1.35 1.35 0 00-.385-.941 1.304 1.304 0 00-.926-.392z"
          opacity="0.1"
        ></path>
        <path
          fill="#000"
          d="M23.592 16h-2.835c.372.798.959 1.472 1.693 1.944a4.533 4.533 0 002.453.723v-1.334a1.348 1.348 0 00-.385-.941 1.303 1.303 0 00-.926-.392z"
          opacity="0.2"
        ></path>
        <path
          fill="#5059C9"
          d="M35.226 19c1.9 0 3.442-1.567 3.442-3.5S37.127 12 35.226 12c-1.9 0-3.44 1.567-3.44 3.5s1.54 3.5 3.44 3.5zM38.176 20h-7.729a.624.624 0 00-.445.188.645.645 0 00-.184.452v8.11a5.178 5.178 0 001.064 3.245 5.026 5.026 0 002.836 1.839 4.842 4.842 0 002.168-.034 4.89 4.89 0 001.944-.978 4.994 4.994 0 001.342-1.733 5.075 5.075 0 00.479-2.151V21.5c0-.398-.156-.78-.432-1.06a1.462 1.462 0 00-1.043-.44z"
        ></path>
        <path
          fill="#7B83EB"
          d="M32.768 21.5V31a8.084 8.084 0 01-1.622 4.87 7.855 7.855 0 01-4.203 2.857 7.738 7.738 0 01-5.044-.336 7.904 7.904 0 01-3.8-3.391 7.22 7.22 0 01-.492-1 8.47 8.47 0 01-.324-1 8.375 8.375 0 01-.246-2v-9.5a1.521 1.521 0 01.431-1.061A1.471 1.471 0 0118.512 20h12.782a1.452 1.452 0 011.043.439 1.5 1.5 0 01.431 1.061z"
        ></path>
        <path
          fill="#000"
          d="M23.592 16h-2.835c.372.798.959 1.472 1.693 1.944a4.533 4.533 0 002.453.723v-1.334a1.348 1.348 0 00-.385-.941 1.303 1.303 0 00-.926-.392z"
          opacity="0.2"
        ></path>
        <path
          fill="#000"
          d="M25.886 20v12.67c0 .301-.101.594-.287.828a1.295 1.295 0 01-.736.462.934.934 0 01-.285.04h-6.97a8.47 8.47 0 01-.325-1 8.375 8.375 0 01-.246-2v-9.5a1.521 1.521 0 01.431-1.061A1.471 1.471 0 0118.512 20h7.374z"
          opacity="0.1"
        ></path>
        <path
          fill="#000"
          d="M24.903 20v13.67a.984.984 0 01-.04.29 1.324 1.324 0 01-.453.748c-.231.189-.519.292-.815.292H18.1a7.22 7.22 0 01-.492-1 8.47 8.47 0 01-.324-1 8.375 8.375 0 01-.246-2v-9.5a1.521 1.521 0 01.431-1.061A1.471 1.471 0 0118.512 20h6.39z"
          opacity="0.2"
        ></path>
        <path
          fill="#000"
          d="M24.903 20v11.67c-.002.352-.14.69-.385.939s-.577.39-.923.391h-6.312a8.375 8.375 0 01-.246-2v-9.5a1.521 1.521 0 01.431-1.061A1.471 1.471 0 0118.512 20h6.39z"
          opacity="0.2"
        ></path>
        <path
          fill="#000"
          d="M18.512 20a1.453 1.453 0 00-1.044.439 1.501 1.501 0 00-.43 1.061V31c0 .674.083 1.346.245 2h5.329c.346-.002.678-.142.923-.391.245-.25.383-.587.384-.939V20h-5.407z"
          opacity="0.2"
        ></path>
        <path
          fill="#4B53BC"
          d="M9.499 16h13.11c.347 0 .68.14.927.39.245.25.383.59.383.943v13.334c0 .353-.138.693-.383.943a1.3 1.3 0 01-.927.39H9.499a1.3 1.3 0 01-.927-.39 1.345 1.345 0 01-.384-.943V17.333c0-.353.138-.693.384-.943A1.3 1.3 0 019.5 16z"
        ></path>
        <path
          fill="#fff"
          d="M19.967 20.975h-2.94v8.02h-1.929v-8.02h-2.957v-1.97h7.826v1.97z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1622_43978">
          <path fill="#fff" d="M0 0H32V32H0z" transform="translate(8 8)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
