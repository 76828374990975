import { Stack, Typography } from "@mui/material";
import { CopyButton } from "common/atoms/copy-button";
import { IDPTokenManagement } from "pages/Integration/components/scim-management-config/IDP-token-management";
import { ScimStatusResult } from "pages/Integration/type";
import { IDPList } from "../scim-management-config/IDPList";

interface TokenManagementProps {
  scimStatusData: ScimStatusResult;
}

export const TokenManagement = ({ scimStatusData }: TokenManagementProps) => {
  return (
    <Stack
      spacing={3}
      alignItems={"flex-start"}
      sx={{ width: "100%", height: "100%" }}
    >
      <Typography variant="overline" color="text.secondary" fontSize={14}>
        {window.getCTTranslatedText("prerequisites")}
      </Typography>
      <Stack
        direction={"row"}
        alignItems={"baseline"}
        justifyContent={"center"}
        spacing={10}
      >
        <Typography variant="subtitle2">
          {window.getCTTranslatedText("baseURL")}
        </Typography>
        <Stack direction={"row"} spacing={1} alignItems="center">
          <Typography variant="body2">
            <code>{scimStatusData?.scimEndpoint}</code>
          </Typography>
          <CopyButton text={scimStatusData?.scimEndpoint} />
        </Stack>
      </Stack>
      <Typography variant="body2">
        {window.getCTTranslatedText("SCIMPrerequisiteDescription")}
      </Typography>

      <Stack direction={"column"} spacing={8} sx={{ width: "100%", pb: 10 }}>
        <IDPList />
        <IDPTokenManagement />
      </Stack>
    </Stack>
  );
};
