import { Box } from "@mui/material";
import {
  AssetCoreTagReadonlyProps,
  CoreTagsKeys,
  CoreTagsLabels,
} from "pages/assets/types";
import { AssetTagsIcon } from "../asset-metadata-wrapper/components/asset-tags-icon";
import { MetaDataItem } from "../asset-metadata-wrapper/components/metadata-item";

export function AssetCoreTag({
  asset,
  coreTagKey,
  index,
  isUserDefinedTagsExist,
}: AssetCoreTagReadonlyProps) {
  if (!asset?.assetId) {
    return <Box style={{ width: 70 }} />;
  }

  const icon: JSX.Element = (
    <AssetTagsIcon asset={asset} coreTagKey={coreTagKey} />
  );

  const coreTagLabel =
    CoreTagsLabels[coreTagKey as keyof typeof CoreTagsLabels] || coreTagKey;
  const coreTagValue = asset?.coreTags[coreTagKey];
  let coreTagLabelValue: string = "";
  if (
    coreTagKey === CoreTagsKeys.ClusterIdentifier &&
    asset?.clusterIdentifier !== undefined
  ) {
    coreTagLabelValue = asset.clusterIdentifier;
  }
  if (
    coreTagKey === CoreTagsKeys.ContainerNamespace &&
    asset?.containerNamespace !== undefined
  ) {
    coreTagLabelValue = asset?.containerNamespace;
  }
  const value = coreTagValue ?? coreTagLabelValue ?? "";
  return (
    <MetaDataItem
      label={coreTagLabel}
      value={value}
      icon={icon}
      isUserDefinedTagsExist={isUserDefinedTagsExist}
    />
  );
}
