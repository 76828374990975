import { Box, Stack, Typography } from "@mui/material";
import { PathColumnRenderProps } from "pages/paths/types";

export function PathLastObserved({ path }: PathColumnRenderProps) {
  if (!path.channelHash) {
    return <Box style={{ width: 70 }} />;
  }

  return (
    <Box style={{ width: "fit-content" }}>
      <Stack alignItems="left" justifyContent="center">
        <Typography noWrap variant="body2">
          {path.pathLastObserved}
        </Typography>
      </Stack>
    </Box>
  );
}
