import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MoreIcon from "@mui/icons-material/More";
import SupportIcon from "@mui/icons-material/Support";
import { Box, Divider, IconButton, Stack } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { CompanyLogo } from "assets/svgs";
import { Toolbar } from "common/atoms/toolbar";
import { MobileUserProfileMenu } from "modules/AppBar/components/MobileUserProfileMenu";
import { UserProfileMenu } from "modules/AppBar/components/UserProfileMenu";
import { MENU_ID, MOBILE_MENU_ID } from "modules/AppBar/constants";
import { CTBreadcrumbs } from "modules/NavigationLayout/components/breadcrumbs";
import { Notifications } from "modules/NavigationLayout/components/notifications";
import { Search } from "modules/search/components";
import { useSearchStore } from "modules/search/store";
import { useTenantProfileAPI } from "pages/organization/components/tenant-profile-editor/useTenantProfileAPI";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

interface StyledAppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open",
})<StyledAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

interface AppBarProps {
  onLogout: Function;
}

export const AppBar = ({ onLogout }: AppBarProps) => {
  const theme = useTheme();
  const location = useLocation();
  const search = useSearchStore(state => state.search);
  const setSearch = useSearchStore(state => state.setSearch);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const routesPaths = location?.pathname
    ?.split("/")
    ?.filter(route => route !== "");

  const activeSidebarTab = routesPaths?.[0]?.toLowerCase();
  const subRoute = routesPaths?.[1]?.toLowerCase() || undefined;

  const showSearchTopRoute = [
    "dashboard",
    "assets",
    "ports",
    "paths",
    "agents",
    "templates",
    "networks",
    "connectors",
    "clusters",
    "appliances",
    "visualizer",
    "monitoring",
    "tags",
    "users",
    "segment",
    "host-groups",
    "reports",
  ].includes(activeSidebarTab);

  const showSearchChildRoute = [
    "create",
    "edit-rule",
    "visualizer",
    "",
  ].includes(subRoute ?? "");

  const showSearch =
    showSearchTopRoute &&
    showSearchChildRoute &&
    !location.search.includes("tags");

  const { data: tenant } = useTenantProfileAPI();

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <StyledAppBar
        position="fixed"
        color={"primary"}
        elevation={1}
        sx={{
          backgroundColor: theme.palette.custom.appHeader,
          boxShadow: "unset",
        }}
      >
        <Toolbar
          sx={{
            paddingLeft: "0px !important",
          }}
        >
          <Stack direction="row" spacing={3.5} alignItems="center">
            <Stack ml={4}>
              <Link to="/">
                <Stack alignItems="end">
                  <Box
                    component="img"
                    src="/xShield.svg"
                    alt="ColorTokens Xshield"
                    width="24px"
                    height="24px"
                  />
                </Stack>
              </Link>
            </Stack>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: alpha(theme.palette.common.white, 0.2) }}
            />
            <Stack>
              <CTBreadcrumbs />
            </Stack>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          {showSearch && (
            <Box px={2}>
              <Search
                search={search}
                setSearch={setSearch}
                crossIconColor={alpha(theme.palette.common.white, 0.6)}
                placeholder={`${window.getCTTranslatedText("Search")}`}
              />
            </Box>
          )}

          <IconButton
            color="inherit"
            size="medium"
            aria-label="help & support"
            sx={{ mr: 1 }}
            onClick={() => {
              window?.productFruits?.api.button.showModal();
            }}
          >
            <SupportIcon />
          </IconButton>

          <Stack pr={2}>
            <Notifications />
          </Stack>

          <Stack pr={2}>
            <CompanyLogo tenant={tenant} />
          </Stack>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={MENU_ID}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircleOutlinedIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={MOBILE_MENU_ID}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </StyledAppBar>
      <MobileUserProfileMenu
        onProfileClick={handleProfileMenuOpen}
        anchorEl={mobileMoreAnchorEl}
        setAnchorEl={setMobileMoreAnchorEl}
      />
      <UserProfileMenu
        onLogout={onLogout}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};
