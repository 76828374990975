import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAssetStore } from "pages/assets/store";
import { useNetworkStore } from "pages/networks/store";
import { Network } from "pages/networks/types";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";

interface RemoveNetworksPayload {
  criteria?: string;
  namedNetworks: Array<string>;
  comment?: null | string;
}

const useRemoveNetworksAPI = ({
  policyId,
  page,
}: {
  policyId?: string;
  page: string;
}) => {
  let path = `assets/namednetworks`;
  let apiKey = "asset";
  if (page === "tags" && policyId) {
    path = `tagbasedpolicies/${policyId}/namednetworks`;
    apiKey = "tagbasedpolicy";
  }

  return useMutation<any, Error, any>([apiKey, path, "delete"]);
};

export interface NetworkRemoveProps {
  criteria: string;
  networks: Array<Network>;
  handleAllowRemove: Function;
  allowRemove: boolean;
  page?: string;
  policyId?: string;
  onConfirm: Function;
  comment?: null | string;
}

export function RemoveNetworksDialog({
  criteria,
  networks,
  handleAllowRemove,
  allowRemove,
  page = "assets",
  policyId,
  onConfirm,
  comment,
}: NetworkRemoveProps) {
  const notify = useEventSubscriptionStore(state => state.notify);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const requestAPIRefresh = useNetworkStore(state => state.requestAPIRefresh);
  const requestAssetAPIRefresh = useAssetStore(
    state => state.requestAPIRefresh
  );

  const requestPoliciesAPIRefresh = useTagPolicyStore(
    state => state.requestAPIRefresh
  );

  const removeNetworksAPI = useRemoveNetworksAPI({
    page: page,
    policyId: policyId,
  });

  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const updateNetworkDetails = () => {
    if ((criteria || policyId) && networks?.length > 0) {
      setLoading(true);
      let body: RemoveNetworksPayload = {
        criteria: criteria,
        namedNetworks: (networks ?? [])?.map(
          (network: Network) => network.namedNetworkId
        ),
        comment: comment,
      };

      if (page === "tags") {
        delete body["criteria"];
      }

      removeNetworksAPI.mutate(body, {
        onSuccess: response => {
          notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
            label: "RemoveNamedNetworkRequestSubmittedSuccessfully",
          });
          queryClient.invalidateQueries({
            queryKey: ["policy"],
          });
          queryClient.invalidateQueries({
            queryKey: ["asset"],
          });
          queryClient.invalidateQueries({
            queryKey: ["tagbasedpolicy"],
          });
          requestAPIRefresh();
          requestAssetAPIRefresh();
          requestPoliciesAPIRefresh();
          setTimeout(() => {
            handleAllowRemove(false);
            onConfirm();
            setLoading(false);
          }, 500);
        },
        onError: error => {
          setLoading(false);
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  };

  return (
    <Dialog
      open={allowRemove}
      onClose={() => handleAllowRemove(false)}
      aria-labelledby="remove-named-network"
    >
      <DialogTitle id="remove-named-network">
        {window.getCTTranslatedText(`Remove Named Network`)}
      </DialogTitle>
      <DialogContent>
        {window.getCTTranslatedText("removeNamedNetworkConfirmation", {
          page: window.getCTTranslatedText(
            page === "tags" ? "tagBasedPolicy" : "assets"
          ),
        })}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => handleAllowRemove(false)}
        >
          {window.getCTTranslatedText("Cancel")}
        </Button>
        <LoadingButton
          loading={removeNetworksAPI.isLoading || loading}
          onClick={updateNetworkDetails}
          autoFocus
          variant="contained"
          color={"error"}
          type="submit"
          disabled={!((criteria || policyId) && networks?.length > 0)}
        >
          {window.getCTTranslatedText("Remove")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
