import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import { renderProcessPlatformIcon } from "../agent-download-page/helpers";

interface SupportedVersionsDrawerProps {
  isOpen: boolean;
  title?: string;
  onCancel: () => void;
  filteredEntities: [string, string][];
  renderVersions: (entity: string) => React.ReactNode;
  rows: number;
}

export const SupportedVersionsDrawer: React.FC<
  SupportedVersionsDrawerProps
> = ({ isOpen, title, onCancel, filteredEntities, renderVersions, rows }) => {
  const theme = useTheme();
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onCancel}
      PaperProps={{
        sx: {
          padding: "0px",
          width: "40%",
          minWidth: "800px",
          height: "100%",
          overflow: "hidden",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <DialogTitle textAlign={"left"} marginBottom={5}>
        <Stack
          alignItems="flex-start"
          sx={{ position: "relative", width: "100%" }}
        >
          <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
            <IconButton
              size="medium"
              aria-label="close drawer"
              onClick={onCancel}
              sx={{ position: "absolute", right: "16px", zIndex: 2 }}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </Stack>

        {window.getCTTranslatedText(title ?? "")}
      </DialogTitle>
      <DialogContent sx={{ flex: 1, overflowX: "hidden" }}>
        <Stack direction="column" spacing={5}>
          {filteredEntities?.map(([entity, name], index) => (
            <Stack key={entity}>
              <Stack direction="row" alignItems="center">
                <Stack direction={"row"} minWidth={200} alignItems={"center"}>
                  {renderProcessPlatformIcon(
                    entity,
                    theme.palette.text.primary
                  )}
                  <Stack marginLeft={1} minWidth={"400"}>
                    <Typography variant="body1">{name}</Typography>
                  </Stack>
                </Stack>
                <Stack minWidth={"60%"} alignItems={"left"} marginLeft={1}>
                  {renderVersions(entity)}
                </Stack>
              </Stack>
              {index < rows && (
                <Divider variant="fullWidth" sx={{ paddingTop: 5 }} />
              )}
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          aria-label="close drawer"
          onClick={onCancel}
        >
          {window.getCTTranslatedText("Close")}
        </Button>
      </DialogActions>
    </Drawer>
  );
};
