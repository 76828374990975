import { AlertsColumnRenderProps } from "pages/monitoring/types";
import { Stack, Tooltip } from "@mui/material";
import { AssetSecurityLevelIcons } from "pages/assets/components/asset-security-level-icons";
import { SecurityLevels } from "pages/assets/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export const AlertSeverity = ({
  alert,
  viewOnly,
  severity,
}: AlertsColumnRenderProps) => {
  if (!severity) {
    return null;
  }
  const typeFacetName = "alertseverity";
  const typeOptionName = severity.toString();
  const typeTitle = alert.severity;

  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "space-evenly",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Stack direction="row">
        <Tooltip title={window.getCTTranslatedText(`${typeTitle}`)}>
          <CTDatagridButton
            asText={viewOnly}
            isDisabled={viewOnly}
            facetName={typeFacetName}
            facetOption={typeOptionName}
            stopPropagation={true}
            startIcon={
              <AssetSecurityLevelIcons
                securityLevel={severity.toLowerCase() as SecurityLevels}
              />
            }
          >
            {window.getCTTranslatedText(typeTitle)}
          </CTDatagridButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
