export const textToBase64 = (text: string) => window.btoa(text);

export const base64ToText = (text: string) => window.atob(text);

export const curlProxyDecodeURIComponent = (text: string) => {
  return decodeURIComponent(text);
};

export const curlProxyEncodeURIComponent = (text: string) => {
  return encodeURIComponent(text).replace(
    /[-_.!~*'()]/g,
    c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
  );
};
