import { Chip, Stack, Typography } from "@mui/material";

interface KeyValueChipProps {
  cKey: string;
  cValue?: string | number;
}

export function KeyValueChip({ cKey, cValue }: KeyValueChipProps) {
  return (
    <Chip
      sx={{ mr: 1, mt: 1 }}
      label={
        <Stack flexDirection={"row"} alignItems="center">
          <Typography variant="body2">{cKey}</Typography>
          <Typography variant="subtitle2" sx={{ ml: 2 }}>
            {cValue}
          </Typography>
        </Stack>
      }
    />
  );
}
