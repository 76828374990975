import { TipsAndUpdatesOutlined } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { RecommendationsButton } from "modules/recommendation-workflows";
import { PathRecommendationPageType } from "modules/recommendation-workflows/PathRecommendationDrawer";
import { ButtonVariant } from "modules/recommendation-workflows/RecommendationsButton";
import numeral from "numeral";

interface AttributesBlockProps {
  isLoading: boolean;
  title: string;
  count: number;
  canAssign: boolean;
  showAssigned: VoidFunction;
  assign: VoidFunction;
  btnTitle?: string;
  criteria?: string;
  recommend?: VoidFunction;
}

export const AssetAttributesBlock = ({
  isLoading,
  title,
  count,
  canAssign,
  showAssigned,
  assign,
  btnTitle,
  criteria,
}: AttributesBlockProps) => {
  return (
    <>
      <Box>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
          sx={{ width: "100%", minHeight: "36.5px" }}
        >
          <Stack direction={"row"} spacing={1} flex={1} alignItems={"center"}>
            <Typography variant="subtitle1">
              <b>{window.getCTTranslatedText(title)}</b>
            </Typography>
            {isLoading ? (
              <Stack
                sx={{ minWidth: 64, p: 0 }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress size={18} />
              </Stack>
            ) : (
              <Button
                variant="text"
                disabled={count === 0}
                onClick={showAssigned}
              >
                {numeral(count ?? 0).format("0,0")}
              </Button>
            )}
          </Stack>
          <Tooltip title="">
            <Stack direction={"row"}>
              {criteria && (
                <RecommendationsButton
                  criteria={criteria}
                  page={PathRecommendationPageType.Asset}
                  variant={ButtonVariant.OUTLINED}
                />
              )}
              {canAssign && (
                <Button variant="outlined" sx={{ ml: 2 }} onClick={assign}>
                  {window.getCTTranslatedText(btnTitle ?? "Assign")}
                </Button>
              )}
            </Stack>
          </Tooltip>
        </Stack>
      </Box>
    </>
  );
};

export const VisxAttributesBlock = ({
  isLoading,
  title,
  count,
  canAssign,
  showAssigned,
  assign,
  btnTitle,
  recommend,
}: AttributesBlockProps) => {
  const theme = useTheme();

  return (
    <>
      <Paper sx={{ width: "100%" }}>
        <Stack
          alignContent={"center"}
          justifyItems={"center"}
          justifyContent="space-between"
          sx={{
            width: "100%",
            background:
              theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, 0.04)"
                : "rgba(0, 0, 0, 0.04)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
          }}
          direction="row"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"center"}
            px={2}
            py={1}
          >
            <Typography variant="subtitle1">
              {window.getCTTranslatedText(title)}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignContent={"center"}
            justifyItems={"center"}
            justifyContent={"center"}
          >
            {canAssign && (
              <>
                {recommend && (
                  <Tooltip title={window.getCTTranslatedText("Recommend")}>
                    <IconButton size="small" onClick={recommend}>
                      <TipsAndUpdatesOutlined
                        fontSize="small"
                        color={"action"}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  title={window.getCTTranslatedText(
                    btnTitle ?? "Assign Templates"
                  )}
                >
                  <IconButton size="small" onClick={assign}>
                    <AddCircleOutlineIcon fontSize="small" color={"action"} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
        </Stack>
        <Stack sx={{ px: 2, py: 1 }}>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Stack direction={"row"} spacing={1} flex={1}>
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Button
                  variant="text"
                  disabled={count === 0}
                  onClick={showAssigned}
                  sx={{ minWidth: "unset" }}
                >
                  {numeral(count ?? 0).format("0,0")}
                </Button>
              )}
            </Stack>
            <Tooltip
              title={window.getCTTranslatedText(
                count === 0 ? "Nothing assigned" : "View assigned"
              )}
            >
              <Stack justifyItems={"flex-end"}>
                <Button
                  variant="text"
                  disabled={count === 0}
                  onClick={showAssigned}
                >
                  {window.getCTTranslatedText("View List")}
                </Button>
              </Stack>
            </Tooltip>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};
