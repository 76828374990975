import { Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCommonStore } from "common/store";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { parseErrorMessage } from "common/utils";
import {
  AlertFilter,
  AlertStatus,
} from "modules/NavigationLayout/components/notifications/types";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { ALL_TIME } from "modules/facets/constants";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
  ALERT_TIME_FILTER_OPTIONS,
  FACET_ORDER,
} from "pages/monitoring/constants";
import { useAlertsStore } from "pages/monitoring/store";
import { CTAlert } from "pages/monitoring/types";
import { formatCoreTagsAsset } from "pages/paths/components/path-data-grid/helpers/columnHelpers";
import { useAlertConfigAPI } from "pages/settings/components/alert-config/hooks/useAlertConfig";
import { useEffect, useState } from "react";
import { AlertDataGrid } from "../alert-data-grid";
import { ALERT_DATA_GRID_ID } from "../alert-data-grid/AlertDataGrid";
import { useAlertsAPI } from "../alerts-action/hooks";
import { EmptyAlertPage } from "../empty-alert-page/EmptyAlertPage";

interface CreationTimeFilterType {
  startTime: string;
  endTime: string;
}

interface AlertsTabProps {
  creationFilter: CreationTimeFilterType;
  setConfiguredRules: (configRule: number) => void;
}

export function AlertsTab({
  creationFilter,
  setConfiguredRules,
}: AlertsTabProps) {
  const theme = useTheme();
  const selectedTime = useUserPreferencesStore(state => state.timeFilter);
  const [showEmptyPage, setShowEmptyPage] = useState<boolean>(false);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const additionalCriteria =
    creationFilter.startTime &&
    creationFilter.endTime &&
    selectedTime !== ALL_TIME
      ? `alertlastseen > '${creationFilter.startTime}' AND alertlastseen < '${creationFilter.endTime}'`
      : "";

  const updateFacetOption = useCommonStore(state => state.updateFacetOption);
  const setExternalCriteria = useCommonStore(
    state => state.setExternalCriteria
  );

  useEffect(() => {
    updateFacetOption({
      facetName: AlertFilter.filters,
      optionName: AlertStatus.PENDING,
      value: true,
    });

    return () =>
      updateFacetOption({
        facetName: AlertFilter.filters,
        optionName: AlertStatus.PENDING,
        value: false,
      });
  }, [updateFacetOption]);

  const coreResponse = useCore<CTAlert>({
    useStore: useAlertsStore,
    facetGroupInfo: FACET_ORDER,
    scope: Scope.Alert,
    dataMapper: alert => {
      if (alert?.asset) {
        alert.asset.coreTags = formatCoreTagsAsset(alert.asset);
      }
      alert.message = alert.details;
      alert.creationTime = alert.lastSeen;
      return alert;
    },
    useApi: useAlertsAPI,
    pageSize: 100,
    defaultSortOrder: [
      { field: "alertseverity", order: "desc" },
      { field: "alertlastseen", order: "desc" },
    ],
    additionalCriteria: additionalCriteria,
    isNewCsvAPIVersion: true,
  });

  useEffect(() => {
    setExternalCriteria(additionalCriteria);

    return () => setExternalCriteria("*");
  }, [additionalCriteria, setExternalCriteria]);

  const { mutate: getAlertMutate } = useAlertConfigAPI();
  useEffect(() => {
    const body = {
      criteria: `'alertrulestatus' in ('enabled')`,
      pagination: {
        offset: 0,
        limit: 1,
      },
    };

    getAlertMutate(body, {
      onSuccess: response => {
        setConfiguredRules(response?.metadata?.total ?? 0);
        if (coreResponse?.rows?.length !== undefined) {
          const hasData = coreResponse?.rows?.length > 0;
          const hasMetadata = response?.metadata?.total > 0;

          setShowEmptyPage(!(hasData || hasMetadata));
        }
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  }, [
    getAlertMutate,
    setSnackbar,
    coreResponse?.rows?.length,
    setConfiguredRules,
  ]);

  const rowCount =
    coreResponse.rowCount > 0 &&
    coreResponse.rowCount === coreResponse.maxRowCount
      ? coreResponse.rowCount - 1
      : coreResponse.rowCount;

  return (
    <>
      {showEmptyPage ? (
        <EmptyAlertPage />
      ) : (
        <Stack direction={"column"} sx={{ height: "100%", width: "100%" }}>
          <Stack direction={"row"}>
            <Stack sx={{ flex: 1, py: 2 }}>
              <FacetOpenCloseController
                facetsOpen={coreResponse.facetsOpen}
                setFacetsOpen={coreResponse.setFacetsOpen}
                timeFilterConfig={{
                  shouldShowTimeFilter: true,
                  options: ALERT_TIME_FILTER_OPTIONS,
                }}
              >
                <FacetControllerGroup
                  config={coreResponse?.facetConfig}
                  value={coreResponse.facetState}
                  onChange={coreResponse.updateFacet}
                />
              </FacetOpenCloseController>
            </Stack>
          </Stack>
          <Paper
            sx={{
              transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              flex: 1,
              overflow: "hidden",
            }}
            id={ALERT_DATA_GRID_ID}
          >
            <AlertDataGrid
              initialState={{
                sorting: {
                  sortModel: [
                    { field: "alertseverity", sort: "desc" },
                    { field: "alertlastseen", sort: "desc" },
                  ],
                },
              }}
              rows={coreResponse.rows?.map((obj, index) => ({
                ...obj,
                id: obj.lastSeen + index,
              }))}
              originalRowCount={coreResponse.rowCount}
              checkboxSelection
              metadata={coreResponse.metadata}
              rowCount={rowCount}
              maxRowCount={coreResponse.maxRowCount}
              mutation={coreResponse.mutation}
              onPageChange={coreResponse.onPageChange}
              page={coreResponse.page}
              pageSize={coreResponse.pageSize}
              onPageSizeChange={coreResponse.onPageSizeChange}
              onSortChange={coreResponse.onSortChange}
              triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
              getExportStatus={coreResponse?.getExportStatus}
              getUrlToDownload={coreResponse?.getUrlToDownload}
              resetDownloadUrl={coreResponse?.resetDownloadUrl}
              rawData={coreResponse?.rawData}
            />
          </Paper>
        </Stack>
      )}
    </>
  );
}
