import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTMultiSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-multi-select/CTMultiSelectDropDown";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import React from "react";

interface Option {
  value: string;
  label: string;
}

type SectionProps = {
  title: string;
  itemList: { ctField: string; integrationField: string[] }[];
  itemOptionsList: Option[] | string[];
  addNewBtnDisable?: boolean;
  addNewItem: () => void;
  removeItem: (index: number) => void;
  handleItemChange: (event: TextFieldUpdate, index: number) => void;
  handleServiceNowItemChange: (event: any, index?: number) => void;
  servicenowIdentifierList: any;
  maxValueSelect?: number;
};

export const IntegrationMappingComponent: React.FC<SectionProps> = ({
  title,
  itemList,
  itemOptionsList,
  addNewBtnDisable = false,
  addNewItem,
  removeItem,
  handleItemChange,
  handleServiceNowItemChange,
  servicenowIdentifierList,
  maxValueSelect,
}) => {
  const rulesSectionWrapper = {
    py: 0,
    px: 3,
    borderRadius: 1,
    width: "49%",
  };

  const rulesTitleWrapper = {
    py: 0,
    px: 0,
    borderRadius: 1,
  };

  const helpText = {
    lineHeight: "20px",
    fontSize: "12px",
  };

  return (
    <Box style={{ minWidth: "100%" }} sx={{ mb: 4 }}>
      <Paper sx={{ minWidth: "100%" }}>
        <Stack alignItems="flex-start" justifyContent="flex-start" spacing={0}>
          <Stack
            alignItems={"flex-start"}
            justifyContent="flex-start"
            spacing={0}
            style={{ position: "relative", height: "100%" }}
            sx={{
              ...rulesTitleWrapper,
              width: "100%",
              background: theme =>
                theme.palette.mode === "dark"
                  ? theme.palette.background.default
                  : "#f9f9f9",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              sx={{
                width: "100%",
              }}
            >
              <Stack
                alignItems="center"
                sx={{
                  my: 2,
                  px: 3,
                }}
              >
                <Typography variant="body1">
                  {window.getCTTranslatedText(title)}
                </Typography>
              </Stack>
              <Stack alignItems="center" sx={{ px: 3 }}>
                {itemList.length !== itemOptionsList.length && (
                  <Button
                    variant="text"
                    startIcon={<AddCircleOutlineIcon />}
                    color="primary"
                    onClick={addNewItem}
                    disabled={addNewBtnDisable}
                  >
                    {window.getCTTranslatedText(title)}
                  </Button>
                )}
              </Stack>
            </Stack>
            {itemList.map((item, index) => (
              <Stack
                key={`attributes-${index}-${title}-${item.ctField}`}
                sx={{
                  width: "100%",
                  px: 0,
                  pt: 1,
                  pb: 1,
                  background: theme =>
                    theme.palette.mode === "dark" ? "#ffffff12" : "#fff",
                }}
              >
                <Stack>
                  <Stack
                    key={`${title}-${index}-${item.ctField}`}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    spacing={4}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        width: "100%",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Stack
                        sx={{
                          ...rulesSectionWrapper,
                          width: "48%",
                          mt: "8px",
                        }}
                      >
                        <CTSelectDropDown
                          field={title}
                          displayName={`${title} in Xshield`}
                          selectedValue={item.ctField}
                          handleUpdate={(event: TextFieldUpdate) =>
                            handleItemChange(event, index)
                          }
                          data={itemOptionsList}
                          required={false}
                          textTransform="none"
                          showLabel={true}
                        ></CTSelectDropDown>
                      </Stack>

                      <Stack
                        sx={{
                          ...rulesSectionWrapper,
                          width: "48%",
                          mx: "0",
                        }}
                      >
                        {item.ctField.toLowerCase() === "application" && (
                          <Stack
                            sx={{
                              mt: "8px",
                            }}
                          >
                            <CTInputField
                              field={`${title} in ServiceNow`}
                              displayName={`${title} in ServiceNow`}
                              value={"application"}
                              disabled={true}
                              handleUpdate={(event: {
                                field: string;
                                value: string;
                              }) => handleServiceNowItemChange(event, index)}
                            />
                          </Stack>
                        )}
                        {item.ctField.toLowerCase() !== "application" && (
                          <>
                            <CTMultiSelectDropDown
                              field={`${title} in ServiceNow`}
                              displayName={`${title} in ServiceNow`}
                              multi={true}
                              selectedValue={item.integrationField}
                              handleUpdate={(event: {
                                field: string;
                                value: string;
                              }) => handleServiceNowItemChange(event, index)}
                              data={servicenowIdentifierList}
                              required={false}
                              textTransform="none"
                              maxValueSelect={maxValueSelect}
                            ></CTMultiSelectDropDown>
                            <Typography style={helpText} sx={{ my: 0 }}>
                              {window.getCTTranslatedText(
                                "integrationDropdownHelperText",
                                {
                                  maxValueSelect,
                                  title,
                                }
                              )}
                            </Typography>
                          </>
                        )}
                      </Stack>

                      <Stack alignItems="center" sx={{ mt: 6 }}>
                        <IconButton
                          sx={{ my: 0 }}
                          size="medium"
                          aria-label={`remove ${title}`}
                          onClick={() => removeItem(index)}
                        >
                          <RemoveCircleOutlineIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};
