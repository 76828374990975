import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Alert,
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { CircularCenteredLoader } from "common/atoms/loader";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import React, { useEffect, useRef, useState } from "react";
import { FormFileUploader } from "./FormFileField";
import {
  Field,
  FieldType,
  FormFieldChangeEvent,
  FormProps,
  TextFieldProps,
  TextFieldsValues,
} from "./type";

const DialogContentPadding = 0;
const rowGap = 2;
const columnGap = 4;

const renderLabel = ({ field }: { field: Field }) => {
  if (field.information) {
    return (
      <Stack direction="row">
        <Box>{window.getCTTranslatedText(field.displayName)}</Box>
        <Tooltip title={window.getCTTranslatedText(field.information)}>
          <InfoOutlinedIcon fontSize="small" sx={{ ml: 1 }} />
        </Tooltip>
      </Stack>
    );
  } else {
    return <>{window.getCTTranslatedText(field.displayName)}</>;
  }
};

const FormTextField = ({
  field,
  value,
  handleChange,
  showPassword = {},
  handlePasswordVisibility = () => {}, // Provide a default function
  sx,
}: TextFieldProps) => {
  let fieldType = "text";
  if (field.type === FieldType.PASSWORD && !showPassword[field.key]) {
    fieldType = "password";
  } else if (field.type === FieldType.INTEGER) {
    fieldType = "number";
  }

  const numberFieldInputProps =
    field.type === FieldType.INTEGER ? { inputProps: { min: 0 } } : undefined;

  return (
    <Grid item xs={12}>
      <TextField
        variant="standard"
        label={renderLabel({ field })}
        name={field.key}
        value={value}
        fullWidth
        disabled={field.readOnly}
        placeholder={window.getCTTranslatedText(field.placeholder ?? "")}
        onChange={handleChange}
        required={field.required}
        type={fieldType}
        InputLabelProps={{
          shrink: true,
          style: { display: "flex" },
        }}
        helperText={field.description}
        sx={{ ...sx }}
        InputProps={
          field.type === "password"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handlePasswordVisibility(field.key)}
                      edge="end"
                      size="small"
                    >
                      {showPassword[field.key] ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : numberFieldInputProps
        }
      />
    </Grid>
  );
};

const FormDropdown = ({ field, value, handleChange, sx }: TextFieldProps) => {
  return (
    <Grid item xs={12}>
      <TextField
        variant="standard"
        label={renderLabel({ field })}
        name={field.key}
        disabled={field.readOnly}
        fullWidth
        select={Boolean(field?.possibleValues?.length)}
        value={value}
        helperText={field.description}
        onChange={handleChange}
        type={"text"}
        sx={{ ...sx }}
      >
        {field?.possibleValues?.length &&
          field?.possibleValues?.map(possibleField => (
            <MenuItem
              key={possibleField.key}
              value={possibleField.defaultValue}
            >
              {window.getCTTranslatedText(possibleField.displayName)}
            </MenuItem>
          ))}
      </TextField>
    </Grid>
  );
};

const FormCheckbox = ({ field, value, handleChange, sx }: TextFieldProps) => {
  if (!value) {
    value = [];
  } else {
    value = value.split(",");
  }

  const handleCheckboxChange = (val: string) => {
    let updatedValue: string[] = [...value];

    const index = updatedValue.indexOf(val);
    if (index !== -1) {
      updatedValue.splice(index, 1);
    } else {
      updatedValue.push(val);
    }

    let body: FormFieldChangeEvent = {
      target: { name: field.key, value: updatedValue.join(",") },
    };
    handleChange(body);
  };
  return (
    <Grid sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
      <Grid container>
        <Grid sx={{ ml: 2, mr: 3, mt: 1 }} item>
          {window.getCTTranslatedText(field.displayName)}
          {field.required && <span>*</span>}
        </Grid>
        {field?.possibleValues?.map(possibleField => (
          <FormControlLabel
            label={window.getCTTranslatedText(possibleField.displayName)}
            control={
              <Grid item key={possibleField.key}>
                <Checkbox
                  checked={value.includes(possibleField.defaultValue)}
                  onChange={() =>
                    handleCheckboxChange(possibleField.defaultValue)
                  }
                  disabled={field.readOnly}
                  sx={{ ...sx }}
                  value={possibleField.defaultValue}
                />
              </Grid>
            }
          />
        ))}
      </Grid>
    </Grid>
  );
};

const renderSection = (field: Field, isInsideSection: boolean) => {
  return (
    <>
      <Stack direction="row" alignItems="flex-end">
        <Typography
          mt={!field.displayName ? 0 : isInsideSection ? 6.5 : 3}
          variant="subtitle1"
          sx={{
            fontFamily: theme => theme.typography.body2.fontFamily,
            ml: -Math.abs(DialogContentPadding),
          }}
        >
          {capitalizeFirstLetter(window.getCTTranslatedText(field.displayName))}
        </Typography>
        {field.information && (
          <Tooltip title={window.getCTTranslatedText(field?.information)}>
            <InfoOutlinedIcon
              fontSize="small"
              color="action"
              sx={{ ml: 1, mb: "4px" }}
            />
          </Tooltip>
        )}
      </Stack>
      <Typography variant="body2" sx={{ ml: -Math.abs(DialogContentPadding) }}>
        {window.getCTTranslatedText(field?.description ?? "")}
      </Typography>
    </>
  );
};

interface FormFieldsProps {
  formConfig: Field[];
  textFieldsValues: { [key: string]: string };
  handleChange: (event: FormFieldChangeEvent) => void;
  showPassword?: { [key: string]: boolean };
  handlePasswordVisibility?: (fieldName: string) => void;
  isDrawer: boolean;
  isSortable: boolean;
  isInsideSection?: boolean;
}

const renderFormFields = ({
  formConfig,
  textFieldsValues,
  handleChange,
  showPassword = {},
  handlePasswordVisibility = () => {}, // Provide a default function
  isDrawer,
  isInsideSection = true,
  isSortable,
}: FormFieldsProps) => {
  const sortedFormConfig = isSortable
    ? formConfig?.sort((a, b) => a.displayName.localeCompare(b.displayName))
    : formConfig;

  return (
    <>
      {sortedFormConfig?.map(field => (
        <React.Fragment key={field.key}>
          {field.type !== FieldType.MAPPING && (
            <>
              {field.type === FieldType.SECTION && (
                <Grid sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                  {renderSection(field, isInsideSection)}
                </Grid>
              )}

              {(field?.children?.length ?? 0) > 0 &&
                renderFormFields({
                  formConfig: field?.children ?? [],
                  textFieldsValues,
                  handleChange,
                  showPassword,
                  handlePasswordVisibility,
                  isDrawer,
                  isInsideSection: false,
                  isSortable,
                })}

              {!field?.children?.length &&
                !field?.possibleValues?.length &&
                field.type !== FieldType.STATIC &&
                field.type !== FieldType.FILE && (
                  <FormTextField
                    field={field}
                    value={textFieldsValues[field.key] ?? ""}
                    handleChange={handleChange}
                    showPassword={showPassword}
                    handlePasswordVisibility={handlePasswordVisibility}
                    isDrawer={isDrawer}
                    sx={{
                      ml:
                        isInsideSection && isDrawer
                          ? -Math.abs(DialogContentPadding)
                          : 0,
                    }}
                  />
                )}
              {!field?.children?.length &&
                !field?.possibleValues?.length &&
                field.type === FieldType.FILE && (
                  <FormFileUploader
                    field={field}
                    fileName={"credentials.json"}
                    value={textFieldsValues[field.key]}
                    handleChange={handleChange}
                    isDrawer={isDrawer}
                    maxFileSizeInBytes={20480}
                  />
                )}

              {(field?.possibleValues?.length ?? 0) > 0 &&
                field.type === FieldType.CHECKBOX && (
                  <>
                    <Grid sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                      <FormCheckbox
                        key={field.key}
                        field={field}
                        value={
                          textFieldsValues[field.key] ??
                          field.defaultValue ??
                          ""
                        }
                        handleChange={handleChange}
                        isDrawer={isDrawer}
                        sx={{
                          ml:
                            isInsideSection && isDrawer
                              ? -Math.abs(DialogContentPadding)
                              : 0,
                        }}
                      />
                      {(
                        textFieldsValues[field.key] ??
                        field.defaultValue ??
                        ""
                      ).includes("flows") && (
                        <Grid sx={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                          <Alert sx={{ width: "100%", mt: 2 }} severity="info">
                            {window.getCTTranslatedText(
                              "processingFlowDataMessage"
                            )}
                          </Alert>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
              {(field?.possibleValues?.length ?? 0) > 0 &&
                field.type === FieldType.DROPDOWN && (
                  <FormDropdown
                    key={field.key}
                    field={field}
                    value={
                      textFieldsValues[field.key] ?? field.defaultValue ?? ""
                    }
                    handleChange={handleChange}
                    isDrawer={isDrawer}
                    sx={{
                      ml:
                        isInsideSection && isDrawer
                          ? -Math.abs(DialogContentPadding)
                          : 0,
                    }}
                  />
                )}

              {field?.possibleValues?.find(possibleField => {
                return (
                  possibleField.key === textFieldsValues[field.key] &&
                  possibleField.children
                );
              }) &&
                renderFormFields({
                  formConfig:
                    field?.possibleValues?.find(
                      possibleField =>
                        possibleField.key === textFieldsValues[field.key] &&
                        possibleField.children
                    )?.children ?? [],
                  textFieldsValues,
                  handleChange,
                  showPassword,
                  handlePasswordVisibility,
                  isDrawer,
                  isInsideSection: false,
                  isSortable,
                })}
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
export const DRAWER_TITLES = {
  CONFIGURE: "configureIntegration",
  CONFIGURE_AND_INSTALL: "configure&InstallIntegration",
};
export const FormFields = ({
  setTextFieldsValues,
  textFieldsValues,
  isLoading,
  formConfig,
  isDrawer = false,
  dialogTitle,
  titleTemplate = DRAWER_TITLES.CONFIGURE,
  onClose,
  formExtrasAfter,
  drawerToolbar,
  formExtrasBefore,
  isSortable = false,
}: FormProps) => {
  const [initialShowPasswordState, setInitialShowPasswordState] = useState(
    () => {
      const initialShowPasswordState: { [key: string]: boolean } = {};
      formConfig?.forEach(field => {
        if (field.type === FieldType.PASSWORD) {
          initialShowPasswordState[field.key] = false;
        }
      });
      return initialShowPasswordState;
    }
  );
  const handleChange = (event: FormFieldChangeEvent) => {
    const { name, value } = event.target;
    setTextFieldsValues({ ...textFieldsValues, [name]: value });
  };

  const handlePasswordVisibility = (fieldName: string) => {
    setInitialShowPasswordState(prevShowPassword => ({
      ...prevShowPassword,
      [fieldName]: !prevShowPassword[fieldName],
    }));
  };

  const isInitialStateSetRef = useRef(false);

  useEffect(() => {
    const shouldSetInitialState =
      formConfig?.length && !isInitialStateSetRef.current;

    if (shouldSetInitialState) {
      const iState: { [key: string]: string | undefined } = {};
      setTextFieldsValues(prev => {
        textValuesUpdater(prev, iState, formConfig);
        return iState;
      });
      isInitialStateSetRef.current = true;
    }
  }, [formConfig, setTextFieldsValues]);

  if (isLoading) {
    return <CircularCenteredLoader />;
  }

  return formConfig?.length && isDrawer ? (
    <Box onClick={e => e.stopPropagation()}>
      <Drawer
        open={Boolean(isDrawer)}
        onClose={onClose}
        anchor="right"
        PaperProps={{
          sx: {
            width: "70%",
            maxwidth: "1000px",
            minWidth: "600px",
            pt: 11,
          },
          elevation: 1,
        }}
      >
        <DialogTitle sx={{ pb: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {window.getCTTranslatedText(titleTemplate, {
              title: dialogTitle,
            })}
            <Tooltip title="Close Drawer">
              <IconButton
                size="large"
                aria-label="close drawer"
                onClick={onClose}
                sx={{
                  zIndex: 2,
                }}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ width: "100%" }}>
          <Box>{formExtrasBefore?.()}</Box>
          <Grid
            container
            columnGap={columnGap}
            rowGap={rowGap}
            sx={{ pl: DialogContentPadding }}
            display="grid"
            gridTemplateColumns="1fr 1fr"
          >
            {renderFormFields({
              formConfig: formConfig ?? [],
              textFieldsValues,
              handleChange,
              showPassword: initialShowPasswordState, // Use the initial state
              handlePasswordVisibility,
              isDrawer,
              isSortable,
            })}
          </Grid>
          <Box>{formExtrasAfter?.()}</Box>
        </DialogContent>

        <DialogActions>{drawerToolbar?.()}</DialogActions>
      </Drawer>
    </Box>
  ) : (
    <>
      <Grid
        display="grid"
        container
        columnGap={columnGap}
        rowGap={rowGap}
        gridTemplateColumns="1fr 1fr"
        sx={{ position: "relative" }}
      >
        {renderFormFields({
          formConfig: formConfig ?? [],
          textFieldsValues,
          handleChange,
          showPassword: initialShowPasswordState, // Use the initial state
          handlePasswordVisibility,
          isDrawer,
          isSortable,
        })}
      </Grid>
    </>
  );
};

const textValuesUpdater = (
  prevState: TextFieldsValues,
  newState: TextFieldsValues,
  fields?: Field[]
) => {
  fields?.forEach(field => {
    if (field.type !== FieldType.MAPPING) {
      if (field.type !== FieldType.SECTION) {
        if (field.defaultValue !== "") {
          newState[field.key] = field.defaultValue;
        }

        if (prevState[field.key]) {
          newState[field.key] = prevState[field.key];
        }
      }

      if (field?.children?.length) {
        textValuesUpdater(prevState, newState, field?.children);
      }

      if (field?.possibleValues?.length) {
        textValuesUpdater(prevState, newState, field?.possibleValues);
      }
    }
  });
};
