import {
  Agent,
  AgentCommands,
  AgentStatusType,
  PendingCommands,
  AgentType,
} from "pages/agents/types";
import {
  AgentActionStatusInfo,
  AgentDebugLogStates,
  AgentDiagnosticSupportedDevVersion,
  AgentDiagnosticSupportedMinVersion,
  AgentDecommissionSupportedDevVersion,
  AgentDecommissionSupportedMinVersion,
  AgentDebugLogSupportedDevVersion,
  AgentDebugLogSupportedMinVersion,
  AgentTrafficConfigSupportedDevVersion,
  AgentTrafficConfigSupportedMinVersion,
  AgentNsTrafficConfigSupportedDevVersion,
  AgentNsTrafficConfigSupportedMinVersion,
} from "../constants";
import * as semver from "semver";
import { Asset } from "pages/assets/types";
import { AssetType } from "pages/assets/types";

export const compareVersions = (
  version1: string,
  version2: string
): boolean => {
  const v1 = semver.coerce(version1) ?? "0.0.0";
  const v2 = semver.coerce(version2) ?? "0.0.0";
  return semver.gte(v1, v2);
};

export const isUpgradeEnabled = (agent: Agent) =>
  agent.agentUpgradeStatus === AgentActionStatusInfo.Synchronized &&
  agent.agentUpgradeAvailable &&
  Boolean(agent.agentId);

export const isRestartEnabled = (agent: Agent) => {
  return (
    agent.agentRestartStatus === AgentActionStatusInfo.Synchronized &&
    Boolean(agent.agentId)
  );
};
export const isDiagnosticsRunning = (agent: Agent) => {
  return (
    agent.agentDiagnosticsStatus === AgentActionStatusInfo.Pending &&
    Boolean(agent.agentId)
  );
};

export const isDiagnosticsSupported = (agent: Agent) => {
  const currentVersion = agent?.currentVersion;
  if (currentVersion === AgentDiagnosticSupportedDevVersion) {
    return true;
  }
  return compareVersions(currentVersion, AgentDiagnosticSupportedMinVersion);
};

export const isDiagnosticsAllowed = (agent: Agent) => {
  return (
    isDiagnosticsSupported(agent) &&
    agent?.agentStatus === AgentStatusType.Active
  );
};

export const isDecommissionEnabled = (agent: Agent) => {
  return (
    agent.agentDecommissionStatus === AgentActionStatusInfo.Synchronized &&
    Boolean(agent.agentId)
  );
};

export const isDecommissionSupported = (agent: Agent) => {
  if (agent.agentStatus === AgentStatusType.Absent) {
    return true;
  }
  const currentVersion = agent?.currentVersion;
  if (currentVersion === AgentDecommissionSupportedDevVersion) {
    return true;
  }
  return compareVersions(currentVersion, AgentDecommissionSupportedMinVersion);
};

export const isDebugLogEnabled = (agent: Agent) => {
  if (agent.debugLogState === AgentDebugLogStates.Enabled) {
    return true;
  }
  return false;
};
export const isDebugLogRunning = (agent: Agent) => {
  return (
    agent.agentDebugLogStatus === AgentActionStatusInfo.Pending &&
    Boolean(agent.agentId)
  );
};

export const isDebugLogSupported = (agent: Agent) => {
  if (agent.agentType === AgentType.CT_LEGACY_AGENT) {
    return false;
  }
  if (agent.agentStatus === AgentStatusType.Absent) {
    return false;
  }
  const currentVersion = agent?.currentVersion;
  if (currentVersion === AgentDebugLogSupportedDevVersion) {
    return true;
  }
  return compareVersions(currentVersion, AgentDebugLogSupportedMinVersion);
};

export const isTrafficConfigurationSupported = (agent: Agent) => {
  if (agent?.assetType !== AssetType.Endpoint) {
    return false;
  }
  const currentVersion = agent?.currentVersion;
  if (currentVersion === AgentTrafficConfigSupportedDevVersion) {
    return true;
  }
  return compareVersions(currentVersion, AgentTrafficConfigSupportedMinVersion);
};

export const isNorthSouthTrafficConfigurationSupported = (agent: Agent) => {
  if (agent?.assetType !== AssetType.Server) {
    return false;
  }
  const currentVersion = agent?.currentVersion;
  if (currentVersion === AgentNsTrafficConfigSupportedDevVersion) {
    return true;
  }
  return isHigherVersion(
    AgentNsTrafficConfigSupportedMinVersion,
    currentVersion
  );
};

export const getAgentStatus = ({
  agent,
  agentCommand,
}: {
  agent: Agent;
  agentCommand: AgentCommands;
}) => {
  const isAgentStatusPending = (agent?.pendingCommands || []).some(
    (command: PendingCommands) => {
      return command.action === agentCommand;
    }
  );
  return isAgentStatusPending
    ? AgentActionStatusInfo.Pending
    : AgentActionStatusInfo.Synchronized;
};

export const getAssetType = (asset: Asset) => {
  if (asset) {
    return asset?.type ?? "";
  }
  return "";
};

export const isHigherVersion = (
  version1: string,
  version2: string
): boolean => {
  const v1 = semver.coerce(version1) ?? "0.0.0";
  const v2 = semver.coerce(version2) ?? "0.0.0";
  return semver.lt(v1, v2);
};

export const isHigherorEqualVersions = (
  version1: string,
  version2: string
): boolean => {
  const v1 = semver.coerce(version1) ?? "0.0.0";
  const v2 = semver.coerce(version2) ?? "0.0.0";
  return semver.gt(v1, v2);
};
