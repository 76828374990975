export const API_URL = process.env.REACT_APP_API_SERVER_URL;

export const WORK_REQUEST_UPDATED_EVENT = "workRequestUpdated";

export const EXTENDED_TIMEOUT_API_PATH_LIST = [
  "synchronize",
  "firewallchanges",
  "zerotrust",
  "templates",
];

export const EXTENDED_TIMEOUT = 1000 * 60 * 2;
