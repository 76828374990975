import { Box, Stack, Typography } from "@mui/material";
import { TagPolicy } from "../../types";
import { CopyButton } from "common/atoms/copy-button";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";

export function TagPolicyName({ policy }: { policy: TagPolicy }) {
  const { isFeatureEnabled: isSegmentsEnabled } = useFeatureFlagControl(
    FEATURES.SEGMENTS
  );

  if (!policy.tagBasedPolicyId || !policy.tagBasedPolicyName) {
    return <Box style={{ minWidth: 120, minHeight: 45 }} />;
  }

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ width: "100%" }}
    >
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Typography
          sx={{
            width: "100%",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
          variant="body2"
        >
          {policy?.tagBasedPolicyName}
        </Typography>
        {!isSegmentsEnabled && (
          <Typography
            variant="caption"
            sx={{
              color: theme => theme.palette.text.secondary,
              width: "100%",
              maxWidth: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {policy?.description}
          </Typography>
        )}
      </Box>
      <Stack sx={{ ml: 2 }}>
        <CopyButton text={policy?.tagBasedPolicyName ?? ""} />
      </Stack>
    </Stack>
  );
}
