import { PRODUCT_NAME } from "common/constants/product";
import { Tenant } from "modules/AppBar/types";

export function getProductTenants(allTenants: Array<Tenant>) {
  return allTenants.filter(ten => {
    for (let product of ten.products) {
      if (product.productName === PRODUCT_NAME) {
        return ten;
      }
    }
    return false;
  });
}
