import { Stack, Tooltip } from "@mui/material";
import { UserGroup } from "pages/users-segmentation/components/user-groups/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";

export interface UserGroupColumnRenderProps {
  readonly userGroup: UserGroup;
  viewOnly?: boolean;
}

export function UserGroupSourceIDP({
  userGroup,
  viewOnly,
}: UserGroupColumnRenderProps) {
  const facetName = "idpname";
  const optionName = userGroup?.idpName?.toString();
  const name = userGroup.idpName;

  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "space-evenly",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Stack direction="row">
        <Tooltip title={`${name}`}>
          <CTDatagridButton
            asText={viewOnly === true}
            isDisabled={viewOnly === true}
            facetName={facetName}
            facetOption={optionName ?? ""}
          >
            {capitalizeFirstLetter(name)}
          </CTDatagridButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
