import { Box, IconButton, Tooltip } from "@mui/material";
import { AssetColumnRenderProps, SecurityLevels } from "pages/assets/types";
import { useCommonStore } from "common/store";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { getColor } from "common/constants/colors";

export function AssetPathLimitReachedIcon({ asset }: AssetColumnRenderProps) {
  const facetState = useCommonStore(state => state.facets);
  const updateFacetOption = useCommonStore(state => state.updateFacetOption);
  const isSelected = facetState
    ?.get("newpathprocessingstopped")
    ?.get(Boolean(asset?.newPathProcessingStopped)?.toString())?.isSelected;

  const updateFacetOptions = () => {
    updateFacetOption({
      facetName: "newpathprocessingstopped",
      optionName: Boolean(asset?.newPathProcessingStopped)?.toString(),
      value: !isSelected,
    });
  };

  if (!asset?.assetId || !asset?.newPathProcessingStopped) {
    return <Box style={{ width: 120 }} />;
  }

  return (
    <>
      <Tooltip
        title={
          window.getCTTranslatedText("singleAsset") +
          window.getCTTranslatedText("assetDetailBannerText")
        }
      >
        <IconButton
          size="small"
          sx={{
            border: theme => `1px solid ${theme.palette.primary.main}`,
          }}
          onClick={updateFacetOptions}
        >
          <WarningAmberOutlinedIcon
            style={{
              color: getColor(SecurityLevels.Medium),
              width: "18px",
              height: "18px",
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  );
}
