import { WorkRequestAction } from "modules/PendingWorkRequestsDrawer/types";
import { Operator } from "modules/facets/types";
import { RESOURCE_CARDS_NAMES } from "pages/Integration/components/resource-count-cards/constants";
import { SCIM_STATUS_ENUM } from "pages/Integration/components/scim-management-config/constants";
import { Integration } from "pages/Integration/components/serviceNow-config/type";
import { AgentStatusType, NsTrafficStatusType } from "pages/agents/types";
import { DeviceTypes } from "pages/appliance-detail/types";
import {
  AsymmetricModeOptions,
  AuthenticationOptions,
  ConfigStatusMap,
  DHCPModes,
  DHCPOptions,
  FailOpenOptions,
  HAModes,
  NetworkInterfaces,
  OptInOptions,
  ProxyOptions,
} from "pages/appliances/components/appliance-form-drawer/components/appliance-config-form/types";
import { HaRole } from "pages/appliances/types";
import {
  AssetAvailabilityEnums,
  AssetProperties,
  AssetSecurityCategory,
  AssetStatus,
  AssetType,
  SecurityLevels,
  SecurityType,
} from "pages/assets/types";
import { TIMELINES } from "pages/dashboard/types";
import { UserRoles } from "pages/organization/components/invite-member-drawer/components/roles-dropdown/types";
import { CompassDirection, PathStatus } from "pages/paths/types";
import {
  PortCategory,
  PortRulePreference,
  PortStatus,
  ProgressiveEnforcementStatus,
} from "pages/ports/types";
import {
  PortFormHelperText,
  PortList,
} from "pages/templates/components/template-form-drawer/components/template-form/types";
import { TemplateType, WARNINGS } from "pages/templates/types";

export const DisplayTextMap: { [key: string]: string } = {
  [AssetStatus.Unsecured]: "Unsecure",
  [AssetStatus.SimulateSecureInternet]: "Simulate Secure Internet",
  [AssetStatus.SecureInternet]: "Secure Internet",
  [AssetStatus.SimulateSecureAll]: "Simulate Secure All",
  [AssetStatus.SecureAll]: "Secure All",
  [AssetProperties.CPUName]: "CPU Name",
  [AssetProperties.KernelArchitecture]: "Kernel Architecture",
  [AssetProperties.CPUCores]: "CPU Cores",
  [AssetProperties.KernelVersion]: "Kernel Version",
  [AssetProperties.RAMCapacityMB]: "RAM Capacity (MB)",
  [AssetProperties.DiskCapacityGB]: "Disk Capacity (GB)",
  [AssetProperties.Platform]: "Platform",
  [AssetProperties.VirtualizationSystem]: "Virtualization System",
  [SecurityLevels.Low]: "Low",
  [SecurityLevels.Medium]: "Medium",
  [SecurityLevels.High]: "High",
  [SecurityLevels.Critical]: "Critical",
  [AgentStatusType.Active]: "Active",
  [AgentStatusType.Absent]: "Absent",
  [AgentStatusType.Unknown]: "Unknown",
  [HaRole.Primary]: "Primary",
  [HaRole.Standby]: "Standby",
  [AssetAvailabilityEnums.Connected]: "Connected",
  [AssetAvailabilityEnums.Disconnected]: "Disconnected",
  [TemplateType.BlockTemplate]: "Block",
  [TemplateType.ApplicationTemplate]: "Allow",
  [AssetType.Server]: "Server",
  [AssetType.Device]: "Device",
  [AssetType.User]: "User",
  [AssetType.Service]: "Service",
  [AssetType.UserGroup]: "User Group",
  [AssetType.Endpoint]: "Endpoint",
  [PortCategory.ApplicationSystem]: "Application System",
  [PortCategory.ApplicationRegistered]: "Application Registered",
  [PortCategory.ApplicationEphemeral]: "Application Ephemeral",
  [PortCategory.ApplicationDatabase]: "Application Database",
  [PortCategory.InfrastructureSystem]: "Infrastructure System",
  [PortCategory.MaliciousRegistered]: "Malicious Registered",
  [PortCategory.ManagementSystem]: "Management System",
  [PortCategory.ApplicationProxy]: "Application Proxy",
  [PortCategory.RiskyRegistered]: "Risky Registered",
  [PortCategory.RiskySystem]: "Risky System",
  [PortCategory.InfrastructureRegistered]: "Infrastructure Registered",
  [PortCategory.ManagementRegistered]: "Management Registered",
  [PortStatus.Unreviewed]: "Unreviewed",
  [PortStatus.AllowIntranet]: "Allow Intranet only",
  [PortStatus.AllowAny]: "Allow all",
  [PortStatus.AllowAnyByTemplate]: "Allow any (Template)",
  [PortStatus.AllowIntranetByTemplate]: "Allow Intranet only (Template)",
  [PortStatus.PathRestricted]: "Restrict to allowed paths",
  [PortStatus.PathRestrictedByTemplate]: "Restrict to allowed paths (Template)",
  [PathStatus.Deny]: "Denied",
  [PathStatus.Allow]: "Allowed",
  [PathStatus.AllowTestDenied]: "Allowed By Test (No Violations)",
  [PathStatus.AllowTestDeniedViolation]: "Allowed By Test (With Violations)",
  [PathStatus.AllowedByProgressive]: "Allowed (Progressive)",
  [PathStatus.AllowedByTemplate]: "Allowed (Template)",
  [PathStatus.DeniedByTemplate]: "Denied (Template)",
  [PortRulePreference.Port]: "Port",
  [PortRulePreference.Process]: "Process",
  [PortRulePreference.Port_Process]: "Port & Process",
  [AssetSecurityCategory.INBOUND]: "Inbound",
  [AssetSecurityCategory.OUTBOUND]: "Outbound",
  [UserRoles.Admin]: "Admin",
  [UserRoles.Read_Only]: "Read Only",
  [UserRoles.Read_Write]: "Read Write",
  [UserRoles.Agent_Role]: "Agent Role",
  [Integration.IP_Address]: "IP Address",
  [Integration.MAC_Address]: "MAC Address",
  [Integration.Department]: "Department",
  [Integration.Environment]: "Environment",
  [Integration.Location]: "Location",
  [Integration.Owner]: "Owner",
  [Integration.Application]: "Application",
  unreported: "Unreported",
  "<nil>": "No Value",
  NULL: "No Value",
  true: "True",
  false: "False",
  [PortList.ANY]: "ANY",
  [Operator.EQUAL]: "Equal",
  [Operator.NOT_EQUAL]: "Not Equal",
  [Operator.CONTAINS]: "Contains",
  [Operator.STARTS_WITH]: "Starts With",
  [Operator.ENDS_WITH]: "Ends With",
  [SecurityType.ATTACK_SURFACE]: "Attack Surface",
  [SecurityType.BLAST_RADIUS]: "Blast Radius",
  [DHCPModes.Server]: "Server",
  [DHCPModes.Relay]: "Relay",
  [DHCPOptions.DHCP]: "DHCP",
  [DHCPOptions.Mixed]: "Mixed",
  [DHCPOptions.Static]: "Static",
  [HAModes.StandAlone]: "Stand-alone",
  [HAModes.Primary]: "Primary",
  [HAModes.Standby]: "Standby",
  [OptInOptions.Yes]: "Selected Devices Only",
  [OptInOptions.No]: "All Devices",
  [NetworkInterfaces.LAN]: "LAN",
  [NetworkInterfaces.VLAN]: "VLAN",
  [ConfigStatusMap.Syncronized]: "Synchronized",
  [ConfigStatusMap.Failed]: "Failed",
  [ConfigStatusMap.Inprogress]: "In-progress",
  [TIMELINES.WEEK]: "Last Week",
  [TIMELINES.MONTH]: "Last Month",
  [CompassDirection.EastWest]: "East-West",
  [CompassDirection.NorthSouth]: "North-South",
  [WARNINGS.ProcessTemplateWarning]:
    "Templates having processes apply only to Windows operating systems.",
  [PortFormHelperText.Process]: "Only applicable for Window machine",
  [PortFormHelperText.ProcessError]: "Invalid process name",
  [RESOURCE_CARDS_NAMES.Departments]: "Departments",
  [RESOURCE_CARDS_NAMES.Groups]: "Groups",
  [RESOURCE_CARDS_NAMES.Users]: "Users",
  [SCIM_STATUS_ENUM.Completed]: "Completed",
  [SCIM_STATUS_ENUM.Pending]: "Pending",
  [SCIM_STATUS_ENUM.Syncing]: "Syncing",
  [ProgressiveEnforcementStatus.Any]: "Any Port",
  [ProgressiveEnforcementStatus.AllowAll]: "Open Ports",
  [ProgressiveEnforcementStatus.AllowWithBandwidth]: "Active Ports",
  [ProgressiveEnforcementStatus.ZeroTrust]: "Zero Trust",
  [ProxyOptions.Yes]: "enableProxy",
  [ProxyOptions.No]: "disableProxy",
  [AuthenticationOptions.Yes]: "enableAuthentication",
  [AuthenticationOptions.No]: "disableAuthentication",
  [AsymmetricModeOptions.Yes]: "enableAsymmetricMode",
  [AsymmetricModeOptions.No]: "disableAsymmetricMode",
  [FailOpenOptions.Yes]: "enableFailOpen",
  [FailOpenOptions.No]: "disableFailOpen",
  [WorkRequestAction.EvaluateTemplatesRuleEngine]:
    "EvaluateTemplatesRuleEngine",
  [WorkRequestAction.AssetAdded]: "AssetAdded",
  [WorkRequestAction.AssetUpdated]: "AssetUpdated",
  [WorkRequestAction.TagRuleAdded]: "TagRuleAdded",
  [WorkRequestAction.TagRuleModified]: "TagRuleModified",
  [WorkRequestAction.TagBasedPolicyAdded]: "TagBasedPolicyAdded",
  [WorkRequestAction.TagBasedPolicyAssignmentsModified]:
    "TagBasedPolicyAssignmentsModified",
  [WorkRequestAction.TagBasedPolicyDeleted]: "TagBasedPolicyDeleted",
  [WorkRequestAction.TagUpdated]: "TagUpdated",
  [WorkRequestAction.TagDeleted]: "TagDeleted",
  [WorkRequestAction.NamedNetworkAssigned]: "NamedNetworkAssigned",
  [WorkRequestAction.NamedNetworkUnassigned]: "NamedNetworkUnassigned",
  [WorkRequestAction.NamedNetworkDeleted]: "NamedNetworkDeleted",
  [WorkRequestAction.NamedNetworkRangeAdd]: "NamedNetworkRangeAdd",
  [WorkRequestAction.NamedNetworkRangeRemove]: "NamedNetworkRangeRemove",
  [WorkRequestAction.NamedNetworkProgramAsIntranet]:
    "NamedNetworkProgramAsIntranet",
  [WorkRequestAction.ScimPullData]: "ScimPullData",
  [WorkRequestAction.TagBasedPolicyMemberChange]: "TagBasedPolicyMemberChange",
  [WorkRequestAction.EvaluateTemplates]: "EvaluateTemplates",
  [WorkRequestAction.TemplateEdit]: "TemplateEdit",
  [WorkRequestAction.UpdateNetworkMap]: "UpdateNetworkMap",
  [WorkRequestAction.EnableUserGroups]: "EnableUserGroups",
  [WorkRequestAction.DisableUserGroups]: "DisableUserGroups",
  [WorkRequestAction.AgentDeleted]: "AgentDeleted",
  [WorkRequestAction.AddScheduledJob]: "ReportGeneration",
  [WorkRequestAction.TagBasedPolicyProgressiveInboundRefresh]:
    "TagBasedPolicyProgressiveInboundRefresh",
  [NsTrafficStatusType.Enabled]: "Enabled",
  [NsTrafficStatusType.Disabled]: "Disabled",
  [DeviceTypes.Managed]: "Managed",
  [DeviceTypes.Unmanaged]: "Unmanaged",
};
