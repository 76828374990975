import { Box, Stack, Typography } from "@mui/material";
import { UnmanagedDevicesColumnRenderProps } from "../../appliance-unmanaged-devices/types";

export function UnmanagedDeviceVendor({
  device,
  viewOnly,
}: UnmanagedDevicesColumnRenderProps) {
  if (!device?.deviceId) {
    return <Box style={{ width: 70 }} />;
  }

  return (
    <Stack
      sx={{ width: !viewOnly ? "100%" : undefined }}
      direction={viewOnly ? "row" : "column"}
      alignItems={viewOnly ? "center" : undefined}
    >
      <Typography variant="body2">
        {window.getCTTranslatedText(device?.deviceVendor)}
      </Typography>
    </Stack>
  );
}
