import { PaletteMode } from "@mui/material";
import {
  GridColumnVisibilityModel,
  GridPinnedColumns,
} from "@mui/x-data-grid-pro";
import { DEFAULT_TIME_FILTER } from "common/molecules/TimeFilter/TimeFilter";
import { getStorageKey, stateStorage } from "common/store/helper";
import { IntegrationConfigType } from "pages/Integration/constants";
import { SummarizeResponse } from "pages/dashboard/components/security-progress/hooks/useSummarizeAPI";
import { create } from "zustand";
import { persist } from "zustand/middleware";

const THEME_MODE_KEY = "colorMode";
const LOCALE_KEY = "locale";
const SIDEBAR_KEY = "userpreference::sidebar";

export interface DataGridPreference {
  hiddenColumns?: GridColumnVisibilityModel;
  pinnedColumns?: GridPinnedColumns;
}

export interface AssetTypeFeatureStore {
  loadTime: number;
  data: SummarizeResponse | undefined;
  tenantID: string;
}

export interface IntegrationListStore {
  loadTime: number;
  data: IntegrationConfigType[] | undefined;
  tenantID: string;
}

export interface UserPreferencesStore {
  themeMode: PaletteMode;
  setThemeMode: (themeMode: PaletteMode) => void;

  locale: string;
  setLocale: (locale: string) => void;

  isSidebarExpanded: boolean;
  setIsSidebarExpanded: (isSidebarExpanded: boolean) => void;

  timeFilter: string;
  setTimeFilter: (timeFilter: string) => void;

  dataGridPreferences: { [key: string]: DataGridPreference | undefined };
  setDataGridPreferences: (
    key: string,
    preferences: DataGridPreference
  ) => void;

  assetTypeFeatures: AssetTypeFeatureStore;
  setAssetTypeFeatures: (assetTypeFeatures: AssetTypeFeatureStore) => void;

  integrationData: IntegrationListStore;
  setIntegrationData: (integrationData: IntegrationListStore) => void;
}

const initialAssetTypeFeature: AssetTypeFeatureStore = {
  loadTime: 0,
  data: undefined,
  tenantID: "",
};

const initialIntegrationData: IntegrationListStore = {
  loadTime: 0,
  data: undefined,
  tenantID: "",
};

export const useUserPreferencesStore = create<UserPreferencesStore>()(
  persist(
    set => {
      return {
        themeMode: (localStorage.getItem(THEME_MODE_KEY) ??
          "dark") as PaletteMode,
        setThemeMode: (themeMode: PaletteMode) => set({ themeMode }),

        locale:
          localStorage?.getItem(LOCALE_KEY) ?? navigator?.language ?? "en",
        setLocale: (locale: string) => set({ locale }),

        isSidebarExpanded: JSON.parse(
          localStorage?.getItem(SIDEBAR_KEY) ?? "true"
        ),
        setIsSidebarExpanded: (isSidebarExpanded: boolean) =>
          set({ isSidebarExpanded }),

        timeFilter: DEFAULT_TIME_FILTER,
        setTimeFilter: (timeFilter: string) => set({ timeFilter }),

        dataGridPreferences: {},
        setDataGridPreferences: (
          key: string,
          preferences: DataGridPreference
        ) =>
          set(state => {
            return {
              dataGridPreferences: {
                ...state.dataGridPreferences,
                [key]: {
                  ...(state.dataGridPreferences[key] ?? {}),
                  ...preferences,
                },
              },
            };
          }),

        assetTypeFeatures: initialAssetTypeFeature,
        setAssetTypeFeatures: (state: AssetTypeFeatureStore) =>
          set({ assetTypeFeatures: state }),

        integrationData: initialIntegrationData,
        setIntegrationData: (state: IntegrationListStore) =>
          set({ integrationData: state }),
      };
    },
    {
      version: 0,
      name: getStorageKey({ namespace: "userpreference" }),
      partialize: state => {
        const stateToStore = {
          themeMode: state.themeMode,
          locale: state.locale,
          isSidebarExpanded: state.isSidebarExpanded,
          timeFilter: state.timeFilter,
          dataGridPreferences: state.dataGridPreferences,
          assetTypeFeatures: state.assetTypeFeatures,
          integrationData: state.integrationData,
        };
        return stateToStore;
      },
      storage: stateStorage,
      onRehydrateStorage: () => {
        return () => {
          localStorage.removeItem(THEME_MODE_KEY);
          localStorage.removeItem(LOCALE_KEY);
          localStorage.removeItem(SIDEBAR_KEY);
        };
      },
    }
  )
);
