import { Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ProcessPaths } from "./types";

export const PORT_PROCESS_NAMES_COLUMNS: GridColDef<ProcessPaths>[] = [
  {
    field: "listenProcessNames",
    headerName: "Port Process Names",
    width: 140,
    flex: 0.4,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <Typography variant="body2">{params.row}</Typography>;
    },
  },
];

export const PORT_PROCESS_PATHS_COLUMNS: GridColDef<ProcessPaths>[] = [
  {
    field: "listenProcesspaths",
    headerName: "Port Process Paths",
    width: 140,
    flex: 0.4,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <Typography variant="body2">{params.row}</Typography>;
    },
  },
];
