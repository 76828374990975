import { nil } from "modules/facets/FacetUtils";
import { FacetOptionState, Operator } from "modules/facets/types";
import { Dimension } from "modules/hierarchy-vis/types";
import { PathStatus } from "pages/paths/types";
import { DEFAULT_HUB_CRITERIA } from "./hooks/useVisualizerCriteriaBuilder";
import {
  GroupNodeDataType,
  PathStatusMap,
  PathTypes,
  TrafficData,
  TrafficDatum,
} from "./types";

export const UNKNOWN = "Unknown";
export const UNTAGGED = "Untagged";

export const OTHERS = "Others";
export const MAX_NODES = 20;

export const NODE_ID_SEPARATOR = ":>";

export function getNodeId(name: string) {
  if (!name) {
    return name;
  }
  let split = name.split(NODE_ID_SEPARATOR);
  return split[split.length - 1];
}

export function getCriteriaForNode(
  nodeName: string,
  nodeDimension?: Dimension
) {
  if (!nodeDimension) {
    return "";
  }

  nodeName = getNodeId(nodeName);

  let isNetworkNode =
    isSubnet(nodeName) || nodeDimension.name === "namednetworkname";
  nodeName = removeSubnetNodePrefix(nodeName);

  let query = `'${nodeDimension?.name}' in ('${nodeName}')`;

  if (
    query.includes(UNKNOWN) ||
    query.includes(UNTAGGED) ||
    query.includes(DEFAULT_INTRANET_NN_NAME) ||
    query.includes(DEFAULT_INTERNET_NN_NAME)
  ) {
    query = `${nodeDimension?.name} = NULL`;
  }

  if (isNetworkNode) {
    query = `${query} AND assetid=NULL`;
  }

  return query;
}

export function createParentCriteriaJoins(
  trafficData: TrafficDatum | undefined
) {
  let parent = trafficData?.parent;
  let parentJoins = [];
  while (parent != null) {
    let parentQuery = getCriteriaForNode(parent.name, parent.sourceDimension);
    parentJoins.push(parentQuery);
    parent = parent.parent;
  }
  return parentJoins;
}

export function createFacetParentJoinUpdates(
  trafficData: TrafficDatum | undefined
) {
  let parent = trafficData?.parent;
  let updates = [];

  while (parent != null) {
    let options: FacetOptionState = new Map();
    let facetValue = getNodeId(parent.name);

    if (facetValue.includes(UNKNOWN) || facetValue.includes(UNTAGGED)) {
      facetValue = `NULL`;
    }

    options.set(facetValue, {
      isSelected: true,
      operator: Operator.EQUAL,
    });

    if (parent.sourceDimension?.name) {
      updates.push({
        facetName: parent.sourceDimension?.name,
        options,
      });
    }

    parent = parent.parent;
  }

  return updates;
}

export function joinCriterias(criterias: string[]) {
  let unique = new Set(criterias);
  return Array.from(unique).join(" AND ");
}

export function withHub(c: string, hubCriteria: string | undefined) {
  const newLocal =
    c === hubCriteria ||
    hubCriteria === "*" ||
    hubCriteria === DEFAULT_HUB_CRITERIA ||
    !hubCriteria;
  if (newLocal) {
    return c;
  } else {
    return `(${c}) AND ${hubCriteria}`;
  }
}

export function withoutHub(c: string, hubCriteria: string | undefined) {
  if (
    hubCriteria === "*" ||
    hubCriteria === DEFAULT_HUB_CRITERIA ||
    !hubCriteria ||
    c === hubCriteria
  ) {
    return c;
  } else {
    return `(${c}) AND NOT(${hubCriteria})`;
  }
}

export function getNodeName(name: string, dimension?: Dimension) {
  if (name === nil) {
    const placeHolder =
      dimension?.name === "primarysubnet" || dimension?.name === "osname"
        ? UNKNOWN
        : UNTAGGED;
    return `${placeHolder} ${dimension?.label}`;
  }

  return name;
}

export function getNodeDisplayName(label: string) {
  let id = getNodeId(label);
  if (id.startsWith(UNKNOWN) || id.startsWith(UNTAGGED)) {
    let split = id.split(" ");
    id = `${window.getCTTranslatedText(
      split[0] ?? ""
    )} ${window.getCTTranslatedText(split[1] ?? "")}`;
  }
  return window.getCTTranslatedText(id);
}

export function getPathStatusBreakdown(map: PathStatusMap | undefined) {
  let totalUnreviewed = map?.get(PathStatus.Unreviewed) ?? 0;
  let denied = map?.get(PathStatus.Deny) ?? 0;
  let templateDenied = map?.get(PathStatus.DeniedByTemplate) ?? 0;
  let totalDenied = denied + templateDenied;
  let allowed = map?.get(PathStatus.Allow) ?? 0;
  let progressiveAllowed = map?.get(PathStatus.AllowedByProgressive) ?? 0;
  let templateAllowed = map?.get(PathStatus.AllowedByTemplate) ?? 0;
  let testAllowed = map?.get(PathStatus.AllowTestDenied) ?? 0;
  let testAllowedViolation = map?.get(PathStatus.AllowTestDeniedViolation) ?? 0;
  let totalAllowed = allowed + templateAllowed + progressiveAllowed;
  let totalAllowedByTest = testAllowed + testAllowedViolation;

  let total = totalAllowed + totalDenied + totalUnreviewed + totalAllowedByTest;
  return {
    totalUnreviewed,
    denied,
    templateDenied,
    allowed,
    progressiveAllowed,
    templateAllowed,
    totalDenied,
    testAllowed,
    testAllowedViolation,
    totalAllowed,
    total,
    totalAllowedByTest,
    hasMixed:
      total > totalAllowed &&
      total > totalDenied &&
      total > totalUnreviewed &&
      total > totalAllowedByTest,
  };
}

export function getSubnetField(inputString: string) {
  return inputString.split("_")[2];
}

export const SUBNET_PREFIX = "ct_subnet";
export function isSubnet(inputString: string) {
  return (
    inputString.startsWith(SUBNET_PREFIX) ||
    inputString.includes("srcsubnet") ||
    inputString.includes("dstsubnet")
  );
}

export function removeSubnetNodePrefix(nodeName?: string) {
  if (!nodeName) {
    return "";
  }
  nodeName = nodeName.replace(/ct_subnet_srcsubnet\d{1,2}_/, "");
  nodeName = nodeName.replace(/ct_subnet_dstsubnet\d{1,2}_/, "");
  return nodeName;
}

export const DEFAULT_INTERNET_NN_NAME = "Other Public IPs";
export const DEFAULT_INTRANET_NN_NAME = "Other Private IPs";
export const NN_TITLE = "Named Network";

export const DEFAULT_PUBLIC_NETWORK_NODE_NAME = "Internet";
export const DEFAULT_PRIVATE_NETWORK_NODE_NAME = "Intranet";

export const getLabelFromStatus = (status: PathTypes) => {
  switch (status) {
    case PathStatus.Allow:
    case PathStatus.AllowedByProgressive:
    case PathStatus.AllowedByTemplate:
    case PathStatus.Deny:
    case PathStatus.DeniedByTemplate:
      return "By Policy";
    case PathStatus.AllowedByTestUIOnly:
    case PathStatus.AllowTestDenied:
    case PathStatus.AllowTestDeniedViolation:
      return "By Test";
    case PathStatus.Unreviewed:
      return "By Default";
    default:
      return "Hybrid";
  }
};

export const getTooltipLabelFromStatus = (status: PathTypes) => {
  switch (status) {
    case PathStatus.Allow:
    case PathStatus.AllowedByProgressive:
    case PathStatus.AllowedByTemplate:
      return "Allowed By Policy";
    case PathStatus.Deny:
    case PathStatus.DeniedByTemplate:
      return "Blocked By Policy";
    case PathStatus.AllowedByTestUIOnly:
    case PathStatus.AllowTestDenied:
    case PathStatus.AllowTestDeniedViolation:
      return "Allowed By Test";
    case PathStatus.Unreviewed:
      return "Allowed By Default";
    default:
      return "Hybrid";
  }
};

export function getChildrenFromNode(
  node: GroupNodeDataType | undefined,
  trafficData: TrafficData | undefined
) {
  const getChildIdsFromTrafficData = (t?: TrafficDatum) => {
    let ids: Set<string> | undefined;

    let children = t?.children || trafficData?.[t?.name ?? ""]?.children;
    children?.forEach(c => {
      if (!ids) {
        ids = new Set();
      }
      Object.keys(c).forEach(k => {
        if (!ids) {
          ids = new Set();
        }
        ids.add(k);
      });
      Object.values(c).forEach(v => {
        let cIds = getChildIdsFromTrafficData(v);
        if (ids && cIds) {
          ids = new Set([...ids, ...cIds]);
        }
      });
    });
    return ids;
  };

  const children = getChildIdsFromTrafficData(node?.trafficData);
  return children;
}
