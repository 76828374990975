import { useMutation } from "@tanstack/react-query";
import { ApplianceRequest, ApplianceResponse } from "pages/appliances/types";

export function useApplianceDetailsAPI() {
  const path = `gateways/actions/search`;
  return useMutation<ApplianceResponse, Error, ApplianceRequest>([
    "appliance-detail",
    path,
    "post",
  ]);
}
