import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Loader } from "common/atoms/loader";
import { useScimStatusAPI } from "pages/Integration/components/scim-management-config/hooks";
import { TokenManagement } from "pages/Integration/components/token";
import { Toolbar } from "common/atoms/toolbar";

export interface SCIMDrawerProps {
  isOpen: boolean;
  page: string;
  title?: string;
  onCancel: () => void;
  btnTitle?: string;
}

export const ScimManagementConfig = ({
  isOpen,
  page,
  title,
  onCancel,
}: SCIMDrawerProps) => {
  const { data: scimStatusData, isLoading } = useScimStatusAPI();

  if (isLoading || !scimStatusData) {
    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onCancel}
        PaperProps={{
          sx: {
            padding: "0",
            width: "80%",
            minWidth: "1000px",
            height: "100%",
          },
          elevation: 1,
        }}
      >
        <Loader />
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onCancel}
      PaperProps={{
        sx: {
          padding: "0px",
          width: "80%",
          minWidth: "1000px",
          height: "100%",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title="Close Drawer">
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={onCancel}
            sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 5, mb: 2, mx: 4 }}>
        <Typography variant="h5">
          <b>{title}</b>
        </Typography>
      </Stack>
      <DialogContent sx={{ height: "100%" }}>
        <Stack spacing={3} sx={{ width: "100%", height: "100%" }}>
          <TokenManagement scimStatusData={scimStatusData} />
        </Stack>
      </DialogContent>
    </Drawer>
  );
};
