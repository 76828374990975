import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { CTRole } from "pages/organization/types";
import { useEffect, useRef, useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

const ddWidth = "120px";

interface RolesDropdownProps {
  onSelect: (role: CTRole) => void;
  rolesList: CTRole[] | undefined;
  selectedRole?: CTRole;
  invite?: boolean;
}

export const RolesDropdown = ({
  onSelect,
  rolesList,
  selectedRole,
  invite = false,
}: RolesDropdownProps) => {
  const [openMenu, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [selectedOperation, setSelectedOperation] = useState<
    CTRole | undefined
  >(selectedRole ?? undefined);

  useEffect(() => {
    if (!invite) {
      setSelectedOperation(selectedRole);
    }
  }, [selectedRole, setSelectedOperation, invite]);

  const handleMenuToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const handleMenuClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMenuOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    role: CTRole
  ) => {
    if (
      !selectedOperation ||
      (selectedOperation && role.roleID !== selectedOperation?.roleID)
    ) {
      onSelect(role);
      setSelectedOperation(role);
    }
    setMenuOpen(false);
  };

  return (
    <Stack direction="row">
      <LoadingButton
        loading={(!selectedOperation || !selectedRole) && !invite}
        variant="outlined"
        ref={anchorRef}
        aria-controls={openMenu ? "split-button-menu" : undefined}
        aria-expanded={openMenu ? "true" : undefined}
        aria-label="select role"
        aria-haspopup="menu"
        endIcon={<ArrowDropDownIcon />}
        sx={{
          minWidth: ddWidth,
          justifyContent: "space-between",
          textTransform: "capitalize",
        }}
        onClick={handleMenuToggle}
      >
        {window.getCTTranslatedText(
          selectedOperation
            ? selectedOperation?.displayName ?? ""
            : "Select Role"
        )}
      </LoadingButton>
      <Popper
        sx={{
          zIndex: 1,
          minWidth: ddWidth,
        }}
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal={invite}
        placement={"bottom-start"}
        modifiers={[
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: false,
              altBoundary: true,
              tether: true,
              rootBoundary: "viewport",
              padding: 8,
            },
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "top-start" : "bottom-start",
            }}
          >
            <Paper sx={{ minWidth: ddWidth }}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  sx={{
                    maxHeight: 225,
                    overflowY: "auto",
                    "& .MuiList-root": { pb: 0 },
                  }}
                >
                  {rolesList &&
                    rolesList?.length > 0 &&
                    ((rolesList as Array<CTRole>) || [])
                      .filter(role => {
                        return role.roleName !== "Agent-Role";
                      })
                      .map((option, index) => (
                        <Stack key={option?.roleID}>
                          <MenuItem
                            onClick={event =>
                              handleMenuItemClick(event, option)
                            }
                          >
                            <Typography
                              variant="body2"
                              noWrap
                              sx={{ textTransform: "capitalize" }}
                            >
                              {window.getCTTranslatedText(option?.displayName)}
                            </Typography>
                          </MenuItem>
                          {index < rolesList?.length - 2 && <Divider />}
                        </Stack>
                      ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};
