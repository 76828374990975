import { Stack, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { DataGridButton } from "common/atoms/data-grid-button";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AlertColumnRenderProps,
  AlertRuleConfigurationType,
} from "pages/settings/components/alert-config/types";

export function AlertRuleTypeIcon({
  alertRule,
  viewOnly,
}: AlertColumnRenderProps) {
  const iconProps = { sx: { width: 24, height: 24, mt: 1.25 } };
  let icon = <PersonIcon {...iconProps}></PersonIcon>;
  let text = "userConfiguredAlertRule";
  if (alertRule?.configuration === AlertRuleConfigurationType.Preconfigured) {
    icon = <SettingsIcon {...iconProps}></SettingsIcon>;
    text = "preConfiguredAlertRule";
  }

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <Tooltip title={window.getCTTranslatedText(text)}>
        <DataGridButton
          asText={viewOnly}
          disabled={viewOnly}
          variant={"text"}
          color={"inherit"}
        >
          {icon}
        </DataGridButton>
      </Tooltip>
    </Stack>
  );
}
