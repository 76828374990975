import { Box } from "@mui/material";
import { AnnotationDrawer } from "modules/annotation-drawer";
import { EntityType } from "modules/annotation-drawer/type";
import { AnnotationCount } from "modules/annotation/components/annotation-count";
import { useAssetStore } from "pages/assets/store";
import { AssetColumnRenderProps } from "pages/assets/types";
import { useState } from "react";

export function AssetAnnotations({ asset, viewOnly }: AssetColumnRenderProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = (event: any) => {
    if (event) {
      event.stopPropagation();
    }
    setIsDrawerOpen(true);
  };

  if (!asset.assetId) {
    return <Box style={{ width: 70 }} />;
  }

  return (
    <>
      <AnnotationCount
        count={asset.totalComments}
        clickCallback={handleDrawerOpen}
      />
      {isDrawerOpen && (
        <AnnotationDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          entity={EntityType.Assets}
          entityId={asset.assetId}
          useStore={useAssetStore}
        />
      )}
    </>
  );
}
