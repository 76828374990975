import CloseIcon from "@mui/icons-material/Close";
import {
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { GridRowId } from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { AlertRuleConfig, AlertRuleStatus } from "../../types";
import { BASE_ALERTCONFIG_COLUMNS } from "../alert-config-data-grid/constants";
import { AlertRuleNotificationActionBar } from "../alert-rule-notification-action-bar";

interface AlertRuleNotificationConfigureDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  selectedData: Array<AlertRuleConfig> | undefined;
  confirm: (data: any) => void;
  title?: string;
}

export const AlertRuleNotificationConfigureDrawer = ({
  isOpen,
  onClose,
  selectedData,
  title,
  confirm,
}: AlertRuleNotificationConfigureDrawerProps) => {
  const [selection, setSelection] = useState<Array<GridRowId>>([]);
  useEffect(() => {
    if (selectedData?.length) {
      const selectedGroupMap: string[] = selectedData
        .filter(
          (row: AlertRuleConfig) =>
            row.alertRuleStatus !== AlertRuleStatus.Disabled
        )
        .map((row: AlertRuleConfig) => row.alertRuleId);

      setSelection(selectedGroupMap);
    }
  }, [selectedData]);

  const confirmAction = (data: any) => {
    confirm(selection);
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "0px",
          width: "80%",
          minWidth: "1000px",
          height: "100%",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title="Close Drawer">
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={onClose}
            sx={{ position: "absolute", right: "16px", top: "16px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 4, mb: 0, mx: 4 }}>
        <Typography variant="h5">
          <b>{title}</b>
        </Typography>
      </Stack>
      <DialogContent>
        <DataGrid
          rowHeight={64}
          columns={BASE_ALERTCONFIG_COLUMNS}
          pagination={true}
          getRowId={(alert: AlertRuleConfig) => `${alert.alertRuleId}`}
          paginationMode="client"
          sortingMode="client"
          checkboxSelection
          isRowSelectable={data =>
            data.row.alertRuleStatus !== AlertRuleStatus.Disabled
          }
          rowSelectionModel={selection}
          onRowSelectionModelChange={selectionModel => {
            setSelection(selectionModel);
          }}
          rows={selectedData}
          rowCount={selectedData?.length ?? 0}
          selectedRawData={selectedData}
          initialState={{
            sorting: {
              sortModel: [{ field: "groupName", sort: "desc" }],
            },
          }}
        />
      </DialogContent>
      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <AlertRuleNotificationActionBar
          selection={selection}
          update={confirmAction}
          cancel={onClose}
        />
      </DialogActions>
    </Drawer>
  );
};
