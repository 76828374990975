import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import numeral from "numeral";
import { NetworkColumnRenderProps } from "pages/networks/types";

export function AssetCount({ network, viewOnly }: NetworkColumnRenderProps) {
  if (!network.namedNetworkId) {
    return <Box style={{ minWidth: 120, minHeight: 45 }} />;
  }

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
      <Stack alignItems="flex-start">
        {network?.namedNetworkAssignments === 0 ? (
          <Typography variant="body2">
            {numeral(network?.namedNetworkAssignments || 0).format("0a")}{" "}
            {window.getCTTranslatedText(
              network?.namedNetworkAssignments &&
                network?.namedNetworkAssignments > 1
                ? "assets"
                : "asset"
            )}
          </Typography>
        ) : (
          <Tooltip title={window.getCTTranslatedText("View assets")}>
            <Link
              typography={"body2"}
              component={RouterLink}
              to={`/assets?filters=${encodeURIComponent(
                `namednetworkname=${network.namedNetworkName}`
              )}`}
              sx={{ color: "primary.main" }}
            >
              {numeral(network?.namedNetworkAssignments || 0).format("0a")}{" "}
              {window.getCTTranslatedText(
                network?.namedNetworkAssignments &&
                  network?.namedNetworkAssignments > 1
                  ? "assets"
                  : "asset"
              )}
            </Link>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
