import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";

export interface CTDrawerProps extends DrawerProps {
  open: boolean;
  actions?: React.ReactNode;
  onClose: VoidFunction;
  title: string;
}

export function CTDrawer(props: CTDrawerProps) {
  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          p: 0,
          width: "70%",
          maxwidth: "1000px",
          minWidth: "600px",
        },
        elevation: 1,
      }}
      {...props}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Toolbar />
        <Stack
          alignItems="flex-start"
          sx={{
            position: "relative",
            width: "100%",
            flex: 1,
            overflow: "hidden",
          }}
        >
          <Stack sx={{ mt: 1 }}>
            <Typography variant="h5">
              <b>{props.title}</b>
            </Typography>
          </Stack>

          <Tooltip title="Close Drawer">
            <IconButton
              size="medium"
              aria-label="close drawer"
              onClick={() => {
                props.onClose();
              }}
              sx={{
                position: "absolute",
                right: "16px",
                top: "8px",
                zIndex: 2,
              }}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Tooltip>

          <Box pt={2} sx={{ width: "100%", flex: 1, overflow: "hidden" }}>
            {props.children}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          size={"large"}
          variant="outlined"
          onClick={() => props.onClose()}
        >
          {window.getCTTranslatedText("Close")}
        </Button>
        {props.actions}
      </DialogActions>
    </Drawer>
  );
}
