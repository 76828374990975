import { SecuritySliderLabel } from "modules/security-slider/SecuritySliderLabel";
import { ProgressiveEnforcementLevelIcon } from "pages/ports/components/progressive-enforcement-slider/ProgressiveEnforcementStatusIcon";
import {
  ProgressiveEnforcementLevel,
  ProgressiveEnforcementLevelLabels,
} from "pages/ports/types";

interface ProgressiveSliderLabelProps {
  progressivePortsStatus: ProgressiveEnforcementLevel;
  currentStatus: ProgressiveEnforcementLevel;
  label: string;
  isMinimum?: boolean;
}

export const ProgressiveSliderLabel = ({
  progressivePortsStatus,
  currentStatus,
  label,
  isMinimum,
}: ProgressiveSliderLabelProps) => {
  const isActive = progressivePortsStatus === currentStatus;

  const alignLabel = () => {
    let alignment = "center";
    if (currentStatus === ProgressiveEnforcementLevel.Any) {
      alignment = "flex-start";
    } else if (currentStatus === ProgressiveEnforcementLevel.ZeroTrust) {
      alignment = "flex-end";
    }
    return alignment;
  };

  const getLeftPadding = () => {
    if (currentStatus !== ProgressiveEnforcementLevel.Any) {
      return 0;
    }

    return isActive ? 10 : 5;
  };

  const getRightPadding = () => {
    if (currentStatus !== ProgressiveEnforcementLevel.ZeroTrust) {
      return 0;
    }

    return isActive ? 10 : 6;
  };

  const sliderLabel =
    label ===
    ProgressiveEnforcementLevelLabels[
      ProgressiveEnforcementLevel.AllowWithBandwidth
    ]
      ? "Active (last 30 days)"
      : label;

  return (
    <SecuritySliderLabel
      securityStatus={progressivePortsStatus}
      currentSecurityStatus={currentStatus}
      label={sliderLabel}
      isMinimum={isMinimum}
      renderIcon={color => (
        <ProgressiveEnforcementLevelIcon status={currentStatus} color={color} />
      )}
      justifyContent={alignLabel()}
      ml={getLeftPadding()}
      mr={getRightPadding()}
    />
  );
};
