import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useCommonStore } from "common/store";

import { getStatus } from "common/utils/statusUtils";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { StatusText } from "pages/ports/constants";
import {
  PortColumnRenderProps,
  PortStatus as PortStatusOptions,
} from "pages/ports/types";

export function PortStatusView({
  port,
  viewOnly,
  useFacetStore = useCommonStore,
}: PortColumnRenderProps) {
  const facetState = useFacetStore(state => state.facets);

  const isSelected =
    !viewOnly &&
    facetState?.get("listenportreviewed")?.get(port.listenPortReviewed)
      ?.isSelected;

  if (!port.lpId) {
    return <Box style={{ width: 160, minHeight: 45 }} />;
  }

  const status =
    StatusText[port?.listenPortReviewed || PortStatusOptions.Unreviewed];

  const statusDetails = getStatus(status);

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <Tooltip title={window.getCTTranslatedText(statusDetails?.status ?? "")}>
        <span>
          <CTDatagridButton
            asText={viewOnly === true}
            isDisabled={viewOnly === true}
            useFacetStore={useFacetStore}
            facetName={"listenportreviewed"}
            facetOption={port.listenPortReviewed ?? ""}
          >
            {window.getCTTranslatedText(statusDetails?.status)}
          </CTDatagridButton>
        </span>
      </Tooltip>
      {statusDetails?.description && (
        <Typography
          variant="caption"
          color={isSelected ? "primary" : "inherit"}
          sx={{ pl: 1 }}
        >
          {window.getCTTranslatedText(statusDetails?.description)}
        </Typography>
      )}
    </Stack>
  );
}
