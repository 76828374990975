import { Grid, Stack, Typography, useTheme } from "@mui/material";

interface WidgetListItemProps {
  alignItems?: string;
  keyText?: string;
  keyElement?: React.ReactElement;
  valueText?: string | number;
  valueElement?: React.ReactElement;
}

const overflowStyles = {
  width: "fitContent",
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const WidgetListItem = ({
  alignItems,
  keyText,
  keyElement,
  valueText,
  valueElement,
}: WidgetListItemProps) => {
  const theme = useTheme();
  return (
    <Stack direction="row" width="100%" alignItems={alignItems ?? "center"}>
      <Grid item lg={6} md={6} justifyContent="flex-start">
        {keyElement ?? (
          <Typography
            sx={{
              color: theme.palette.grey[600],
            }}
            variant="body2"
          >
            {keyText}
          </Typography>
        )}
      </Grid>
      <Grid item lg={6} md={6}>
        {valueElement ?? (
          <Typography variant="body2" sx={overflowStyles}>
            {valueText}
          </Typography>
        )}
      </Grid>
    </Stack>
  );
};
