import { Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { MoreOptionsMenu } from "common/molecules/more-options-menu";
import {
  MenuItemOption,
  MenuOption,
} from "common/molecules/more-options-menu/types";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import {
  GridToolbar,
  ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAgentStore } from "pages/agents/store";
import { Agent } from "pages/agents/types";
import { AssetType } from "pages/assets/types";
import { useMemo, useState } from "react";
import {
  AgentDebugLogSupportedMinVersion,
  AgentDecommissionSupportedMinVersion,
  AgentDiagnosticSupportedMinVersion,
} from "../agent-data-grid/constants";
import { isDiagnosticsAllowed } from "../agent-data-grid/helpers/columnHelpers";
import { useTrafficConfigAgents } from "../agent-data-grid/hooks/useAgentsApi";
import { AgentDownload } from "../agent-downlaod/AgentDownload";
import {
  AgentDebugLogDrawer,
  AgentDecommissionDrawer,
  AgentDiagnosticDrawer,
  AgentRestartDrawer,
  AgentTrafficConfigDrawer,
  AgentNsTrafficConfigDrawer,
  AgentUpgradeDrawer,
  AutoUpgradeDrawer,
} from "../agent-drawers";
import { ConfirmTrafficConfigActionProps } from "../agent-drawers/AgentTrafficConfigDrawer";
import { ConfirmNsTrafficConfigActionProps } from "../agent-drawers/AgentNsTrafficConfigDrawer";
import {
  AGENT_AUTOUPGRADE_COLUMNS,
  AGENT_DEBUGLOG_COLUMNS,
  AGENT_DECOMMISSION_COLUMNS,
  AGENT_DIAGNOSTICS_COLUMNS,
  AGENT_RESTART_COLUMNS,
  AGENT_TRAFFIC_CONFIG_COLUMNS,
  AGENT_NS_TRAFFIC_CONFIG_COLUMNS,
  AGENT_UPGRADE_COLUMNS,
  AutoUpgradeStatus,
  DebugLogStatus,
  TrafficConfigStatus,
  NsTrafficConfigStatus,
} from "../agent-drawers/helpers/constants";
import {
  AutoUpgradeActions,
  RestartActions,
  UpgradeActions,
} from "./ToolbarActions";
import * as semver from "semver";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { ROUTES } from "routes";

function isString(value: any): value is string {
  return typeof value === "string";
}

export interface AgentToolbarProps extends Omit<ToolbarProps, "children"> {
  selectedData: Array<Agent> | undefined;
  selection: Array<GridRowId>;
  hideToolbar: Function;
  showPortStatusOption?: boolean;
  recommendationId?: string;
}
export enum AgentActionType {
  RESTART = "restart",
  UPGRADE = "upgrade",
  AUTOUPGRADE = "autoupgrade",
  DIAGNOSTIC = "diagnostic",
  DECOMMISSION = "decommission",
  DEBUGLOG = "debuglog",
  TRAFFIC_CONFIG = "trafficConfig",
  NS_TRAFFIC_CONFIG = "nsTrafficConfig",
}
export default function AgentToolbar(props: AgentToolbarProps) {
  const route = ROUTES.INSTALL_AGENTS.pathname;
  const notify = useEventSubscriptionStore(state => state.notify);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const { isFeatureEnabled: isTrafficConfigEnabled } = useFeatureFlagControl(
    FEATURES.TRAFFIC_CONFIG
  );
  const { isFeatureEnabled: isNsTrafficConfigEnabled } = useFeatureFlagControl(
    FEATURES.NS_TRAFFIC_CONFIG
  );
  const requestAPIRefresh = useAgentStore(store => store.requestAPIRefresh);

  const upgradeAgentPerm = userPermissions.has("UPGRADE_AGENT");
  const createDepKeyPerm = userPermissions.has("CREATE_DEPLOYMENT_KEY");

  const [openAgentUpgradeMenu, setAgentUpgradeMenuOpen] = useState(false);
  const [openAgentRestartMenu, setAgentRestartMenuOpen] = useState(false);
  const [openAutoUpgradeMenu, setAutoUpgradeMenuOpen] = useState(false);
  const [openAgentDiagnosticMenu, setAgentDiagnosticMenuOpen] = useState(false);
  const [openAgentDecommissionMenu, setAgentDecommissionMenuOpen] =
    useState(false);
  const [openAgentDebugLogMenu, setOpenAgentDebugLogMenu] = useState(false);
  const [openTrafficConfigMenu, setOpenTrafficConfigMenu] = useState(false);
  const [openNsTrafficConfigMenu, setOpenNsTrafficConfigMenu] = useState(false);
  const [autoUpgradeAction, setAutoUpgradeAction] = useState("");
  const [debugLogAction, setDebugLogAction] = useState("");
  const [trafficConfigAction, setTrafficConfigAction] = useState("");
  const [nsTrafficConfigAction, setNsTrafficConfigAction] = useState("");

  const AgentTrafficConfigSupportedMinVersion = "5.9.6";
  const AgentNsTrafficConfigSupportedMinVersion = "24.6.0";

  const updatedAgentUpgradeSelection = useMemo(() => {
    return props?.selection.filter(item => {
      const matchedData = props?.selectedData?.find(
        (obj: Agent) => obj.rowId === item && obj.isUpgradeEnabled
      );
      return matchedData !== undefined;
    });
  }, [props.selection, props.selectedData]);

  const updatedAgentRestartSelection = useMemo(() => {
    return props?.selection.filter(item => {
      const matchedData = props?.selectedData?.find(
        (obj: Agent) => obj.rowId === item && obj.isRestartEnabled
      );
      return matchedData !== undefined;
    });
  }, [props.selection, props.selectedData]);

  const updatedAgentDiagnosticsSelection = useMemo(() => {
    return props?.selection.filter(item => {
      const matchedData = props?.selectedData?.find(
        (obj: Agent) =>
          obj.rowId === item &&
          !obj.isDiagnosticsRunning &&
          isDiagnosticsAllowed(obj)
      );
      return matchedData !== undefined;
    });
  }, [props.selection, props.selectedData]);

  const updatedAgentDecommissionSelection = useMemo(() => {
    return props?.selection.filter(item => {
      const matchedData = props?.selectedData?.find(
        (obj: Agent) =>
          obj.rowId === item &&
          obj.isDecommissionEnabled &&
          obj.isDecommissionSupported
      );
      return matchedData !== undefined;
    });
  }, [props.selection, props.selectedData]);

  const updatedAutoUpgradeSelection = useMemo(() => {
    return props?.selection.filter(item => {
      const matchedData = props?.selectedData?.find((obj: Agent) =>
        autoUpgradeAction === AutoUpgradeStatus.Disable
          ? obj.rowId === item && obj.autoUpgradeEnabled
          : obj.rowId === item && !obj.autoUpgradeEnabled
      );
      return matchedData !== undefined;
    });
  }, [props.selection, props.selectedData, autoUpgradeAction]);

  const updatedDebugLogSelection = useMemo(() => {
    return props?.selection.filter(item => {
      const matchedData = props?.selectedData?.find(
        (obj: Agent) =>
          obj.rowId === item &&
          (debugLogAction === DebugLogStatus.Disable
            ? obj.isDebugLogEnabled
            : !obj.isDebugLogEnabled) &&
          !obj.isDebugLogRunning &&
          obj.isDebugLogSupported
      );
      return matchedData !== undefined;
    });
  }, [props.selection, props.selectedData, debugLogAction]);

  const updatedTrafficConfigSelection = useMemo(() => {
    return props?.selection.filter(item => {
      const matchedData = props?.selectedData?.find(
        (obj: Agent) =>
          obj.rowId === item &&
          obj.asset.type === AssetType.Endpoint &&
          obj.currentTrafficConfiguration !== trafficConfigAction &&
          semver.gt(obj.currentVersion, AgentTrafficConfigSupportedMinVersion)
      );
      return matchedData !== undefined;
    });
  }, [props.selection, props.selectedData, trafficConfigAction]);

  const updatedNsTrafficConfigSelection = useMemo(() => {
    return props?.selection.filter(item => {
      const matchedData = props?.selectedData?.find(
        (obj: Agent) =>
          obj.rowId === item &&
          obj.asset.type === AssetType.Server &&
          obj.northSouthTrafficConfiguration !== nsTrafficConfigAction &&
          semver.gt(obj.currentVersion, AgentNsTrafficConfigSupportedMinVersion)
      );
      return matchedData !== undefined;
    });
  }, [props.selection, props.selectedData, nsTrafficConfigAction]);

  const isUpgradeButtonDisabled: boolean = useMemo(() => {
    return !props?.selectedData?.some(obj => obj.isUpgradeEnabled);
  }, [props?.selectedData]);

  const isRestartButtonDisabled: boolean = useMemo(() => {
    return !props?.selectedData?.some(obj => obj.isRestartEnabled);
  }, [props?.selectedData]);

  const isDiagnosticsButtonDisabled: boolean = useMemo(() => {
    return !props?.selectedData?.some(
      obj => !obj.isDiagnosticsRunning && isDiagnosticsAllowed(obj)
    );
  }, [props?.selectedData]);

  const isDecommissionButtonDisabled: boolean = useMemo(() => {
    return !props?.selectedData?.some(
      obj => obj.isDecommissionEnabled && obj.isDecommissionSupported
    );
  }, [props?.selectedData]);

  const isDebugLogButtonDisabled: boolean = useMemo(() => {
    return !props?.selectedData?.some(
      obj => !obj.isDebugLogRunning && obj.isDebugLogSupported
    );
  }, [props?.selectedData]);

  const isTrafficConfigButtonDisabled = useMemo(() => {
    const isTrafficConfigSupported = (obj: Agent) => {
      return semver.gt(
        obj.currentVersion,
        AgentTrafficConfigSupportedMinVersion
      );
    };
    return !props?.selectedData?.some(
      obj =>
        obj.assetType === AssetType.Endpoint && isTrafficConfigSupported(obj)
    );
  }, [props?.selectedData]);

  const isNsTrafficConfigButtonDisabled = useMemo(() => {
    const isNsTrafficConfigSupported = (obj: Agent) => {
      return semver.gt(
        obj.currentVersion,
        AgentNsTrafficConfigSupportedMinVersion
      );
    };
    return !props?.selectedData?.some(
      obj =>
        obj.assetType === AssetType.Server && isNsTrafficConfigSupported(obj)
    );
  }, [props?.selectedData]);

  const onClose = () => {
    setAgentUpgradeMenuOpen(false);
    setAgentRestartMenuOpen(false);
    setAutoUpgradeMenuOpen(false);
    setAgentDiagnosticMenuOpen(false);
    setAgentDecommissionMenuOpen(false);
    setOpenAgentDebugLogMenu(false);
    setOpenTrafficConfigMenu(false);
    setOpenNsTrafficConfigMenu(false);
    props.hideToolbar();
    requestAPIRefresh();
  };

  const handleButtonChange = (btnAction: string, btnActionType: string) => {
    switch (btnAction) {
      case AgentActionType.UPGRADE:
        setAgentUpgradeMenuOpen(prevOpen => !prevOpen);
        break;
      case AgentActionType.RESTART:
        setAgentRestartMenuOpen(prevOpen => !prevOpen);
        break;
      case AgentActionType.DIAGNOSTIC:
        setAgentDiagnosticMenuOpen(prevOpen => !prevOpen);
        break;
      case AgentActionType.DECOMMISSION:
        setAgentDecommissionMenuOpen(prevOpen => !prevOpen);
        break;
      case AgentActionType.DEBUGLOG:
        if (isString(btnActionType)) {
          setOpenAgentDebugLogMenu(prevOpen => !prevOpen);
          setDebugLogAction(btnActionType);
        }
        break;
      case AgentActionType.TRAFFIC_CONFIG:
        setOpenTrafficConfigMenu(prevOpen => !prevOpen);
        setTrafficConfigAction(btnActionType);
        break;

      case AgentActionType.NS_TRAFFIC_CONFIG:
        setOpenNsTrafficConfigMenu(prevOpen => !prevOpen);
        setNsTrafficConfigAction(btnActionType);
        break;

      default:
        if (isString(btnActionType)) {
          setAutoUpgradeMenuOpen(prevOpen => !prevOpen);
          setAutoUpgradeAction(btnActionType);
        }
        break;
    }
  };

  const getDiagnosticsTooltipMessage = () => {
    return isDiagnosticsButtonDisabled
      ? window.getCTTranslatedText(
          "agentDiagnosticSupportedMinVersionTooltipText",
          {
            AgentDiagnosticSupportedMinVersion,
          }
        )
      : "";
  };

  const getDebugLogTooltipMessage = () => {
    return isDebugLogButtonDisabled
      ? window.getCTTranslatedText("agentDebugLogSupportedMinVersion", {
          AgentDebugLogSupportedMinVersion,
        })
      : "";
  };

  const getTrafficConfigTooltipMessage = () => {
    return isTrafficConfigButtonDisabled
      ? window.getCTTranslatedText("trafficConfigTooltip", {
          AgentTrafficConfigSupportedMinVersion,
        })
      : "";
  };

  const getNsTrafficConfigTooltipMessage = () => {
    return isNsTrafficConfigButtonDisabled
      ? window.getCTTranslatedText("nsTrafficConfigTooltip", {
          AgentNsTrafficConfigSupportedMinVersion,
        })
      : "";
  };

  const getDecommissionTooltipMessage = () => {
    return isDecommissionButtonDisabled
      ? window.getCTTranslatedText("agentDecommissionSupportedMinVersion", {
          AgentDecommissionSupportedMinVersion,
        })
      : "";
  };

  const moreActionMenuOptions: Array<MenuOption> = [
    {
      label: window.getCTTranslatedText("diagnosticsButton"),
      handler: () => handleButtonChange(AgentActionType.DIAGNOSTIC, ""),
      disabled: isDiagnosticsButtonDisabled,
      tooltipMessage: getDiagnosticsTooltipMessage(),
    },
  ];

  if (upgradeAgentPerm) {
    const debugLogSubMenuOptions = [
      {
        label: window.getCTTranslatedText("Enable"),
        handler: () =>
          handleButtonChange(AgentActionType.DEBUGLOG, DebugLogStatus.Enable),
      },
      {
        label: window.getCTTranslatedText("Disable"),
        handler: () =>
          handleButtonChange(AgentActionType.DEBUGLOG, DebugLogStatus.Disable),
      },
    ];

    moreActionMenuOptions.push({
      label: window.getCTTranslatedText("debugLog"),
      disabled: isDebugLogButtonDisabled,
      tooltipMessage: getDebugLogTooltipMessage(),
      type: "subMenu",
      subMenuOptions: debugLogSubMenuOptions,
    });
  }

  if (upgradeAgentPerm && isTrafficConfigEnabled) {
    const trafficConfigSubMenuOptions: Array<MenuItemOption> = [
      {
        label: window.getCTTranslatedText("inboundTraffic"),
        handler: () =>
          handleButtonChange(
            AgentActionType.TRAFFIC_CONFIG,
            TrafficConfigStatus.EnableInboundOnly
          ),
      },
      {
        label: window.getCTTranslatedText("outboundTraffic"),
        handler: () =>
          handleButtonChange(
            AgentActionType.TRAFFIC_CONFIG,
            TrafficConfigStatus.EnableOutboundOnly
          ),
      },
      {
        label: window.getCTTranslatedText("bidirectionalTraffic"),
        handler: () =>
          handleButtonChange(
            AgentActionType.TRAFFIC_CONFIG,
            TrafficConfigStatus.EnableAll
          ),
      },
      {
        label: window.getCTTranslatedText("disableAllTraffic"),
        handler: () =>
          handleButtonChange(
            AgentActionType.TRAFFIC_CONFIG,
            TrafficConfigStatus.DisableAll
          ),
      },
    ];

    moreActionMenuOptions.push({
      label: window.getCTTranslatedText("trafficConfig"),
      disabled: isTrafficConfigButtonDisabled,
      tooltipMessage: getTrafficConfigTooltipMessage(),
      type: "subMenu",
      subMenuOptions: trafficConfigSubMenuOptions,
    });
  }

  if (upgradeAgentPerm && isNsTrafficConfigEnabled) {
    const nsTrafficConfigSubMenuOptions: Array<MenuItemOption> = [
      {
        label: window.getCTTranslatedText("Enable"),
        handler: () =>
          handleButtonChange(
            AgentActionType.NS_TRAFFIC_CONFIG,
            NsTrafficConfigStatus.Enable
          ),
      },
      {
        label: window.getCTTranslatedText("Disable"),
        handler: () =>
          handleButtonChange(
            AgentActionType.NS_TRAFFIC_CONFIG,
            NsTrafficConfigStatus.Disable
          ),
      },
    ];

    moreActionMenuOptions.push({
      label: window.getCTTranslatedText("nsTrafficConfig"),
      disabled: isNsTrafficConfigButtonDisabled,
      tooltipMessage: getNsTrafficConfigTooltipMessage(),
      type: "subMenu",
      subMenuOptions: nsTrafficConfigSubMenuOptions,
    });
  }

  if (upgradeAgentPerm) {
    moreActionMenuOptions.push({
      label: window.getCTTranslatedText("decommissionButton"),
      handler: () => handleButtonChange(AgentActionType.DECOMMISSION, ""),
      disabled: isDecommissionButtonDisabled,
      tooltipMessage: getDecommissionTooltipMessage(),
    });
  }

  const trafficConfigAgentsMutation = useTrafficConfigAgents();
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const requestAgentAPIRefresh = useAgentStore(
    store => store.requestAPIRefresh
  );

  const confirmTrafficConfigAction = async ({
    selectedItems,
    trafficConfigAction,
  }: ConfirmTrafficConfigActionProps) => {
    if (selectedItems) {
      const selectedAgentIds = selectedItems
        ?.map((element: string) => `'${element}'`)
        .join(", ");
      const body = {
        criteria: `agentId in (${selectedAgentIds})`,
        updateDetails: {
          trafficConfiguration: trafficConfigAction,
        },
      };
      await trafficConfigAgentsMutation.mutateAsync(body, {
        onSuccess: response => {
          notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
            label: "AgentTrafficConfigRequestedSuccessfully",
          });
          props.hideToolbar();
          onClose();
          requestAgentAPIRefresh();
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
          onClose();
        },
      });
    }
  };

  const confirmNsTrafficConfigAction = async ({
    selectedItems,
    nsTrafficConfigAction,
  }: ConfirmNsTrafficConfigActionProps) => {
    if (selectedItems) {
      const selectedAgentIds = selectedItems
        ?.map((element: string) => `'${element}'`)
        .join(", ");
      const body = {
        criteria: `agentId in (${selectedAgentIds})`,
        updateDetails: {
          northSouthTrafficConfiguration: nsTrafficConfigAction,
        },
      };
      await trafficConfigAgentsMutation.mutateAsync(body, {
        onSuccess: response => {
          notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
            label: "AgentNsTrafficConfigRequestedSuccessfully",
          });
          props.hideToolbar();
          onClose();
          requestAgentAPIRefresh();
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
          onClose();
        },
      });
    }
  };

  return (
    <>
      {openAgentUpgradeMenu && (
        <AgentUpgradeDrawer
          isOpen={openAgentUpgradeMenu}
          page={"agent"}
          title={"Upgrade Agent"}
          onCancel={onClose}
          data={props?.selectedData}
          columns={AGENT_UPGRADE_COLUMNS}
          rowCount={props?.selection?.length || 0}
          selection={updatedAgentUpgradeSelection}
          hideToolbar={props?.hideToolbar}
        />
      )}

      {openAgentRestartMenu && (
        <AgentRestartDrawer
          isOpen={openAgentRestartMenu}
          page={"agent"}
          title={"Restart Agent"}
          onCancel={onClose}
          data={props?.selectedData}
          columns={AGENT_RESTART_COLUMNS}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedAgentRestartSelection}
          hideToolbar={props?.hideToolbar}
        />
      )}

      {openAgentDiagnosticMenu && (
        <AgentDiagnosticDrawer
          isOpen={openAgentDiagnosticMenu}
          page={"agent"}
          title={"Agent Diagnostics"}
          onCancel={onClose}
          data={props?.selectedData}
          columns={AGENT_DIAGNOSTICS_COLUMNS}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedAgentDiagnosticsSelection}
          hideToolbar={props?.hideToolbar}
        />
      )}

      {openAgentDecommissionMenu && (
        <AgentDecommissionDrawer
          isOpen={openAgentDecommissionMenu}
          page={"agent"}
          title={"agentDecommission"}
          onCancel={onClose}
          data={props?.selectedData}
          columns={AGENT_DECOMMISSION_COLUMNS}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedAgentDecommissionSelection}
          hideToolbar={props?.hideToolbar}
        />
      )}

      {openAgentDebugLogMenu && (
        <AgentDebugLogDrawer
          isOpen={openAgentDebugLogMenu}
          page={"agent"}
          title={"debugLog"}
          onCancel={onClose}
          data={props?.selectedData}
          columns={AGENT_DEBUGLOG_COLUMNS(debugLogAction)}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedDebugLogSelection}
          hideToolbar={props?.hideToolbar}
          debugLogAction={debugLogAction}
        />
      )}

      {openTrafficConfigMenu && props?.selectedData && (
        <AgentTrafficConfigDrawer
          isOpen={openTrafficConfigMenu}
          page={"agent"}
          title={"trafficConfig"}
          onCancel={onClose}
          data={props?.selectedData}
          columns={AGENT_TRAFFIC_CONFIG_COLUMNS(trafficConfigAction)}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedTrafficConfigSelection}
          trafficConfigAction={trafficConfigAction as TrafficConfigStatus}
          onConfirm={confirmTrafficConfigAction}
        />
      )}

      {openNsTrafficConfigMenu && props?.selectedData && (
        <AgentNsTrafficConfigDrawer
          isOpen={openNsTrafficConfigMenu}
          page={"agent"}
          title={"nsTrafficConfig"}
          onCancel={onClose}
          data={props?.selectedData}
          columns={AGENT_NS_TRAFFIC_CONFIG_COLUMNS(nsTrafficConfigAction)}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedNsTrafficConfigSelection}
          nsTrafficConfigAction={nsTrafficConfigAction as NsTrafficConfigStatus}
          onConfirm={confirmNsTrafficConfigAction}
        />
      )}

      {openAutoUpgradeMenu && (
        <AutoUpgradeDrawer
          isOpen={openAutoUpgradeMenu}
          page={"agent"}
          title={"Auto Upgrade Agent"}
          onCancel={onClose}
          data={props?.selectedData}
          columns={AGENT_AUTOUPGRADE_COLUMNS(autoUpgradeAction)}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedAutoUpgradeSelection}
          hideToolbar={props?.hideToolbar}
          autoUpgradeAction={autoUpgradeAction}
        />
      )}

      {(upgradeAgentPerm || createDepKeyPerm) && (
        <GridToolbar {...props}>
          <Stack
            direction="row"
            spacing={3}
            sx={{ width: "100%" }}
            justifyItems="flex-end"
            alignItems={"flex-end"}
            justifyContent="flex-end"
          >
            {props?.selection.length > 0 && upgradeAgentPerm && (
              <>
                <RestartActions
                  {...{ isRestartButtonDisabled, handleButtonChange }}
                />
                <UpgradeActions
                  {...{ isUpgradeButtonDisabled, handleButtonChange }}
                />
                <AutoUpgradeActions {...{ handleButtonChange }} />
                <MoreOptionsMenu
                  menuOptions={moreActionMenuOptions}
                  toolTipPlacement={"left"}
                />
              </>
            )}
            {createDepKeyPerm &&
              !openAgentUpgradeMenu &&
              props?.selection.length === 0 && <AgentDownload route={route} />}
          </Stack>
        </GridToolbar>
      )}
    </>
  );
}
