import { Instruction } from "common/molecules/instructions-renderer/type";
import {
  RegistrationInstructionContent,
  VerifyInstructionContent,
} from "./components/RegisterIntructionsDrawer";

export const RegisterInstructions: Instruction[] = [
  {
    title: "bootupAndLogin",
    subtitle: "bootupAndLoginSubtitle",
  },
  {
    title: "enterApplianceName",
    subtitle: "enterApplianceNameSubtitle",
    terminalCommands: ["enterApplianceNameTerminalCommand1"],
  },
  {
    title: "displayApplianceInformation",
    subtitle: "displayApplianceInformationSubtitle",
    terminalCommands: [
      "displayApplianceInformationTerminalCommand1",
      "displayApplianceInformationTerminalCommand2",
      "displayApplianceInformationTerminalCommand3",
      "displayApplianceInformationTerminalCommand4",
      "displayApplianceInformationTerminalCommand5",
      "displayApplianceInformationTerminalCommand6",
      "displayApplianceInformationTerminalCommand7",
    ],
  },
  {
    title: "mainMenuOptions",
    subtitle: "mainMenuOptionsSubtitle",
    terminalCommands: [
      "mainMenuOptionsTerminalCommand1",
      "mainMenuOptionsTerminalCommand2",
      "mainMenuOptionsTerminalCommand3",
      "mainMenuOptionsTerminalCommand4",
      "mainMenuOptionsTerminalCommand5",
      "mainMenuOptionsTerminalCommand6",
      "mainMenuOptionsTerminalCommand7",
      "mainMenuOptionsTerminalCommand8",
    ],
  },
  {
    title: "networkSettings",
    subtitle: "networkSettingsSubtitle",
    isOptional: true,
    terminalCommands: [
      "networkSettingsTerminalCommand1",
      "networkSettingsTerminalCommand2",
      "networkSettingsTerminalCommand3",
      "networkSettingsTerminalCommand4",
    ],
  },
  {
    title: "registerAppliance",
    subtitle: "registerApplianceSubtitle",
    terminalCommands: [
      "registerApplianceTerminalCommand1",
      "registerApplianceTerminalCommand2",
      "registerApplianceTerminalCommand3",
      "registerApplianceTerminalCommand4",
    ],
    instructionContent: RegistrationInstructionContent,
  },
  {
    title: "verifyAndConfigure",
    instructionContent: VerifyInstructionContent,
  },
];
