import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  FormHelperText,
} from "@mui/material";
import grey from "@mui/material/colors/grey";

import { PathDirectionListProps } from "../template-form/types";

interface Option {
  value: string;
  label: string;
}

export interface CTSelectDropDownProps {
  data?: Option[] | string[]; // Union type of Option[] or string[]
  field: string;
  displayName: string;
  selectedValue:
    | NonNullable<string | PathDirectionListProps | Option>
    | undefined;
  handleUpdate: Function;
  required?: boolean;
  showLabel?: boolean;
  disabled?: boolean;
  textTransform?: string;
  size?: string;
  showSymbols?: boolean;
  helperText?: string;
}

export function CTSelectDropDown({
  field,
  displayName,
  selectedValue,
  handleUpdate,
  data,
  required,
  showLabel,
  disabled,
  textTransform,
  size = "medium",
  showSymbols,
  helperText = "",
}: CTSelectDropDownProps) {
  const handleSelectedValue = async (event: SelectChangeEvent<string>) => {
    const value = event?.target?.value || "";
    handleUpdate({ field, value });
  };

  return (
    <FormControl variant="standard" sx={{ width: "100%" }}>
      {showLabel && (
        <InputLabel
          shrink={true}
          required={required}
          id="status-label"
          size={size === "small" ? "small" : "normal"}
        >
          {window.getCTTranslatedText(displayName)}
        </InputLabel>
      )}
      <Select
        label={showLabel ? window.getCTTranslatedText(displayName) : null}
        labelId="status-label"
        id={field}
        value={selectedValue as NonNullable<string> | undefined}
        onChange={handleSelectedValue}
        displayEmpty
        placeholder={`${window.getCTTranslatedText(
          "Select"
        )} ${window.getCTTranslatedText(displayName)}`}
        disabled={disabled}
        renderValue={selectedValue => {
          if (!selectedValue) {
            return (
              <Typography
                variant={size === "small" ? "body2" : "body1"}
                sx={{
                  color: grey[500],
                }}
              >{`${window.getCTTranslatedText(
                "Select"
              )} ${window.getCTTranslatedText(displayName)}`}</Typography>
            );
          }
          return (
            <Stack direction="row" spacing={1}>
              <Typography
                variant={size === "small" ? "body2" : "body1"}
                sx={{
                  textTransform: textTransform ?? "inherit",
                }}
                noWrap
              >
                {`${window.getCTTranslatedText(selectedValue)} ${
                  showSymbols
                    ? `(${window.getCTTranslatedText(selectedValue)})`
                    : ""
                }`}
              </Typography>
            </Stack>
          );
        }}
      >
        {(data ?? []).map((option, index) => (
          <MenuItem
            key={`option-${typeof option === "string" ? option : option.value}`}
            value={typeof option === "string" ? option : option.value}
          >
            <Stack direction="row" spacing={1}>
              <Typography
                variant="body2"
                sx={{
                  textTransform: textTransform ?? "capitalize",
                }}
                noWrap
              >
                {window.getCTTranslatedText(
                  typeof option === "string" ? option : option.label
                )}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText>
          {window.getCTTranslatedText(helperText)}
        </FormHelperText>
      )}
    </FormControl>
  );
}
