import { useMutation } from "@tanstack/react-query";
import EventEmitter from "events";

const demoChangeEventEmitter = new EventEmitter();
const listener = () => {
  window.location.href = "/";
};
demoChangeEventEmitter.addListener("change", listener);

export function useDemoController() {
  const isDemo = window.sessionStorage.getItem("isDemo") === "true";

  const useDemo = useMutation<undefined, Error, undefined>([
    "tenant-switch",
    "auth/token/demo",
  ]);

  const useHome = useMutation<undefined, Error, undefined>([
    "tenant-switch",
    "auth/token/home",
  ]);

  const handleDemoClick = async () => {
    await useDemo.mutateAsync(undefined);
    window.sessionStorage.setItem("isDemo", "true");
    demoChangeEventEmitter.emit("change", true);
  };

  const handleHomeClick = async () => {
    await useHome.mutateAsync(undefined);
    window.sessionStorage.removeItem("isDemo");
    demoChangeEventEmitter.emit("change", false);
  };

  const exitDemo = () => {
    window.sessionStorage.removeItem("isDemo");
    demoChangeEventEmitter.emit("change", false);
  };

  return {
    isDemo,
    handleDemoClick,
    handleHomeClick,
    exitDemo,
  };
}
