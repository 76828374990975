import { Stack, Tab } from "@mui/material";
import { CtTabs, TabPanel } from "common/atoms/ct-tabs";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { PortProcessTabs } from "pages/ports/components/port-process-drawer.tsx/types";
import { Port } from "pages/ports/types";
import { useState } from "react";
import {
  PORT_PROCESS_NAMES_COLUMNS,
  PORT_PROCESS_PATHS_COLUMNS,
} from "./dataGridConstants";

interface PortProcessDataGridProps {
  data: Port;
  defaultSelection: PortProcessTabs;
}

export const PortProcessDataGrid = ({
  data,
  defaultSelection,
}: PortProcessDataGridProps) => {
  const [selectedTab, setSelectedTab] = useState(
    defaultSelection ?? PortProcessTabs.Paths
  );

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <CtTabs
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
      >
        <Tab label={window.getCTTranslatedText("Port Process Paths")} />
        <Tab label={window.getCTTranslatedText("Port Process Names")} />
      </CtTabs>
      <Stack
        sx={{ width: "100%", flex: 1, overflow: "hidden" }}
        alignItems="flex-start"
      >
        <TabPanel
          value={selectedTab}
          index={PortProcessTabs.Paths}
          style={{ height: "100%" }}
        >
          <DataGrid<string>
            rows={data.listenProcessPaths}
            rowHeight={64}
            columns={PORT_PROCESS_PATHS_COLUMNS}
            pagination
            paginationMode="client"
            sortingMode="client"
            rowCount={data.listenProcessPaths.length}
            getRowId={id => id}
          />
        </TabPanel>
        <TabPanel
          value={selectedTab}
          index={PortProcessTabs.Names}
          style={{ height: "100%" }}
        >
          <DataGrid<string>
            rows={data.listenProcessNames}
            rowHeight={64}
            columns={PORT_PROCESS_NAMES_COLUMNS}
            pagination
            paginationMode="client"
            sortingMode="client"
            rowCount={data.listenProcessNames.length}
            getRowId={id => id}
          />
        </TabPanel>
      </Stack>
    </Stack>
  );
};
