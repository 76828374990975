import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Stack } from "@mui/material";
import { getRelativeLastObserved, parseErrorMessage } from "common/utils";
import { FormFields, DRAWER_TITLES } from "modules/form-fields";
import { Field, FieldType } from "modules/form-fields/type";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
  processChannelConfig,
  processIntegrationFormatData,
} from "pages/Integration/IntegrationUtils";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import React, { useEffect, useState } from "react";
import {
  INTEGRATIONS,
  INTEGRATION_CATEGORY,
  INTEGRATION_TYPES,
  IntegrationConfigType,
  Integration,
  Reconciliation,
  getIntegrationEnum,
} from "../../constants";
import {
  useIntegrationFieldsAPI,
  useIntegrationGetAPI,
  useTestIntegrationConnection,
} from "../../hooks";
import { FormExtrasAfter } from "../../tabs/authentication/components/form-extras-after";
import { FormExtrasBefore } from "../../tabs/authentication/components/form-extras-before";
import { IntegrationStaticData } from "../integration-static-data";
import { IntegrationToolbarAction } from "../integration-toolbar-action";
import { MappingConfig } from "../serviceNow-config";
import { useIntegrationUpdateConfig } from "./hooks/useIntegrationUpdateConfig";

interface IntegrationDetailsDrawerProps {
  open: boolean;
  onClose: () => void;
  loaderActive: (isLoading: boolean, item: IntegrationConfigType) => void;
  integration: IntegrationConfigType;
}

export const IntegrationDetailsDrawer: React.FC<
  IntegrationDetailsDrawerProps
> = ({ open, onClose, integration, loaderActive }) => {
  const selectedValue = integration;

  const { data: formConfig = [] } = useIntegrationFieldsAPI(
    integration.integrationType,
    integration.integrationProvider
  );

  const { data: fieldData = {}, isLoading: isFieldDataLoading } =
    useIntegrationGetAPI(
      integration.integrationType,
      integration.integrationProvider
    );

  const [textFieldsValues, setTextFieldsValues] = useState<any>(
    fieldData.integrationParameters || {} || []
  );
  const [testTriggered, setTestTriggered] = useState<boolean>(false);

  const [mappingData, setMappingData] = useState<Reconciliation>({
    reconciliationIdentifier: [],
    reconciliationAttributes: [],
  });

  const testConfigMutation = useTestIntegrationConnection(
    integration.integrationType,
    integration.integrationProvider
  );

  const { isLoading, updateIntegrationConfig } =
    useIntegrationUpdateConfig(integration);

  const callUpdateConfig = () => {
    updateIntegrationConfig(
      formConfig,
      integration,
      textFieldsValues,
      onClose,
      mappingData
    );
  };

  const setSnackbar = useSnackbarStore((state: any) => state.setSnackbar);
  const [lastSyncTime, setLastSyncTime] = useState<string>("");
  const [integrationLoaded, setIntegrationLoaded] = useState(false);

  useEffect(() => {
    if (fieldData) {
      if (fieldData.integrationParameters) {
        if (
          getIntegrationEnum(fieldData) === INTEGRATIONS.MSTEAMS_COLLABORATION
        ) {
          const channelConfig = processChannelConfig(
            fieldData.integrationParameters.channels
          );
          setTextFieldsValues(channelConfig);
        } else {
          setTextFieldsValues(fieldData.integrationParameters);
          if (getIntegrationEnum(fieldData) === INTEGRATIONS.SERVICENOW_CMDB) {
            const mappingReconciliationData = {
              reconciliationIdentifier:
                fieldData.integrationParameters.reconciliationIdentifier,
              reconciliationAttributes:
                fieldData.integrationParameters.reconciliationAttributes,
            };
            updateMappingData(mappingReconciliationData);
          }
          setIntegrationLoaded(true);
        }
      }
      const lastSyncTime = fieldData.lastSyncTime
        ? getRelativeLastObserved(fieldData.lastSyncTime)
        : "";
      setLastSyncTime(lastSyncTime);
    }
  }, [fieldData, integration, integrationLoaded]);

  const testConfig = () => {
    if (textFieldsValues?.status) {
      delete textFieldsValues.status;
    }

    const processData = processIntegrationFormatData(
      integration,
      formConfig,
      textFieldsValues
    );
    const body: Integration = {
      integrationProvider: integration.integrationProvider,
      integrationType: integration.integrationType,
      integrationParameters: processData,
    };

    testConfigMutation.mutateAsync(body, {
      onSuccess: response => {
        setTestTriggered(true);
        const message =
          integration?.integrationType !== INTEGRATION_TYPES.SIEM
            ? "integrationConfigurationTestSuccessful"
            : "integrationTestSuccessful";

        const variables = {
          name:
            integration?.integrationType !== INTEGRATION_TYPES.SIEM
              ? capitalizeFirstLetter(integration.name)
              : integration.displayCardName,
        };
        setSnackbar(true, SnackBarSeverity.Success, message, variables);
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const updateMappingData = (data: Reconciliation) => {
    setMappingData(data);
  };

  const drawerToolbar = () =>
    getIntegrationEnum(integration) !== INTEGRATIONS.VSPHERE_CONNECTORS && (
      <IntegrationToolbarAction
        formConfig={formConfig}
        textFieldsValues={textFieldsValues}
        handlerOnCloseDrawer={onClose}
        updateConfig={callUpdateConfig}
        loading={isLoading}
      />
    );
  const hasErrors = () => {
    const iState: { [key: string]: string } = {};
    let ddChilsElements: Field[] = [];
    const processField = (field: Field) => {
      if (field.type !== FieldType.MAPPING && field.type !== FieldType.STATIC) {
        if (field.type !== FieldType.SECTION && field.required) {
          iState[field.key] = textFieldsValues[field.key];
        }
        field?.children?.forEach(processField);
      }

      if (field.type === FieldType.DROPDOWN && field.required) {
        field?.possibleValues?.forEach(possibleValue => {
          if (possibleValue?.children?.length) {
            const filterVal = field?.possibleValues?.filter(
              obj => obj.key === iState[field.key]
            );
            if (filterVal?.length) {
              filterVal[0].children?.forEach(child => {
                if (child.children?.length) {
                  ddChilsElements = child.children;
                }
              });
            }
          }
          possibleValue?.children?.forEach(processField);
        });
      }
    };
    formConfig?.forEach(processField);
    if (ddChilsElements.length) {
      for (const field of ddChilsElements) {
        if (checkValid(iState, field.key) && field.required) {
          return true;
        }
      }
    } else {
      for (const key in iState) {
        if (checkValid(iState, key)) {
          return true;
        }
      }
    }

    return false;
  };

  const checkValid = (iState: { [key: string]: string }, key: string) => {
    if (typeof iState[key] === "object") {
      if (!Object.keys(iState[key] ?? {}).length) {
        return true;
      }
    } else {
      if (
        !iState[key]?.toString()?.length ||
        iState[key]?.toString()?.length === 0
      ) {
        return true;
      }
    }

    return false;
  };

  const formExtrasAfter = () => {
    const staticData = formConfig.filter(
      (item: Field) => item.type === "static"
    );
    switch (getIntegrationEnum(integration)) {
      case INTEGRATIONS.CLAROTY_CMDB:
      case INTEGRATIONS.MEDIGATE_CMDB:
      case INTEGRATIONS.RAPID7_VULNERABILITYSCANNER:
      case INTEGRATIONS.SPLUNK_SIEM:
      case INTEGRATIONS.QRADAR_SIEM:
      case INTEGRATIONS.OTHERS_SIEM:
      case INTEGRATIONS.ARCSIGHT_SIEM:
      case INTEGRATIONS.SERVICENOW_CMDB:
      case INTEGRATIONS.MSTEAMS_COLLABORATION:
      case INTEGRATIONS.NESSUS_VULNERABILITYSCANNER:
        return (
          <>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                loading={testConfigMutation.isLoading}
                variant="contained"
                type="submit"
                disabled={hasErrors()}
                onClick={() => testConfig()}
                sx={{ ml: 1, mt: 2 }}
              >
                {window.getCTTranslatedText("Test")}
              </LoadingButton>
            </Stack>
            {getIntegrationEnum(integration) ===
              INTEGRATIONS.SERVICENOW_CMDB && (
              <MappingConfig
                formConfig={formConfig}
                integration={integration}
                textFieldsValues={textFieldsValues}
                fieldData={fieldData}
                isFieldDataLoading={isFieldDataLoading}
                testTriggered={testTriggered}
                setTestTriggered={setTestTriggered}
                updateMappingData={updateMappingData}
              ></MappingConfig>
            )}
            {staticData.length > 0 && (
              <IntegrationStaticData
                field={staticData[0]}
                integration={selectedValue}
              ></IntegrationStaticData>
            )}
          </>
        );

      case INTEGRATIONS.CROWDSTRIKE_EDR:
        return (
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              loading={testConfigMutation.isLoading}
              variant="contained"
              type="submit"
              disabled={hasErrors()}
              onClick={() => testConfig()}
              sx={{ ml: 1, mt: 2 }}
            >
              {window.getCTTranslatedText("Test")}
            </LoadingButton>
          </Stack>
        );

      case INTEGRATIONS.OAUTH_IDENTITYPROVIDER:
      case INTEGRATIONS.SAML_IDENTITYPROVIDER:
        return <FormExtrasAfter selectedValue={selectedValue} />;
      case INTEGRATIONS.VSPHERE_CONNECTORS:
        return (
          <>
            {staticData.length > 0 && (
              <IntegrationStaticData
                field={staticData[0]}
                integration={selectedValue}
                showHeading={false}
              />
            )}
          </>
        );
      default:
        return <></>;
    }
  };
  const formExtrasBefore = () => {
    switch (getIntegrationEnum(integration)) {
      case INTEGRATIONS.OAUTH_IDENTITYPROVIDER:
      case INTEGRATIONS.SAML_IDENTITYPROVIDER:
        return <FormExtrasBefore selectedValue={selectedValue} />;
      case INTEGRATIONS.SPLUNK_SIEM:
      case INTEGRATIONS.ARCSIGHT_SIEM:
      case INTEGRATIONS.QRADAR_SIEM:
      case INTEGRATIONS.OTHERS_SIEM:
        if (lastSyncTime) {
          return (
            <Alert severity="info" sx={{ my: 3 }}>
              {window.getCTTranslatedText("lastSyncedAt", {
                lastSyncedAt: lastSyncTime,
              })}
            </Alert>
          );
        } else {
          return <></>;
        }
      case INTEGRATIONS.CROWDSTRIKE_EDR:
        return (
          <Alert severity="info" sx={{ mt: 2 }}>
            {window.getCTTranslatedText("SavingConfigurationImportsHostGroups")}
          </Alert>
        );
      case INTEGRATIONS.MSTEAMS_COLLABORATION:
        return (
          <Alert severity="info" sx={{ mt: 2 }}>
            {window.getCTTranslatedText("validateTeamsConfig")}
          </Alert>
        );
      default:
        return <></>;
    }
  };
  const getDialogTitle = () => {
    if (selectedValue.category === INTEGRATION_CATEGORY.SIEM) {
      return selectedValue?.displayCardName ?? "";
    }
    return selectedValue?.name ?? "";
  };

  const getTitleTemplate = () => {
    if (getIntegrationEnum(selectedValue) === INTEGRATIONS.VSPHERE_CONNECTORS) {
      return DRAWER_TITLES.CONFIGURE_AND_INSTALL;
    }
    return DRAWER_TITLES.CONFIGURE;
  };

  return (
    <FormFields
      textFieldsValues={textFieldsValues}
      setTextFieldsValues={setTextFieldsValues}
      formConfig={formConfig ?? []}
      key={selectedValue?.name}
      isDrawer={true}
      dialogTitle={getDialogTitle()}
      titleTemplate={getTitleTemplate()}
      onClose={onClose}
      drawerToolbar={drawerToolbar}
      formExtrasAfter={formExtrasAfter}
      formExtrasBefore={formExtrasBefore}
    />
  );
};
