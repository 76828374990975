import { Grid } from "@mui/material";
import { Scope } from "modules/scope-metadata/types";
import { PathDirection } from "pages/paths/types";
import { useState } from "react";
import { CTScopeAutoSuggest } from "../../../../../../modules/ct-scope-auto-suggest";
import { CTSelectDropDown } from "../ct-select";
import {
  MapPathFields,
  PathDirectionListProps,
  PathFieldDisplayText,
  PathFieldKeys,
  PathFormInt,
  PathFormProps,
  ProtocolOptionsList,
} from "../template-form/types";
import { AllowedField, Suggestion } from "modules/ct-scope-auto-suggest/types";

export function PathForm({
  path,
  updatePathData,
  showLabel = true,
}: PathFormProps) {
  const [pathSuggestion, setPathSuggestion] = useState<
    Suggestion | undefined
  >();
  const [portSuggestion, setPortSuggestion] = useState<
    Suggestion | undefined
  >();

  const handleUpdate = ({
    field,
    value,
  }: {
    field: string;
    value?: PathDirectionListProps | string;
  }) => {
    if (field) {
      setFieldValue({
        data: path,
        key: field as PathFieldKeys,
        value: value as string | (string & PathDirectionListProps),
        updateData: updatePathData,
      });
    }
  };

  const handlePortSuggestion = (suggestion?: Suggestion) => {
    setPortSuggestion(suggestion);
    setFieldValue({
      data: path,
      key: PathFieldKeys.Port,
      value: suggestion?.value ?? "",
      updateData: updatePathData,
    });
  };

  const handlePathSuggestion = (suggestion?: Suggestion) => {
    setPathSuggestion(suggestion);
    setFieldValue({
      data: path,
      key:
        path.direction === PathDirection.Inbound
          ? PathFieldKeys.Source
          : PathFieldKeys.Destination,
      value: suggestion ?? "",
      updateData: updatePathData,
    });
  };

  interface selectedValueProps {
    path: PathFormInt;
    key: PathFieldKeys;
  }

  const getSelectedValue = ({
    path,
    key,
  }: selectedValueProps): string | undefined | PathDirectionListProps => {
    return path && key && path[key] ? path[key] : "";
  };

  return (
    <>
      <Grid container columnSpacing={3} sx={{ width: "100%" }}>
        <Grid item xs>
          <CTSelectDropDown
            field={PathFieldKeys.Protocol}
            displayName={PathFieldDisplayText[PathFieldKeys.Protocol]}
            selectedValue={getSelectedValue({
              path,
              key: PathFieldKeys.Protocol,
            })}
            handleUpdate={handleUpdate}
            data={ProtocolOptionsList}
            required={Boolean(
              MapPathFields[path[PathFieldKeys.Direction]][
                PathFieldKeys.Protocol
              ]
            )}
            showLabel={showLabel}
          />
        </Grid>

        <Grid item xs>
          <CTScopeAutoSuggest
            displayName={window.getCTTranslatedText("Port")}
            scopeConfig={[
              {
                scope: Scope.Path,
                field: AllowedField.PathPort,
              },
            ]}
            required={true}
            showLabel={showLabel}
            selectedValue={portSuggestion}
            handleUpdate={handlePortSuggestion}
          />
        </Grid>

        <Grid item xs>
          <CTScopeAutoSuggest
            displayName={window.getCTTranslatedText(
              PathFieldDisplayText[
                path.direction === PathDirection.Inbound
                  ? "source"
                  : "destination"
              ]
            )}
            selectedValue={pathSuggestion}
            handleUpdate={handlePathSuggestion}
            scopeConfig={[
              {
                scope: Scope.Path,
                field:
                  path.direction === PathDirection.Inbound
                    ? AllowedField.PathWithoutDomain
                    : AllowedField.PathWithDomain,
              },
              {
                scope: Scope.Network,
              },
              {
                scope: Scope.TagBasedPolicy,
              },
              {
                scope: Scope.Asset,
              },
            ]}
            required={true}
            showLabel={showLabel}
          />
        </Grid>
      </Grid>
    </>
  );
}

interface FieldValueProps {
  data: PathFormInt;
  key: PathFieldKeys;
  value: Suggestion | string | any;
  updateData: Function;
}

function setFieldValue({ data, key, value, updateData }: FieldValueProps) {
  if (value !== data[key]) {
    let newDataObj = { ...data };
    newDataObj[key] = value;
    updateData(newDataObj);
  }
}
