import { platforms } from "pages/agents/components/agent-download-page/constants";
import { AgentVersionDetails } from "pages/agents/components/agent-download-page/type";

export function getAvailableContainerPlatforms(
  platformAgentData: AgentVersionDetails
) {
  return Object.keys(platformAgentData)
    .filter(key => key === platforms.DOCKER)
    .sort()
    .reverse();
}
