import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { CTAlert } from "pages/monitoring/types";
import { useAlertStatusUpdateAPI } from "./hooks";
import { useAlertsStore } from "pages/monitoring/store";
import { blue } from "@mui/material/colors";

interface AlertProps {
  record: CTAlert;
}

export function AlertsAction({ record }: Readonly<AlertProps>) {
  const requestAPIRefresh = useAlertsStore(store => store.requestAPIRefresh);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const assignTemplateToTags = useAlertStatusUpdateAPI();

  const clickCallback = (event: any) => {
    if (event) {
      event.stopPropagation();
      const body = {
        criteria: `alerthash in (${record.alertHash})`,
      };
      assignTemplateToTags.mutateAsync(body, {
        onSuccess: response => {
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            "AlertDismissSuccessfully"
          );
          requestAPIRefresh();
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  };

  return (
    <>
      {record.status.toLowerCase() === "pending" && (
        <Tooltip title={window.getCTTranslatedText("Dismiss")}>
          <Stack
            sx={{
              maxWidth: "100%",
              cursor: "pointer",
            }}
            alignItems="center"
            justifyContent="center"
            direction="row"
            onClick={clickCallback}
          >
            <IconButton sx={{ my: 0 }} size="medium">
              <RemoveCircleOutlineIcon
                fontSize="small"
                sx={{ color: blue[600] }}
              />
            </IconButton>
          </Stack>
        </Tooltip>
      )}
    </>
  );
}
