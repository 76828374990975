import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { useCommonStore } from "common/store";
import { SankeyChart } from "modules/sankey-vis";
import { useMemo, useState } from "react";
import { Scope } from "../scope-metadata/types";
import { Dimension, HierarchyVisProps } from "./types";
import { useTagsAPI } from "pages/tags/hooks";

const FIRST_DIMENSIONS: { [key: string]: boolean } = {
  assetinboundstatus: true,
  assetoutboundstatus: true,
  attacksurface: true,
  blastradius: true,
  assetrisk: true,
  assetinternetfacing: true,
  businessvalue: true,
  listenportprotocol: true,
  listenportname: true,
  listenportreviewed: true,
  osname: true,
  application: true,
  environment: true,
  location: true,
  owner: true,
  role: true,
  type: true,
  listenport: true,
  model: true,
  category: true,
  subcategory: true,
  manufacturer: true,
  portcategory: true,
};

const SankeyDimensionsAsset = [
  {
    name: "assetrisk",
    label: "Breach Impact",
    dataType: "",
  },
  {
    name: "environment",
    label: "Environment",
    dataType: "",
  },
  {
    name: "location",
    label: "Location",
    dataType: "",
  },
];

const SankeyDimensionsPort = [
  {
    name: "assetrisk",
    label: "Breach Impact",
    dataType: "",
  },
  {
    name: "environment",
    label: "Environment",
    dataType: "",
  },
  {
    name: "location",
    label: "Location",
    dataType: "",
  },
];

export interface ControllerProps {
  scope: Scope;
  defaultDimensionName: string;
}

export function HierarchyVisController(props: ControllerProps) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(true);
  const { tagFields: tags } = useTagsAPI();

  // const facetValue = useCommonStore(state => state.facets);
  // const updateFacetOption = useCommonStore(state => state.updateFacetOption);

  const metadata = useCommonStore(state => state.metadata);

  const visControllerOptions: HierarchyVisProps | undefined = useMemo(() => {
    const columns = metadata?.columns || {};
    let columnNames = Object.keys(columns).filter(columnName => {
      return metadata?.columns?.[columnName]?.searchable || false;
    });
    const options = columnNames.map(columnName => {
      const columnMetadata = metadata?.columns?.[columnName];
      return {
        label: columnMetadata?.displayName || "",
        name: columnName,
        dataType: columnMetadata?.dataType || "",
      };
    });

    if (!options.length) {
      return undefined;
    }

    const userDefinedTagsList: {
      label: string;
      name: string;
      dataType: string;
    }[] =
      tags?.userDefinedTags?.map(coreTag => ({
        label: coreTag.displayName,
        name: coreTag?.name || "",
        dataType: coreTag.dataType,
      })) ?? [];

    return {
      dimensionOptions: [
        ...options
          .filter(option => FIRST_DIMENSIONS[option.name])
          .sort((a, b) =>
            a?.label && b?.label ? a.label.localeCompare(b.label) : -1
          ),
        ...userDefinedTagsList,
      ],
      dimensionDefaultOption:
        options.find(option => option.name === props.defaultDimensionName) ||
        options[0],
      scope: props.scope,
    };
  }, [
    metadata?.columns,
    props.defaultDimensionName,
    props.scope,
    tags?.userDefinedTags,
  ]);

  const sankeyDimensions: Array<Dimension> =
    props.scope === Scope.Asset ? SankeyDimensionsAsset : SankeyDimensionsPort;
  return (
    <Paper sx={{ mb: 2 }}>
      <Accordion
        sx={{
          ".MuiAccordionSummary-root": {
            maxHeight: 48,
            minHeight: 48,
          },
          background: theme.palette.contrastThreshold,
        }}
        elevation={0}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          sx={{ py: 0, my: 0 }}
          expandIcon={<ExpandMoreOutlined />}
        >
          <Stack>
            <Typography>{window.getCTTranslatedText("Summary")}</Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails sx={{ pt: 0 }}>
          {visControllerOptions && (
            <>
              <SankeyChart
                scope={props.scope}
                dimensions={sankeyDimensions}
                dimensionOptions={visControllerOptions.dimensionOptions}
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
