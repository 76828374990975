import { Box, Stack, useTheme } from "@mui/material";
// import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
// import { useCore } from "modules/core";
// import { FacetControllerGroup } from "modules/facets";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import { Loader } from "common/atoms/loader";
import { FacetGroupInfo } from "modules/core/types";
import { useScopeMetadata } from "modules/scope-metadata";
// import { useTagRulesListAPI } from "pages/tags/components/tag-rules-list/TagRulesList";
// import {
//   useTagRuleFacetStore,
//   useTagRuleStore,
// } from "pages/tags/components/tag-rules-list/store";
import { RuleForm } from "./components/RuleForm";
import { useFacetOrder } from "pages/tags/components/tag-rules-list/components/matching-tags/MatchingTags";

export function CreateTagRule() {
  let { data: metadata } = useScopeMetadata({ scope: Scope.TagRule });
  const facetOrder = useFacetOrder(metadata);

  if (!facetOrder || !metadata) {
    return <Loader />;
  }

  return <CreateTagRuleCore facetOrder={facetOrder} metadata={metadata} />;
}

export function CreateTagRuleCore({
  facetOrder,
  metadata,
}: {
  facetOrder: FacetGroupInfo;
  metadata: ScopeMetadata;
}) {
  const theme = useTheme();

  // const coreResponse = useCore<Scope.TagRule>({
  //   useStore: useTagRuleStore,
  //   facetGroupInfo: facetOrder,
  //   scope: Scope.TagRule,
  //   useFacetStore: useTagRuleFacetStore,
  //   useApi: useTagRulesListAPI,
  //   pageSize: 1,
  // });

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      {/* <Stack direction={"row"} alignItems="center">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            useFacetStore={useTagRuleFacetStore}
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
            title="Tag Rule Filters"
            dialogTitle="Apply Tag Rule Filters"
          >
            <FacetControllerGroup
              useFacetStore={useTagRuleFacetStore}
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack> */}

      <Box
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowY: "auto",
          flex: 1,
          height: "100%",
        }}
      >
        <RuleForm facetOrder={facetOrder} metadata={metadata} />
      </Box>
    </Stack>
  );
}
