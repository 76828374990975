import { Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DestinationIPs } from "pages/paths/components/destination-ip-drawer/types";

export const DESTINATION_IPS_COLUMNS: GridColDef<DestinationIPs>[] = [
  {
    field: "dstIp",
    headerName: "Destination IPs",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <Typography variant="body2">{params.row}</Typography>;
    },
  },
];
