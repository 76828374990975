import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

import { CloneDataGridRowAction } from "modules/clone-datagrid-row";
import { TemplateDelete } from "pages/templates/components/template-delete";
import { useTemplateStore } from "pages/templates/store";
import { TemplateColumnRenderProps } from "pages/templates/types";
import { useState } from "react";

export function useTemplateCloneAPI() {
  return useMutation<any, Error, any>(["ports", "templates/clone"]);
}

export function ActionTemplateDialog({ template }: TemplateColumnRenderProps) {
  const [allowDelete, setAllowDelete] = useState(false);
  const apiRefreshPath = useTemplateStore(state => state.requestAPIRefresh);

  const handleAllowDelete = (event: any, value: boolean) => {
    if (event) {
      event.stopPropagation();
    }
    if (!template?.oobTemplate) {
      setAllowDelete(value);
    }
  };

  if (!template.templateId) {
    return <Box style={{ width: 100 }} />;
  }

  const generateBody = () => {
    return {
      templateName: `${template.templateName} ${window.getCTTranslatedText(
        "(copy)"
      )}`,
      templateDescription: template.templateDescription
        ? `${template.templateDescription} ${window.getCTTranslatedText(
            "(copy)"
          )}`
        : "",
      srcTemplateID: template.templateId,
    };
  };

  const isDisabled =
    Boolean(template.templateTagBasedPolicyAssignments) ||
    template?.oobTemplate ||
    Boolean(template.templateUnassignmentsPendingFirewallSynchronize);

  let tooltipText = "Delete template";

  if (
    Boolean(template.templateUnassignmentsPendingFirewallSynchronize) ||
    Boolean(template.templateTagBasedPolicyAssignments)
  ) {
    tooltipText = "Template cannot be deleted when it is being used";
  }

  if (template?.oobTemplate) {
    tooltipText = "System template cannot be deleted";
  }

  return (
    <Box>
      <Stack direction="row">
        <IconButton
          aria-label="Delete"
          onClick={$event => handleAllowDelete($event, true)}
          disableRipple={isDisabled}
          sx={{ cursor: isDisabled ? `not-allowed` : "pointer" }}
        >
          <Tooltip title={window.getCTTranslatedText(tooltipText)}>
            <DeleteOutlineIcon color={isDisabled ? "disabled" : "action"} />
          </Tooltip>
        </IconButton>
        {
          <CloneDataGridRowAction
            generateBody={generateBody}
            title="Clone Template"
            refreshAPI={apiRefreshPath}
            useAPI={useTemplateCloneAPI}
          />
        }
      </Stack>
      {allowDelete && (
        <TemplateDelete
          template={template}
          allowDelete={allowDelete}
          handleAllowDelete={value => setAllowDelete(value)}
        />
      )}
    </Box>
  );
}
