import { Button, Stack } from "@mui/material";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import {
  GridToolbar,
  ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { usePortStore } from "pages/ports/store";
import { Port, PortStatus } from "pages/ports/types";
import { useEffect, useState } from "react";
import { PortsViewDrawer } from "../ports-review-drawer/PortsViewDrawer";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";

export interface PortToolbarProps extends Omit<ToolbarProps, "children"> {
  selectedData: Array<Port> | undefined;
  showPortStatusOption: boolean;
  recommendationId?: string;
}
export default function PortToolbar(props: PortToolbarProps) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const requestAPIRefresh = usePortStore(store => store.requestAPIRefresh);
  const [selectedOperation, setSelectedOperation] = useState<
    PortStatus | undefined
  >(undefined);
  const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);

  const [openMenu, setMenuOpen] = useState(false);

  useEffect(() => {
    if (props.recommendationId) {
      const StatusRecommendationMap: any = {
        portsunusednotraffic: PortStatus.Deny,
        portsusednointernettraffic: PortStatus.AllowIntranet,
        pathrestrictedfewpaths: PortStatus.PathRestricted,
        sensitiveports: PortStatus.PathRestricted,
      };

      const status = StatusRecommendationMap[props.recommendationId];
      setSelectedOperation(status);
      setMenuOpen(true);
    }
  }, [props.recommendationId]);

  const handleMenuToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const confirmPortsStatus = () => {
    setSelectedOperation(undefined);
    props.hideToolbar();
    requestAPIRefresh();
  };

  const handleAddToTemplateDialogOpen = (dialogVisibility: boolean) => {
    setShowAddToTemplateDialog(dialogVisibility);
  };

  const handleClose = () => {
    setShowAddToTemplateDialog(false);
  };

  const addToTemplate = () => {
    setShowAddToTemplateDialog(false);
    requestAPIRefresh();
    props.hideToolbar();
  };

  const actions = (
    <Stack direction="row">
      <Button
        variant="outlined"
        aria-label="change port status"
        aria-haspopup="menu"
        onClick={handleMenuToggle}
      >
        Change Port status
      </Button>
    </Stack>
  );

  return (
    <GridToolbar {...props}>
      {props.selectedData && props.selectedData.length > 0 && (
        <Stack
          direction="row"
          spacing={3}
          sx={{ width: "100%" }}
          justifyItems="flex-end"
          alignItems={"flex-end"}
          justifyContent="flex-end"
        >
          <AddToTemplateDrawer
            isOpen={showAddToTemplateDialog}
            page={"ports"}
            title="Add to Template"
            rules={props.selectedData}
            showPortStatusOption={props.showPortStatusOption}
            onCancel={handleClose}
            onConfirm={addToTemplate}
            btnTitle={"add"}
            actionType={TemplateActionType.add}
          ></AddToTemplateDrawer>
          {userPermissions.has("UPDATE_PORT") && actions}

          {userPermissions.has("UPDATE_TEMPLATE") && (
            <Stack direction="row" alignItems={"center"}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleAddToTemplateDialogOpen(true)}
              >
                Add to template
              </Button>
            </Stack>
          )}

          {openMenu && (
            <PortsViewDrawer
              isOpen={openMenu}
              selectedStatus={selectedOperation}
              selectedData={props?.selectedData}
              title={"Change Port status"}
              confirm={confirmPortsStatus}
              onClose={() => {
                setSelectedOperation(undefined);
                props.hideToolbar();
              }}
            />
          )}
        </Stack>
      )}
    </GridToolbar>
  );
}
