import { Box, Link, Stack, Tooltip } from "@mui/material";
import { OVERWRITE_FACETS } from "modules/facets/constants";
import numeral from "numeral";
import pluralize from "pluralize";
import { Link as RouterLink } from "react-router-dom";
import { TagPolicy } from "../../types";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";

export function MatchedAssetCount({ policy }: { policy: TagPolicy }) {
  const { isFeatureEnabled: isSegmentsEnabled } = useFeatureFlagControl(
    FEATURES.SEGMENTS
  );

  if (!policy.tagBasedPolicyId) {
    return <Box style={{ minWidth: 120, minHeight: 45 }} />;
  }

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
      <Stack alignItems="flex-start">
        <Tooltip title={window.getCTTranslatedText("View assets")}>
          <Link
            typography={"body2"}
            component={RouterLink}
            to={`/assets?${policy.criteriaAsParams}&${OVERWRITE_FACETS}`}
            sx={{
              color: "primary.main",
              textDecoration: isSegmentsEnabled ? "none" : undefined,
            }}
          >
            {numeral(policy?.matchingAssets ?? 0).format("0a")}{" "}
            {!isSegmentsEnabled &&
              pluralize(
                window.getCTTranslatedText("asset"),
                policy.matchingAssets ?? 0
              )}
          </Link>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
