import merge from "lodash/merge";
import { Dimension } from "modules/hierarchy-vis/types";
import { usePathStore } from "pages/paths/store";
import { usePortStore } from "pages/ports/store";
import { useEffect, useMemo, useRef } from "react";
import { useVisxStore } from "../store";
import { PathReviewStatus, StatisticType } from "../types";
import { ParentNodeName, useTrafficData } from "./useTrafficData";

export interface VisualizerLoaderProps {
  trafficCriteria: string | undefined;
  sourceCriteria: string | undefined;
  destinationCriteria: string | undefined;
  selectedSourceDimension: Dimension | undefined;
  selectedDestinationDimension: Dimension | undefined;
  syncWithStore?: boolean;
  tag: string;
  loadAssets?: boolean;
  topParent?: ParentNodeName;
  leafParent?: ParentNodeName;
}

export function useVisualizerData({
  trafficCriteria,
  sourceCriteria,
  destinationCriteria,
  selectedSourceDimension,
  selectedDestinationDimension,
  syncWithStore = false,
  tag,
  loadAssets,
  topParent,
  leafParent,
}: VisualizerLoaderProps) {
  const selectedDirection = useVisxStore(state => state.selectedDirection);

  const selectedReviewStatus = useVisxStore(state => state.selectedPathStatus);

  const enforcedStatName = StatisticType.Enforced;

  const candidateStatName =
    selectedReviewStatus === PathReviewStatus.WIP ||
    selectedReviewStatus === PathReviewStatus.DIFF
      ? StatisticType.Candidate
      : undefined;

  const {
    trafficData: enforcedTrafficData,
    isLoading: enforcedLoading,
    isEmpty: enforcedEmpty,
    reload: enforcedReload,
  } = useTrafficData({
    criteria: trafficCriteria,
    sourceCriteria,
    destinationCriteria,
    selectedSourceDimension,
    selectedDestinationDimension,
    statisticsName: enforcedStatName,
    selectedDirection,
    loadAssets: loadAssets && Boolean(enforcedStatName),
    tag,
    topParent,
    leafParent,
  });

  const { trafficData: candidateTrafficData, reload: candidateReload } =
    useTrafficData({
      criteria: trafficCriteria,
      sourceCriteria,
      destinationCriteria,
      selectedSourceDimension,
      selectedDestinationDimension,
      statisticsName: candidateStatName,
      selectedDirection,
      tag,
      topParent,
      leafParent,
    });

  const isLoading = enforcedLoading;

  const isEmpty = enforcedEmpty;

  const reload = () => {
    enforcedReload();
    candidateReload();
  };

  // TODO: optimise query load
  const initialOpenTime = useRef(Date.now());
  const requestPathsRefresh = usePathStore(state => state.apiRefreshRequest);
  const requestPortsRefresh = usePortStore(state => state.apiRefreshRequest);

  const reloadRef = useRef(reload);
  reloadRef.current = reload;

  useEffect(() => {
    if (requestPathsRefresh && requestPathsRefresh > initialOpenTime.current) {
      reloadRef.current();
    }
  }, [requestPathsRefresh]);

  useEffect(() => {
    if (requestPortsRefresh && requestPortsRefresh > initialOpenTime.current) {
      reloadRef.current();
    }
  }, [requestPortsRefresh]);

  const trafficData = useMemo(() => {
    if (selectedReviewStatus === PathReviewStatus.Enforced) {
      return enforcedTrafficData;
    } else if (enforcedTrafficData || candidateTrafficData) {
      return merge({}, enforcedTrafficData, candidateTrafficData);
    }
  }, [candidateTrafficData, enforcedTrafficData, selectedReviewStatus]);

  const setIsLoading = useVisxStore(state => state.setIsLoading);
  const addTrafficData = useVisxStore(state => state.addTrafficData);

  useEffect(() => {
    if (!syncWithStore) {
      return;
    }
    setIsLoading(isLoading);
    if (trafficData) {
      addTrafficData(trafficData);
    }
  }, [isLoading, setIsLoading, addTrafficData, syncWithStore, trafficData]);

  return {
    isLoading,
    isEmpty,
    trafficData,
    reload,
  };
}
