import { useTheme } from "@mui/material";
import { getColor } from "common/constants/colors";
import { LinearProgressChart } from "@colortokens/ng-ui";
import numeral from "numeral";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { AssetSecurityLevelIcons } from "pages/assets/components/asset-security-level-icons";
import { SecurityLevels } from "pages/assets/types";
import { ASSET_FIELDS_PRIORITY } from "pages/dashboard/constants";
import { getSecurityLevelText } from "pages/dashboard/helpers/getSecurityLevelText";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export interface ListDatum {
  label: string;
  name: string;
  count: number;
}

export interface ClassificationData {
  loading?: boolean;
  total: number;
  data: Array<ListDatum>;
  totalAssets?: number;
}

export interface AssetClassificationByLevelsProps {
  data: ClassificationData;
  type: string;
  category: string;
}

function getLink(
  data: { name: string; label: string; count: number },
  type: string
) {
  let link = "";
  let queryParam = encodeURI(`${type}=${data.name}`);
  link = `/assets?filters=${queryParam}`;

  return link;
}

function getBtnLink(text: SecurityLevels, type: string) {
  let link = "";
  let queryParam = encodeURI(`${type}=${text}`);
  link = `/assets?filters=${queryParam}`;

  return link;
}

export const AssetClassificationByLevels = ({
  data: config,
  type,
  category,
}: AssetClassificationByLevelsProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const linksData: ListDatum[] = useMemo(() => {
    let sortedData: ListDatum[] = [];

    (config?.data ?? []).forEach((option: ListDatum) => {
      const idx: number = ASSET_FIELDS_PRIORITY[option.name];
      sortedData[idx] = {
        ...option,
        label: window.getCTTranslatedText(option.label),
      };
    });

    return sortedData;
  }, [config]);

  const processBtnClickCallback = useCallback(
    (text: SecurityLevels) => {
      const link = getBtnLink(text, type);
      if (link) {
        navigate(link);
      }
    },
    [navigate, type]
  );

  const processLinkCallback = (data: ListDatum) => {
    const link = getLink(data, type);
    if (link) {
      navigate(link);
    }
  };

  const text = getSecurityLevelText(config?.total ?? 0);

  const buttonProps = useMemo(() => {
    const text = getSecurityLevelText(config?.total ?? 0) ?? "unknown";

    return {
      text: window.getCTTranslatedText(capitalizeFirstLetter(text)),
      tooltipText: `${window.getCTTranslatedText(
        "SecurityLevelTextButtonToolTip",
        {
          category: window.getCTTranslatedText(category),
          text: window.getCTTranslatedText(capitalizeFirstLetter(text ?? "")),
        }
      )}`,
      bgColor: getColor(`${text}bg`),
      icon: <AssetSecurityLevelIcons securityLevel={text} />,
      labelButtonStyles: {
        color: theme.palette.text.primary,
      },
    };
  }, [category, config?.total, theme.palette.text.primary]);

  const onClickCountButton = useCallback(() => {
    navigate("/assets");
  }, [navigate]);

  const onClickChartLabelButton = useCallback(
    () => processBtnClickCallback(text),
    [processBtnClickCallback, text]
  );

  const countOption = useMemo(
    () => ({
      label: `${window.getCTTranslatedText("total assets")}`,
      name: "totalassets",
      count: config?.totalAssets ?? 0,
    }),
    [config?.totalAssets]
  );

  if (config?.loading) {
    return null;
  }

  const tooltipText =
    config?.totalAssets === 0
      ? ""
      : `${window.getCTTranslatedText("Total Assets count")}: ${numeral(
          config?.totalAssets ?? 0
        ).format("0a")}`;

  return (
    <LinearProgressChart
      onClickCountButton={onClickCountButton}
      onClickChartLabelButton={onClickChartLabelButton}
      tooltipText={tooltipText}
      data={linksData}
      countOption={countOption}
      type={type}
      onClickLinearProgress={processLinkCallback}
      getColor={getColor}
      buttonProps={buttonProps}
    />
  );
};
