import { CTConfirmation } from "common/atoms/ct-confirmation";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useRebootApplianceAPI } from "pages/appliance-detail/hooks/useRebootApplianceAPI";
import { ApplianceRebootProps } from "../types";
import { useApplianceStore } from "pages/appliances/store";

export const ApplianceReboot = ({
  agent,
  showReboot,
  setShowReboot,
}: ApplianceRebootProps) => {
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const applianceRebootMutation = useRebootApplianceAPI(agent?.agentId);
  const requestAPIRefresh = useApplianceStore(state => state.requestAPIRefresh);

  const rebootAppliance = () => {
    applianceRebootMutation.mutateAsync(
      {},
      {
        onSuccess(response) {
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            window.getCTTranslatedText("rebootApplianceSuccess")
          );
          setShowReboot(false);
          requestAPIRefresh();
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      }
    );
  };
  return (
    <CTConfirmation
      open={showReboot}
      onClose={() => setShowReboot(false)}
      title={window.getCTTranslatedText("rebootAppliance")}
      primaryText={window.getCTTranslatedText("applianceRebootConfirmation")}
      secondaryText={window.getCTTranslatedText("applianceRebootNote")}
      primaryButtonText={window.getCTTranslatedText("rebootButton")}
      isLoading={applianceRebootMutation.isLoading}
      onSuccess={rebootAppliance}
    />
  );
};
