import { Stack, Typography, useTheme } from "@mui/material";
import { DiagonalFillPattern } from "assets/svgs";
import { getColor } from "common/constants/colors";
import { PieChartDatum as ChartDatum, PieChart } from "@colortokens/ng-ui";
import { SecurityLevels } from "pages/assets/types";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../asset/components/asset-detail/helpers/capitalizeFirstLetterString";

export interface ChartData {
  loading?: boolean;
  total: number;
  totalAssets?: number;
  data: Array<ChartDatum>;
}
export interface SecurityPostureGraphsType {
  risk: ChartData;
  attackSurface: ChartData;
  blastRadius: ChartData;
}

export interface SecurityPostureProps extends SecurityPostureGraphsType {
  overallPosture: string;
  riskScorePercentage: number;
  attackSurfacePercentage: number;
  type: string;
}

const ASSET_FIELDS_PRIORITY: { [key: string]: number } = {
  [SecurityLevels.Critical]: 0,
  [SecurityLevels.High]: 1,
  [SecurityLevels.Medium]: 2,
  [SecurityLevels.Low]: 3,
};

function getMappingText(value: number): SecurityLevels {
  if (value <= 25) {
    return SecurityLevels.Low;
  } else if (value <= 50) {
    return SecurityLevels.Medium;
  } else if (value <= 75) {
    return SecurityLevels.High;
  } else {
    return SecurityLevels.Critical;
  }
}

function getLink(
  data: { name: string; label: string; count: number },
  type: string
) {
  let link = "";
  let queryParam = encodeURI(`${type}=${data.name}`);
  link = `/assets?filters=${queryParam}`;

  return link;
}

function getChartColor(datum: ChartDatum) {
  return getColor(datum.name);
}

export function SecurityPosture({
  posture,
}: {
  posture: SecurityPostureProps;
}) {
  return <SecurityPostureGraphs posture={posture} />;
}

function SecurityPostureGraphs({ posture }: { posture: SecurityPostureProps }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const onClickDatum = (data: any, type: string) => {
    const link = getLink(data, type);
    if (link) {
      navigate(link);
    }
  };

  const fontFamily = {
    fontFamily: `${theme.typography.body2.fontFamily} !important`,
  };

  return (
    <Stack direction={"row"} spacing={0}>
      {posture?.type === "assetrisk" && (
        <Stack spacing={4}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h6">Security Breach Impact:</Typography>
            <Typography
              variant="h6"
              sx={{
                ...fontFamily,
                ml: 1,
                backgroundColor: getColor(posture.overallPosture),
                px: 2,
                borderRadius: theme.shape.borderRadius,
                color: "#fff",
              }}
            >
              {posture.riskScorePercentage}
            </Typography>
          </Stack>
          <PieChartGraph
            title={capitalizeFirstLetter(posture.overallPosture)}
            titleStyle={{
              ...fontFamily,
              ml: 1,
              color: getColor(posture.overallPosture),
              pointerEvents: "all",
            }}
            chartProps={{
              data: posture.risk.data,
              getColor: getChartColor,
            }}
            loading={posture.risk.loading}
            total={null}
            totalTitle={capitalizeFirstLetter(posture.overallPosture)}
            priorityMap={ASSET_FIELDS_PRIORITY}
            onClickDatum={data => onClickDatum(data, posture?.type)}
          />
        </Stack>
      )}

      {posture?.type === "attacksurface" && (
        <Stack spacing={4}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h6">Attack Surface</Typography>
          </Stack>
          <PieChartGraph
            title={"Attack Surface"}
            titleStyle={{
              ...fontFamily,
              ml: 1,
              color: getColor(getMappingText(posture.attackSurface.total)),
              pointerEvents: "all",
            }}
            chartProps={{
              data: posture.attackSurface.data,
              getColor: getChartColor,
            }}
            loading={posture.risk.loading}
            total={null}
            totalTitle={capitalizeFirstLetter(
              getMappingText(posture.attackSurface.total)
            )}
            priorityMap={ASSET_FIELDS_PRIORITY}
            onClickDatum={data => onClickDatum(data, posture?.type)}
          />
        </Stack>
      )}

      {posture?.type === "blastradius" && (
        <Stack spacing={4}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h6">Blast Radius</Typography>
          </Stack>
          <PieChartGraph
            title={"Blast Radius"}
            titleStyle={{
              ...fontFamily,
              ml: 1,
              color: getColor(getMappingText(posture.blastRadius.total)),
              pointerEvents: "all",
            }}
            chartProps={{
              data: posture.blastRadius.data,
              getColor: getChartColor,
            }}
            loading={posture.risk.loading}
            total={null}
            totalTitle={capitalizeFirstLetter(
              getMappingText(posture.blastRadius.total)
            )}
            priorityMap={ASSET_FIELDS_PRIORITY}
            onClickDatum={data => onClickDatum(data, posture?.type)}
          />
        </Stack>
      )}
    </Stack>
  );
}

interface ChartProps {
  data: Array<ChartDatum>;
  getColor: (datum: ChartDatum) => string;
}
interface PieChartGraphProps {
  title: string;
  titleStyle: object;
  titleDecorator?: React.ReactNode;
  chartProps: ChartProps;
  total: number | null;
  priorityMap: { [key: string]: number };
  totalTitle?: string;
  loading?: boolean;
  height?: number;
  width?: number;
  onClickDatum: (data: any) => void;
}

export function PieChartGraph({
  title,
  titleStyle,
  titleDecorator,
  chartProps,
  totalTitle,
  total,
  height,
  width,
  priorityMap,
  loading,
  onClickDatum,
}: PieChartGraphProps) {
  const onSort = useCallback(
    (a: ChartDatum, b: ChartDatum) => {
      let priorityA: number = priorityMap[a.name];
      let priorityB: number = priorityMap[b.name];

      if (priorityB === undefined) {
        return -1;
      } else if (priorityA === undefined) {
        return 1;
      }

      if (priorityA === priorityB) {
        return b.count - a.count;
      }

      return priorityA - priorityB;
    },
    [priorityMap]
  );

  const data = useMemo(() => {
    return chartProps.data.map((datum: ChartDatum) => {
      return {
        ...datum,
        label: window.getCTTranslatedText(datum.label),
      };
    });
  }, [chartProps.data]);

  return (
    <Stack direction="row" spacing={2} justifyContent="center">
      <PieChart
        {...chartProps}
        data={data}
        pieValue={input => input.count}
        getKey={input => input.name}
        onClickDatum={onClickDatum}
        width={width ?? 200}
        height={height ?? 200}
        totalTitle={totalTitle}
        titleStyle={titleStyle}
        total={total}
        loading={loading}
        renderPattern={() => {
          return <DiagonalFillPattern opacity={0.5} />;
        }}
        sorter={onSort}
        legendLabelTooltipSuffixText={window.getCTTranslatedText("assets")}
      />
    </Stack>
  );
}
