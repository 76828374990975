import { Box, Stack, Tooltip } from "@mui/material";
import { PendingWorkRequestsColumnRenderProps } from "modules/PendingWorkRequestsDrawer/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { useWorkRequestFacetStore } from "modules/PendingWorkRequestsDrawer/store/useWorkRequestStore";

export function PendingWorkRequestAction({
  pendingItems,
}: PendingWorkRequestsColumnRenderProps) {
  if (!pendingItems.action) {
    return <Box style={{ minWidth: 310, minHeight: 45 }} />;
  }
  return (
    <Stack
      sx={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
      alignItems="left"
      justifyContent="center"
    >
      <Tooltip
        title={`${window.getCTTranslatedText(
          "Show only for"
        )} ${window.getCTTranslatedText(pendingItems?.action)}`}
      >
        <CTDatagridButton
          facetName={"workrequestaction"}
          facetOption={pendingItems?.action ?? ""}
          useFacetStore={useWorkRequestFacetStore}
        >
          {window.getCTTranslatedText(pendingItems?.action)}
        </CTDatagridButton>
      </Tooltip>
    </Stack>
  );
}
