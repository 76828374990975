import { FirewallLogs } from "pages/asset/types";
import { useEffect } from "react";
import { useFirewallLogsAPI } from "../../hooks";
import { FirewallLogType } from "./ViewFirewallLogsButton";
import { useFirewallLogsApplianceAPI } from "../../hooks/useFirewallLogsAPI";

export interface FirewallLogsFetcherProps {
  setOptionsData: React.Dispatch<
    React.SetStateAction<FirewallLogs | undefined>
  >;
  setAreOptionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  type: FirewallLogType;
}

export const FirewallLogsFetcher = ({
  id,
  setOptionsData,
  setAreOptionsLoading,
  type,
}: FirewallLogsFetcherProps) => {
  const API =
    type === FirewallLogType.APPLIANCE
      ? useFirewallLogsApplianceAPI
      : useFirewallLogsAPI;
  const { data, isLoading }: any = API({ id });

  useEffect(() => {
    setAreOptionsLoading(isLoading);
  }, [isLoading, setAreOptionsLoading]);

  useEffect(() => {
    setOptionsData(data);
  }, [data, setOptionsData]);

  return null;
};
