import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { SavedQuery } from "./types";

export const getRecentQueryList = () => {
  const recentQueryList = window.localStorage.getItem("recentQueryList");
  let recentQueryData;
  if (recentQueryList) {
    recentQueryData = JSON.parse(recentQueryList);
  }
  return recentQueryData || [];
};

export const addRecentQuery = (query: SavedQuery) => {
  const recentQueryData = getRecentQueryList();
  const index = existingQueryIndex({ recentQueryData, query });
  if (index !== -1) {
    replaceOldQuery({ recentQueryData, query, index });
  } else {
    recentQueryData.unshift(query);
    setRecentQueryList(recentQueryData);
  }
};

const existingQueryIndex = ({
  recentQueryData,
  query,
}: {
  recentQueryData: Array<SavedQuery>;
  query: SavedQuery;
}) => {
  return (recentQueryData || []).findIndex(recentQuery => {
    return recentQuery.criteria === query.criteria;
  });
};

export const replaceOldQuery = ({
  recentQueryData,
  query,
  index,
}: {
  recentQueryData: Array<SavedQuery>;
  query: SavedQuery;
  index: number;
}) => {
  recentQueryData.splice(index, 1);
  recentQueryData.unshift(query);
  setRecentQueryList(recentQueryData);
};

export const setRecentQueryList = (recentQueryData: Array<SavedQuery>) => {
  recentQueryData = getUniqueListBy(recentQueryData, "criteriaAsParams");
  window.localStorage.setItem(
    "recentQueryList",
    JSON.stringify(recentQueryData)
  );
};
