import { Theme } from "@mui/material";
import { AssetStatus } from "pages/assets/types";

export enum Direction {
  Inbound = 0,
  Outbound = 1,
}

export enum SecurityStatus {
  None = -1,
  Unsecure = 0,
  SimulateInternet = 50, // review sagar
  Internet = 50,
  SimulateAll = 100,
  All = 100,
}

export const SecurityStatusLabels = {
  [SecurityStatus.None]: "None",
  [SecurityStatus.Unsecure]: "Unsecure",
  // [SecurityStatus.SimulateInternet]: "Simulate Secure Internet",
  [SecurityStatus.Internet]: "Secure Internet",
  // [SecurityStatus.SimulateAll]: "Simulate Secure All",
  [SecurityStatus.All]: "Secure All",
};

export const AssetStatusMap = {
  [AssetStatus.None]: SecurityStatus.None,
  [AssetStatus.Unsecured]: SecurityStatus.Unsecure,
  [AssetStatus.SimulateSecureInternet]: SecurityStatus.SimulateInternet,
  [AssetStatus.SecureInternet]: SecurityStatus.Internet,
  [AssetStatus.SimulateSecureAll]: SecurityStatus.SimulateAll,
  [AssetStatus.SecureAll]: SecurityStatus.All,
};

export const AssetStatusReverseMap = {
  [SecurityStatus.None]: AssetStatus.None,
  [SecurityStatus.Unsecure]: AssetStatus.Unsecured,
  // [SecurityStatus.SimulateInternet]: AssetStatus.SimulateSecureInternet,
  [SecurityStatus.Internet]: AssetStatus.SecureInternet,
  // [SecurityStatus.SimulateAll]: AssetStatus.SimulateSecureAll,
  [SecurityStatus.All]: AssetStatus.SecureAll,
};

export const AlertMessages = {
  [Direction.Inbound]: {
    [SecurityStatus.Unsecure]: {
      message: {
        info: "Reduce Attack Surface by Securing Internet",
        simulate: "",
        warning: "This asset has unreviewed inbound ports and paths",
        confirmation: "Confirm Unsecure status change",
      },
    },
    [SecurityStatus.Internet]: {
      message: {
        info: "Reduce Attack Surface by Securing All",
        simulateInfo: "Reduce Attack Surface by Securing Internet",
        simulate:
          "Use Test Mode to test configured policies on the host firewall",
        warning: "This asset has unreviewed inbound ports and paths",
        confirmation: "Confirm Secure Internet status change",
      },
    },
    [SecurityStatus.All]: {
      message: {
        info: "Attack Surface reduced to v(a)",
        simulateInfo: "Reduce Attack Surface by Securing All",
        simulate:
          "Use Test Mode to test configured policies on the host firewall",
        warning: "This asset has unreviewed inbound ports and paths",
        confirmation: "Confirm Secure All status change",
      },
    },
    [SecurityStatus.None]: {
      message: {
        warning: "",
        info: "",
        simulateInfo: "",
        simulate: "",
        confirmation: "",
        simulateConfirmation: "",
      },
    },
  },
  [Direction.Outbound]: {
    [SecurityStatus.Unsecure]: {
      message: {
        warning: "This asset has unreviewed outbound paths",
        info: "Reduce Blast Radius by Simulating Secure Internet in Test mode",
        simulateInfo: "",
        simulate: "",
        confirmation: "Confirm Unsecure status change",
      },
    },
    [SecurityStatus.Internet]: {
      message: {
        warning: "This asset has unreviewed outbound paths",
        info: "Reduce Blast Radius by Simulating Secure All in Test mode",
        simulateInfo: "Reduce Blast Radius by Securing Internet",
        simulate:
          "Use Test Mode to test configured policies on the host firewall",
        confirmation: "Confirm Secure Internet status change",
        simulateConfirmation:
          "Use Test Mode to test configured policies on the host firewall",
      },
    },
    [SecurityStatus.All]: {
      message: {
        warning: "This asset has unreviewed outbound paths",
        info: "Blast Radius reduced to v(b)",
        simulateInfo: "Reduce Blast Radius by Securing All",
        simulate:
          "Use Test Mode to test configured policies on the host firewall",
        confirmation: "Confirm Secure All status change",
        simulateConfirmation:
          "Use Test Mode to test configured policies on the host firewall",
      },
    },
    [SecurityStatus.None]: {
      message: {
        warning: "",
        info: "",
        simulateInfo: "",
        simulate: "",
        confirmation: "",
        simulateConfirmation: "",
      },
    },
  },
  deploy:
    "Deploying policies without running Test Mode first may lead to a significant traffic disruption.",
};

export const assetSliderStyles = ({
  theme,
  minValue,
  selectedStatus,
  isAlpha,
}: {
  theme: Theme;
  minValue?: SecurityStatus;
  selectedStatus?: SecurityStatus;
  isAlpha: boolean;
}) => {
  const DisabledPointerColor = theme.palette.custom.sliderBg;
  let disabledPointer;
  let simulatePointer = {};

  if (minValue === SecurityStatus.SimulateInternet) {
    disabledPointer = {
      "&[data-index='0']": {
        background: `${DisabledPointerColor}`,
        border: `5px solid ${DisabledPointerColor}`,
      },
    };
  } else if (minValue === SecurityStatus.All) {
    disabledPointer = {
      "&[data-index='0']": {
        background: `${DisabledPointerColor}`,
        border: `5px solid ${DisabledPointerColor}`,
      },
      "&[data-index='1']": {
        background: `${DisabledPointerColor}`,
        border: `5px solid ${DisabledPointerColor}`,
      },
    };
  }

  if (selectedStatus === SecurityStatus.SimulateAll && isAlpha) {
    simulatePointer = {
      "&[data-index='1']": {
        background: `${theme.palette.warning.main}`,
        border: `5px solid ${theme.palette.warning.main}`,
      },
    };
  }

  let gradient = `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.main}, ${theme.palette.warning.main})`;
  if (isAlpha) {
    gradient = `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.main}, ${theme.palette.warning.main}, ${theme.palette.warning.main})`;
  }
  return {
    "&.asset-status-slider.simulate .MuiSlider-track": {
      border: "none",
      background: gradient,
    },
    "&.asset-status-slider.simulate .MuiSlider-thumb": {
      backgroundColor: theme.palette.warning.main,
      border: `5px solid ${theme.palette.warning.main}`,
      boxShadow: "0 0 0 7px rgba(255, 160, 0, 0.16)",
      zIndex: 2,
      "&.asset-status-slider.simulate:hover": {
        boxShadow: "0 0 0 7px rgba(255, 160, 0, 0.16)",
      },
    },
    ".MuiSlider-mark": {
      zIndex: "2 !important",
      ...disabledPointer,
    },
    "&.asset-status-slider.simulate .MuiSlider-mark": {
      zIndex: "2 !important",
      ...simulatePointer,
    },
  };
};
