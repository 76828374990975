import { Box, Stack } from "@mui/material";
import {
  // AGENT_MODE_TEXT,
  ApplianceColumnRenderProps,
} from "pages/appliances/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AgentMode({ agent, viewOnly }: ApplianceColumnRenderProps) {
  if (!agent?.agentId) {
    return <Box style={{ minWidth: 120 }} />;
  }
  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <CTDatagridButton
        asText={viewOnly === true}
        isDisabled={viewOnly === true}
        facetName={"mode"}
        facetOption={agent.mode ?? ""}
      >
        {agent?.mode || ""}
        {/* {AGENT_MODE_TEXT[agent?.mode] || ""} */}
      </CTDatagridButton>
    </Stack>
  );
}
