import { Box, Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCommonStore } from "common/store";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { HierarchyVisController } from "modules/hierarchy-vis";
import { Scope } from "modules/scope-metadata/types";
import { getAssetInterfaceIPs } from "pages/asset/components/metadata-item-wrapper/helpers";
import { FACET_OPTION_MAPPING } from "pages/users-segmentation/components/users/constants";
import { AssetDataGrid } from "./components/asset-data-grid";
import {
  calculateReviewCoverage,
  getFilterNameFromColumnName,
} from "./components/asset-data-grid/helpers";
import { useAssetsAPI } from "./components/asset-data-grid/hooks";
import { useAssetsFacetsOrder } from "./constants";
import { useAssetStore } from "./store";
import { Asset } from "./types";
import { AssetVisualizeButton } from "./components/asset-visualize-button";

export const Assets = () => {
  const theme = useTheme();

  const dataMapper = (asset: Asset) => {
    asset.reviewCoverage = calculateReviewCoverage(asset);
    if (asset?.containerNamespace || asset?.clusterIdentifier) {
      if (!asset?.coreTags) {
        asset.coreTags = {};
      }
      if (asset?.containerNamespace) {
        asset.coreTags.containerNamespace = asset?.containerNamespace;
      }
      if (asset?.clusterIdentifier) {
        asset.coreTags.clusterIdentifier = asset?.clusterIdentifier;
      }
    }
    asset.ipAddresses = `"${getAssetInterfaceIPs(asset.interfaces ?? [])}"`;

    return asset;
  };

  const coreResponse = useCore<Asset>({
    useStore: useAssetStore,
    facetGroupInfo: useAssetsFacetsOrder(),
    scope: Scope.Asset,
    facetOptionsDisplayMapping: FACET_OPTION_MAPPING,
    defaultSortOrder: [{ field: "assetrisk", order: "desc" }],
    dataMapper: dataMapper,
    useApi: useAssetsAPI,
    pageSize: 100,
  });

  let metadata = useCommonStore(state => state.metadata);
  // update column name
  // if (metadata?.columns?.assetname) {
  //   metadata.columns.assetname.displayName = "Asset";
  // }
  if (metadata?.columns?.assetavailability) {
    metadata.columns.assetavailability.displayName = "Status";
  }
  const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction={"row"} alignItems="center">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
        <AssetVisualizeButton />
      </Stack>

      <Box
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowY: "auto",
          flex: 1,
          height: "100%",
        }}
      >
        <HierarchyVisController
          defaultDimensionName="assetrisk"
          scope={Scope.Asset}
        />

        <Paper sx={{ height: "100%" }}>
          <AssetDataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "assetrisk", sort: "desc" }],
              },
            }}
            metadata={metadata}
            rows={coreResponse.rows}
            rowCount={coreResponse.rowCount}
            mutation={coreResponse.mutation}
            onPageChange={coreResponse.onPageChange}
            page={coreResponse.page}
            pageSize={coreResponse.pageSize}
            onPageSizeChange={coreResponse.onPageSizeChange}
            onSortChange={sortModel => {
              if (sortModel?.length > 0) {
                sortModel = sortModel.map(item => {
                  item.field = getFilterNameFromColumnName(item.field);
                  return item;
                });
                coreResponse.onSortChange(sortModel);
              }
            }}
            hasNoData={
              coreResponse.mutation.isSuccess &&
              coreResponse.rowCount === 0 &&
              searchCriteria === "*"
            }
            triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
            getExportStatus={coreResponse?.getExportStatus}
            getUrlToDownload={coreResponse?.getUrlToDownload}
            resetDownloadUrl={coreResponse?.resetDownloadUrl}
            rawData={coreResponse?.rawData}
          />
        </Paper>
      </Box>
    </Stack>
  );
};
