import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ParentSize } from "@visx/responsive";
import { useCommonStore } from "common/store";
import { schemeCategory10, schemeTableau10, select } from "d3";
import { ErrorFallback } from "modules/error-boundary/error-fallback";
import { Dimension } from "modules/hierarchy-vis/types";
import { Scope } from "modules/scope-metadata/types";
import { useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { SelectTextField } from "../../hierarchy-vis/HierarchyVis";
import { useSankeyData } from "../hooks/useSankeyData";
import { SankeyChartInternal } from "./SankeyChartInternal";

interface SankeyChartProps {
  scope: Scope;
  dimensions: Dimension[];
  dimensionOptions: Dimension[];
}

export function ResponsiveSankeyChart(props: SankeyChartProps) {
  const [dimensions, setDimensions] = useState(props.dimensions);

  const [useLog, setUseLog] = useState(true);

  const data = useSankeyData({
    groupBy: dimensions.map(d => d.name),
    scope: props.scope,
    statistics: [`count("assetid")`],
    topN: 5,
    useLog,
  });

  if (!data?.nodes.length) {
    return (
      <Stack height="326px" justifyContent="center" alignItems="center">
        <Typography variant="body2">
          {window.getCTTranslatedText("No results")}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <Stack
        alignItems={"flex-end"}
        sx={{
          position: "absolute",
          right: 42,
          top: 22,
        }}
      >
        <FormControlLabel
          label={
            <Typography variant="body2">
              {window.getCTTranslatedText("Use Log Scale")}
            </Typography>
          }
          control={
            <Switch
              value={useLog}
              checked={useLog}
              onChange={(_, c) => setUseLog(c)}
              inputProps={{ "aria-label": "Use Log Scale" }}
            />
          }
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="100%">
        {dimensions.map((dimension, index) => (
          <Stack direction={"row"} alignItems="center" key={dimension.label}>
            <SelectTextField
              value={dimension.name}
              onChange={e => {
                const value = e.target.value;
                setDimensions(prev => {
                  const selectedDimension = props.dimensionOptions.find(
                    option => option.name === value
                  );
                  if (selectedDimension) {
                    prev[index] = selectedDimension;
                  }
                  return [...prev];
                });
              }}
            >
              <MenuItem disabled value={"empty"}>
                {window.getCTTranslatedText("Select dimension")}
              </MenuItem>

              {props.dimensionOptions
                .filter(
                  dimensionOption =>
                    !dimensions.find(
                      obj =>
                        obj.name === dimensionOption.name &&
                        dimension.name !== obj.name
                    )
                )
                .map(dimension => (
                  <MenuItem key={dimension.name} value={dimension.name}>
                    {window.getCTTranslatedText(dimension.label)}
                  </MenuItem>
                ))}
            </SelectTextField>

            {false && index === dimensions.length - 1 && (
              <>
                <IconButton
                  disabled={dimensions.length <= 2}
                  onClick={() => setDimensions(prev => prev.slice(0, -1))}
                >
                  <Tooltip
                    title={window.getCTTranslatedText("Remove dimension")}
                  >
                    <RemoveCircleOutline />
                  </Tooltip>
                </IconButton>

                <IconButton
                  onClick={() => {
                    const selectedDimension = props.dimensionOptions.filter(
                      dimensionOption =>
                        !dimensions.find(
                          obj => obj.name === dimensionOption.name
                        )
                    )?.[0];

                    setDimensions(prev => [...prev, selectedDimension]);
                  }}
                >
                  <Tooltip
                    title={window.getCTTranslatedText("Add dimension")}
                    placement="right-end"
                  >
                    <AddCircleOutline />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </Stack>
        ))}
      </Stack>
      <Box sx={{ height: 300, width: "100%", display: "block" }}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ParentSize>
            {parent => {
              return (
                <ReactSankeyChart
                  data={data}
                  useLog={useLog}
                  width={parent.width}
                  height={parent.height}
                  key={useLog}
                />
              );
            }}
          </ParentSize>
        </ErrorBoundary>
      </Box>
    </>
  );
}

function ReactSankeyChart(props: any) {
  const data = props.data;
  const theme = useTheme();
  const updateFacetOption = useCommonStore(state => state.updateFacetOption);

  const ref = useRef<any>();
  useEffect(() => {
    if (!data || !data.links.length || !ref.current) {
      return;
    }
    const colors =
      theme.palette.mode === "dark" ? schemeCategory10 : schemeTableau10;

    // @ts-ignore
    SankeyChartInternal(data, select(ref.current), {
      nodeGroup: (n: any) => n.id,
      width: props.width,
      height: props.height,
      colors,
      nodeStrokeWidth: 0,
      updateFacetOption,
      useLog: props.useLog,
    });
  }, [data, props.width, props.height, theme, updateFacetOption, props.useLog]);

  return (
    <svg
      className="sankey-chart"
      ref={ref}
      style={{ width: props.width, height: props.height }}
    />
  );
}
