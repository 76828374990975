import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useNetworkStore } from "pages/networks/store";
import { IPRangeInt, Network } from "pages/networks/types";

export interface NetworkRemoveProps {
  network: Network | undefined;
  ipRange: IPRangeInt;
  handleAllowRemove: Function;
  allowRemove: boolean;
}

const useRemoveNetworkRangeAPI = ({
  namedNetworkId,
}: {
  namedNetworkId: string | undefined;
}) => {
  const path = `namednetworks/${namedNetworkId}/networkranges`;
  return useMutation<any, Error, any>(["networks", path, "delete"]);
};

export function RemoveNetworkRange({
  network,
  ipRange,
  handleAllowRemove,
  allowRemove,
}: NetworkRemoveProps) {
  const requestAPIRefresh = useNetworkStore(state => state.requestAPIRefresh);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const notify = useEventSubscriptionStore(state => state.notify);

  const removeNetworkRangeAPI = useRemoveNetworkRangeAPI({
    namedNetworkId: network?.namedNetworkId,
  });

  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const updateNetworkDetails = () => {
    if (ipRange?.id) {
      setLoading(true);

      let body: any = [
        {
          rangeID: ipRange?.id,
        },
      ];

      removeNetworkRangeAPI.mutate(body, {
        onSuccess: response => {
          queryClient.invalidateQueries({
            queryKey: ["networks"],
          });
          requestAPIRefresh();
          notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
            label: "IPRangeRemovedFromThisNamedNetwork",
          });
          setTimeout(() => {
            handleAllowRemove(false);
            setLoading(false);
          }, 500);
        },
        onError: error => {
          setLoading(false);
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  };

  return (
    <>
      <Dialog
        open={allowRemove}
        onClose={() => handleAllowRemove(false)}
        aria-labelledby="delete-ip-range"
      >
        <DialogTitle id="delete-ip-range">
          {window.getCTTranslatedText(`Delete IP Range`)}
        </DialogTitle>
        <DialogContent>
          {window.getCTTranslatedText("removeIPFromNamedNetwork", {
            ipRangeValue: ipRange?.value,
          })}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => handleAllowRemove(false)}
          >
            {window.getCTTranslatedText("Cancel")}
          </Button>
          <LoadingButton
            loading={removeNetworkRangeAPI.isLoading || loading}
            onClick={updateNetworkDetails}
            autoFocus
            variant="contained"
            color={"error"}
            type="submit"
          >
            {window.getCTTranslatedText("Remove")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
