import { dayjsWithPlugins } from "common/utils";
import { API_URL } from "hooks/useNetworking/constants";
import { DiagnosticOptions, Diagnostics } from "pages/agents/types";
import prettyBytes from "pretty-bytes";
import { useMemo, useState } from "react";
import { DiagnosticFetcher } from "./DiagnosticFetcher";
import { ApplianceDiagnosticDetailsDrawer } from "../appliance-drawers/ApplianceDiagnosticDetailsDrawer";
import { Appliance } from "pages/appliances/types";

interface ViewDiagnosticButtonProps {
  agentId: string;
  agent?: Appliance;
  isOpen?: boolean;
  onClose?: () => void;
}

export const ViewDiagnosticButton = ({
  agentId,
  agent,
  isOpen,
  onClose,
}: ViewDiagnosticButtonProps) => {
  const [optionsData, setOptionsData] = useState<Diagnostics>();
  const [areOptionsLoading, setAreOptionsLoading] = useState(true);

  const diagnosticOptions: DiagnosticOptions = useMemo(
    () =>
      optionsData?.items.map((item, index) => ({
        value: item.lastModified,
        label: `${index === 0 ? "Latest (" : ""} ${dayjsWithPlugins(
          item.lastModified
        ).format("DD-MMM-YY HH:mm:ss")} ${index === 0 ? ")" : ""}`,
        id: item.name,
        fileSize: prettyBytes(item.sizeInBytes),
      })),
    [optionsData]
  );

  return (
    <>
      {isOpen && (
        <DiagnosticFetcher
          agentId={agentId}
          setOptionsData={setOptionsData}
          setAreOptionsLoading={setAreOptionsLoading}
        />
      )}
      <ApplianceDiagnosticDetailsDrawer
        isOpen={isOpen}
        onClose={onClose}
        label="Date"
        areOptionsLoading={areOptionsLoading}
        diagnosticOptions={diagnosticOptions}
        downloadPrefix={`${API_URL}/agents/${agentId}/diagnostics/`}
      />
    </>
  );
};
