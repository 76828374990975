import { Asset, PendingReviewCount } from "pages/assets/types";
import { AssetStatusMap, Direction, SecurityStatus } from "../../../constants";
import { MessageBlockComp } from "../components/message-block";

interface canDisplaySynchornizeAlertProps {
  direction: Direction;
  asset: Asset;
}

export function canDisplaySynchornizeAlert({
  direction,
  asset,
}: canDisplaySynchornizeAlertProps) {
  let canDisplay = false;
  let message = "";
  let messageComp: JSX.Element | null = null;
  let pendingCounts: PendingReviewCount | undefined;
  let securityLevel: SecurityStatus;
  let totalPendingCount;

  const getTotal = () => {
    return Object.values(pendingCounts || {}).reduce(
      (prev, curr) => prev + (Array.isArray(curr) ? curr?.length + 1 : curr),
      0
    );
  };

  switch (direction) {
    case Direction.Inbound:
      pendingCounts = asset?.attackSurfacePendingChanges;
      securityLevel = AssetStatusMap[asset.inboundAssetStatus];
      totalPendingCount = getTotal();
      canDisplay =
        Boolean(asset?.pendingAttackSurfaceChanges) ||
        Boolean(totalPendingCount);
      if (canDisplay) {
        messageComp = getPushToFirewallMessageDetails({
          securityLevel,
          pendingCounts,
          asset,
          direction,
        })?.messageComp;
      }
      break;

    case Direction.Outbound:
      pendingCounts = asset?.blastRadiusPendingChanges;
      securityLevel = AssetStatusMap[asset.outboundAssetStatus];
      totalPendingCount = getTotal();

      canDisplay =
        Boolean(asset?.pendingBlastRadiusChanges) || Boolean(totalPendingCount);
      if (canDisplay) {
        messageComp = getPushToFirewallMessageDetails({
          securityLevel,
          pendingCounts,
          asset,
          direction,
        })?.messageComp;
      }
      break;
  }

  return {
    show: canDisplay,
    message: message,
    messageComp: messageComp,
    pendingCounts,
    totalPendingCount,
  };
}

interface PushToFirewallDetailsProps {
  securityLevel: SecurityStatus;
  pendingCounts: PendingReviewCount | undefined;
  asset: Asset;
  direction: Direction;
}

function getPushToFirewallMessageDetails({
  securityLevel,
  pendingCounts,
  asset,
  direction,
}: PushToFirewallDetailsProps): {
  messageComp: JSX.Element | null;
} {
  let messageComp: JSX.Element | null = null;

  if (!pendingCounts) {
    return { messageComp: messageComp };
  }

  switch (securityLevel) {
    case SecurityStatus.Unsecure:
      messageComp = (
        <>
          <MessageBlockComp
            pendingCounts={{
              ...pendingCounts,
              ports: 0,
              paths: 0,
            }}
            asset={asset}
            direction={direction}
          />
        </>
      );
      break;
    case SecurityStatus.Internet:
      messageComp = (
        <>
          <MessageBlockComp
            pendingCounts={{
              ...pendingCounts,
              ports: pendingCounts?.internetPorts || 0,
              paths: pendingCounts?.internetPaths || 0,
            }}
            asset={asset}
            direction={direction}
          />
        </>
      );
      break;
    case SecurityStatus.All:
      messageComp = (
        <>
          <MessageBlockComp
            pendingCounts={{
              ...pendingCounts,
              ports:
                pendingCounts?.internetPorts + pendingCounts?.intranetPorts ||
                0,
              paths:
                pendingCounts?.internetPaths + pendingCounts?.intranetPaths ||
                0,
            }}
            asset={asset}
            direction={direction}
          />
        </>
      );
      break;
  }
  return { messageComp };
}
