import { IconButton, Tooltip } from "@mui/material";
import { UseMutationResult } from "@tanstack/react-query";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CloneRowFormDialog } from "./components/clone-row-form";
import { useState } from "react";

export interface CloneDataGridRowActionProps {
  useAPI: () => UseMutationResult<any, Error, any, unknown>;
  refreshAPI: VoidFunction;
  generateBody: () => Record<string, string>;
  title: string;
}

export function CloneDataGridRowAction({
  useAPI,
  refreshAPI,
  generateBody,
  title,
}: CloneDataGridRowActionProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handlerOnClick = (event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setIsDrawerOpen(true);
  };

  const handleClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <IconButton onClick={handlerOnClick}>
        <Tooltip title={window.getCTTranslatedText("Clone")}>
          <ContentCopyIcon fontSize="small" />
        </Tooltip>
      </IconButton>

      {isDrawerOpen && (
        <CloneRowFormDialog
          title={title}
          useAPI={useAPI}
          refreshAPI={refreshAPI}
          generateBody={generateBody}
          onClose={handleClose}
        />
      )}
    </>
  );
}
