import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useCommonStore } from "common/store";
import { getRelativeLastObserved } from "common/utils";
import { getStatus } from "common/utils/statusUtils";
import numeral from "numeral";
import { StatusText } from "pages/paths/constants";
import {
  PathColumnRenderProps,
  PathEnforcedConfigStatus,
  PathStatus as PathStatusOptions,
} from "pages/paths/types";
import pluralize from "pluralize";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

const width = 180;

export function PathStatusEnforced({
  path,
  viewOnly,
  useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
  const facetState = useFacetStore(state => state.facets);

  const isSelected =
    !viewOnly && facetState?.get("enforced")?.get(path.enforced)?.isSelected;

  if (!path.channelHash) {
    return <Box style={{ minWidth: width, minHeight: 45 }} />;
  }

  const status = StatusText[path?.enforced || PathStatusOptions.Unreviewed];
  const statusDetails = getStatus(status);

  const firewallAllowCount = path?.firewallAllowCount ?? 0;
  const firewallDenyCount = path?.firewallDenyCount ?? 0;

  let tooltipText = "";
  if (firewallAllowCount > 0 || firewallDenyCount > 0) {
    tooltipText =
      path?.enforced === PathEnforcedConfigStatus.AllowTestDenied ||
      path?.enforced === PathEnforcedConfigStatus.AllowTestDeniedViolation
        ? `${numeral(firewallAllowCount).format("0a")} ${pluralize(
            "connection",
            firewallAllowCount
          )} allowed`
        : `${numeral(firewallDenyCount).format("0a")} ${pluralize(
            "connection",
            firewallDenyCount
          )} denied`;

    tooltipText = `${tooltipText}, Last reported  ${getRelativeLastObserved(
      path.firewallLastReported
    )}`;
  }

  return (
    <Tooltip title={window.getCTTranslatedText(tooltipText)}>
      <Stack
        sx={{
          maxWidth: "100%",
          "& .counts": {
            opacity: 0,
            display: "none",
            transition: "opacity 100ms ease-in 0",
          },
          "&:hover .counts": {
            opacity: 1,
            display: "inline-block",
          },
        }}
        alignItems="left"
        justifyContent="center"
      >
        <CTDatagridButton
          asText={viewOnly === true}
          isDisabled={viewOnly === true}
          useFacetStore={useFacetStore}
          facetName={"enforced"}
          facetOption={path.enforced ?? ""}
        >
          {window.getCTTranslatedText(statusDetails.status)}
        </CTDatagridButton>

        {statusDetails.description && (
          <Typography
            variant="caption"
            color={isSelected ? "primary" : "inherit"}
            sx={{ pl: 1 }}
          >
            {window.getCTTranslatedText(statusDetails.description)}
          </Typography>
        )}
      </Stack>
    </Tooltip>
  );
}
