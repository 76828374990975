import { DisplayTextMap } from "common/constants/displayTextMap";
import { FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "modules/facets/types";
import { PathStatus } from "pages/paths/types";
import { ALL_TIME, COMMON_FACETS } from "../../modules/facets/constants";
import { TIME_FILTER_OPTIONS } from "common/molecules/TimeFilter/TimeFilter";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useMemo } from "react";

export const NIL = "<nil>";

export const PATH_TRAFFIC_FACET_ORDER: FacetGroupInfo = {
  pathlastobserved: FacetGroups.Paths,
  enforced: FacetGroups.Paths,
  reviewed: FacetGroups.Paths,
  compassdirection: FacetGroups.Paths,
  direction: FacetGroups.Paths,
  bandwidthinbytes: FacetGroups.Paths,
  connectioncount: FacetGroups.Paths,
  internetbandwidthinbytes: FacetGroups.Paths,
  intranetbandwidthinbytes: FacetGroups.Paths,
  internetfacing: FacetGroups.Paths,
  dstprocess: FacetGroups.Paths,
  port: FacetGroups.Ports,
  portname: FacetGroups.Ports,
  protocol: FacetGroups.Ports,
  firewalllastreported: FacetGroups.Firewall,
  srcip: FacetGroups.Paths,
  dstip: FacetGroups.Paths,
};

const BASE_PATHS_FACET_ORDER: FacetGroupInfo = {
  ...COMMON_FACETS,
  ...PATH_TRAFFIC_FACET_ORDER,
  attacksurface: FacetGroups.Security,
  assetinboundstatus: FacetGroups.Security,
  blastradius: FacetGroups.Security,
  assetoutboundstatus: FacetGroups.Security,
  assetrisk: FacetGroups.Security,
  totalports: FacetGroups.Security,
  totalpaths: FacetGroups.Security,
  assetname: FacetGroups.Assets,
  internetoutboundenforcementready: FacetGroups.Assets,
  intranetoutboundenforcementready: FacetGroups.Assets,
  assetinternetfacing: FacetGroups.Assets,
  agentstatus: FacetGroups.Agents,
  currentversion: FacetGroups.Agents,
  policystatus: FacetGroups.Agents,
  agenttype: FacetGroups.Agents,
  firewalllastreported: FacetGroups.Firewall,
  namednetworkname: FacetGroups.Network,
  provider: FacetGroups.Network,
  isoobnetwork: FacetGroups.Network,
  cveid: FacetGroups.Security,
  vulnerabilities: FacetGroups.Security,
  securitypatches: FacetGroups.Security,
  package: FacetGroups.Security,
};

export const PATHS_FACET_ORDER: FacetGroupInfo = {
  ...BASE_PATHS_FACET_ORDER,
  agentname: FacetGroups.Appliances,
  agentlocation: FacetGroups.Appliances,
};

export const usePathsFacetsOrder = () => {
  const { isFeatureEnabled: isDevicesFiltersFeatureEnabled } =
    useFeatureFlagControl(FEATURES.DEVICES_FILTERS);

  const FACET_ORDER: FacetGroupInfo = useMemo(() => {
    if (isDevicesFiltersFeatureEnabled) {
      return PATHS_FACET_ORDER;
    } else {
      return BASE_PATHS_FACET_ORDER;
    }
  }, [isDevicesFiltersFeatureEnabled]);

  return FACET_ORDER;
};

export const StatusOptionsText: { [key: string]: string } = DisplayTextMap;

export const StatusOptions: { [key: string]: string } = DisplayTextMap;

export { PathStatus };

export const StatusText = StatusOptions;

export const PATH_TIME_FILTER_OPTIONS = [...TIME_FILTER_OPTIONS, ALL_TIME];
