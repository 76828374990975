import { Box, Stack, Tooltip } from "@mui/material";
import { useCommonStore } from "common/store";
import { AssetNameInternal } from "pages/assets/components/asset-name-internal";
import { AuditLogColumnRenderProps } from "pages/monitoring/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AuditAsset({
  audit,
  viewOnly,
  useFacetStore = useCommonStore,
}: AuditLogColumnRenderProps) {
  viewOnly = viewOnly ?? !audit?.asset?.assetName;

  if (!audit?.asset) {
    return <Box style={{ minWidth: 310, minHeight: 45 }} />;
  }

  const isDecommissioned =
    Boolean(audit?.asset?.assetId) &&
    audit?.asset?.assetName === "Decommissioned Asset";

  return (
    <Stack
      sx={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
      alignItems="left"
      justifyContent="center"
    >
      <Tooltip
        title={
          viewOnly
            ? ""
            : `${window.getCTTranslatedText("Show only for")} ${
                audit?.asset?.assetName
              }`
        }
      >
        <CTDatagridButton
          asText={viewOnly || isDecommissioned}
          isDisabled={viewOnly || isDecommissioned}
          useFacetStore={useFacetStore}
          facetName={"assetname"}
          facetOption={audit?.asset?.assetName! ?? ""}
          stopPropagation={true}
        >
          {AssetNameInternal({ asset: audit?.asset })}
        </CTDatagridButton>
      </Tooltip>
    </Stack>
  );
}
