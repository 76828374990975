import { Button, CircularProgress, Stack, Typography } from "@mui/material";

import { FacetUtils } from "modules/facets";
import { FacetState } from "modules/facets/types";
import { PortRecommendations } from "modules/recommendation-workflows/components/port-recommendations";
import {
  PortAgg,
  usePortRecommendations,
} from "modules/recommendation-workflows/hooks/usePortRecommendations";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import { createTagPolicyFacetStore } from "pages/tags/components/tag-policy-list/store";
import { PortFormInt } from "pages/templates/components/template-form-drawer/components/template-form/types";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SourceAndDestinationForm } from "./source-and-destination-form";
import {
  CoreTags,
  PathAssetType,
  TagBasedPolicyCreationInput,
  tagsToFacetState,
  useAssetAggregateAPI,
} from "./source-and-destination-form/SourceAndDestinationForm";

const EMPTY_LIST: PortFormInt[] = [];
export interface PathTagPolicyRulesGeneratorProps {
  onChangeSource: (details: TagBasedPolicyCreationInput) => void;
  onChangeDest: (details: TagBasedPolicyCreationInput) => void;
  sourceDetails?: TagBasedPolicyCreationInput;
  destDetails?: TagBasedPolicyCreationInput;
  defaultSourceCoreTags?: CoreTags;
  defaultDestinationCoreTags?: CoreTags;
  defaultSourceFacets?: FacetState;
  defaultDestinationFacets?: FacetState;
  defaultRules: PortFormInt[];
  defaultSourceTagBasedPolicyId?: string;
  defaultDestinationTagBasedPolicyId?: string;
  onChangeRules: (rules: PortFormInt[]) => void;
  metadata: ScopeMetadata;
  isRingFence?: boolean;
}
export function PathTagPolicyRulesGenerator({
  defaultSourceCoreTags,
  defaultDestinationCoreTags,
  defaultSourceFacets,
  defaultDestinationFacets,
  onChangeSource,
  onChangeDest,
  sourceDetails,
  destDetails,
  defaultRules,
  onChangeRules,
  defaultSourceTagBasedPolicyId,
  defaultDestinationTagBasedPolicyId,
  metadata,
  isRingFence,
}: PathTagPolicyRulesGeneratorProps) {
  const useSourceTagPolicyFacetStore = useMemo(() => {
    return createTagPolicyFacetStore();
  }, []);

  const [srcDefaultTBP, setDefaultSrcTBP] = useState(
    defaultSourceTagBasedPolicyId
  );
  useEffect(() => {
    setDefaultSrcTBP(defaultSourceTagBasedPolicyId);
  }, [defaultSourceTagBasedPolicyId]);

  const [destDefaultTBP, setDefaultDestTBP] = useState(
    defaultDestinationTagBasedPolicyId
  );
  useEffect(() => {
    setDefaultDestTBP(defaultDestinationTagBasedPolicyId);
  }, [defaultDestinationTagBasedPolicyId]);

  const [sourceFacets, setDefaultSourceFacets] = useState(
    defaultSourceFacets ??
      tagsToFacetState(defaultSourceCoreTags ?? {}, metadata)
  );
  useEffect(() => {
    setDefaultSourceFacets(
      defaultSourceFacets ??
        tagsToFacetState(defaultSourceCoreTags ?? {}, metadata)
    );
  }, [defaultSourceFacets, defaultSourceCoreTags, metadata]);

  const [destinationFacets, setDefaultDestinationFacets] = useState(
    defaultDestinationFacets ??
      tagsToFacetState(defaultDestinationCoreTags ?? {}, metadata)
  );

  useEffect(() => {
    setDefaultDestinationFacets(
      defaultDestinationFacets ??
        tagsToFacetState(defaultDestinationCoreTags ?? {}, metadata)
    );
  }, [defaultDestinationFacets, defaultDestinationCoreTags, metadata]);

  const srcSetFacets = useSourceTagPolicyFacetStore(state => state.setFacets);
  useEffect(() => {
    srcSetFacets(sourceFacets);
  }, [sourceFacets, srcSetFacets]);

  const useDestTagPolicyFacetStore = useMemo(() => {
    return createTagPolicyFacetStore();
  }, []);

  const destSetFacets = useDestTagPolicyFacetStore(state => state.setFacets);
  useEffect(() => {
    destSetFacets(destinationFacets);
  }, [destinationFacets, destSetFacets]);

  const { aggregates, isLoading: isLoadingRecommendedRules } =
    usePortRecommendations({
      sourceCriteriaFacets: sourceDetails?.criteria,
      destinationCriteriaFacets: destDetails?.criteria,
    });

  useEffect(() => {
    onChangeRules(EMPTY_LIST);
  }, [sourceDetails?.criteria, destDetails?.criteria, onChangeRules]);

  const setPortSelection = useCallback(
    (selection: PortAgg[]) => {
      onChangeRules(
        selection.map(portAgg => {
          return {
            listenPort: portAgg.port,
            listenPortProtocol: portAgg.protocol,
            listenPortReviewed: "",
            listenProcessNames: "",
          };
        })
      );
    },
    [onChangeRules]
  );

  const showPortRecommendations =
    Boolean(sourceDetails?.criteria) && Boolean(destDetails?.criteria);

  const onChangeSourceCombined = useCallback(
    (p: TagBasedPolicyCreationInput) => {
      onChangeSource(p);
      if (isRingFence) {
        onChangeDest(p);
      }
    },
    [onChangeSource, onChangeDest, isRingFence]
  );

  return (
    <>
      <Stack
        spacing={2}
        pb={2}
        sx={{
          flex: showPortRecommendations ? undefined : 1,
        }}
      >
        <Stack
          spacing={3}
          direction={"row"}
          sx={{
            width: "100%",
            flex: showPortRecommendations ? undefined : 1,
          }}
        >
          <SourceAndDestinationForm
            assetType={isRingFence ? undefined : PathAssetType.Source}
            store={useSourceTagPolicyFacetStore}
            onChange={onChangeSourceCombined}
            defaultTagBasedPolicyId={srcDefaultTBP}
            oppositeStore={useDestTagPolicyFacetStore}
            rules={defaultRules}
          />
          {!isRingFence && (
            <SourceAndDestinationForm
              assetType={PathAssetType.Destination}
              store={useDestTagPolicyFacetStore}
              onChange={onChangeDest}
              defaultTagBasedPolicyId={destDefaultTBP}
              oppositeStore={useSourceTagPolicyFacetStore}
              rules={defaultRules}
            />
          )}
        </Stack>

        {(Boolean(sourceDetails?.criteria) || Boolean(destDetails?.criteria)) &&
          !isRingFence && (
            <SourceAndDestinationConnection
              sourceDetails={sourceDetails}
              destDetails={destDetails}
              onToggle={() => {
                setDefaultSourceFacets(destDetails?.criteria);
                setDefaultDestinationFacets(sourceDetails?.criteria);

                setDefaultSrcTBP(destDefaultTBP);
                setDefaultDestTBP(srcDefaultTBP);
                onChangeSource({});
                onChangeDest({});
              }}
            />
          )}
      </Stack>

      {isLoadingRecommendedRules && (
        <Stack
          my={1}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={1}
        >
          <CircularProgress size={12} />
          <Typography variant="body2">
            {window.getCTTranslatedText("Loading recommendations")}...
          </Typography>
        </Stack>
      )}

      {showPortRecommendations && (
        <Stack
          sx={{ width: "100%", minHeight: defaultRules?.length ? 800 : 300 }}
        >
          <PortRecommendations
            data={aggregates}
            isLoading={isLoadingRecommendedRules}
            setPortSelection={setPortSelection}
          />
        </Stack>
      )}
    </>
  );
}

interface SourceAndDestinationConnectionProps {
  sourceDetails?: TagBasedPolicyCreationInput;
  destDetails?: TagBasedPolicyCreationInput;
  onToggle: VoidFunction;
}

const SourceAndDestinationConnection = ({
  sourceDetails,
  destDetails,
  onToggle,
}: SourceAndDestinationConnectionProps) => {
  const mutation = useAssetAggregateAPI();
  const mutate = mutation.mutate;
  const { data, isLoading, reset } = mutation;
  const count = data?.items?.statistics?.channelhashcount || 0;

  const { data: metadata } = useScopeMetadata({ scope: Scope.Path });
  useEffect(() => {
    if (
      !sourceDetails?.criteria?.size ||
      !destDetails?.criteria?.size ||
      !metadata
    ) {
      reset();
      return;
    }
    const sourceCriteria = FacetUtils.getServerQueryLanguageFromFacets(
      sourceDetails?.criteria,
      metadata
    );
    const destinationCriteria = FacetUtils.getServerQueryLanguageFromFacets(
      destDetails?.criteria,
      metadata
    );

    if (!sourceCriteria || !destinationCriteria) {
      return;
    }
    mutate({
      criteria: `*`,
      sourceCriteria,
      destinationCriteria,
      groupBy: [],
      scope: Scope.Path,
      statistics: ["count(channelhash)"],
    });
  }, [destDetails?.criteria, metadata, mutate, reset, sourceDetails?.criteria]);

  return (
    <Stack
      direction={"row"}
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Stack alignItems="center" justifyContent={"center"}>
        {isLoading ? (
          <CircularProgress size={12} />
        ) : (
          <Typography variant="body2">
            {window.getCTTranslatedText("matchingEntity", {
              count: Number(count ?? 0),
              entity: pluralize(
                window.getCTTranslatedText("path"),
                Number(count ?? 0)
              ),
            })}
          </Typography>
        )}
      </Stack>

      <Button variant="text" color="primary" onClick={onToggle}>
        {window.getCTTranslatedText("Switch directions")}
      </Button>
    </Stack>
  );
};
