import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useTemplateStore } from "pages/templates/store";
import { Template } from "pages/templates/types";
import { useState } from "react";

export interface TemplateDeleteProps {
  template: Template | undefined;
  handleAllowDelete: (value: boolean) => void;
  allowDelete: boolean;
}

const useDeleteTemplatesAPI = (templateId: string | undefined) => {
  return useMutation<any, Error, any>([
    "policy",
    `templates/${templateId}`,
    "delete",
  ]);
};

export function TemplateDelete({
  template,
  handleAllowDelete,
  allowDelete,
}: TemplateDeleteProps) {
  const notify = useEventSubscriptionStore(state => state.notify);
  const deleteTemplatesAPI = useDeleteTemplatesAPI(template?.templateId);
  const requestAPIRefresh = useTemplateStore(state => state.requestAPIRefresh);
  const [loading, setLoading] = useState(false);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  const queryClient = useQueryClient();

  const updateTemplateDetails = () => {
    setLoading(true);

    deleteTemplatesAPI.mutate(
      {},
      {
        onSuccess: response => {
          notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
            label: "DeleteTemplatesRequestSubmittedSuccessfully",
          });
          queryClient.invalidateQueries({
            queryKey: ["policy"],
          });
          setTimeout(() => {
            requestAPIRefresh();
            handleAllowDelete(false);
            setLoading(false);
          }, 500);
        },
        onError: error => {
          setLoading(false);
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      }
    );
  };

  return (
    <Box onClick={e => e.stopPropagation()}>
      <Dialog
        open={allowDelete}
        onClose={() => handleAllowDelete(false)}
        aria-labelledby="delete-template"
      >
        <DialogTitle id="delete-template">
          {window.getCTTranslatedText(`Delete Template`)}
        </DialogTitle>
        <DialogContent>
          {`${window.getCTTranslatedText("AreYouSureYouWantToDeleteTemplate", {
            templateName: template?.templateName,
          })}`}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => handleAllowDelete(false)}
          >
            {window.getCTTranslatedText("Cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            loading={deleteTemplatesAPI.isLoading || loading}
            onClick={updateTemplateDetails}
            autoFocus
            color={"error"}
            type="submit"
          >
            {window.getCTTranslatedText("Delete")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
