import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTIPInput } from "common/atoms/ct-ip-input";
import { IPRangeInt } from "../../types";

const baseRowStyle = {
  pt: 0,
  pb: 1.75,
  px: 3,
};

const rulesSectionWrapper = {
  borderRadius: 1,
};

interface DNSServerInputProps {
  IPList: IPRangeInt[];
  listSetter: (ipRanges: IPRangeInt[]) => void;
  isFormDisabled: boolean;
}

export function DNSServersInput({
  IPList,
  listSetter,
  isFormDisabled,
}: DNSServerInputProps) {
  const handleIPRangesChange = (
    e: TextFieldUpdate,
    index: number,
    IPList: IPRangeInt[],
    listSetter: (ipRanges: IPRangeInt[]) => void
  ) => {
    if (e?.value !== undefined) {
      const value = e?.value.trim() || "";
      let newIPRanges = [...IPList];
      newIPRanges[index]["value"] = value;
      listSetter(newIPRanges);
    }
  };

  const addNewIPRange = (
    IPList: IPRangeInt[],
    listSetter: (ipRanges: IPRangeInt[]) => void
  ) => {
    const newIPRanges = [...IPList];
    newIPRanges.push({ value: "" });
    listSetter(newIPRanges);
  };

  const removeIPRange = (
    index: number,
    IPList: IPRangeInt[],
    listSetter: (ipRanges: IPRangeInt[]) => void
  ) => {
    const newIPRanges = [...IPList];
    newIPRanges.splice(index, 1);
    listSetter(newIPRanges);
  };

  return (
    <Box sx={{ minWidth: "100%" }}>
      <Stack
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={0}
        sx={{
          ...rulesSectionWrapper,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: "100%", my: 2 }}
        >
          <Stack alignItems="center">
            <Typography
              variant="subtitle1"
              sx={{
                color: theme => theme.palette.text.disabled,
              }}
            >
              DNS Servers
            </Typography>
          </Stack>
          <Stack alignItems="center">
            <Button
              variant="text"
              startIcon={<AddCircleOutlineIcon />}
              color="primary"
              onClick={() => addNewIPRange(IPList, listSetter)}
              disabled={isFormDisabled}
            >
              Add DNS Server
            </Button>
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Stack>
            {IPList &&
              IPList.length > 0 &&
              IPList.map((item, index) => {
                return (
                  <Stack
                    direction="row"
                    key={`ip-${index}`}
                    sx={{
                      background: theme =>
                        theme.palette.mode === "dark"
                          ? theme.palette.background.paper
                          : theme.palette.custom.lightGreyBg,
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      justifyContent="flex-start"
                      spacing={2}
                      sx={{
                        ...baseRowStyle,
                        width: "70%",
                      }}
                    >
                      <CTIPInput
                        field={"dnsServer"}
                        displayName={"DNS Server"}
                        value={item?.value}
                        handleUpdate={(event: TextFieldUpdate) =>
                          handleIPRangesChange(event, index, IPList, listSetter)
                        }
                        showLabel={false}
                        disabled={isFormDisabled}
                      />
                    </Stack>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      width="30%"
                    >
                      <IconButton
                        size="small"
                        aria-label="remove IP range"
                        onClick={() => removeIPRange(index, IPList, listSetter)}
                        disabled={isFormDisabled}
                      >
                        <RemoveCircleOutlineIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Stack>
                );
              })}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
