import { useMutation } from "@tanstack/react-query";

export function useUpdateAssetAnnotations(assetId: string | undefined) {
  const path = `assets/${assetId}/annotations`;
  return useMutation<any, Error, any>(["asset", path, "put"]);
}

export function useUpdateBulkAssetsAnnotations() {
  const path = `assets/annotations`;
  return useMutation<any, Error, any>(["asset", path, "put"]);
}
