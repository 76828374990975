import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  // FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  // Radio,
  // RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { getValidObjectEntries } from "common/utils";
import difference from "lodash/difference";
import { useCore } from "modules/core";
import { FacetGroupInfo } from "modules/core/types";
import { FacetControllerGroup } from "modules/facets";
import { GroupInfo } from "modules/facets/components/group-info";
import { decodeThrowable } from "modules/facets/hooks/useFacetQueryConnector";
import { FacetGroups } from "modules/facets/types";
import { Dimension } from "modules/hierarchy-vis/types";
import { SavedQueryList } from "modules/saved-query/components/saved-query-list";
import {
  Scope,
  ScopeMetadata,
  ScopeValuesEntity,
} from "modules/scope-metadata/types";
import { useSearchStore } from "modules/search/store";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { FormSectionWrapper } from "pages/create-tag-policy/components/PolicyForm";
import { useTagRulesListAPI } from "pages/tags/components/tag-rules-list/TagRulesList";
import {
  useTagRuleFacetStore,
  useTagRuleStore,
} from "pages/tags/components/tag-rules-list/store";
import { TagRule } from "pages/tags/components/tag-rules-list/types";
import { Tags, useTagsAPI } from "pages/tags/hooks/useTagsAPI";
import { DimensionPicker } from "pages/traffic-visualizer/components/DimensionPicker";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AddCriteria } from "./add-criteria";
import { DisplayAppliedRule } from "./display-applied-rule";

const tagWrapperStyle = {
  width: "100%",
  mt: 2,
  pt: 1,
  px: 2,
  pb: 2,
};

enum FieldType {
  VALUE = "Value",
  FIELD = "Field",
}

export function useTagRuleApi({ ruleId }: any) {
  return useQuery<TagRule, Error>(["tag=rule", `tagrules/${ruleId}`], {
    staleTime: 10000,
    enabled: Boolean(ruleId),
  });
}

function useSaveRuleMutation() {
  return useMutation<any, Error, Omit<TagRule, "ruleId">>([
    "tag-rule",
    "tagrules",
  ]);
}

function useEditRuleMutation(savedRuleId: string | undefined) {
  return useMutation<any, Error, Omit<TagRule, "ruleId">>([
    "tag-rule",
    `tagrules/${savedRuleId}`,
    "PUT",
  ]);
}
type Pairs = { [key: string]: string };
type FieldTypeMap = { [key: string]: FieldType };

export function RuleForm({
  facetOrder,
  metadata,
}: {
  facetOrder: FacetGroupInfo;
  metadata: ScopeMetadata;
}) {
  const notify = useEventSubscriptionStore(state => state.notify);
  const { ruleId } = useParams();
  let { data: savedRule } = useTagRuleApi({ ruleId });
  const [urlParams, setURLParams] = useSearchParams();
  const setQuery = useRef(setURLParams);
  setQuery.current = setURLParams;
  const setSearch = useSearchStore(state => state.setSearch);
  const setFilters = useTagRuleFacetStore(state => state.setFacets);
  const isEditMode = Boolean(ruleId);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const [selectedFieldType, setSelectedFieldType] = useState<FieldTypeMap>({});

  useEffect(() => {
    setSearch("");
    return () => {
      setSearch("");
    };
  }, [setSearch]);

  useEffect(() => {
    setFilters(undefined);
    return () => {
      setFilters(undefined);
    };
  }, [setFilters]);

  useEffect(() => {
    if (!savedRule) {
      return;
    }

    const pairs = savedRule?.onMatch ?? {};

    Object.entries(pairs ?? {}).forEach(([key, value]) => {
      const isField = value.startsWith("$<");

      if (isField) {
        setSelectedFieldType(prev => ({ ...prev, [key]: FieldType.FIELD }));
        const extractedValue = value.match(/\$<(.*?)>/)?.[1];
        if (extractedValue) {
          pairs[key] = extractedValue;
        }
      } else {
        setSelectedFieldType(prev => ({ ...prev, [key]: FieldType.VALUE }));
      }
    });

    setName(savedRule?.ruleName ?? "");
    setPairs(pairs);

    if (savedRule?.ruleCriteriaAsParams) {
      setQuery.current(savedRule?.ruleCriteriaAsParams);
    }
    if (savedRule?.ruleDescription) {
      setRuleDescription(savedRule?.ruleDescription);
    }
    const urlParams = new URLSearchParams(savedRule?.ruleCriteriaAsParams);
    const search = urlParams.get("search");
    if (search) {
      setSearch(search);
    }

    const filters = decodeThrowable(urlParams.get("filters"));
    setFilters(filters);
  }, [savedRule, setSearch, setFilters, setSelectedFieldType]);

  useEffect(() => {
    // append existing search params with tab=rules
    const criteriaAsParams = new URLSearchParams(urlParams);
    if (criteriaAsParams.get("tab") === "rules") {
      return;
    }
    criteriaAsParams.set("tab", "rules");
    setURLParams(criteriaAsParams);
  }, [setURLParams, urlParams]);

  const [name, setName] = useState("");
  const [ruleDescription, setRuleDescription] = useState<string | null>(null);

  const [pairs, setPairs] = useState<Pairs>({});

  const saveMutation = useSaveRuleMutation();
  const editMutation = useEditRuleMutation(savedRule?.ruleId);

  const searchCriteria = useTagRuleFacetStore(
    state => state.currentSearchCriteria
  );

  const coreResponse = useCore<Scope.TagRule>({
    useStore: useTagRuleStore,
    facetGroupInfo: facetOrder,
    scope: Scope.TagRule,
    useFacetStore: useTagRuleFacetStore,
    useApi: useTagRulesListAPI,
    pageSize: 1,
  });

  const navigate = useNavigate();

  const reset = () => {
    setName("");
    setRuleDescription("");
    setPairs({});
  };

  const saveTagRule = () => {
    if (isFormInvalid()) {
      return;
    }
    const validTagPairs = getValidObjectEntries(pairs);

    Object.keys(validTagPairs).forEach((key, index) => {
      if (key !== "Lov" && selectedFieldType[key] === FieldType.FIELD) {
        validTagPairs[key] = `$<${validTagPairs[key]}>`;
      }
    });

    let criteriaAsParams = new URLSearchParams(urlParams);
    criteriaAsParams.delete("tab");
    const body = {
      ruleCriteria: searchCriteria,
      ruleCriteriaAsParams: criteriaAsParams.toString(),
      onMatch: validTagPairs,
      ruleDescription,
      ruleName: name,
    };
    const mutation = isEditMode ? editMutation : saveMutation;
    mutation.mutate(body, {
      onSuccess: () => {
        notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
          label: "TagLabelRuleRequestSubmittedSuccessfully",
          variables: {
            action: window.getCTTranslatedText(
              isEditMode ? "Update" : "Create"
            ),
          },
        });
        setTimeout(() => {
          reset();
          navigate("/tags?tab=rules");
        }, 200);
      },
      onError: error => {
        let message = "SomethingWentWrong";
        // @ts-ignore: Axios error type
        if (error?.response?.status === 409) {
          message = "ASimilarRuleAlreadyExists";
        }
        setSnackbar(true, SnackBarSeverity.Error, message);
      },
    });
  };

  const isFormInvalid = () => {
    if (!searchCriteria || searchCriteria === "*") {
      return true;
    }

    const validTagPairs = getValidObjectEntries(pairs);
    const hasValidValues = Object.keys(validTagPairs)?.length > 0;
    return !hasValidValues;
  };

  return (
    <Stack
      component={Paper}
      p={3}
      spacing={4}
      alignItems={"flex-end"}
      sx={{ width: "100%" }}
    >
      <Stack alignItems={"flex-start"} sx={{ width: "100%" }}>
        <Typography variant="h6">
          {`${window.getCTTranslatedText(
            isEditMode ? "Edit" : "Create"
          )} ${window.getCTTranslatedText("Tag Label Rule")}`}
        </Typography>
      </Stack>
      <Stack direction={"column"} spacing={4} sx={{ width: "100%" }}>
        <Stack spacing={6} direction="row">
          <Stack sx={{ width: "50%" }}>
            <CTInputField
              field="name"
              displayName="Name"
              value={name ?? ""}
              handleUpdate={(event: TextFieldUpdate) => setName(event?.value)}
            />
          </Stack>
          <Stack sx={{ width: "50%" }}>
            <CTInputField
              field="description"
              displayName="Description"
              value={ruleDescription ?? ""}
              handleUpdate={(event: TextFieldUpdate) =>
                setRuleDescription(event?.value)
              }
            />
          </Stack>
        </Stack>

        <FormSectionWrapper>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
              sx={{
                width: "100%",
              }}
            >
              <Stack alignItems="center">
                <Typography variant="subtitle1">
                  {window.getCTTranslatedText("Rule Criteria")} *
                </Typography>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={2}>
                <AddCriteria
                  useFacetStore={useTagRuleFacetStore}
                  facetsOpen={coreResponse.facetsOpen}
                  setFacetsOpen={coreResponse.setFacetsOpen}
                  dialogTitle="Create Rule Criteria"
                  btnTitle="Add rule criteria"
                >
                  <FacetControllerGroup
                    useFacetStore={useTagRuleFacetStore}
                    config={coreResponse.facetConfig}
                    value={coreResponse.facetState}
                    onChange={coreResponse.updateFacet}
                  />
                </AddCriteria>
                <SavedQueryList
                  useFacetStore={useTagRuleFacetStore}
                  showButtonTrigger={true}
                />
              </Stack>
            </Stack>

            <DisplayAppliedRule useFacetStore={useTagRuleFacetStore} />
          </Stack>
        </FormSectionWrapper>

        <FormSectionWrapper>
          <Stack>
            <ApplyTags
              pairs={pairs}
              setPairs={setPairs}
              metadata={metadata}
              facetOrder={facetOrder}
              selectedFieldType={selectedFieldType}
              setSelectedFieldType={setSelectedFieldType}
            />
          </Stack>
        </FormSectionWrapper>
      </Stack>

      <Button
        variant="contained"
        color="primary"
        disabled={isFormInvalid()}
        onClick={() => saveTagRule()}
      >
        {window.getCTTranslatedText(isEditMode ? "Save" : "Create")}
      </Button>
    </Stack>
  );
}

function ApplyTags({
  pairs,
  setPairs,
  metadata,
  facetOrder,
  selectedFieldType,
  setSelectedFieldType,
}: {
  pairs: Pairs;
  setPairs: React.Dispatch<React.SetStateAction<Pairs>>;
  metadata: ScopeMetadata;
  facetOrder: FacetGroupInfo;
  selectedFieldType: FieldTypeMap;
  setSelectedFieldType: (value: FieldTypeMap) => void;
}) {
  const { tagFields, isLoading } = useTagsAPI();
  if (isLoading) {
    return (
      <Stack
        sx={{
          flex: 1,
        }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress size={24} />
      </Stack>
    );
  }
  if (!tagFields) {
    return null;
  }
  return (
    <>
      <KeyValuePairs
        tagFields={tagFields}
        pairs={pairs}
        metadata={metadata}
        setPairs={setPairs}
        facetOrder={facetOrder}
        selectedFieldType={selectedFieldType}
        setSelectedFieldType={setSelectedFieldType}
      />
    </>
  );
}

const AcceptableTypes: Record<string, boolean> = {
  [FacetGroups.Tags]: true,
  [FacetGroups.Others]: true,
  [FacetGroups.Assets]: true,
  [FacetGroups.Security]: true,
};

function KeyValuePairs({
  tagFields,
  pairs,
  setPairs,
  metadata,
  facetOrder,
  selectedFieldType,
  setSelectedFieldType,
}: {
  tagFields: Tags;
  pairs: Pairs;
  setPairs: React.Dispatch<React.SetStateAction<Pairs>>;
  metadata: ScopeMetadata;
  facetOrder: FacetGroupInfo;
  selectedFieldType: FieldTypeMap;
  setSelectedFieldType: (value: FieldTypeMap) => void;
}) {
  const theme = useTheme();
  const [pairOrder, setPairOrder] = useState<Array<string>>(Object.keys(pairs));

  let allTags = useMemo(
    () => [
      ...tagFields.coreTags.filter(
        col =>
          false ===
          [
            "primarysubnet",
            "clusteridentifier",
            "containernamespace",
            "usergroup",
            "department",
            "type",
            "osname",
          ].includes(col.name ?? "")
      ),
      ...tagFields.userDefinedTags,
    ],
    [tagFields]
  );

  const { nonSelectedDimensions, allDimensions } = useMemo(() => {
    let allTagNames = allTags.map(tag => tag.name);
    let selectedTagNames = Object.keys(pairs);
    let diff = difference(allTagNames, selectedTagNames);
    let nonSelectedDimensions: { [key: string]: Dimension } = {};
    let allDimensions: { [key: string]: Dimension } = {};
    allTags.forEach(col => {
      let name = col.name || "";
      let dimension = {
        dataType: col.dataType,
        label: col.displayName,
        name,
      };
      if (diff.includes(col.name)) {
        nonSelectedDimensions[name] = dimension;
      }
      allDimensions[name] = dimension;
    });
    return { nonSelectedDimensions, allDimensions };
  }, [allTags, pairs]);

  const addPair = (fieldType: FieldType) => {
    let tag = { name: "" };

    if (fieldType === FieldType.VALUE) {
      for (const dimension of Object.values(nonSelectedDimensions)) {
        if ((metadata.columns[dimension.name]?.values?.length ?? 0) <= 0) {
          tag = dimension;
          break;
        }
      }
    } else {
      tag = Object.values(nonSelectedDimensions)?.[0];
    }

    if (!tag.name) {
      return;
    }

    setPairs(prevPairs => ({
      ...prevPairs,
      [tag.name]: "",
    }));
    setPairOrder(old => [...old, tag.name]);
    setSelectedFieldType({ ...selectedFieldType, [tag.name]: fieldType });
  };

  const removePair = (key: string) => {
    const updatedPairs = { ...pairs };
    delete updatedPairs[key];
    setPairs(updatedPairs);
    setPairOrder(old => old.filter(p => p !== key));
  };

  const handleValueChange = (value: string, key: string) => {
    setPairs(prevPairs => ({
      ...prevPairs,
      [key]: value,
    }));
  };

  const AllAutocompleteOptions = useMemo(() => {
    return Object.keys(facetOrder)
      .filter(facet => {
        return (
          AcceptableTypes[facetOrder[facet]] === true &&
          metadata.columns[facet]?.displayName &&
          metadata.columns[facet]?.dataType === "String" &&
          !metadata.columns[facet]?.listOfValues
        );
      })
      .map(facet => {
        const label = metadata.columns[facet]?.displayName;
        let facetGroupName = facetOrder[facet];

        const isPropertiesFacetType =
          metadata.columns[facet]?.internalName?.startsWith("_p_");
        if (isPropertiesFacetType) {
          facetGroupName = FacetGroups.Others;
        } else if (facetGroupName === FacetGroups.Others) {
          facetGroupName = "Others";
        }

        let groupInfo = GroupInfo;
        return {
          id: metadata.columns[facet]?.internalName,
          label: isPropertiesFacetType ? `${label} (Properties)` : label,
          ...metadata.columns[facet],
          icon: groupInfo[facetGroupName]?.icon,
          group: window.getCTTranslatedText(facetGroupName),
        };
      })
      .sort((a, b) => -b.group.localeCompare(a.group));
  }, [facetOrder, metadata.columns]);

  const autoCompleteOptions = (dimension: Dimension) => {
    return AllAutocompleteOptions.filter(t => t.id !== dimension.name);
  };
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          width: "100%",
        }}
      >
        <Stack alignItems="center">
          <Typography variant="subtitle1">
            {window.getCTTranslatedText("Apply Tags")} *
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Button
            sx={{ alignSelf: "flex-start" }}
            variant="text"
            color="primary"
            startIcon={<AddCircleOutline />}
            onClick={() => addPair(FieldType.VALUE)}
          >
            {window.getCTTranslatedText("Add Tag Value")}
          </Button>
          {
            <Button
              sx={{ alignSelf: "flex-start" }}
              variant="text"
              color="primary"
              startIcon={<AddCircleOutline />}
              onClick={() => addPair(FieldType.FIELD)}
            >
              {window.getCTTranslatedText("Add Tag Mapping")}
            </Button>
          }
        </Stack>
      </Stack>
      <Paper
        sx={Object.keys(pairs).length > 0 ? tagWrapperStyle : {}}
        elevation={theme.palette.mode === "dark" ? 3 : 0}
      >
        <Stack spacing={2} sx={{ mt: 0 }}>
          {Object.keys(pairs)
            .sort((a, b) => pairOrder.indexOf(a) - pairOrder.indexOf(b))
            .map((key, index) => (
              <Stack
                spacing={2}
                key={selectedFieldType[key] + index}
                direction={"row"}
                alignItems={"flex-end"}
              >
                <DimensionPicker
                  sx={{ flex: 1 }}
                  selectedDimension={allDimensions[key]}
                  options={Object.values(allDimensions)}
                  showLabel={true}
                  pickerLabel={
                    index === 0
                      ? `${window.getCTTranslatedText("Tag Name")} *`
                      : ""
                  }
                  setSelectedDimension={d => {
                    setPairOrder(order => {
                      let oldIndex = order.indexOf(key);
                      let newOrder = [...order];
                      if (oldIndex >= 0) {
                        newOrder[oldIndex] = d.name;
                      }
                      return newOrder;
                    });
                    removePair(key);
                    handleValueChange(pairs[key], d.name);
                    setSelectedFieldType({
                      ...selectedFieldType,
                      [d.name]: selectedFieldType[key],
                    });
                  }}
                />

                {(metadata.columns[key]?.values?.length ?? 0) > 0 ? (
                  <LovFieldSelection
                    setPairs={setPairs}
                    options={metadata.columns[key]?.values}
                    pairKey={key}
                    value={pairs[key] || ""}
                  />
                ) : (
                  <>
                    {(!selectedFieldType[key] ||
                      selectedFieldType[key] === FieldType.VALUE) && (
                      <TextField
                        sx={{ flex: 1 }}
                        id="tag-value"
                        label={window.getCTTranslatedText("Tag Value")}
                        value={pairs[key] || ""}
                        multiline={true}
                        variant="standard"
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        onChange={e => handleValueChange(e.target.value, key)}
                      />
                    )}

                    {selectedFieldType[key] === FieldType.FIELD && (
                      <Autocomplete
                        id="field-auto-complete"
                        options={autoCompleteOptions(allDimensions[key])}
                        sx={{ flex: 1 }}
                        value={
                          autoCompleteOptions(allDimensions[key]).find(
                            option => option.internalName === pairs[key]
                          ) ?? null
                        }
                        groupBy={option => option.group}
                        onInputChange={(event, newInputValue) => {
                          const value = autoCompleteOptions(
                            allDimensions[key]
                          ).find(
                            option => option.label === newInputValue
                          )?.internalName;
                          if (value) {
                            setPairs(prev => ({ ...prev, [key]: value }));
                          }
                        }}
                        getOptionLabel={option =>
                          window.getCTTranslatedText(option.label ?? "")
                        }
                        renderInput={params => (
                          <TextField
                            variant="standard"
                            sx={{ m: 0 }}
                            {...params}
                            label={`${window.getCTTranslatedText(
                              "MappedFrom"
                            )} *`}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )}
                  </>
                )}
                <IconButton
                  aria-label="remove"
                  size="small"
                  onClick={() => {
                    removePair(key);
                  }}
                >
                  <RemoveCircleOutline fontSize="small" />
                </IconButton>
              </Stack>
            ))}
        </Stack>
      </Paper>
    </>
  );
}

const LovFieldSelection = ({
  options,
  setPairs,
  pairKey,
  value,
}: {
  options?: ScopeValuesEntity[] | null;
  setPairs: React.Dispatch<React.SetStateAction<Pairs>>;
  pairKey: string;
  value: string;
}) => {
  return (
    <>
      <FormControl
        variant="standard"
        sx={{
          width: "fit-content",
          pointerEvents: "all",
          m: 0,
          mt: 1,
          flex: 1,
        }}
      >
        <InputLabel shrink>{`${window.getCTTranslatedText(
          "MappedFrom"
        )} *`}</InputLabel>
        <Select
          size="small"
          placeholder={window.getCTTranslatedText("Select")}
          variant="standard"
          value={value}
          onChange={e => {
            setPairs(prev => ({
              ...prev,
              [pairKey]: e.target.value as string,
            }));
          }}
          sx={{ flex: 1, textTransform: "capitalize" }}
        >
          {options?.map(d => {
            return (
              <MenuItem
                sx={{ textTransform: "capitalize" }}
                key={d.display}
                value={d.display}
              >
                {window.getCTTranslatedText(d.display)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

// const FieldTypeSelection = ({
//   fieldKey,
//   selectedFieldType,
//   setSelectedFieldType,
//   isDisabled,
// }: {
//   selectedFieldType: FieldTypeMap;
//   setSelectedFieldType: (value: FieldTypeMap) => void;
//   isDisabled: boolean;
//   fieldKey: string;
// }) => {
//   const options = [FieldType.VALUE, FieldType.FIELD];

//   return (
//     <>
//       <FormControl
//         variant="standard"
//         sx={{
//           width: "fit-content",
//           pointerEvents: "all",
//           m: 0,
//           mt: 1,
//         }}
//       >
//         <InputLabel shrink={true} sx={{ mt: -2 }}>
//           {"Select Field Type"}
//         </InputLabel>

//         <RadioGroup
//           row
//           aria-labelledby="select-source-destination-label"
//           name="path-direction-radio-buttons-group"
//           defaultValue={selectedFieldType[fieldKey]}
//           onChange={e => {
//             let fieldType = e.target.value as FieldType;
//             setSelectedFieldType({
//               ...selectedFieldType,
//               [fieldKey]: fieldType,
//             });
//           }}
//         >
//           {options?.map((d, index, all) => {
//             return (
//               <FormControlLabel
//                 key={d}
//                 disabled={isDisabled}
//                 value={d}
//                 control={<Radio disabled={isDisabled} size="small" />}
//                 label={d}
//                 sx={{
//                   "& .MuiFormControlLabel-label": {
//                     fontSize: 14,
//                   },
//                   pr: index === all.length - 1 ? 0 : 3,
//                   mb: -1,
//                 }}
//               />
//             );
//           })}
//         </RadioGroup>
//       </FormControl>
//     </>
//   );
// };

export default KeyValuePairs;
