import { RefreshOutlined } from "@mui/icons-material";
import {
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { TimeFilter } from "common/molecules/TimeFilter";
import { updateTimeFilterFacet } from "common/store/helper";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import {
  FacetOptionState,
  FacetState,
  Operator,
  UpdateFacet,
} from "modules/facets/types";
import { Dimension } from "modules/hierarchy-vis/types";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import {
  Direction,
  SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { useAssetsAPI } from "pages/assets/components/asset-data-grid/hooks";
import {
  useAssetsFacetsOrder,
  useFacetOrderAlpha,
} from "pages/assets/constants";
import { useAssetStore } from "pages/assets/store";
import { Asset } from "pages/assets/types";
import { PATH_TRAFFIC_FACET_ORDER } from "pages/paths/constants";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FacetGroupInfo } from "../../../modules/core/types";
import { usePathStore } from "../../paths/store/usePathStore";
import { useSourceFacetStore, useVisxStore } from "../store";
import { EnabledStatusMap, PathReviewStatus, PathTypes } from "../types";
import { DimensionPicker } from "./DimensionPicker";
import {
  PathLegendsGroup,
  allowedStatuses,
  deniedStatuses,
  mixedStatuses,
} from "./source-node/PathsLegendsGroup";

const readOnlyDimensions = [
  "direction",
  "pathlastobserved",
  "srcip",
  "dstip",
  "enforced",
  "reviewed",
];

export interface TrafficVisxToolbarProps {
  hubCriteria: string | undefined;
  onClose?: Function;
  setStatusEnabled: (status: PathTypes, enabled: boolean) => void;
  enabledStatus: EnabledStatusMap;
  sourceAssetFacet: FacetState;
  updateSourceAssetFacet: UpdateFacet;
  assetMetadata: ScopeMetadata | undefined;
  dimensionOptions: Dimension[];
}

export function TrafficVisxToolbar({
  hubCriteria,
  onClose,
  sourceAssetFacet,
  updateSourceAssetFacet,
  setStatusEnabled,
  enabledStatus,
  assetMetadata,
  dimensionOptions,
}: TrafficVisxToolbarProps) {
  const updateFacet = useSourceFacetStore(state => state.updateFacet);
  const { isFeatureEnabled } = useFeatureFlagControl(FEATURES.PROGRESSIVE);
  const selectedDimension = useVisxStore(state => state.selectedDimension);
  const isToolbarHidden = useVisxStore(state => state.isToolbarHidden);

  const theme = useTheme();

  const setIsDrawerOpen = useVisxStore(state => state.setIsDrawerOpen);

  const selectedDirection = useVisxStore(state => state.selectedDirection);
  const setSelectedDirection = useVisxStore(
    state => state.setSelectedDirection
  );

  const setSelectedSecurityStatus = useVisxStore(
    state => state.setSelectedSecurityStatus
  );
  const setSelectedDimension = useVisxStore(
    state => state.setSelectedDimension
  );

  const setSelectedPathStatus = useVisxStore(
    state => state.setSelectedPathStatus
  );
  const setSelectedNode = useVisxStore(state => state.setSelectedNode);

  const onDirectionChange = (direction: Direction) => {
    setSelectedDirection(direction);
    setIsDrawerOpen(false);
    setSelectedNode(undefined);
    setSelectedSecurityStatus(SecurityStatus.None);
    setSelectedPathStatus(PathReviewStatus.Enforced);

    let options: FacetOptionState = new Map();
    options.set(direction === Direction.Inbound ? "inbound" : "outbound", {
      isSelected: true,
      operator: Operator.EQUAL,
    });

    updateFacet({
      facetName: "direction",
      options,
    });
  };

  const assetsFacetsOrder = useAssetsFacetsOrder();
  const facetOrderAlpha = useFacetOrderAlpha();

  const VISX_FACET_GROUP_INFO = useMemo(() => {
    return {
      ...assetsFacetsOrder,
      ...PATH_TRAFFIC_FACET_ORDER,
    };
  }, [assetsFacetsOrder]);

  const VISX_FACET_GROUP_INFO_WITHOUT_READ_ONLY_DIMENSIONS =
    useMemo<FacetGroupInfo>(() => {
      let newOrder: FacetGroupInfo = isFeatureEnabled
        ? { ...VISX_FACET_GROUP_INFO, ...facetOrderAlpha }
        : { ...VISX_FACET_GROUP_INFO };

      readOnlyDimensions.forEach(key => {
        delete newOrder[key];
      });
      return newOrder;
    }, [isFeatureEnabled, VISX_FACET_GROUP_INFO, facetOrderAlpha]);

  const sourceAssetCoreResponse = useCore<Asset>({
    name: "visx",
    useStore: useAssetStore,
    facetGroupInfo: VISX_FACET_GROUP_INFO_WITHOUT_READ_ONLY_DIMENSIONS,
    scope: Scope.Path,
    useApi: useAssetsAPI,
    pageSize: 0,
    includeFacetFields: [],
    useFacetStore: useSourceFacetStore,
    skipUseFacetQueryConnector: false,
  });

  const facetConfig = useMemo(() => {
    return sourceAssetCoreResponse?.facetConfig?.map(group => {
      group.facets.forEach(f => {
        if (
          f.qualifier === Scope.PathStat ||
          f.qualifier === Scope.AssetPathRecency
        ) {
          f.qualifier = Scope.Path;
        } else if (![Scope.Port, Scope.Path].includes(f.qualifier)) {
          f.qualifier = Scope.Asset;
        }
      });
      return group;
    });
  }, [sourceAssetCoreResponse.facetConfig]);

  const [showSourceAssetFacetDrawer, setShowSourceAssetFacetDrawer] =
    useState(false);

  const sourceAssetFilters = () => {
    return (
      <FacetOpenCloseController
        facetsOpen={showSourceAssetFacetDrawer}
        readOnlyDimensions={readOnlyDimensions}
        setFacetsOpen={setShowSourceAssetFacetDrawer}
        useFacetStore={useSourceFacetStore}
        disableSavedQuery={true}
      >
        <FacetControllerGroup
          config={facetConfig}
          value={sourceAssetFacet}
          onChange={updateSourceAssetFacet}
          useFacetStore={useSourceFacetStore}
        />
      </FacetOpenCloseController>
    );
  };

  const selectedTime = useUserPreferencesStore(state => state.timeFilter);
  const setSelectedTime = useUserPreferencesStore(state => state.setTimeFilter);

  const setSelectedTimeFilter = useCallback(
    (time: string) => {
      setSelectedTime(time);
      updateTimeFilterFacet({
        updateFacet,
        facetName: "pathlastobserved",
        time,
      });
    },
    [setSelectedTime, updateFacet]
  );

  useEffect(() => {
    updateTimeFilterFacet({
      updateFacet,
      facetName: "pathlastobserved",
      time: selectedTime,
    });
  }, [selectedTime, updateFacet]);

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          borderRadius: 0,
          background:
            theme.palette.mode === "dark"
              ? theme.palette.grey[900]
              : theme.palette.grey[100],
          display: isToolbarHidden ? "none" : "block",
        }}
      >
        <Stack
          spacing={1}
          direction={"column"}
          sx={{
            position: "relative",
            pt: 3,
          }}
        >
          <Stack
            direction="row"
            alignItems={"center"}
            sx={{
              width: "100%",
              px: 3,
            }}
          >
            <>
              {sourceAssetFilters()}
              <SectionDivider height={40}></SectionDivider>
              <RefreshButton />
            </>
          </Stack>
          <Stack
            sx={{
              borderRadius: 0,
              pl: 3,
              pb: 1.5,
            }}
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              sx={{
                pl: 1,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                sx={{ width: "100%" }}
              >
                <Grid item xs="auto">
                  <TrafficDirection
                    selectedDirection={selectedDirection}
                    onDirectionChange={onDirectionChange}
                  />
                </Grid>
                <Grid item xs="auto">
                  <PathFilters
                    enabledStatus={enabledStatus}
                    setStatusEnabled={setStatusEnabled}
                  />
                </Grid>
                <Grid item xs="auto">
                  <DimensionFilters
                    selectedDimension={selectedDimension}
                    setSelectedDimension={setSelectedDimension}
                    Dimensions={dimensionOptions}
                  />
                </Grid>
                <Grid item xs="auto">
                  <TimeFilterBlock
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTimeFilter}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}

interface TrafficDirectionProps {
  onDirectionChange: (direction: Direction) => void;
  selectedDirection: Direction;
}

const TrafficDirection = ({
  selectedDirection,
  onDirectionChange,
}: TrafficDirectionProps) => {
  return (
    <>
      <Stack direction="row" alignItems={"center"}>
        <Stack justifyContent="flex-start">
          <ToolbarSectionTitle
            title={`${window.getCTTranslatedText("Security")}`}
          ></ToolbarSectionTitle>
          <Stack direction="row" justifyContent="flex-start">
            <RadioGroup
              row
              aria-labelledby="direction-label"
              name="direction-radio-buttons-group"
              value={selectedDirection}
              defaultValue={Direction.Inbound}
              onChange={(_, value) =>
                onDirectionChange(Number(value) as Direction)
              }
            >
              <Stack direction={"row"}>
                <Stack>
                  <FormControlLabel
                    value={Direction.Inbound}
                    control={<Radio size="small" color="default" />}
                    label={
                      <Stack direction={"column"}>
                        <Typography variant="body1">
                          {window.getCTTranslatedText("Attack Surface")}
                        </Typography>
                      </Stack>
                    }
                  />
                </Stack>
                <Stack>
                  <FormControlLabel
                    sx={{ mr: 0 }}
                    value={Direction.Outbound}
                    control={<Radio size="small" color="default" />}
                    label={
                      <Stack
                        direction={"column"}
                        spacing={1}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography variant="body1">
                          {window.getCTTranslatedText("Blast Radius")}
                        </Typography>
                      </Stack>
                    }
                  />
                </Stack>
              </Stack>
            </RadioGroup>
          </Stack>
        </Stack>
        <SectionDivider></SectionDivider>
      </Stack>
    </>
  );
};

interface PathFilterProps {
  setStatusEnabled: (status: PathTypes, enabled: boolean) => void;
  enabledStatus: EnabledStatusMap;
}

// const FreeWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.background.paper,
//     maxWidth: "none",
//     borderRadius: "8px",
//     padding: 0,
//   },
// }));

const PathFilters = ({ enabledStatus, setStatusEnabled }: PathFilterProps) => {
  return (
    <>
      <Stack direction="row" alignItems={"flex-end"}>
        <Stack justifyContent="flex-start">
          <Stack direction="row" spacing={1}>
            <ToolbarSectionTitle
              title={`${window.getCTTranslatedText("Allowed Paths")}`}
            ></ToolbarSectionTitle>
          </Stack>
          <Stack>
            <PathLegendsGroup
              statuses={allowedStatuses}
              enabledStatus={enabledStatus}
              setStatusEnabled={setStatusEnabled}
            />
          </Stack>
        </Stack>

        <SectionDivider height={30} />

        <Stack justifyContent="flex-start">
          <Stack direction="row" spacing={1}>
            <ToolbarSectionTitle
              title={`${window.getCTTranslatedText("Blocked Paths")}`}
            ></ToolbarSectionTitle>
          </Stack>
          <Stack>
            <PathLegendsGroup
              statuses={deniedStatuses}
              enabledStatus={enabledStatus}
              setStatusEnabled={setStatusEnabled}
            />
          </Stack>
        </Stack>

        <SectionDivider height={30} />

        <Stack justifyContent="flex-start">
          <Stack>
            <PathLegendsGroup
              statuses={mixedStatuses}
              enabledStatus={enabledStatus}
              setStatusEnabled={setStatusEnabled}
            />
          </Stack>
        </Stack>
        <SectionDivider />
      </Stack>
    </>
  );
};

interface DimensionFiltersProps {
  selectedDimension: Dimension | undefined;
  setSelectedDimension: (d: Dimension) => void;
  Dimensions: Dimension[];
}

const DimensionFilters = ({
  selectedDimension,
  setSelectedDimension,
  Dimensions,
}: DimensionFiltersProps) => {
  return (
    <>
      <Stack direction="row" alignItems={"center"}>
        <Stack justifyContent="flex-start" sx={{ pr: 1 }}>
          <ToolbarSectionTitle
            title={`${window.getCTTranslatedText("Dimension")}`}
          ></ToolbarSectionTitle>
          <Stack justifyContent="flex-start" flexWrap={"wrap"}>
            <DimensionPicker
              {...{
                selectedDimension,
                setSelectedDimension,
                options: Dimensions,
              }}
            />
          </Stack>
        </Stack>
        <SectionDivider></SectionDivider>
      </Stack>
    </>
  );
};

interface TimeFiltersProps {
  selectedTime: string;
  setSelectedTime: (time: string) => void;
}

const TimeFilterBlock = ({
  selectedTime,
  setSelectedTime,
}: TimeFiltersProps) => {
  return (
    <>
      <Stack direction="row" alignItems={"center"}>
        <Stack justifyContent="flex-start">
          <ToolbarSectionTitle
            title={window.getCTTranslatedText("Time")}
          ></ToolbarSectionTitle>
          <Stack justifyContent="flex-start" flexWrap={"wrap"}>
            <TimeFilter
              selectedTime={selectedTime || ""}
              setSelectedTime={setSelectedTime}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const ToolbarSectionTitle = ({
  title,
  icon,
}: {
  title: string;
  icon?: ReactNode;
}) => {
  return (
    <>
      <Stack direction="row" justifyContent="flex-start" alignItems={"center"}>
        {icon}
        <Typography
          variant="body2"
          sx={{
            textTransform: "capitalize",
            color: theme => theme.palette.text.disabled,
          }}
        >
          {title}
        </Typography>
      </Stack>
    </>
  );
};

const SectionDivider = ({ height = 60 }: { height?: number }) => {
  return (
    <Divider
      orientation="vertical"
      sx={{
        mr: `16px !important`,
        width: "12px",
        height: height,
      }}
    ></Divider>
  );
};

function RefreshButton() {
  const setTrafficData = useVisxStore(state => state.setTrafficData);
  const reload = usePathStore(state => state.requestAPIRefresh);

  return (
    <IconButton
      aria-label="refresh"
      onClick={() => {
        console.log("Resetting traffic data because of refresh");
        setTrafficData(undefined);
        reload();
      }}
      sx={{ opacity: 0.7 }}
    >
      <RefreshOutlined />
    </IconButton>
  );
}
