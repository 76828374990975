import { useMutation } from "@tanstack/react-query";

export function useNotificationAPI() {
  const path = `notifications/recipients`;
  return useMutation<any, Error, any>(["notification-config", path, "post"]);
}

export function useUpdateNotificationAPI() {
  const path = `alertrules`;
  return useMutation<any, Error, any>(["notification-config", path, "put"]);
}
