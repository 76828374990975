import { IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import { AppleIcon, LinuxIcon, SolarisIcon, WindowsIcon } from "assets/svgs";
import { AIXIcon } from "assets/svgs/AIXIcon";
import { useCommonStore } from "common/store";

import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { AssetColumnRenderProps } from "pages/assets/types";

const iconSize = "18";

export function AssetOS({ asset, viewOnly }: AssetColumnRenderProps) {
  const theme = useTheme();
  const facetState = useCommonStore(state => state.facets);
  const updateFacetOption = useCommonStore(state => state.updateFacetOption);

  const isSelected =
    !viewOnly && facetState?.get("osname")?.get(asset.osName)?.isSelected;

  let osIcon;

  switch (asset.osName) {
    case "linux":
      osIcon = (
        <LinuxIcon
          color={theme.palette.text.primary}
          width={iconSize}
          height={iconSize}
        />
      );
      break;
    case "windows":
      osIcon = (
        <WindowsIcon
          color={theme.palette.text.primary}
          width={iconSize}
          height={iconSize}
        />
      );
      break;
    case "darwin":
      osIcon = (
        <AppleIcon
          width={iconSize}
          height={iconSize}
          color={theme.palette.text.primary}
        />
      );
      break;
    case "AIX":
      osIcon = (
        <AIXIcon width="20" height="20" color={theme.palette.text.primary} />
      );
      break;
    case "solaris":
    case "SunOS":
      osIcon = (
        <SolarisIcon
          width="20"
          height="20"
          color={theme.palette.text.primary}
        />
      );
      break;
    default:
      osIcon = null;
  }

  const selectedBorder = `1px solid ${theme.palette.primary.main}`;

  if (!asset?.assetId || !asset?.osName) {
    return null;
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      {osIcon ? (
        <Tooltip
          title={window.getCTTranslatedText(
            capitalizeFirstLetter(asset?.osName) ?? ""
          )}
        >
          <IconButton
            size="small"
            sx={{
              border: isSelected ? selectedBorder : undefined,
            }}
            disableRipple={asset?.osName === "" ? true : false}
            disabled={viewOnly === true}
            color={isSelected ? "primary" : "inherit"}
            onClick={() => {
              if (asset?.osName !== "") {
                updateFacetOption({
                  facetName: "osname",
                  optionName: asset.osName,
                  value: !isSelected,
                });
              }
            }}
          >
            {osIcon}
          </IconButton>
        </Tooltip>
      ) : null}
    </Stack>
  );
}
