import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { PendingWorkRequestActor } from "modules/PendingWorkRequestsDrawer/components/PendingWorkRequestActor";
import { FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "modules/facets/types";
import { PendingWorkRequestAction } from "../PendingWorkRequestAction";
import { PendingWorkRequestResource } from "../PendingWorkRequestResource";
import { PendingWorkRequestStatus } from "../PendingWorkRequestStatus";
import { PendingWorkRequestTime } from "../PendingWorkRequestTime";

export const BASE_PENDING_WORK_REQUESTS_COLUMNS: GridColDef[] = [
  {
    field: "action",
    headerName: "Action",
    flex: 0.8,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <PendingWorkRequestAction pendingItems={params.row} />;
    },
  },
  {
    field: "createdAt",
    headerName: "Created At",
    flex: 0.6,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <PendingWorkRequestTime pendingItems={params.row} />;
    },
  },
  {
    field: "alertstatus",
    headerName: "Status",
    sortingOrder: ["asc", "desc"],
    flex: 0.5,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <PendingWorkRequestStatus pendingItems={params?.row} />;
    },
  },
  {
    field: "actoremail",
    headerName: "Triggered By",
    sortingOrder: ["asc", "desc"],
    flex: 0.6,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <PendingWorkRequestActor pendingItems={params?.row} />;
    },
  },

  {
    field: "resource",
    headerName: "Resource",
    flex: 0.8,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <PendingWorkRequestResource pendingItems={params.row} />;
    },
  },
];

export const FACET_ORDER: FacetGroupInfo = {
  actoremail: FacetGroups.WorkRequest,
  workrequestaction: FacetGroups.WorkRequest,
};
