import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  DialogActions,
  Drawer,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { DataGridProProps, GridRowId } from "@mui/x-data-grid-pro";
import { useQueryClient } from "@tanstack/react-query";
import { Toolbar } from "common/atoms/toolbar";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { PORT_STATUS_DETAIL_COLUMNS } from "modules/add-to-templates/components/AddToTemplateDialog/constants";
import {
  PortRulesBody,
  useUpdateTemplate,
} from "modules/add-to-templates/components/AddToTemplateDialog/helpers";
import { PortRulesBodyFromPathRules } from "modules/add-to-templates/components/AddToTemplateDialog/helpers/port-rules-body";
import { Rule } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { Path, PathDirection } from "pages/paths/types";
import { Port, PortStatus } from "pages/ports/types";
import {
  shouldSuggestRestrictPortToAllowedPathsForPaths,
  shouldSuggestRestrictPortToAllowedPathsForPorts,
} from "pages/templates/components/template-data-grid/helper";
import { TemplateInboundPathsDataGrid } from "pages/templates/components/template-detail/TemplateInboundPathsDataGrid";
import { TemplateType } from "pages/templates/types";
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

export function getRowId(
  row: PathWithPreviewStatus | PortWithPreviewStatus,
  ruleType: "PORT" | "PATH"
) {
  if (ruleType === "PORT") {
    const port = row as PortWithPreviewStatus;
    return port.lpId;
  }

  const path = row as PathWithPreviewStatus;
  return `${path.channelHash}-${path.direction}`;
}

interface PathWithPreviewStatus extends Path {
  previewStatus: "No Change" | PortStatus.PathRestricted;
}

interface PortWithPreviewStatus extends Port {
  previewStatus: "No Change" | PortStatus.PathRestricted;
}

interface RestrictToAllowedPathsPreviewDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  rules: Rule[] | undefined;
  templateID: string | undefined;
  allRules: Rule[] | undefined;
  ruleType: "PORT" | "PATH";
  templateType?: TemplateType;
  title: string;
}

export const RestrictToAllowedPathsPreviewDrawer = ({
  isOpen,
  onClose,
  rules,
  templateID,
  allRules,
  ruleType,
  templateType,
  title,
}: RestrictToAllowedPathsPreviewDrawerProps) => {
  const notify = useEventSubscriptionStore(state => state.notify);
  const [selection, setSelection] = useState<Array<GridRowId>>([]);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const [rows, setRows] = useState<
    PathWithPreviewStatus[] | PortWithPreviewStatus[]
  >([]);

  const filterUniqueRows = useCallback(
    (
      rules: Rule[] | undefined
    ): PathWithPreviewStatus[] | PortWithPreviewStatus[] => {
      const uniqueSet = new Set();

      if (ruleType === "PORT") {
        const filteredRules =
          rules
            ?.filter((rule: Rule | undefined): rule is Port => Boolean(rule))
            ?.filter(rule =>
              shouldSuggestRestrictPortToAllowedPathsForPorts(
                rule,
                templateType,
                allRules as Path[]
              )
            )
            ?.filter((rule: Port) => {
              const portProtocol = `${rule.listenPort}_${rule.listenPortProtocol}`;
              if (uniqueSet.has(portProtocol)) {
                return false;
              }

              uniqueSet.add(portProtocol);
              return true;
            }) ?? [];
        return filteredRules.map((rule: Port) => {
          return {
            ...rule,
            paths: (allRules as PathWithPreviewStatus[])?.filter(
              row =>
                row.port === rule.listenPort &&
                row.protocol === rule.listenPortProtocol &&
                row.direction === PathDirection.Inbound
            ),
            previewStatus: PortStatus.PathRestricted,
          };
        });
      } else {
        const filteredRules =
          rules
            ?.filter((rule: Rule | undefined): rule is Path => Boolean(rule))
            ?.filter(rule =>
              shouldSuggestRestrictPortToAllowedPathsForPaths(
                rule,
                templateType,
                allRules as Port[]
              )
            )
            ?.filter((rule: Path) => {
              const portProtocol = `${rule.port}_${rule.protocol}`;
              if (uniqueSet.has(portProtocol)) {
                return false;
              }

              uniqueSet.add(portProtocol);
              return true;
            }) ?? [];
        return filteredRules.map((rule: Path) => {
          return {
            ...rule,
            paths: (allRules as PathWithPreviewStatus[])?.filter(
              row =>
                row.port === rule.port &&
                row.protocol === rule.protocol &&
                row.direction === rule.direction
            ),
            previewStatus: PortStatus.PathRestricted,
          };
        });
      }
    },
    [ruleType, templateType, allRules]
  );

  useEffect(() => {
    const rows = filterUniqueRows(rules);
    setSelection(rows.map(row => getRowId(row, ruleType)));
    setRows(rows);
  }, [filterUniqueRows, ruleType, rules, setSelection]);

  const updateSelections = (selectedPortIds: GridRowId[]) => {
    setSelection(selectedPortIds);
    setRows(prev => {
      if (ruleType === "PORT") {
        return prev.map(row => {
          if (selectedPortIds.indexOf(getRowId(row, ruleType)) !== -1) {
            return {
              ...row,
              previewStatus: PortStatus.PathRestricted,
            } as PortWithPreviewStatus;
          } else {
            return {
              ...row,
              previewStatus: "No Change",
            } as PortWithPreviewStatus;
          }
        });
      } else {
        return prev.map(row => {
          if (selectedPortIds.indexOf(getRowId(row, ruleType)) !== -1) {
            return {
              ...row,
              previewStatus: PortStatus.PathRestricted,
            } as PathWithPreviewStatus;
          } else {
            return {
              ...row,
              previewStatus: "No Change",
            } as PathWithPreviewStatus;
          }
        });
      }
    });
  };

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(({ row }) => {
    return (
      <Stack px={2} py={2}>
        <TemplateInboundPathsDataGrid rows={row.paths ?? []} />
      </Stack>
    );
  }, []);

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridProProps["getDetailPanelHeight"]>
  >(() => "auto" as const, []);

  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const updateAddToTemplateMutation = useUpdateTemplate(templateID);

  const queryClient = useQueryClient();

  const buildRequestBody = () => {
    let body: any = {};

    if (ruleType === "PORT") {
      body["templatePorts"] = rules
        ?.filter((rule: Rule) =>
          selection.includes(getRowId(rule as PortWithPreviewStatus, ruleType))
        )
        ?.map(rule => {
          return PortRulesBody({
            rules: [rule] as Array<Port>,
            portStatus: PortStatus.PathRestricted,
            templateType: templateType ?? TemplateType.ApplicationTemplate,
          });
        })
        .flat();
    } else {
      body["templatePorts"] = rules
        ?.filter((rule: Rule) =>
          selection.includes(getRowId(rule as PathWithPreviewStatus, ruleType))
        )
        ?.map(rule => {
          return PortRulesBodyFromPathRules({
            rules: [rule] as Array<Path>,
            portStatus: PortStatus.PathRestricted,
          });
        })
        .flat();
    }

    return body;
  };

  const confirmAction = async () => {
    const body = buildRequestBody();
    updateAddToTemplateMutation.mutate(body, {
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: ["policy"],
        });
        notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
          label: "portsUpdatedSuccessfully",
        });
        onClose();
        setSelection([]);
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  if (rows.length === 0) {
    return null;
  }

  return (
    <Box onClick={e => e.stopPropagation()}>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            padding: 3,
            width: "70%",
            overflow: "hidden",
          },
          elevation: 1,
        }}
      >
        <Stack sx={{ width: "100%", height: "100%" }} spacing={3}>
          <Toolbar />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction={"row"} alignItems="center">
              <Link
                typography={"h6"}
                underline="hover"
                color={theme => theme.palette.text.primary}
                component={RouterLink}
                to={"#"}
                onClick={onClose}
              >
                {window.getCTTranslatedText(title)}
              </Link>
              <NavigateNextIcon />
              <Typography variant="h6">
                {window.getCTTranslatedText("restrictPortsToAllowedPaths")}
              </Typography>
            </Stack>
            <IconButton
              size="small"
              aria-label="close drawer"
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>
          <Box sx={{ flex: 1, overflow: "hidden" }}>
            <DataGrid<PathWithPreviewStatus | PortWithPreviewStatus>
              columns={PORT_STATUS_DETAIL_COLUMNS}
              checkboxSelection={userPermissions.has("UPDATE_PORT")}
              rowSelectionModel={selection}
              onRowSelectionModelChange={updateSelections}
              paginationMode="client"
              sortingMode="client"
              pagination
              rows={rows}
              rowCount={rows?.length ?? 0}
              getRowId={row => getRowId(row, ruleType)}
              frontendOnly={true}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={getDetailPanelHeight}
            />
          </Box>
          <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
            {userPermissions.has("UPDATE_PATH") && (
              <ToolbarAction
                loading={updateAddToTemplateMutation?.isLoading}
                isValid={selection?.length > 0}
                actionBtnText={"Confirm"}
                save={confirmAction}
                cancel={onClose}
                hasPermission={userPermissions.has("UPDATE_PORT")}
              ></ToolbarAction>
            )}
          </DialogActions>
        </Stack>
      </Drawer>
    </Box>
  );
};
