import { CustomCommonStoreType } from "common/types/types";
import { FacetStoreBuilder } from "modules/facets";
import { FacetStore } from "modules/facets/types";
import { create } from "zustand";

export interface CustomCommonStore extends FacetStore {}

export const usePortListCommonStore: CustomCommonStoreType =
  create<CustomCommonStore>()(set => ({
    ...FacetStoreBuilder(set),
  }));
