import { CTDrawer } from "common/molecules/drawer";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { AssetAttributesBlock } from "modules/attributes-block";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useTagPolicyListAPI } from "pages/tags/components/tag-policy-list/TagPolicyList";
import { TagPolicyDataGrid } from "pages/tags/components/tag-policy-list/components/TagPolicyDataGrid";
import { useTagPolicyFacetStore } from "pages/tags/components/tag-policy-list/store";
import { useEffect, useState } from "react";

export function AssetTbp({
  criteria,
  count,
}: {
  criteria: string;
  count: number;
}) {
  const [showPolicies, setShowPolicies] = useState(false);
  const { isFeatureEnabled: isSegmentsEnabled } = useFeatureFlagControl(
    FEATURES.SEGMENTS
  );

  const { data: metaData } = useScopeMetadata({ scope: Scope.TagPolicy });
  const updateMetadata = useTagPolicyFacetStore(state => state.updateMetadata);
  useEffect(() => {
    updateMetadata(metaData);
  }, [metaData, updateMetadata]);

  const api = useTagPolicyListAPI();
  const mutate = api.mutate;
  useEffect(() => {
    if (!showPolicies) {
      return;
    }
    mutate({
      criteria,
      pagination: {
        offset: 0,
        limit: 100,
      },
    });
  }, [criteria, showPolicies, mutate]);

  return (
    <>
      <AssetAttributesBlock
        assign={() => {}}
        count={count ?? 0}
        canAssign={false}
        isLoading={api.isLoading}
        showAssigned={() => {
          setShowPolicies(true);
        }}
        title={isSegmentsEnabled ? "Segments" : "Tag based policies"}
      />

      <CTDrawer
        open={showPolicies}
        onClose={() => {
          setShowPolicies(false);
        }}
        title={
          isSegmentsEnabled
            ? "Targeting Segments"
            : "Targeting tag based policies"
        }
      >
        <TagPolicyDataGrid
          rowCount={api.data?.metadata?.total ?? 0}
          rows={api.data?.items}
          hiddenColumns={HIDDEN_COLS}
        />
      </CTDrawer>
    </>
  );
}

const HIDDEN_COLS = ["matchingAssets"];
