import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellCheckboxRenderer,
  GridColDef,
  GridRenderCellParams,
  GRID_TREE_DATA_GROUPING_FIELD,
} from "@mui/x-data-grid-pro";
import { AgentLanIp } from "../agent-lanip";
import { AgentLocation } from "../agent-location";
import { AgentMode } from "../agent-mode";
import { AgentStatus } from "../agent-status";
import { AgentVersion } from "../agent-version";
import { AgentWanIp } from "../agent-wanip";
import { DeviceCount } from "../device-count";
import { UnmanagedDeviceCount } from "../unmanaged-device-count";
import { AgentLogs } from "modules/agent-logs";
import { HaRole } from "pages/appliances/types";
import { ApplianceActionsMenu } from "../appliance-actions-menu";
import { ApplianceName } from "../appliance-name";

export const applianceDiagnosticSupportedMinVersion = "3.0.625";
export const applianceDiagnosticSupportedDevVersion = "1.0.1";

export const BASE_APPLIANCE_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: params => {
      return <GridCellCheckboxRenderer {...params} />;
    },
    type: "singleSelect",
  },
  {
    field: "agentName",
    headerName: "applianceName",
    minWidth: 140,
    width: 160,
    flex: 0.8,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <ApplianceName agent={params.row} />;
    },
  },
  {
    field: "agentStatus",
    headerName: "applianceStatus",
    width: 160,
    minWidth: 160,
    flex: 0.4,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentStatus agent={params.row} />;
    },
  },
  {
    field: "currentVersion",
    headerName: "applianceVersion",
    width: 160,
    minWidth: 160,
    flex: 0.25,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentVersion agent={params.row} />;
    },
  },
  {
    field: "agentLocation",
    headerName: "Location",
    width: 160,
    minWidth: 100,
    flex: 0.4,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentLocation agent={params.row} />;
    },
  },
  {
    field: "managedDeviceCount",
    headerName: "managedDevices",
    width: 120,
    minWidth: 100,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        params?.row?.haRole !== HaRole.Standby && (
          <DeviceCount agent={params.row} />
        )
      );
    },
  },
  {
    field: "unmanagedDeviceCount",
    headerName: "UnmanagedDevices",
    width: 120,
    minWidth: 100,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        params?.row?.haRole !== HaRole.Standby && (
          <UnmanagedDeviceCount agent={params.row} />
        )
      );
    },
  },
  {
    field: "mode",
    headerName: "DHCP Mode",
    width: 120,
    minWidth: 120,
    flex: 0.3,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentMode agent={params.row} viewOnly={true} />;
    },
  },
  {
    field: "wanIp",
    headerName: "WAN IP",
    width: 140,
    minWidth: 140,
    flex: 0.3,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentWanIp agent={params.row} viewOnly={true} />;
    },
  },
  {
    field: "lanIp",
    headerName: "LAN/VLAN IP",
    width: 140,
    minWidth: 140,
    flex: 0.3,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentLanIp agent={params.row} viewOnly={true} />;
    },
  },
];

export const LOG_COLUMN: GridColDef[] = [
  {
    field: "logs",
    headerName: "Logs",
    width: 90,
    minWidth: 90,
    flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
    headerAlign: "left",
    headerClassName: "hideRightSeparator",
    align: "center",
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentLogs agent={params.row} />;
    },
  },
];

export const ACTION_COLUMN: GridColDef[] = [
  {
    field: "action",
    headerName: "",
    sortable: false,
    width: 50,
    minWidth: 50,
    headerClassName: "hideRightSeparator",
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <ApplianceActionsMenu agent={params.row} />;
    },
  },
];

export const GROUP_COLUMN: GridColDef[] = [
  {
    field: GRID_TREE_DATA_GROUPING_FIELD,
    minWidth: 30,
    width: 30,
  },
];

const ColumnVisibilityModel: { [key: string]: boolean } = {
  // Hide columns from the display
};

const PinnedColumns = {
  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "agentName"],
  right: ["logs", "diagnostics", "action", GRID_TREE_DATA_GROUPING_FIELD],
};

export const ApplianceColumnConfig = {
  ColumnVisibilityModel: ColumnVisibilityModel,
  PinnedColumns: PinnedColumns,
};
