import { Box, Button, Paper, Stack, useTheme } from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid-pro";
import { useMutation } from "@tanstack/react-query";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { SortOrder } from "modules/core/types";
import { ColConfig } from "modules/data-grid/components/data-grid/types";
import { useSearchStore } from "modules/search/store";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FacetOpenCloseController } from "../../common/atoms/facet-open-close-controller";
import { useCommonStore } from "../../common/store";
import { useCore } from "../../modules/core";
import { FacetControllerGroup } from "../../modules/facets";
import { Scope } from "../../modules/scope-metadata/types";
import { TemplateDataGrid } from "./components/template-data-grid";
import { TemplateFormDrawer } from "./components/template-form-drawer";
import { TemplateFormMode } from "./components/template-form-drawer/components/template-form/types";
import { TemplateViewDrawer } from "./components/template-view-drawer";
import { FACET_ORDER } from "./constants";
import { useTemplateStore } from "./store";
import { Template } from "./types";

const useTemplatesAPI = () => {
  return useMutation<any, Error, any>([
    "policy",
    "templates/actions/search?computeTotal=true",
  ]);
};

export const Templates = () => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const theme = useTheme();

  const [selectedTemplateId, setSelectedTemplateId] = useState<
    string | undefined
  >(undefined);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const navigate = useNavigate();

  const coreResponse = useCore<Template>({
    useStore: useTemplateStore,
    facetGroupInfo: FACET_ORDER,
    scope: Scope.Template,
    dataMapper: template => template,
    defaultSortOrder: [
      { field: "oobTemplate", order: "asc" },
      { field: "templateName", order: "asc" },
    ],
    useApi: useTemplatesAPI,
    pageSize: 100,
  });
  const metadata = useCommonStore(state => state.metadata);
  const requestAPIRefresh = useTemplateStore(state => state.requestAPIRefresh);
  const setSearch = useSearchStore(state => state.setSearch);
  const [createDrawerVisibility, setCreateDrawerVisibility] = useState(false);
  const individualCellClickCols: ColConfig = {
    templateType: true,
    oobTemplate: true,
    templateAssignments: true,
    ugTemplateAssignments: true,
  };

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const templateId = searchParams.get("templateId");

    if (templateId) {
      setSelectedTemplateId(templateId);
      setDrawerVisibility(true);
    }
  }, [location.search]);

  useEffect(() => {
    setSearch("");
    return () => {
      setSearch("");
    };
  }, [setSearch]);

  const onCloseDrawer = () => {
    navigate(`/templates`);
    setSelectedTemplateId(undefined);
    setDrawerVisibility(false);
  };

  const onCloseCreateDrawer = () => {
    navigate(`/templates`);
    setCreateDrawerVisibility(false);
  };

  const updateTemplateData = () => {
    requestAPIRefresh();
  };

  const onSortChange = (sortOrder: SortOrder[]) => {
    const newSortOrder = sortOrder.map(sort => {
      if (sort.field === "isoobtemplate") {
        return {
          field: "oobtemplate",
          order: sort.order,
        };
      }

      if (sort.field === "templateTagBasedPolicyAssignments") {
        return {
          field: "templatepolicyassignments",
          order: sort.order,
        };
      }

      return sort;
    });

    coreResponse.onSortChange(newSortOrder);
  };

  const handleOnCellClick: GridEventListener<"cellClick"> = (
    params // GridRowParams
  ) => {
    if (
      !individualCellClickCols[params?.field] &&
      params?.row?.templateId &&
      params?.field !== "templateTagBasedPolicyAssignments"
    ) {
      navigate(`/templates?templateId=${params?.row?.templateId}`);
      setSelectedTemplateId(params?.row?.templateId);
      setDrawerVisibility(true);
    }
  };

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction={"row"} alignItems="center">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              config={coreResponse?.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
        <Stack>
          {userPermissions.has("CREATE_TEMPLATE") && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCreateDrawerVisibility(true)}
              sx={{
                ml: 2,
              }}
            >
              {window.getCTTranslatedText("Create template")}
            </Button>
          )}
        </Stack>
      </Stack>
      <Box
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowY: "auto",
          flex: 1,
        }}
      >
        <Paper
          sx={{
            height: "100%",
          }}
        >
          <TemplateDataGrid
            initialState={{
              sorting: {
                sortModel: [
                  { field: "isoobtemplate", sort: "asc" },
                  { field: "templateName", sort: "asc" },
                ],
              },
            }}
            rows={coreResponse.rows}
            rowCount={coreResponse.rowCount}
            mutation={coreResponse.mutation}
            onPageChange={coreResponse.onPageChange}
            page={coreResponse.page}
            pageSize={coreResponse.pageSize}
            onPageSizeChange={coreResponse.onPageSizeChange}
            onSortChange={onSortChange}
            metadata={metadata}
            onCellClick={handleOnCellClick}
            triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
            getExportStatus={coreResponse?.getExportStatus}
            getUrlToDownload={coreResponse?.getUrlToDownload}
            resetDownloadUrl={coreResponse?.resetDownloadUrl}
            rawData={coreResponse?.rawData}
          />
        </Paper>
        {createDrawerVisibility && (
          <TemplateFormDrawer
            isOpen={createDrawerVisibility}
            onClose={onCloseCreateDrawer}
            updateTemplateData={updateTemplateData}
            title={"Create New Template"}
            btnTitle={"Create"}
            mode={TemplateFormMode.CREATE}
          />
        )}
        {drawerVisibility && (
          <TemplateViewDrawer
            isOpen={drawerVisibility}
            onClose={onCloseDrawer}
            templateId={selectedTemplateId}
          />
        )}
      </Box>
    </Stack>
  );
};
