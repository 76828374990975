import { CTConfirmation } from "common/atoms/ct-confirmation";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useDecommissionApplianceAPI } from "pages/appliance-detail/hooks/useDecommissionApplianceAPI";
import { ApplianceDecommissionProps } from "../types";
import { useApplianceStore } from "pages/appliances/store";

export const ApplianceDecommission = ({
  agent,
  showDecommission,
  setShowDecommission,
}: ApplianceDecommissionProps) => {
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const requestAPIRefresh = useApplianceStore(state => state.requestAPIRefresh);
  const applianceDecommissionMutation = useDecommissionApplianceAPI(
    agent?.agentId
  );

  const decommissionAppliance = () => {
    applianceDecommissionMutation.mutateAsync(
      {},
      {
        onSuccess(response) {
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            window.getCTTranslatedText("decommissionApplianceSuccess")
          );
          setShowDecommission(false);
          requestAPIRefresh();
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      }
    );
  };
  return (
    <CTConfirmation
      open={showDecommission}
      onClose={() => setShowDecommission(false)}
      title={window.getCTTranslatedText("decommissionAppliance")}
      primaryText={window.getCTTranslatedText(
        "applianceDecommissionConfirmation"
      )}
      secondaryText={window.getCTTranslatedText("applianceDecommissionNote")}
      primaryButtonText={window.getCTTranslatedText("decommissionButton")}
      isLoading={applianceDecommissionMutation.isLoading}
      onSuccess={decommissionAppliance}
    />
  );
};
