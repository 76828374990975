import { Stack, Tooltip } from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";

import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import {
  ProgressiveEnforcementLevelLabels,
  ProgressiveEnforcementStatus,
  ProgressiveEnforcementStatusMap,
} from "pages/ports/types";
import { PolicyAutomationDrawer } from "pages/tags/components/tag-policy-list/components/policy-automation-drawer";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";
import { useState } from "react";

interface TagPolicyProgressiveStatusProps {
  policy: TagPolicy;
}

export const TagPolicyProgressiveStatus = ({
  policy,
}: TagPolicyProgressiveStatusProps) => {
  const [editDrawerVisibility, setEditDrawerVisibility] = useState(false);
  const requestAPIRefresh = useTagPolicyStore(state => state.requestAPIRefresh);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const openEditDrawer = () => {
    setEditDrawerVisibility(true);
  };

  const onCloseEditDrawer = (refresh: boolean) => {
    setEditDrawerVisibility(false);
    refresh && requestAPIRefresh();
  };

  const hasPermission = userPermissions.has("UPDATE_POLICY_AUTOMATION");

  const displayText =
    ProgressiveEnforcementLevelLabels[
      ProgressiveEnforcementStatusMap[
        (policy?.lowestProgressiveInboundPolicyStatus ??
          ProgressiveEnforcementStatus.ZeroTrust) as ProgressiveEnforcementStatus
      ]
    ];

  return (
    <>
      <Tooltip
        title={
          hasPermission
            ? window.getCTTranslatedText("configurePolicyAutomation")
            : ""
        }
      >
        <Stack>
          <DataGridButton
            disabled={!hasPermission}
            size="medium"
            variant="text"
            color="inherit"
            onClick={() => openEditDrawer()}
          >
            {window.getCTTranslatedText(displayText)}
          </DataGridButton>
        </Stack>
      </Tooltip>
      {editDrawerVisibility && (
        <PolicyAutomationDrawer
          isOpen={editDrawerVisibility}
          onClose={onCloseEditDrawer}
          id={policy.tagBasedPolicyId}
          criteria={policy?.criteria}
          isZeroTrustAutomationEditable={policy?.policyAutomationConfigurable}
        />
      )}
    </>
  );
};
