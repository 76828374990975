import {
  Box,
  Button,
  DialogActions,
  FormControl,
  Paper,
  Stack,
} from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import {
  CreateTagFieldOptions,
  CreateTagFieldDisplayText,
  RequiredCreateTagFields,
} from "../create-tag/constants";
import { useState } from "react";

import { CreateTagFieldKeys, TagItemProps } from "pages/tags/types";
import { useUpdateTag } from "./hooks";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";

export enum Modes {
  Create = 0,
  Edit = 1,
}
interface CreateTagProps extends TagItemProps {
  mode?: Modes;
  cancelCallback?: () => void;
}

export const CreateTag = ({
  tag,
  mode = 0,
  cancelCallback,
}: CreateTagProps) => {
  const [showCreateForm, setShowCreateForm] = useState(
    mode === Modes.Edit ? true : false
  );
  const [name, setName] = useState(tag?.displayName ?? "");
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const {
    saveTag,
    handleUpdate,
    keyListener,
    handleClick,
    handleClose,
    isValidTag,
  } = useUpdateTag({
    setShowCreateForm,
    name,
    setName,
    mode,
    tag,
    cancelCallback,
  });

  return (
    <Stack alignItems="flex-start">
      {!showCreateForm && mode === Modes.Create ? (
        userPermissions.has("CREATE_FIELD") && (
          <Button
            variant="outlined"
            onClick={handleClick}
            sx={{ width: "100%" }}
          >
            {window.getCTTranslatedText("Create Custom Tag")}
          </Button>
        )
      ) : (
        <Stack sx={{ width: "100%" }}>
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              flexShrink: 0,
              whiteSpace: "nowrap",
              boxSizing: "border-box",
              mb: 2,
            }}
          >
            <Box
              sx={{
                px: 3,
                pt: 2,
                cursor: "pointer",
                width: "100%",
              }}
            >
              <FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
                {CreateTagFieldOptions.map(
                  (key: CreateTagFieldKeys, index: number) => {
                    return (
                      <Box key={`${key}`}>
                        <CTInputField
                          field={key}
                          displayName={CreateTagFieldDisplayText[key]}
                          value={key === CreateTagFieldKeys.Name ? name : ""}
                          handleUpdate={handleUpdate}
                          required={RequiredCreateTagFields[key]}
                          autoFocus={index === 0}
                          onKeyUp={keyListener}
                        />
                      </Box>
                    );
                  }
                )}
              </FormControl>
            </Box>
            <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
              <ToolbarAction
                loading={false}
                save={saveTag}
                cancel={handleClose}
                actionBtnText={mode === Modes.Edit ? "Save" : "Create"}
                background={false}
                size={"small"}
                isValid={isValidTag()}
              />
            </DialogActions>
          </Paper>
        </Stack>
      )}
    </Stack>
  );
};
