import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import numeral from "numeral";
import { NetworkColumnRenderProps } from "pages/networks/types";
import pluralize from "pluralize";

export function GroupCount({
  network,
  viewOnly,
}: Readonly<NetworkColumnRenderProps>) {
  if (!network.namedNetworkId) {
    return <Box style={{ minWidth: 120, minHeight: 45 }} />;
  }

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
      <Stack alignItems="flex-start">
        {viewOnly || network?.usergroupNamedNetworkAssignments === 0 ? (
          <Typography variant="body2">
            {numeral(network?.usergroupNamedNetworkAssignments || 0).format(
              "0a"
            )}{" "}
            {window
              .getCTTranslatedText(
                pluralize("Group", network?.usergroupNamedNetworkAssignments)
              )
              .toLowerCase()}
          </Typography>
        ) : (
          <Tooltip title={window.getCTTranslatedText("View Group")}>
            <Link
              typography={"body2"}
              component={RouterLink}
              to={`/users?tab=groups&filters=${encodeURIComponent(
                `namednetworkname=${network.namedNetworkName}`
              )}`}
              sx={{ color: "primary.main" }}
            >
              {numeral(network?.usergroupNamedNetworkAssignments || 0).format(
                "0a"
              )}{" "}
              {window.getCTTranslatedText(
                pluralize("group", network?.usergroupNamedNetworkAssignments)
              )}
            </Link>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
