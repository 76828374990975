import { Paper, Stack, useTheme } from "@mui/material";
import { FACET_GROUP_INFO } from "./constants";
import { useCore } from "modules/core";
import { Scope } from "modules/scope-metadata/types";
import { useContainerStore } from "./store";
import { Container } from "./types";
import { useContainersAPI } from "./components/containers-data-grid/hooks";
import { ContainerDataGrid } from "./components/containers-data-grid";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { FacetControllerGroup } from "modules/facets";
import { mapContainer } from "./components/containers-data-grid/helpers/containerDataMapper";

export const Containers = () => {
  const theme = useTheme();
  const coreResponse = useCore<Container>({
    useStore: useContainerStore,
    facetGroupInfo: FACET_GROUP_INFO,
    scope: Scope.Cluster,
    defaultSortOrder: [{ field: "agentstatus", order: "desc" }],
    useApi: useContainersAPI,
    dataMapper: container => {
      mapContainer(container);
      return container;
    },
    pageSize: 100,
    isNewCsvAPIVersion: true,
  });

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction="row">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack>
      <Paper
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          flex: 1,
          overflow: "hidden",
        }}
      >
        <ContainerDataGrid
          rows={coreResponse.rows}
          rowCount={coreResponse.rowCount}
          mutation={coreResponse.mutation}
          metadata={coreResponse.metadata}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={coreResponse.onSortChange}
          triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
          getExportStatus={coreResponse?.getExportStatus}
          getUrlToDownload={coreResponse?.getUrlToDownload}
          resetDownloadUrl={coreResponse?.resetDownloadUrl}
          rawData={coreResponse?.rawData}
        />
      </Paper>
    </Stack>
  );
};
