import { GridColDef } from "@mui/x-data-grid-pro";
import { CopyInviteButton } from "./components/copy-invite-button";
import { InviteActions } from "./components/invite-actions";

export const QUERY_ACTIONS: { [key: string]: string } = {
  INVITE: "invite",
};

export const MEMBERS_COLUMNS: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    sortingOrder: ["asc", "desc"],
  },
  {
    field: "email",
    headerName: "Email",
    headerAlign: "left",
    flex: 1,
    align: "left",
    sortingOrder: ["asc", "desc"],
  },
];

export const INVITE_COLUMNS: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    sortingOrder: ["asc", "desc"],
  },
  {
    field: "email",
    headerName: "Email",
    headerAlign: "left",
    flex: 1,
    align: "left",
    sortingOrder: ["asc", "desc"],
  },
  {
    field: "inviteLink",
    headerName: "Invite code",
    headerAlign: "left",
    align: "left",
    flex: 1,
    sortable: false,
    renderCell: params => {
      return (
        <CopyInviteButton
          code={params.row.inviteLink}
          buttonProps={{
            variant: "text",
          }}
        />
      );
    },
  },
  {
    field: "action",
    headerName: "Action",
    headerAlign: "left",
    align: "left",
    flex: 1,
    sortable: false,
    renderCell: params => {
      return <InviteActions invite={params.row} />;
    },
  },
];
