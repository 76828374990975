import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { Template } from "pages/templates/types";
import { useMemo } from "react";
import {
  TEMPLATES_COLUMNS,
  ENDPOINT_TEMPLATE_COLUMNS,
  TemplateColumnConfig,
} from "./constants";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";

export const TemplateDataGrid = (props: DataGridProps<Template>) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const { isFeatureEnabled: isUserGroupsColumnEnabled } = useFeatureFlagControl(
    FEATURES.USER_GROUP_COLUMN
  );

  const templateColumns = isUserGroupsColumnEnabled
    ? ENDPOINT_TEMPLATE_COLUMNS
    : TEMPLATES_COLUMNS;
  const columns = useMemo(() => {
    let selectedColumns = userPermissions.has("DELETE_NAMED_NETWORK")
      ? templateColumns
      : templateColumns.filter(col => col.field !== "action");

    return selectedColumns;
  }, [userPermissions, templateColumns]);

  return (
    <DataGrid
      defaultPinnedColumns={TemplateColumnConfig.PinnedColumns}
      rowHeight={64}
      columns={columns}
      pagination
      getRowId={({ templateId }: Template) => templateId}
      paginationMode="server"
      sortingMode="server"
      {...props}
    />
  );
};
