import { Stack } from "@mui/material";
import {
  GridToolbar,
  ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { AgentDownload } from "pages/agents/components/agent-downlaod";
import { agent_types } from "pages/agents/components/agent-download-page/constants";
import { ROUTES } from "routes";

export interface ClusterToolbarProps extends Omit<ToolbarProps, "children"> {
  hideToolbar: Function;
}

export function ContainersToolbar(props: ClusterToolbarProps) {
  const route = ROUTES.INSTALL_COLLECTOR.pathname;
  const agentType = agent_types.CT_CONTAINER_AGENT;
  return (
    <GridToolbar {...props}>
      <Stack
        direction="row"
        spacing={3}
        sx={{ width: "100%" }}
        justifyItems="flex-end"
        alignItems={"flex-end"}
        justifyContent="flex-end"
      >
        <AgentDownload agentType={agentType} route={route} />
      </Stack>
    </GridToolbar>
  );
}
