import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  Link,
  Paper,
  Stack,
  Typography,
  debounce,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { NETWORK_LIST_COLUMNS } from "pages/networks/components/network-data-grid/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { SearchBar } from "modules/add-to-templates/components/search-bar";
import { Annotation } from "modules/annotation";
import { SortOrder } from "modules/core/types";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAssetStore } from "pages/assets/store/useAssetStore";
import { NetworkDetail } from "pages/networks/components/network-detail/NetworkDetail";
import { NetworkFormDrawer } from "pages/networks/components/network-form-drawer";
import { useNetworkStore } from "pages/networks/store";
import { AssignNetworkListProps, Network } from "pages/networks/types";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";
import { ListTable } from "../list-table";
import {
  useAssignNetworkToAssets,
  useAssignNetworkToTags,
} from "./helpers/use-assign-network";
import { useNetworksSuggestions } from "./helpers/use-networks-suggestions";
import { AssignNetworksDialogProps } from "./types";

export default function AssignNetworksDialog({
  page,
  rules,
  onConfirm,
  onCancel,
  title,
  btnTitle,
  criteria,
  multiselect = false,
  onSelectCallback,
  policyId,
  hasPermission,
}: AssignNetworksDialogProps) {
  const notify = useEventSubscriptionStore(state => state.notify);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const networksSuggestMutation = useNetworksSuggestions();
  const [networkList, setNetworkList] = useState<Array<Network>>([]);
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [createDrawerVisibility, setCreateDrawerVisibility] = useState(false);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const total = useMemo(
    () => networksSuggestMutation.data?.metadata?.total ?? 0,
    [networksSuggestMutation.data?.metadata.total]
  );

  const [selectedNetwork, setSelectedNetwork] = useState<
    Network | AssignNetworkListProps | undefined
  >(undefined);

  const [selectedNetworkList, setSelectedNetworkList] = useState<
    Array<Network>
  >([]);

  const [shouldShowAddIPsDrawer, setShouldShowAddIPsDrawer] = useState(false);

  const apiRefreshRequest = useNetworkStore(state => state.apiRefreshRequest);
  const requestAPIRefresh = useNetworkStore(state => state.requestAPIRefresh);
  const requestTagPolicyAPIRefresh = useTagPolicyStore(
    state => state.requestAPIRefresh
  );

  const requestAssetAPIRefresh = useAssetStore(
    state => state.requestAPIRefresh
  );

  const defaultSortOrder: Array<SortOrder> = [
    { field: "isOOBNetwork", order: "asc" },
    { field: "namedNetworkName", order: "asc" },
  ];
  const [sort, setSort] = useState<Array<SortOrder>>(defaultSortOrder || []);

  const onSortChange = useCallback((sort: Array<SortOrder>) => {
    setSort(sort);
  }, []);

  const [searchText, setSearchText] = useState("");
  const [showList, setShowList] = useState(true);

  const queryClient = useQueryClient();

  const assignNetworkToAssetsMutation = useAssignNetworkToAssets();

  const assignNetworkToTagsMutation = useAssignNetworkToTags({
    policyId: policyId,
  });

  const onCloseCreateDrawer = () => {
    setCreateDrawerVisibility(false);
  };

  const updateNetworkData = () => {
    requestAPIRefresh();
  };

  const mutate = useMemo(
    () => debounce(networksSuggestMutation.mutate, 300),
    [networksSuggestMutation.mutate]
  );

  const assignNetworkToAssets = async () => {
    interface NetworksToAssets {
      namedNetworks?: Array<string>;
      criteria: string;
      comment?: string;
      namedNetworkid?: string;
    }

    let body: NetworksToAssets = {
      criteria: criteria ?? "",
      comment: comment,
    };

    const selectedNetworkIds = (selectedNetworkList ?? []).map(
      (network: Network) => network.namedNetworkId
    );

    body["namedNetworks"] = [...selectedNetworkIds];

    if (multiselect) {
      body["namedNetworks"] = [...selectedNetworkIds];
    } else {
      body["namedNetworkid"] = selectedNetwork?.namedNetworkId;
    }

    await assignNetworkToAssetsMutation.mutateAsync(body, {
      onSuccess: response => {
        onConfirm();
        setComment(undefined);
        notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
          label: "NamedNetworkAssignedSubmitted",
        });
        queryClient.invalidateQueries({
          queryKey: ["asset"],
        });
        requestAssetAPIRefresh();
        requestAPIRefresh();
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const assignNetworkToTags = async () => {
    interface NetworksToTags {
      namednetworks: Array<string>;
      comment?: string;
    }

    let body: NetworksToTags = {
      namednetworks: [],
      comment: comment,
    };

    const selectedNetworkIds = (selectedNetworkList ?? []).map(
      (network: Network) => network.namedNetworkId
    );

    body["namednetworks"] = [...selectedNetworkIds];

    await assignNetworkToTagsMutation.mutateAsync(body, {
      onSuccess: response => {
        onConfirm();
        notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
          label: "NamedNetworkAssignedSubmitted",
        });
        queryClient.invalidateQueries({
          queryKey: ["tagbasedpolicy"],
        });
        requestTagPolicyAPIRefresh();
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const onClose = () => {
    setSelectedNetwork(undefined);
    onCancel();
  };

  const confirmChanges = () => {
    if (multiselect) {
      if (onSelectCallback) {
        onSelectCallback(selectedNetworkList);
        onConfirm();
      } else {
        // process bulk assignment
        if (page === "tags") {
          assignNetworkToTags();
        } else if (page === "assets") {
          assignNetworkToAssets();
        }
      }
    } else {
      if (selectedNetwork?.namedNetworkId) {
        assignNetworkToAssets();
      } else {
        setSelectedNetwork(undefined);
      }
    }
  };

  useEffect(() => {
    const hasSearch = searchText?.trim()?.length > 0;
    const criteria = searchText ? `'${searchText}'` : "*";

    const body = {
      criteria: criteria,
      pagination: {
        offset: hasSearch ? 0 : pageCount * pageSize,
        limit: pageSize,
        sort: sort,
      },
    };

    mutate(body, {
      onSuccess: response => {
        setNetworkList(response?.items || ([] as Array<Network>));
      },
    });
  }, [mutate, apiRefreshRequest, searchText, page, sort, pageCount, pageSize]);

  const onSearchTextChange = (searchText: string) => {
    setSearchText(searchText || "");
  };

  const onSearchFocus = () => {
    setShowList(true);
  };

  const handleRowClickEvent = (row: any) => {
    const network: Network = row as Network;
    if (row?.namedNetworkId) {
      setSelectedNetwork(network);
      setShowList(false);
      if (page === "paths") {
        setShouldShowAddIPsDrawer(true);
      }
    }
  };

  const onUpdateNNDrawerClose = () => {
    setShowList(true);
    setSelectedNetwork(undefined);
    setShouldShowAddIPsDrawer(false);
    onCancel();
  };

  const onBack = () => {
    setSelectedNetwork(undefined);
    setShowList(true);
  };

  const onTempSelectCallback = useCallback((networks: Array<Network>) => {
    setSelectedNetworkList(networks);
  }, []);

  const isValid = () => {
    if (multiselect) {
      return Boolean(selectedNetworkList?.length > 0);
    } else {
      return Boolean(selectedNetwork?.namedNetworkId);
    }
  };

  return (
    <>
      <DialogContent>
        <Stack alignItems={"flex-start"} sx={{ width: "100%", height: "100%" }}>
          <Stack sx={{ width: "100%", height: "100%" }}>
            <Stack
              direction="row"
              sx={{ width: "100%" }}
              alignItems={"center"}
              mb={2}
              spacing={1}
            >
              {showList ? (
                <Typography variant="h6">
                  {window.getCTTranslatedText(title)}
                </Typography>
              ) : (
                <Link
                  typography={"h6"}
                  underline="hover"
                  color={theme => theme.palette.text.primary}
                  component={RouterLink}
                  to={"#"}
                  onClick={onBack}
                >
                  {window.getCTTranslatedText(title)}
                </Link>
              )}
              {!showList && (
                <>
                  <NavigateNextIcon />
                  <Typography variant="h6">
                    {window.getCTTranslatedText("Selected Named Network")}
                  </Typography>
                </>
              )}
            </Stack>
            <Alert severity="warning" sx={{ mb: 2 }}>
              {window.getCTTranslatedText("NamedNetworkAssignWarning")}
            </Alert>
            <Stack
              sx={{ width: "100%", flex: 1, overflow: "hidden" }}
              spacing={2}
            >
              {showList && (
                <Stack direction="row" sx={{ width: "100%" }} spacing={3}>
                  <SearchBar
                    id="network-list-searchbar"
                    label="Search Named Networks"
                    placeholder="Search Named Networks"
                    selectedValue={selectedNetwork?.namedNetworkName}
                    onChange={onSearchTextChange}
                    onFocus={onSearchFocus}
                  />
                  {userPermissions.has("CREATE_NAMED_NETWORK") && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setCreateDrawerVisibility(true)}
                      sx={{
                        width: "300px",
                      }}
                    >
                      {window.getCTTranslatedText("Create named network")}
                    </Button>
                  )}
                  {createDrawerVisibility && (
                    <NetworkFormDrawer
                      isOpen={createDrawerVisibility}
                      onClose={onCloseCreateDrawer}
                      updateNetworkData={updateNetworkData}
                      title="Create New Network"
                      mode={"create"}
                      btnTitle={"create"}
                    />
                  )}
                </Stack>
              )}
              {showList &&
                (networkList?.length > 0 ||
                  (networkList?.length === 0 &&
                    searchText?.trim().length === 0)) && (
                  <Stack sx={{ flex: 1, overflow: "hidden" }}>
                    <Stack
                      alignItems="flex-start"
                      sx={{ flex: 1, overflow: "hidden" }}
                    >
                      <ListTable
                        list={networkList}
                        columns={NETWORK_LIST_COLUMNS}
                        mutation={networksSuggestMutation}
                        rowClickCallback={handleRowClickEvent}
                        multiselect={multiselect}
                        onSelectCallback={onTempSelectCallback}
                        onSortChange={onSortChange}
                        hasPermission={hasPermission}
                        setPage={setPageCount}
                        setPageSize={setPageSize}
                        pageSize={pageSize}
                        page={pageCount}
                        totalCount={total}
                      />
                    </Stack>
                    {selectedNetworkList.length > 0 && (
                      <Annotation setComment={setComment} comment={comment} />
                    )}
                  </Stack>
                )}
              {networkList?.length === 0 && searchText?.trim().length > 0 && (
                <Paper
                  sx={{
                    flex: 1,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Stack
                    alignContent={"center"}
                    justifyContent="center"
                    justifyItems={"center"}
                    sx={{ py: 5, px: 3 }}
                  >
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      {window.getCTTranslatedText(
                        "No Named Networks available"
                      )}
                    </Typography>
                  </Stack>
                </Paper>
              )}
              {!showList &&
                selectedNetwork?.namedNetworkId &&
                !shouldShowAddIPsDrawer && (
                  <>
                    <NetworkDetail
                      namedNetworkId={selectedNetwork?.namedNetworkId}
                      viewOnly={true}
                    />
                    <Annotation setComment={setComment} comment={comment} />
                  </>
                )}
              {userPermissions.has("UPDATE_NAMED_NETWORK") && (
                <NetworkFormDrawer
                  network={selectedNetwork}
                  cidrList={rules as string[]}
                  isOpen={shouldShowAddIPsDrawer}
                  onClose={onUpdateNNDrawerClose}
                  updateNetworkData={requestAPIRefresh}
                  title={"Add IP ranges to Named Network"}
                  btnTitle={"add"}
                  mode={"add"}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <ToolbarAction
          loading={assignNetworkToAssetsMutation?.isLoading}
          save={confirmChanges}
          cancel={showList ? onClose : onBack}
          isValid={isValid()}
          actionBtnText={btnTitle}
          secondaryBtnText={selectedNetwork?.namedNetworkId ? "back" : "cancel"}
        />
      </DialogActions>
    </>
  );
}
