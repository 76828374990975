import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Alert,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { Annotation } from "modules/annotation";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { PORTLESS_PROTOCOLS } from "pages/paths/types";
import { useTemplateStore } from "pages/templates/store";
import { TemplateFormProps, TemplateType } from "pages/templates/types";
import { useEffect, useState } from "react";
import { AddInboundOrOutboundPaths } from "./components/add-inbound-or-outbound-paths";
import { AssignPort } from "./components/assign-ports";
import { RenderTemplateForm } from "./components/render-template-form";
import { getPathRules, getPortRules, resetPortRuleStatus } from "./helpers";
import { getAllRules, ruleValidator } from "./helpers/helpers";
import {
  useAddRulesAPI,
  useCreateTemplatesAPI,
  useEditTemplatesAPI,
} from "./hooks";
import {
  PathFormInt,
  PortFormInt,
  TemplateCreateFormProps,
  TemplateFormData,
  TemplateFormMode,
} from "./types";

export function TemplateForm({
  template,
  title,
  updateTemplateData,
  onClose,
  mode,
  btnTitle = TemplateFormMode.CREATE,
  backButtonEnable,
}: TemplateCreateFormProps) {
  const notify = useEventSubscriptionStore(state => state.notify);
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [formData, setFormData] = useState<TemplateFormData>({
    templateName: template?.templateName ?? "",
    templateDescription: template?.templateDescription ?? "",
    templateCategory: template?.templateCategory ?? undefined,
    templateType: template?.templateType ?? TemplateType.ApplicationTemplate,
  });

  const [portList, setPortList] = useState<Array<PortFormInt>>([]);
  const [inboundPathList, setInboundPathList] = useState<Array<PathFormInt>>(
    [],
  );
  const [outboundPathList, setOutboundPathList] = useState<Array<PathFormInt>>(
    [],
  );

  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const requestAPIRefresh = useTemplateStore(state => state.requestAPIRefresh);

  const templateId = template?.templateId;

  const createTemplatesAPI = useCreateTemplatesAPI();
  const editTemplatesAPI = useEditTemplatesAPI(templateId);
  const addRulesAPI = useAddRulesAPI(templateId);

  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      mode === TemplateFormMode.ADD &&
      formData.templateType === TemplateType.BlockTemplate &&
      initialLoad &&
      portList &&
      portList?.length > 0
    ) {
      resetPortRuleStatus({
        templateType: formData.templateType,
        portList,
        setPortList,
      });
      setInitialLoad(false);
    }
  }, [mode, formData.templateType, portList, initialLoad]);

  const updateTemplateDetails = () => {
    if (formData.templateName?.trim().length > 0) {
      const body: TemplateFormProps = {
        templateName: formData.templateName,
        templateDescription: formData.templateDescription,
        templateType: formData.templateType,
        templateCategory: formData.templateCategory,
        comment,
      };

      if (mode === TemplateFormMode.CREATE) {
        body["templatePorts"] = getPortRules(portList);
        if (formData.templateType === TemplateType.ApplicationTemplate) {
          const pathList = [...inboundPathList, ...outboundPathList];
          body["templatePaths"] = getPathRules(pathList);
        }
        createTemplates(body);
      } else if (mode === TemplateFormMode.EDIT && template?.templateId) {
        if (mode === TemplateFormMode.EDIT) {
          delete body["templateType"];
        }
        editTemplates(body);
      } else if (mode === TemplateFormMode.ADD && template?.templateId) {
        let rulesBody: any = {
          templatePorts: getPortRules(portList),
        };
        if (formData.templateType === TemplateType.ApplicationTemplate) {
          const pathList = [...inboundPathList, ...outboundPathList];
          rulesBody["templatePaths"] = getPathRules(pathList);
        }
        addRules(rulesBody);
      }
    }
  };

  const addRules = (body: any) => {
    if (
      ruleValidator({
        templatePorts: portList,
        templatePaths: [...inboundPathList, ...outboundPathList],
        templateType: formData.templateType,
      })
    ) {
      let payload: any = body;
      payload.templatePorts = formatPorts(body);
      payload.templatePorts = getAllRules(payload.templatePorts);
      payload.templatePaths = getAllRules(payload.templatePaths);
      payload.comment = comment;
      addRulesAPI.mutate(payload, {
        onSuccess: response => {
          queryClient.invalidateQueries({
            queryKey: ["policy"],
          });
          setComment(undefined);
          requestAPIRefresh();
          notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
            label: "AddRulesToTemplateRequestSubmittedSuccessfully",
          });
          setTimeout(() => {
            onClose();
            setLoading(false);
          }, 300);
        },
        onError: error => {
          setLoading(false);
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  };
  const formatPorts = (body: TemplateFormProps) => {
    let ports: Array<any> = [];
    body.templatePorts?.forEach(port => {
      let listenPort = "";

      if (PORTLESS_PROTOCOLS.includes(port.listenPortProtocol?.toUpperCase())) {
        listenPort = "any";
      }

      if (port?.listenPort?.length) {
        listenPort = port.listenPort;
      }

      ports.push({
        listenPort: listenPort,
        listenPortProtocol: port.listenPortProtocol,
        listenPortReviewed: port.listenPortReviewed,
        listenProcessNames: port.listenProcessNames
          ? [port.listenProcessNames]
          : [],
      });
    });
    return ports;
  };
  const createTemplates = (body: TemplateFormProps) => {
    if (isValidTemplate()) {
      setLoading(true);
      let payload: any = body;
      payload.templatePorts = formatPorts(body);
      payload.templatePorts = getAllRules(payload.templatePorts);
      payload.templatePaths = getAllRules(payload.templatePaths);
      createTemplatesAPI.mutate(payload, {
        onSuccess: response => {
          queryClient.invalidateQueries({
            queryKey: ["policy"],
          });
          updateTemplateData({
            templateName: formData.templateName,
            templateDescription: formData.templateDescription,
            templateType: formData.templateType,
          });
          notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
            label: "CreateTemplateRequestSubmittedSuccessfully",
          });
          setTimeout(() => {
            onClose();
            setLoading(false);
          }, 300);
        },
        onError: error => {
          setLoading(false);
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  };

  const editTemplates = (body: TemplateFormProps) => {
    if (isValidTemplate()) {
      setLoading(true);
      let payload: TemplateFormProps = body;
      payload.templatePorts = formatPorts(body);
      if (!payload.templatePorts?.length) {
        delete payload.templatePorts;
      }
      editTemplatesAPI.mutate(payload, {
        onSuccess: response => {
          updateTemplateData({
            templateName: formData.templateName,
            templateDescription: formData.templateDescription,
            templateType: formData.templateType,
          });
          setLoading(false);
          notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
            label: "UpdateTemplateRequestSubmittedSuccessfully",
          });
          setTimeout(() => {
            onClose();
            setLoading(false);
          }, 300);
        },
        onError: error => {
          setLoading(false);
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  };

  const isValidTemplate = () => {
    let isValid = false;
    if (mode === TemplateFormMode.ADD) {
      isValid = ruleValidator({
        templatePorts: portList,
        templatePaths: [...inboundPathList, ...outboundPathList],
        templateType: formData.templateType,
      });
    } else {
      const isNameValid = formData.templateName?.trim().length > 0;

      if (isNameValid && formData.templateType) {
        isValid =
          portList.length || inboundPathList.length || outboundPathList.length
            ? ruleValidator({
                templatePorts: portList,
                templatePaths: [...inboundPathList, ...outboundPathList],
                templateType: formData.templateType,
              })
            : true;
      } else {
        isValid = false;
      }
    }
    return isValid;
  };

  const handleResetPortRuleStatus = (type: TemplateType) =>
    resetPortRuleStatus({ templateType: type, portList, setPortList });

  const { isFeatureEnabled: isProcessTemplateEnabled } = useFeatureFlagControl(
    FEATURES.PROCESS_BASED_TEMPLATE,
  );

  return (
    <>
      <DialogContent>
        <Stack
          alignItems={"flex-start"}
          sx={{ position: "relative", height: "100%", px: 2 }}
        >
          <Stack sx={{ flex: 1, width: "100%" }}>
            {!backButtonEnable ? (
              <Typography variant="h6" sx={{ mt: 2 }}>
                {window.getCTTranslatedText(title ?? "")}
              </Typography>
            ) : (
              <Stack direction="row" sx={{ width: "100%" }} spacing={1}>
                <IconButton
                  size="medium"
                  aria-label="close drawer"
                  onClick={onClose}
                >
                  <ArrowBackIosIcon fontSize="small" />
                </IconButton>
                <Typography variant="h6">
                  {window.getCTTranslatedText(title ?? "")}
                </Typography>
              </Stack>
            )}

            {mode !== TemplateFormMode.ADD && (
              <Stack
                alignItems="flex-start"
                justifyContent="flex-start"
                spacing={4}
                my={4}
                sx={{ width: "100%" }}
              >
                <RenderTemplateForm
                  mode={mode}
                  formData={formData}
                  setFormData={setFormData}
                  handleResetPortRuleStatus={handleResetPortRuleStatus}
                />
              </Stack>
            )}

            {mode !== TemplateFormMode.EDIT && (
              <Stack
                spacing={4}
                sx={{
                  width: "100%",
                  pb: 8,
                }}
              >
                <Stack
                  sx={{
                    mt: mode === TemplateFormMode.ADD ? 4 : 2,
                    width: "100%",
                  }}
                  spacing={1}
                >
                  <Alert sx={{ width: "100%" }} severity={"warning"}>
                    <Typography variant="body2">
                      {`${window.getCTTranslatedText(
                        "TemplateFormAlertMessage",
                        {
                          text: window.getCTTranslatedText(
                            formData.templateType ===
                              TemplateType.ApplicationTemplate
                              ? "and paths"
                              : "",
                          ),
                        },
                      )}`}
                    </Typography>
                  </Alert>
                  <Alert sx={{ width: "100%" }} severity="info">
                    <Typography variant="body2">
                      <strong>{window.getCTTranslatedText("proTip")}: </strong>
                      {window.getCTTranslatedText("portRangeProTip")}
                    </Typography>
                  </Alert>
                </Stack>

                <AssignPort
                  portList={portList}
                  setPortList={setPortList}
                  mode={mode}
                  templateType={formData.templateType}
                  hideProcess={!isProcessTemplateEnabled}
                />

                {formData.templateType === TemplateType.ApplicationTemplate && (
                  <Stack spacing={4}>
                    <AddInboundOrOutboundPaths
                      pathList={inboundPathList}
                      mode={mode}
                      setPathList={setInboundPathList}
                      formData={formData}
                      direction={Direction.Inbound}
                    />

                    <AddInboundOrOutboundPaths
                      pathList={outboundPathList}
                      mode={mode}
                      setPathList={setOutboundPathList}
                      formData={formData}
                      direction={Direction.Outbound}
                    />
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>

          {mode !== TemplateFormMode.CREATE && (
            <Annotation setComment={setComment} comment={comment} />
          )}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <ToolbarAction
          loading={
            createTemplatesAPI.isLoading ||
            editTemplatesAPI.isLoading ||
            addRulesAPI.isLoading ||
            loading
          }
          save={updateTemplateDetails}
          cancel={onClose}
          actionBtnText={btnTitle ? btnTitle : "create"}
          isValid={isValidTemplate()}
        />
      </DialogActions>
    </>
  );
}
