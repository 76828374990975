import { CustomCommonStoreType } from "common/types/types";
import { Asset } from "pages/assets/types";
import { MatchedByTemplates } from "pages/paths/types";

export interface PortColumnRenderProps {
  port: Port;
  viewOnly?: boolean;
  useFacetStore?: CustomCommonStoreType;
}

interface ListenAsset extends Asset {}

export interface Port {
  lpId: string;
  listenPortProtocol: string;
  listenPort: string;
  listenPortName: string;
  listenProcessNames: string[];
  listenAsset: ListenAsset;
  bandwidthInBytes: number | string;
  bandwidthInBytesSeries: Array<number>;
  listeningonpublicinterface: boolean;
  listenPortLastObserved: string;
  connectionCount: number;
  internetPathCount: number;
  pathCount: number;
  listenPortReviewed: PortStatus;
  listenPortEnforced: PortStatus;
  totalComments: number;
  rawBandwidthInBytes: number | string;
  rawListenPortLastObserved: string;
  matchedByTemplates: MatchedByTemplates[];
  listenProcessPaths: string[];
  listenPortCategoryName: PortCategory;
}

export enum PortCategory {
  ApplicationSystem = "Application System",
  ApplicationRegistered = "Application Registered",
  ApplicationEphemeral = "Application Ephemeral",
  ApplicationDatabase = "Application Database",
  InfrastructureSystem = "Infrastructure System",
  ManagementSystem = "Management System",
  ApplicationProxy = "Application Proxy",
  MaliciousRegistered = "Malicious Registered",
  RiskyRegistered = "Risky Registered",
  RiskySystem = "Risky System",
  InfrastructureRegistered = "Infrastructure Registered",
  ManagementRegistered = "Management Registered",
}

export enum PortStatus {
  Unreviewed = "unreviewed",
  AllowIntranet = "allow-intranet",
  AllowAny = "allow-any",
  Deny = "denied",
  PathRestricted = "path-restricted",
  PathRestrictedByTemplate = "path-restricted-by-template",
  AllowAnyByTemplate = "allow-any-by-template",
  AllowIntranetByTemplate = "allow-intranet-by-template",
  AllowedByProgressive = "allowed-by-progressive",
  DeniedByTemplate = "denied-by-template",
}

export interface PortRule {
  lpId?: string;
  listenPort: string;
  listenPortName: string;
  listenProcessNames: string[];
  listenPortProtocol: string;
  listenPortReviewed: PortAllowedStatus | PortStatus;
}

export enum PortAllowedStatus {
  AllowIntranet = "allow-intranet",
  AllowAny = "allow-any",
}

export enum PortRulePreference {
  Port = "port",
  Process = "process",
  Port_Process = "port & process",
}

export enum ProgressiveEnforcementLevel {
  Any = 0,
  AllowAll = 33,
  AllowWithBandwidth = 66,
  ZeroTrust = 100,
}

export const ProgressiveEnforcementLevelLabels = {
  [ProgressiveEnforcementLevel.Any]: "Any Port",
  [ProgressiveEnforcementLevel.AllowAll]: "Open Ports",
  [ProgressiveEnforcementLevel.AllowWithBandwidth]: "Active Ports",
  [ProgressiveEnforcementLevel.ZeroTrust]: "Zero Trust",
};

export enum ProgressiveEnforcementStatus {
  Any = "any",
  AllowAll = "allow-all",
  AllowWithBandwidth = "allow-with-bandwidth",
  ZeroTrust = "zerotrust",
}

export const ProgressiveEnforcementStatusMap = {
  [ProgressiveEnforcementStatus.Any]: ProgressiveEnforcementLevel.Any,
  [ProgressiveEnforcementStatus.AllowAll]: ProgressiveEnforcementLevel.AllowAll,
  [ProgressiveEnforcementStatus.AllowWithBandwidth]:
    ProgressiveEnforcementLevel.AllowWithBandwidth,
  [ProgressiveEnforcementStatus.ZeroTrust]:
    ProgressiveEnforcementLevel.ZeroTrust,
};

export const ProgressiveEnforcementStatusReverseMap = {
  [ProgressiveEnforcementLevel.Any]: ProgressiveEnforcementStatus.Any,
  [ProgressiveEnforcementLevel.AllowAll]: ProgressiveEnforcementStatus.AllowAll,
  [ProgressiveEnforcementLevel.AllowWithBandwidth]:
    ProgressiveEnforcementStatus.AllowWithBandwidth,
  [ProgressiveEnforcementLevel.ZeroTrust]:
    ProgressiveEnforcementStatus.ZeroTrust,
};

export interface ProgressiveStatusSliderMark {
  key: ProgressiveEnforcementLevel;
  value: number;
}

export const ProgressiveStatusSliderMarks: Array<ProgressiveStatusSliderMark> =
  [
    {
      key: ProgressiveEnforcementLevel.Any,
      value: 0,
    },
    {
      key: ProgressiveEnforcementLevel.AllowAll,
      value: 33,
    },
    {
      key: ProgressiveEnforcementLevel.AllowWithBandwidth,
      value: 66,
    },
    {
      key: ProgressiveEnforcementLevel.ZeroTrust,
      value: 100,
    },
  ];
