import { Box, Stack, Tooltip } from "@mui/material";
import { useCommonStore } from "common/store";

import { AssetNameInternal } from "pages/assets/components/asset-name-internal";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { PortColumnRenderProps } from "pages/ports/types";

export function PortAsset({
  port,
  viewOnly,
  useFacetStore = useCommonStore,
}: PortColumnRenderProps) {
  if (!port.listenAsset.assetName) {
    return <Box style={{ minWidth: 310, minHeight: 45 }} />;
  }

  return (
    <Stack
      sx={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
      alignItems="left"
      justifyContent="center"
    >
      <Tooltip
        title={
          viewOnly
            ? ""
            : `${window.getCTTranslatedText("Show only for")} ${
                port.listenAsset.assetName
              }`
        }
      >
        <CTDatagridButton
          asText={viewOnly === true}
          isDisabled={viewOnly === true}
          useFacetStore={useFacetStore}
          facetName={"assetname"}
          facetOption={port.listenAsset?.assetName! ?? ""}
        >
          {AssetNameInternal({ asset: port?.listenAsset })}
        </CTDatagridButton>
      </Tooltip>
    </Stack>
  );
}
