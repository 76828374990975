import { Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import {
  GridToolbar,
  ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { AutoUpgradeStatus } from "pages/agents/components/agent-drawers/helpers/constants";
import { useApplianceStore } from "pages/appliances/store";
import { Appliance } from "pages/appliances/types";
import { useMemo, useState } from "react";
import { isDiagnosticsAllowed } from "../appliance-data-grid/helpers/columnHelpers";
import { ApplianceDownload } from "../appliance-download";
import { ApplianceDiagnosticDrawer } from "../appliance-drawers/ApplianceDiagnosticDrawer";
import { ApplianceRestartDrawer } from "../appliance-drawers/ApplianceRestartDrawer";
import { ApplianceUpgradeDrawer } from "../appliance-drawers/ApplianceUpgradeDrawer";
import { AutoUpgradeDrawer } from "../appliance-drawers/helpers/AutoUpgradeDrawer";
import {
  AGENT_AUTOUPGRADE_COLUMNS,
  APPLIANCE_DIAGNOSTICS_COLUMNS,
  APPLIANCE_RESTART_COLUMNS,
  APPLIANCE_UPGRADE_COLUMNS,
} from "../appliance-drawers/helpers/constants";
import {
  AutoUpgradeActions,
  DiagnosticActions,
  RestartActions,
  UpgradeActions,
} from "./ToolbarActions";
import { ApplianceActivation } from "../appliance-activation";

export interface ApplianceToolbarProps extends Omit<ToolbarProps, "children"> {
  selectedData: Array<Appliance> | undefined;
  selection: Array<GridRowId>;
  hideToolbar: Function;
  showPortStatusOption?: boolean;
  recommendationId?: string;
}
export enum AgentActionType {
  RESTART = "restart",
  UPGRADE = "upgrade",
  AUTOUPGRADE = "AUTOUPGRADE",
  DIAGNOSTIC = "diagnostic",
}
export default function ApplianceToolbar(props: ApplianceToolbarProps) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const requestAPIRefresh = useApplianceStore(store => store.requestAPIRefresh);

  const upgradeAgentPerm = userPermissions.has("UPGRADE_AGENT");
  const createDepKeyPerm = userPermissions.has("CREATE_DEPLOYMENT_KEY");

  const [openAgentUpgradeMenu, setAgentUpgradeMenuOpen] = useState(false);
  const [openAgentRestartMenu, setAgentRestartMenuOpen] = useState(false);
  const [openAutoUpgradeMenu, setAutoUpgradeMenuOpen] = useState(false);
  const [autoUpgradeAction, setAutoUpgradeAction] = useState("");
  const [openAgentDiagnosticMenu, setAgentDiagnosticMenuOpen] = useState(false);

  let updatedAgentUpgradeSelection = props?.selection.filter(item => {
    const matchedData = props?.selectedData?.find(
      (obj: Appliance) => obj.agentId === item && obj.isUpgradeEnabled
    );

    return matchedData !== undefined;
  });

  let updatedAgentRestartSelection = props?.selection.filter(item => {
    const matchedData = props?.selectedData?.find(
      (obj: Appliance) => obj.agentId === item && obj.isRestartEnabled
    );

    return matchedData !== undefined;
  });

  let updatedAgentDiagnosticsSelection = props?.selection.filter(item => {
    const matchedData = props?.selectedData?.find(
      (obj: Appliance) =>
        obj.rowId === item &&
        !obj.isDiagnosticsRunning &&
        isDiagnosticsAllowed(obj)
    );

    return matchedData !== undefined;
  });

  let updatedAutoUpgradeSelection = props?.selection.filter(item => {
    const matchedData = props?.selectedData?.find((obj: Appliance) =>
      autoUpgradeAction === AutoUpgradeStatus.Disable
        ? obj.rowId === item && obj.autoUpgradeEnabled
        : obj.rowId === item && !obj.autoUpgradeEnabled
    );

    return matchedData !== undefined;
  });

  const isUpgradeButtonDisabled: boolean = useMemo(() => {
    return !props?.selectedData?.some(obj => obj.isUpgradeEnabled);
  }, [props?.selectedData]);

  const isRestartButtonDisabled: boolean = useMemo(() => {
    return !props?.selectedData?.some(obj => obj.isRestartEnabled);
  }, [props?.selectedData]);

  const isDiagnosticsButtonDisabled: boolean = useMemo(() => {
    return !props?.selectedData?.some(
      obj => !obj.isDiagnosticsRunning && isDiagnosticsAllowed(obj)
    );
  }, [props?.selectedData]);

  const onClose = () => {
    setAgentUpgradeMenuOpen(false);
    setAgentRestartMenuOpen(false);
    setAutoUpgradeMenuOpen(false);
    setAgentDiagnosticMenuOpen(false);
    props.hideToolbar();
    requestAPIRefresh();
  };

  const handleButtonChange = (btnAction: string, btnActionType: string) => {
    if (btnAction === AgentActionType.UPGRADE) {
      setAgentUpgradeMenuOpen(prevOpen => !prevOpen);
    } else if (btnAction === AgentActionType.RESTART) {
      setAgentRestartMenuOpen(prevOpen => !prevOpen);
    } else if (btnAction === AgentActionType.DIAGNOSTIC) {
      setAgentDiagnosticMenuOpen(prevOpen => !prevOpen);
    } else {
      setAutoUpgradeMenuOpen(prevOpen => !prevOpen);
      setAutoUpgradeAction(btnActionType);
    }
  };

  return (
    <>
      {openAgentUpgradeMenu && (
        <ApplianceUpgradeDrawer
          isOpen={openAgentUpgradeMenu}
          page={"appliance"}
          title={window.getCTTranslatedText("upgradeAppliance")}
          onCancel={onClose}
          data={props?.selectedData}
          columns={APPLIANCE_UPGRADE_COLUMNS}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedAgentUpgradeSelection}
          hideToolbar={props?.hideToolbar}
        />
      )}

      {openAgentRestartMenu && (
        <ApplianceRestartDrawer
          isOpen={openAgentRestartMenu}
          page={"appliance"}
          title={window.getCTTranslatedText("restartAppliance")}
          onCancel={onClose}
          data={props?.selectedData}
          columns={APPLIANCE_RESTART_COLUMNS}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedAgentRestartSelection}
          hideToolbar={props?.hideToolbar}
        />
      )}
      {openAgentDiagnosticMenu && (
        <ApplianceDiagnosticDrawer
          isOpen={openAgentDiagnosticMenu}
          page={"agent"}
          title={window.getCTTranslatedText("agentDiagnostics")}
          onCancel={onClose}
          data={props?.selectedData}
          columns={APPLIANCE_DIAGNOSTICS_COLUMNS}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedAgentDiagnosticsSelection}
          hideToolbar={props?.hideToolbar}
        />
      )}

      {openAutoUpgradeMenu && (
        <AutoUpgradeDrawer
          isOpen={openAutoUpgradeMenu}
          page={"appliance"}
          title={window.getCTTranslatedText("autoUpgradeAppliance")}
          onCancel={onClose}
          data={props?.selectedData}
          columns={AGENT_AUTOUPGRADE_COLUMNS(autoUpgradeAction)}
          rowCount={props?.selection?.length ?? 0}
          selection={updatedAutoUpgradeSelection}
          hideToolbar={props?.hideToolbar}
          autoUpgradeAction={autoUpgradeAction}
        />
      )}

      {(upgradeAgentPerm || createDepKeyPerm) && (
        <GridToolbar {...props}>
          <Stack
            direction="row"
            spacing={3}
            sx={{ width: "100%" }}
            justifyItems="flex-end"
            alignItems={"flex-end"}
            justifyContent="flex-end"
          >
            {props?.selection.length > 0 && upgradeAgentPerm && (
              <>
                <RestartActions
                  {...{ isRestartButtonDisabled, handleButtonChange }}
                />
                <UpgradeActions
                  {...{ isUpgradeButtonDisabled, handleButtonChange }}
                />
                <AutoUpgradeActions {...{ handleButtonChange }} />
                <DiagnosticActions
                  isDiagnosticsButtonDisabled={isDiagnosticsButtonDisabled}
                  onClickDiagnostics={handleButtonChange}
                />
              </>
            )}
            {createDepKeyPerm &&
              !openAgentUpgradeMenu &&
              props?.selection.length === 0 && (
                <>
                  <ApplianceDownload />
                  <ApplianceActivation />
                </>
              )}
          </Stack>
        </GridToolbar>
      )}
    </>
  );
}
