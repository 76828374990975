import {
  Box,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { CircularCenteredLoader } from "common/atoms/loader";
import {
  Direction,
  SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { ProgressiveEnforcementLevel } from "pages/ports/types";
import { SecurityLevelCard } from "../security-level-card";

interface PolicyAutomationGeneratorProps {
  isInboundSimulated: boolean;
  isOutboundSimulated: boolean;
  initialAttackSurfaceStatus?: SecurityStatus;
  selectedAttackSurfaceStatus: SecurityStatus;
  initialProgressiveEnforcementLevel?: ProgressiveEnforcementLevel;
  selectedProgressiveEnforcementLevel: ProgressiveEnforcementLevel;
  selectedBlastRadiusStatus: SecurityStatus;
  autoPush: boolean;
  setSelectedAttackSurfaceStatus: (value: SecurityStatus) => void;
  setSelectedProgressiveEnforcementLevel: (
    value: ProgressiveEnforcementLevel
  ) => void;
  setSelectedBlastRadiusStatus: (value: SecurityStatus) => void;
  setAutoPush: (value: boolean) => void;
  isLoading: boolean;
  onChangeAttackSurfaceTestMode: (test: boolean) => void;
  onChangeBlastRadiusTestMode: (test: boolean) => void;
  criteria?: string;
  isZeroTrustAutomationEditable: boolean;
  policyId: string;
  policyProgressiveLastRefreshed?: string;
}

export const PolicyAutomationGenerator = ({
  isInboundSimulated,
  isOutboundSimulated,
  initialAttackSurfaceStatus,
  selectedAttackSurfaceStatus,
  setSelectedAttackSurfaceStatus,
  initialProgressiveEnforcementLevel,
  selectedProgressiveEnforcementLevel,
  setSelectedProgressiveEnforcementLevel,
  selectedBlastRadiusStatus,
  setSelectedBlastRadiusStatus,
  autoPush,
  setAutoPush,
  isLoading,
  onChangeAttackSurfaceTestMode,
  onChangeBlastRadiusTestMode,
  criteria,
  isZeroTrustAutomationEditable,
  policyId,
  policyProgressiveLastRefreshed,
}: PolicyAutomationGeneratorProps) => {
  if (isLoading) {
    return <CircularCenteredLoader />;
  }

  const isAttackSurfaceZeroTrustAutomationEditable =
    isZeroTrustAutomationEditable ||
    (selectedProgressiveEnforcementLevel !==
      ProgressiveEnforcementLevel.ZeroTrust &&
      selectedProgressiveEnforcementLevel !== ProgressiveEnforcementLevel.Any);

  return (
    <Box sx={{ p: 0 }}>
      <Stack spacing={3}>
        <Box>
          <Typography variant="subtitle2">
            {window.getCTTranslatedText("Enforcement levels")}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: theme => theme.palette.text.secondary }}
          >
            {window.getCTTranslatedText(
              "Configure minimal enforcement level of the matching assets"
            )}
          </Typography>
        </Box>

        <SecurityLevelCard
          direction={Direction.Inbound}
          currentStatus={initialAttackSurfaceStatus}
          selectedStatus={selectedAttackSurfaceStatus}
          setSelectedStatus={setSelectedAttackSurfaceStatus}
          initialProgressiveEnforcementLevel={
            initialProgressiveEnforcementLevel
          }
          selectedProgressiveEnforcementLevel={
            selectedProgressiveEnforcementLevel
          }
          setSelectedProgressiveEnforcementLevel={
            setSelectedProgressiveEnforcementLevel
          }
          simulatedStatus={isInboundSimulated}
          onChangeTestMode={onChangeAttackSurfaceTestMode}
          criteria={criteria}
          isZeroTrustAutomationEditable={
            isAttackSurfaceZeroTrustAutomationEditable
          }
          policyId={policyId}
          policyProgressiveLastRefreshed={policyProgressiveLastRefreshed}
        />

        <SecurityLevelCard
          direction={Direction.Outbound}
          selectedStatus={selectedBlastRadiusStatus}
          setSelectedStatus={setSelectedBlastRadiusStatus}
          simulatedStatus={isOutboundSimulated}
          onChangeTestMode={onChangeBlastRadiusTestMode}
          criteria={criteria}
          isZeroTrustAutomationEditable={isZeroTrustAutomationEditable}
        />
      </Stack>
      <Box
        component={Paper}
        elevation={2}
        p={2}
        mt={3}
        sx={{ opacity: isZeroTrustAutomationEditable ? 1 : 0.3 }}
      >
        <Typography variant="subtitle1">
          {window.getCTTranslatedText("Auto push to firewall")}
        </Typography>
        <FormControlLabel
          sx={{ mt: 3 }}
          disabled={!isZeroTrustAutomationEditable}
          label={
            <Typography variant="body2" ml={1}>
              {window.getCTTranslatedText(
                "Enable auto push to firewall on any changes in ports, paths, templates or named networks of the matching assets"
              )}
            </Typography>
          }
          control={
            <Switch
              value={autoPush}
              checked={autoPush}
              onChange={(_, c) => setAutoPush(c)}
              inputProps={{ "aria-label": "Use Auto Push" }}
            />
          }
        />
      </Box>
    </Box>
  );
};
