import { useCommonStore } from "common/store";
import { useCustomFacetGroup } from "hooks/useCustomFacetGroup";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import merge from "lodash/merge";
import { useExportCSV } from "modules/export-csv/hooks";
import { FacetUtils, useFacetQueryConnector } from "modules/facets";
import { allowedFieldOptionsValueUpdate } from "modules/facets/utils/allowedFieldOptionsValueUpdate";
import { useScopeMetadata } from "modules/scope-metadata";
import { useSearchStore } from "modules/search/store";
import { useEffect, useMemo, useRef, useState } from "react";
import { FacetState } from "../facets/types";
import { ScopeMetadata } from "../scope-metadata/types";
import {
  CoreProps,
  CoreResponse,
  DataLoaderParams,
  DataLoaderResponse,
  ParamsType,
  SortOrder,
} from "./types";

export function useCriteriaBuilder(
  searchQuery: string,
  facetState: FacetState,
  metaData: ScopeMetadata | undefined,
  appendSearchCriteria?: string
) {
  return useMemo(() => {
    if (!metaData) {
      return undefined;
    }
    const facetsQuery = FacetUtils.getServerQueryLanguageFromFacets(
      facetState,
      metaData
    );
    let criteria = searchQuery.length > 0 ? `'${searchQuery}'` : "";
    if (appendSearchCriteria && appendSearchCriteria !== "*") {
      if (!criteria) {
        criteria = appendSearchCriteria;
      } else {
        criteria += " AND " + appendSearchCriteria;
      }
    }
    criteria +=
      facetsQuery.length > 0 && criteria.length > 0
        ? " AND " + facetsQuery
        : facetsQuery;
    if (!criteria.length) {
      criteria = "*";
    }

    return criteria;
  }, [searchQuery, facetState, metaData, appendSearchCriteria]);
}
export interface DataLoaderBody extends ParamsType {
  sourceCriteria?: string;
  destinationCriteria?: string;
  facetFields: Array<any> | undefined;
  _download?: boolean;
}

export function useDataLoader<DataType>({
  onLoadFacets,
  useStore,
  useApi,
  defaultSortOrder,
  dataMapper,
  pageSize: requestedPageSize,
  includeFacets,
  sourceCriteria = "",
  destinationCriteria = "",
  includeFacetFields,
  criteria: searchCriteria,
  onIssueQuery,
  scope,
  isNewCsvAPIVersion,
  additionalBodyParams,
}: DataLoaderParams<DataType>): DataLoaderResponse<DataType> {
  const [totalCount, setTotalCount] = useState(0);
  const [totalCap, setTotalCap] = useState<number | undefined>(0);

  const [data, setData] = useState<Array<DataType> | undefined>(undefined);
  const [rawData, setRawData] = useState<Array<DataType> | undefined>(
    undefined
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(
    requestedPageSize === undefined ? 100 : requestedPageSize
  );
  const [sort, setSort] = useState<Array<SortOrder>>(defaultSortOrder || []);

  const apiRefreshRequest = useStore(state => state.apiRefreshRequest);

  const mutation = useApi();

  const dataLoaderImpl = () => {
    if (!searchCriteria || !pageSize) {
      return;
    }
    const search: DataLoaderBody = {
      criteria: searchCriteria,
      pagination: {
        offset: page * pageSize,
        limit: pageSize,
        sort,
      },
      // TODO - making it to specific use case to help query optimization at BE
      facetFields: includeFacetFields ? includeFacets : [],
      ...additionalBodyParams,
    };

    if (sourceCriteria !== "") {
      search["sourceCriteria"] = sourceCriteria ?? "";
    }

    if (destinationCriteria !== "") {
      search["destinationCriteria"] = destinationCriteria ?? "";
    }

    onIssueQuery?.(searchCriteria);
    mutation.mutate(search, {
      onSuccess(data) {
        setRawData(cloneDeep(data?.items ?? []));
        const responseData = data;
        const total = responseData?.metadata?.total;
        setTotalCount(total ?? 0);
        setTotalCap(responseData?.metadata?.totalThreshold);
        if (onLoadFacets) {
          onLoadFacets(responseData?.metadata?.facets);
        }
        onLoadData(responseData?.items ?? []);
      },
      onError(error) {
        setData([]);
        setRawData([]);
        setTotalCount(0);
      },
    });
  };

  const dataLoaderRef = useRef(dataLoaderImpl);
  dataLoaderRef.current = dataLoaderImpl;

  const dataLoader = useMemo(
    () => debounce(() => dataLoaderRef.current(), 500),
    []
  );

  const onLoadData = (data: Array<DataType>) => {
    if (dataMapper) {
      data = (data ?? []).map(dataMapper);
    }
    setData(data);
  };

  useEffect(() => {
    setPage(0);
  }, [searchCriteria]);

  useEffect(() => {
    dataLoader();
  }, [dataLoader, searchCriteria, page, pageSize, sort, apiRefreshRequest]);

  const {
    triggerExportAsCsv,
    getExportStatus,
    getUrlToDownload,
    resetDownloadUrl,
  } = useExportCSV({
    useApi,
    searchCriteria,
    page,
    sort,
    sourceCriteria,
    destinationCriteria,
    scope,
    isNewCsvAPIVersion,
    maxRowCount: totalCap,
  });

  return {
    rowCount: totalCount,
    maxRowCount: totalCap,
    rows: data,
    page,
    pageSize,
    onPageChange: setPage,
    onPageSizeChange: setPageSize,
    onSortChange: setSort,
    mutation,
    rawData,
    triggerExportAsCsv,
    getExportStatus,
    getUrlToDownload,
    resetDownloadUrl,
  };
}

export default function useCore<DataType>({
  useStore,
  scope,
  defaultSortOrder,
  dataMapper,
  useApi,
  pageSize,
  facetGroupInfo,
  includeFacetFields = [],
  keepTags = true,
  skipUseFacetQueryConnector = false,
  skipSearch = false,
  facetsURLKey = "filters",
  additionalCriteria = "",
  sourceCriteria = "",
  destinationCriteria = "",
  useFacetStore = useCommonStore,
  name,
  isNewCsvAPIVersion,
  facetOptionsDisplayMapping,
  additionalBodyParams,
}: CoreProps<DataType>): CoreResponse<DataType> {
  const setFacetGroupInfo = useFacetStore(state => state.setFacetGroupInfo);
  let metadata = useFacetStore(state => state.metadata);

  const facetGroupInfoWithCustomTags = useCustomFacetGroup(
    metadata,
    facetGroupInfo
  );

  useEffect(() => {
    if (facetGroupInfoWithCustomTags) {
      setFacetGroupInfo(facetGroupInfoWithCustomTags);
    }
  }, [facetGroupInfoWithCustomTags, setFacetGroupInfo]);

  const facetConfig = useFacetStore(state => state.facetConfig);
  const setFacetConfig = useFacetStore(state => state.setFacetConfig);
  const facetState = useFacetStore(state => state.facets);
  const setFacetState = useFacetStore(state => state.setFacets);
  const updateFacet = useFacetStore(state => state.updateFacet);

  const clearCommonStore = useFacetStore(state => state.clear);

  useEffect(() => {
    if (!keepTags) {
      clearCommonStore(keepTags, true);
    }
    return () => {
      try {
        clearCommonStore(keepTags);
      } catch (e: any) {
        console.error(e);
      }
    };
  }, [clearCommonStore, keepTags]);

  const setScope = useFacetStore(state => state.setScope);
  useEffect(() => {
    setScope(scope);
  }, [scope, setScope]);

  const clearLastInteractedFacet = useFacetStore(
    state => state.clearLastInteractedFacet
  );

  const updateMetadata = useFacetStore(state => state.updateMetadata);

  const storeScope = useFacetStore(state => state.scope);
  const { data: metaData, error: metadataLoadingError } = useScopeMetadata({
    scope: storeScope!,
  });

  const [facetsOpen, setFacetsOpen] = useState(false);

  useEffect(() => {
    if (metaData) {
      if (metaData.columns) {
        const filteredFacetOptionsDisplayMapping = Object.fromEntries(
          [...allowedFieldOptionsValueUpdate].map(key => [
            key,
            facetOptionsDisplayMapping?.[key],
          ])
        );
        const mergedColumns = merge(
          {},
          metaData.columns,
          filteredFacetOptionsDisplayMapping
        );
        const updatedData = { ...metaData, columns: mergedColumns };
        updateMetadata(updatedData);
      } else {
        updateMetadata(metaData);
      }
    } else if (metadataLoadingError) {
      console.error("Failed to load metadata", { metadataLoadingError });
    }
  }, [
    metaData,
    updateMetadata,
    metadataLoadingError,
    facetOptionsDisplayMapping,
  ]);

  skipUseFacetQueryConnector =
    skipUseFacetQueryConnector || scope !== storeScope;

  useFacetQueryConnector(
    facetState,
    metaData,
    setFacetState,
    skipUseFacetQueryConnector,
    facetsURLKey
  );

  const onLoadFacets = (serverFacets: Array<any>) => {
    setFacetConfig(serverFacets);
  };

  useEffect(() => {
    if (pageSize === 0 || !includeFacetFields?.length) {
      setFacetConfig([]);
    }
  }, [pageSize, includeFacetFields?.length, setFacetConfig]);

  const searchQuery = useSearchStore(state => state.search);
  useEffect(() => {
    clearLastInteractedFacet();
  }, [searchQuery, clearLastInteractedFacet]);

  // TODO remove if not needed
  // const openFacets = useFacetStore(state => state.openFacets);
  // const openFacets = useCommonStore(state => state.openFacets);
  const openFacetsRef = useRef<Array<string>>([]);
  // openFacetsRef.current = Array.from(openFacets.keys()).filter(facetName => {
  //   return metaData?.columns[facetName]?.facetable;
  // });
  openFacetsRef.current = (includeFacetFields || []).filter(facetName => {
    return metaData?.columns[facetName]?.facetable;
  });
  const updateSearchCriteria = useFacetStore(
    state => state.updateCurrentSearchCriteria
  );

  const searchCriteria = useCriteriaBuilder(
    skipSearch ? "" : searchQuery,
    facetState,
    metaData,
    additionalCriteria
  );

  const dataLoaderResponse = useDataLoader<DataType>({
    onLoadFacets,
    useStore,
    useApi,
    defaultSortOrder,
    dataMapper,
    pageSize,
    includeFacets: openFacetsRef.current,
    sourceCriteria,
    destinationCriteria,
    includeFacetFields,
    criteria: searchCriteria,
    onIssueQuery: updateSearchCriteria,
    scope,
    isNewCsvAPIVersion,
    additionalBodyParams,
  });

  return {
    ...dataLoaderResponse,
    facetConfig,
    updateFacet,
    facetsOpen,
    setFacetsOpen,
    facetState,
    metadata: metaData,
  };
}
