import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AssignedNetworksViewDrawer } from "modules/asset-networks/assigned-networks-view-drawer";
import { useAssetNetworks } from "modules/asset-networks/hooks";
import { AssignNetworksDrawer } from "modules/assign-networks-to-assets/components/assign-networks-drawer";
import { AssignNetworksOption } from "pages/tags/components/tag-policy-list/components/tag-networks/components/assigned-networks-tags/AssignedNetworksTags";
import { useUserGroupStore } from "pages/users-segmentation/components/user-groups/store";
import { useEffect } from "react";

interface UserGroupAssignedNamedNetworksViewDrawerProps {
  criteria: string;
  multiselect?: boolean;
  onClose: Function;
  onSelectCallback?: Function;
}
export const UserGroupAssignedNamedNetworksViewDrawer = ({
  criteria,
  multiselect = true,
  onClose,
  onSelectCallback,
}: Readonly<UserGroupAssignedNamedNetworksViewDrawerProps>) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const requestAPIRefresh = useUserGroupStore(store => store.requestAPIRefresh);
  const {
    showAssignedNetworksDrawer,
    openAssignedNetworksDrawer,
    closeAssignedNetworksDrawer,
    showAssignNetworksDrawer,
    openAssignNetworksDrawer,
    closeAssignNetworksDrawer,
    networkList,
    isLoading,
    networkListMutation,
    onSortChange,
    setPageSize,
    setPage,
    pageSize,
    page: pageCount,
    totalCount,
    onSearchCriteriaChange,
  } = useAssetNetworks({ criteria });

  const hasPermission = Boolean(
    userPermissions.has("UPDATE_ASSET") &&
      userPermissions.has("UPDATE_NAMED_NETWORK")
  );
  useEffect(() => {
    openAssignedNetworksDrawer();
  }, [openAssignedNetworksDrawer]);
  return (
    <>
      <AssignNetworksDrawer
        isOpen={showAssignNetworksDrawer}
        page={"assets"}
        title="Assign Named Network"
        criteria={criteria}
        rules={[]}
        onCancel={closeAssignNetworksDrawer}
        onConfirm={closeAssignNetworksDrawer}
        btnTitle={"assign"}
        multiselect={multiselect}
        onSelectCallback={onSelectCallback}
        hasPermission={hasPermission}
      />

      <AssignedNetworksViewDrawer
        isOpen={showAssignedNetworksDrawer}
        onClose={() => {
          onClose();
          closeAssignedNetworksDrawer();
          requestAPIRefresh();
        }}
        networkList={networkList}
        networksSuggestMutation={networkListMutation}
        page="assets"
        onSortChange={onSortChange}
        AssignNetworksOption={() =>
          AssignNetworksOption({
            openAssignNetworksDrawer,
            hasPermission,
          })
        }
        multiselect={multiselect}
        criteria={criteria}
        hasPermission={hasPermission}
        isLoading={isLoading}
        setPage={setPage}
        setPageSize={setPageSize}
        pageCount={pageCount}
        pageSize={pageSize}
        totalCount={totalCount}
        onSearchCriteriaChange={onSearchCriteriaChange}
      />
    </>
  );
};
