import { Stack, Typography, Alert, AlertColor } from "@mui/material";
import { DisplayTextMap } from "common/constants/displayTextMap";
import { ConfigStatusMap } from "../types";

export function ConfigStatus({ status }: { status: string }) {
  let label: string = DisplayTextMap[ConfigStatusMap.Inprogress];
  let severity: AlertColor = "info";
  if (status === ConfigStatusMap.Syncronized) {
    label = DisplayTextMap[ConfigStatusMap.Syncronized];
    severity = "success";
  }
  if (status === ConfigStatusMap.Failed) {
    label = DisplayTextMap[ConfigStatusMap.Failed];
    severity = "error";
  }
  if (!status) {
    return <Stack />;
  }
  return (
    <Stack>
      <Alert severity={severity}>
        <Typography variant="subtitle2">{label}</Typography>
      </Alert>
    </Stack>
  );
}
