import { Stack, Typography } from "@mui/material";
import { IDPColumnRenderProps } from "../types";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { getRelativeLastObserved } from "common/utils";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

function isDateLessThanToday(expiryDate: string) {
  const inputDate = new Date(expiryDate);
  const currentDate = new Date();

  currentDate.setHours(0, 0, 0, 0);

  return inputDate < currentDate;
}

export const TokenExpiryTime = ({
  idp,
  viewOnly = false,
}: IDPColumnRenderProps) => {
  const tokenExpiryTime = idp.tokenExpiryTime ?? "";
  const isExpired = isDateLessThanToday(tokenExpiryTime);

  return (
    <Stack direction={"row"} spacing={1} alignItems="center">
      {isExpired && (
        <WarningAmberOutlinedIcon fontSize="small" color="warning" />
      )}
      <Typography variant="body2">
        {isExpired
          ? window.getCTTranslatedText("TokenExpired")
          : capitalizeFirstLetter(
              getRelativeLastObserved(tokenExpiryTime || "")
            )}
      </Typography>
    </Stack>
  );
};
