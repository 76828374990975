import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { AnalyticsAPIReq, AnalyticsResponse } from "common/types/types";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import {
  useAssignNetworkToAssets,
  useAssignNetworkToTags,
} from "modules/assign-networks-to-assets/components/AssignNetworkDialog/helpers/use-assign-network";
import { AssignNetworksDrawer } from "modules/assign-networks-to-assets/components/assign-networks-drawer";
import { useCriteriaBuilder } from "modules/core/Core";
import { useTBPCriteriaBuilder } from "modules/create-tag-based-policy-drawer/hooks";
import {
  NAMED_NETWORK_ASSIGNMENT_ID,
  NAMED_NETWORK_ASSIGNMENT_OPTIONS,
  PUBLIC_PRIVATE_IP_OPTIONS,
} from "modules/recommendation-workflows/components/port-recommendation-status-action-bar/constants";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
  SubnetType,
  useSourceAndDestinationIPsList,
} from "modules/source-destination-IPs-list/SourceDestinationIPsListDrawer";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { useSaveTagPolicyAPI } from "pages/create-tag-policy/components/PolicyForm";
import { DisplayAppliedRule } from "pages/create-tag-rule/components/display-applied-rule";
import { NetworkFormDrawer } from "pages/networks/components/network-form-drawer";
import { CustomCommonStore } from "pages/paths/components/paths-review-drawer/store/common/usePathListCommonStore";
import {
  CompassDirection,
  NamedNetwork,
  PathDirection,
} from "pages/paths/types";
import { PortStoreType } from "pages/ports/store/types";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";
import pluralize from "pluralize";
import { useCallback, useEffect, useMemo, useState } from "react";
import { StoreApi, UseBoundStore } from "zustand";
import { IPsDataGrid } from "./components/IPs-datagrid";
import {
  usePrivateNNCheckboxSelectionStore,
  usePublicNNCheckboxSelectionStore,
} from "./components/IPs-datagrid/store";
import { PathNamedNetworkRecommendations } from "./components/path-named-network-recommendations";
import { PathRecommendations } from "./components/path-recommendations";
import { PortRecommendationActions } from "./components/port-recommendation-status-action-bar";
import { useNNAssignRecommendation } from "./hooks/usePathNNRecommendations";
import {
  PathAgg,
  usePathRecommendationsWithDataVolumeGroup,
} from "./hooks/usePathRecommendations";

export enum SelectionType {
  Template = "Templates",
  NamedNetwork = "Named Network Assignment",
  PublicIPs = "Public Named Network Classification",
  PrivateIPs = "Private Named Network Classification",
}

export enum SubnetDisplayNameType {
  Subnet8 = "Subnet/8",
  Subnet16 = "Subnet/16",
  Subnet24 = "Subnet/24",
}

export interface MergedNNRecommendationsForInboundAndOutbound {
  id: string;
  inboundCoverage: number;
  outboundCoverage: number;
  inboundPeerCount: number;
  outboundPeerCount: number;
  namedNetwork: NamedNetwork;
  totalAddressCount: number;
  coverage: number;
}

export enum PathRecommendationPageType {
  Paths = "Paths",
  Port = "Port",
  Tags = "tags",
  Asset = "asset",
}

export enum FilterTargetGroupsType {
  Assets = "assets",
}

interface PathRecommendationProps {
  criteria: string;
  useStore?: PortStoreType;
  policyId?: string;
  page: PathRecommendationPageType;
  useFacetStore?: UseBoundStore<StoreApi<CustomCommonStore>>;
  onClose: () => void;
  hidePreview?: boolean;
}

interface PathRecommendationDrawerProps extends PathRecommendationProps {
  isOpen: boolean;
}

export const PathRecommendationDrawer = (
  props: PathRecommendationDrawerProps,
) => {
  return (
    <Drawer
      anchor="right"
      open={props.isOpen}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          padding: "0px",
          width: "80%",
          minWidth: "1000px",
          height: "100%",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={props.onClose}
            sx={{ position: "absolute", right: "16px", top: "16px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <PathRecommendationsCore {...props} />
    </Drawer>
  );
};

export const PathRecommendationsCore = ({
  criteria,
  page,
  policyId,
  useFacetStore,
  onClose,
  hidePreview,
}: PathRecommendationProps) => {
  hidePreview =
    hidePreview ||
    Boolean(page === PathRecommendationPageType.Tags && policyId);

  const notify = useEventSubscriptionStore(state => state.notify);
  const facetState = useFacetStore?.(state => state.facets);
  let { data: assetMetadata } = useScopeMetadata({ scope: Scope.Asset });

  const { data: tagPolicyMetadata } = useScopeMetadata({
    scope: Scope.TagPolicy,
  });
  const [tagBasedPolicyId, setTagBasedPolicyId] = useState<string | undefined>(
    policyId,
  );
  const [
    shouldCallAssignNetworkToTagsMutation,
    setShouldCallAssignNetworkToTagsMutation,
  ] = useState(false);

  const createTagPolicyAPI = useSaveTagPolicyAPI();
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions,
  );

  const [showAssignNetworksDrawer, setShowAssignNetworksDrawer] =
    useState(false);
  const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);
  const [createNNDrawerVisibility, setCreateNNDrawerVisibility] =
    useState(false);

  const [pathsSelection, setPathsSelection] = useState<PathAgg[]>([]);
  const [pathsNNSelection, setPathsNNSelection] = useState<
    MergedNNRecommendationsForInboundAndOutbound[]
  >([]);

  const [privateIPsSelection, setPrivateIPsSelection] = useState<string[]>([]);
  const [publicIPsSelection, setPublicIPsSelection] = useState<string[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selection, setSelection] = useState<SelectionType>(
    page === PathRecommendationPageType.Paths
      ? SelectionType.Template
      : SelectionType.NamedNetwork,
  );
  const open = Boolean(anchorEl);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const queryClient = useQueryClient();
  const assignNetworkToAssetsMutation = useAssignNetworkToAssets();
  const assignNetworkToTagsMutation = useAssignNetworkToTags({
    policyId: tagBasedPolicyId,
  });

  const isNNAssignmentWarningVisible = selection === SelectionType.NamedNetwork;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setSelectedDirection(
      Number((event.currentTarget as HTMLSelectElement).value) as Direction,
    );
    setAnchorEl(null);
  };

  const handleOnCloseSubnetMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSelectedSubnetType(
      (event.currentTarget as HTMLSelectElement).value.toString() as SubnetType,
    );
    setAnchorEl(null);
  };

  const handleAddToTemplateDialogOpen = (dialogVisibility: boolean) => {
    setShowAddToTemplateDialog(dialogVisibility);
  };

  const handleCloseAddTemplateDrawer = () => {
    setShowAddToTemplateDialog(false);
    onClose();
  };

  const directionFacet = facetState?.get("direction")?.keys();
  const directions = Array.from(directionFacet || []);
  const isDirectionInbound = directions.includes("inbound");
  const isDirectionOutbound = directions.includes("outbound");

  const [selectedDirection, setSelectedDirection] = useState(
    isDirectionInbound && !isDirectionOutbound
      ? Direction.Inbound
      : Direction.Outbound,
  );
  const [selectedSubnetType, setSelectedSubnetType] = useState(
    selection === SelectionType.PrivateIPs
      ? SubnetType.Subnet24
      : SubnetType.Subnet8,
  );

  useEffect(() => {
    if (
      selection === SelectionType.PrivateIPs ||
      selection === SelectionType.PublicIPs
    ) {
      setSelectedSubnetType(
        selection === SelectionType.PrivateIPs
          ? SubnetType.Subnet24
          : SubnetType.Subnet8,
      );
    }
  }, [selection]);

  const facetAssetCriteria = useCriteriaBuilder("", facetState, assetMetadata);
  const assetCriteria =
    facetAssetCriteria === "*" ? criteria : facetAssetCriteria;
  const {
    criteria: tbpCriteria,
    criteriaAsParams: tbpCriteriaAsParams,
    exhaustiveCriteria: exhaustiveTBPCriteria,
  } = useTBPCriteriaBuilder(facetState, tagPolicyMetadata);

  const assetAggMutation = useAssetAggregateAPI();
  const assetAggMutate = assetAggMutation.mutate;
  const {
    data: assetAgg,
    isLoading: isLoadingAssetAgg,
    reset: resetAggCount,
  } = assetAggMutation;

  const assetAggCount: number = Number(
    assetAgg?.items?.statistics?.assetidcount ?? 0,
  );

  useEffect(() => {
    if (!assetCriteria) {
      resetAggCount();
      return;
    }

    assetAggMutate({
      criteria: `${assetCriteria}`,
      scope: Scope.Asset,
      statistics: ["count(assetid)"],
    });
  }, [assetAggMutate, assetCriteria, resetAggCount, selectedDirection]);

  const { aggregates: pathAggregates, isLoading: isPathLoading } =
    usePathRecommendationsWithDataVolumeGroup({
      criteria: "*",
      destinationCriteria:
        selectedDirection === Direction.Inbound ? criteria : "*",
      sourceCriteria: selectedDirection === Direction.Outbound ? criteria : "*",
      enabled: selection === SelectionType.Template,
      direction: selectedDirection,
      total: assetAggCount,
    });

  const { subnets, isLoading: isIPsListLoading } =
    useSourceAndDestinationIPsList({
      criteria,
      subnet: selectedSubnetType,
      compassDirection:
        selection === SelectionType.PrivateIPs
          ? CompassDirection.EastWest
          : CompassDirection.NorthSouth,
      enabled:
        selection === SelectionType.PrivateIPs ||
        selection === SelectionType.PublicIPs,
      isDirectionInbound: directionFacet ? isDirectionInbound : true,
      isDirectionOutbound: directionFacet ? isDirectionOutbound : true,
      selection,
    });

  const assignNetworkToTags = useCallback(async () => {
    if (!tagBasedPolicyId) {
      return Promise.reject("policy id is missing");
    }
    interface NetworksToTags {
      namednetworks: Array<string>;
      comment: string | null;
    }

    let body: NetworksToTags = {
      namednetworks: [],
      comment: null,
    };

    body["namednetworks"] =
      pathsNNSelection.map(path => path.namedNetwork.namedNetworkId) ?? [];

    await assignNetworkToTagsMutation.mutateAsync(body, {
      onSuccess: response => {
        notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
          label: "NamedNetworkAssignmentSubmitted",
        });
        queryClient.invalidateQueries({
          queryKey: ["tagbasedpolicy"],
        });
        onClose();
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  }, [
    assignNetworkToTagsMutation,
    notify,
    onClose,
    pathsNNSelection,
    queryClient,
    setSnackbar,
    tagBasedPolicyId,
  ]);

  useEffect(() => {
    if (shouldCallAssignNetworkToTagsMutation) {
      assignNetworkToTags();
      setShouldCallAssignNetworkToTagsMutation(false);
    }
  }, [assignNetworkToTags, shouldCallAssignNetworkToTagsMutation]);

  const assignNetworkToAssets = async () => {
    if (assetCriteria === "*" || !assetCriteria) {
      return Promise.reject("asset crtieria is missing");
    }
    await assignNetworkToAssetsMutation.mutateAsync(
      {
        namedNetworks:
          pathsNNSelection.map(path => path.namedNetwork.namedNetworkId) ?? [],
        criteria: assetCriteria,
        comment: null,
      },
      {
        onSuccess: response => {
          notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
            label: "NamedNetworkAssignmentSubmitted",
          });
          queryClient.invalidateQueries({
            queryKey: ["asset"],
          });
          onClose();
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      },
    );
  };

  const confirmNamedNetworkChanges = async (buttonSelection?: string) => {
    if (
      page === PathRecommendationPageType.Asset ||
      buttonSelection === NAMED_NETWORK_ASSIGNMENT_ID.ASSIGN_TO_ASSETS
    ) {
      assignNetworkToAssets();
      return;
    }

    if (
      page === PathRecommendationPageType.Tags ||
      policyId ||
      tagBasedPolicyId
    ) {
      assignNetworkToTags();
      return;
    }

    if (!tbpCriteria || !tagPolicyMetadata) {
      return;
    }

    const newTagBasedPolicyId = await createTagBasedPolicyId({
      criteria: tbpCriteria,
      mutation: createTagPolicyAPI.mutateAsync,
      criteriaAsParams: tbpCriteriaAsParams,
    });

    setTagBasedPolicyId(newTagBasedPolicyId);
    setShouldCallAssignNetworkToTagsMutation(true);
  };

  const confirmIPChanges = async (buttonSelection?: string) => {
    if (
      !buttonSelection ||
      buttonSelection === NAMED_NETWORK_ASSIGNMENT_ID.CREATE_NEW_NAMED_NETWORK
    ) {
      setCreateNNDrawerVisibility(true);
      return;
    }

    setShowAssignNetworksDrawer(true);
  };

  const { data: nnAssignData, isLoading: nnAssignLoading } =
    useNNAssignRecommendation({
      criteria,
      enabled: selection === SelectionType.NamedNetwork,
      directions,
    });

  function handleUpdate(selection: SelectionType, buttonSelection?: string) {
    switch (selection) {
      case SelectionType.Template:
        handleAddToTemplateDialogOpen(true);
        break;
      case SelectionType.NamedNetwork:
        confirmNamedNetworkChanges(buttonSelection);

        break;
      case SelectionType.PrivateIPs:
      case SelectionType.PublicIPs:
        confirmIPChanges(buttonSelection);

        break;
    }
  }

  const onTabChange = (_: React.SyntheticEvent, value: any) => {
    setSelection(value);
    setPathsNNSelection([]);
    setPathsSelection([]);
    setPrivateIPsSelection([]);
    setPublicIPsSelection([]);
  };

  const CIDRList = useMemo(() => {
    if (
      privateIPsSelection.length > 0 &&
      selection === SelectionType.PrivateIPs
    ) {
      return privateIPsSelection;
    }
    if (
      publicIPsSelection.length > 0 &&
      selection === SelectionType.PublicIPs
    ) {
      return publicIPsSelection;
    }
    return [];
  }, [privateIPsSelection, publicIPsSelection, selection]);

  const renderActionButtons = () => {
    const getButtonOptions = () => {
      switch (selection) {
        case SelectionType.PrivateIPs:
        case SelectionType.PublicIPs:
          return PUBLIC_PRIVATE_IP_OPTIONS();
        case SelectionType.NamedNetwork:
          return NAMED_NETWORK_ASSIGNMENT_OPTIONS();
        default:
          return [];
      }
    };

    const buttonOptions = getButtonOptions();

    return (
      <>
        {buttonOptions.map((buttonOption, index) => {
          const isNotPublicPrivateIPs =
            selection !== SelectionType.PublicIPs &&
            selection !== SelectionType.PrivateIPs;

          const isLoading =
            isNotPublicPrivateIPs &&
            (index === 0
              ? assignNetworkToAssetsMutation.isLoading
              : assignNetworkToTagsMutation.isLoading ||
                createTagPolicyAPI.isLoading);

          const getIsDisabled = () => {
            if (!isValid) {
              return true;
            }

            if (
              buttonOption.id ===
                NAMED_NETWORK_ASSIGNMENT_ID.ASSIGN_TO_ASSETS &&
              facetAssetCriteria === "*"
            ) {
              return true;
            }

            if (
              buttonOption.id ===
                NAMED_NETWORK_ASSIGNMENT_ID.ASSIGN_TO_TAG_BASED_POLICY &&
              tbpCriteria === "*"
            ) {
              return true;
            }

            return false;
          };

          const getTooltipTitle = () => {
            if (
              buttonOption.id === NAMED_NETWORK_ASSIGNMENT_ID.ASSIGN_TO_ASSETS
            ) {
              return window.getCTTranslatedText(
                "Please apply Asset filters to assign to Assets",
              );
            }

            if (
              buttonOption.id ===
              NAMED_NETWORK_ASSIGNMENT_ID.ASSIGN_TO_TAG_BASED_POLICY
            ) {
              return window.getCTTranslatedText(
                "Please apply Tag filters to assign to Tag Based Policy",
              );
            }

            return "";
          };

          if (
            !buttonOption.permissions.every(permission =>
              userPermissions.has(permission),
            )
          ) {
            return null;
          }

          const loadingButton = (
            <LoadingButton
              key={buttonOption.id}
              loading={isLoading}
              onClick={() => handleUpdate(selection, buttonOption.id)}
              variant="contained"
              disabled={getIsDisabled()}
            >
              {window.getCTTranslatedText(buttonOption.label)}
            </LoadingButton>
          );

          const isDisabled = getIsDisabled();

          if (!isDisabled) {
            return loadingButton;
          }

          return (
            <Tooltip key={buttonOption.id} title={getTooltipTitle()}>
              <Box>{loadingButton}</Box>
            </Tooltip>
          );
        })}
      </>
    );
  };

  const isValid =
    pathsSelection.length > 0 ||
    pathsNNSelection.length > 0 ||
    privateIPsSelection.length > 0 ||
    publicIPsSelection.length > 0;

  const isNamedNetworkAssignment =
    selection === SelectionType.PrivateIPs ||
    selection === SelectionType.PublicIPs;

  const hasMultipleActionButtons =
    (isNamedNetworkAssignment || selection === SelectionType.NamedNetwork) &&
    !policyId &&
    page !== PathRecommendationPageType.Asset;

  const ipButtonLabel =
    selection === SelectionType.PrivateIPs
      ? SubnetDisplayNameType.Subnet24
      : SubnetDisplayNameType.Subnet8;

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ mt: 4, mb: 0, mx: 4 }}>
        <Typography variant="h5">
          <b>{window.getCTTranslatedText("Recommendations")}</b>
        </Typography>

        {page !== PathRecommendationPageType.Asset && !hidePreview && (
          <>
            <DisplayAppliedRule
              useFacetStore={useFacetStore}
              minRequired={1}
              sortByName={true}
              viewOnly
              metadata={assetMetadata}
            />

            <Stack direction={"row"}>
              <MatchedAssetCount
                total={assetAggCount ?? 0}
                isLoading={isLoadingAssetAgg}
              />
            </Stack>
          </>
        )}
      </Stack>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        {page !== PathRecommendationPageType.Asset &&
          isNNAssignmentWarningVisible && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {window.getCTTranslatedText("NamedNetworkAssignWarning")}
            </Alert>
          )}
        <>
          <Tabs
            value={selection}
            onChange={onTabChange}
            aria-label="change direction tabs"
            sx={{ position: "relative" }}
          >
            {page === PathRecommendationPageType.Paths && (
              <Tab
                value={SelectionType.Template}
                label={window.getCTTranslatedText(SelectionType.Template)}
              />
            )}
            <Tab
              value={SelectionType.NamedNetwork}
              label={window.getCTTranslatedText(SelectionType.NamedNetwork)}
            />
            <Tab
              value={SelectionType.PrivateIPs}
              label={window.getCTTranslatedText(SelectionType.PrivateIPs)}
              sx={{
                display:
                  page === PathRecommendationPageType.Paths ? "flex" : "none",
              }}
            />
            <Tab
              value={SelectionType.PublicIPs}
              label={window.getCTTranslatedText(SelectionType.PublicIPs)}
              sx={{
                display:
                  page === PathRecommendationPageType.Paths ? "flex" : "none",
              }}
            />

            {(selection === SelectionType.PublicIPs ||
              selection === SelectionType.PrivateIPs) && (
              <Stack
                width="100%"
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                sx={{ mb: 1.5 }}
              >
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {window.getCTTranslatedText(
                    selectedSubnetType === SubnetType.Subnet16
                      ? SubnetDisplayNameType.Subnet16
                      : ipButtonLabel,
                  )}
                </Button>
                <Menu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClick={() => setAnchorEl(null)}
                >
                  {selection === SelectionType.PublicIPs && (
                    <MenuItem
                      onClick={handleOnCloseSubnetMenu}
                      value={SubnetType.Subnet8}
                    >
                      {window.getCTTranslatedText(
                        SubnetDisplayNameType.Subnet8,
                      )}
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={handleOnCloseSubnetMenu}
                    value={SubnetType.Subnet16}
                  >
                    {window.getCTTranslatedText(SubnetDisplayNameType.Subnet16)}
                  </MenuItem>
                  {selection === SelectionType.PrivateIPs && (
                    <MenuItem
                      onClick={handleOnCloseSubnetMenu}
                      value={SubnetType.Subnet24}
                    >
                      {window.getCTTranslatedText(
                        SubnetDisplayNameType.Subnet24,
                      )}
                    </MenuItem>
                  )}
                </Menu>
              </Stack>
            )}

            {selection === SelectionType.Template && (
              <Stack
                width="100%"
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                sx={{ mb: 1.5 }}
              >
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {window.getCTTranslatedText(
                    selectedDirection === Direction.Inbound
                      ? "Inbound"
                      : "Outbound",
                  )}
                </Button>
                <Menu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClick={() => setAnchorEl(null)}
                >
                  <MenuItem
                    disabled={isDirectionOutbound && !isDirectionInbound}
                    onClick={handleClose}
                    value={Direction.Inbound}
                  >
                    {window.getCTTranslatedText("Inbound")}
                  </MenuItem>
                  <MenuItem
                    disabled={isDirectionInbound && !isDirectionOutbound}
                    onClick={handleClose}
                    value={Direction.Outbound}
                  >
                    {window.getCTTranslatedText("Outbound")}
                  </MenuItem>
                </Menu>
              </Stack>
            )}
          </Tabs>

          {selection === SelectionType.Template && (
            <PathRecommendations
              setPortSelection={setPathsSelection}
              isLoading={isPathLoading}
              data={pathAggregates ?? []}
              onClickSuggestion={() => {
                setSelection(SelectionType.NamedNetwork);
              }}
            />
          )}

          {selection === SelectionType.NamedNetwork && (
            <PathNamedNetworkRecommendations
              setPathSelection={setPathsNNSelection}
              isLoading={nnAssignLoading ?? false}
              data={nnAssignData ?? []}
              criteria={exhaustiveTBPCriteria}
              policyId={policyId}
              onFindTagBasedPolicyId={setTagBasedPolicyId}
            />
          )}

          {selection === SelectionType.PrivateIPs && (
            <IPsDataGrid
              key={selectedSubnetType}
              setIPsSelection={setPrivateIPsSelection}
              isLoading={isIPsListLoading ?? false}
              data={subnets ?? []}
              useStore={usePrivateNNCheckboxSelectionStore}
            />
          )}

          {selection === SelectionType.PublicIPs && (
            <IPsDataGrid
              key={selectedSubnetType}
              setIPsSelection={setPublicIPsSelection}
              isLoading={isIPsListLoading ?? false}
              data={subnets ?? []}
              useStore={usePublicNNCheckboxSelectionStore}
            />
          )}
        </>

        <AddToTemplateDrawer
          isOpen={showAddToTemplateDialog}
          page={"pathRules"}
          title="Add to Template"
          rules={pathsSelection.map(port => ({
            port: port.port,
            protocol: port.protocol,
            direction:
              selectedDirection === Direction.Inbound
                ? PathDirection.Inbound
                : PathDirection.Outbound,
            [`${
              selectedDirection === Direction.Outbound
                ? "destination"
                : "source"
            }NamedNetwork`]: port.namedNetwork,
          }))}
          showPortStatusOption={true}
          onCancel={handleCloseAddTemplateDrawer}
          onConfirm={handleCloseAddTemplateDrawer}
          btnTitle={"add"}
          actionType={TemplateActionType.add}
        />
        {createNNDrawerVisibility &&
          (selection === SelectionType.PrivateIPs ||
            selection === SelectionType.PublicIPs) && (
            <NetworkFormDrawer
              isOpen={createNNDrawerVisibility}
              onClose={() => setCreateNNDrawerVisibility(false)}
              updateNetworkData={() => console.log()}
              title="Create New Network"
              mode={"create"}
              btnTitle={"create"}
              cidrList={CIDRList}
            />
          )}
        <AssignNetworksDrawer
          isOpen={showAssignNetworksDrawer}
          page={"paths"}
          title={NAMED_NETWORK_ASSIGNMENT_ID.ADD_TO_NAMED_NETWORK}
          criteria={criteria}
          rules={CIDRList}
          onCancel={() => setShowAssignNetworksDrawer(false)}
          onConfirm={() => setShowAssignNetworksDrawer(false)}
          btnTitle={"assign"}
          hasPermission={userPermissions.has("UPDATE_NAMED_NETWORK")}
        />
      </DialogContent>

      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <PortRecommendationActions
          isValid={isValid}
          isLoading={
            assignNetworkToTagsMutation.isLoading ||
            assignNetworkToAssetsMutation.isLoading
          }
          update={(buttonSelection?: string) =>
            handleUpdate(selection, buttonSelection)
          }
          cancel={onClose}
          selection={selection}
          renderActionButtons={
            hasMultipleActionButtons ? renderActionButtons : undefined
          }
        />
      </DialogActions>
    </>
  );
};

export function useAssetAggregateAPI() {
  return useMutation<AnalyticsResponse, Error, AnalyticsAPIReq>([
    "asset-aggregate",
    "aggregate",
  ]);
}

function MatchedAssetCount({
  total,
  isLoading,
}: {
  total: number | string;
  isLoading: boolean;
}) {
  return (
    <Stack
      alignItems={"flex-start"}
      justifyContent={"center"}
      sx={{ minHeight: 20 }}
    >
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Typography variant="body2">
          {window.getCTTranslatedText("matchingEntity", {
            count: Number(total ?? 0),
            entity: pluralize(
              window.getCTTranslatedText("asset"),
              Number(total ?? 0),
            ),
          })}
        </Typography>
      )}
    </Stack>
  );
}

async function createTagBasedPolicyId({
  criteria,
  mutation,
  criteriaAsParams,
}: {
  criteria: string;
  mutation: UseMutateAsyncFunction<
    TagPolicy,
    Error,
    Omit<TagPolicy, "tagBasedPolicyId">,
    any
  >;
  criteriaAsParams: string;
}) {
  const execute = async () => {
    let resp = await mutation({
      criteria,
      criteriaAsParams,
    });
    return resp.tagBasedPolicyId;
  };

  return execute();
}
