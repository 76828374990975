import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { NetworkType } from "../network-type";
import { Provider } from "../provider";
import { RemoveNetworkDialog } from "../remove-network-dialog";
import { AssetCount } from "../asset-count";
import { NetworkAnnotations } from "../network-annotations";
import numeral from "numeral";
import { ActionNetworkDialog } from "../action-network-dialog";
import { UsedByTagBasedPolicy } from "../used-by-tag-based-policy";
import { GroupCount } from "../group-count";

export const BASE_NETWORKS_COLUMNS: GridColDef[] = [
  {
    field: "oobnetwork",
    headerName: "",
    width: 70,
    minWidth: 70,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <NetworkType network={params?.row}></NetworkType>;
    },
  },
  {
    field: "namedNetworkName",
    headerName: "Name",
    width: 160,
    minWidth: 160,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Tooltip title={params?.row?.namedNetworkName || ""}>
          <Typography variant="body2" noWrap>
            {params?.row?.namedNetworkName || ""}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "namedNetworkDescription",
    headerName: "Description",
    sortable: false,
    width: 160,
    minWidth: 160,
    flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Tooltip title={params?.row?.namedNetworkDescription || ""}>
          <Typography variant="body2" noWrap>
            {params?.row?.namedNetworkDescription || ""}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "provider",
    headerName: "Provider",
    width: 140,
    minWidth: 140,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <Provider network={params?.row}></Provider>;
    },
  },
  {
    field: "totalCount",
    headerName: "IP Count",
    sortable: false,
    width: 100,
    minWidth: 100,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.15 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Typography variant="body2" noWrap>
          {numeral(params?.row?.totalCount ?? 0).format("0a")}
        </Typography>
      );
    },
  },
  {
    field: "domain",
    headerName: "Domain",
    sortable: false,
    width: 160,
    minWidth: 160,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Tooltip title={params?.row?.domain || ""}>
          <Typography variant="body2" noWrap>
            {params?.row?.domain || ""}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "namedNetworkAssignments",
    headerName: "Used by assets",
    width: 120,
    minWidth: 120,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AssetCount network={params?.row} viewOnly={true} />;
    },
  },
  {
    field: "namednetworkTagBasedPolicyAssignments",
    headerName: "Used by policy",
    width: 120,
    minWidth: 120,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <UsedByTagBasedPolicy network={params?.row} viewOnly={true} />;
    },
  },
];

const COMMON_NETWORK_COLUMNS: GridColDef[] = [
  {
    field: "action",
    headerName: "Action",
    width: 100,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <ActionNetworkDialog network={params.row} />;
    },
  },
  {
    field: "comment",
    headerName: "",
    headerAlign: "left",
    align: "left",
    width: 50,
    minWidth: 50,
    flex: window.screen.availWidth > 1200 ? 0.1 : undefined,
    sortingOrder: ["desc", "asc"],
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <NetworkAnnotations network={params.row} />;
    },
  },
];

export const ENDPOINT_NETWORKS_COLUMNS: GridColDef[] = [
  ...BASE_NETWORKS_COLUMNS,
  {
    field: "ugnamedNetworkAssignments",
    headerName: "Used by User Groups",
    width: 120,
    minWidth: 120,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <GroupCount network={params?.row} />;
    },
  },
  ...COMMON_NETWORK_COLUMNS,
];

export const NETWORKS_COLUMNS: GridColDef[] = [
  ...BASE_NETWORKS_COLUMNS,
  ...COMMON_NETWORK_COLUMNS,
];

export const NETWORK_LIST_COLUMNS: GridColDef[] = [
  {
    field: "isOOBNetwork",
    headerName: "",
    width: 70,
    minWidth: 70,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <NetworkType network={params?.row} viewOnly={true} />;
    },
  },
  {
    field: "namedNetworkName",
    headerName: "Name",
    width: 160,
    minWidth: 160,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Tooltip title={params?.row?.namedNetworkName || ""}>
          <Typography variant="body2" noWrap>
            {params?.row?.namedNetworkName || ""}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "namedNetworkDescription",
    headerName: "Description",
    sortable: false,
    width: 180,
    minWidth: 180,
    flex: window.screen.availWidth > 1200 ? 0.6 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Tooltip title={params?.row?.namedNetworkDescription || ""}>
          <Typography variant="body2" noWrap>
            {params?.row?.namedNetworkDescription || ""}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "ugnamedNetworkAssignments",
    headerName: "Used by User Groups",
    width: 160,
    minWidth: 160,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.6 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <GroupCount network={params?.row} />;
    },
  },
  {
    field: "namedNetworkAssignments",
    headerName: "Used by assets",
    width: 160,
    minWidth: 160,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.6 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AssetCount network={params?.row} viewOnly={true} />;
    },
  },
  {
    field: "namednetworkTagBasedPolicyAssignments",
    headerName: "Used by policies",
    width: 160,
    minWidth: 160,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.6 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <UsedByTagBasedPolicy network={params?.row} viewOnly={true} />;
    },
  },
];

export const ASSET_NETWORK_COLUMNS: GridColDef[] = [
  ...NETWORK_LIST_COLUMNS,
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 70,
    minWidth: 70,
    flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <RemoveNetworkDialog network={params.row} />;
    },
  },
];

export const ASSIGNED_NETWORKS_TAG_COLUMNS: GridColDef[] = [
  ...NETWORK_LIST_COLUMNS,
];

const ColumnVisibilityModel: { [key: string]: boolean } = {
  // Hide columns from the display
  isOOBNetwork: false,
};

const PinnedColumns = {
  left: ["oobnetwork", "namedNetworkName"],
  right: ["action", "comment"],
};

export const NetworkColumnConfig = {
  ColumnVisibilityModel: ColumnVisibilityModel,
  PinnedColumns: PinnedColumns,
};
