import PendingActions from "@mui/icons-material/PendingActions";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { usePollPendingWorkRequest } from "modules/AppBar/hooks";
import { PendingWorkRequestsDrawer } from "modules/PendingWorkRequestsDrawer";
import { useWorkRequestStore } from "modules/PendingWorkRequestsDrawer/store/useWorkRequestStore";
import { useCallback, useEffect } from "react";

interface PendingWorkRequestsIconProps {
  resourceID: string;
}

export const PendingWorkRequestsIconImpl = ({
  resourceID,
}: PendingWorkRequestsIconProps) => {
  const { badgeConfig, stopPolling } = usePollPendingWorkRequest(resourceID);

  const isPendingWorkRequestsDrawerOpen = useWorkRequestStore(
    state => state.isOpen
  );
  const setIsPendingWorkRequestsDrawerOpen = useWorkRequestStore(
    state => state.setOpen
  );

  useEffect(() => {
    if (isPendingWorkRequestsDrawerOpen) {
      stopPolling();
    }
  }, [isPendingWorkRequestsDrawerOpen, stopPolling]);

  const onIconClick = useCallback(
    () => setIsPendingWorkRequestsDrawerOpen(true),
    [setIsPendingWorkRequestsDrawerOpen]
  );

  const onDrawerClose = useCallback(
    () => setIsPendingWorkRequestsDrawerOpen(false),
    [setIsPendingWorkRequestsDrawerOpen]
  );

  const { count } = badgeConfig;

  if (!count) {
    return null;
  }

  return (
    <>
      <Tooltip title={window.getCTTranslatedText("Background Processes")}>
        <IconButton color="primary" size="medium" onClick={onIconClick}>
          <PendingActions />
        </IconButton>
      </Tooltip>
      {isPendingWorkRequestsDrawerOpen && (
        <PendingWorkRequestsDrawer
          isOpen={isPendingWorkRequestsDrawerOpen}
          onClose={onDrawerClose}
          resourceID={resourceID}
        />
      )}
    </>
  );
};

export const PendingWorkRequestsIcon = ({
  resourceID,
}: PendingWorkRequestsIconProps) => {
  const { isFeatureEnabled: isWorkRequestEnabled } = useFeatureFlagControl(
    FEATURES.WORK_REQUEST
  );

  if (!isWorkRequestEnabled) {
    return null;
  }

  return (
    <Stack pr={2}>
      <PendingWorkRequestsIconImpl resourceID={resourceID} />
    </Stack>
  );
};
