import groupBy from "lodash/groupBy";
import { SelectionType } from "modules/recommendation-workflows/PathRecommendationDrawer";
import { MergedIPsForInboundAndOutBound } from "modules/recommendation-workflows/components/IPs-datagrid/IPsDataGrid";
import { Scope } from "modules/scope-metadata/types";
import { NIL } from "pages/paths/constants";
import { CompassDirection } from "pages/paths/types";
import { useAggregateAPI } from "pages/tags/hooks";
import { useEffect, useMemo } from "react";

enum SourceDestinationIPsOptionType {
  Source = "Source",
  Destination = "Destination",
}

export enum SubnetType {
  Subnet8 = "8",
  Subnet16 = "16",
  Subnet24 = "24",
}

export function useSourceAndDestinationIPsList({
  criteria,
  enabled,
  compassDirection,
  subnet = SubnetType.Subnet24,
  isDirectionInbound = true,
  isDirectionOutbound = true,
  selection,
}: {
  criteria?: string;
  type?: SourceDestinationIPsOptionType;
  enabled: boolean;
  compassDirection: CompassDirection;
  subnet: SubnetType;
  isDirectionInbound: boolean;
  isDirectionOutbound: boolean;
  selection: SelectionType;
}) {
  const { isLoading: isSrcLoading, IPsList: srcIPList } = useIPList({
    type: SourceDestinationIPsOptionType.Source,
    criteria,
    enabled: enabled && isDirectionInbound,
    compassDirection,
    selection,
  });
  const { isLoading: isDstLoading, IPsList: dstIPList } = useIPList({
    type: SourceDestinationIPsOptionType.Destination,
    criteria,
    enabled: enabled && isDirectionOutbound,
    compassDirection,
    selection,
  });

  const isLoading = isSrcLoading || isDstLoading;

  const subnets = useMemo(() => {
    const mergedMap: {
      [key: string]: MergedIPsForInboundAndOutBound;
    } = {};

    srcIPList.list.forEach((item, index) => {
      mergedMap[item.IPs] = {
        IPCoverage: 0,
        IP: item.IPs,
        inboundCount: item.count,
        inboundCoverage: item.coverage,
        outboundCount: 0,
        outboundCoverage: 0,
        index: index,
        domain: item.domain,
      };
    });

    dstIPList.list?.forEach((item, index) => {
      if (!mergedMap[item.IPs]) {
        mergedMap[item.IPs] = {
          IPCoverage: 0,
          IP: item.IPs,
          inboundCount: 0,
          inboundCoverage: 0,
          outboundCount: item.count,
          outboundCoverage: item.coverage,
          index: index,
          domain: item.domain,
        };
      } else {
        mergedMap[item.IPs].outboundCoverage = item.coverage;
        mergedMap[item.IPs].outboundCount = item.count;
      }
    });

    const IPsList = Object.values(mergedMap);

    const transformedData: MergedIPsForInboundAndOutBound[] = IPsList.map(
      aggValue => ({
        ...aggValue,
        inboundCoverage: Number((aggValue.inboundCount / 1).toFixed(2)),
        outboundCoverage: Number((aggValue.outboundCount / 1).toFixed(2)),
      })
    );

    let transformedDataWithSubnet = transformedData.map(ip => {
      return { ...ip, subnet: convertIPsToSubnetCIDR([ip.IP], subnet)?.[0] };
    });

    let newList = groupBy(transformedDataWithSubnet, "subnet");
    let subnetData: MergedIPsForInboundAndOutBound[] = [];

    Object.keys(newList).forEach((subnet, index) => {
      let totalIps = calculateTotalIPs(subnet) ?? 0;
      let ipList = newList[subnet];
      subnetData.push({
        IP: subnet,
        subnet: subnet,
        inboundCount: ipList.reduceRight(
          (prev, curr) => prev + curr.inboundCount,
          0
        ),
        inboundCoverage: ipList.reduceRight(
          (prev, curr) => prev + curr.inboundCoverage,
          0
        ),
        outboundCount: ipList.reduceRight(
          (prev, curr) => prev + curr.outboundCount,
          0
        ),
        outboundCoverage: ipList.reduceRight(
          (prev, curr) => prev + curr.outboundCoverage,
          0
        ),
        index,
        IPS: ipList,
        IPCount: ipList?.length ?? 0,
        IPCoverage: (ipList?.length ?? 0) / totalIps,
        path: [`${subnet}`],
        domain: null,
      });

      ipList.forEach(ip => {
        ip.path = [`${subnet}`, `${ip.IP}`];
      });

      subnetData.push(...ipList);
    });

    return subnetData.sort((a, b) => b.IPCoverage - a.IPCoverage);
  }, [srcIPList, dstIPList, subnet]);

  return { subnets, isLoading };
}

function useIPList({
  type,
  criteria,
  enabled,
  compassDirection,
  selection,
}: {
  type: SourceDestinationIPsOptionType;
  compassDirection: CompassDirection;
  criteria?: string;
  enabled: boolean;
  selection: SelectionType;
}) {
  const { mutate, data, isLoading } = useAggregateAPI();
  useEffect(() => {
    let hasSourceDest = Boolean(type);

    if (!hasSourceDest || !enabled) {
      return;
    }
    const direction =
      type === SourceDestinationIPsOptionType.Source ? "inbound" : "outbound";
    const compass = `'compassdirection' in ('${compassDirection}')`;

    const PRIVATE_IPS_CRITERIA = "namednetworkid=null";
    const PUBLIC_IPS_CRITERIA = "namednetworkid=null AND assetid=null";

    const getSourceCriteria = () => {
      if (type === SourceDestinationIPsOptionType.Destination) {
        return criteria;
      } else {
        return selection === SelectionType.PrivateIPs
          ? PRIVATE_IPS_CRITERIA
          : PUBLIC_IPS_CRITERIA;
      }
    };

    const getDestinationCriteria = () => {
      if (type === SourceDestinationIPsOptionType.Source) {
        return criteria;
      } else {
        return selection === SelectionType.PrivateIPs
          ? PRIVATE_IPS_CRITERIA
          : PUBLIC_IPS_CRITERIA;
      }
    };

    mutate({
      criteria: `${compass} and direction=${direction}`,
      sourceCriteria: getSourceCriteria(),
      destinationCriteria: getDestinationCriteria(),
      scope: Scope.Path,
      groupBy: [
        type === SourceDestinationIPsOptionType.Source ? "srcip" : "dstip",
        "domain",
      ],
      statistics: [
        `distinctcount(${
          type === SourceDestinationIPsOptionType.Destination
            ? "source."
            : "destination."
        }assetid)`,
      ],
    });
  }, [compassDirection, criteria, enabled, mutate, type, selection]);

  const IPsList = useMemo(() => {
    let result: {
      IPs: string;
      count: number;
      index: number;
      coverage: number;
      domain: string | null;
    }[] = [];
    let index = 0;
    let total = 0;
    for (const key in data?.items) {
      const domains = data.items[key]?.["domain"];

      for (const domainKey in domains) {
        const count =
          domains?.[domainKey]?.statistics?.["sourceassetiddistinctcount"] ??
          domains?.[domainKey]?.statistics?.[
            "destinationassetiddistinctcount"
          ] ??
          0;

        result.push({
          IPs: key.replace("/32", ""),
          count: count,
          index: index,
          coverage: 0,
          domain: domainKey === NIL ? null : domainKey,
        });
        index++;
        total += count;
      }
    }

    result.forEach(aggValue => {
      if (aggValue.count && total) {
        aggValue.coverage = Number((aggValue.count / total).toFixed(2));
      }
    });

    result.sort((a, b) => b.coverage - a.coverage);

    return { list: result, total };
  }, [data]);

  return {
    isLoading,
    IPsList,
  };
}

export function convertIPsToSubnetCIDR(
  ips: string[],
  subnet: SubnetType = SubnetType.Subnet24
) {
  const subnetCIDRs = new Set<string>();

  ips.forEach(ip => {
    if (ip.includes(":")) {
      const parts = ip.split(":");
      if (ip.endsWith("/64")) {
        subnetCIDRs.add(ip);
      } else if (parts.length >= 4) {
        const subnetCIDR = parts.slice(0, 4).join(":") + "/64";
        subnetCIDRs.add(subnetCIDR);
      }
    } else {
      if (
        ip.endsWith("/16") ||
        ip === "0.0.0.0" ||
        ip.endsWith("/24" || ip.endsWith("/8"))
      ) {
        subnetCIDRs.add(ip);
      } else {
        const parts = ip.split(".");
        if (parts.length === 4) {
          if (subnet === SubnetType.Subnet24) {
            parts[3] = "0/24";
          } else if (subnet === SubnetType.Subnet16) {
            parts[2] = "0";
            parts[3] = "0/16";
          } else if (subnet === SubnetType.Subnet8) {
            parts[1] = "0";
            parts[2] = "0";
            parts[3] = "0/8";
          }
          const subnetCIDR = parts.join(".");
          subnetCIDRs.add(subnetCIDR);
        }
      }
    }
  });

  return Array.from(subnetCIDRs);
}

function calculateTotalIPs(cidrNotation: string) {
  const [ip, _prefixLength] = cidrNotation.split("/");

  let prefixLength = parseInt(_prefixLength);
  if (ip.includes(":")) {
    // It's an IPv6 CIDR notation
    const totalIPs = Math.pow(2, 128 - prefixLength);
    return totalIPs;
  } else {
    // It's an IPv4 CIDR notation
    const totalIPs = Math.pow(2, 32 - prefixLength);
    return totalIPs;
  }
}
