import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { IDP_MODE } from "../IDP-Form/IDPForm";
import { useIDPStore } from "../store";
import { IDPInfoDrawer } from "./../IDP-Info-Drawer";

export function IDPToolbar() {
  const requestAPIRefresh = useIDPStore(store => store.requestAPIRefresh);
  const [openDrawer, setDrawer] = useState(false);

  const handleDrawer = () => {
    setDrawer(true);
  };

  const onClose = () => {
    setDrawer(false);
    requestAPIRefresh();
  };

  return (
    <Stack direction="row" sx={{ width: "100%", mb: 1 }}>
      <Stack flex={1} alignItems="center" direction={"row"}>
        <Typography variant="subtitle2">
          {window.getCTTranslatedText("bearerTokens")}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Stack justifyContent={"flex-end"}>
          <Tooltip title={window.getCTTranslatedText("IdP")}>
            <Button
              variant="text"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => handleDrawer()}
            >
              {window.getCTTranslatedText("IdP")}
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      {openDrawer && (
        <IDPInfoDrawer
          isOpen={openDrawer}
          title={window.getCTTranslatedText("Add IdP")}
          mode={IDP_MODE.CREATE}
          onCancel={onClose}
        />
      )}
    </Stack>
  );
}
