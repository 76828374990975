import { Typography, Stack, Tooltip, Link } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { VulnerabilityReadOnlyBody } from "modules/vulnerability-drawer/types";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

export const VULNERABILITY_COLUMNS: GridColDef[] = [
  {
    field: "cveID",
    headerName: "CVE ID",
    minWidth: 150,
    flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <CVEIDCell vulnerability={params.row} />;
    },
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 250,
    flex: window.screen.availWidth > 1200 ? 1 : undefined,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Tooltip title={params.row.description}>
          <Typography variant="body2">{params.row.description}</Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "product",
    headerName: "Product",
    minWidth: 150,
    flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Tooltip
          title={`(${params.row.vendor}) ${params.row.product} ${params.row.version}`}
        >
          <Typography variant="body2">
            {params.row.product} {params.row.version}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "cvssscore",
    headerName: "CVSS Score",
    minWidth: 100,
    flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <Typography variant="body2">{params.row.cvssscore}</Typography>;
    },
  },
  {
    field: "severity",
    headerName: "Severity",
    minWidth: 100,
    flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <SeverityCell vulnerability={params.row} />;
    },
  },
  {
    field: "exploitURL",
    headerName: "Exploit URL",
    minWidth: 100,
    align: "center",
    flex: window.screen.availWidth > 1200 ? 0.1 : undefined,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <ExploitCell vulnerability={params.row} />;
    },
  },
];

const CVEIDCell = ({ vulnerability }: VulnerabilityReadOnlyBody) => {
  return (
    <Link
      underline="none"
      component={"a"}
      rel="noreferrer"
      target="_blank"
      href={`https://nvd.nist.gov/vuln/detail/${vulnerability.cveID}`}
      display="flex"
    >
      <Typography variant="body2">{vulnerability.cveID}</Typography>
      <OpenInNewIcon sx={{ ml: 1 }} color="action" fontSize="small" />
    </Link>
  );
};

const ExploitCell = ({ vulnerability }: VulnerabilityReadOnlyBody) => {
  return (
    <>
      {vulnerability.exploitURL ? (
        <Link
          underline="none"
          component={"a"}
          color="inherit"
          target="_blank"
          href={vulnerability.exploitURL}
        >
          <OpenInNewIcon color="action" fontSize="small" />
        </Link>
      ) : null}
    </>
  );
};

const CriticalArrow = () => {
  return (
    <ArrowUpwardIcon
      fontSize="small"
      color="error"
      sx={{ marginRight: "4px" }}
    />
  );
};

const HighArrow = () => {
  return (
    <KeyboardDoubleArrowUpIcon
      fontSize="small"
      color="warning"
      sx={{ marginRight: "4px" }}
    />
  );
};

const SeverityCell = ({ vulnerability }: VulnerabilityReadOnlyBody) => {
  switch (vulnerability.severity) {
    case 3:
      return (
        <Stack sx={{ mr: 2 }} direction="row" alignItems="center">
          <HighArrow />
          <Typography variant="overline">
            {window.getCTTranslatedText("High")}
          </Typography>
        </Stack>
      );

    case 4:
      return (
        <Stack sx={{ mr: 2 }} direction="row" alignItems="center">
          <HighArrow />
          <Typography variant="overline">
            {window.getCTTranslatedText("High")}
          </Typography>
        </Stack>
      );

    case 5:
      return (
        <Stack sx={{ mr: 2 }} direction="row" alignItems="center">
          <CriticalArrow />
          <Typography variant="overline">
            {window.getCTTranslatedText("Critical")}
          </Typography>
        </Stack>
      );

    default:
      return null;
  }
};
