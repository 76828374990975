import { Box, Stack } from "@mui/material";
import { useCommonStore } from "common/store";
import { PathColumnRenderProps } from "pages/paths/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function PathService({
  path,
  viewOnly,
  useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
  if (!path.channelHash) {
    return <Box />;
  }

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        {Boolean(path?.portName) && (
          <CTDatagridButton
            isDisabled={viewOnly === true || !path.portName}
            asText={viewOnly === true}
            useFacetStore={useFacetStore}
            facetName={"portname"}
            facetOption={path.portName ?? ""}
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        )}
      </Stack>
    </Box>
  );
}
