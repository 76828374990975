import { Box, Button } from "@mui/material";
import { useAssignedTemplatesTags } from "./hooks";
import { useTagTemplates } from "../../hooks";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { AssignedTemplatesViewDrawer } from "modules/asset-templates/assigned-templates-view-drawer";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";

export function AssignedTemplatesTags({
  criteria,
  multiselect = false,
  policyId,
  open,
  onClose,
}: {
  criteria?: string;
  multiselect?: boolean;
  policyId?: string;
  open: boolean;
  onClose: () => void;
}) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const { templateList, isLoading, onSortChange, onSearchCriteriaChange } =
    useAssignedTemplatesTags({
      criteria,
      policyId,
    });

  const {
    showAddToTemplateDrawer,
    openAddToTemplateDrawer,
    closeAddToTemplateDrawer,
  } = useTagTemplates();

  if (!criteria) {
    return <Box style={{ width: 42 }} />;
  }

  return (
    <>
      {showAddToTemplateDrawer && (
        <AddToTemplateDrawer
          isOpen={showAddToTemplateDrawer}
          page={"tags"}
          title="Assign Template"
          rules={[]}
          onCancel={closeAddToTemplateDrawer}
          onConfirm={closeAddToTemplateDrawer}
          btnTitle={"assign"}
          criteria={criteria}
          multiselect={multiselect}
          policyId={policyId}
          hasPermission={userPermissions.has("UPDATE_TAGBASEDPOLICY")}
          actionType={TemplateActionType.assign}
        />
      )}

      {open && (
        <AssignedTemplatesViewDrawer
          isOpen={open}
          onClose={onClose}
          templateList={templateList}
          templatesSuggestMutation={undefined}
          isLoading={isLoading}
          AssignTemplatesOption={() =>
            AssignTemplatesOption({
              openAddToTemplateDrawer,
              hasPermission: userPermissions.has("UPDATE_TAGBASEDPOLICY"),
            })
          }
          page={"tags"}
          policyId={policyId}
          onSortChange={onSortChange}
          multiselect={true}
          hasPermission={userPermissions.has("UPDATE_TAGBASEDPOLICY")}
          showSearch={true}
          onSearchCriteriaChange={onSearchCriteriaChange}
        />
      )}
    </>
  );
}

export const AssignTemplatesOption = ({
  openAddToTemplateDrawer,
  hasPermission,
}: {
  openAddToTemplateDrawer: () => void;
  hasPermission?: boolean;
}) => {
  return (
    <>
      {hasPermission ? (
        <Button
          variant="contained"
          color="primary"
          onClick={openAddToTemplateDrawer}
          sx={{
            minWidth: "max-content",
          }}
        >
          {window.getCTTranslatedText("Assign Templates")}
        </Button>
      ) : null}
    </>
  );
};
