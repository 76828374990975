import AutorenewIcon from "@mui/icons-material/Autorenew";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Stack } from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";
import { getWorkRequestStatusDisplayText } from "modules/AppBar/helpers";
import { WorkRequestStatus } from "modules/AppBar/types";
import { PendingWorkRequestsColumnRenderProps } from "modules/PendingWorkRequestsDrawer/types";
export function PendingWorkRequestStatus({
  pendingItems,
}: PendingWorkRequestsColumnRenderProps) {
  if (!pendingItems.status) {
    return <Box style={{ minWidth: 310, minHeight: 45 }} />;
  }

  const status = window.getCTTranslatedText(
    getWorkRequestStatusDisplayText(pendingItems.status)
  );

  return (
    <Stack
      sx={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
      alignItems="center"
      justifyContent="left"
      direction="row"
    >
      {pendingItems.status === WorkRequestStatus.Completed && (
        <CheckCircleIcon fontSize="small" color="success" />
      )}
      {pendingItems.status === WorkRequestStatus.Cancelled && (
        <ErrorIcon fontSize="small" color="error" />
      )}
      {(pendingItems.status === WorkRequestStatus.InProgress ||
        pendingItems.status === WorkRequestStatus.Pending) && (
        <AutorenewIcon fontSize="small" color="info" />
      )}
      <DataGridButton variant={"text"} color={"inherit"} asText>
        {status}
      </DataGridButton>
    </Stack>
  );
}
