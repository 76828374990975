import { useMutation } from "@tanstack/react-query";
import { useDepKey } from "./useDepKey";

export function useClusterIdValidation(clusterId: string) {
  const path = `collector-validate/${clusterId}`;
  const { depKey } = useDepKey();
  return useMutation<any, Error, any>([
    "collector-validate",
    path,
    "post",
    {
      headers: {
        Authorization: `Bearer ${depKey}`,
      },
    },
  ]);
}
