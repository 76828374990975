import { CommonColors } from "@mui/material";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import merge from "lodash/merge";

declare module "@mui/material/styles" {
  interface CustomCommonColors extends CommonColors {
    lightGrey?: string;
    sidebarLight?: string;
    tableBdrClrDark?: string;
  }

  interface Palette {
    custom: { [key: string]: string };
  }
  interface PaletteOptions {
    custom: { [key: string]: string };
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    custom: {
      lightGrey: "#333333",
      sliderBg: "#C9C9C9",
      lightGreyBg: "#f9f9f9",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightRegular: 500,
    fontWeightLight: 400,
    subtitle1: {
      fontWeight: 500,
    },
    body2: {
      fontWeight: 400,
    },
    button: {
      textTransform: "capitalize",
    },
  },
  spacing: 6,
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        dense: {
          paddingY: 0,
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          marginRight: 4,
        },
        root: {
          borderRadius: 30,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiToolbar: {
      defaultProps: {},
    },
    MuiChip: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTab: {
      defaultProps: {
        sx: {
          textTransform: "capitalize !important",
          minWidth: 0,
          minHeight: 42,
          px: 2,
          py: 0,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        sx: {
          minHeight: 0,
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        sx: {
          backgroundColor: theme =>
            theme.palette.mode === "dark" ? "#232323" : "#f9f9f9",
        },
      },
    },
  },
};

export const createCTTheme = (extraOptions?: ThemeOptions) => {
  return createTheme(merge({}, themeOptions, extraOptions));
};

export const defaultCTTheme = createCTTheme();
