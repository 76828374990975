import { Stack, Typography } from "@mui/material";
import { getLocalDate } from "common/utils/getDate";
import { AlertsTimeColumnRenderProps } from "pages/monitoring/types";
import React from "react";

export const AlertTime = React.forwardRef<any, AlertsTimeColumnRenderProps>(
  (props, ref) => {
    const { alert, firstSeen } = props;
    const time = firstSeen
      ? getLocalDate(alert?.firstSeen)
      : getLocalDate(alert?.lastSeen);

    return (
      <Stack
        {...props}
        ref={ref}
        alignItems="left"
        sx={{
          height: "100%",
          justifyContent: "space-evenly",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography variant="body2" noWrap>
          {time}
        </Typography>
      </Stack>
    );
  }
);
