import { KeyboardArrowDown } from "@mui/icons-material";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Stack, Tooltip, useTheme } from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";
import { getColor } from "common/constants/colors";

import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { SecurityLevels } from "pages/assets/types";

interface SingleSecurityLevelTextButtonProps {
  status: SecurityLevels;
  onClickCallback: (text: SecurityLevels) => void;
  category: string;
  viewOnly?: boolean;
}

export function SingleSecurityLevelTextButton({
  status,
  onClickCallback,
  category,
  viewOnly,
}: SingleSecurityLevelTextButtonProps) {
  const theme = useTheme();
  let icon = (
    <ChangeHistoryIcon
      color="inherit"
      style={{ fontSize: 14, marginLeft: 2 }}
    />
  );
  let text = status;

  switch (text) {
    case SecurityLevels.Critical:
      icon = (
        <PriorityHighIcon
          style={{ color: getColor(SecurityLevels.Critical) }}
        />
      );
      break;
    case SecurityLevels.High:
      icon = (
        <KeyboardDoubleArrowUpIcon
          style={{ color: getColor(SecurityLevels.High) }}
        />
      );
      break;
    case SecurityLevels.Medium:
      icon = (
        <KeyboardArrowUpIcon
          style={{ color: getColor(SecurityLevels.Medium) }}
        />
      );
      break;
    case SecurityLevels.Low:
      icon = (
        <KeyboardArrowDown style={{ color: getColor(SecurityLevels.Low) }} />
      );
      break;
  }
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      borderRadius={1}
      sx={{
        width: "100%",
        maxWidth: "120px",
        backgroundColor: getColor(`${text}bg`),
      }}
    >
      <Tooltip
        title={window.getCTTranslatedText(
          "singleSecurityLevelButtonTooltipText",
          {
            category: window.getCTTranslatedText(category),
            status: window.getCTTranslatedText(capitalizeFirstLetter(text)),
          }
        )}
      >
        <DataGridButton
          disabled={viewOnly}
          asText={viewOnly}
          onClick={() => onClickCallback(text)}
          startIcon={icon}
          variant={"text"}
          sx={{
            px: 2,
            borderRadius: 1,
            width: "100%",
            height: "50px",
            maxWidth: "110px",
            color: theme.palette.text.primary,
            fontSize: 16,
            textTransform: "uppercase",
          }}
        >
          {window.getCTTranslatedText(text)}
        </DataGridButton>
      </Tooltip>
    </Stack>
  );
}
