import { Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { FacetControllerGroup } from "modules/facets";
import { ApplianceDataGrid } from "./components/appliance-data-grid";
import { FACET_GROUP_INFO } from "./constants";
import { useCore } from "modules/core";
import { Scope } from "modules/scope-metadata/types";
import { useAppliancesAPI } from "./components/appliance-data-grid/hooks";
import { useApplianceStore } from "./store";
import { Appliance, HaRole } from "./types";
import { useSearchStore } from "modules/search/store";
import { useEffect, useMemo } from "react";
import {
  compareTimestamps,
  mapAppliance,
} from "./components/appliance-data-grid/helpers/columnHelpers";

export const Appliances = () => {
  const theme = useTheme();

  const coreResponse = useCore<Appliance>({
    useStore: useApplianceStore,
    facetGroupInfo: FACET_GROUP_INFO,
    scope: Scope.Appliance,
    defaultSortOrder: [{ field: "lastCheckIn", order: "desc" }],
    useApi: useAppliancesAPI,
    dataMapper: appliance => {
      mapAppliance(appliance);
      return appliance;
    },
    pageSize: 100,
    isNewCsvAPIVersion: true,
  });

  const setSearch = useSearchStore(state => state.setSearch);

  useEffect(() => {
    setSearch("");
    return () => {
      setSearch("");
    };
  }, [setSearch]);

  const updatedRows: Array<Appliance> = useMemo(() => {
    const items: Array<Appliance> = coreResponse?.rows ?? [];
    const updatedApplianceData: Array<Appliance> = [];
    const processedVirtualIds = new Set<string>();

    const handleNullHaRole = (item: Appliance) => {
      item.applianceName = [item.agentName];
    };

    const handlePrimaryHaRole = (item: Appliance) => {
      if (item?.virtualId && !processedVirtualIds.has(item?.virtualId)) {
        processedVirtualIds.add(item?.virtualId);
        item.applianceName = [item.agentName];
      } else {
        const matchPrimary = items.find(
          nItem =>
            item.virtualId === nItem.virtualId &&
            nItem.haRole === HaRole.Primary
        );

        if (matchPrimary) {
          const isMatchedParent = compareTimestamps(
            matchPrimary.lastCheckIn,
            item.lastCheckIn
          );

          if (isMatchedParent) {
            item.applianceName = [matchPrimary.agentName, item.agentName];
          } else {
            matchPrimary.applianceName = [
              item.agentName,
              matchPrimary.agentName,
            ];

            item.applianceName = [item.agentName];
          }
        }
      }
    };

    const handleStandbyHaRole = (item: Appliance) => {
      const match = items.find(
        nItem =>
          item.virtualId === nItem.virtualId && nItem.haRole === HaRole.Primary
      );
      if (match) {
        item.applianceName = [match.agentName, item.agentName];
      } else {
        item.applianceName = [item.agentName];
      }
    };

    for (const item of items) {
      if (item.haRole === null) {
        handleNullHaRole(item);
      } else if (item.haRole === HaRole.Primary && item.virtualId !== null) {
        handlePrimaryHaRole(item);
      } else if (item.haRole === HaRole.Standby && item.virtualId !== null) {
        handleStandbyHaRole(item);
      }

      updatedApplianceData.push(item);
    }

    return updatedApplianceData;
  }, [coreResponse?.rows]);

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction="row">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack>

      <Paper
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          flex: 1,
          overflow: "hidden",
        }}
      >
        <ApplianceDataGrid
          rows={updatedRows}
          rowCount={coreResponse.rowCount}
          mutation={coreResponse.mutation}
          metadata={coreResponse.metadata}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={coreResponse.onSortChange}
          triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
          getExportStatus={coreResponse?.getExportStatus}
          getUrlToDownload={coreResponse?.getUrlToDownload}
          resetDownloadUrl={coreResponse?.resetDownloadUrl}
          rawData={coreResponse?.rawData}
        />
      </Paper>
    </Stack>
  );
};
