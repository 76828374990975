import { Autocomplete, Chip, FormControl, TextField } from "@mui/material";
import React, { useState } from "react";

export interface Option {
  columnName: string;
  columnLabel: string;
  childAttributes?: Option[] | null;
}

export interface CTMultiSelectDropDownProps {
  data: Option[] | [];
  field: string;
  displayName: string;
  selectedValue: string[];
  handleUpdate: Function;
  required?: boolean;
  disabled?: boolean;
  textTransform?: string;
  multi?: boolean;
  maxValueSelect?: number;
  helperText?: string;
}

export function CTMultiSelectDropDown({
  field,
  displayName,
  selectedValue = [],
  handleUpdate,
  data,
  required,
  disabled,
  textTransform,
  multi = false,
  maxValueSelect,
  helperText,
}: CTMultiSelectDropDownProps) {
  const [inputValue, setInputValue] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>(selectedValue);

  const generateOutputJson = (
    data: Option[],
    parentLabel = "",
    parentName = ""
  ): Option[] => {
    const outputJson: Option[] = [];

    for (const item of data) {
      if (!item) {
        continue;
      }

      const { columnLabel, columnName, childAttributes } = item;
      const currentLabel = parentLabel
        ? `${parentLabel} > ${columnLabel}`
        : columnLabel;
      const currentName = parentName
        ? `${parentName} > ${columnName}`
        : columnName;

      if (childAttributes && childAttributes.length > 0) {
        const childOutput: Option[] = generateOutputJson(
          childAttributes,
          currentLabel,
          currentName
        );
        outputJson.push(...childOutput);
      } else {
        outputJson.push({
          columnLabel: currentLabel,
          columnName: currentName,
          childAttributes: null,
        });
      }
    }

    return outputJson;
  };

  const processData = generateOutputJson(data);
  const handleSelectedValueChange = (
    _event: React.SyntheticEvent,
    newValue: string | string[] | null
  ) => {
    let selectedValues = Array.isArray(newValue) ? newValue : [];
    selectedValues = selectedValues.filter(option =>
      processData.some(dataItem => dataItem?.columnName === option)
    );
    if (maxValueSelect && selectedValues.length > maxValueSelect) {
      selectedValues = selectedValues.slice(0, maxValueSelect);
    }
    setSelectedValues(selectedValues);
    handleUpdate({ field, value: selectedValues });
  };
  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  return (
    <FormControl variant="standard" sx={{ width: "100%" }}>
      <Autocomplete
        multiple={multi}
        id={field}
        value={selectedValues}
        onChange={handleSelectedValueChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        options={(processData ?? []).map(option => option?.columnName ?? "")}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            helperText={window.getCTTranslatedText(helperText ?? "")}
            label={window.getCTTranslatedText(displayName)}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option}>
            {window.getCTTranslatedText(
              processData?.find(dataItem => dataItem?.columnName === option)
                ?.columnLabel ?? ""
            )}
          </li>
        )}
        renderTags={(value: string[], getTagProps) =>
          value
            .filter(option =>
              processData.some(dataItem => dataItem?.columnName === option)
            )
            .map(
              option =>
                processData.length > 0 && (
                  <Chip
                    label={window.getCTTranslatedText(
                      processData.find(
                        dataItem => dataItem?.columnName === option
                      )?.columnLabel ?? ""
                    )}
                    {...getTagProps({ index: value.indexOf(option) })}
                  />
                )
            )
        }
      />
    </FormControl>
  );
}
