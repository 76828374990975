import { ScopeMetadata } from "modules/scope-metadata/types";

interface GetFilteredMetadataProps {
  columns: Array<any>;
  metadata: ScopeMetadata | undefined;
}

export const getFilteredMetadata = ({
  columns,
  metadata,
}: GetFilteredMetadataProps) => {
  if (metadata) {
    columns = columns.filter((column: any) => {
      const key = column?.name ?? (column as string);
      return metadata?.columns[key];
    });
  }
  return columns;
};
