import { Stack, Tooltip } from "@mui/material";

import { AlertsColumnRenderProps } from "pages/monitoring/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export const AlertStatus = ({ alert, viewOnly }: AlertsColumnRenderProps) => {
  const typeFacetName = "alertstatus";
  const typeOptionName = alert?.status?.toString();

  const typeTitle = alert.status;

  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "space-evenly",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Stack direction="row">
        <Tooltip title={`${window.getCTTranslatedText(typeTitle)}`}>
          <CTDatagridButton
            asText={viewOnly}
            isDisabled={viewOnly}
            facetName={typeFacetName}
            facetOption={typeOptionName}
            stopPropagation={true}
          >
            {window.getCTTranslatedText(typeTitle)}
          </CTDatagridButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
