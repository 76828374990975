import { Stack, Tab } from "@mui/material";
import { CtTabs } from "common/atoms/ct-tabs";
import { ApplianceActionsMenu } from "pages/appliances/components/appliance-actions-menu";
import { Appliance } from "pages/appliances/types";

interface ApplianceToolbarProps {
  selectedTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  appliance: Appliance;
}

export const ApplianceToolbar = ({
  selectedTab,
  handleTabChange,
  appliance,
}: Readonly<ApplianceToolbarProps>) => {
  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      <Stack flex={1}>
        <CtTabs value={selectedTab} onChange={handleTabChange}>
          <Tab label={window.getCTTranslatedText("Summary")} />
          <Tab label={window.getCTTranslatedText("devices")} />
          <Tab label={window.getCTTranslatedText("UnmanagedDevices")} />
        </CtTabs>
      </Stack>
      <Stack>
        <ApplianceActionsMenu
          agent={appliance}
          triggerButtonText={window.getCTTranslatedText("Actions")}
        />
      </Stack>
    </Stack>
  );
};
