import {
  TemplateColumnRenderProps,
  TemplateType as templateType,
} from "pages/templates/types";
import { Stack, Tooltip } from "@mui/material";
import { TemplateTypeOptionsText } from "pages/templates/constants";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function TemplateType({
  template,
  viewOnly,
}: TemplateColumnRenderProps) {
  const type = template?.templateType ?? templateType.ApplicationTemplate;

  const facetName = "templatetype";
  const optionName = type?.toString() || "";

  const title =
    TemplateTypeOptionsText[type] ||
    TemplateTypeOptionsText[templateType.ApplicationTemplate];

  return (
    <Stack
      sx={{ maxWidth: "100%" }}
      alignItems="center"
      justifyContent="center"
      justifyItems={"center"}
    >
      <Tooltip title={window.getCTTranslatedText(title)}>
        <CTDatagridButton
          asText={viewOnly === true}
          isDisabled={viewOnly === true}
          facetName={facetName}
          facetOption={optionName}
          sx={{
            alignItems: "center",
          }}
        >
          {window.getCTTranslatedText(title)}
        </CTDatagridButton>
      </Tooltip>
    </Stack>
  );
}
