import { Box, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { AssetType } from "pages/assets/types";
import { useUpdateTotalCount } from "pages/paths/components/path-data-grid/hooks";
import { Path } from "pages/paths/types";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PathToolbar } from "../path-data-grid-toolbar";
import {
  PATHS_DATA_GRID_ID,
  PATH_COLUMNS,
  PathColumnConfig,
} from "./constants";
import { mapFieldToSort } from "./helpers/columnHelpers";

function getRowId({ channelHash, direction }: Path) {
  return `${channelHash}-${direction}`;
}

export const PathDataGrid = (props: DataGridProps<Path>) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const recommendationId =
    new URLSearchParams(window.location.search).get("recommendation") || "";

  const [selection, setSelection] = useState<Array<GridRowId>>([]);

  const selectedData: Array<Path> | undefined = useMemo(() => {
    return props.rows?.filter((row: Path) => {
      return selection.indexOf(getRowId(row)) !== -1;
    });
  }, [selection, props.rows]);

  const showSrcDestOption: boolean = useMemo(() => {
    return (selectedData || []).some((row: Path) => {
      return row?.sourceAsset?.assetId && row?.destinationAsset?.assetId;
    });
  }, [selectedData]);

  useEffect(() => {
    if (recommendationId && userPermissions.has("UPDATE_PATH")) {
      const tempSelections: GridRowId[] = (props.rows || [])
        .filter(item => item.isRowSelectable)
        .map(item => item.channelHash);
      if (tempSelections?.length > 0) {
        setSelection(tempSelections);
      }
    }
  }, [
    recommendationId,
    props.rows,
    searchParams,
    setSearchParams,
    userPermissions,
  ]);

  useUpdateTotalCount({
    isLoading: props.isLoading,
    maxRowCount: props.maxRowCount,
    originalRowCount: props.originalRowCount,
    id: PATHS_DATA_GRID_ID,
  });

  const onClickClearSelection = () => {
    setSelection([]);
    if (searchParams.has("recommendation")) {
      searchParams.delete("recommendation");
      setSearchParams(searchParams);
    }
  };

  const hasService = useMemo(() => {
    return props.rows?.some((row: Path) => {
      return Boolean(row?.method) || Boolean(row?.uri);
    });
  }, [props.rows]);

  const hasPortName = useMemo(() => {
    return props.rows?.some((row: Path) => {
      return (
        Boolean(row?.portName) ||
        !(
          row?.sourceAsset?.type === AssetType.Service ||
          row?.destinationAsset?.type === AssetType.Service
        )
      );
    });
  }, [props.rows]);

  const columns = useMemo(
    () =>
      PATH_COLUMNS.filter(column => {
        const removeColumns = [];

        removeColumns.push("__check__");

        if (!hasService) {
          removeColumns.push("api");
        }
        if (!hasPortName) {
          removeColumns.push("portname", "destinationProcess");
        }
        return !removeColumns.includes(column.field);
      }),
    [hasService, hasPortName]
  );

  const selectedRawData: Array<Path> | undefined = useMemo(() => {
    return (props?.rawData ?? [])?.filter((row: Path) => {
      return selection.indexOf(getRowId(row)) !== -1;
    });
  }, [selection, props?.rawData]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      {selection?.length > 0 && (
        <PathToolbar
          selectedData={selectedData ?? []}
          hideToolbar={onClickClearSelection}
          show={selection?.length > 0}
          showSrcDestOption={showSrcDestOption}
          recommendationId={recommendationId}
        />
      )}
      <Box sx={{ flex: 1, overflow: "hidden" }} id={PATHS_DATA_GRID_ID}>
        <DataGrid
          defaultPinnedColumns={PathColumnConfig.PinnedColumns}
          checkboxSelection={userPermissions.has("UPDATE_PATH")}
          rowSelectionModel={selection}
          onRowSelectionModelChange={selectionModel => {
            setSelection(selectionModel);
          }}
          exportRowCount={props.originalRowCount}
          rowHeight={64}
          columns={columns}
          pagination
          getRowId={getRowId}
          paginationMode="server"
          sortingMode="server"
          {...props}
          selectedRawData={selectedRawData}
          onSortChange={order => {
            if (!props.onSortChange) {
              return;
            }
            props.onSortChange(mapFieldToSort(order));
          }}
        />
      </Box>
    </Stack>
  );
};
