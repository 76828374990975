import { useQuery } from "@tanstack/react-query";
import { Logs } from "pages/agents/types";
import { LogsFetcherProps } from "../LogsFetcher";

interface LogsAPIOptions extends Pick<LogsFetcherProps, "agentId"> {}

export function useLogsAPI({ agentId }: LogsAPIOptions) {
  return useQuery<Logs, Error>([
    `agents/${agentId}/logs`,
    `agents/${agentId}/logs`,
  ]);
}
