import { WORK_REQUEST_UPDATED_EVENT } from "hooks/useNetworking/constants";
import { usePolling } from "hooks/usePolling";
import {
  INITIAL_BADGE_CONFIG,
  WORK_REQUEST_POLLING_INTERVAL,
  WORK_REQUEST_POLLING_INTERVAL_FOR_PENDING_REQUESTS,
} from "modules/AppBar/constants";
import { getWRAdditionalCriteria } from "modules/AppBar/helpers";
import { WorkRequestStatus } from "modules/AppBar/types";
import { useWorkRequestFacetStore } from "modules/PendingWorkRequestsDrawer/store/useWorkRequestStore";
import { Scope } from "modules/scope-metadata/types";
import { useSummarizeAPI } from "pages/dashboard/components/security-progress/hooks";
import { useCallback, useEffect, useState } from "react";

export const usePollPendingWorkRequest = (resourceID?: string) => {
  const mutation = useSummarizeAPI("work-request");
  const mutate = mutation.mutate;
  const { data, isLoading } = mutation;

  const setExternalCriteria = useWorkRequestFacetStore(
    state => state.setExternalCriteria
  );

  const [badgeConfig, setBadgeConfig] = useState(INITIAL_BADGE_CONFIG);

  const fetchData = useCallback(() => {
    const additionalCriteria = getWRAdditionalCriteria();

    const additionalCriteriaForResourceID = resourceID
      ? ` AND 'workrequestresourceid' in ('${resourceID}')`
      : "";

    const criteria = `'status' in ('${WorkRequestStatus.Cancelled}','${WorkRequestStatus.InProgress}','${WorkRequestStatus.Pending}') AND ${additionalCriteria}${additionalCriteriaForResourceID}`;

    mutate({
      criteria,
      facetField: "status",
      facetFieldFilter: "",
      scope: Scope.WorkRequest,
    });
    setExternalCriteria(additionalCriteria);
  }, [mutate, resourceID, setExternalCriteria]);

  const { startPolling, stopPolling } = usePolling(
    fetchData,
    WORK_REQUEST_POLLING_INTERVAL
  );

  useEffect(() => {
    const refetchPendingRequests = () => {
      startPolling(WORK_REQUEST_POLLING_INTERVAL_FOR_PENDING_REQUESTS);
    };

    window.addEventListener(WORK_REQUEST_UPDATED_EVENT, refetchPendingRequests);

    return () => {
      stopPolling();
      window.removeEventListener(
        WORK_REQUEST_UPDATED_EVENT,
        refetchPendingRequests
      );
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!data?.Facet?.values) {
      setBadgeConfig(INITIAL_BADGE_CONFIG);
      return;
    }

    const count = Object.values(data.Facet.values).reduce(
      (acc, currValue) => acc + currValue,
      0
    );

    const shouldFetchMoreFrequently =
      data.Facet.values[WorkRequestStatus.InProgress] > 0 ||
      data.Facet.values[WorkRequestStatus.Pending] > 0;

    setBadgeConfig({
      count,
      shouldFetchMoreFrequently,
    });
  }, [isLoading, data?.Facet?.values]);

  useEffect(() => {
    startPolling(
      badgeConfig.shouldFetchMoreFrequently
        ? WORK_REQUEST_POLLING_INTERVAL_FOR_PENDING_REQUESTS
        : WORK_REQUEST_POLLING_INTERVAL
    );

    return () => {
      stopPolling();
    };
  }, [badgeConfig.shouldFetchMoreFrequently, startPolling, stopPolling]);

  return { badgeConfig, isLoading, startPolling, stopPolling };
};
