import { isValidIp, isValidSubnet } from "common/utils";
import { useState } from "react";
import { CTInputField } from "../ct-input-field";
import { TextFieldUpdate } from "../ct-input-field/CTInputField";

export const IP_FIELD_TYPES = {
  IP: "ip",
  SUBNET: "subnet",
};

interface InputFieldProps {
  field: string;
  displayName: string;
  placeholder?: string;
  value: string | undefined;
  handleUpdate: Function;
  required?: boolean;
  showLabel?: boolean;
  autoFocus?: boolean;
  error?: boolean;
  onKeyUp?: React.KeyboardEventHandler<HTMLDivElement>;
  type?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  startIcon?: JSX.Element;
  disabled?: boolean;
  showClear?: boolean;
  size?: string;
  fieldType?: string;
}

export function CTIPInput({
  field,
  displayName,
  placeholder,
  value,
  handleUpdate,
  required = false,
  showLabel = true,
  autoFocus = false,
  error = false,
  onKeyUp,
  type = "text",
  onFocus,
  startIcon,
  disabled = false,
  showClear,
  size = "medium",
  fieldType = IP_FIELD_TYPES.IP,
}: InputFieldProps) {
  const [isError, setIsError] = useState(error);

  function checkIfValidIP(ip: string) {
    if (fieldType === IP_FIELD_TYPES.IP) {
      if (isValidIp(ip)) {
        setIsError(false);
        return;
      }
    }
    if (fieldType === IP_FIELD_TYPES.SUBNET) {
      if (isValidSubnet(ip)) {
        setIsError(false);
        return;
      }
    }
    setIsError(true);
  }

  function handleValueUpdate({ field, value }: TextFieldUpdate) {
    handleUpdate({ field, value });
    checkIfValidIP(value);
  }

  return (
    <CTInputField
      field={field}
      displayName={displayName}
      placeholder={placeholder}
      value={value}
      handleUpdate={handleValueUpdate}
      required={required}
      showLabel={showLabel}
      autoFocus={autoFocus}
      error={isError}
      onKeyUp={onKeyUp}
      type={type}
      onFocus={onFocus}
      startIcon={startIcon}
      disabled={disabled}
      showClear={showClear}
      size={size}
    />
  );
}
