import { Button, Stack, Tooltip } from "@mui/material";
import { useDemoController } from "hooks/useDemoController";
import { useNavigate } from "react-router-dom";
import { agent_types } from "../agent-download-page/constants";

export interface RouteProps {
  route: string;
  agentType?: string | undefined;
}

export const AgentDownload = ({ route, agentType }: RouteProps) => {
  const { isDemo } = useDemoController();
  const navigate = useNavigate();

  const navigateToDownloadPage = () => {
    if (!isDemo) {
      navigate(route);
    }
  };

  const renderDownloadButton = () => {
    return (
      <Tooltip
        title={
          isDemo
            ? window.getCTTranslatedText(
                "Please exit demo mode to download agent"
              )
            : ""
        }
      >
        <span>
          <Button
            variant="contained"
            color="primary"
            disabled={isDemo}
            onClick={() => navigateToDownloadPage()}
            size="medium"
          >
            {agentType === agent_types.CT_CONTAINER_AGENT
              ? window.getCTTranslatedText("Install")
              : window.getCTTranslatedText("Install Agent")}
          </Button>
        </span>
      </Tooltip>
    );
  };

  return (
    <Stack
      direction={"row-reverse"}
      alignItems="flex-start"
      justifyItems="flex-end"
    >
      {renderDownloadButton()}
    </Stack>
  );
};
