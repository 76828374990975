import { CircularProgress, Stack, Typography } from "@mui/material";
import numeral from "numeral";
import pluralize from "pluralize";

export function MatchedAssetCount({
  total,
  isLoading,
}: {
  total: number;
  isLoading: boolean;
}) {
  return (
    <Stack
      alignItems={"flex-start"}
      justifyContent={"center"}
      sx={{ minHeight: 20 }}
    >
      {isLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Typography variant="body2">
          <strong>{`${numeral(total).format("0a")}`}</strong>{" "}
          {`matching ${pluralize("asset", Number(total))}`}
        </Typography>
      )}
    </Stack>
  );
}
