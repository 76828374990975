import { IconsProps } from "./common";

export const TemplateIcon = ({
  width = "24px",
  height = "24px",
  color,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
    >
      <path d="M12.5 1L3.5 5V11C3.5 16.55 7.34 21.74 12.5 23C17.66 21.74 21.5 16.55 21.5 11V5L12.5 1ZM19.5 11C19.5 15.52 16.52 19.69 12.5 20.93C8.48 19.69 5.5 15.52 5.5 11V6.3L12.5 3.19L19.5 6.3V11Z" />
      <rect x="7.5" y="7" width="10" height="2" />
      <rect x="9" y="11" width="7" height="2" />
      <rect x="10.5" y="15" width="4" height="2" />
    </svg>
  );
};
