import {
  Box,
  DialogActions,
  DialogContent,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import {
  AssetMetaDataEditProps,
  AssetType,
  BusinessValue,
  CoreTagsKeys,
} from "pages/assets/types";

import BadgeIcon from "@mui/icons-material/Badge";
import CloseIcon from "@mui/icons-material/Close";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MonitorIcon from "@mui/icons-material/Monitor";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import Grid from "@mui/material/Grid";
import { useQueryClient } from "@tanstack/react-query";
import { HintIcon } from "assets/svgs";
import { CTInputField } from "common/atoms/ct-input-field";
import { Toolbar } from "common/atoms/toolbar";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { Device_CORE_TAGS } from "modules/scope-metadata/constants";
import { ScopeMetadataColumn } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { AssetCoreTagEdit } from "pages/asset/components/asset-core-tag/components/asset-core-tag-edit";
import {
  useUpdateAssetAnnotations,
  useUpdateBulkAssetsAnnotations,
} from "pages/asset/components/asset-detail/hooks/use-update-asset-annotations";
import { NO_VALUE } from "pages/asset/constants";
import { useAssetStore } from "pages/assets/store";
import { useTagsAPI } from "pages/tags/hooks/useTagsAPI";
import { useMemo, useState } from "react";

const iconStyle = "rgba(0, 0, 0, 0.38)";
const IconStyleDarkMode = "rgba(255, 255, 255, 0.38)";

const editableCoreTagListOptions: Array<string> = [
  CoreTagsKeys.Application,
  CoreTagsKeys.Environment,
  CoreTagsKeys.Location,
  CoreTagsKeys.Owner,
  CoreTagsKeys.Role,
  CoreTagsKeys.Model,
  CoreTagsKeys.Category,
  CoreTagsKeys.SubCategory,
  CoreTagsKeys.Manufacturer,
];
interface AssetMetaDataFormProps {
  assetName?: string;
  businessValue?: string;
  coreTags?: { [key: string]: string };
  osName?: string;
  vendorInfo?: string;
  criteria?: string;
  userDefinedTags?: {};
}

export function MetadataEdit({
  asset,
  updateAssetMetadata,
  handleAllowEdit,
  allowEdit,
  assetIds,
  mode = "single",
}: AssetMetaDataEditProps) {
  const theme = useTheme();
  const notify = useEventSubscriptionStore(state => state.notify);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  const requestAssetAPIRefresh = useAssetStore(
    state => state.requestAPIRefresh,
  );
  const [assetName, setAssetName] = useState(asset?.assetName ?? "");
  const [userDefinedCoreTags, setUserDefinedCoreTags] = useState({});
  const [businessValue, setBusinessValue] = useState(
    asset?.businessValue ?? "empty",
  );
  const [application, setApplicationValue] = useState(
    asset?.coreTags[CoreTagsKeys.Application] ?? "",
  );
  const [environment, setEnvironmentValue] = useState(
    asset?.coreTags[CoreTagsKeys.Environment] ?? "",
  );
  const [location, setLocationValue] = useState(
    asset?.coreTags[CoreTagsKeys.Location] ?? "",
  );
  const [owner, setOwnerValue] = useState(
    asset?.coreTags[CoreTagsKeys.Owner] ?? "",
  );
  const [role, setRoleValue] = useState(
    asset?.coreTags[CoreTagsKeys.Role] ?? "",
  );
  const [model, setModelValue] = useState(
    asset?.coreTags[CoreTagsKeys.Model] ?? "",
  );
  const [category, setCategoryValue] = useState(
    asset?.coreTags[CoreTagsKeys.Category] ?? "",
  );
  const [subcategory, setSubCategoryValue] = useState(
    asset?.coreTags[CoreTagsKeys.SubCategory] ?? "",
  );
  const [manufacturer, setManufacturerValue] = useState(
    asset?.coreTags[CoreTagsKeys.Manufacturer] ?? "",
  );
  const [osName, setOsName] = useState(asset?.osName ?? "");
  const [vendorInfo, setVendorInfo] = useState<string>(asset?.vendorInfo ?? "");

  const [loading, setLoading] = useState(false);

  const updateAssetMetaDataMutation = useUpdateAssetAnnotations(asset?.assetId);
  const updateBulkAssetsMetaDataMutation = useUpdateBulkAssetsAnnotations();

  const { tagFields: tags } = useTagsAPI();

  const coreTagList = useMemo(() => {
    if (!tags) {
      return;
    }

    const assetCoreTags = Object.entries(asset?.coreTags ?? {})
      .filter(([, value]) => value)
      .map(([key]) => key);

    tags.coreTags = tags.coreTags.filter((tag: ScopeMetadataColumn) => {
      const key: string = tag?.name ?? "";

      if (asset?.type !== AssetType.Device) {
        return (
          key &&
          editableCoreTagListOptions.includes(key) &&
          !Device_CORE_TAGS[key]
        );
      }

      return key && editableCoreTagListOptions.includes(key);
    });

    const coreTags = tags?.coreTags.map(col => col.name ?? "") ?? [];

    return sortBy(uniq([...assetCoreTags, ...coreTags]));
  }, [asset?.coreTags, asset?.type, tags]);

  const userDefinedTagsList = useMemo(() => {
    if (!tags) {
      return;
    }
    return tags?.userDefinedTags.map(col => col.displayName ?? "") ?? [];
  }, [tags]);

  const queryClient = useQueryClient();

  const updateAssetDetails = () => {
    if (mode === "single") {
      updateSingleAsset();
    } else {
      updateBulkAssets();
    }
  };

  const updateSingleAsset = () => {
    setLoading(true);
    let body: AssetMetaDataFormProps = {
      assetName: assetName?.trim(),
      businessValue: businessValue,
      coreTags: {
        [CoreTagsKeys.Application]: application?.trim(),
        [CoreTagsKeys.Environment]: environment?.trim(),
        [CoreTagsKeys.Location]: location?.trim(),
        [CoreTagsKeys.Owner]: owner?.trim(),
        [CoreTagsKeys.Role]: role?.trim(),
        [CoreTagsKeys.Model]: model?.trim(),
        [CoreTagsKeys.Category]: category?.trim(),
        [CoreTagsKeys.SubCategory]: subcategory?.trim(),
        [CoreTagsKeys.Manufacturer]: manufacturer?.trim(),
        ...userDefinedCoreTags,
      },
      osName: osName?.trim(),
    };

    if (asset?.type === AssetType.Device) {
      body["vendorInfo"] = vendorInfo?.trim();
    }

    body = handleNoValueString(body);

    updateAssetMetaDataMutation.mutate(body, {
      onSuccess: response => {
        updateAssetMetadata({ body, id: "assetName" });
        requestAssetAPIRefresh();
        notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
          label: "AssetDetailsSubmittedSuccessfully",
        });
        setTimeout(() => {
          handleAllowEdit(false);
          setLoading(false);
        }, 500);
      },
      onError: error => {
        setLoading(false);
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const buildRequestBody = () => {
    let body: AssetMetaDataFormProps = {
      criteria: `assetId in (${(assetIds ?? [])
        .map(id => `'${id}'`)
        .join(",")})`,
    };

    if (isValidBusinessValue()) {
      body["businessValue"] = businessValue;
    }

    if (isValidTags()) {
      if (!body?.coreTags) {
        body["coreTags"] = {};
      }
      if (application?.trim() !== "") {
        body["coreTags"]["application"] = application?.trim();
      }
      if (environment?.trim() !== "") {
        body["coreTags"]["environment"] = environment?.trim();
      }
      if (location?.trim() !== "") {
        body["coreTags"]["location"] = location?.trim();
      }
      if (owner?.trim() !== "") {
        body["coreTags"]["owner"] = owner?.trim();
      }
      if (role?.trim() !== "") {
        body["coreTags"]["role"] = role?.trim();
      }
      if (model?.trim() !== "") {
        body["coreTags"]["model"] = model?.trim();
      }
      if (category?.trim() !== "") {
        body["coreTags"]["category"] = category?.trim();
      }
      if (subcategory?.trim() !== "") {
        body["coreTags"]["subcategory"] = subcategory?.trim();
      }
      if (manufacturer?.trim() !== "") {
        body["coreTags"]["manufacturer"] = manufacturer?.trim();
      }
    }

    if (isValidOS()) {
      body["osName"] = osName?.trim();
    }

    if (isValidVendor()) {
      body["vendorInfo"] = vendorInfo?.trim();
    }

    if (isValidUserDefinedTags()) {
      body["coreTags"] = { ...body["coreTags"], ...userDefinedCoreTags };
    }

    return body;
  };

  const handleNoValueString = (body: AssetMetaDataFormProps) => {
    for (const key in body?.coreTags) {
      if (body.coreTags[key] === NO_VALUE) {
        body.coreTags[key] = "";
      }
    }
    return body;
  };

  const isValidUserDefinedTags = () => {
    return Object.values(userDefinedCoreTags).every(coreTag => coreTag !== "");
  };

  const isValidVendor = () => {
    return Boolean(vendorInfo?.trim() !== "");
  };

  const isValidOS = () => {
    return Boolean(osName?.trim() !== "");
  };

  const isValidBusinessValue = () => {
    return Boolean(
      businessValue && businessValue !== "" && businessValue !== "empty",
    );
  };

  const isValidTags = () => {
    return Boolean(
      application?.trim() ||
        environment?.trim() ||
        location?.trim() ||
        owner?.trim() ||
        role?.trim() ||
        model?.trim() ||
        category?.trim() ||
        subcategory?.trim() ||
        manufacturer?.trim(),
    );
  };

  const isValid = () => {
    return Boolean(
      isValidBusinessValue() ||
        isValidTags() ||
        isValidVendor() ||
        isValidOS() ||
        isValidUserDefinedTags(),
    );
  };

  const updateBulkAssets = () => {
    setLoading(true);
    let body: AssetMetaDataFormProps = handleNoValueString(buildRequestBody());

    updateBulkAssetsMetaDataMutation.mutate(body, {
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: ["asset"],
        });
        updateAssetMetadata();
        requestAssetAPIRefresh();
        setSnackbar(
          true,
          SnackBarSeverity.Success,
          "SelectedAssetsTaggedSuccessfully",
        );
        setTimeout(() => {
          handleAllowEdit(false);
          setLoading(false);
        }, 500);
      },
      onError: error => {
        setLoading(false);
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const handleNameChange = (e: any) => {
    if (e?.target?.value !== undefined) {
      setAssetName(e.target.value);
    }
  };

  const handleVendorChange = (e: any) => {
    if (e?.target?.value !== undefined) {
      setVendorInfo(e.target.value);
    }
  };

  const handleOSValue = (e: any) => {
    if (e?.target?.value !== undefined) {
      setOsName(e.target.value);
    }
  };

  const handleBusinessValue = async (event: SelectChangeEvent<string>) => {
    const businessValue = event.target.value as BusinessValue;
    setBusinessValue(businessValue);
  };

  const handleUpdate = ({
    coreTagKey,
    coreTagValue,
  }: {
    coreTagKey: CoreTagsKeys | string;
    coreTagValue: string;
  }) => {
    if (
      coreTagKey === CoreTagsKeys.Application &&
      coreTagValue !== application
    ) {
      setApplicationValue(coreTagValue);
    } else if (
      coreTagKey === CoreTagsKeys.Environment &&
      coreTagValue !== environment
    ) {
      setEnvironmentValue(coreTagValue);
    } else if (
      coreTagKey === CoreTagsKeys.Location &&
      coreTagValue !== location
    ) {
      setLocationValue(coreTagValue);
    } else if (coreTagKey === CoreTagsKeys.Owner && coreTagValue !== owner) {
      setOwnerValue(coreTagValue);
    } else if (coreTagKey === CoreTagsKeys.Role && coreTagValue !== role) {
      setRoleValue(coreTagValue);
    } else if (coreTagKey === CoreTagsKeys.Model && coreTagValue !== model) {
      setModelValue(coreTagValue);
    } else if (
      coreTagKey === CoreTagsKeys.Category &&
      coreTagValue !== category
    ) {
      setCategoryValue(coreTagValue);
    } else if (
      coreTagKey === CoreTagsKeys.SubCategory &&
      coreTagValue !== subcategory
    ) {
      setSubCategoryValue(coreTagValue);
    } else if (
      coreTagKey === CoreTagsKeys.Manufacturer &&
      coreTagValue !== manufacturer
    ) {
      setManufacturerValue(coreTagValue);
    } else if (userDefinedTagsList?.includes(coreTagKey)) {
      setUserDefinedCoreTags(prev => ({
        ...prev,
        [coreTagKey]: coreTagValue.trim(),
      }));
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={allowEdit}
        onClose={() => handleAllowEdit(false)}
        PaperProps={{
          sx: {
            p: 0,
            width: "50%",
            maxwidth: "786px",
            minWidth: "786px",
          },
          elevation: 1,
        }}
      >
        <Toolbar />
        <DialogContent sx={{ width: "100%", height: "100%" }}>
          <Stack
            alignItems="flex-start"
            sx={{ position: "relative", width: "100%" }}
          >
            <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
              <IconButton
                size="large"
                aria-label="close drawer"
                onClick={() => handleAllowEdit(false)}
                sx={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                  zIndex: 2,
                }}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Stack>

          <Stack alignItems={"flex-start"} sx={{ height: "100%" }}>
            <Typography variant="h6" sx={{ my: 2 }}>
              {window.getCTTranslatedText("AssetEditDetailsTitle", {
                mode: window.getCTTranslatedText(
                  mode === "single" ? "Asset" : "Assets",
                ),
              })}
            </Typography>
            <Box
              sx={{
                my: 5,
                width: "100%",
              }}
            >
              <Stack
                direction="row"
                alignItems={"center"}
                spacing={4}
                sx={{
                  width: "100%",
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? theme.palette.action.hover
                      : theme.palette.custom.lightGreyBg,
                  p: 4,
                  borderRadius: 1,
                }}
              >
                <Stack
                  sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: "100px",
                    p: 1,
                  }}
                >
                  <HintIcon
                    width="24"
                    height="24"
                    color={theme.palette.text.primary}
                  />
                </Stack>
                <Stack>
                  <Typography variant="body1">
                    {window.getCTTranslatedText("AssetEditDetailsInfo", {
                      mode: window.getCTTranslatedText(
                        mode === "single" ? "asset" : "assets",
                      ),
                    })}
                  </Typography>
                </Stack>
              </Stack>
            </Box>

            <Box sx={{ minWidth: "100%" }}>
              <Stack alignItems="center" justifyContent="center" spacing={4}>
                <FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
                  <Stack direction={"column"}>
                    {mode === "single" && (
                      <CTInputField
                        field="assetname"
                        value={assetName}
                        type="text"
                        placeholder="Enter asset name"
                        displayName="Name"
                        handleUpdate={handleNameChange}
                        sx={{ mb: 3 }}
                      />
                    )}

                    <Grid container columnSpacing={6}>
                      {(coreTagList ?? []).map((key: string, index: number) => {
                        return (
                          <Grid item xs={6} key={`${key}`} sx={{ mb: 3 }}>
                            <AssetCoreTagEdit
                              asset={asset}
                              coreTagKey={key}
                              handleUpdate={handleUpdate}
                              getTagIcon={getTagIcon}
                            />
                          </Grid>
                        );
                      })}
                      <Grid item xs={6} sx={{ mb: 3 }}>
                        <FormControl variant="standard" sx={{ width: "100%" }}>
                          <InputLabel id="business-value-label">
                            {window.getCTTranslatedText("Business Value")}
                          </InputLabel>
                          <Select
                            label={window.getCTTranslatedText("Business Value")}
                            labelId="business-value-label"
                            id="business-value"
                            value={businessValue}
                            onChange={handleBusinessValue}
                            displayEmpty
                          >
                            <MenuItem disabled value={"empty"}>
                              {window.getCTTranslatedText(
                                "Select business value",
                              )}
                            </MenuItem>
                            <MenuItem value={BusinessValue.High}>
                              <Stack direction="row" spacing={1}>
                                <KeyboardDoubleArrowUpIcon
                                  color={"error"}
                                  fontSize="small"
                                />
                                <Typography
                                  variant="caption"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {window.getCTTranslatedText(
                                    BusinessValue.High,
                                  )}
                                </Typography>
                              </Stack>
                            </MenuItem>
                            <MenuItem value={BusinessValue.Medium}>
                              <Stack direction="row" spacing={1}>
                                <KeyboardArrowUpIcon
                                  color={"warning"}
                                  fontSize="small"
                                />
                                <Typography
                                  variant="caption"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {window.getCTTranslatedText(
                                    BusinessValue.Medium,
                                  )}
                                </Typography>
                              </Stack>
                            </MenuItem>
                            <MenuItem value={BusinessValue.Low}>
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{ alignItems: "end" }}
                              >
                                <KeyboardArrowDown
                                  color={"success"}
                                  fontSize="small"
                                />
                                <Typography
                                  variant="caption"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {window.getCTTranslatedText(
                                    BusinessValue.Low,
                                  )}
                                </Typography>
                              </Stack>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {asset?.type === "device" && (
                        <>
                          <Grid item xs={6} sx={{ mb: 3 }}>
                            <CTInputField
                              field="osname"
                              value={osName}
                              type="text"
                              displayName="Operating System"
                              handleUpdate={handleOSValue}
                            />
                          </Grid>
                          <Grid item xs={6} sx={{ mb: 3 }}>
                            <TextField
                              name="vendor"
                              value={vendorInfo}
                              type="text"
                              placeholder={window.getCTTranslatedText(
                                "Enter vendor info",
                              )}
                              fullWidth
                              label={window.getCTTranslatedText("Vendor Info")}
                              onChange={handleVendorChange}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                autoComplete: "off",
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <StoreIcon
                                      sx={{
                                        color: theme =>
                                          theme.palette.mode === "dark"
                                            ? IconStyleDarkMode
                                            : iconStyle,
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              variant="standard"
                            />
                          </Grid>
                        </>
                      )}
                      {userDefinedTagsList?.map((key, index) => (
                        <Grid item xs={6} key={`${key}`} sx={{ mb: 3, mt: 0 }}>
                          <AssetCoreTagEdit
                            asset={asset}
                            coreTagKey={key}
                            handleUpdate={handleUpdate}
                            getTagIcon={() => (
                              <LocalOfferOutlinedIcon
                                width="24"
                                height="24"
                                sx={{
                                  color: theme =>
                                    theme.palette.mode === "dark"
                                      ? IconStyleDarkMode
                                      : iconStyle,
                                }}
                              />
                            )}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                </FormControl>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
          <ToolbarAction
            loading={updateAssetMetaDataMutation.isLoading || loading}
            save={() => updateAssetDetails()}
            cancel={() => handleAllowEdit(false)}
            actionBtnText={"Update"}
            isValid={isValid()}
          />
        </DialogActions>
      </Drawer>
    </>
  );
}

const getTagIcon = (coreTagKey: CoreTagsKeys) => {
  let tagIcon;

  switch (coreTagKey) {
    case CoreTagsKeys.Application:
      tagIcon = (
        <MonitorIcon
          width="24"
          height="24"
          sx={{
            color: theme =>
              theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
          }}
        />
      );
      break;
    case CoreTagsKeys.Location:
      tagIcon = (
        <LocationOnIcon
          width="24"
          height="24"
          sx={{
            color: theme =>
              theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
          }}
        />
      );
      break;
    case CoreTagsKeys.Role:
      tagIcon = (
        <BadgeIcon
          width="24"
          height="24"
          sx={{
            color: theme =>
              theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
          }}
        />
      );
      break;
    case CoreTagsKeys.Owner:
      tagIcon = (
        <PersonOutlineIcon
          width="24"
          height="24"
          sx={{
            color: theme =>
              theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
          }}
        />
      );
      break;
    case CoreTagsKeys.Environment:
      tagIcon = (
        <CloudQueueIcon
          width="24"
          height="24"
          sx={{
            color: theme =>
              theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
          }}
        />
      );
      break;
    default:
      tagIcon = (
        <LocalOfferOutlinedIcon
          width="24"
          height="24"
          sx={{
            color: theme =>
              theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
          }}
        />
      );
  }

  return tagIcon;
};
