import { UserPreferencesStore } from "common/store/useUserPreferenceStore";
import { FacetState } from "modules/facets/types";
import { Scope } from "modules/scope-metadata/types";

export const getSelectedTime = (
  state: UserPreferencesStore,
  scope?: Scope,
  facets?: FacetState
) => {
  switch (scope) {
    case Scope.Port:
      return (
        Array.from(facets?.get("listenportlastobserved")?.keys() ?? [])[0] ||
        state.timeFilter
      );
    case Scope.Path:
      return (
        Array.from(facets?.get("pathlastobserved")?.keys() ?? [])[0] ||
        state.timeFilter
      );
    default:
      return state.timeFilter;
  }
};

export const getSelectedTimeFacetNameFromScope = (scope?: Scope) => {
  switch (scope) {
    case Scope.Port:
      return "listenportlastobserved";
    case Scope.Path:
      return "pathlastobserved";
    default:
      return "";
  }
};
