import {
  agent_types,
  platforms,
} from "pages/agents/components/agent-download-page/constants";
import { AgentDefaultType, AgentVersionDetails } from "../type";

export function getAvailablePlatforms(
  platformAgentData: AgentVersionDetails,
  props: AgentDefaultType
) {
  if (props.agentType === agent_types.CT_REMOTE_CONNECTOR) {
    return Object.keys(platformAgentData)
      .filter(key => key === platforms.DEBIAN)
      .sort()
      .reverse();
  } else {
    return Object.keys(platformAgentData)
      .filter(
        key =>
          key !== platforms.DOCKER &&
          key !== platforms.LINUX &&
          key !== platforms.POWERSHELL
      )
      .sort()
      .reverse();
  }
}

export function filterServerUserPlatforms(
  platformAgentData: AgentVersionDetails,
  props: AgentDefaultType,
  isAlpha: boolean
) {
  if (isAlpha) {
    return getAvailablePlatforms(platformAgentData, props);
  } else {
    return getAvailablePlatforms(platformAgentData, props).filter(
      item => item !== platforms.RPM_LEGACY
    );
  }
}
