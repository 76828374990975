import { Card, Stack, Typography, debounce } from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
  FormSectionWrapper,
  TagBasedPolicyModeType,
} from "pages/create-tag-policy/components/PolicyForm";
import {
  avgDataMapper,
  barChartRequestBody,
} from "pages/dashboard/components/security-progress/helpers";
import { useTimeSeriesAPI } from "pages/dashboard/components/security-progress/hooks";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import { useEffect, useMemo } from "react";
import { TagPolicy } from "../../types";
const BREACH_IMPACT_TIMELINE = ["30 days", "60 days", "90 days"];

interface BreachImpactMetricsProps {
  target: number | undefined;
  baseline: number | undefined;
  timeline: number;
  setTarget: (target: number | undefined) => void;
  setBaseline: (timeline: number | undefined) => void;
  setTimeline: (timeline: number) => void;
  criteria?: string;
  mode: TagBasedPolicyModeType;
  savedPolicy?: TagPolicy;
}

export const BreachImpactMetrics = ({
  target,
  timeline,
  baseline,
  setTarget,
  setTimeline,
  setBaseline,
  criteria,
  mode,
  savedPolicy,
}: BreachImpactMetricsProps) => {
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  const getTimeline = (timeline: string) => {
    return Number(timeline.split(" ")[0]);
  };

  const keyMetricsAvgMutation = useTimeSeriesAPI();
  const savedPolicyCriteria = savedPolicy?.criteria;
  const baselineBreachImpactScore =
    baseline ?? savedPolicy?.baselineBreachImpactScore;

  const keyMetricsAvgMutationObj = useMemo(
    () => debounce(keyMetricsAvgMutation.mutate, 500),
    [keyMetricsAvgMutation.mutate]
  );

  useEffect(() => {
    if (!criteria && baselineBreachImpactScore) {
      setBaseline(undefined);
    }
  }, [baselineBreachImpactScore, criteria, setBaseline]);

  useEffect(() => {
    if (!criteria || mode === TagBasedPolicyModeType.Edit) {
      return;
    }

    if (
      mode === TagBasedPolicyModeType.Clone &&
      criteria === savedPolicyCriteria
    ) {
      return;
    }

    const requestObj = barChartRequestBody({
      criteria,
      scope: Scope.Asset,
      groupBy: [],
      statistics: ["mean(assetriskscore)"],
    });

    keyMetricsAvgMutationObj(requestObj, {
      onSuccess(responseData) {
        const items = responseData?.items ?? {};
        const avgRiskVal = avgDataMapper({
          items,
          statisticsId: "assetriskscoremean",
        });
        setBaseline(Math.round(avgRiskVal ?? 0));
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, "FailedToFetchBreachImpact");
      },
    });
  }, [
    keyMetricsAvgMutationObj,
    criteria,
    setSnackbar,
    setBaseline,
    mode,
    savedPolicyCriteria,
  ]);

  return (
    <Stack>
      <Card>
        <FormSectionWrapper>
          <Typography variant="subtitle1">
            {window.getCTTranslatedText("breachImpactMetrics")} *
          </Typography>
        </FormSectionWrapper>
        <Stack
          spacing={6}
          direction="row"
          sx={{ justifyContent: "space-between", p: 3 }}
          alignItems={"flex-start"}
        >
          <CTInputField
            key={`${criteria}_${baselineBreachImpactScore}`}
            field="baseline"
            displayName="baseline"
            value={baselineBreachImpactScore
              ?.toFixed(baselineBreachImpactScore % 1 === 0 ? 0 : 2)
              ?.toString()}
            handleUpdate={(event: TextFieldUpdate) => null}
            disabled={true}
            helperText={"breachImpactScore"}
            placeholder={window.getCTTranslatedText("baselinePlaceholder")}
            sx={{ m: 0 }}
          />

          <CTInputField
            type="number"
            field="target"
            displayName="target"
            value={target}
            handleUpdate={e => {
              const value = e?.target?.value
                ? Number(e?.target?.value)
                : undefined;

              setTarget(value);
            }}
            helperText={window.getCTTranslatedText("impactScoreAimedToAchieve")}
            placeholder={window.getCTTranslatedText("targetPlaceholder", {
              baseline: 100,
            })}
            inputProps={{
              min: 0,
              max: 100,
            }}
            sx={{ m: 0 }}
          />
          <CTSelectDropDown
            field={"timeline"}
            displayName={"timeline"}
            selectedValue={timeline.toString() + ` days`}
            handleUpdate={(event: TextFieldUpdate) =>
              setTimeline(getTimeline(event.value))
            }
            data={BREACH_IMPACT_TIMELINE}
            textTransform="none"
            showLabel={true}
            helperText={"timeFrame"}
          />
        </Stack>
      </Card>
    </Stack>
  );
};
