import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Button, Stack } from "@mui/material";
import { Field, FieldType, TextFieldsValues } from "modules/form-fields/type";

interface IntegrationToolbarActionProps {
  textFieldsValues: TextFieldsValues;
  handlerOnCloseDrawer: VoidFunction;
  updateConfig: VoidFunction;
  loading: boolean;
  formConfig: any;
}

export const IntegrationToolbarAction = ({
  textFieldsValues,
  handlerOnCloseDrawer,
  updateConfig,
  loading,
  formConfig,
}: IntegrationToolbarActionProps) => {
  const hasErrors = () => {
    const iState: { [key: string]: string } = {};
    let ddChilsElements: Field[] = [];
    let checkboxFieldsWithErrors = false;
    const processField = (field: Field) => {
      if (field.type !== FieldType.MAPPING && field.type !== FieldType.STATIC) {
        if (field.type !== FieldType.SECTION && field.required) {
          iState[field.key] = textFieldsValues[field.key];
        }
        field?.children?.forEach(processField);
      }

      if (field.type === FieldType.DROPDOWN && field.required) {
        field?.possibleValues?.forEach(possibleValue => {
          if (possibleValue?.children?.length) {
            const filterVal = field?.possibleValues?.filter(
              obj => obj.key === iState[field.key],
            );
            if (filterVal?.length) {
              filterVal[0].children?.forEach(child => {
                if (child.children?.length) {
                  ddChilsElements = child.children;
                }
              });
            }
          }
          possibleValue?.children?.forEach(processField);
        });
      }

      if (field.type === FieldType.DROPDOWN && !field.required) {
        field?.possibleValues?.forEach(possibleValue => {
          if (possibleValue?.children?.length) {
            const selectedValue =
              possibleValue.key === textFieldsValues[field.key];
            if (selectedValue) {
              possibleValue.children?.forEach(child => {
                if (child.children?.length) {
                  ddChilsElements.push(...child.children);
                }
              });
            }
          }
          possibleValue?.children?.forEach(processField);
        });
      }

      if (field.type === FieldType.CHECKBOX && field.required) {
        if (!iState[field.key]) {
          checkboxFieldsWithErrors = true;
        }
      }
    };

    formConfig?.forEach(processField);
    if (ddChilsElements.length) {
      for (const field of ddChilsElements) {
        if (checkValid(iState, field.key) && field.required) {
          return true;
        }
      }
    } else {
      for (const key in iState) {
        if (checkValid(iState, key)) {
          return true;
        }
      }
    }

    return checkboxFieldsWithErrors;
  };

  const checkValid = (iState: { [key: string]: string }, key: string) => {
    if (typeof iState[key] === "object") {
      if (!Object.keys(iState[key] ?? {}).length) {
        return true;
      }
    } else {
      if (
        !iState[key]?.toString()?.length ||
        iState[key]?.toString()?.length === 0
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      <Button size={"medium"} variant="outlined" onClick={handlerOnCloseDrawer}>
        {window.getCTTranslatedText("Cancel")}
      </Button>
      <LoadingButton
        loading={loading}
        variant="contained"
        type="submit"
        disabled={hasErrors()}
        onClick={updateConfig}
        sx={{ ml: 1 }}
      >
        {window.getCTTranslatedText("Save")}
      </LoadingButton>
    </Stack>
  );
};
