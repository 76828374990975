import { platforms, environments } from "../constants";
import { AIXLogo, AppleIcon, LinuxIcon, WindowsIcon } from "assets/svgs";
import { DebianIcon } from "assets/svgs/DebianIcon";
import { ContainerIcon } from "assets/svgs/ContainerIcon";
import { SuseIcon } from "assets/svgs/SuseIcon";
import { SolarisLogo } from "assets/svgs/SolarisLogo";
import { KubernetesIcon } from "assets/svgs/KubernetesIcon";
import { IstioIcon } from "assets/svgs/IstioIcon";
import { HelmIcon } from "assets/svgs/HelmIcon";
export function renderProcessPlatformIcon(platform: string, color: string) {
  switch (platform) {
    case platforms.RPM:
      return <LinuxIcon width="30" height="30" color={color} />;
    case platforms.DEBIAN:
      return <DebianIcon width="35" height="35" color={color} />;
    case platforms.WINDOWS:
      return <WindowsIcon width="30" height="30" color={color} />;
    case platforms.DARWIN:
      return <AppleIcon width="35" height="35" color={color} />;
    case platforms.DOCKER:
      return <ContainerIcon width="30" height="30" color={color} />;
    case platforms.AIX:
      return <AIXLogo width="30" height="30" color={color} />;
    case platforms.SUSE:
      return <SuseIcon width="35" height="35" color={color} />;
    case platforms.SOLARIS:
      return <SolarisLogo width="35" height="35" color={color} />;
    case environments.KUBERNETES:
      return <KubernetesIcon color={color} />;
    case environments.ISTIO:
      return <IstioIcon color={color} />;
    case environments.HELM:
      return <HelmIcon color={color} />;

    default:
      return <LinuxIcon width="30" height="30" color={color} />;
  }
}
