import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { VulnerabilityDataGrid } from "./components/vulnerability-data-grid";
import { Toolbar } from "common/atoms/toolbar";

interface VulnerabilityDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  assetId: string;
  assetName: string;
}

export const VulnerabilityDrawer = ({
  isOpen,
  onClose,
  assetId,
  assetName,
}: VulnerabilityDrawerProps) => {
  let headerText = "Vulnerabilities";
  let subHeader = assetName;

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: 3,
          width: "70%",
          overflow: "hidden",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Stack direction={"row"} alignContent="center">
          <Stack direction={"column"} justifyContent="center">
            <Typography variant="h6">
              {window.getCTTranslatedText(headerText)}
            </Typography>
            <Typography variant="body2" color="GrayText">
              {subHeader}
            </Typography>
          </Stack>
        </Stack>

        <IconButton size="small" aria-label="close drawer" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Stack
        justifyContent="space-between"
        style={{ flex: 1, position: "relative", overflow: "hidden" }}
      >
        <>
          <VulnerabilityDataGrid assetId={assetId} />
        </>
      </Stack>
    </Drawer>
  );
};
