export interface TemplateColumnRenderProps {
  tag: Tag;
  viewOnly?: boolean;
}

export interface Tag {
  tagId?: string;
  tagName?: string;
}

export interface TagFormProps {
  tagId?: string;
  tagName?: string;
}

export interface TagKeyInt {
  key: string;
  displayName: string;
  isCoreTag: boolean;
  count: string;
}

export interface TagValueInt {
  key: string;
  displayName: string;
  value: string;
  count: string;
}

export interface TagListProps {
  tagList: Array<TagKeyInt> | undefined;
}

export interface TagItemProps {
  tag?: TagKeyInt;
}

export enum CreateTagFieldKeys {
  Name = "key",
}
export interface CreateTagFormProps {
  displayName: string;
}
