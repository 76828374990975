import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellCheckboxRenderer,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import {
  isDiagnosticsAllowed,
  isRestartEnabled,
  isUpgradeEnabled,
} from "../../appliance-data-grid/helpers/columnHelpers";
import { Stack, Tooltip } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  renderToolTipDrawerAutoUpgradeColumns,
  renderToolTipDrawerDiagnosticsColumns,
  renderToolTipDrawerRestartColumns,
  renderToolTipDrawerUpgradeColumns,
} from "./drawerColumnHelpers";
import { AgentStatus } from "../../agent-status";
import { AgentName } from "../../agent-name";
import { AgentLocation } from "../../agent-location";
import { DeviceCount } from "../../device-count";
import { AgentMode } from "../../agent-mode";
import { AgentWanIp } from "../../agent-wanip";
import { AgentLanIp } from "../../agent-lanip";
import { AgentVersion } from "../../agent-version";
import { AutoUpgradeStatus } from "pages/agents/components/agent-drawers/helpers/constants";
import { HaRole } from "pages/appliances/types";

export const APPLIANCE_COLUMNS: GridColDef[] = [
  {
    field: "agentname",
    headerName: "Appliance Name",
    minWidth: 180,
    flex: 1,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentName agent={params.row} />;
    },
  },
  {
    field: "agentstatus",
    headerName: "Status",
    minWidth: 140,
    flex: 0.5,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentStatus agent={params.row} />;
    },
  },
  {
    field: "currentversion",
    headerName: "Version",
    minWidth: 150,
    flex: 0.3,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentVersion agent={params.row} />;
    },
  },
  {
    field: "agentlocation",
    headerName: "Location",
    minWidth: 180,
    flex: 0.4,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentLocation agent={params.row} />;
    },
  },
  {
    field: "manageddevicecount",
    headerName: "Device Count",
    width: 120,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        params?.row?.haRole !== HaRole.Standby && (
          <DeviceCount agent={params.row} />
        )
      );
    },
  },
  {
    field: "mode",
    headerName: "DHCP Mode",
    minWidth: 120,
    flex: 0.3,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentMode agent={params.row} viewOnly={true} />;
    },
  },
  {
    field: "wanip",
    headerName: "WAN IP",
    minWidth: 140,
    flex: 0.3,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentWanIp agent={params.row} viewOnly={true} />;
    },
  },
  {
    field: "lanip",
    headerName: "LAN IP",
    minWidth: 140,
    flex: 0.3,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentLanIp agent={params.row} viewOnly={true} />;
    },
  },
];

export const APPLIANCE_UPGRADE_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: params => {
      params.row.isUpgradeEnabled = isUpgradeEnabled(params.row);

      return (
        <Tooltip
          PopperProps={{ disablePortal: true }}
          placement={"right"}
          title={renderToolTipDrawerUpgradeColumns(params.row)}
          followCursor={false}
        >
          {params.row.isUpgradeEnabled ? (
            <GridCellCheckboxRenderer {...params} />
          ) : (
            <Stack>
              <CheckBoxOutlineBlankIcon
                sx={{ width: "20px" }}
                color="disabled"
              ></CheckBoxOutlineBlankIcon>
            </Stack>
          )}
        </Tooltip>
      );
    },
    type: "singleSelect",
  },
  ...APPLIANCE_COLUMNS,
];

export const APPLIANCE_RESTART_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: params => {
      params.row.isRestartEnabled = isRestartEnabled(params.row);

      return (
        <Tooltip
          PopperProps={{ disablePortal: true }}
          placement={"right"}
          title={renderToolTipDrawerRestartColumns(params.row)}
          followCursor={false}
        >
          {params.row.isRestartEnabled ? (
            <GridCellCheckboxRenderer {...params} />
          ) : (
            <Stack>
              <CheckBoxOutlineBlankIcon
                sx={{ width: "20px" }}
                color="disabled"
              ></CheckBoxOutlineBlankIcon>
            </Stack>
          )}
        </Tooltip>
      );
    },
    type: "singleSelect",
  },
  ...APPLIANCE_COLUMNS,
];

export const AGENT_AUTOUPGRADE_COLUMNS = (action: string): GridColDef[] => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: params => {
        let selectedRows =
          action === AutoUpgradeStatus.Disable
            ? params.row.autoUpgradeEnabled
            : !params.row.autoUpgradeEnabled;

        return (
          <Tooltip
            PopperProps={{ disablePortal: true }}
            placement={"right"}
            title={renderToolTipDrawerAutoUpgradeColumns(params.row, action)}
            followCursor={false}
          >
            {selectedRows ? (
              <GridCellCheckboxRenderer {...params} />
            ) : (
              <Stack>
                <CheckBoxOutlineBlankIcon
                  sx={{ width: "20px" }}
                  color="disabled"
                ></CheckBoxOutlineBlankIcon>
              </Stack>
            )}
          </Tooltip>
        );
      },
      type: "singleSelect",
    },
    ...APPLIANCE_COLUMNS,
  ];
};

export const APPLIANCE_DIAGNOSTICS_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: params => {
      return (
        <Tooltip
          PopperProps={{ disablePortal: true }}
          placement={"right"}
          title={renderToolTipDrawerDiagnosticsColumns(params.row)}
          followCursor={false}
        >
          {!params.row.isDiagnosticsRunning &&
          isDiagnosticsAllowed(params?.row) ? (
            <GridCellCheckboxRenderer {...params} />
          ) : (
            <Stack>
              <CheckBoxOutlineBlankIcon
                sx={{ width: "20px" }}
                color="disabled"
              ></CheckBoxOutlineBlankIcon>
            </Stack>
          )}
        </Tooltip>
      );
    },
    type: "singleSelect",
  },
  ...APPLIANCE_COLUMNS,
];
