import { Box, Grid, Link, Stack, Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import { AssetColumnRenderProps, AssetType } from "pages/assets/types";
import { Link as RouterLink } from "react-router-dom";

export function AssetPorts({ asset, viewOnly }: AssetColumnRenderProps) {
  if (!asset.assetId) {
    return <Box style={{ width: 70 }} />;
  }

  if (asset.type === AssetType.Service) {
    return (
      <Stack sx={{ minWidth: 32 }} alignItems="center" justifyContent="center">
        <Tooltip title="Not applicable for service type asset">
          <Typography variant="body2">N.A</Typography>
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="center"
      >
        <Tooltip title={!viewOnly ? "View unreviewed ports" : ""}>
          {viewOnly ? (
            <Typography variant="body2" sx={{ color: "warning.main" }}>
              {numeral(asset?.unreviewedPorts || 0).format("0a")}
            </Typography>
          ) : (
            <Link
              typography={"body2"}
              component={RouterLink}
              to={`/ports?filters=${encodeURIComponent(
                `listenportreviewed=unreviewed|assetname=${asset.assetName}`
              )}`}
              sx={{ color: "warning.main" }}
            >
              {numeral(asset?.unreviewedPorts || 0).format("0a")}
            </Link>
          )}
        </Tooltip>
        <Stack style={{ margin: "0 4px" }}>/</Stack>

        <Tooltip title={!viewOnly ? "View all ports" : ""}>
          {viewOnly ? (
            <Typography variant="body2">
              {numeral(asset?.totalPorts || 0).format("0a")}
            </Typography>
          ) : (
            <Link
              typography={"body2"}
              component={RouterLink}
              to={`/ports?filters=${encodeURIComponent(
                `assetname=${asset.assetName}`
              )}`}
              sx={{ color: "primary.main" }}
            >
              {numeral(asset?.totalPorts || 0).format("0a")}
            </Link>
          )}
        </Tooltip>
      </Grid>
    </Stack>
  );
}
