import { getDate } from "common/utils";
import { AgentActionStatusInfo } from "pages/agents/components/agent-data-grid/constants";
import {
  AgentCommands,
  AgentStatusType,
  PendingCommands,
} from "pages/agents/types";
import { Appliance } from "pages/appliances/types";
import prettyBytes from "pretty-bytes";
import * as semver from "semver";
import {
  applianceDiagnosticSupportedDevVersion,
  applianceDiagnosticSupportedMinVersion,
} from "../constants";

export const compareVersions = (
  version1: string,
  version2: string
): boolean => {
  const v1 = semver.coerce(version1) ?? "0.0.0";
  const v2 = semver.coerce(version2) ?? "0.0.0";
  return semver.gte(v1, v2);
};

export const compareTimestamps = (
  timestamp1: string,
  timestamp2: string
): boolean => {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);
  if (date1 >= date2) {
    return true;
  } else {
    return false;
  }
};
export const isUpgradeEnabled = (appliance: Appliance) =>
  appliance.agentUpgradeStatus === AgentActionStatusInfo.Synchronized &&
  appliance.agentUpgradeAvailable &&
  Boolean(appliance.agentId);

export const isRestartEnabled = (appliance: Appliance) =>
  appliance.agentRestartStatus === AgentActionStatusInfo.Synchronized &&
  Boolean(appliance.agentId);

export const getApplianceStatus = ({
  appliance,
  agentCommand,
}: {
  appliance: Appliance;
  agentCommand: AgentCommands;
}) => {
  const isAgentStatusPending = (appliance?.pendingCommands || []).some(
    (command: PendingCommands) => {
      return command.action === agentCommand;
    }
  );
  return isAgentStatusPending
    ? AgentActionStatusInfo.Pending
    : AgentActionStatusInfo.Synchronized;
};
export const isDiagnosticsRunning = (appliance: Appliance) => {
  return (
    appliance.agentDiagnosticsStatus === AgentActionStatusInfo.Pending &&
    Boolean(appliance.agentId)
  );
};

export const isDiagnosticsSupported = (agent: Appliance) => {
  const currentVersion = agent?.currentVersion;
  if (currentVersion === applianceDiagnosticSupportedDevVersion) {
    return true;
  }
  return compareVersions(
    currentVersion,
    applianceDiagnosticSupportedMinVersion
  );
};
export const isDiagnosticsAllowed = (agent: Appliance) => {
  return (
    isDiagnosticsSupported(agent) &&
    agent?.agentStatus === AgentStatusType.Active
  );
};
export const mapAppliance = (appliance: Appliance) => {
  appliance.rowId = appliance.agentId;
  appliance.agentUpgradeStatus = getApplianceStatus({
    appliance,
    agentCommand: AgentCommands.UPGRADE,
  });
  appliance.agentRestartStatus = getApplianceStatus({
    appliance,
    agentCommand: AgentCommands.RESTART,
  });
  appliance.agentDiagnosticsStatus = getApplianceStatus({
    appliance,
    agentCommand: AgentCommands.COLLECT_DIAGNOSTICS,
  });
  appliance.isDiagnosticsRunning = isDiagnosticsRunning(appliance);
  appliance.isDiagnosticsSupported = isDiagnosticsSupported(appliance);
  appliance.isUpgradeEnabled = isUpgradeEnabled(appliance);
  appliance.isRestartEnabled = isRestartEnabled(appliance);
  appliance.agentCreated = getDate(appliance?.agentCreated ?? "");
  appliance.cpuUtilizationStr = appliance?.cpuUtilization
    ? `${appliance?.cpuUtilization} ${
        appliance?.cpuUtilization && appliance?.cpuUtilization > 0 ? "%" : ""
      }`
    : "";
  appliance.memoryUtilizationStr = appliance?.memoryUtilization
    ? prettyBytes(appliance?.memoryUtilization * 1000 * 1000) // converting it to bytes and formatting
    : "";
};
