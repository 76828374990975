import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Paper, Stack, Tooltip, Typography } from "@mui/material";
import {
  DataGridProProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridColDef,
} from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import groupBy from "lodash/groupBy";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { useExportCSV } from "modules/export-csv/hooks";
import {
  CheckboxSelectionStore,
  CheckboxSelectionType,
} from "modules/recommendation-workflows/components/IPs-datagrid/store/types";
import { Scope } from "modules/scope-metadata/types";
import numeral from "numeral";
import React, { useEffect, useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { useAggregateAPI } from "../path-recommendations/PathRecommendations";
import { CIDR } from "./components/CIDR";
import { IPSelectionCheckbox } from "./components/IP-selection-checkbox";

export interface MergedIPsForInboundAndOutBound {
  IP: string;
  IPS?: MergedIPsForInboundAndOutBound[];
  IPCount?: number;
  IPCoverage: number;
  subnet?: string;
  inboundCount: number;
  outboundCount: number;
  index: number;
  inboundCoverage: number;
  outboundCoverage: number;
  path?: string[];
  domain: string | null;
}

interface IPsDataGridProps {
  setIPsSelection: (selectionModel: string[]) => void;
  data: MergedIPsForInboundAndOutBound[];
  isLoading: boolean;
  useStore: CheckboxSelectionStore;
}

function getRowId(agg: MergedIPsForInboundAndOutBound) {
  if (agg.IPS?.length) {
    return `subnet-${agg.subnet}`;
  }
  return `ip-${agg.IP}`;
}

const IPsDataGridWrapper = ({
  setIPsSelection,
  data,
  isLoading,
  useStore,
}: IPsDataGridProps) => {
  const [recommendationList, setRecommendationList] = useState(data ?? []);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const PATH_IPs_COLUMNS = useMemo(() => {
    const columns: GridColDef<MergedIPsForInboundAndOutBound>[] = [
      {
        field: "checkbox",
        headerName: "",
        width: 60,
        renderCell: params => (
          <IPSelectionCheckbox
            {...params}
            recommendationList={recommendationList}
            useStore={useStore}
          />
        ),
        type: "singleSelect",
        sortable: false,
      },
      {
        field: "CIDR",
        headerName: "CIDR",
        flex: 1,
        renderCell: params => <CIDR {...params} useStore={useStore} />,
        valueGetter: params => {
          const hierarchy = params.row.path;
          return hierarchy?.[hierarchy.length - 1];
        },
      },
      {
        field: "IPCount",
        headerName: "IPs with traffic",
        flex: 1,
        sortingOrder: ["asc", "desc"],
        renderCell: params => {
          if (!params.row.IPCount) {
            return null;
          }
          return (
            <Stack>
              <Typography variant="body2">
                {`${numeral(params.row.IPCount).format("0a")}`}
              </Typography>

              <Typography
                variant="caption"
                color={() => {
                  if (
                    params.row.IPCoverage >= 0.01 &&
                    params.row.IPCoverage < 0.7
                  ) {
                    return "info.main";
                  }
                  if (params.row.IPCoverage >= 0.7) {
                    return "success.main";
                  }
                  return undefined;
                }}
              >
                {`${numeral(params.row.IPCoverage).format(
                  params.row.IPCoverage > 0.1 ? "0%" : "0.00%"
                )} ${window.getCTTranslatedText("coverage")}`}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "domain",
        headerName: "Domain",
        flex: 1,
        renderCell: params => {
          if (!params.row.domain) {
            return null;
          }

          return (
            <Tooltip title={params.row.domain}>
              <Typography
                variant="body2"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {params.row.domain}
              </Typography>
            </Tooltip>
          );
        },
      },
    ];

    if (
      !userPermissions.has("UPDATE_NAMED_NETWORK") &&
      !userPermissions.has("CREATE_NAMED_NETWORK")
    ) {
      columns.shift();
    }

    return columns;
  }, [recommendationList, useStore, userPermissions]);

  useEffect(() => {
    setRecommendationList(data);
  }, [data]);

  const {
    triggerExportAsCsv,
    getExportStatus,
    getUrlToDownload,
    resetDownloadUrl,
  } = useExportCSV({
    useApi: useAggregateAPI,
    searchCriteria: "",
    page: 0,
    sort: [],
    sourceCriteria: "",
    destinationCriteria: "",
    scope: Scope.Path,
    frontendOnly: true,
  });

  const count = useMemo(() => {
    let grouped = groupBy(data ?? [], "subnet");
    return Object.keys(grouped ?? {})?.length ?? data?.length;
  }, [data]);

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = row => row.path;

  return (
    <Stack
      alignItems="flex-start"
      spacing={0}
      direction={"column"}
      sx={{ width: "100%", flex: 1, overflow: "hidden" }}
    >
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          flex: 1,
          "& .cidr-header": {
            paddingLeft: 8,
          },
          overflow: "hidden",
          ".MuiDataGrid-pinnedColumns--right": {
            border: "none !important",
          },
          ".MuiDataGrid-pinnedColumnHeaders--right": {
            border: "none !important",
          },
        }}
      >
        <MemoizedIPDataGridImpl
          columns={PATH_IPs_COLUMNS}
          isLoading={isLoading}
          rows={recommendationList || []}
          rowCount={count || recommendationList.length}
          getTreeDataPath={getTreeDataPath}
          triggerExportAsCsv={triggerExportAsCsv}
          getExportStatus={getExportStatus}
          getUrlToDownload={getUrlToDownload}
          resetDownloadUrl={resetDownloadUrl}
          setIPsSelection={setIPsSelection}
          useStore={useStore}
        />
      </Paper>
    </Stack>
  );
};

export const IPsDataGrid = React.memo(IPsDataGridWrapper);

const IPDataGridImpl = ({
  columns,
  isLoading,
  rows,
  rowCount,
  getTreeDataPath,
  triggerExportAsCsv,
  getExportStatus,
  getUrlToDownload,
  resetDownloadUrl,
  setIPsSelection,
  useStore,
}: {
  columns: GridColDef<MergedIPsForInboundAndOutBound>[];
  isLoading: boolean;
  rows: MergedIPsForInboundAndOutBound[];
  rowCount: number;
  getTreeDataPath: (row: any) => string[];
  triggerExportAsCsv: (selectedData: unknown[] | undefined) => Promise<void>;
  getExportStatus: () => boolean;
  getUrlToDownload: () => string | undefined;
  resetDownloadUrl: () => void;
  setIPsSelection: (selectionModel: string[]) => void;
  useStore: CheckboxSelectionStore;
}) => {
  const storeSelection = useStore(useShallow(state => state.selection));

  const resetSelection = useStore(useShallow(state => state.resetSelection));

  useEffect(() => {
    return () => resetSelection();
  }, [resetSelection]);

  const memoizedSelection = useMemo(() => {
    return Object.values(storeSelection ?? {})
      .map(obj => {
        const selectedIPs = Object.keys(obj?.selectedIPs ?? {});
        if (obj?.selectionType !== CheckboxSelectionType.SUBNET) {
          return selectedIPs;
        }

        return [obj?.subnet, ...selectedIPs];
      })
      .flat();
  }, [storeSelection]);

  const selectedRawData: Array<MergedIPsForInboundAndOutBound> | undefined =
    useMemo(() => {
      let result: any = [];
      let list = rows;
      if (memoizedSelection.length > 0) {
        list = (list ?? [])?.filter((row: MergedIPsForInboundAndOutBound) => {
          return memoizedSelection.indexOf(getRowId(row)) !== -1;
        });
      }
      list.forEach((row: MergedIPsForInboundAndOutBound) => {
        if (row.IPS?.length) {
          row.IPS?.forEach(ip => {
            result.push({
              IP: ip.IP,
              assetsReceivingTraffic: ip.inboundCount,
              assetsSendingTraffic: ip.outboundCount,
              subnet: ip.subnet,
            });
          });
        }
      });
      return result;
    }, [memoizedSelection, rows]);

  useEffect(() => {
    if (storeSelection && Object.entries(storeSelection).length > 0) {
      const IPsList: string[] = [];

      rows.forEach(path => {
        const row = storeSelection[getRowId(path)];
        if (!row) {
          return;
        }

        if (row.selectionType === CheckboxSelectionType.SUBNET) {
          IPsList.push(row.subnet.split("-")[1]);
        } else {
          const selectedIPs = Object.keys(row.selectedIPs ?? {})
            .filter(key => row.selectedIPs?.[key])
            .map(ip => ip.split("-")[1] + "/32");

          IPsList.push(...selectedIPs);
        }
      });

      setIPsSelection(IPsList);
    } else {
      setIPsSelection([]);
    }
  }, [storeSelection, memoizedSelection, rows, setIPsSelection]);

  return (
    <DataGrid<MergedIPsForInboundAndOutBound>
      checkboxSelection={false}
      rowSelectionModel={memoizedSelection ?? []}
      initialState={{
        sorting: {
          sortModel: [{ field: "IPCount", sort: "desc" }],
        },
        pinnedColumns: {
          right: [GRID_TREE_DATA_GROUPING_FIELD],
        },
      }}
      components={{
        TreeDataExpandIcon: () => <KeyboardArrowDown />,
        TreeDataCollapseIcon: () => <KeyboardArrowUp />,
      }}
      groupingColDef={{
        hideDescendantCount: true,
        width: 55,
        headerClassName: "cidr-header",
        headerName: "",
        valueFormatter: () => "",
      }}
      // isRowSelectable={params => {
      //   return params.id.toString().startsWith("subnet");
      // }}
      rowHeight={64}
      columns={columns}
      getRowId={(agg: MergedIPsForInboundAndOutBound) => getRowId(agg)}
      paginationMode="client"
      sortingMode="client"
      pagination
      isLoading={isLoading}
      rows={rows}
      rowCount={rowCount}
      treeData
      getTreeDataPath={getTreeDataPath}
      triggerExportAsCsv={triggerExportAsCsv}
      getExportStatus={getExportStatus}
      getUrlToDownload={getUrlToDownload}
      resetDownloadUrl={resetDownloadUrl}
      frontendOnly={true}
      selectedRawData={selectedRawData}
    />
  );
};

const MemoizedIPDataGridImpl = React.memo(IPDataGridImpl);
