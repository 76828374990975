import ConfirmationDialog from "common/molecules/confirmation-dialog/ConfirmationDialog";
import { MoreOptionsMenu } from "common/molecules/more-options-menu/MoreOptionsMenu";
import { MenuOption } from "common/molecules/more-options-menu/types";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useState } from "react";
import { IDP_MODE } from "../IDP-Form/IDPForm";
import { IDPInfoDrawer } from "../IDP-Info-Drawer";
import { TokenGeneration } from "../TokenGeneration";
import { useDeleteIdpAPI } from "../hooks";
import { useIDPStore } from "../store";
import { IDPColumnRenderProps } from "./../types";

export const IDPActions = ({ idp, viewOnly = false }: IDPColumnRenderProps) => {
  const moreActionMenuOptions: Array<MenuOption> = [
    {
      label: "EditIDP",
      handler: () => {
        setIDPInfoDrawer(true);
      },
    },
    {
      label: "DeleteIDP",
      handler: () => {
        setDeleteIDPDrawer(true);
      },
    },
  ];

  const requestAPIRefresh = useIDPStore(store => store.requestAPIRefresh);
  const [showIDPInfoDrawer, setIDPInfoDrawer] = useState(false);
  const [showDeleteIDPDrawer, setDeleteIDPDrawer] = useState(false);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const deleteIdpAPI = useDeleteIdpAPI(idp.idpID ?? "");

  const handleClose = () => {
    setIDPInfoDrawer(false);
    requestAPIRefresh();
  };

  const handlerOnDeleteAuthConfig = () => {
    deleteIdpAPI.mutate(
      {},
      {
        onSuccess: response => {
          requestAPIRefresh();
          setDeleteIDPDrawer(false);
          setSnackbar(true, SnackBarSeverity.Success, "DeletedSuccessfully");
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      },
    );
  };

  return (
    <>
      <TokenGeneration idp={idp} />
      <MoreOptionsMenu menuOptions={moreActionMenuOptions} color="primary" />

      {showIDPInfoDrawer && (
        <IDPInfoDrawer
          isOpen={showIDPInfoDrawer}
          title={window.getCTTranslatedText("Edit IdP")}
          onCancel={handleClose}
          idpData={idp}
          mode={IDP_MODE.EDIT}
        />
      )}

      {showDeleteIDPDrawer && (
        <ConfirmationDialog
          title="DeleteIDP?"
          info="DeleteIDPWarning"
          subInfo="AreYouSureYouWantToContinue"
          open={showDeleteIDPDrawer}
          handleClose={() => setDeleteIDPDrawer(false)}
          onConfirmation={handlerOnDeleteAuthConfig}
          isLoading={Boolean(deleteIdpAPI.isLoading)}
          primaryButtonText={"Delete"}
        />
      )}
    </>
  );
};
