import { isValidSubnet } from "common/utils";
import {
  PORTLESS_PROTOCOLS,
  PathDirection,
  PathStatus,
} from "pages/paths/types";
import { PortStatus } from "pages/ports/types";
import { TemplateType } from "pages/templates/types";
import {
  PathFieldKeys,
  PathFormInt,
  PathRuleDirection,
  PathRuleInt,
  PortFieldKeys,
  PortFormInt,
  PortRuleInt,
} from "../types";
import {
  isInvalidProcessPath,
  isValidDomain,
  isValidIp,
  isValidPortNumber,
} from "./validators";
import { Scope } from "modules/scope-metadata/types";
import { Suggestion } from "modules/ct-scope-auto-suggest/types";

const MIN_PORT_NUMBER = 0;
const MAX_PORT_NUMBER = 65535;

interface RuleValidatorProps {
  templatePorts: Array<PortFormInt>;
  templatePaths: Array<PathFormInt>;
  templateType: TemplateType;
}

export function ruleValidator({
  templatePorts,
  templatePaths,
  templateType,
}: RuleValidatorProps) {
  const isPortsValid = templatePorts.length
    ? templatePorts.every(port => {
        return isValidPort(port);
      })
    : true;
  const isPathsValid = templatePaths.length
    ? templatePaths.every(path => {
        return isValidPath(path);
      })
    : true;

  return isPortsValid && isPathsValid;
}

export function isValidPath(path: PathFormInt): boolean {
  const isPortValid = PORTLESS_PROTOCOLS.includes(path.protocol?.toUpperCase())
    ? true
    : isValidPortNumber(path[PathFieldKeys.Port]?.trim());

  const isValidRange = isValidPortRange(path[PathFieldKeys.Port]?.trim());
  if (!isValidRange && !isPortValid) {
    return false;
  }

  const isProtocolValid = path[PathFieldKeys.Protocol]?.trim().length > 0;
  if (!isProtocolValid) {
    return false;
  }
  const isValidDirection = path[PathFieldKeys.Direction];
  if (!isValidDirection) {
    return false;
  }

  if (path[PathFieldKeys.Direction] === PathDirection.Inbound) {
    const source = path[PathFieldKeys.Source];
    if (!source) {
      return false;
    }

    const sourceSuggestion = source as Suggestion;
    if (
      sourceSuggestion.type === Scope.Network ||
      sourceSuggestion.type === Scope.TagBasedPolicy ||
      sourceSuggestion.type === Scope.Asset
    ) {
      return Boolean(sourceSuggestion?.value);
    } else if (
      sourceSuggestion.type === "IP" ||
      sourceSuggestion.type === "Default"
    ) {
      return (
        isValidIp(sourceSuggestion?.value) ||
        isValidSubnet(sourceSuggestion?.value)
      );
    } else if (!sourceSuggestion.type) {
      return isValidIp(source as string) || isValidSubnet(source as string);
    }
  }

  if (path[PathFieldKeys.Direction] === PathDirection.Outbound) {
    const destination = path[PathFieldKeys.Destination];
    if (!destination) {
      return false;
    }

    const destinationSuggestion = destination as Suggestion;
    if (
      destinationSuggestion.type === Scope.Network ||
      destinationSuggestion.type === Scope.TagBasedPolicy ||
      destinationSuggestion.type === Scope.Asset
    ) {
      return Boolean(destinationSuggestion?.value);
    } else if (
      destinationSuggestion.type === "IP" ||
      destinationSuggestion.type === "Default"
    ) {
      return (
        isValidIp(destinationSuggestion?.value) ||
        isValidSubnet(destinationSuggestion?.value)
      );
    } else if (destinationSuggestion.type === "Domain") {
      return isValidDomain(destinationSuggestion?.value);
    } else if (!destinationSuggestion.type) {
      return (
        isValidIp(destination as string) || isValidSubnet(destination as string)
      );
    }
  }

  return false;
}

export function getPathRules(pathList: Array<PathFormInt>): Array<PathRuleInt> {
  let rules: Array<PathRuleInt> = [];
  if (!pathList?.length) {
    return rules;
  }

  rules = pathList
    .filter(rule => isValidPath(rule))
    .map((rule): PathRuleInt => {
      let pathRule: PathRuleInt = {
        [PathFieldKeys.Port]:
          rule[PathFieldKeys.Port].trim().length > 0
            ? rule[PathFieldKeys.Port].trim()
            : "any",
        [PathFieldKeys.Protocol]: rule[PathFieldKeys.Protocol].trim(),
        [PathFieldKeys.Direction]: rule[PathFieldKeys.Direction],
        reviewed: PathStatus.Allow,
      };

      if (rule[PathFieldKeys.Direction] === PathDirection.Inbound) {
        const source = rule[PathFieldKeys.Source];
        const sourceSuggestion = source as Suggestion;

        if (sourceSuggestion.type === Scope.Network) {
          pathRule[PathRuleDirection.sourceNamedNetwork] = {
            namedNetworkId: sourceSuggestion?.value,
            namedNetworkName: sourceSuggestion?.displayName ?? "",
          };
        } else if (sourceSuggestion.type === Scope.TagBasedPolicy) {
          pathRule[PathRuleDirection.sourceTagBasedPolicy] = {
            tagBasedPolicyId: sourceSuggestion?.value,
            tagBasedPolicyName: sourceSuggestion?.displayName ?? "",
          };
        } else if (sourceSuggestion.type === Scope.Asset) {
          pathRule[PathRuleDirection.sourceAssetId] = sourceSuggestion?.value;
        } else {
          pathRule[PathRuleDirection.srcIp] =
            sourceSuggestion?.value ?? (source as string);
        }
      }

      if (rule[PathFieldKeys.Direction] === PathDirection.Outbound) {
        const destination = rule[PathFieldKeys.Destination];
        const destinationSuggestion = destination as Suggestion;

        if (destinationSuggestion.type === Scope.Network) {
          pathRule[PathRuleDirection.destinationNamedNetwork] = {
            namedNetworkId: destinationSuggestion?.value,
            namedNetworkName: destinationSuggestion?.displayName ?? "",
          };
        } else if (destinationSuggestion.type === Scope.TagBasedPolicy) {
          pathRule[PathRuleDirection.destinationTagBasedPolicy] = {
            tagBasedPolicyId: destinationSuggestion?.value,
            tagBasedPolicyName: destinationSuggestion?.displayName ?? "",
          };
        } else if (destinationSuggestion.type === Scope.Asset) {
          pathRule[PathRuleDirection.destinationAssetId] =
            destinationSuggestion?.value;
        } else if (destinationSuggestion?.type === "Domain") {
          pathRule[PathRuleDirection.domain] =
            destinationSuggestion?.value ?? (destination as string);
        } else {
          pathRule[PathRuleDirection.destIp] = [
            destinationSuggestion?.value ?? (destination as string),
          ];
        }
      }

      return pathRule;
    });
  return rules;
}

export function resetPortRuleStatus({
  templateType,
  portList,
  setPortList,
}: {
  templateType: TemplateType;
  portList: Array<PortFormInt>;
  setPortList: (portList: Array<PortFormInt>) => void;
}) {
  let newPortList = [...portList];
  newPortList = newPortList.map(port => {
    const portObj = { ...port };
    portObj[PortFieldKeys.Status] =
      templateType === TemplateType.BlockTemplate ? PortStatus.Deny : "";
    return portObj;
  });
  setPortList(newPortList);
}

export const isValidPortRange = (portRange: string) => {
  if (portRange.includes(",") && portRange.includes("-")) {
    return false;
  }

  if (portRange.includes(",")) {
    const ports = portRange.split(",").map(port => (port ? Number(port) : -1));
    for (const port of ports) {
      if (isNaN(port) || port < MIN_PORT_NUMBER || port > MAX_PORT_NUMBER) {
        return false;
      }
    }
    return true;
  }

  const [startPort, endPort] = portRange.split("-").map(Number);

  if (
    !endPort ||
    startPort >= endPort ||
    startPort < MIN_PORT_NUMBER ||
    startPort > MAX_PORT_NUMBER ||
    endPort < MIN_PORT_NUMBER ||
    endPort > MAX_PORT_NUMBER
  ) {
    return false;
  }

  if (endPort - startPort > 20) {
    return false;
  }

  return true;
};

export function isValidPort(port: PortFormInt): boolean {
  let isValid = false;
  const process = port[PortFieldKeys.Process]?.trim();
  const isPortValid = isValidPortNumber(port[PortFieldKeys.Port]?.trim());
  const isValidRange = isValidPortRange(port[PortFieldKeys.Port]?.trim());
  const isProtocolValid = port[PortFieldKeys.Protocol]?.trim().length > 0;
  const isPortStatusValid = port[PortFieldKeys.Status]?.trim().length > 0;
  const isValidProcess = process ? isInvalidProcessPath(process) : true;

  if (
    (isValidRange || isPortValid) &&
    isProtocolValid &&
    isPortStatusValid &&
    isValidProcess
  ) {
    isValid = true;
  } else if (
    PORTLESS_PROTOCOLS.includes(port.listenPortProtocol?.toUpperCase()) &&
    isPortStatusValid
  ) {
    isValid = true;
  } else {
    isValid = false;
  }

  return isValid;
}

export function getPortRules(portList: Array<PortFormInt>) {
  if (portList && portList.length > 0) {
    let portRuleList: Array<PortRuleInt> = portList.reduce(
      (filtered: any, port) => {
        if (isValidPort(port)) {
          let portRuleObj: PortRuleInt = {
            [PortFieldKeys.Port]: port[PortFieldKeys.Port].trim(),
            [PortFieldKeys.Protocol]: port[PortFieldKeys.Protocol].trim(),
            [PortFieldKeys.Status]: port[PortFieldKeys.Status].trim(),
            [PortFieldKeys.Process]: port[PortFieldKeys.Process].trim(),
          };
          filtered.push(portRuleObj as any);
        }
        return filtered;
      },
      []
    );
    return portRuleList;
  }
  return [];
}

type RuleType = PortRuleInt | PathRuleInt;

function isPortRule(rule: RuleType): rule is PortRuleInt {
  return "listenPort" in rule;
}

export function getAllRules(ruleList: RuleType[]) {
  return ruleList?.reduce((acc: RuleType[], templateRule: RuleType) => {
    const portKey = isPortRule(templateRule) ? "listenPort" : "port";
    const port = isPortRule(templateRule)
      ? templateRule.listenPort
      : templateRule.port;

    if (isValidPortRange(port)) {
      if (port.includes(",")) {
        const ports = port.split(",").map(Number);
        ports.forEach(currentPort => {
          acc.push({ ...templateRule, [portKey]: currentPort.toString() });
        });
      } else {
        const [startPort, endPort] = port.split("-").map(Number);
        for (
          let currentPort = startPort;
          currentPort <= endPort;
          currentPort++
        ) {
          acc.push({ ...templateRule, [portKey]: currentPort.toString() });
        }
      }
    } else {
      acc.push(templateRule);
    }

    return acc;
  }, []);
}
