import { INTEGRATIONS } from "pages/Integration/constants";
import { AssetType } from "pages/assets/types";
import { useEffect, useState } from "react";
import { useAssetTypeFeature } from "./useAssetTypeFeature";
import { useFeatureListAPI } from "./useFeatureListAPI";

const localEnv = ["ng-local"];
const devEnv = ["ng-dev", "ng-dev2", "ng-dev3", "demo"];

export interface EnvDetails {
  isFeatureEnabled: boolean;
  featureConfig: any;
}

export const FEATURES = {
  WORK_REQUEST: "WORK_REQUEST",
  EDIT_TEMPLATE_DETAIL: "EDIT_TEMPLATE_DETAIL",
  EDIT_NETWORK_DETAIL: "EDIT_NETWORK_DETAIL",
  CT_ASSISTANT: "CT_ASSISTANT",
  EXPORT_CSV: "EXPORT_CSV",
  SOURCE_MENU_OPTION: "SOURCE_MENU_OPTION",
  DATA_GRID_COLUMNS_CONTROLS: "DATA_GRID_COLUMNS_CONTROLS",
  SERVER_USER_PLATFORMS: "SERVER_USER_PLATFORMS",
  DARWIN_ALERT: "DARWIN_ALERT",
  X86_SUPPORT: "X86_SUPPORT",
  VIZ_MAKE_TEST_DENIED: "MAKE_TEST_DENIED",
  EXPERIMENTAL_ASSETS_FACETS: "EXPERIMENTAL_ASSETS_FACETS",
  EXPERIMENTAL_PORTS_FACETS: "EXPERIMENTAL_PORTS_FACETS",
  EXPERIMENTAL_PATHS_FACETS: "EXPERIMENTAL_PATHS_FACETS",
  EXPERIMENTAL_INTEGRATIONS: "EXPERIMENTAL_INTEGRATIONS",
  PROGRESSIVE: "PROGRESSIVE",
  CMDB_INTEGRATIONS: "CMDB_INTEGRATIONS",
  TRAFFIC_CONFIG: "TRAFFIC_CONFIG",
  NS_TRAFFIC_CONFIG: "NS_TRAFFIC_CONFIG",
  ASSET_STATUS_SLIDER_UI: "ASSET_STATUS_SLIDER_UI",
  APPLIANCE_DETAIL: "APPLIANCE_DETAIL",
  USER_GROUP_COLUMN: "USER_GROUP_COLUMN",
  PANOPTIC_MAP: "PANOPTIC_MAP",
  USER_GROUP_FILTERS: "USER_GROUP_FILTERS",
  DEVICES_FILTERS: "DEVICES_FILTERS",
  USER_SEGMENTATION: "USER_SEGMENTATION",
  CROWDSTRIKE_EDR: "CROWDSTRIKE_EDR",
  SEGMENTS: "SEGMENTS",
  VISX_POLICY_CONTROLS: "VISX_POLICY_CONTROLS",
  NOTIFICATION_MEDIUM_TYPE: "NOTIFICATION_MEDIUM_TYPE",
  PROCESS_BASED_TEMPLATE: "PROCESS_BASED_TEMPLATE",
};

const FEATURE_TYPE: { [key: string]: AssetType | INTEGRATIONS } = {
  [FEATURES.USER_GROUP_COLUMN]: AssetType.Endpoint,
  [FEATURES.USER_GROUP_FILTERS]: AssetType.Endpoint,

  [FEATURES.DEVICES_FILTERS]: AssetType.Device,

  [FEATURES.USER_SEGMENTATION]: INTEGRATIONS.SCIM_USERPROVISIONING,
  [FEATURES.CROWDSTRIKE_EDR]: INTEGRATIONS.CROWDSTRIKE_EDR,
};

const emptyMap: { [key: string]: boolean } = {};

const GA_MAP: { [key: string]: boolean } = {
  [FEATURES.TRAFFIC_CONFIG]: true,
  [FEATURES.WORK_REQUEST]: true,
  [FEATURES.DATA_GRID_COLUMNS_CONTROLS]: true,
  [FEATURES.SEGMENTS]: true,
  ...Object.keys(FEATURE_TYPE).reduce((p, f) => {
    p[f] = true;
    return p;
  }, emptyMap),
};

export function useFeatureFlagControl(featureName: string): EnvDetails {
  const subDomain = window.location.host.split(".")[0];
  const { data: features } = useFeatureListAPI();
  const [isFeatureLoaded, setIsFeatureLoaded] = useState(false);
  const activeTypeFeature = useAssetTypeFeature();

  useEffect(() => {
    if (features?.whitelisted && features?.blacklisted) {
      setIsFeatureLoaded(true);
    }
  }, [features?.blacklisted, features?.whitelisted]);

  let isLocal = false;
  let isDev = false;

  localEnv.forEach(env => {
    if (subDomain === env) {
      isLocal = true;
    }
  });

  devEnv.forEach(env => {
    if (subDomain === env) {
      isDev = true;
    }
  });

  const isAlpha = isLocal || isDev;

  const getIsFeatureEnabled = (): EnvDetails => {
    let featureType = FEATURE_TYPE[featureName];
    if (featureType && !activeTypeFeature.includes(featureType)) {
      return { isFeatureEnabled: false, featureConfig: undefined };
    }

    if (isAlpha) {
      return { isFeatureEnabled: true, featureConfig: undefined };
    }

    if (!isFeatureLoaded || !features) {
      return { isFeatureEnabled: false, featureConfig: undefined };
    }

    if (features?.blacklisted?.[featureName]) {
      return {
        isFeatureEnabled: false,
        featureConfig: features?.blacklisted?.[featureName]?.config,
      };
    }

    if (features?.whitelisted?.[featureName] || GA_MAP[featureName]) {
      return {
        isFeatureEnabled: true,
        featureConfig: features?.whitelisted?.[featureName]?.config,
      };
    }

    return { isFeatureEnabled: false, featureConfig: undefined };
  };

  const { isFeatureEnabled, featureConfig } = getIsFeatureEnabled();
  return {
    isFeatureEnabled,
    featureConfig,
  };
}
