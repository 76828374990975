import { Box, Stack, TextField, Typography } from "@mui/material";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import React from "react";

interface AnnotationProps {
  setComment?: (value: string | undefined) => void;
  hideTitle?: boolean;
  comment?: string;
}

export const Annotation = ({
  setComment,
  hideTitle = false,
  comment,
}: AnnotationProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment?.(e.target.value);
  };

  return (
    <>
      {!hideTitle && (
        <Stack direction={"row"} alignItems="center" pt={5}>
          <CommentOutlinedIcon
            sx={{ color: theme => theme.palette.text.secondary }}
          />
          <Typography sx={{ ml: 1 }} variant="subtitle1">
            {window.getCTTranslatedText("Comments")}
          </Typography>
        </Stack>
      )}

      <Box sx={{ width: "100%", pt: 2 }}>
        <TextField
          sx={{ width: "100%" }}
          id="comments"
          placeholder={window.getCTTranslatedText("Enter comment ...")}
          variant="outlined"
          value={comment ?? ""}
          onChange={handleOnChange}
        />
      </Box>
    </>
  );
};
