import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "modules/facets/types";
import {
  useAssetsFacetsOrder,
  useFacetOrderAlpha,
} from "pages/assets/constants";
import { usePathsFacetsOrder } from "pages/paths/constants";
import { usePortsFacetsOrder } from "pages/ports/constants";
import { useMemo } from "react";
import { MatchedAssetCount } from "./components/matched-asset-count";
import { MatchingTags } from "./components/matching-tags";
import { RuleCriteria } from "./components/rule-criteria";
import { RuleName } from "./components/rule-name";
import { TagRuleActions } from "./components/tag-rule-actions";
import { TagRule } from "./types";

export const useFacetGroupInfo = () => {
  const { isFeatureEnabled } = useFeatureFlagControl(FEATURES.PROGRESSIVE);
  const facetOrderAlpha = useFacetOrderAlpha();
  const portsFacetOrder = usePortsFacetsOrder();
  const pathsFacetOrder = usePathsFacetsOrder();
  const assetsFacetsOrder = useAssetsFacetsOrder();

  const FACET_GROUP_INFO: FacetGroupInfo = useMemo(() => {
    const commonFacetOrder = {
      ...portsFacetOrder,
      ...pathsFacetOrder,
      listenportadress: FacetGroups.Ports,
      listenprocessnames: FacetGroups.Ports,
      listenlocalip: FacetGroups.Ports,
    };

    return isFeatureEnabled
      ? {
          ...facetOrderAlpha,
          ...commonFacetOrder,
        }
      : {
          ...assetsFacetsOrder,
          ...commonFacetOrder,
        };
  }, [
    isFeatureEnabled,
    facetOrderAlpha,
    portsFacetOrder,
    pathsFacetOrder,
    assetsFacetsOrder,
  ]);

  return FACET_GROUP_INFO;
};

export const BASE_TAG_RULES_COLS: GridColDef<TagRule>[] = [
  {
    field: "ruleName",
    headerName: "Name",
    width: 140,
    flex: 0.7,
    sortable: false,
    sortingOrder: ["asc", "desc"],
    renderCell: params => {
      return <RuleName rule={params.row} />;
    },
  },
  {
    field: "ruleCriteria",
    headerName: "Criteria",
    width: 140,
    flex: 0.7,
    sortable: false,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <RuleCriteria rule={params.row} />;
    },
  },
  {
    field: "onMatch",
    headerName: "Assigned Tags",
    width: 140,
    flex: 0.6,
    sortable: false,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <MatchingTags rule={params.row} />;
    },
  },
  {
    field: "matchingAssets",
    headerName: "Used by",
    width: 160,
    sortable: false,
    sortingOrder: ["asc", "desc"],
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <MatchedAssetCount rule={params?.row} />;
    },
  },
];

export const TAG_RULES_COLS: GridColDef<TagRule>[] = [
  ...BASE_TAG_RULES_COLS,
  {
    field: "actions",
    headerName: "",
    width: 60,
    sortable: false,
    sortingOrder: ["asc", "desc"],
    renderCell: params => {
      return <TagRuleActions rule={params.row} />;
    },
  },
];
