import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  DialogContent,
  // Typography,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  // Asset,
  AssetMetaDataViewProps,
} from "pages/assets/types";
import { TagsRenderer } from "./components/tags-renderer";
// import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
// import { AssetProperties } from "./components/asset-properties";
import { Toolbar } from "common/atoms/toolbar";
import { Widget } from "common/atoms/widget";

export function MetadataView({
  asset,
  viewMore,
  handleViewMore,
}: AssetMetaDataViewProps) {
  if (!asset?.assetId) {
    return <Box style={{ width: "100%" }} />;
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={viewMore}
        onClose={() => handleViewMore(false)}
        PaperProps={{
          sx: {
            p: 0,
            width: "786px",
          },
          elevation: 1,
        }}
      >
        <Toolbar />
        <DialogContent sx={{ width: "100%", height: "100%" }}>
          <Stack
            alignItems="flex-start"
            sx={{ position: "relative", width: "100%" }}
          >
            <Tooltip title="Close Drawer">
              <IconButton
                size="large"
                aria-label="close drawer"
                onClick={() => handleViewMore(false)}
                sx={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                  zIndex: 2,
                }}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Stack>

          {/* <Stack alignItems={"flex-start"}>
            <Typography variant="h6" sx={{ my: 2 }}>
              Asset Properties
            </Typography>
          </Stack> */}
          <Box sx={{ minWidth: "100%" }}>
            <Stack
              alignItems="center"
              justifyContent="center"
              spacing={4}
              sx={{ width: "100%" }}
            >
              <Widget header={`Cloud Tags`}>
                <TagsRenderer asset={asset} />
              </Widget>
              {/* <Widget header={`Asset Properties`}>
                <AssetProperties asset={asset} />
              </Widget> */}
            </Stack>
          </Box>
        </DialogContent>
      </Drawer>
    </>
  );
}
