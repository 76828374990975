import { Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { FacetControllerGroup } from "modules/facets";
import { useCore } from "modules/core";
import { Scope } from "modules/scope-metadata/types";
import { HostGroupsDataGrid } from "./components/host-groups-data-grid";
import { HostGroup } from "./types";
import { HOST_GROUP_FACET_ORDER } from "./constants";
import { useHostGroupStore } from "./store";
import { useHostGroupsAPI } from "./hooks";

export const HostGroups = () => {
  const theme = useTheme();

  const coreResponse = useCore<HostGroup>({
    useStore: useHostGroupStore,
    facetGroupInfo: HOST_GROUP_FACET_ORDER,
    scope: Scope.CrowdStrikeEDR,
    defaultSortOrder: [
      { field: "hostgroupstatus", order: "desc" },
      { field: "hostgroupname", order: "desc" },
    ],
    useApi: useHostGroupsAPI,
  });

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction="row">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack>

      <Paper
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          flex: 1,
          overflow: "hidden",
        }}
      >
        <HostGroupsDataGrid
          metadata={coreResponse.metadata}
          rows={coreResponse.rows}
          rowCount={coreResponse.rowCount}
          mutation={coreResponse.mutation}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={coreResponse.onSortChange}
        />
      </Paper>
    </Stack>
  );
};
