import { Stack, Typography, debounce } from "@mui/material";
import { useAppliancesAPI } from "pages/appliances/components/appliance-data-grid/hooks";
import { Appliance, OnlineRecoveryProps } from "pages/appliances/types";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import { useEffect, useMemo, useState } from "react";

export const OnlineRecovery = ({
  appliance,
  selectedAppliance,
  setSelectedAppliance,
  initalActiveAppliances,
}: OnlineRecoveryProps) => {
  const [activeAppliances, setActiveAppliances] = useState<Array<Appliance>>(
    []
  );
  const appliancesMutation = useAppliancesAPI();
  const mutate = useMemo(
    () => debounce(appliancesMutation.mutate, 300),
    [appliancesMutation.mutate]
  );
  useEffect(() => {
    const getPrimaryAppliances = async () => {
      let criteria =
        "'agentstatus' in ('active') AND 'currentversion' > '3.0.1878' AND 'gatekeeperconfigstatus' in ('completed')";
      const body = {
        criteria: criteria,
        facetFields: [],
        pagination: {
          offset: 0,
          limit: 100,
          sort: [
            {
              field: "agentname",
              order: "asc",
            },
          ],
        },
      };

      mutate(body, {
        onSuccess: response => {
          setActiveAppliances(response?.items || ([] as Array<Appliance>));
        },
      });
    };
    getPrimaryAppliances();
  }, [mutate]);

  function handleApplianceUpdate(
    applianceIdentifier: string,
    identifierType: "agentName" | "agentId"
  ) {
    const appliance = activeAppliances.find(appl => {
      return appl[identifierType] === applianceIdentifier;
    });
    if (appliance) {
      setSelectedAppliance(appliance);
    }
  }

  const PrimaryAppliancesList = useMemo(() => {
    let appliances: Array<string> = [...initalActiveAppliances];
    if (activeAppliances?.length) {
      for (let primaryAppliance of activeAppliances) {
        if (primaryAppliance.agentId !== appliance?.agentId) {
          appliances.push(primaryAppliance?.agentName);
        }
      }
    }
    return appliances;
  }, [activeAppliances, appliance?.agentId, initalActiveAppliances]);

  const SelectedApplianceLabel = useMemo(() => {
    if (activeAppliances?.length) {
      const appliance = activeAppliances.find(appl => {
        return appl.agentName === selectedAppliance?.agentName;
      });
      return appliance?.agentName;
    }
    return initalActiveAppliances[0];
  }, [selectedAppliance, activeAppliances, initalActiveAppliances]);

  return (
    <Stack
      alignItems={"flex-start"}
      style={{ position: "relative", height: "100%" }}
      spacing={2}
    >
      <Typography variant="body2">
        {window.getCTTranslatedText("selectOnlineRecovery")}
      </Typography>
      <CTSelectDropDown
        field={"activeAppliances"}
        displayName={window.getCTTranslatedText("applianceName")}
        selectedValue={SelectedApplianceLabel ?? initalActiveAppliances[0]}
        handleUpdate={(event: { field: string; value: string }) =>
          handleApplianceUpdate(event.value, "agentName")
        }
        data={PrimaryAppliancesList}
        showLabel={true}
        required={Boolean(false)}
      />
    </Stack>
  );
};
