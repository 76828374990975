import { Alert, Box } from "@mui/material";
import { FieldsOption } from "modules/form-fields/type";
import { INTEGRATION_PROVIDERS } from "pages/Integration/constants";

interface FormExtrasBeforeProps {
  selectedValue: FieldsOption | null;
}

export const FormExtrasBefore = ({ selectedValue }: FormExtrasBeforeProps) => {
  return selectedValue?.integrationProvider === INTEGRATION_PROVIDERS.SAML ? (
    <Alert severity="info" sx={{ my: 3 }}>
      {window.getCTTranslatedText("Only POST binding is supported")}
      (urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST)
    </Alert>
  ) : (
    <Box></Box>
  );
};
