import { useQueryClient } from "@tanstack/react-query";
import { METHOD_TYPE } from "common/constants/methods";
import { parseErrorMessage } from "common/utils";
import { Field, TextFieldsValues } from "modules/form-fields/type";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { processIntegrationFormatData } from "pages/Integration/IntegrationUtils";
import {
  INTEGRATIONS,
  INTEGRATION_PROVIDERS,
  IntegrationConfigType,
  Integration,
  getIntegrationEnum,
} from "pages/Integration/constants";
import {
  useIntegrationActionConnection,
  useIntegrationFieldsAPI,
  useIntegrationUpdate,
} from "pages/Integration/hooks/useIntegrationAPI";
import { useSubmitAuthConfigAPI } from "pages/Integration/tabs/authentication/hooks";
import { IntegrationIdentifierMappings } from "pages/Integration/type";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { useState } from "react";

export function useIntegrationUpdateConfig(integration: IntegrationConfigType) {
  const [isLoading, setIsLoading] = useState(false);
  const submitConfigMutation = useIntegrationUpdate(
    integration.integrationType,
    integration.integrationProvider,
    integration.configured ? METHOD_TYPE.PUT : METHOD_TYPE.POST
  );
  const submitAuthConfigMutation = useSubmitAuthConfigAPI();
  const integrationActionMutation = useIntegrationActionConnection();
  const queryClient = useQueryClient();
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  type ReconciliationData = {
    reconciliationIdentifier: IntegrationIdentifierMappings[];
    reconciliationAttributes: IntegrationIdentifierMappings[];
  };

  async function updateIntegrationConfig(
    formConfig: Array<Field>,
    integration: IntegrationConfigType,
    textFieldsValues: TextFieldsValues,
    onClose: () => void,
    mappingData: ReconciliationData
  ) {
    setIsLoading(true);
    try {
      if (textFieldsValues?.status) {
        delete textFieldsValues.status;
      }

      if (
        getIntegrationEnum(integration) ===
          INTEGRATIONS.OAUTH_IDENTITYPROVIDER ||
        getIntegrationEnum(integration) === INTEGRATIONS.SAML_IDENTITYPROVIDER
      ) {
        // Handle OAuth2 and SAML2 cases
        const body = {
          authName: integration.name,
          authType:
            integration.integrationProvider === INTEGRATION_PROVIDERS.OAUTH
              ? "OAUTH"
              : "SAML",
          config: {
            clientID: textFieldsValues?.clientID,
            clientSecret: textFieldsValues?.clientSecret,
            clientScopes: textFieldsValues?.clientScopes,
            authUrl: textFieldsValues?.authUrl,
            tokenUrl: textFieldsValues?.tokenUrl,
            logoutUrl: textFieldsValues?.logoutUrl,
            dicoveryEndpoint: textFieldsValues?.dicoveryEndpoint,
            tokenType: textFieldsValues?.tokenType,
            profileEndpoint: textFieldsValues?.profileEndpoint,
            algo: textFieldsValues?.algo,
            ssoURL: textFieldsValues?.ssoURL,
            ssoIssuer: textFieldsValues?.ssoIssuer,
            emailAttribute: textFieldsValues?.emailAttribute,
            ssoLogoutUrl: textFieldsValues?.ssoLogoutUrl,
            terminateAllSessions: textFieldsValues?.terminateAllSessions,
            ca: textFieldsValues?.ca?.replace(/(\\n)/g, "\n"),
            metaDataUrl: textFieldsValues?.metaDataUrl,
            refreshTokenRollingEnabled:
              textFieldsValues?.refreshTokenRollingEnabled,
            refreshTokenTTL:
              textFieldsValues?.refreshTokenTTL &&
              Number(textFieldsValues?.refreshTokenTTL),
            accessTokenTTL:
              textFieldsValues?.accessTokenTTL &&
              Number(textFieldsValues?.accessTokenTTL),
          },
        };

        await submitAuthConfigMutation.mutateAsync(body, {
          onSuccess: response => {
            setSnackbar(
              true,
              SnackBarSeverity.Success,
              "AuthConfigurationSaved"
            );
            queryClient.invalidateQueries({
              predicate: (q: any) => {
                let tag: string = q.queryKey[0] as string;
                return tag.startsWith("auth");
              },
            });
            onClose();
          },
          onError: error => {
            setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
          },
        });
      } else {
        const processData = processIntegrationFormatData(
          integration,
          formConfig,
          textFieldsValues
        );
        let body: Integration = {
          integrationProvider: integration.integrationProvider,
          integrationType: integration.integrationType,
          integrationParameters: processData,
        };
        if (getIntegrationEnum(integration) === INTEGRATIONS.SERVICENOW_CMDB) {
          body.integrationParameters.reconciliationIdentifier =
            mappingData.reconciliationIdentifier;
          body.integrationParameters.reconciliationAttributes =
            mappingData.reconciliationAttributes;
        }
        await submitConfigMutation.mutateAsync(body, {
          onSuccess: response => {
            setSnackbar(
              true,
              SnackBarSeverity.Success,
              "IntegrationConfigurationSaved",
              { name: capitalizeFirstLetter(integration.name) }
            );

            setTimeout(() => {
              updateIntegrationstatus(true, onClose);
            }, 500);
          },
          onError: error => {
            setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const updateIntegrationstatus = (status: boolean, onClose: () => void) => {
    const body = [
      {
        integrationProvider: integration.integrationProvider,
        integrationType: integration.integrationType,
        status: status,
      },
    ];

    integrationActionMutation.mutateAsync(body, {
      onSuccess: (response: any) => {
        setSnackbar(true, SnackBarSeverity.Success, "ConfigurationActivated", {
          name: capitalizeFirstLetter(integration.name),
        });
        onClose();
      },
      onError: (error: any) => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  return { isLoading, updateIntegrationConfig };
}

export function useFetchFormConfig(selectedIntegration: IntegrationConfigType) {
  const formConfig = useIntegrationFieldsAPI(
    selectedIntegration.integrationType,
    selectedIntegration.integrationProvider
  );
  return formConfig;
}
