import { DataGridButton } from "@colortokens/ng-ui";
import CircleIcon from "@mui/icons-material/Circle";
import { Tooltip } from "@mui/material";

export enum IconColor {
  Disabled = "disabled",
  Action = "action",
  Inherit = "inherit",
  Primary = "primary",
  Secondary = "secondary",
  Error = "error",
  Info = "info",
  Success = "success",
  Warning = "warning",
}

interface DataGridStatusButtonProps {
  viewOnly?: boolean;
  tooltipText?: string;
  color?: IconColor;
  isSelected?: boolean;
  displayText: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const DataGridStatusButton = ({
  viewOnly,
  tooltipText,
  color = IconColor.Disabled,
  isSelected,
  onClick,
  displayText,
}: DataGridStatusButtonProps) => {
  return (
    <Tooltip title={window.getCTTranslatedText(`${tooltipText}`)}>
      <DataGridButton
        asText={viewOnly}
        disabled={viewOnly}
        startIcon={<CircleIcon color={color} style={{ marginRight: 8 }} />}
        variant={isSelected ? "outlined" : "text"}
        color={isSelected ? "primary" : "inherit"}
        onClick={onClick}
      >
        {window.getCTTranslatedText(displayText)}
      </DataGridButton>
    </Tooltip>
  );
};
