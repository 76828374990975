import { Button, Paper, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { NewAPIKeyButton } from "./components";
import { APIKeyCreds } from "./components/APIKeyCreds";
import { API_KEYS_COLUMNS } from "./constants";
import { APIKeysResult, UserAPIKey } from "./types";

export function APIKeys() {
  return (
    <>
      <Stack
        sx={{ mb: 1 }}
        spacing={1}
        direction={"row"}
        alignItems="center"
        justifyContent={"flex-end"}
      >
        <Button
          variant="outlined"
          href={`https://api-${window.location.host}/docs`}
          target="_blank"
        >
          {window.getCTTranslatedText("Documentation")}
        </Button>
        <APIKeyCreds />
        <NewAPIKeyButton />
      </Stack>
      <APIKeysList />
    </>
  );
}

function useAPIKeys() {
  return useQuery<APIKeysResult, Error>(["apiKey", "auth/user/api-key"]);
}

function APIKeysList() {
  const { data: apiKeys, isLoading } = useAPIKeys();

  return (
    <Paper sx={{ flex: 1, overflow: "hidden" }}>
      <DataGrid
        isLoading={isLoading}
        rows={getUniqueListBy(apiKeys || [], "fingerprint")}
        rowCount={apiKeys?.length || 0}
        columns={API_KEYS_COLUMNS}
        getRowId={({ fingerprint }: UserAPIKey) => fingerprint}
      />
    </Paper>
  );
}
