import { Alert, Paper, Stack, useTheme } from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { Scope } from "modules/scope-metadata/types";
import { AlertConfigDataGrid } from "./components/alert-config-data-grid";
import { ALERTCONFIG_FACET_ORDER } from "./constants";
import { useAlertConfigAPI } from "./hooks/useAlertConfig";
import { useAlertConfigStore } from "./store";
import { AlertRuleConfig } from "./types";

export const AlertConfig = () => {
  const theme = useTheme();

  const coreResponse = useCore<AlertRuleConfig>({
    useStore: useAlertConfigStore,
    facetGroupInfo: ALERTCONFIG_FACET_ORDER,
    scope: Scope.AlertRule,
    useApi: useAlertConfigAPI,
    pageSize: 100,
    isNewCsvAPIVersion: true,
    defaultSortOrder: [
      { field: "severity", order: "desc" },
      { field: "alertRuleStatus", order: "desc" },
    ],
  });

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction={"row"}>
        <Stack sx={{ flex: 1 }}>
          <Alert sx={{ width: "100%", mb: 2 }} severity={"info"}>
            {`${window.getCTTranslatedText("alertStepsToFollow")}`}
          </Alert>
        </Stack>
      </Stack>
      <Stack direction={"row"} alignItems="center">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              config={coreResponse?.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack>

      <Paper
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AlertConfigDataGrid
          rows={coreResponse.rows}
          rowCount={coreResponse.rowCount}
          mutation={coreResponse.mutation}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          metadata={coreResponse.metadata}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={coreResponse.onSortChange}
          triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
          getExportStatus={coreResponse?.getExportStatus}
          getUrlToDownload={coreResponse?.getUrlToDownload}
          resetDownloadUrl={coreResponse?.resetDownloadUrl}
          rawData={coreResponse?.rawData}
        />
      </Paper>
    </Stack>
  );
};
