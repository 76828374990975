import {
  CircularProgress,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import { useQuery } from "@tanstack/react-query";
import { CopyButton } from "common/atoms/copy-button";
import { useState } from "react";
import { Toolbar } from "common/atoms/toolbar";

export function APIKeyCreds() {
  const [showCredential, setShowCredentials] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setShowCredentials(true);
        }}
      >
        {window.getCTTranslatedText("View Credentials")}
      </Button>
      {showCredential && (
        <Credentials
          onClose={() => {
            setShowCredentials(false);
          }}
        />
      )}
    </>
  );
}

interface APIKeysCredentialsResult {
  userId: string;
  tenantId: string;
}

function useAPIKeysCredentials() {
  return useQuery<APIKeysCredentialsResult, Error>([
    "apiKeyCredentials",
    "auth/user/api-key-creds",
  ]);
}

function Credentials({ onClose }: { onClose: Function }) {
  const { data, isLoading } = useAPIKeysCredentials();
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={true}
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        elevation: 1,
      }}
    >
      <Toolbar />

      <DialogContent sx={{ minWidth: 600 }}>
        <Typography variant="h6" sx={{ marginBottom: "16px" }}>
          {window.getCTTranslatedText("ColorTokens API IDs")}
        </Typography>
        {isLoading && (
          <CircularProgress
            sx={{ alignSelf: "center", justifySelf: "center" }}
            size={24}
          />
        )}

        {data?.tenantId && (
          <>
            <Typography variant="subtitle1">
              {window.getCTTranslatedText("Tenant ID")}
            </Typography>
            <Stack direction={"row"} spacing={1} alignItems="center">
              <code>{data?.tenantId}</code>
              <CopyButton text={data?.tenantId} />
            </Stack>
          </>
        )}

        {data?.userId && (
          <>
            <Typography variant="subtitle1">
              {window.getCTTranslatedText("User ID")}
            </Typography>

            <Stack direction={"row"} spacing={1} alignItems="center">
              <code>{data?.userId}</code>
              <CopyButton text={data?.userId} />
            </Stack>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="info" onClick={() => onClose()}>
          Close
        </Button>
      </DialogActions>
    </Drawer>
  );
}
