import { Box, Button } from "@mui/material";
import { useAssignedNetworksTags } from "./hooks";
import { useTagNetworks } from "../../hooks";
import { AssignedNetworksViewDrawer } from "modules/asset-networks/assigned-networks-view-drawer";
import { AssignNetworksDrawer } from "modules/assign-networks-to-assets/components/assign-networks-drawer";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";

export function AssignedNetworksTags({
  criteria,
  multiselect = false,
  policyId,
  open,
  onClose,
}: {
  criteria?: string;
  multiselect?: boolean;
  policyId?: string;
  open: boolean;
  onClose: () => void;
}) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const { networkList, isLoading, onSortChange, onSearchCriteriaChange } =
    useAssignedNetworksTags({
      criteria,
      policyId,
    });

  const {
    showAssignNetworksDrawer,
    openAssignNetworksDrawer,
    closeAssignNetworksDrawer,
  } = useTagNetworks();

  if (!criteria) {
    return <Box style={{ width: 42 }} />;
  }

  return (
    <>
      {showAssignNetworksDrawer && (
        <AssignNetworksDrawer
          isOpen={showAssignNetworksDrawer}
          page={"tags"}
          title="Assign Named Network"
          rules={[]}
          onCancel={closeAssignNetworksDrawer}
          onConfirm={closeAssignNetworksDrawer}
          btnTitle={"assign"}
          criteria={criteria}
          multiselect={multiselect}
          policyId={policyId}
          hasPermission={userPermissions.has("UPDATE_TAGBASEDPOLICY")}
        />
      )}

      {open && (
        <AssignedNetworksViewDrawer
          isOpen={open}
          onClose={onClose}
          networkList={networkList}
          networksSuggestMutation={undefined}
          isLoading={isLoading}
          AssignNetworksOption={() =>
            AssignNetworksOption({
              openAssignNetworksDrawer,
              hasPermission: userPermissions.has("UPDATE_TAGBASEDPOLICY"),
            })
          }
          page={"tags"}
          policyId={policyId}
          criteria={criteria}
          onSortChange={onSortChange}
          multiselect={true}
          hasPermission={userPermissions.has("UPDATE_TAGBASEDPOLICY")}
          onSearchCriteriaChange={onSearchCriteriaChange}
        />
      )}
    </>
  );
}

export const AssignNetworksOption = ({
  openAssignNetworksDrawer,
  hasPermission,
}: {
  openAssignNetworksDrawer: () => void;
  hasPermission?: boolean;
}) => {
  return (
    <>
      {hasPermission ? (
        <Button
          variant="contained"
          color="primary"
          onClick={openAssignNetworksDrawer}
          sx={{
            minWidth: "max-content",
          }}
        >
          {window.getCTTranslatedText("Assign Named Networks")}
        </Button>
      ) : null}
    </>
  );
};
