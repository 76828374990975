import { ExpandCircleDownOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { MatchedByTemplateDrawer } from "modules/matched-by-templates-drawer";
import { MatchedByTemplates } from "pages/paths/types";
import { useState } from "react";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";

interface MatchedByTemplateProps {
  matchedByTemplates: MatchedByTemplates[];
  showDeleteIcon: boolean;
  id?: string;
}

export function MatchedByTemplate({
  id,
  matchedByTemplates,
  showDeleteIcon,
}: MatchedByTemplateProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = (event: any) => {
    if (event) {
      event.stopPropagation();
    }
    setIsDrawerOpen(true);
  };

  if (!id) {
    return <Box style={{ width: 70 }} />;
  }

  const renderContent = () => {
    if (matchedByTemplates && matchedByTemplates.length > 0) {
      return (
        <Stack direction="row" alignItems="center">
          <Tooltip title={matchedByTemplates[0].templateName ?? ""}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {matchedByTemplates[0].templateName}
            </Typography>
          </Tooltip>

          <Tooltip
            title={window.getCTTranslatedText("Show matching templates")}
          >
            <IconButton
              sx={{ "& svg": { transform: "rotate(218deg)" } }}
              aria-label="Show matching templates"
              onClick={handleDrawerOpen}
            >
              <ExpandCircleDownOutlined
                sx={{ opacity: 0.6 }}
                fontSize="small"
                color="info"
              />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    }

    return (
      <Typography
        sx={{ display: "flex", alignItems: "center" }}
        variant="body2"
      >
        {showDeleteIcon ? (
          <Tooltip title={window.getCTTranslatedText("AwaitingPushToFirewall")}>
            <AutoDeleteIcon />
          </Tooltip>
        ) : (
          "-"
        )}
      </Typography>
    );
  };

  return (
    <Box
      style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
    >
      {renderContent()}

      {isDrawerOpen && (
        <MatchedByTemplateDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          list={matchedByTemplates}
        />
      )}
    </Box>
  );
}
