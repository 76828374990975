import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Direction } from "pages/asset/components/asset-detail/constants";

interface TrafficVisualizerDrawerHeaderProps {
  title: string;
  selectedDirection: Direction;
  isExpanded?: boolean;
  onClose?: () => void;
  toggleDrawerSize?: () => void;
}

export const TrafficVisualizerDrawerHeader = ({
  title,
  isExpanded,
  onClose,
  toggleDrawerSize,
}: TrafficVisualizerDrawerHeaderProps) => {
  return (
    <>
      <Typography sx={{ fontSize: 18 }} variant="h6">
        {title}
      </Typography>
      <Stack direction="row" spacing={1}>
        {toggleDrawerSize && (
          <Tooltip title={window.getCTTranslatedText("minimizeDrawer")}>
            <IconButton
              size="small"
              aria-label="Minimize drawer"
              onClick={toggleDrawerSize}
            >
              {isExpanded ? (
                <ExpandMoreIcon fontSize="small" />
              ) : (
                <ExpandLessIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        )}
        {onClose && (
          <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
            <IconButton
              size="small"
              aria-label="Close Drawer"
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </>
  );
};
