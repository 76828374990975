import { parseErrorMessage } from "common/utils";
import debounce from "lodash/debounce";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import numeral from "numeral";
import { useTagsAPI } from "pages/tags/hooks/useTagsAPI";
import { useTagStore } from "pages/tags/store";
import { TagKeyInt } from "pages/tags/types";
import { useEffect, useMemo, useState } from "react";
import {
  WithoutGroupByResponse,
  useAggregateAPI,
} from "../../../hooks/useAggregateAPI";

const format = "0,0";

export function useTagList() {
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const currentTag = useTagStore(state => state.currentTag);
  const setCurrentTag = useTagStore(state => state.setCurrentTag);

  const [tagList, setTagList] = useState<Array<TagKeyInt> | undefined>(
    undefined
  );

  const { tagFields: tags, metaData } = useTagsAPI();
  const tagFields = useMemo(() => {
    if (!tags) {
      return;
    }
    return {
      coreTags: tags?.coreTags.map(col => col.name || "") || [],
      userDefinedTags: tags?.userDefinedTags.map(col => col.name || "") || [],
    };
  }, [tags]);

  useEffect(() => {
    if (!tagFields?.coreTags || !tagFields.userDefinedTags) {
      return;
    }
    let previouslySelectedTag = currentTag;
    if (
      previouslySelectedTag &&
      !tagFields?.coreTags?.includes(previouslySelectedTag) &&
      !tagFields?.userDefinedTags?.includes(previouslySelectedTag)
    ) {
      previouslySelectedTag = undefined;
    }
    const selectedTag =
      previouslySelectedTag ||
      tagFields.userDefinedTags[0] ||
      tagFields.coreTags[0];
    setCurrentTag(selectedTag);
  }, [
    currentTag,
    setCurrentTag,
    tagFields?.coreTags,
    tagFields?.userDefinedTags,
  ]);

  const tagListMutation = useAggregateAPI();

  const tagListMutationObj = useMemo(
    () => debounce(tagListMutation.mutate, 500),
    [tagListMutation.mutate]
  );

  useEffect(() => {
    if (!tagFields) {
      return;
    }

    const getStatisticsFromTagFields = (tagFields: Array<string>) => {
      return (tagFields ?? []).map((field: string) => {
        return `distinctcount(${field})`;
      });
    };

    const buildTagList = (values: { [key: string]: number }) => {
      let list: Array<TagKeyInt> = [];

      tagFields.userDefinedTags.forEach((tagField: string) => {
        const tagObj: TagKeyInt = {
          key: tagField,
          displayName: metaData?.columns[tagField]?.displayName ?? tagField,
          isCoreTag: false,
          count: numeral(values[`${tagField}distinctcount`]).format(format),
        };
        list.push(tagObj);
      });

      tagFields.coreTags.forEach((tagField: string) => {
        const tagObj: TagKeyInt = {
          key: tagField,
          displayName: metaData?.columns[tagField]?.displayName ?? tagField,
          isCoreTag: true,
          count: numeral(values[`${tagField}distinctcount`]).format(format),
        };
        list.push(tagObj);
      });
      return list;
    };
    const body = {
      criteria: "*",
      scope: Scope.Asset,
      groupBy: [],
      statistics: getStatisticsFromTagFields([
        ...tagFields.coreTags,
        ...tagFields.userDefinedTags,
      ]),
    };

    tagListMutationObj(body, {
      onSuccess(data) {
        if (data) {
          const responseData = data as WithoutGroupByResponse;
          const values = responseData?.items?.statistics ?? {};
          const tagList = buildTagList(values);
          setTagList(tagList);
        }
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  }, [metaData, setTagList, tagFields, tagListMutationObj, setSnackbar]);

  return {
    tagList,
    isLoading: tagListMutation.isLoading,
    createdCustomTagsCount: tagFields?.userDefinedTags?.length ?? 0,
  };
}
