import { Box, Stack, Tooltip, Typography } from "@mui/material";

import { useCommonStore } from "common/store";

import { PathColumnRenderProps } from "pages/paths/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function PathAPIDetails({
  path,
  viewOnly,
  useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
  const method = path?.method || "";
  const uri = path?.uri || "";

  if (!path.channelHash) {
    return <Box />;
  }

  if (!method && !uri) {
    return (
      <Stack sx={{ minWidth: 32 }} alignItems="center" justifyContent="center">
        <Typography variant="body2"></Typography>
      </Stack>
    );
  }

  return (
    <Stack sx={{ maxWidth: "100%" }}>
      <CTDatagridButton
        isDisabled={viewOnly === true || !method}
        asText={viewOnly === true}
        useFacetStore={useFacetStore}
        facetName={"method"}
        facetOption={method}
      >
        {window.getCTTranslatedText(method)}
      </CTDatagridButton>

      <CTDatagridButton
        isDisabled={viewOnly === true || !uri}
        asText={viewOnly === true}
        useFacetStore={useFacetStore}
        facetName={"uri"}
        facetOption={uri}
      >
        <Tooltip arrow={false} placement="bottom-start" title={uri}>
          <Typography
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            variant="caption"
          >
            {window.getCTTranslatedText(uri)}
          </Typography>
        </Tooltip>
      </CTDatagridButton>
    </Stack>
  );
}
