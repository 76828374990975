import { Stack, Typography, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface TagAttributesBlockProps {
  title: string;
  canAssign: boolean;
  assign: () => void;
  btnTitle?: string;
}

export const TagAttributesBlock = ({
  title,
  canAssign,
  assign,
  btnTitle,
}: TagAttributesBlockProps) => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{
          width: "100%",
        }}
      >
        <Typography variant="subtitle1">
          {window.getCTTranslatedText(title)}
        </Typography>
        {canAssign && (
          <Button
            variant="text"
            startIcon={<AddCircleOutlineIcon />}
            color="primary"
            onClick={assign}
          >
            {window.getCTTranslatedText(btnTitle ?? "Assign")}
          </Button>
        )}
      </Stack>
    </>
  );
};
