import { Box, Stack } from "@mui/material";
import { ApplianceColumnRenderProps } from "pages/appliances/types";
import { ViewDiagnosticButton } from "./ViewDiagnosticButton";

export function ApplianceDiagnostic({
  agent,
  isOpen,
  onClose,
}: ApplianceColumnRenderProps) {
  if (!agent?.agentId) {
    return <Box style={{ width: 42 }} />;
  }

  return (
    <Stack
      sx={{ width: "100%" }}
      alignItems="flex-start"
      justifyItems={"center"}
    >
      <ViewDiagnosticButton
        agent={agent}
        agentId={agent.agentId}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Stack>
  );
}
