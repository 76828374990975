import { Box, Stack } from "@mui/material";
import { AgentColumnRenderProps } from "pages/agents/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
export function NorthSouthTrafficCollectionStatus({
  agent,
  viewOnly,
}: Readonly<AgentColumnRenderProps>) {
  if (!agent?.agentId) {
    return <Box style={{ width: 120 }} />;
  }
  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <CTDatagridButton
        asText={viewOnly === true}
        facetName={"nstrafficstatus"}
        facetOption={agent.northSouthTrafficConfiguration}
      >
        {window.getCTTranslatedText(agent.northSouthTrafficConfiguration)}
      </CTDatagridButton>
    </Stack>
  );
}
