import { Box } from "@mui/material";
import { AnnotationDrawer } from "modules/annotation-drawer";
import { EntityType } from "modules/annotation-drawer/type";
import { AnnotationCount } from "modules/annotation/components/annotation-count";
import { useTemplateStore } from "pages/templates/store";
import { TemplateColumnRenderProps } from "pages/templates/types";
import { useState } from "react";

export function TemplateAnnotations({ template }: TemplateColumnRenderProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = (event: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setIsDrawerOpen(true);
  };

  if (!template.templateId) {
    return <Box style={{ width: 70 }} />;
  }

  return (
    <>
      <AnnotationCount
        count={template.totalComments}
        clickCallback={handleDrawerOpen}
      />
      {isDrawerOpen && (
        <AnnotationDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          entity={EntityType.Templates}
          entityId={template.templateId}
          useStore={useTemplateStore}
        />
      )}
    </>
  );
}
