import { recommendationConfig } from "../constant";
import { Recommendation } from "../types";

export const getRecomnmendationPermission = (rec: Recommendation) => {
  if (rec) {
    const id = rec?.name;
    const scope = rec?.attributes?.scope;

    if (id && scope) {
      return recommendationConfig()[scope][id]?.permission;
    }
  }
  return "";
};
