import { AddCircleOutline } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  CircularProgress,
  IconButton,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AssignNetworksDrawer } from "modules/assign-networks-to-assets/components/assign-networks-drawer";
import { FacetOptionState, Operator } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { getFilteredMetadata } from "modules/scope-metadata/utils";
import numeral from "numeral";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { NetworkFormDrawer } from "pages/networks/components/network-form-drawer";
import { useTagsAPI } from "pages/tags/hooks";
import { useEffect, useMemo, useState } from "react";
import { Handle, Position } from "reactflow";
import { Dimension } from "../../../modules/hierarchy-vis/types";
import { useSourceFacetStore, useVisxStore } from "../store";
import {
  CTNodeType,
  Dimensions,
  GroupNodeDataType,
  PathReviewStatus,
  StatisticType,
  TrafficDatum,
} from "../types";
import {
  DEFAULT_PRIVATE_NETWORK_NODE_NAME,
  DEFAULT_PUBLIC_NETWORK_NODE_NAME,
  NN_TITLE,
  OTHERS,
  UNKNOWN,
  UNTAGGED,
  createFacetParentJoinUpdates,
  getNodeDisplayName,
  getNodeId,
  isSubnet,
} from "../visx-utils";

const networkExpansions: Array<Dimension> = [
  { dataType: "string", label: "Named Network", name: "namednetworkname" },
];

const srcSubnetExpansions: Array<Dimension> = [
  { dataType: "string", label: "Subnet (/8)", name: "srcsubnet8" },
  { dataType: "string", label: "Subnet (/16)", name: "srcsubnet16" },
  { dataType: "string", label: "Subnet (/24)", name: "srcsubnet24" },
];

const dstSubnetExpansions: Array<Dimension> = [
  { dataType: "string", label: "Subnet (/8)", name: "dstsubnet8" },
  { dataType: "string", label: "Subnet (/16)", name: "dstsubnet16" },
  { dataType: "string", label: "Subnet (/24)", name: "dstsubnet24" },
];

const SubnetParents: { [key: number]: Array<number> } = {
  8: [],
  16: [8],
  24: [16, 8],
};

export function CTNode<T extends GroupNodeDataType>({
  data,
  selectedDimension,
  isLoading,
}: {
  data: T;
  selectedDimension: Dimension | undefined;
  isLoading: boolean;
}) {
  const theme = useTheme();
  const selectedPathStatus = useVisxStore(state => state.selectedPathStatus);
  const setSelectedPathStatus = useVisxStore(
    state => state.setSelectedPathStatus
  );
  const { tagFields: tags, metaData } = useTagsAPI();

  const { data: pathsMetadata } = useScopeMetadata({ scope: Scope.Path });

  const setIsOthersNodeExpanded = useVisxStore(
    state => state.setIsOthersNodeExpanded
  );
  const setIsPublicNodeExpanded = useVisxStore(
    state => state.setIsPublicNodeExpanded
  );
  const setIsPrivateNodeExpanded = useVisxStore(
    state => state.setIsPrivateNodeExpanded
  );

  const selectedStatus =
    selectedPathStatus === PathReviewStatus.Enforced ||
    selectedPathStatus === PathReviewStatus.DIFF
      ? StatisticType.Enforced
      : StatisticType.Candidate;

  const statistics = data.trafficData?.data?.[selectedStatus];

  const [anchor, setAnchor] = useState<HTMLElement | undefined | null>(null);
  const addExpansion = useVisxStore(state => state.addExpansion);
  const setHoveredId = useVisxStore(state => state.setHoveredId);

  const dimensionHierarchy = useMemo(() => {
    const parentHierarchy: Dimension[] = [];

    const addToHierarchy = (trafficDatum: TrafficDatum | undefined) => {
      if (trafficDatum?.sourceDimension) {
        parentHierarchy.push(trafficDatum?.sourceDimension);
      }

      if (trafficDatum?.parent?.sourceDimension) {
        parentHierarchy.push(trafficDatum?.parent?.sourceDimension);
      }

      if (trafficDatum?.parent?.parent) {
        addToHierarchy(trafficDatum?.parent);
      }
    };

    if (data.type === CTNodeType.HUB) {
      addToHierarchy(data?.trafficData);
    }

    if (isSubnet(data?.dimension?.name ?? "")) {
      let subnetNumber = parseInt(
        (data?.dimension?.name ?? "")
          ?.replaceAll("srcsubnet", "")
          ?.replaceAll("dstsubnet", "")
      );
      if (false === isNaN(subnetNumber)) {
        let subnetHierarchy = SubnetParents[subnetNumber];
        let prefix = data?.dimension?.name?.startsWith("src")
          ? "srcsubnet"
          : "dstsubnet";
        subnetHierarchy?.forEach(number => {
          parentHierarchy.push({
            name: `${prefix}${number}`,
            dataType: "string",
            label: "",
          });
        });
      }
    }

    return [data?.dimension, selectedDimension, ...parentHierarchy].map(
      d => d?.name
    );
  }, [data, selectedDimension]);

  useEffect(() => {
    if (anchor) {
      setHoveredId(undefined);
    }
  }, [anchor, setHoveredId]);

  const displayLabel = useMemo(
    () => getNodeDisplayName(data.label),
    [data.label]
  );

  const setIsDrawerOpen = useVisxStore(state => state.setIsDrawerOpen);
  const setSelectedNode = useVisxStore(state => state.setSelectedNode);
  const selectedNode = useVisxStore(state => state.selectedNode);
  const onSelectNode = () => {
    if (data.type !== CTNodeType.HUB) {
      return;
    }

    if (selectedPathStatus === PathReviewStatus.Enforced) {
      setSelectedPathStatus(PathReviewStatus.WIP);
    }
    setIsDrawerOpen(true);
    setSelectedNode(data);
  };

  const assetCount = statistics?.aggregates?.assetAggregate || 0;

  const isPublicNetworkNode = data.id === DEFAULT_PUBLIC_NETWORK_NODE_NAME;
  const isPrivateNetworkNode = data.id === DEFAULT_PRIVATE_NETWORK_NODE_NAME;

  const selectedDirection = useVisxStore(store => store.selectedDirection);

  const options = useMemo(() => {
    const userDefinedTagsList: {
      label: string;
      name: string;
      dataType: string;
    }[] =
      tags?.userDefinedTags?.map(coreTag => ({
        label: coreTag.displayName,
        name: coreTag?.name || "",
        dataType: coreTag.dataType,
      })) ?? [];

    const isNetworkNode = isSubnet(data.id) || data.title === NN_TITLE;

    let subnetExpansions =
      isNetworkNode &&
      (data.dimension?.name?.startsWith("src") ||
        selectedDirection === Direction.Inbound)
        ? srcSubnetExpansions
        : dstSubnetExpansions;

    if (isPublicNetworkNode || isPrivateNetworkNode) {
      return networkExpansions;
    }

    let columns = getFilteredMetadata({
      columns: isNetworkNode ? subnetExpansions : Dimensions,
      metadata: isNetworkNode ? pathsMetadata : metaData,
    });

    columns = columns.filter((d: Dimension) => {
      if (
        d.name === "assetname" &&
        (statistics?.aggregates?.assetAggregate || 0) > 100
      ) {
        return false;
      }

      return !dimensionHierarchy.includes(d.name);
    });

    if (!isNetworkNode) {
      columns = columns?.concat(userDefinedTagsList);
    }

    return columns?.sort((a, b) => a.label.localeCompare(b.label));
  }, [
    data.dimension?.name,
    selectedDirection,
    isPublicNetworkNode,
    isPrivateNetworkNode,
    pathsMetadata,
    metaData,
    statistics?.aggregates?.assetAggregate,
    dimensionHierarchy,
    tags?.userDefinedTags,
    data.id,
    data.title,
  ]);

  return (
    <Box
      className={`ctNode-internal ${data.label === OTHERS ? "others" : ""}`}
      sx={{
        pointerEvents: "all",
        borderRadius: isLoading ? "50% !important" : undefined,
      }}
    >
      <TargetHandlers handle={data.handle} />

      <Stack
        justifyContent="space-evenly"
        alignItems={"center"}
        sx={{
          p: 0,
          height: "100%",
          position: "relative",
          "& .expansionButtonContainer": {
            opacity: 0,
          },
          "&:hover .expansionButtonContainer": {
            opacity: 1,
          },
        }}
        onClick={() => {
          if (data.label !== OTHERS) {
            onSelectNode();
          }
        }}
        onMouseEnter={() => {
          if (
            !selectedNode &&
            selectedPathStatus === PathReviewStatus.Enforced &&
            data.label !== OTHERS
          ) {
            if (isSubnet(data.id)) {
              setHoveredId(data.id);
            } else {
              setHoveredId(data.label);
            }
          }
        }}
        onMouseLeave={() =>
          selectedNode ? undefined : setHoveredId(undefined)
        }
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%",
          }}
        >
          <Tooltip
            title={`${window.getCTTranslatedText(
              data.title
            )} - ${window.getCTTranslatedText(
              data.dimension?.label ? data.dimension?.label + ":" : ""
            )} ${displayLabel}`}
          >
            <Typography
              variant="overline"
              sx={{
                lineHeight: 1.2,
                textTransform: "initial",
                color: theme.palette.text.primary,
                whiteSpace: "break-spaces",
                maxWidth: "100%",
                maxHeight: "42px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {displayLabel}
            </Typography>
          </Tooltip>
        </Stack>

        {assetCount > 0 && data.dimension?.name !== "assetname" && (
          <Stack
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? "#000" : grey[300],
              borderRadius: "50%",
              width: 24,
              height: 24,
            }}
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: 12,
              }}
            >
              {numeral(assetCount).format("0a")}
            </Typography>
          </Stack>
        )}

        <Stack
          alignContent={"center"}
          justifyContent="center"
          className="expansionButtonContainer"
          sx={{
            zIndex: 1,
            top: 8,
            right: -22,
            opacity: isLoading ? "1 !important" : undefined,
            borderRadius: 4,
            position: "absolute",
            background:
              theme.palette.mode === "dark"
                ? theme.palette.custom.lightGrey
                : theme.palette.background.paper,
          }}
        >
          {data?.canExpand &&
            (isLoading && selectedDimension ? (
              <Box sx={{ width: 30, height: 30 }}>
                <CircularProgress size={14} />
              </Box>
            ) : (
              <>
                {Boolean(options?.length) && (
                  <NodeOptionButton setAnchor={setAnchor} />
                )}
              </>
            ))}

          <>
            {Boolean(isSubnet(data?.id)) && (
              <NodeOptionButton setAnchor={setAnchor} />
            )}
          </>
        </Stack>
      </Stack>

      {Boolean(!isSubnet(data?.id)) && (
        <DimensionSelectorMenu
          anchor={anchor}
          setAnchor={setAnchor}
          selectedDimension={selectedDimension}
          setSelectedDimension={d => {
            if (data.label === OTHERS) {
              setIsOthersNodeExpanded(true);
              return;
            }
            if (isPublicNetworkNode) {
              setIsPublicNodeExpanded(true);
              return;
            }

            if (isPrivateNetworkNode) {
              setIsPrivateNodeExpanded(true);
              return;
            }

            if (data?.label && data?.dimension) {
              addExpansion({
                dimension: d,
                parent: {
                  name: data?.label,
                  dimension: data?.dimension,
                },
              });
            }
            setHoveredId(undefined);
          }}
          options={options}
          facetValue={getNodeId(data.label)}
          facetName={data.dimension?.name}
          trafficData={data.trafficData}
        />
      )}

      {Boolean(isSubnet(data?.id)) && (
        <NamedNetworkOptions
          anchor={anchor}
          setAnchor={setAnchor}
          ctNode={data}
        />
      )}

      <SourceHandlers handle={data.handle} />
    </Box>
  );
}

function TargetHandlers({ handle }: { handle: string }) {
  return (
    <>
      <Handle type="target" position={Position.Left} id={"tl"} />
      <Handle type="target" position={Position.Right} id={"tr"} />
      <Handle type="target" position={Position.Top} id={"tt"} />
      <Handle type="target" position={Position.Bottom} id={"tb"} />
    </>
  );
}

function SourceHandlers({ handle }: { handle: string }) {
  return (
    <>
      <Handle type="source" position={Position.Left} id={"sl"} />
      <Handle type="source" position={Position.Right} id={"sr"} />

      <Handle type="source" position={Position.Top} id={"st"} />
      <Handle type="source" position={Position.Bottom} id={"sb"} />
    </>
  );
}

function NodeOptionButton({ setAnchor }: { setAnchor: (e: any) => void }) {
  return (
    <IconButton
      color="primary"
      size="small"
      aria-label="options"
      onClick={e => {
        setAnchor(e.currentTarget);
        e.preventDefault?.();
        e.stopPropagation?.();
      }}
    >
      <Tooltip title={window.getCTTranslatedText("Options")}>
        <AddCircleOutline color="primary" fontSize="small" />
      </Tooltip>
    </IconButton>
  );
}

interface DimensionSelectorMenuProps {
  anchor: HTMLElement | undefined | null;
  setAnchor: React.Dispatch<
    React.SetStateAction<HTMLElement | undefined | null>
  >;
  selectedDimension: Dimension | undefined;
  setSelectedDimension: (dimension: Dimension) => void;
  options: Array<Dimension>;
  facetValue: string;
  facetName?: string;
  trafficData?: TrafficDatum;
}
function DimensionSelectorMenu({
  anchor,
  setAnchor,
  selectedDimension,
  setSelectedDimension,
  options,
  facetValue,
  facetName,
  trafficData,
}: DimensionSelectorMenuProps) {
  if (facetName === "namednetworkname") {
    facetName = "";
  }
  const [open, setOpen] = useState(false);
  const [SubMenuAnchor, setSubMenuAnchor] = useState<
    HTMLElement | undefined | null
  >(null);
  const updateFacet = useSourceFacetStore(state => state.updateFacet);

  const handlerOnClickZeroIn = () => {
    if (!facetName || !facetValue) {
      return;
    }

    if (trafficData?.parent) {
      const parentFacets = createFacetParentJoinUpdates(trafficData);
      if (parentFacets.length) {
        parentFacets.forEach(facet => updateFacet(facet));
      }
    }

    if (facetValue.includes(UNKNOWN) || facetValue.includes(UNTAGGED)) {
      facetValue = `NULL`;
    }

    let options: FacetOptionState = new Map();
    options.set(facetValue, {
      isSelected: true,
      operator: Operator.EQUAL,
    });
    updateFacet({
      facetName,
      options,
    });
  };

  const setIsOthersNodeExpanded = useVisxStore(
    store => store.setIsOthersNodeExpanded
  );

  const handleExpandOthersClick = () => {
    setIsOthersNodeExpanded(true);
    setAnchor(null);
  };

  return (
    <Menu
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={() => {
        setAnchor(null);
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {facetValue === OTHERS && (
        <MenuItem onClick={handleExpandOthersClick}>
          {window.getCTTranslatedText("Expand")}
        </MenuItem>
      )}

      {facetName && facetValue && (
        <MenuItem onClick={handlerOnClickZeroIn}>
          {window.getCTTranslatedText("Zero In")}
        </MenuItem>
      )}
      {facetName && facetValue && (
        <ListItemButton
          onClick={e => {
            setSubMenuAnchor(e.currentTarget);
            e.preventDefault?.();
            e.stopPropagation?.();
            setOpen(!open);
          }}
        >
          <ListItemText primary={window.getCTTranslatedText("Expand")} />
          <KeyboardArrowRightIcon />
        </ListItemButton>
      )}

      {facetValue !== OTHERS && (
        <Menu
          anchorEl={facetName ? SubMenuAnchor : anchor}
          open={Boolean(facetName ? SubMenuAnchor : anchor)}
          onClose={() => {
            facetName ? setSubMenuAnchor(null) : setAnchor(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: facetName ? "right" : "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {(!facetName || !facetValue) && (
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"flex-start"}
              spacing={1}
              sx={{ px: 2 }}
            >
              <Typography variant="overline">
                {window.getCTTranslatedText("Expand")}
              </Typography>
            </Stack>
          )}
          {options.map(d => {
            return (
              <MenuItem
                selected={d.name === selectedDimension?.name}
                key={d.name}
                onClick={e => {
                  setAnchor(null);
                  setSelectedDimension(d);
                }}
              >
                {window.getCTTranslatedText(d.label)}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </Menu>
  );
}

export enum NAMED_NETWORK_ASSIGNMENT_ID {
  ADD_TO_NAMED_NETWORK = "Add to Named Network",
  CREATE_NEW_NAMED_NETWORK = "Create new Named Network",
}

function NamedNetworkOptions({
  anchor,
  ctNode,
  setAnchor,
}: {
  anchor: HTMLElement | undefined | null;
  setAnchor: React.Dispatch<
    React.SetStateAction<HTMLElement | undefined | null>
  >;
  ctNode: GroupNodeDataType;
}) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const [showCreate, setShowNamedNetworkCreation] = useState(false);
  const [showUpdate, setShowNamedNetworkUpdate] = useState(false);

  const CIDRList: string[] = [ctNode.label];
  return (
    <>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => {
          setAnchor(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={e => {
            setAnchor(null);
            setShowNamedNetworkUpdate(true);
          }}
        >
          {window.getCTTranslatedText(
            NAMED_NETWORK_ASSIGNMENT_ID.ADD_TO_NAMED_NETWORK
          )}
        </MenuItem>
        <MenuItem
          onClick={e => {
            setAnchor(null);
            setShowNamedNetworkCreation(true);
          }}
        >
          {window.getCTTranslatedText(
            NAMED_NETWORK_ASSIGNMENT_ID.CREATE_NEW_NAMED_NETWORK
          )}
        </MenuItem>
      </Menu>

      <NetworkFormDrawer
        isOpen={showCreate}
        onClose={() => setShowNamedNetworkCreation(false)}
        updateNetworkData={() => console.log()}
        title="Create New Network"
        mode={"create"}
        btnTitle={"create"}
        cidrList={CIDRList}
      />

      <AssignNetworksDrawer
        isOpen={showUpdate}
        page={"paths"}
        title={NAMED_NETWORK_ASSIGNMENT_ID.ADD_TO_NAMED_NETWORK}
        criteria={""}
        rules={CIDRList}
        onCancel={() => setShowNamedNetworkUpdate(false)}
        onConfirm={() => setShowNamedNetworkUpdate(false)}
        btnTitle={"assign"}
        hasPermission={userPermissions.has("UPDATE_NAMED_NETWORK")}
      />
    </>
  );
}
