import { Box, Stack } from "@mui/material";

import { AssetColumnRenderProps } from "pages/assets/types";

import { AssetSecurityLevelIcons } from "../asset-security-level-icons/AssetSecurityLevelIcons";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AssetAttackSurface({
  asset,
  viewOnly,
}: AssetColumnRenderProps) {
  if (!asset?.assetId) {
    return <Box style={{ width: 70 }} />;
  }

  return (
    <Stack
      sx={{ width: !viewOnly ? "100%" : undefined }}
      direction={viewOnly ? "row" : "column"}
      alignItems={viewOnly ? "center" : undefined}
    >
      <CTDatagridButton
        isDisabled={viewOnly === true}
        asText={viewOnly === true}
        facetName={"attacksurface"}
        facetOption={asset.attackSurface ?? ""}
        startIcon={
          <AssetSecurityLevelIcons securityLevel={asset?.attackSurface} />
        }
      >
        {window.getCTTranslatedText(asset?.attackSurface)}
      </CTDatagridButton>
    </Stack>
  );
}
