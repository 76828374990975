import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MOBILE_MENU_ID } from "modules/AppBar/constants";
import React from "react";

interface MobileUserProfileMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: Function;
  onProfileClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const MobileUserProfileMenu = ({
  anchorEl,
  setAnchorEl,
  onProfileClick,
}: MobileUserProfileMenuProps) => {
  const handleMobileMenuClose = () => {
    setAnchorEl(null);
  };

  const isMobileMenuOpen = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={MOBILE_MENU_ID}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={onProfileClick}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
        <p>{window.getCTTranslatedText("Profile")}</p>
      </MenuItem>
    </Menu>
  );
};
