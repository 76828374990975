import { Button, Stack, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

export const EmptyAlertPage = () => {
  const navigate = useNavigate();
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        height: "100%",
      }}
      flex={1}
    >
      <Typography variant="body1">
        {window.getCTTranslatedText("All alerts are currently disabled.")}
      </Typography>
      <Typography variant="body1">
        {window.getCTTranslatedText(
          "To stay informed go to alerts config page and enable them."
        )}
      </Typography>
      <Stack alignItems="center" direction={"row"} justifyContent={"center"}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            mr: 3,
            mt: 2,
          }}
          onClick={() => navigate(`/monitoring?tab=alerts`)}
        >
          {window.getCTTranslatedText("Refresh") ?? ""}
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mr: 3,
            mt: 2,
          }}
          onClick={() => navigate(`/settings?tab=alertconfig`)}
        >
          {window.getCTTranslatedText("Alert Config") ?? ""}
        </Button>
      </Stack>
    </Stack>
  );
};
