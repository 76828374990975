import CloseIcon from "@mui/icons-material/Close";
import {
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";
import { parseErrorMessage } from "common/utils";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
  AssetStatusMap,
  AssetStatusReverseMap,
  SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { AssetStatus } from "pages/assets/types";
import {
  ProgressiveEnforcementLevel,
  ProgressiveEnforcementStatus,
  ProgressiveEnforcementStatusMap,
  ProgressiveEnforcementStatusReverseMap,
} from "pages/ports/types";
import { useEffect, useState } from "react";
import { PolicyAutomationActions } from "./components/policy-automation-actions";
import { PolicyAutomationGenerator } from "./components/policy-automation-generator";
import {
  useAddAutoPolicyConfigAPI,
  useTagBasedPolicyAutoPushAPI,
} from "./hooks";

interface PolicyAutomationDrawerProps {
  isOpen: boolean;
  onClose: (refresh: boolean) => void;
  id: string;
  criteria?: string;
  isZeroTrustAutomationEditable?: boolean;
}

const getEnforcementStatus = (
  selectedStatus: SecurityStatus,
  isTest: boolean
) => {
  let status = AssetStatusReverseMap[selectedStatus];
  if (!isTest || selectedStatus === SecurityStatus.Unsecure) {
    return status;
  }
  return `simulate-${status}`;
};

const getProgressiveEnforcementStatus = (
  selectedStatus: ProgressiveEnforcementLevel
) => {
  return ProgressiveEnforcementStatusReverseMap[selectedStatus];
};

const isTestMode = (status?: string) => {
  if (!status || status.includes("simulate-") || status.includes("unsecure")) {
    return true;
  }

  return false;
};

export const PolicyAutomationDrawer = ({
  isOpen,
  onClose,
  id,
  criteria,
  isZeroTrustAutomationEditable = true,
}: PolicyAutomationDrawerProps) => {
  const { isFeatureEnabled } = useFeatureFlagControl(FEATURES.PROGRESSIVE);
  const isProgressiveEnabled = isFeatureEnabled;
  const { data, isLoading } = useTagBasedPolicyAutoPushAPI(id);
  const updateDeployMutation = useAddAutoPolicyConfigAPI(id);

  const [selectedAttackSurfaceStatus, setSelectedAttackSurfaceStatus] =
    useState<SecurityStatus>(SecurityStatus.Unsecure);
  const [
    selectedProgressiveEnforcementLevel,
    setSelectedProgressiveEnforcementLevel,
  ] = useState<ProgressiveEnforcementLevel>(
    ProgressiveEnforcementLevel.ZeroTrust
  );
  const [selectedBlastRadiusStatus, setSelectedBlastRadiusStatus] =
    useState<SecurityStatus>(SecurityStatus.Unsecure);
  const [autoPush, setAutoPush] = useState<boolean>(false);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  const [attackSurfaceTestMode, setAttackSurfaceTestMode] = useState(
    isTestMode(data?.lowestInboundPolicyStatus)
  );
  const [blastRadiusTestMode, setBlastRadiusTestMode] = useState(
    isTestMode(data?.lowestOutboundPolicyStatus)
  );

  useEffect(() => {
    setAttackSurfaceTestMode(isTestMode(data?.lowestInboundPolicyStatus));
    setBlastRadiusTestMode(isTestMode(data?.lowestOutboundPolicyStatus));

    setSelectedAttackSurfaceStatus(
      AssetStatusMap[
        (data?.lowestInboundPolicyStatus as AssetStatus) ??
          AssetStatus.Unsecured
      ]
    );
    setSelectedBlastRadiusStatus(
      AssetStatusMap[
        (data?.lowestOutboundPolicyStatus as AssetStatus) ??
          AssetStatus.Unsecured
      ]
    );
    setSelectedProgressiveEnforcementLevel(
      ProgressiveEnforcementStatusMap[
        (data?.lowestProgressiveInboundPolicyStatus ??
          ProgressiveEnforcementStatus.ZeroTrust) as ProgressiveEnforcementStatus
      ]
    );
    setAutoPush(data?.autoSynchronizeEnabled ?? false);
  }, [
    data?.lowestInboundPolicyStatus,
    data?.autoSynchronizeEnabled,
    data?.lowestOutboundPolicyStatus,
    data?.lowestProgressiveInboundPolicyStatus,
  ]);

  const submitConfigForDeployMode = () => {
    const isZeroTrustProgressive =
      selectedProgressiveEnforcementLevel ===
      ProgressiveEnforcementLevel.ZeroTrust;
    const isAnyProgressive =
      selectedProgressiveEnforcementLevel === ProgressiveEnforcementLevel.Any;

    const attackSurfaceStatus =
      !isZeroTrustAutomationEditable &&
      (isZeroTrustProgressive || isAnyProgressive)
        ? SecurityStatus.Unsecure
        : selectedAttackSurfaceStatus;

    const body = {
      lowestInboundPolicyStatus: getEnforcementStatus(
        attackSurfaceStatus,
        attackSurfaceTestMode
      ),
      lowestOutboundPolicyStatus: getEnforcementStatus(
        selectedBlastRadiusStatus,
        blastRadiusTestMode
      ),
      autoSynchronizeEnabled: autoPush,
      lowestProgressiveInboundPolicyStatus: isProgressiveEnabled
        ? getProgressiveEnforcementStatus(selectedProgressiveEnforcementLevel)
        : undefined,
    };

    updateDeployMutation.mutateAsync(body, {
      onSuccess: response => {
        setSnackbar(
          true,
          SnackBarSeverity.Success,
          "ConfigurationSavedSuccessfully"
        );
        onClose(true);
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "0px",
          width: "40%",
          height: "100%",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={() => onClose(false)}
            sx={{ position: "absolute", right: "16px", top: "16px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction="column" spacing={2} sx={{ mt: 4, mb: 0, mx: 4 }}>
        <Typography variant="h5">
          <b>{window.getCTTranslatedText("Policy Automation")}</b>
        </Typography>
      </Stack>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <PolicyAutomationGenerator
          autoPush={autoPush}
          setAutoPush={setAutoPush}
          isInboundSimulated={attackSurfaceTestMode}
          isOutboundSimulated={blastRadiusTestMode}
          onChangeAttackSurfaceTestMode={setAttackSurfaceTestMode}
          onChangeBlastRadiusTestMode={setBlastRadiusTestMode}
          selectedBlastRadiusStatus={selectedBlastRadiusStatus}
          setSelectedBlastRadiusStatus={(status: SecurityStatus) => {
            setSelectedBlastRadiusStatus(status);
            setBlastRadiusTestMode(true);
          }}
          initialAttackSurfaceStatus={SecurityStatus.Unsecure}
          selectedAttackSurfaceStatus={selectedAttackSurfaceStatus}
          setSelectedAttackSurfaceStatus={(status: SecurityStatus) => {
            setSelectedAttackSurfaceStatus(status);
            setAttackSurfaceTestMode(true);
          }}
          initialProgressiveEnforcementLevel={
            ProgressiveEnforcementStatusMap[
              (data?.lowestProgressiveInboundPolicyStatus ??
                ProgressiveEnforcementStatus.ZeroTrust) as ProgressiveEnforcementStatus
            ]
          }
          selectedProgressiveEnforcementLevel={
            selectedProgressiveEnforcementLevel
          }
          setSelectedProgressiveEnforcementLevel={
            setSelectedProgressiveEnforcementLevel
          }
          isZeroTrustAutomationEditable={isZeroTrustAutomationEditable}
          isLoading={isLoading}
          criteria={criteria}
          policyId={id}
          policyProgressiveLastRefreshed={data?.policyProgressiveLastRefreshed}
        />
      </DialogContent>

      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <PolicyAutomationActions
          isDeployLoading={updateDeployMutation.isLoading}
          updateDeployMode={submitConfigForDeployMode}
          cancel={() => onClose(false)}
        />
      </DialogActions>
    </Drawer>
  );
};
