import { Stack, Typography } from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";
import { AssignedNetworksViewDrawer } from "modules/asset-networks/assigned-networks-view-drawer";
import { useAssetNetworks } from "modules/asset-networks/hooks";
import { AssignedTemplatesViewDrawer } from "modules/asset-templates/assigned-templates-view-drawer";
import { useAssetTemplates } from "modules/asset-templates/hooks";
import numeral from "numeral";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { Asset, PendingReviewCount } from "pages/assets/types";
import pluralize from "pluralize";
import { useState } from "react";

export interface PendingExtendedCountsProps extends PendingReviewCount {
  ports: number;
  paths: number;
}

interface MessageBlockCompProps {
  asset: Asset;
  direction?: Direction;
  pendingCounts: PendingExtendedCountsProps | undefined;
}

export const MessageBlockComp = ({
  pendingCounts,
  asset,
  direction,
}: MessageBlockCompProps) => {
  return (
    <>
      {pendingCounts && (
        <Stack direction="row" alignItems="center" sx={{ flexWrap: "wrap" }}>
          <Typography
            component={"span"}
            variant="body2"
            sx={{ whiteSpace: "nowrap" }}
          >
            {window.getCTTranslatedText("Status of")}
          </Typography>
          <ProgressivePortStatus
            pendingCounts={pendingCounts}
            asset={asset}
            direction={direction}
          />
          <PendingPortsCount
            pendingCounts={pendingCounts}
            asset={asset}
            direction={direction}
          />
          <PendingPathsCount
            pendingCounts={pendingCounts}
            asset={asset}
            direction={direction}
          />
          <BlockTemplateCount pendingCounts={pendingCounts} asset={asset} />
          <AllowedTemplate
            direction={direction}
            pendingCounts={pendingCounts}
            asset={asset}
          />
          <NamedNetworkChangeCount
            direction={direction}
            pendingCounts={pendingCounts}
            asset={asset}
          />
          <UnassignedAllowTemplatesCount
            direction={direction}
            pendingCounts={pendingCounts}
            asset={asset}
          />
          <UnassignedBlockTemplatesCount
            direction={direction}
            pendingCounts={pendingCounts}
            asset={asset}
          />
          <PendingNetworkCount pendingCounts={pendingCounts} asset={asset} />
          <Typography
            component={"span"}
            variant="body2"
            sx={{ pl: 0.5, whiteSpace: "nowrap" }}
          >
            {window.getCTTranslatedText("has been updated")}
          </Typography>
        </Stack>
      )}
    </>
  );
};

const ProgressivePortStatus = ({ pendingCounts }: MessageBlockCompProps) => {
  if (!pendingCounts?.progressiveSyncPending) {
    return null;
  }

  return (
    <>
      {pendingCounts && (
        <Stack
          direction="row"
          sx={{ whiteSpace: "nowrap", ml: 1 }}
          alignItems={"center"}
        >
          <Typography component={"span"} variant="body2">
            {window.getCTTranslatedText("progressivePorts")}
          </Typography>
        </Stack>
      )}
    </>
  );
};

const BlockTemplateCount = ({ pendingCounts }: MessageBlockCompProps) => {
  const [showBlockTemplatesDrawer, setShowBlockTemplatesDrawer] =
    useState(false);

  const criteria = `templateName in (${
    pendingCounts &&
    (pendingCounts?.blockTemplates || [])
      .map(templatename => `'${templatename}'`)
      .join(",")
  })`;

  const {
    templateList,
    isLoading,
    templateListMutation,
    onSortChange,
    sort,
    setPageSize,
    setPage,
    pageSize,
    page,
    totalCount,
    onSearchCriteriaChange,
  } = useAssetTemplates({
    criteria: criteria,
    enable: showBlockTemplatesDrawer,
  });

  const openBlockTemplatesDrawer = () => {
    setShowBlockTemplatesDrawer(true);
  };

  const closeBlockTemplatesDrawer = () => {
    setShowBlockTemplatesDrawer(false);
  };

  if (pendingCounts && !pendingCounts?.blockTemplates) {
    return null;
  }

  return (
    <>
      {pendingCounts && (
        <Stack
          direction="row"
          sx={{ whiteSpace: "nowrap" }}
          spacing={0}
          alignItems={"center"}
        >
          <Separator
            show={Boolean(pendingCounts?.paths > 0 || pendingCounts?.ports > 0)}
          />
          <Stack>
            <DataGridButton
              sx={{
                color: "inherit",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => {
                openBlockTemplatesDrawer();
              }}
            >
              {numeral(pendingCounts?.blockTemplates?.length ?? 0).format(
                "0,0"
              )}
            </DataGridButton>
          </Stack>
          <Typography component={"span"} variant="body2">
            {getFormattedStr(
              "block template",
              pendingCounts?.blockTemplates?.length || 0
            )}
          </Typography>
        </Stack>
      )}
      {showBlockTemplatesDrawer && (
        <AssignedTemplatesViewDrawer
          isOpen={showBlockTemplatesDrawer}
          onClose={closeBlockTemplatesDrawer}
          templateList={templateList}
          isLoading={isLoading}
          templatesSuggestMutation={templateListMutation}
          title={"Assigned Block Templates"}
          page={"assets"}
          onSortChange={onSortChange}
          setPageSize={setPageSize}
          setPage={setPage}
          pageSize={pageSize}
          pageCount={page}
          sort={sort}
          totalCount={totalCount}
          onSearchCriteriaChange={onSearchCriteriaChange}
        />
      )}
    </>
  );
};

const AllowedTemplate = ({
  pendingCounts,
  asset,
  direction,
}: MessageBlockCompProps) => {
  const allowedTemplate =
    direction === Direction.Inbound
      ? asset.attackSurfacePendingChanges?.allowTemplates
      : asset.blastRadiusPendingChanges?.allowTemplates;

  if (!allowedTemplate?.length) {
    return null;
  }

  return (
    <>
      {pendingCounts && (
        <Stack
          direction="row"
          sx={{ whiteSpace: "nowrap" }}
          spacing={0}
          alignItems={"center"}
        >
          <Separator
            show={Boolean(pendingCounts?.paths > 0 || pendingCounts?.ports > 0)}
          />
          <Stack>
            <Typography component={"span"} variant="body2" sx={{ px: 0.5 }}>
              {numeral(allowedTemplate?.length ?? 0).format("0,0")}
            </Typography>
          </Stack>
          <Typography component={"span"} variant="body2">
            {getFormattedStr("allowed template", allowedTemplate?.length || 0)}
          </Typography>
        </Stack>
      )}
    </>
  );
};

const NamedNetworkChangeCount = ({
  pendingCounts,
  asset,
  direction,
}: MessageBlockCompProps) => {
  const namedNetworkChange =
    direction === Direction.Inbound
      ? asset.attackSurfacePendingChanges?.namednetworkChange
      : asset.blastRadiusPendingChanges?.namednetworkChange;

  if (!namedNetworkChange?.length) {
    return null;
  }

  return (
    <>
      {pendingCounts && (
        <Stack
          direction="row"
          sx={{ whiteSpace: "nowrap" }}
          spacing={0}
          alignItems={"center"}
        >
          <Separator
            show={Boolean(pendingCounts?.paths > 0 || pendingCounts?.ports > 0)}
          />
          <Stack>
            <Typography component={"span"} variant="body2" sx={{ px: 0.5 }}>
              {numeral(namedNetworkChange?.length ?? 0).format("0,0")}
            </Typography>
          </Stack>
          <Typography component={"span"} variant="body2">
            {getFormattedStr("named network", namedNetworkChange?.length || 0)}{" "}
          </Typography>
        </Stack>
      )}
    </>
  );
};

const UnassignedAllowTemplatesCount = ({
  pendingCounts,
  asset,
  direction,
}: MessageBlockCompProps) => {
  const unassignedAllowTemplates =
    direction === Direction.Inbound
      ? asset.attackSurfacePendingChanges?.unassignedAllowTemplates
      : asset.blastRadiusPendingChanges?.unassignedAllowTemplates;

  if (!unassignedAllowTemplates?.length) {
    return null;
  }

  return (
    <>
      {pendingCounts && (
        <Stack
          direction="row"
          spacing={0}
          sx={{ whiteSpace: "nowrap" }}
          alignItems={"center"}
        >
          <Separator
            show={Boolean(pendingCounts?.paths > 0 || pendingCounts?.ports > 0)}
          />
          <Stack>
            <Typography component={"span"} variant="body2" sx={{ px: 0.5 }}>
              {numeral(unassignedAllowTemplates?.length ?? 0).format("0,0")}
            </Typography>
          </Stack>
          <Typography component={"span"} variant="body2">
            {getFormattedStr(
              "unassigned allow template",
              unassignedAllowTemplates?.length || 0
            )}
          </Typography>
        </Stack>
      )}
    </>
  );
};

const UnassignedBlockTemplatesCount = ({
  pendingCounts,
  asset,
  direction,
}: MessageBlockCompProps) => {
  const unassignedBlockTemplates =
    direction === Direction.Inbound
      ? asset.attackSurfacePendingChanges?.unassignedBlockTemplates
      : asset.blastRadiusPendingChanges?.unassignedBlockTemplates;

  if (!unassignedBlockTemplates?.length) {
    return null;
  }

  return (
    <>
      {pendingCounts && (
        <Stack
          direction="row"
          spacing={0}
          sx={{ whiteSpace: "nowrap" }}
          alignItems={"center"}
        >
          <Separator
            show={Boolean(pendingCounts?.paths > 0 || pendingCounts?.ports > 0)}
          />
          <Stack>
            <Typography component={"span"} variant="body2" sx={{ px: 0.5 }}>
              {numeral(unassignedBlockTemplates?.length ?? 0).format("0,0")}
            </Typography>
          </Stack>
          <Typography component={"span"} variant="body2">
            {getFormattedStr(
              "unassigned block template",
              unassignedBlockTemplates?.length || 0
            )}
          </Typography>
        </Stack>
      )}
    </>
  );
};

const PendingNetworkCount = ({ pendingCounts }: MessageBlockCompProps) => {
  const [showNetworkDrawer, setShowNetworkDrawer] = useState(false);

  const criteria = `namedNetworkName in (${
    pendingCounts &&
    (pendingCounts?.intranetChange || [])
      .map(namedNetworkName => `'${namedNetworkName}'`)
      .join(",")
  })`;

  const {
    networkList,
    isLoading,
    networkListMutation,
    onSortChange,
    setPageSize,
    setPage,
    pageSize,
    page: pageCount,
    totalCount,
    onSearchCriteriaChange,
  } = useAssetNetworks({ criteria, enable: showNetworkDrawer });

  const openNetworkDrawer = () => {
    setShowNetworkDrawer(true);
  };

  const closeNetworkDrawer = () => {
    setShowNetworkDrawer(false);
  };

  if (pendingCounts && !pendingCounts?.intranetChange) {
    return null;
  }

  return (
    <>
      {" "}
      {pendingCounts && (
        <Stack
          direction="row"
          spacing={0}
          sx={{ whiteSpace: "nowrap" }}
          alignItems={"center"}
        >
          <Separator
            show={Boolean(
              pendingCounts?.paths > 0 ||
                pendingCounts?.ports > 0 ||
                (pendingCounts?.blockTemplates &&
                  pendingCounts.blockTemplates.length > 0)
            )}
          />
          <DataGridButton
            sx={{
              color: "inherit",
              "&:hover": { textDecoration: "underline" },
            }}
            onClick={() => {
              openNetworkDrawer();
            }}
          >
            {numeral(pendingCounts?.intranetChange?.length ?? 0).format("0,0")}
          </DataGridButton>

          <Typography component={"span"} variant="body2">
            {getFormattedStr(
              "default intranet",
              pendingCounts?.intranetChange?.length || 0
            )}
          </Typography>
        </Stack>
      )}
      {showNetworkDrawer && (
        <AssignedNetworksViewDrawer
          isOpen={showNetworkDrawer}
          onClose={closeNetworkDrawer}
          networkList={networkList}
          networksSuggestMutation={networkListMutation}
          title={"Default Intranet Named Network"}
          page="assets"
          onSortChange={onSortChange}
          isLoading={isLoading}
          setPage={setPage}
          setPageSize={setPageSize}
          pageCount={pageCount}
          pageSize={pageSize}
          totalCount={totalCount}
          onSearchCriteriaChange={onSearchCriteriaChange}
        />
      )}
    </>
  );
};

const PendingPathsCount = ({ pendingCounts }: MessageBlockCompProps) => {
  if (!pendingCounts?.paths) {
    return null;
  }

  return (
    <>
      {pendingCounts && (
        <Stack
          direction="row"
          sx={{ whiteSpace: "nowrap" }}
          spacing={0}
          alignItems={"center"}
        >
          <Separator show={Boolean(pendingCounts?.ports > 0)} />
          <Stack>
            <DataGridButton
              asText
              disabled
              variant={"text"}
              color={"inherit"}
              sx={{
                "&:hover": { textDecoration: "none" },
              }}
            >
              {numeral(pendingCounts.paths ?? 0).format("0,0")}
            </DataGridButton>
          </Stack>
          <Typography component={"span"} variant="body2">
            {getFormattedStr("path", pendingCounts?.paths || 0)}
          </Typography>
        </Stack>
      )}
    </>
  );
};

const PendingPortsCount = ({ pendingCounts }: MessageBlockCompProps) => {
  if (!pendingCounts?.ports) {
    return null;
  }

  return (
    <>
      {pendingCounts && (
        <Stack
          direction="row"
          sx={{ whiteSpace: "nowrap" }}
          spacing={0}
          alignItems={"center"}
        >
          <Separator show={Boolean(pendingCounts?.progressiveSyncPending)} />
          <Stack>
            <DataGridButton
              asText
              disabled
              variant={"text"}
              color={"inherit"}
              sx={{
                "&:hover": { textDecoration: "none" },
              }}
            >
              {numeral(pendingCounts.ports ?? 0).format("0,0")}
            </DataGridButton>
          </Stack>
          <Typography component={"span"} variant="body2">
            {getFormattedStr("port", pendingCounts?.ports || 0)}
          </Typography>
        </Stack>
      )}
    </>
  );
};

const Separator = ({ show }: { show: boolean }) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <Typography component={"span"} variant="body2">
        ,
      </Typography>
    </>
  );
};

function getFormattedStr(key: string, count: number) {
  return count > 0
    ? `${pluralize(window.getCTTranslatedText(key), count || 0)}`
    : "";
}
