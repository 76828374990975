import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";

export const getUniqueListBy = (arr: any[], key: string) => {
  return uniqBy(arr, key);
};

export const getUniqueValues = (arr: any[]) => {
  return uniq(arr);
};
