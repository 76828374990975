import { Box, Stack, debounce } from "@mui/material";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { VulnerabilityBody } from "../../types";
import { useMutation } from "@tanstack/react-query";
import { SortOrder } from "modules/core/types";
import { useEffect, useMemo, useState } from "react";
import { VULNERABILITY_COLUMNS } from "./constants";
import { useVulnerabilityStore } from "modules/vulnerability-drawer/stores";

interface VulnerabilityDataGridProps {
  assetId: string;
}

const useVulnerabilitiesAPI = (assetId: string) => {
  return useMutation<any, Error, any>([
    "vulnerability-list",
    `assets/${assetId}/vulnerabilities?computeTotal=true`,
  ]);
};

export const VulnerabilityDataGrid = (props: VulnerabilityDataGridProps) => {
  const defaultSortOrder: Array<SortOrder> = [
    { field: "severity", order: "desc" },
  ];

  const vulnerabilitiesAPI = useVulnerabilitiesAPI(props.assetId);

  const [sort, setSort] = useState<Array<SortOrder>>(defaultSortOrder);
  const [rows, setRows] = useState<Array<VulnerabilityBody> | undefined>(
    undefined
  );
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const apiRefreshRequest = useVulnerabilityStore(
    (state: { apiRefreshRequest: any }) => state.apiRefreshRequest
  );

  const mutate = useMemo(
    () => debounce(vulnerabilitiesAPI.mutate, 300),
    [vulnerabilitiesAPI.mutate]
  );

  useEffect(() => {
    const search = {
      pagination: {
        offset: page * pageSize,
        limit: pageSize,
        sort,
      },
    };

    const dataMapper = (pack: VulnerabilityBody) => {
      return pack;
    };
    mutate(search, {
      onSuccess(data) {
        if (data) {
          const responseData = data;
          setRows((responseData?.items || []).map(dataMapper) || []);
          setTotalCount(responseData?.metadata.total || 0);
        }
      },
    });
  }, [props, mutate, page, pageSize, sort, apiRefreshRequest]);

  let hasExploit = useMemo(() => {
    return rows?.some((row: VulnerabilityBody) => {
      return Boolean(row.exploitURL);
    });
  }, [rows]);

  const columns = useMemo(
    () =>
      VULNERABILITY_COLUMNS.filter(column => {
        if (column.field === "exploitURL") {
          return hasExploit;
        }

        return true;
      }),
    [hasExploit]
  );

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <DataGrid
          rowHeight={64}
          columns={columns}
          pagination
          getRowId={({ cveID }: VulnerabilityBody) => cveID}
          paginationMode="server"
          sortingMode="server"
          initialState={{
            sorting: {
              sortModel: [{ field: "severity", sort: "desc" }],
            },
          }}
          isLoading={vulnerabilitiesAPI.isLoading || !rows}
          rows={rows}
          rowCount={totalCount}
          onPageChange={setPage}
          page={page}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          onSortChange={sortModel => {
            if (sortModel?.length > 0) {
              setSort(sortModel);
            }
          }}
        />
      </Box>
    </Stack>
  );
};
