import { Checkbox } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CheckBoxWithOptions } from "common/atoms/checkbox-with-options";
import { CheckedWithOptionValue } from "common/atoms/checkbox-with-options/types";
import {
  CheckboxSelectionStore,
  CheckboxSelectionType,
} from "modules/recommendation-workflows/components/IPs-datagrid/store/types";
import React from "react";
import { MergedIPsForInboundAndOutBound } from "../../IPsDataGrid";
import { useShallow } from "zustand/react/shallow";

enum CHECKBOX_OPTION_TYPE {
  SELECT_OBSERVED_IPs = "Select Observed IPs",
  SELECT_ENTIRE_SUBNET = "Select Entire Subnet",
}

const DEFAULT_CHECKBOX_SELECTION_OPTION = {
  id: CHECKBOX_OPTION_TYPE.SELECT_OBSERVED_IPs,
  label: CHECKBOX_OPTION_TYPE.SELECT_OBSERVED_IPs,
};

const CHECKBOX_SELECTION_OPTIONS = [
  DEFAULT_CHECKBOX_SELECTION_OPTION,
  {
    id: CHECKBOX_OPTION_TYPE.SELECT_ENTIRE_SUBNET,
    label: CHECKBOX_OPTION_TYPE.SELECT_ENTIRE_SUBNET,
  },
];

interface IPSelectionCheckboxProps
  extends GridRenderCellParams<any, MergedIPsForInboundAndOutBound, any> {
  recommendationList: MergedIPsForInboundAndOutBound[];
  useStore: CheckboxSelectionStore;
}

export const IPSelectionCheckbox = (props: IPSelectionCheckboxProps) => {
  const rowID = props.id as string;
  const rowParentID = props.rowNode.parent ?? "";
  const rowSelection = props.useStore(
    useShallow(state => state.selection[rowID])
  );
  const parentRowSelection = props.useStore(
    useShallow(state => state.selection[rowParentID])
  );
  const recommendationList = props.recommendationList;

  const setPartialIPs = props.useStore(
    useShallow(state => state.setPartialIPs)
  );
  const setSubnetOrAllObservedIPs = props.useStore(
    useShallow(state => state.setSubnetOrAllObservedIPs)
  );
  const isChecked =
    Boolean(rowSelection) ||
    parentRowSelection?.selectionType === CheckboxSelectionType.ALL_IPS ||
    Boolean(parentRowSelection?.selectedIPs?.[rowID]);

  const onChange = (
    _: React.SyntheticEvent,
    value: CheckedWithOptionValue<string>
  ) => {
    if (value.option?.id === CHECKBOX_OPTION_TYPE.SELECT_OBSERVED_IPs) {
      return setSubnetOrAllObservedIPs({
        rowID,
        selectionType: CheckboxSelectionType.ALL_IPS,
        isChecked,
        selectedIPs:
          props.row.IPS?.map(
            (ip: MergedIPsForInboundAndOutBound) => `ip-${ip.IP}`
          ) ?? [],
      });
    }

    if (value.option?.id === CHECKBOX_OPTION_TYPE.SELECT_ENTIRE_SUBNET) {
      return setSubnetOrAllObservedIPs({
        rowID,
        selectionType: CheckboxSelectionType.SUBNET,
        isChecked,
      });
    }

    setSubnetOrAllObservedIPs({
      rowID,
      selectionType:
        rowSelection?.selectionType ?? CheckboxSelectionType.ALL_IPS,
      isChecked,
    });
  };

  const handleOnChangeForSingleCheckboxSelection = () => {
    setPartialIPs({
      rowID: rowParentID as string,
      selectionType: CheckboxSelectionType.PARTIAL_IPS,
      isChecked,
      selectedIP: rowID,
      availableIPs: getAvailableIPs(recommendationList, rowParentID as string),
    });
  };

  if (rowID?.includes("subnet")) {
    return (
      <CheckBoxWithOptions
        value={{ checked: isChecked }}
        defaultOption={DEFAULT_CHECKBOX_SELECTION_OPTION}
        onChange={onChange}
        options={CHECKBOX_SELECTION_OPTIONS}
        checkBoxProps={{
          indeterminate:
            rowSelection?.selectionType === CheckboxSelectionType.PARTIAL_IPS,
        }}
      />
    );
  }

  return (
    <Checkbox
      key={`${isChecked}`}
      checked={isChecked}
      onChange={handleOnChangeForSingleCheckboxSelection}
      sx={{ marginLeft: 1 }}
    />
  );
};

function getAvailableIPs(
  recommendationList: MergedIPsForInboundAndOutBound[],
  rowParentID: string
) {
  return (
    recommendationList
      .find(row => {
        const subnetKey = getSubnetKey(row.subnet);

        return subnetKey === rowParentID;
      })
      ?.IPS?.map(ip => `ip-${ip.IP}`) ?? []
  );
}

const getSubnetKey = (subnet: string | undefined) => {
  return `subnet-${subnet}`;
};
