import {
  AlertColor,
  Box,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { getRelativeLastObserved } from "common/utils";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { FacetOptionState, FacetState, Operator } from "modules/facets/types";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import { SynchronizeAssetPolicyInt } from "pages/asset/components/asset-detail/AssetSecurityDetailWithoutAggregatedEndpoint";
import { AttackSurfaceStatusOptionsText } from "pages/assets/constants";
import { useAssetStore } from "pages/assets/store";
import {
  Asset,
  AssetSecurityCategory,
  AssetStatus,
  AssetType,
  SecurityLevels,
} from "pages/assets/types";
import { SecurityTitleBlock } from "pages/dashboard/components/section-title-block";
import { PathStatus } from "pages/paths/types";
import {
  ProgressiveEnforcementLevelLabels,
  ProgressiveEnforcementStatus,
  ProgressiveEnforcementStatusMap,
} from "pages/ports/types";
import { useState } from "react";
import { AssetReviewDrawer } from "./components/asset-review-drawer";
import { AssetStatusAction } from "./components/asset-status-action";
import { AssetStatusAlert } from "./components/asset-status-alert";
import { AssetStatusSlider } from "./components/asset-status-slider/AssetStatusSlider";
import { AssetSynchronizeAlert } from "./components/asset-synchronize-alert";
import { canDisplaySynchornizeAlert } from "./components/asset-synchronize-alert/helpers";
import { SingleAssetClassificationByLevels } from "./components/single-assets-classification-by-levels";
import { ReviewDetailsInt } from "./components/single-assets-classification-by-levels/SingleAssetClassificationByLevels";
import { AssetStatusMap, Direction, SecurityStatus } from "./constants";
import { useSynchronizePolicy } from "./hooks/use-update-status";

const UNREVIEWED_READ_ONLY_DIMENSIONS = [
  "listenportreviewed",
  "listenportlastobserved",
  "pathlastobserved",
  "reviewed",
];

const READ_ONLY_DIMENSIONS = ["listenportlastobserved", "pathlastobserved"];

interface AssetAttackSurfaceDetailProps {
  asset: Asset;
  isLoading: boolean;
  reviewAttackSurface: ({ data, unreviewed }: ReviewDetailsInt) => void;
  reviewASDrawerVisibility: boolean;
  selectedAttackSurfaceStatus: SecurityStatus;
  selectedBlastRadiusStatus: SecurityStatus;
  setSelectedAttackSurfaceStatus: (value: SecurityStatus) => void;
  isTestModeVisibleForInbound: boolean;
  setIsTestModeVisibleForInbound: (value: boolean) => void;
  showUnreviewedFacet: boolean;
  activeTab: Direction;
  inBoundComment: string | undefined;
  setInBoundComment: (value: string | undefined) => void;
  statusChangeCallback: () => void;
  onCloseReviewDrawer: () => void;
  data: any;
  synchronizeAssetPolicy: ({
    direction,
  }: SynchronizeAssetPolicyInt) => Promise<void>;
  activeOperation: Direction | undefined;
}

export const AssetAttackSurfaceDetail = ({
  asset,
  isLoading,
  reviewAttackSurface,
  reviewASDrawerVisibility,
  selectedAttackSurfaceStatus,
  selectedBlastRadiusStatus,
  setSelectedAttackSurfaceStatus,
  isTestModeVisibleForInbound,
  setIsTestModeVisibleForInbound,
  showUnreviewedFacet,
  activeTab,
  inBoundComment,
  setInBoundComment,
  statusChangeCallback,
  onCloseReviewDrawer,
  data,
  synchronizeAssetPolicy,
  activeOperation,
}: AssetAttackSurfaceDetailProps) => {
  const { isFeatureEnabled: isProgressiveEnabled } = useFeatureFlagControl(
    FEATURES.PROGRESSIVE
  );
  const synchronizePolicyMutation = useSynchronizePolicy();
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const [isInboundDrawerOpen, setIsInboundDrawerOpen] = useState(false);

  const attackSurfaceSyncAlert = canDisplaySynchornizeAlert({
    direction: Direction.Inbound,
    asset: asset,
  });

  const displayTextProgressiveMode = asset?.lowestProgressiveInboundAssetStatus
    ? ProgressiveEnforcementLevelLabels[
        ProgressiveEnforcementStatusMap[
          (asset?.lowestProgressiveInboundAssetStatus ??
            ProgressiveEnforcementStatus.ZeroTrust) as ProgressiveEnforcementStatus
        ]
      ]
    : "";

  const direction = AssetSecurityCategory.INBOUND;
  const portFacetState: FacetState = new Map();

  const pathFacetState: FacetState = new Map([
    [
      "direction",
      new Map([[direction, { isSelected: true, operator: Operator.EQUAL }]]),
    ],
  ]);

  if (showUnreviewedFacet) {
    const unreviewedOptions: FacetOptionState = new Map([
      [PathStatus.Unreviewed, { isSelected: true, operator: Operator.EQUAL }],
    ]);
    pathFacetState.set("reviewed", unreviewedOptions);
    portFacetState.set("listenportreviewed", unreviewedOptions);
  }

  return (
    <Paper sx={{ width: "100%" }}>
      <Stack direction={"row"} alignItems={"center"} sx={{ width: "100%" }}>
        <Stack
          alignContent={"center"}
          justifyItems={"center"}
          justifyContent="space-between"
          sx={{
            width: "100%",
            background: theme =>
              theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, 0.04)"
                : "rgba(0, 0, 0, 0.04)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
          }}
          direction="row"
        >
          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"center"}
            >
              <SecurityTitleBlock
                title={"Attack Surface"}
                addBackground={false}
              />
            </Stack>
            <Stack
              alignContent={"center"}
              justifyItems={"center"}
              justifyContent={"center"}
              sx={{ display: "none" }}
            >
              <Typography>
                {AttackSurfaceStatusOptionsText[asset.inboundAssetStatus]}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            alignContent={"center"}
            justifyItems={"center"}
            justifyContent={"center"}
            sx={{ pr: 3 }}
          >
            <Typography variant="overline">
              {window.getCTTranslatedText("Inbound")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          opacity: asset.type === AssetType.UserGroup ? 0.4 : 1,
          pointerEvents: asset.type === AssetType.UserGroup ? "none" : "",
        }}
      >
        <Stack>
          <SingleAssetClassificationByLevels
            data={{
              status: asset.attackSurface as SecurityLevels,
              loading: isLoading,
              data: [
                {
                  name: "ports",
                  label: "Ports",
                  unreviewed: asset?.unreviewedPorts ?? 0,
                  total: asset?.totalPorts ?? 0,
                },
                {
                  name: "paths",
                  label: "Paths",
                  unreviewed:
                    (asset?.inboundInternetPaths?.unreviewed ?? 0) +
                    (asset?.inboundIntranetPaths.unreviewed ?? 0),
                  total:
                    (asset?.inboundInternetPaths?.total ?? 0) +
                    (asset?.inboundIntranetPaths.total ?? 0),
                },
              ],
            }}
            type="attacksurface"
            category="Attack Surface"
            reviewDetails={reviewAttackSurface}
          />
        </Stack>
        <Stack sx={{ px: 3 }}>
          <Divider sx={{ borderColor: "rgba(0, 0, 0, 0.05)" }} />
        </Stack>
        <Stack
          justifyContent={"flex-start"}
          alignContent={"flex-start"}
          alignItems="flex-start"
          sx={{ px: 3, mt: 3, width: "100%" }}
        >
          {userPermissions.has("UPDATE_ASSET_ZEROTRUST") &&
          attackSurfaceSyncAlert.show &&
          AssetStatusMap[asset.inboundAssetStatus] ===
            selectedAttackSurfaceStatus ? (
            <AssetSynchronizeAlert
              severity={"warning" as AlertColor}
              messageComp={attackSurfaceSyncAlert?.messageComp}
              clickCallback={() =>
                synchronizeAssetPolicy({
                  direction: Direction.Inbound,
                })
              }
              loading={
                activeOperation === Direction.Inbound &&
                synchronizePolicyMutation.isLoading
              }
              direction={Direction.Inbound}
              asset={asset}
              canViewChanges={Boolean(attackSurfaceSyncAlert.totalPendingCount)}
              showInternetChangesOnly={false}
            />
          ) : (
            AssetStatusAlert({
              type: Direction.Inbound,
              status: asset.attackSurface as SecurityLevels,
              asset: asset,
              selectedAttackSurfaceStatus: selectedAttackSurfaceStatus,
              selectedBlastRadiusStatus: selectedBlastRadiusStatus,
            })
          )}

          {reviewASDrawerVisibility && (
            <AssetReviewDrawer
              isOpen={reviewASDrawerVisibility}
              onClose={onCloseReviewDrawer}
              asset={asset}
              direction={direction}
              mode={AssetReviewModeType.Review}
              title="Review Inbound Ports and Paths"
              portFacetState={portFacetState}
              pathFacetState={pathFacetState}
              activeTab={activeTab}
              readOnlyDimensions={
                showUnreviewedFacet
                  ? UNREVIEWED_READ_ONLY_DIMENSIONS
                  : READ_ONLY_DIMENSIONS
              }
            />
          )}
        </Stack>
        <Stack sx={{ mt: 15 }}>
          <Box
            sx={{
              width: "100%",
              px: 5,
            }}
          >
            <AssetStatusSlider
              direction={Direction.Inbound}
              asset={asset}
              selectedStatus={selectedAttackSurfaceStatus}
              setSelectedStatus={setSelectedAttackSurfaceStatus}
              simulatedStatus={isTestModeVisibleForInbound}
              disabled={
                !userPermissions.get("UPDATE_ASSET_ZEROTRUST") ||
                asset.type === AssetType.UserGroup
              }
              minValue={
                AssetStatusMap[data.lowestInboundAssetStatus as AssetStatus]
              }
            />
            {isProgressiveEnabled && (
              <Stack
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                height={"40px"}
              >
                {displayTextProgressiveMode && (
                  <Stack direction="row" alignItems="center">
                    <Typography variant="body2">
                      {window.getCTTranslatedText("progressive")}:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme => theme.palette.text.secondary,
                        ml: 1,
                      }}
                    >
                      {window.getCTTranslatedText(displayTextProgressiveMode)}
                    </Typography>
                  </Stack>
                )}

                {asset?.assetProgressiveLastRefreshed && (
                  <Stack direction="row" alignItems="center">
                    <Typography variant="body2">
                      {window.getCTTranslatedText("lastSynced")}:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme => theme.palette.text.secondary,
                        ml: 1,
                      }}
                    >
                      {getRelativeLastObserved(
                        asset?.assetProgressiveLastRefreshed ?? ""
                      )}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </Box>
        </Stack>
        <Stack sx={{ width: "100%", p: 4, pt: 0 }}>
          <AssetStatusAction
            asset={asset}
            direction={Direction.Inbound}
            selectedStatus={selectedAttackSurfaceStatus}
            disabled={!userPermissions.get("UPDATE_ASSET_ZEROTRUST")}
            statusChangeCallback={statusChangeCallback}
            comment={inBoundComment}
            setComment={setInBoundComment}
            minValue={
              AssetStatusMap[data.lowestInboundAssetStatus as AssetStatus]
            }
            isTestMode={isTestModeVisibleForInbound}
            onChangeTestMode={setIsTestModeVisibleForInbound}
            isCommentDrawerOpen={isInboundDrawerOpen}
            setIsCommentDrawerOpen={setIsInboundDrawerOpen}
            useAssetStore={useAssetStore}
            isCommentsVisible={true}
            isPreviewMode={
              asset.type !== AssetType.Endpoint &&
              asset.type !== AssetType.Service
            }
            hasPermission={userPermissions.has("UPDATE_ASSET_ZEROTRUST")}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};
