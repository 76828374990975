import BadgeIcon from "@mui/icons-material/Badge";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MonitorIcon from "@mui/icons-material/Monitor";
import { Box, Stack, useTheme } from "@mui/material";
import { AssetCoreTagBaseProps, CoreTagsKeys } from "pages/assets/types";

export function AssetTagsIcon({ asset, coreTagKey }: AssetCoreTagBaseProps) {
  const theme = useTheme();
  if (!asset?.assetId) {
    return <Box sx={{ width: 24 }} />;
  }

  let icon;

  const iconStyle = {
    color:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, 0.38)"
        : "rgba(0, 0, 0, 0.38)",
  };

  switch (coreTagKey) {
    case CoreTagsKeys.Application:
      icon = <MonitorIcon width="24" height="24" sx={iconStyle} />;
      break;
    case CoreTagsKeys.Location:
      icon = <LocationOnIcon width="24" height="24" sx={iconStyle} />;
      break;
    case CoreTagsKeys.Role:
      icon = <BadgeIcon width="24" height="24" sx={iconStyle} />;
      break;
    case CoreTagsKeys.Owner:
      icon = <ManageAccountsIcon width="24" height="24" sx={iconStyle} />;
      break;
    case CoreTagsKeys.Environment:
      icon = <CloudQueueIcon width="24" height="24" sx={iconStyle} />;
      break;
    case CoreTagsKeys.UserGroup:
      icon = <GroupsOutlinedIcon width="24" height="24" sx={iconStyle} />;
      break;
    case CoreTagsKeys.Department:
      icon = <Diversity3OutlinedIcon width="24" height="24" sx={iconStyle} />;
      break;
    default:
      icon = <LocalOfferOutlinedIcon width="24" height="24" sx={iconStyle} />;
  }

  return (
    <Stack
      alignContent={"center"}
      justifyItems={"center"}
      justifyContent={"center"}
    >
      {icon}
    </Stack>
  );
}
