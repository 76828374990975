import { Button, Stack, Typography } from "@mui/material";
import RouterOutlinedIcon from "@mui/icons-material/RouterOutlined";

interface DownloadProps {
  onInstallInstructions: () => void;
}

export function DownloadItem({ onInstallInstructions }: DownloadProps) {
  return (
    <Stack
      sx={{
        p: 5,
        width: "100%",
        background: theme =>
          theme.palette.mode === "dark"
            ? theme.palette.divider
            : theme.palette.background.paper,
      }}
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      boxShadow={2}
      borderRadius={1}
    >
      <Stack direction="row" spacing={1} minWidth={180}>
        <Stack justifyContent={"center"}>
          <RouterOutlinedIcon sx={{ fontSize: 35 }} />
        </Stack>
        <Stack justifyContent={"center"}>
          <Typography variant="subtitle1">
            {window.getCTTranslatedText("appliance")}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => onInstallInstructions()}
        >
          {window.getCTTranslatedText("registerInstructions")}
        </Button>
      </Stack>
    </Stack>
  );
}
