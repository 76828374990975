import { Box, Stack, Tooltip } from "@mui/material";
import { NetworkColumnRenderProps } from "pages/networks/types";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function NetworkType({ network, viewOnly }: NetworkColumnRenderProps) {
  if (!network?.namedNetworkId) {
    return <Box style={{ width: 120 }} />;
  }

  let icon = <PersonIcon sx={{ width: 24, height: 24, mt: 1.25 }}></PersonIcon>;
  let text = "User defined network";

  if (network.isOOBNetwork) {
    icon = (
      <SettingsIcon sx={{ width: 24, height: 24, mt: 1.25 }}></SettingsIcon>
    );
    text = "System defined network";
  }

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <Tooltip title={window.getCTTranslatedText(text || "")}>
        <CTDatagridButton
          asText={viewOnly === true}
          isDisabled={viewOnly === true}
          facetName={"isoobnetwork"}
          facetOption={network.isOOBNetwork.toString() ?? ""}
          stopPropagation={true}
        >
          {icon}
        </CTDatagridButton>
      </Tooltip>
    </Stack>
  );
}
