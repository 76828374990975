import {
  Box,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useState } from "react";
import {
  Appliance,
  ApplianceFormProps,
  RecoveryModes,
} from "pages/appliances/types";
import CloseIcon from "@mui/icons-material/Close";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useApplianceRestorePutAPI } from "../../hooks";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { parseErrorMessage } from "common/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbarStore } from "modules/snackbar/store";
import { OnlineRecovery } from "./components/OnlineRecovery";
import { OfflineRecovery } from "./components/OfflineRecovery";

export interface ApplianceRecoverProps {
  appliance?: ApplianceFormProps | Appliance;
  isOpen?: boolean;
  title?: string;
  updateApplianceData?: Function;
  cancel?: () => void;
  btnTitle?: string;
}

const initalActiveAppliances = ["None"];

export function ApplianceRecoverForm({
  cancel,
  isOpen,
  title,
  appliance,
  updateApplianceData,
  btnTitle,
}: Readonly<ApplianceRecoverProps>) {
  const [recoveryMode, setRecoveryMode] = useState(RecoveryModes.Online);
  const [selectedAppliance, setSelectedAppliance] = useState<Appliance>();

  const restoreApplianceAPI = useApplianceRestorePutAPI(
    appliance?.agentId,
    selectedAppliance?.agentId
  );
  const queryClient = useQueryClient();
  const setSnackbar = useSnackbarStore(
    (state: { setSnackbar: any }) => state.setSnackbar
  );

  const restoreAppliance = () => {
    restoreApplianceAPI.mutate(
      {},
      {
        onSuccess: response => {
          queryClient.invalidateQueries({
            queryKey: ["gatekeeper-restore"],
          });
          updateApplianceData?.();
          setTimeout(() => {
            cancel?.();
          }, 300);
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            "recoveryInitiatedSuccessfully"
          );
        },
        onError: (error: any) => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      }
    );
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={cancel}
      PaperProps={{
        sx: {
          p: 0,
          width: "50%",
          maxwidth: "1000px",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <DialogTitle sx={{ pb: 1, pt: 4 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack>
            <Stack direction="row" alignItems="center">
              <Typography variant="h6" mr={2}>
                {title}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row">
            <Box>
              <IconButton
                size="medium"
                aria-label="close drawer"
                onClick={cancel}
                sx={{
                  zIndex: 2,
                }}
              >
                <Tooltip title="Close Drawer">
                  <CloseIcon fontSize="medium" />
                </Tooltip>
              </IconButton>
            </Box>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Stack direction={"row"} alignItems={"center"} spacing={4} my={4}>
          <Typography variant="subtitle1">
            {window.getCTTranslatedText("selectRecoveryMethod")}
          </Typography>
          <RadioGroup
            row
            aria-labelledby="select-source-destination-label"
            name="path-direction-radio-buttons-group"
            defaultValue={RecoveryModes.Online}
            value={recoveryMode}
            onChange={(event, value) => setRecoveryMode(value as RecoveryModes)}
          >
            <FormControlLabel
              value={RecoveryModes.Online}
              control={<Radio size="small" />}
              label={window.getCTTranslatedText("online")}
            />
            <FormControlLabel
              value={RecoveryModes.Offline}
              control={<Radio size="small" />}
              label={window.getCTTranslatedText("offlineUsingUSB")}
            />
          </RadioGroup>
        </Stack>
        {recoveryMode === RecoveryModes.Online ? (
          <OnlineRecovery
            appliance={appliance}
            selectedAppliance={selectedAppliance}
            setSelectedAppliance={setSelectedAppliance}
            initalActiveAppliances={initalActiveAppliances}
          />
        ) : (
          <OfflineRecovery appliance={appliance} />
        )}
      </DialogContent>

      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <ToolbarAction
          loading={restoreApplianceAPI.isLoading}
          save={restoreAppliance}
          cancel={() => {
            updateApplianceData?.();
            cancel?.();
          }}
          isValid={Boolean(selectedAppliance?.agentId)}
          secondaryBtnText={window.getCTTranslatedText(
            recoveryMode === RecoveryModes.Online ? "Cancel" : "Done"
          )}
          hasPermission={Boolean(recoveryMode === RecoveryModes.Online)}
          actionBtnText={
            btnTitle
              ? window.getCTTranslatedText(btnTitle)
              : window.getCTTranslatedText("Recover")
          }
        />
      </DialogActions>
    </Drawer>
  );
}
