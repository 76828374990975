import { Stack, Tooltip } from "@mui/material";
import { UserGroupStatusName } from "pages/users-segmentation/components/user-groups/types";
import { User } from "../../types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export interface UserColumnRenderProps {
  readonly user: User;
  readonly viewOnly?: boolean;
}

export function UserStatus({ user, viewOnly }: UserColumnRenderProps) {
  const typeFacetName = "active";
  const typeOptionName = user?.active?.toString();

  let text = "";

  if (user?.active) {
    text = UserGroupStatusName.ACTIVE;
  } else {
    text = UserGroupStatusName.INACTIVE;
  }

  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "space-evenly",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Stack direction={"row"}>
        <Tooltip title={`${window.getCTTranslatedText(text)}`}>
          <Stack>
            <CTDatagridButton
              asText={viewOnly}
              isDisabled={viewOnly}
              facetName={typeFacetName}
              facetOption={typeOptionName}
            >
              {window.getCTTranslatedText(text)}
            </CTDatagridButton>
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
