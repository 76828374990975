import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { IconButton, Stack, debounce } from "@mui/material";
import { useCommonStore } from "common/store";
import { AnalyticsResponse } from "common/types/types";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { DashboardWidgetDialog } from "pages/dashboard/components/dashboard-widget-dialog";
import { SecurityProgressPredictions } from "pages/dashboard/components/security-progress-predictions";
import {
  barChartRequestBody,
  getChartDates,
} from "pages/dashboard/components/security-progress/helpers";
import { useTimeSeriesAPI } from "pages/dashboard/components/security-progress/hooks";
import { useEffect, useMemo, useState } from "react";

export const RiskTrendChartDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

  const riskTrendMutation = useTimeSeriesAPI();

  const riskTrendMutationObj = useMemo(
    () => debounce(riskTrendMutation.mutate, 500),
    [riskTrendMutation.mutate]
  );

  const [predictionsData, setPredictionsData] = useState<
    AnalyticsResponse | undefined
  >(undefined);

  useEffect(() => {
    if (open) {
      let criteria = searchCriteria ?? "*";

      const date = getChartDates();

      const requestObj = barChartRequestBody({
        criteria,
        scope: Scope.Asset,
        groupBy: [],
        statistics: ["predict(avg(metricassetriskscore),30)"],
        timeStart: date.timeStart,
        timeEnd: date.timeEnd,
      });

      riskTrendMutationObj(requestObj, {
        onSuccess(data) {
          if (data) {
            setPredictionsData(data);
          }
        },
        onError: error => {
          setSnackbar(
            true,
            SnackBarSeverity.Error,
            "FailedToFetchBreachImpactTrendPrediction"
          );
        },
      });
    }
  }, [riskTrendMutationObj, searchCriteria, setSnackbar, open]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <IconButton aria-label="trend" onClick={handleClick} size="medium">
        <TrendingUpIcon />
      </IconButton>
      <DashboardWidgetDialog
        title={`${window.getCTTranslatedText("BreachImpactTrendPrediction")}`}
        isLoading={riskTrendMutation?.isLoading}
        children={
          riskTrendMutation?.isLoading || !predictionsData ? null : (
            <SecurityProgressPredictions
              predictions={{
                predictionData: predictionsData,
                height: 400,
              }}
            />
          )
        }
        open={open}
        handleClose={handleClose}
      />
    </Stack>
  );
};
