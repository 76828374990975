import { useQueryClient } from "@tanstack/react-query";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useTagStore } from "pages/tags/store";
import { TagItemProps } from "pages/tags/types";
import { useDeleteTagAPI } from "../../tag-item/hooks";

export const useDeleteTag = ({ tag }: TagItemProps) => {
  const notify = useEventSubscriptionStore(state => state.notify);
  const setCurrentTag = useTagStore(state => state.setCurrentTag);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const requestAPIRefresh = useTagStore(state => state.requestAPIRefresh);
  const deleteTagAPI = useDeleteTagAPI();

  const queryClient = useQueryClient();

  const deleteCustomTag = () => {
    if (!tag?.isCoreTag && tag?.displayName) {
      deleteTagAPI.mutate(
        {
          displayName: tag?.displayName,
        },
        {
          onSuccess: response => {
            setCurrentTag(undefined);
            queryClient.invalidateQueries({
              queryKey: [`${Scope.Asset}Metadata`],
            });
            requestAPIRefresh();
            notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
              label: "DeleteTagRequestSubmittedSuccessfully",
            });
          },
          onError: error => {
            setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
          },
        }
      );
    }
  };

  return {
    deleteTag: deleteCustomTag,
    isLoading: deleteTagAPI.isLoading,
  };
};
