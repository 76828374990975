import { Stack, Typography } from "@mui/material";
import { CustomCommonStoreType } from "common/types/types";

import { CoreResponse } from "modules/core/types";
import { FacetControllerGroup } from "modules/facets";
import { SavedQueryList } from "modules/saved-query/components/saved-query-list";
import { Scope } from "modules/scope-metadata/types";
import { AddCriteria } from "pages/create-tag-rule/components/add-criteria";
import { AddCriteriaProps } from "pages/create-tag-rule/components/add-criteria/AddCriteria";
import { DisplayAppliedRule } from "pages/create-tag-rule/components/display-applied-rule";

export function PolicySelectionForm({
  coreResponse,
  useFacetStore,
  sortDisplayByName,
  viewOnly,
  header,
  addCriteriaProps,
  hideSavedQuery,
}: {
  header?: React.ReactElement;
  coreResponse: CoreResponse<Scope.TagPolicy>;
  useFacetStore: CustomCommonStoreType;
  sortDisplayByName?: boolean;
  viewOnly?: boolean;
  addCriteriaProps?: Partial<AddCriteriaProps>;
  hideSavedQuery?: boolean;
}) {
  return (
    <>
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          sx={{
            width: "100%",
          }}
        >
          {header ?? (
            <Stack alignItems="flex-start">
              <Typography variant="subtitle1">
                {window.getCTTranslatedText("Policy Criteria")} *
              </Typography>
            </Stack>
          )}

          <Stack alignItems="center" direction="row" spacing={2}>
            <AddCriteria
              {...addCriteriaProps}
              useFacetStore={useFacetStore}
              facetsOpen={coreResponse.facetsOpen}
              setFacetsOpen={coreResponse.setFacetsOpen}
              dialogTitle="Create Policy Criteria"
              btnTitle="Add policy criteria"
              viewOnly={viewOnly}
            >
              <FacetControllerGroup
                useFacetStore={useFacetStore}
                config={coreResponse.facetConfig}
                value={coreResponse.facetState}
                onChange={coreResponse.updateFacet}
                isSelection={true}
              />
            </AddCriteria>
            {!viewOnly && hideSavedQuery !== true && (
              <SavedQueryList
                useFacetStore={useFacetStore}
                showButtonTrigger={true}
                disableSearch={true}
              />
            )}
          </Stack>
        </Stack>
        <DisplayAppliedRule
          useFacetStore={useFacetStore}
          minRequired={1}
          sortByName={sortDisplayByName}
          viewOnly={viewOnly}
        />
      </Stack>
    </>
  );
}
