import { FacetOption } from "modules/facets/types";
import { PieChartDatum } from "@colortokens/ng-ui";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";

interface Props {
  facet: { [key: string]: number } | undefined;
  labelMapper: { [key: string]: string | undefined };
  possibleValues?: string[];
}

export function facetMetricsToChartData(props: Props): PieChartDatum[] {
  const { facet, labelMapper, possibleValues = [] } = props;

  const optionMapper = (option: FacetOption) => ({
    name: option.name,
    label: labelMapper[option.label] ?? capitalizeFirstLetter(option.label),
    count: option.count,
  });

  if (facet) {
    let results: PieChartDatum[] = [];

    (possibleValues ?? []).forEach(name => {
      const option = optionMapper({
        name: name,
        label: name,
        count: facet[name] ?? 0,
      });

      if (option) {
        results.push(option);
      }
    });
    return results;
  } else {
    return [];
  }
}
