import { PORTLESS_PROTOCOLS, Path, PathDirection } from "pages/paths/types";
import { Port, PortStatus } from "pages/ports/types";
import { PortList } from "pages/templates/components/template-form-drawer/components/template-form/types";
import {
  TemplatePath,
  TemplatePort,
  TemplateType,
} from "pages/templates/types";

export const shouldSuggestRestrictPortToAllowedPathsForPorts = (
  port: Port,
  templateType?: TemplateType,
  pathList?: Array<TemplatePath>,
) => {
  if (
    !port ||
    templateType === TemplateType.BlockTemplate ||
    port.listenPort.toLowerCase() === PortList.ANY ||
    PORTLESS_PROTOCOLS.includes(port?.listenPortProtocol.toUpperCase()) ||
    port?.listenPortReviewed === PortStatus.PathRestricted
  ) {
    return false;
  }

  if (
    port &&
    pathList?.find?.(
      (path: TemplatePath) =>
        `${port?.listenPort} ${port?.listenPortProtocol}` ===
        `${path?.port} ${path?.protocol}`,
    )
  ) {
    return true;
  }

  return false;
};

export const shouldSuggestRestrictPortToAllowedPathsForPaths = (
  path: Path,
  templateType?: TemplateType,
  portList?: Array<TemplatePort>,
) => {
  if (
    templateType === TemplateType.BlockTemplate ||
    path.port.toLowerCase() === PortList.ANY ||
    PORTLESS_PROTOCOLS.includes(path?.protocol?.toUpperCase()) ||
    path?.direction !== PathDirection.Inbound
  ) {
    return false;
  }

  if (
    portList?.every?.(
      (port: TemplatePort) =>
        `${port?.listenPort} ${port?.listenPortProtocol}` !==
        `${path?.port} ${path?.protocol}`,
    )
  ) {
    return true;
  }

  if (
    portList?.find?.(
      (port: TemplatePort) =>
        `${port?.listenPort} ${port?.listenPortProtocol}` ===
          `${path?.port} ${path?.protocol}` &&
        port?.listenPortReviewed !== PortStatus.PathRestricted,
    )
  ) {
    return true;
  }

  return false;
};
