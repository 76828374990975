import {
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

export interface DashboardWidgetProps {
  title: string;
  actions?: React.ReactElement | null;
  children: React.ReactElement | null;
  isLoading?: boolean;
  height?: string | number;
}

const overflowStyles = {
  width: "fitContent",
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const CTWidget = ({
  title,
  isLoading,
  children,
  actions,
  height = "100%",
}: DashboardWidgetProps) => {
  return (
    <Paper
      elevation={1}
      sx={{
        width: "100%",
        height: height,
        borderRadius: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        alignContent={"center"}
        justifyContent="space-between"
        px={3}
        py={actions ? 2.2 : 3}
        sx={{ width: "100%" }}
      >
        <Typography variant="subtitle1" noWrap sx={overflowStyles}>
          {title}
        </Typography>
        <Stack spacing={2} direction="row" alignItems="center">
          {actions ?? null}
        </Stack>
      </Stack>
      <Divider />
      <Stack
        alignItems="center"
        justifyItems="center"
        p={3}
        sx={{ height: height, width: "100%" }}
      >
        {isLoading ? <CircularProgress /> : children}
      </Stack>
    </Paper>
  );
};
