import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { IconButton, Link } from "@mui/material";
import { API_URL } from "hooks/useNetworking/constants";
import { ReportColumnRenderProps } from "pages/reports/types";

export const ReportActions = ({ report }: ReportColumnRenderProps) => {
  return (
    <Link
      underline="none"
      component={"a"}
      href={`${API_URL}${report.downloadLink}`}
    >
      <IconButton size="medium">
        <SaveAltIcon
          fontSize="small"
          sx={{
            color: theme => theme.palette.primary.main,
          }}
        />
      </IconButton>
    </Link>
  );
};
