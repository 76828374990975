import { Box, Stack, Typography } from "@mui/material";

import numeral from "numeral";
import { PortColumnRenderProps } from "pages/ports/types";
import pluralize from "pluralize";

export function PortConnectionCount({ port }: PortColumnRenderProps) {
  if (!port.lpId) {
    return <Box style={{ width: 110 }} />;
  }

  return (
    <Stack alignItems="left" justifyContent="center">
      <Typography variant="body2">
        {numeral(port?.connectionCount ?? 0).format("0a")}{" "}
        {window.getCTTranslatedText(
          pluralize("connection", port?.connectionCount)
        )}
      </Typography>
    </Stack>
  );
}
