export enum RESOURCE_CARDS_NAMES {
  "Users" = "Users",
  "Groups" = "User Groups",
  "Departments" = "Departments",
}

export const RESOURCE_CARDS: { [key: string]: string } = {
  [RESOURCE_CARDS_NAMES.Users]: "Users",
  [RESOURCE_CARDS_NAMES.Groups]: "Groups",
  [RESOURCE_CARDS_NAMES.Departments]: "Departments",
};
