import { Grid } from "@mui/material";
import { CTMultiSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-multi-select/CTMultiSelectDropDown";
import {
  FilterFormInt,
  FilterFormKeys,
  FilterFormKeysDisplayText,
  FilterFormProps,
} from "./types";

export const FilterForm = ({
  filter,
  updateFilterData,
  filterData,
  alertRules = [],
  showLabel = true,
}: FilterFormProps) => {
  const handleUpdate = <T extends string | string[]>({
    field,
    value,
  }: {
    field: FilterFormKeys;
    value: T;
  }) => {
    if (field) {
      setFieldValue<T>({
        data: filter,
        key: field,
        value,
        updateData: updateFilterData,
      });
    }
  };

  return (
    <Grid container columnSpacing={0} sx={{ width: "100%" }}>
      <Grid item xs>
        <CTMultiSelectDropDown
          field={FilterFormKeys.AlertRuleName}
          displayName={FilterFormKeysDisplayText[FilterFormKeys.AlertRuleName]}
          multi={true}
          selectedValue={alertRules}
          handleUpdate={handleUpdate}
          data={filterData}
          required={false}
          textTransform="none"
          helperText={"AlertRuleHelperText"}
        />
      </Grid>
    </Grid>
  );
};

interface FieldValueProps<T> {
  data: FilterFormInt;
  key: FilterFormKeys;
  value: T;
  updateData: Function;
}

function setFieldValue<T extends string | string[]>({
  data,
  key,
  value,
  updateData,
}: FieldValueProps<T>) {
  if (value !== data[key]) {
    let newDataObj: FilterFormInt = { ...data };
    if (key === FilterFormKeys.AlertRuleName) {
      newDataObj[key] = Array.isArray(value) ? value : [value];
    } else {
      newDataObj[key] = Array.isArray(value) ? value.join(", ") : value;
    }
    updateData(newDataObj);
  }
}
