import { FacetOptionUnit } from "modules/scope-metadata/types";
import { convertStringRangeToReadableBytes } from "./convertStringRangeToReadableBytes";
import { convertStringRangeToReadableDates } from "./convertStringRangeToReadableDates";

export const getOptionLabel = (key: string, unit?: FacetOptionUnit | null) => {
  switch (unit) {
    case FacetOptionUnit.Hour:
      return convertStringRangeToReadableDates(key, unit);
    case FacetOptionUnit.Minute:
      return convertStringRangeToReadableDates(key, unit);
    case FacetOptionUnit.Byte:
      return convertStringRangeToReadableBytes(key, unit);
  }

  return key;
};
