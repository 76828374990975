import {
  Stack,
  MenuItem,
  Select,
  FormControl,
  Typography,
  useTheme,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import { useState } from "react";
import { renderIcons } from "../ApplianceInstall";
import { InstallationLinks, platforms } from "../../../constants";
import { architectureType } from "pages/agents/components/agent-download-page/constants";
import { PlatformData } from "pages/agents/components/agent-download-page/type";
import { UserInfoType } from "modules/analytics/KnowledgeBase";

export interface VersionInfo {
  version: string;
  downloadLink: string;
  architecture: string;
  fileName: string;
  agentType: string;
  targetPlatform: string;
  recommended: boolean;
}

export interface AgentVersion {
  platform: string;
  platformData: PlatformData;
  agentGatwayVersion: VersionInfo[];
  knowledgeBaseHmac: UserInfoType | null;
}

function ProccessPlatform(platform: string) {
  switch (platform) {
    case platforms.ISO:
      return `ISO (.iso)`;
    case platforms.OVA:
      return `OVA (.ova)`;
    case platforms.DEBIAN:
      return `Debian (.deb)`;
  }
}

export function DownloadItem(props: Readonly<AgentVersion>) {
  const theme = useTheme();
  const architecture = architectureType.X86_64;
  const [agentVersion, setAgentVersion] = useState(() => {
    const latestVersionArr = props?.agentGatwayVersion.filter(
      arch => architecture === arch.architecture && arch.recommended
    );

    return latestVersionArr.length > 0 ? latestVersionArr[0]?.version : "";
  });

  const handleChange = (event: SelectChangeEvent) => {
    setAgentVersion(event?.target?.value);
  };

  const onDownloadClick = () => {
    let selectedAgent = props?.agentGatwayVersion.find(agent => {
      return agent.version === agentVersion;
    });
    if (selectedAgent?.downloadLink) {
      window.open(selectedAgent.downloadLink, "_blank", "noopener, noreferrer");
    }
  };

  const onInstallInstructionsClick = () => {
    let knowledgeBaseParams = `?hmac=${encodeURIComponent(
      JSON.stringify(props.knowledgeBaseHmac)
    )}`;
    let docLink = `${
      InstallationLinks[props.platform as keyof typeof InstallationLinks]
    }${knowledgeBaseParams}`;
    window.open(docLink, "_blank", "noopener, noreferrer");
  };

  return (
    <Stack
      sx={{
        p: 5,
        width: "100%",
        background: theme =>
          theme.palette.mode === "dark"
            ? theme.palette.divider
            : theme.palette.background.paper,
      }}
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      boxShadow={2}
      borderRadius={1}
    >
      <Stack direction="row" spacing={1} minWidth={180}>
        <Stack justifyContent={"center"}>
          {renderIcons(props.platform, theme.palette.text.primary)}
        </Stack>
        <Stack justifyContent={"center"}>
          <Typography variant="subtitle1">
            {ProccessPlatform(props.platform)}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <FormControl>
          <Select
            displayEmpty
            sx={{
              display: "flex",
              textAlign: "left",
              fontSize: "small",
              minWidth: 200,
            }}
            value={agentVersion}
            inputProps={{ "aria-label": "Without label" }}
            onChange={handleChange}
          >
            {props.platformData?.versionsInfo?.map((vName: VersionInfo) => (
              <MenuItem
                sx={{
                  display: "flex",
                  backgroundColor: "initial",
                  fontSize: "small",
                  justifyContent: "flex-start",
                }}
                key={vName?.version}
                value={vName?.version}
              >
                <Typography variant="body2" sx={{ pointerEvents: "all" }}>
                  {vName?.version}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => onInstallInstructionsClick()}
        >
          {window.getCTTranslatedText("installInstructions")}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => onDownloadClick()}
        >
          {window.getCTTranslatedText("download")}
        </Button>
      </Stack>
    </Stack>
  );
}
