import // AzureIcon,
"assets/svgs";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { useEffect, useMemo } from "react";
import { Dimension } from "../../../modules/hierarchy-vis/types";
import {
  VisualizerLoaderProps,
  useVisualizerData,
} from "../hooks/useVisualizerData";
import { useVisxStore } from "../store";
import { ExpansionNode, GroupNodeDataType } from "../types";
import {
  createParentCriteriaJoins,
  getCriteriaForNode,
  isSubnet,
  joinCriterias,
  withHub,
  withoutHub,
} from "../visx-utils";
import { HUB_PARENT } from "../hooks/useTrafficData";

interface DataMergeControllerProps {
  selectedDimension: Dimension;
  mainDimension?: Dimension;
  loaderProps: Omit<
    VisualizerLoaderProps,
    "selectedSourceDimension" | "selectedDestinationDimension" | "tag"
  >;
  selectedDirection: Direction;
  nodeData: GroupNodeDataType;
}

export function DataMergerController({
  mainDimension,
  loaderProps,
  selectedDirection,
  selectedDimension,
  nodeData,
}: DataMergeControllerProps) {
  const expansions = useVisxStore(state => state.expansions);

  const originalTrafficData = useVisxStore(state => state.trafficData);

  const { filtered: expandedLeaves } = useMemo(() => {
    if (!expansions) {
      return { filtered: [] };
    }

    let filtered = expansions.filter(e => {
      if (
        e.dimension.name === selectedDimension.name &&
        e.parent.name === nodeData.label
      ) {
        return false;
      }
      if (
        e.parent.name.startsWith(HUB_PARENT.label) &&
        !originalTrafficData?.[e.parent.name]
      ) {
        // we are still loading data, wait for that
        return false;
      }
      return true;
    });

    return { filtered };
  }, [expansions, originalTrafficData, selectedDimension, nodeData.label]);

  return (
    <>
      {expandedLeaves.map(e => {
        let key = `${nodeData.label}-${e.dimension.name}-${e.parent.name}`;

        return (
          <DataMerger
            key={key}
            id={key}
            mainDimension={mainDimension}
            expansion={e}
            selectedDirection={selectedDirection}
            loaderProps={loaderProps}
            selectedDimension={selectedDimension}
            nodeData={nodeData}
          />
        );
      })}
    </>
  );
}

interface DataMergerProps extends Omit<DataMergeControllerProps, ""> {
  id: React.Key;
  expansion: ExpansionNode;
}

function DataMerger({
  id,
  expansion,
  selectedDirection,
  loaderProps,
  selectedDimension,
  nodeData,
}: DataMergerProps) {
  let criteria = getCriteriaForNode(nodeData.id, nodeData.dimension);

  let parentJoins = createParentCriteriaJoins(nodeData.trafficData);
  if (parentJoins.length) {
    criteria = joinCriterias([criteria, ...parentJoins]);
  }

  const destinationCriteria = useVisxStore(state => state.destinationCriteria);
  const sourceCriteria = useVisxStore(state => state.sourceCriteria);

  const hubCriteria =
    selectedDirection === Direction.Inbound
      ? destinationCriteria
      : sourceCriteria;

  const originalTrafficData = useVisxStore(state => state.trafficData);

  const expandedGroupCriteria = useMemo(() => {
    let e: ExpansionNode | null = expansion;
    let expansionCriteria: string[] = [];

    while (e != null) {
      let eCriteria = getCriteriaForNode(e.parent.name, e.parent.dimension);

      expansionCriteria.push(eCriteria);
      if (originalTrafficData?.[e.parent.name]?.parent) {
        e = {
          dimension:
            originalTrafficData?.[e.parent.name].parent?.sourceDimension!,
          parent: {
            dimension:
              originalTrafficData?.[e.parent.name].parent?.sourceDimension!,
            name: originalTrafficData?.[e.parent.name].parent?.name!,
          },
        };
      } else {
        e = null;
      }
    }
    return joinCriterias(expansionCriteria);
  }, [expansion, originalTrafficData]);

  criteria = withHub(criteria, hubCriteria);

  if (selectedDirection === Direction.Outbound) {
    loaderProps.sourceCriteria = criteria;
    if (originalTrafficData?.[expansion.parent.name]) {
      loaderProps.destinationCriteria = withHub(
        expandedGroupCriteria,
        hubCriteria
      );
    } else if (
      expansion.dimension.name === "namednetworkname" ||
      isSubnet(expansion.dimension.name)
    ) {
      loaderProps.destinationCriteria =
        "namednetworkname = NULL AND assetid=NULL";
    } else {
      loaderProps.destinationCriteria = withoutHub(
        expandedGroupCriteria,
        hubCriteria
      );
    }
  } else {
    loaderProps.destinationCriteria = criteria;
    if (originalTrafficData?.[expansion.parent.name]) {
      loaderProps.sourceCriteria = withHub(expandedGroupCriteria, hubCriteria);
    } else if (
      expansion.dimension.name === "namednetworkname" ||
      isSubnet(expansion.dimension.name)
    ) {
      loaderProps.sourceCriteria = "namednetworkname = NULL AND assetid=NULL";
    } else {
      loaderProps.sourceCriteria = withoutHub(
        expandedGroupCriteria,
        hubCriteria
      );
    }
  }

  const nestedLoading: VisualizerLoaderProps = {
    ...loaderProps,
    selectedDestinationDimension:
      selectedDirection === Direction.Inbound
        ? selectedDimension
        : expansion.dimension,
    selectedSourceDimension:
      selectedDirection === Direction.Inbound
        ? expansion.dimension
        : selectedDimension,
    tag: `nested-${id}`,
    topParent: nodeData,
    leafParent: { label: expansion.parent.name },
  };

  const { isLoading, trafficData } = useVisualizerData(nestedLoading);

  const addTrafficData = useVisxStore(state => state.addTrafficData);

  useEffect(() => {
    if (isLoading || !trafficData) {
      return;
    }

    console.log("Nested loading finished", {
      data: trafficData,
    });

    const parent = nodeData.label;
    addTrafficData(trafficData, parent, false, true);
  }, [
    trafficData,
    isLoading,
    nodeData.label,
    addTrafficData,
    expansion.parent.name,
    expansion.parent.dimension,
  ]);

  return null;
}
