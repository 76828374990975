import { makeStyles } from "@material-ui/core/styles";
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  FormControl,
  TextField,
} from "@mui/material";
import { Suggestion } from "modules/ct-scope-auto-suggest/types";
import { Scope } from "modules/scope-metadata/types";
import { SyntheticEvent, useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  input: {
    "&::placeholder": {
      fontSize: "14px",
    },
  },
}));

export enum AutosuggestTypes {
  Default = "default",
  Select = "select",
}

type AutosuggestType = AutosuggestTypes.Default | AutosuggestTypes.Select;

interface CTAutoSuggestProps {
  key?: string;
  displayName: string;
  selectedValue?: string | Suggestion;
  handleUpdate: ({ value }: { value: Suggestion | string | null }) => void;
  scope?: Scope;
  required?: boolean;
  showLabel?: boolean;
  list: Suggestion[];
  onSearchTextChange: (text: string) => void;
  type?: AutosuggestType;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
}

export function CTAutoSuggest({
  key,
  displayName,
  selectedValue,
  handleUpdate,
  required = false,
  showLabel = true,
  list,
  onSearchTextChange,
  helperText = "",
  error = false,
  type = AutosuggestTypes.Default,
  disabled,
}: CTAutoSuggestProps) {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setSearchText(old => {
      if (old || !selectedValue) {
        return old;
      }

      if ((selectedValue as Suggestion)?.displayName) {
        return (selectedValue as Suggestion)?.displayName ?? "";
      } else if ((selectedValue as string)?.length) {
        return selectedValue as string;
      }

      let suggestionFromList = list.find(
        v =>
          v.value === (selectedValue as Suggestion)?.value ||
          v.value === selectedValue
      );

      return suggestionFromList?.displayName ?? "";
    });
  }, [selectedValue, list]);

  const onChange = (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string | Suggestion | null
  ) => {
    const value = newInputValue;
    if (newInputValue !== undefined) {
      handleUpdate({ value: value });
    }
  };

  const handleTextFieldChange = (
    event: any,
    newInputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === "reset") {
      if (newInputValue.trim() !== "") {
        setSearchText(newInputValue.trim());
        onSearchTextChange(newInputValue?.trim());
      }
      return;
    }
    if (newInputValue !== undefined) {
      const value = newInputValue;
      if (value?.trim() !== searchText) {
        onSearchTextChange(value?.trim());
      }
      setSearchText(value);
      if (type === AutosuggestTypes.Default) {
        handleUpdate({ value: value?.trim() });
      }
    }
  };

  const inputClasses = useStyles();

  return (
    <FormControl sx={{ m: 0, width: "100%" }} size="small">
      <Autocomplete
        freeSolo={type === AutosuggestTypes.Default}
        filterOptions={x => x}
        id={`autocomplete-${key}`}
        openOnFocus
        autoHighlight
        options={list.map(option => option)}
        groupBy={option => option._group ?? ""}
        getOptionLabel={(option: any) => option?.displayName ?? ""}
        disabled={disabled}
        value={selectedValue ?? ""}
        inputValue={searchText}
        onInputChange={handleTextFieldChange}
        onChange={onChange}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => {
          return (
            option === value ||
            ((option as Suggestion)?.value === (value as Suggestion)?.value &&
              (option as Suggestion)?.type === (value as Suggestion)?.type)
          );
        }}
        renderInput={params => (
          <TextField
            variant="standard"
            {...params}
            label={showLabel ? window.getCTTranslatedText(displayName) : null}
            placeholder={`${window.getCTTranslatedText(
              type === AutosuggestTypes.Default ? "Enter" : "Select"
            )} ${window.getCTTranslatedText(displayName)}`}
            InputLabelProps={{ shrink: true, required: required }}
            helperText={window.getCTTranslatedText(helperText)}
            error={error}
            InputProps={{
              ...params.InputProps,
              classes: { input: inputClasses.input },
            }}
          />
        )}
      />
    </FormControl>
  );
}
