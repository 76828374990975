import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { getLocalDate } from "common/utils/getDate";
import { ReportActions } from "../report-action";
import { ReportName } from "../report-name";
import { ReportCreatedBy } from "../report-created-by";

export const REPORTS_COLUMNS: GridColDef[] = [
  {
    field: "historyName",
    headerName: "Report Name",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <ReportName report={params.row} />
    ),
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <ReportCreatedBy report={params.row} />
    ),
  },
  {
    field: "createdAt",
    headerName: "Created On",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return getLocalDate(params.row.createdAt);
    },
  },
  {
    field: "action",
    headerName: "",
    width: 60,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <ReportActions report={params.row} />;
    },
  },
];

const ColumnVisibilityModel: { [key: string]: boolean } = {
  // Hide columns from the display
};

const PinnedColumns = {
  left: [],
  right: ["action"],
};

export const ReportsColumnConfig = {
  ColumnVisibilityModel: ColumnVisibilityModel,
  PinnedColumns: PinnedColumns,
};
