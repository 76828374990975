const excludeWords: { [key: string]: string } = {
  OS: "OS",
  "CVE ID": "CVE ID",
  "CVE-ID": "CVE-ID",
};

export const capitalizeFirstLetter = (string: string) => {
  if (excludeWords[string]) {
    return string;
  }

  return string?.length
    ? string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
    : string;
};
