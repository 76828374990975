import { Scope } from "modules/scope-metadata/types";
import { useEffect, useMemo, useState } from "react";
import {
  WithGroupByResponse,
  useAggregateAPI,
} from "../../../hooks/useAggregateAPI";
import { debounce } from "@mui/material";
import numeral from "numeral";
import { StatContainer } from "common/types/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { parseErrorMessage } from "common/utils";
import { TagValueInt } from "pages/tags/types";
import { useTagStore } from "pages/tags/store";

const format = "0,0";

export function useTagValueList() {
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const currentTag = useTagStore(state => state.currentTag);

  const [tagValueList, setTagValueList] = useState<
    Array<TagValueInt> | undefined
  >(undefined);

  const tagValueListMutation = useAggregateAPI();

  const tagValueListMutationObj = useMemo(
    () => debounce(tagValueListMutation.mutate, 500),
    [tagValueListMutation.mutate]
  );

  useEffect(() => {
    if (!currentTag) {
      return;
    }
    const body = {
      criteria: "*",
      scope: Scope.Asset,
      groupBy: [currentTag],
      statistics: ["count(assetid)"],
    };

    const buildTagValueList = (items: { [key: string]: StatContainer }) => {
      let list: any = [];
      const valueKeys = Object.keys(items ?? {}) ?? [];

      valueKeys.forEach((value: string) => {
        const tagObj: TagValueInt = {
          key: currentTag,
          value: value,
          displayName: value,
          count: numeral(items[value]?.statistics[`assetidcount`]).format(
            format
          ),
        };
        if (value !== "<nil>") {
          list.push(tagObj);
        }
      });

      return list;
    };

    tagValueListMutationObj(body, {
      onSuccess(data) {
        if (data) {
          const responseData = data as WithGroupByResponse;
          const items = responseData?.items ?? {};
          const tagValueList = buildTagValueList(items);
          setTagValueList(tagValueList);
        }
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  }, [currentTag, setTagValueList, tagValueListMutationObj, setSnackbar]);

  return {
    tagValueList,
    isLoading: tagValueListMutation.isLoading,
  };
}
