import { TagKeyInt } from "pages/tags/types";
import React from "react";
import { TagItem } from "../tag-item";
import { List, Stack } from "@mui/material";
import { useTagList } from "./hooks";
import { CircularCenteredLoader } from "common/atoms/loader";
import { CreateTag } from "../create-tag";
import { MAX_CUSTOM_TAGS_COUNT } from "pages/tags/constants";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";

export const TagList = () => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const { isLoading, tagList, createdCustomTagsCount } = useTagList();

  if (isLoading || !tagList) {
    return (
      <Stack
        direction={"row"}
        alignItems="flex-start"
        spacing={3}
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <CircularCenteredLoader />
      </Stack>
    );
  }

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          height: "100%",
        }}
        spacing={2}
      >
        {userPermissions.has("CREATE_FIELD") &&
          createdCustomTagsCount < MAX_CUSTOM_TAGS_COUNT && <CreateTag />}
        <List dense={false} disablePadding>
          {(tagList ?? [])?.map((tag: TagKeyInt) => {
            return (
              <React.Fragment key={`${tag.key}`}>
                <TagItem tag={tag} />
              </React.Fragment>
            );
          })}
        </List>
      </Stack>
    </>
  );
};
