import { IconsProps } from "./common";

export const OVAFileIcon = ({
  width = "20px",
  height = "20px",
  color = "#000",
}: IconsProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 32"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5293_75813)">
        <path
          d="M23.9663 8.00039C23.933 7.86706 23.8997 7.76706 23.833 7.66706L17.333 0.400391V8.00039H23.9663Z"
          fill={color}
          fillOpacity="0.87"
        />
        <path
          d="M18.8336 25.5342C18.8336 25.0009 18.4003 24.5676 17.8669 24.5676H5.90026C5.36693 24.5676 4.93359 25.0009 4.93359 25.5342V26.9009C4.93359 27.4342 5.36693 27.8676 5.90026 27.8676H17.8669C18.4003 27.8676 18.8336 27.4342 18.8336 26.9009V25.5342ZM13.6669 26.8009C13.3669 26.8009 13.1003 26.5676 13.1003 26.2342C13.1003 25.9009 13.3669 25.6676 13.6669 25.6676C13.9669 25.6676 14.2336 25.9009 14.2336 26.2342C14.2336 26.5676 14.0003 26.8009 13.6669 26.8009ZM15.3669 26.8009C15.0669 26.8009 14.8003 26.5676 14.8003 26.2342C14.8003 25.9009 15.0336 25.6676 15.3669 25.6676C15.6669 25.6676 15.9336 25.9009 15.9336 26.2342C15.9336 26.5676 15.6669 26.8009 15.3669 26.8009ZM17.0336 26.8009C16.7336 26.8009 16.4669 26.5676 16.4669 26.2342C16.4669 25.9009 16.7003 25.6676 17.0336 25.6676C17.3336 25.6676 17.6003 25.9009 17.6003 26.2342C17.6003 26.5676 17.3336 26.8009 17.0336 26.8009ZM5.90026 24.0009H17.8669C18.1336 24.0009 18.4003 24.0676 18.6003 24.2009L17.4336 21.7676C17.1003 21.0676 16.5669 20.8009 16.0336 20.8009H14.7336L12.8669 22.7342C12.7336 22.8676 12.5669 22.9676 12.4003 23.0342C12.2336 23.1009 12.0336 23.1342 11.8669 23.1342C11.6669 23.1342 11.5003 23.1009 11.3336 23.0342H11.2669C11.1336 22.9676 11.0003 22.8676 10.8669 22.7676L9.00026 20.7676H7.70026C7.16693 20.7676 6.66693 21.0676 6.30026 21.7342L5.13359 24.1676C5.36693 24.0676 5.60026 24.0009 5.90026 24.0009Z"
          fill={color}
          fillOpacity="0.87"
        />
        <path
          d="M14.6663 19.8672C14.733 19.8005 14.7663 19.7339 14.833 19.6339C14.8663 19.5339 14.8997 19.4672 14.8997 19.3339C14.8997 19.2339 14.8663 19.1339 14.833 19.0672C14.7997 18.9672 14.733 18.9005 14.6663 18.8339C14.5997 18.7672 14.4997 18.7005 14.433 18.6672C14.3663 18.6339 14.2663 18.6005 14.1663 18.6005C14.0663 18.6005 13.9663 18.6339 13.8997 18.6672C13.7997 18.7005 13.733 18.7672 13.6663 18.8339L12.5997 19.9005V16.5672C12.5997 16.4672 12.5663 16.3672 12.533 16.3005C12.4997 16.2005 12.433 16.1339 12.3663 16.0672C12.2997 16.0005 12.233 15.9339 12.133 15.9005C12.0663 15.8672 11.9663 15.8672 11.8663 15.8672C11.7663 15.8672 11.6663 15.8672 11.5997 15.9005C11.4997 15.9339 11.433 16.0005 11.3663 16.0672C11.2997 16.1339 11.233 16.2005 11.1997 16.3005C11.1663 16.4005 11.133 16.4672 11.133 16.5672V19.9339L10.0663 18.8672C9.99967 18.8005 9.93301 18.7339 9.83301 18.7005C9.76634 18.6672 9.66634 18.6339 9.56634 18.6339C9.46634 18.6339 9.36634 18.6672 9.29967 18.7005C9.23301 18.7339 9.13301 18.8005 9.06634 18.8672C8.99967 18.9339 8.93301 19.0005 8.89967 19.1005C8.86634 19.2005 8.83301 19.2672 8.83301 19.3672C8.83301 19.4672 8.86634 19.5672 8.89967 19.6672C8.93301 19.7672 8.99967 19.8339 9.06634 19.9005L11.3663 22.2339C11.433 22.3005 11.4997 22.3672 11.5997 22.4005C11.6663 22.4339 11.7663 22.4672 11.8663 22.4672C11.9663 22.4672 12.0663 22.4339 12.133 22.4005C12.233 22.3672 12.2997 22.3005 12.3663 22.2339L14.6663 19.8672Z"
          fill={color}
          fillOpacity="0.87"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0.666667V31.3333C0 31.7 0.3 32 0.666667 32H23.3333C23.7 32 24 31.7 24 31.3333V9.33333H16.6667C16.3 9.33333 16 9.03333 16 8.66667V0H0.666667C0.3 0 0 0.3 0 0.666667ZM2 13.666H22V29.9993H2V13.666ZM1.87988 5.4582C1.87988 5.14087 1.93215 4.8534 2.03668 4.5958C2.14495 4.3382 2.29428 4.1198 2.48468 3.9406C2.67508 3.75767 2.90095 3.61767 3.16228 3.5206C3.42362 3.42353 3.70922 3.375 4.01908 3.375C4.32895 3.375 4.61455 3.42353 4.87588 3.5206C5.13722 3.61767 5.36308 3.75767 5.55348 3.9406C5.74388 4.1198 5.89135 4.3382 5.99588 4.5958C6.10415 4.8534 6.15828 5.14087 6.15828 5.4582C6.15828 5.77553 6.10415 6.063 5.99588 6.3206C5.89135 6.5782 5.74388 6.79847 5.55348 6.9814C5.36308 7.1606 5.13722 7.29873 4.87588 7.3958C4.61455 7.49287 4.32895 7.5414 4.01908 7.5414C3.70922 7.5414 3.42362 7.49287 3.16228 7.3958C2.90095 7.29873 2.67508 7.1606 2.48468 6.9814C2.29428 6.79847 2.14495 6.5782 2.03668 6.3206C1.93215 6.063 1.87988 5.77553 1.87988 5.4582ZM2.78708 5.4582C2.78708 5.64487 2.81508 5.8166 2.87108 5.9734C2.93082 6.12647 3.01482 6.26087 3.12308 6.3766C3.23135 6.4886 3.36015 6.57633 3.50948 6.6398C3.66255 6.70327 3.83242 6.735 4.01908 6.735C4.20575 6.735 4.37375 6.70327 4.52308 6.6398C4.67615 6.57633 4.80682 6.4886 4.91508 6.3766C5.02335 6.26087 5.10548 6.12647 5.16148 5.9734C5.22122 5.8166 5.25108 5.64487 5.25108 5.4582C5.25108 5.27527 5.22122 5.1054 5.16148 4.9486C5.10548 4.7918 5.02335 4.6574 4.91508 4.5454C4.80682 4.42967 4.67615 4.34007 4.52308 4.2766C4.37375 4.21313 4.20575 4.1814 4.01908 4.1814C3.83242 4.1814 3.66255 4.21313 3.50948 4.2766C3.36015 4.34007 3.23135 4.42967 3.12308 4.5454C3.01482 4.6574 2.93082 4.7918 2.87108 4.9486C2.81508 5.1054 2.78708 5.27527 2.78708 5.4582ZM6.36406 3.4758H7.36086L8.34086 6.0742H8.35206L9.34886 3.4758H10.2841L8.64886 7.4406H7.94886L6.36406 3.4758ZM12.424 3.4758H11.7016L9.98798 7.4406H10.9512L11.2816 6.6006H12.8216L13.1632 7.4406H14.1488L12.424 3.4758ZM11.556 5.8614L12.0376 4.6294L12.5248 5.8614H11.556Z"
          fill={color}
          fillOpacity="0.87"
        />
      </g>
    </svg>
  );
};
