import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Stack, Tooltip, Typography } from "@mui/material";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellCheckboxRenderer,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useCommonStore } from "common/store";
import { AssetCoreTags } from "modules/asset-core-tags/components";
import { AssetNameAndTags } from "pages/assets/components/asset-name-and-tags";
import { AssetOS } from "pages/assets/components/asset-os";
import { AssetTypeIcon } from "pages/assets/components/asset-type-icon";
import { AssetType } from "pages/assets/types";
import { isDiagnosticsAllowed } from "../../agent-data-grid/helpers/columnHelpers";
import { AgentStatus } from "../../agent-status";
import { AgentUtilization } from "../../agent-utilization";
import { AgentVersion } from "../../agent-version";
import {
  renderToolTipDrawerAutoUpgradeColumns,
  renderToolTipDrawerDebugLogColumns,
  renderToolTipDrawerDecommissionColumns,
  renderToolTipDrawerDiagnosticsColumns,
  renderToolTipDrawerNorthSouthTrafficConfigColumns,
  renderToolTipDrawerRestartColumns,
  renderToolTipDrawerTrafficConfigColumns,
  renderToolTipDrawerUpgradeColumns,
} from "./drawerColumnHelpers";

export enum AutoUpgradeStatus {
  Enable = "enable",
  Disable = "disable",
}

export enum DebugLogStatus {
  Enable = "enable",
  Disable = "disable",
}

export enum TrafficConfigStatus {
  DisableAll = "disabled",
  EnableAll = "enable-all",
  EnableInboundOnly = "enable-inbound-only",
  EnableOutboundOnly = "enable-outbound-only",
}

export enum NsTrafficConfigStatus {
  Disable = "disabled",
  Enable = "enabled",
}

export const TrafficConfigStatusLabels = {
  [TrafficConfigStatus.DisableAll]: "disableAllTraffic",
  [TrafficConfigStatus.EnableAll]: "bidirectionalTraffic",
  [TrafficConfigStatus.EnableInboundOnly]: "inboundTraffic",
  [TrafficConfigStatus.EnableOutboundOnly]: "outboundTraffic",
};

export const AGENT_DRAWER_BASE_COLUMNS: GridColDef[] = [
  {
    field: "agentstatus",
    headerName: "Agent Status",
    width: 140,
    minWidth: 140,
    flex: 0.5,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentStatus agent={params.row} />;
    },
  },
  {
    field: "currentversion",
    headerName: "Agent Version",
    width: 160,
    minWidth: 160,
    flex: 0.5,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AgentVersion agent={params.row} />;
    },
  },
  {
    field: "agenttype",
    headerName: "Type",
    width: 60,
    minWidth: 60,
    headerAlign: "left",
    align: "left",
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AssetTypeIcon asset={params.row.asset} />;
    },
  },
  {
    field: "assetname",
    headerName: "Asset",
    width: 180,
    minWidth: 180,
    flex: 0.7,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AssetNameAndTags asset={params.row.asset} />;
    },
  },
  {
    field: "tags",
    minWidth: 180,
    headerAlign: "left",
    align: "left",
    sortable: false,
    headerName: "Tags",
    flex: window.screen.availWidth > 1200 ? 0.5 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <AssetCoreTags
          viewOnly={false}
          coreTags={params.row.asset.coreTags}
          useAssetCoreTagsStore={useCommonStore}
        />
      );
    },
  },
  {
    field: "osname",
    headerName: "OS",
    width: 80,
    minWidth: 80,
    headerAlign: "left",
    align: "left",
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AssetOS asset={params.row.asset} />;
    },
  },
  {
    field: "agentcreated",
    headerName: "Agent Created",
    minWidth: 180,
    flex: 0.4,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Typography variant="body2">
          {params?.row?.agentCreated || ""}
        </Typography>
      );
    },
  },
  {
    field: "cpuutilization",
    headerName: "CPU Utilization",
    minWidth: 150,
    flex: 0.3,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <AgentUtilization agent={params.row} field={"cpuUtilizationStr"} />
      );
    },
  },
  {
    field: "memoryutilization",
    headerName: "Memory Utilization",
    minWidth: 180,
    flex: 0.3,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <AgentUtilization agent={params.row} field={"memoryUtilizationStr"} />
      );
    },
  },
  {
    field: "lastCheckIn",
    headerName: "Last Checked In",
    minWidth: 180,
    flex: 0.3,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Typography variant="body2">
          {params?.row?.lastCheckIn || ""}
        </Typography>
      );
    },
  },
];

export const AGENT_UPGRADE_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: params => {
      return (
        <Tooltip
          PopperProps={{ disablePortal: true }}
          placement={"right"}
          title={renderToolTipDrawerUpgradeColumns(params.row)}
          followCursor={false}
        >
          {params.row.isUpgradeEnabled ? (
            <GridCellCheckboxRenderer {...params} />
          ) : (
            <Stack>
              <CheckBoxOutlineBlankIcon
                sx={{ width: "20px" }}
                color="disabled"
              ></CheckBoxOutlineBlankIcon>
            </Stack>
          )}
        </Tooltip>
      );
    },
    type: "singleSelect",
  },
  ...AGENT_DRAWER_BASE_COLUMNS,
];

export const AGENT_RESTART_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: params => {
      return (
        <Tooltip
          PopperProps={{ disablePortal: true }}
          placement={"right"}
          title={renderToolTipDrawerRestartColumns(params.row)}
          followCursor={false}
        >
          {params.row.isRestartEnabled ? (
            <GridCellCheckboxRenderer {...params} />
          ) : (
            <Stack>
              <CheckBoxOutlineBlankIcon
                sx={{ width: "20px" }}
                color="disabled"
              ></CheckBoxOutlineBlankIcon>
            </Stack>
          )}
        </Tooltip>
      );
    },
    type: "singleSelect",
  },
  ...AGENT_DRAWER_BASE_COLUMNS,
];

export const AGENT_DIAGNOSTICS_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: params => {
      return (
        <Tooltip
          PopperProps={{ disablePortal: true }}
          placement={"right"}
          title={renderToolTipDrawerDiagnosticsColumns(params.row)}
          followCursor={false}
        >
          {!params.row.isDiagnosticsRunning &&
          isDiagnosticsAllowed(params?.row) ? (
            <GridCellCheckboxRenderer {...params} />
          ) : (
            <Stack>
              <CheckBoxOutlineBlankIcon
                sx={{ width: "20px" }}
                color="disabled"
              ></CheckBoxOutlineBlankIcon>
            </Stack>
          )}
        </Tooltip>
      );
    },
    type: "singleSelect",
  },
  ...AGENT_DRAWER_BASE_COLUMNS,
];

export const AGENT_DECOMMISSION_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: params => {
      return (
        <Tooltip
          PopperProps={{ disablePortal: true }}
          placement={"right"}
          title={renderToolTipDrawerDecommissionColumns(params.row)}
          followCursor={false}
        >
          {params.row.isDecommissionEnabled ? (
            <GridCellCheckboxRenderer {...params} />
          ) : (
            <Stack>
              <CheckBoxOutlineBlankIcon
                sx={{ width: "20px" }}
                color="disabled"
              ></CheckBoxOutlineBlankIcon>
            </Stack>
          )}
        </Tooltip>
      );
    },
    type: "singleSelect",
  },
  ...AGENT_DRAWER_BASE_COLUMNS,
];

export const AGENT_AUTOUPGRADE_COLUMNS = (action: string): GridColDef[] => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: params => {
        let selectedRows =
          action === AutoUpgradeStatus.Disable
            ? params.row.autoUpgradeEnabled
            : !params.row.autoUpgradeEnabled;

        return (
          <Tooltip
            PopperProps={{ disablePortal: true }}
            placement={"right"}
            title={renderToolTipDrawerAutoUpgradeColumns(params.row, action)}
            followCursor={false}
          >
            {selectedRows ? (
              <GridCellCheckboxRenderer {...params} />
            ) : (
              <Stack>
                <CheckBoxOutlineBlankIcon
                  sx={{ width: "20px" }}
                  color="disabled"
                ></CheckBoxOutlineBlankIcon>
              </Stack>
            )}
          </Tooltip>
        );
      },
      type: "singleSelect",
    },
    ...AGENT_DRAWER_BASE_COLUMNS,
  ];
};

export const AGENT_DEBUGLOG_COLUMNS = (action: string): GridColDef[] => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: params => {
        const { isdebugLogEnabled, isDebugLogRunning, isDebugLogSupported } =
          params.row;
        let selectedRows =
          (action === DebugLogStatus.Disable
            ? isdebugLogEnabled
            : !isdebugLogEnabled) &&
          !isDebugLogRunning &&
          isDebugLogSupported;

        return (
          <Tooltip
            PopperProps={{ disablePortal: true }}
            placement={"right"}
            title={renderToolTipDrawerDebugLogColumns(params.row, action)}
            followCursor={false}
          >
            {selectedRows ? (
              <GridCellCheckboxRenderer {...params} />
            ) : (
              <Stack>
                <CheckBoxOutlineBlankIcon
                  sx={{ width: "20px" }}
                  color="disabled"
                ></CheckBoxOutlineBlankIcon>
              </Stack>
            )}
          </Tooltip>
        );
      },
      type: "singleSelect",
    },
    ...AGENT_DRAWER_BASE_COLUMNS,
  ];
};

export const AGENT_TRAFFIC_CONFIG_COLUMNS = (action: string): GridColDef[] => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: params => {
        let selectedRows =
          params.row.asset.type === AssetType.Endpoint &&
          action !== params.row.currentTrafficConfiguration;
        return (
          <Tooltip
            PopperProps={{ disablePortal: true }}
            placement={"right"}
            title={renderToolTipDrawerTrafficConfigColumns(params.row, action)}
            followCursor={false}
          >
            {selectedRows ? (
              <GridCellCheckboxRenderer {...params} />
            ) : (
              <Stack>
                <CheckBoxOutlineBlankIcon
                  sx={{ width: "20px" }}
                  color="disabled"
                ></CheckBoxOutlineBlankIcon>
              </Stack>
            )}
          </Tooltip>
        );
      },
      type: "singleSelect",
    },
    ...AGENT_DRAWER_BASE_COLUMNS,
  ];
};

export const AGENT_NS_TRAFFIC_CONFIG_COLUMNS = (
  action: string
): GridColDef[] => {
  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: params => {
        let selectedRows =
          params.row.asset.type === AssetType.Server &&
          action !== params.row.northSouthTrafficConfiguration;

        return (
          <Tooltip
            PopperProps={{ disablePortal: true }}
            placement={"right"}
            title={renderToolTipDrawerNorthSouthTrafficConfigColumns(
              params.row,
              action
            )}
            followCursor={false}
          >
            {selectedRows ? (
              <GridCellCheckboxRenderer {...params} />
            ) : (
              <Stack>
                <CheckBoxOutlineBlankIcon
                  sx={{ width: "20px" }}
                  color="disabled"
                ></CheckBoxOutlineBlankIcon>
              </Stack>
            )}
          </Tooltip>
        );
      },
      type: "singleSelect",
    },
    ...AGENT_DRAWER_BASE_COLUMNS,
  ];
};
