import { Stack, Typography } from "@mui/material";
import { WorkRequest } from "modules/AppBar/types";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useMemo } from "react";
import { BASE_PENDING_WORK_REQUESTS_COLUMNS } from "./constants";

interface PendingWorkRequestDataGridProps extends DataGridProps<WorkRequest> {
  resourceID?: string;
}

export const PendingWorkRequestDataGrid = (
  props: PendingWorkRequestDataGridProps
) => {
  const columns = useMemo(() => {
    let selectedColumns = props.resourceID
      ? BASE_PENDING_WORK_REQUESTS_COLUMNS.filter(
          col => col.field !== "resource"
        )
      : BASE_PENDING_WORK_REQUESTS_COLUMNS;

    return selectedColumns;
  }, [props.resourceID]);

  return (
    <Stack sx={{ width: "100%", flex: 1, overflow: "hidden" }}>
      <Stack flex={1} overflow="hidden">
        <DataGrid
          rowHeight={64}
          columns={columns}
          pagination
          getRowId={({ id }: WorkRequest) => id}
          paginationMode="server"
          sortingMode="server"
          slots={{
            noRowsOverlay: NoRowsOverlayComp,
          }}
          {...props}
        />
      </Stack>
    </Stack>
  );
};

export const NoRowsOverlayComp = () => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent="center"
      sx={{
        zIndex: 100,
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Typography variant="body2">
        {window.getCTTranslatedText("noBackgroundProcesses")}
      </Typography>
    </Stack>
  );
};
