import { Stack, Typography } from "@mui/material";
import {
  EMPTY_STRING,
  SUPPORTED_ENV_VERSIONS,
  ENVIRONMENT,
} from "../agent-download-page/constants";
import { SupportedVersionsDrawer } from "./SupportedVersionsDrawer";

export interface SupportedEnvironmentProps {
  isOpen: boolean;
  title?: string;
  onCancel: () => void;
}

export const SupportedEnvironments = ({
  isOpen,
  title,
  onCancel,
}: SupportedEnvironmentProps) => {
  const environments = Object.entries(ENVIRONMENT);
  const renderEnvironmentVersions = (environment: string) => {
    const versions = SUPPORTED_ENV_VERSIONS[environment];

    if (Array.isArray(versions)) {
      return (
        <Stack alignItems={"left"} justifyContent={"center"}>
          <Typography variant="body2">{versions.join(" ")}</Typography>
        </Stack>
      );
    } else {
      return EMPTY_STRING;
    }
  };

  return (
    <SupportedVersionsDrawer
      isOpen={isOpen}
      title={title}
      onCancel={onCancel}
      filteredEntities={environments}
      renderVersions={renderEnvironmentVersions}
      rows={environments.length - 1}
    />
  );
};
