import { useMutation } from "@tanstack/react-query";

export function useUserGroupsAPI() {
  return useMutation<any, Error, any>([
    "scim-groups",
    "scim/groups?computeTotal=true",
  ]);
}

export function useUserGroupsStatusAPI() {
  return useMutation<any, Error, any>([
    "scim-groups",
    "scim/user-groups",
    "put",
  ]);
}
