import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import capitalize from "lodash/capitalize";
import { StatusOptionsText } from "pages/paths/constants";
import { PathStoreType } from "pages/paths/store/types";
import { usePathStore } from "pages/paths/store/usePathStore";
import { PathStatus } from "pages/paths/types";
import { useEffect, useRef, useState } from "react";

interface PathStatusDropdownProps {
  onSelect: (status: PathStatus) => void;
  useStore?: PathStoreType;
  preSelectedPathStatus?: PathStatus;
  disabled?: boolean;
}

const StatusOptionsList = [PathStatus.Allow, PathStatus.Deny];

export const PathStatusDropdown = ({
  preSelectedPathStatus,
  onSelect,
  useStore = usePathStore,
  disabled,
}: PathStatusDropdownProps) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const apiRefreshPath = useStore(state => state.apiRefreshRequest);
  const [openMenu, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedOperation, setSelectedOperation] = useState<
    PathStatus | undefined
  >(undefined);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    setSelectedOperation(undefined);
    setMenuOpen(false);
  }, [apiRefreshPath]);

  useEffect(() => {
    if (preSelectedPathStatus && initialLoad) {
      setSelectedOperation(preSelectedPathStatus);
      setInitialLoad(false);
    }
  }, [
    setSelectedOperation,
    preSelectedPathStatus,
    initialLoad,
    selectedOperation,
  ]);

  const handleMenuToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const handleMenuClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMenuOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    status: PathStatus
  ) => {
    onSelect(status);
    setSelectedOperation(status);
    setMenuOpen(false);
  };

  return (
    <Stack direction="row">
      {userPermissions.has("UPDATE_PATH") && (
        <Tooltip
          title={window.getCTTranslatedText("disablePathStatusDropdown")}
        >
          <ButtonGroup
            variant="outlined"
            ref={anchorRef}
            aria-label="split button"
            sx={{ minWidth: "225px" }}
            disabled={disabled}
          >
            <Button
              aria-controls={openMenu ? "split-button-menu" : undefined}
              aria-expanded={openMenu ? "true" : undefined}
              aria-label="change port status"
              aria-haspopup="menu"
              onClick={handleMenuToggle}
              endIcon={<ArrowDropDownIcon />}
              sx={{ width: "225px" }}
            >
              {window.getCTTranslatedText(
                selectedOperation
                  ? getDisplayOption(selectedOperation)
                  : "Select Path status"
              )}
            </Button>
          </ButtonGroup>
        </Tooltip>
      )}
      <Popper
        sx={{
          zIndex: 2,
          minWidth: "225px",
        }}
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {StatusOptionsList.map((option, index) => (
                    <Stack key={option} sx={{ mx: 2 }}>
                      <MenuItem
                        onClick={event => handleMenuItemClick(event, option)}
                      >
                        <Typography variant="body2">
                          {capitalize(
                            window.getCTTranslatedText(getDisplayOption(option))
                          )}
                        </Typography>
                      </MenuItem>
                      {index < StatusOptionsList.length - 1 && <Divider />}
                    </Stack>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};

const getDisplayOption = (option: PathStatus) => {
  switch (option) {
    case PathStatus.Allow:
      return "allow";
    case PathStatus.Deny:
      return "deny";
    default:
      return StatusOptionsText[option];
  }
};
