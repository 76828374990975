import { FacetGroupInfo } from "modules/core/types";
import { TemplateType } from "./types";
import { FacetGroups } from "../../modules/facets/types";
export const FACET_ORDER: FacetGroupInfo = {
  oobtemplate: FacetGroups.Templates,
  templatetype: FacetGroups.Templates,
  templatename: FacetGroups.Templates,
  templatecategory: FacetGroups.Templates,
};

export const TemplateTypeOptionsText: { [key: string]: string } = {
  [TemplateType.ApplicationTemplate]: "Allow",
  [TemplateType.BlockTemplate]: "Block",
};
