import { create } from "zustand";
import { UserPermissionsInterface } from "./types";

export const useUserPermissionsStore = create<UserPermissionsInterface>(
  set => ({
    userPermissions: new Map(),
    setPermissions: (userPermissions: Array<string>) => {
      set(state => {
        let newPermissions = new Map(state.userPermissions);

        userPermissions.forEach((permission: string) => {
          newPermissions.set(permission, true);
        });

        return {
          userPermissions: newPermissions,
        };
      });
    },
  })
);
