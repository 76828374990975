import { Scope } from "modules/scope-metadata/types";
import { Appliance } from "pages/appliances/types";
import { Asset } from "pages/assets/types";
import { Network } from "pages/networks/types";
import { Path } from "pages/paths/types";
import { Suggestion } from "./types";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";

export const onLoadPathData = (
  data: Path[],
  includeDomain: boolean
): Suggestion[] => {
  let suggestList: Suggestion[] = [];

  data?.forEach((path: Path) => {
    if (path?.sourceNamedNetwork) {
      suggestList.push({
        type: Scope.Network,
        displayName: path?.sourceNamedNetwork?.namedNetworkName,
        value: path?.sourceNamedNetwork?.namedNetworkId,
        rawData: path?.sourceNamedNetwork,
      });
      suggestList.push({
        type: Scope.TagBasedPolicy,
        displayName: path?.sourceTagBasedPolicy?.tagBasedPolicyName,
        value: path?.sourceTagBasedPolicy?.tagBasedPolicyId ?? "",
        rawData: path?.sourceTagBasedPolicy,
      });
    }
    if (path?.destinationNamedNetwork) {
      suggestList.push({
        type: Scope.Network,
        displayName: path?.destinationNamedNetwork?.namedNetworkName,
        value: path?.destinationNamedNetwork?.namedNetworkId,
        rawData: path?.destinationNamedNetwork,
      });
      suggestList.push({
        type: Scope.TagBasedPolicy,
        displayName: path?.destinationTagBasedPolicy?.tagBasedPolicyName,
        value: path?.destinationTagBasedPolicy?.tagBasedPolicyId ?? "",
        rawData: path?.destinationTagBasedPolicy,
      });
    }
    if (path?.srcIp) {
      suggestList.push({
        type: "IP",
        value: path?.srcIp,
        displayName: path?.srcIp,
      });
    }
    if (path?.dstIp && path?.dstIp?.length > 0) {
      path?.dstIp.forEach((ip: string) => {
        suggestList.push({
          type: "IP",
          value: ip,
          displayName: ip,
        });
      });
    }

    if (includeDomain && path?.domain) {
      suggestList.push({
        type: "Domain",
        value: path?.domain,
        displayName: path?.domain,
      });
    }
  });

  return suggestList;
};

export const segmentsDataMapper = (segment: TagPolicy): Suggestion => {
  return {
    type: Scope.TagBasedPolicy,
    displayName: segment.tagBasedPolicyName,
    value: segment.tagBasedPolicyId,
    rawData: {
      tagBasedPolicyName: segment.tagBasedPolicyName,
      tagBasedPolicyvalie: segment.tagBasedPolicyId,
    },
  };
};

export const networkDataMapper = (network: Network): Suggestion => {
  return {
    type: Scope.Network,
    displayName: network.namedNetworkName,
    value: network.namedNetworkId,
    rawData: {
      namedNetworkName: network.namedNetworkName,
      namedNetworkvalue: network.namedNetworkId,
    },
  };
};

export const assetDataMapper = (asset: Asset): Suggestion => {
  return {
    type: Scope.Asset,
    displayName: asset.assetName,
    value: asset.assetId,
  };
};

export const applianceDataMapper = (
  applianceData: Appliance[],
  agentId?: string
): Suggestion[] => {
  let appliances: Suggestion[] = [];
  if (applianceData?.length) {
    for (let appliance of applianceData) {
      if (appliance.agentId !== agentId) {
        appliances.push({
          type: Scope.Appliance,
          displayName: appliance.agentName,
          value: appliance.agentId,
          rawData: appliance,
        });
      }
    }
  }

  return appliances;
};
