import {
  Box,
  Stack,
  FormControl,
  TextField,
  Typography,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { Appliance, ApplianceFormProps } from "pages/appliances/types";

const formMinWidth = 550;

export interface ApplianceEditFormProps {
  appliance?: ApplianceFormProps | Appliance | undefined;
  title?: string;
  updateApplianceData?: Function;
  cancel?: () => void;
  btnTitle?: string;
}

const useEditApplianceAPI = (agentId: string | undefined) => {
  const path = `gateways/${agentId}`;
  return useMutation<any, Error, any>(["gateways", path, "put"]);
};

export function ApplianceForm({
  appliance,
  title,
  updateApplianceData,
  cancel,
  btnTitle,
}: ApplianceEditFormProps) {
  const [agentName, setAgentName] = useState(appliance?.agentName || "");
  const [agentLocation, setAgentLocation] = useState(
    appliance?.agentLocation || ""
  );
  const [loading, setLoading] = useState(false);
  const agentId = appliance?.agentId;
  const editAppliancesAPI = useEditApplianceAPI(agentId);
  const queryClient = useQueryClient();

  const updateApplianceDetails = () => {
    if (agentName?.trim().length > 0) {
      setLoading(true);

      const body: ApplianceFormProps = {
        agentName: (agentName || "")?.trim(),
        agentLocation: (agentLocation || "")?.trim(),
      };

      if (appliance?.agentId) {
        editAppliance(body);
      }
    }
  };

  const editAppliance = (body: ApplianceFormProps) => {
    editAppliancesAPI.mutate(body, {
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: ["gateways"],
        });
        if (updateApplianceData) {
          updateApplianceData({ agentName, agentLocation });
        }
        setLoading(false);
        setTimeout(() => {
          cancel && cancel();
          setLoading(false);
        }, 300);
      },
      onError: () => {
        setLoading(false);
        cancel && cancel();
      },
    });
  };

  const handleNameChange = (e: any) => {
    if (e?.target?.value) {
      setAgentName(e.target.value);
    }
  };

  const handleLocationChange = (e: any) => {
    if (e?.target?.value) {
      setAgentLocation(e.target.value);
    }
  };

  const keyListener = (event: any) => {
    if (event?.key === "Enter" || event?.keyCode === 13) {
      updateApplianceDetails();
    }
  };

  return (
    <>
      <DialogContent>
        <Stack
          alignItems={"flex-start"}
          style={{ position: "relative", height: "100%" }}
        >
          <Typography variant="h6" sx={{ mt: 2, mb: 4 }}>
            {window.getCTTranslatedText(title ?? "")}
          </Typography>
          <Box style={{ minWidth: "100%" }}>
            <Stack
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={4}
            >
              <FormControl sx={{ m: 0, minWidth: formMinWidth }} size="small">
                <Stack direction={"column"} spacing={5}>
                  <TextField
                    name="agentname"
                    value={agentName}
                    type="text"
                    placeholder={window.getCTTranslatedText(
                      "enterApplianceName"
                    )}
                    fullWidth
                    label={window.getCTTranslatedText("Name")}
                    onChange={handleNameChange}
                    inputProps={{ autoComplete: "off" }}
                    onKeyUp={keyListener}
                  />

                  <TextField
                    name="agentlocation"
                    value={agentLocation}
                    type="text"
                    placeholder={window.getCTTranslatedText(
                      "Enter appliance location ..."
                    )}
                    fullWidth
                    label={window.getCTTranslatedText("Location")}
                    onChange={handleLocationChange}
                    inputProps={{ autoComplete: "off" }}
                    onKeyUp={keyListener}
                  />
                </Stack>
              </FormControl>
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <ToolbarAction
          loading={loading}
          save={updateApplianceDetails}
          cancel={cancel}
          actionBtnText={btnTitle}
        ></ToolbarAction>
      </DialogActions>
    </>
  );
}
