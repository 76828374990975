import { Box, Stack } from "@mui/material";
import { AgentColumnRenderProps } from "pages/agents/types";
import { ViewLogsButton } from "./ViewLogsButton";

export function AgentLogs({ agent, asset }: Readonly<AgentColumnRenderProps>) {
  if (!agent?.agentId) {
    return <Box style={{ width: 42 }} />;
  }

  return (
    <Stack
      sx={{ width: "100%" }}
      alignItems="flex-start"
      justifyItems={"center"}
    >
      <ViewLogsButton agent={agent} agentId={agent.agentId} asset={asset} />
    </Stack>
  );
}
