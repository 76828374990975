import CircleIcon from "@mui/icons-material/Circle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { MetaDataItemProps, User } from "pages/assets/types";
import { useState } from "react";

export const MetaDataItem = ({
  label,
  value,
  icon,
  isUserDefinedTagsExist,
}: MetaDataItemProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Stack
      direction="row"
      spacing={1.5}
      sx={{ my: isUserDefinedTagsExist ? 1 : 2, maxWidth: "100%" }}
    >
      <Stack
        direction={"column"}
        spacing={0}
        alignContent={"center"}
        justifyItems={"center"}
        justifyContent={"center"}
      >
        <>{icon}</>
      </Stack>

      <Stack
        direction={"column"}
        spacing={0}
        alignContent={"center"}
        justifyItems={"center"}
        justifyContent={"center"}
        sx={{ width: "calc(100% - 24px)", maxWidth: "calc(100% - 24px)" }}
      >
        <Typography
          variant="caption"
          sx={{
            color:
              theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, 0.6)"
                : "rgba(0, 0, 0, 0.6)",
            textTransform: "capitalize",
          }}
          title={window.getCTTranslatedText(label)}
          noWrap
        >
          {window.getCTTranslatedText(label)}
        </Typography>

        {typeof value === "string" && (
          <Tooltip
            title={value ? value : ""}
            placement="bottom-start"
            arrow={false}
          >
            <Typography
              noWrap
              variant="body2"
              sx={{ textTransform: "capitalize", pr: 4 }}
            >
              {value || ""}
            </Typography>
          </Tooltip>
        )}
        {Array.isArray(value) && (
          <>
            {value?.length === 1 ? (
              <>
                <Stack direction={"row"} spacing={2} alignItems="center">
                  <CircleIcon
                    color={value[0]?.signedin ? "success" : "disabled"}
                    fontSize="small"
                    sx={{ width: "16px" }}
                  />
                  <Tooltip title={`${value[0]?.email}`}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{ textTransform: "capitalize", pr: 4 }}
                    >
                      {`${value[0]?.email}`}
                    </Typography>
                  </Tooltip>
                </Stack>
              </>
            ) : (
              <>
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems="center"
                  onMouseEnter={e => {
                    if (value && value?.length > 1) {
                      setAnchorEl(e.currentTarget);
                    }
                  }}
                  onMouseLeave={() => {
                    setAnchorEl(null);
                  }}
                >
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{ textTransform: "capitalize" }}
                    className="user-count"
                  >
                    {value?.length || 0}
                  </Typography>
                  {value?.length > 0 && (
                    <InfoOutlinedIcon sx={{ width: "14px" }}></InfoOutlinedIcon>
                  )}
                </Stack>

                <Popper
                  id={"user-popper"}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper elevation={6}>
                        <Stack sx={{ px: 4, py: 2 }}>
                          <List
                            dense={false}
                            disablePadding
                            sx={{
                              width: "100%",
                            }}
                          >
                            {(value || []).map((user: User, index: number) => {
                              return (
                                <ListItem
                                  disablePadding
                                  key={`${user?.name}-${index}`}
                                >
                                  <ListItemIcon sx={{ minWidth: "32px" }}>
                                    <CircleIcon
                                      color={
                                        user?.signedin ? "success" : "disabled"
                                      }
                                      fontSize="small"
                                      sx={{ width: "16px" }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={`${user.email}`} />
                                </ListItem>
                              );
                            })}
                          </List>
                        </Stack>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};
