import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { CopyButton } from "common/atoms/copy-button";
import { dayjsWithPlugins } from "common/utils";
import { DeleteAPIKeyButton } from "./components";
import { UserAPIKey } from "./types";

export const API_KEYS_COLUMNS: GridColDef<UserAPIKey>[] = [
  {
    field: "fingerprint",
    headerName: "Fingerprint",
    flex: 1,
    sortable: false,
    renderCell: params => {
      return (
        <Stack direction={"row"} spacing={1} alignItems="center">
          <code>{params.row.fingerprint}</code>
          <CopyButton text={params.row.fingerprint} />
        </Stack>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created",
    flex: 1,
    sortable: false,
    renderCell: params => {
      return (
        <Typography variant="body2">
          {dayjsWithPlugins(params.row.createdAt).fromNow()}
        </Typography>
      );
    },
  },

  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    sortable: false,
    renderCell: params => {
      return <DeleteAPIKeyButton apiKey={params.row} />;
    },
  },
];
