import pluralize from "pluralize";
import { recommendationConfig } from "../constant";
import { Recommendation } from "../types";

export const getRecomnmendationText = (
  rec: Recommendation,
  type = "title",
  hasPermission = true
) => {
  if (!hasPermission) {
    return "View";
  }
  if (rec) {
    const id = rec?.name;
    const scope = rec?.attributes?.scope;
    const days = rec?.attributes?.durationInDays ?? 0;

    if (id && scope) {
      if (type === "btnTitle") {
        let btnStr = recommendationConfig()[scope][id]?.btnTitle;
        return window.getCTTranslatedText(btnStr);
      } else {
        const count = rec?.attributes?.count || 0;

        const scopeText = window.getCTTranslatedText(pluralize(scope, count));
        const dayText = window.getCTTranslatedText(pluralize("day", days));
        let str = recommendationConfig({
          count,
          scope: scopeText,
          durationCount: days,
          durationLabel: dayText,
        })[scope][id]?.title;

        return str;
      }
    }
    return "";
  }
  return "";
};
