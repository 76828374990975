import { getUniqueValues } from "common/utils/getUniqueListBy";
import { flatten } from "flat";
import { FlattenData } from "../types";

export function convertJsonToCsv<DataType>(
  data: Array<DataType>,
  download?: boolean
): string {
  const flattenedData = flattenData(data); //flatten nested objects and arrays in JSON
  const csvData = JsonToCSV(flattenedData); // Convert flattened JSON data to CSV
  const urlToDownload = downloadCsv(csvData, download); // Usage: Create a download link for the CSV file
  return urlToDownload;
}

function flattenData<DataType>(data: Array<DataType>): Array<FlattenData> {
  // Flatten JSON data
  const flattenedData: Array<FlattenData> = data.map((entry: any) => {
    const currentObject = entry;
    for (const key in currentObject) {
      if (currentObject && Array.isArray(currentObject[key])) {
        const arrData = currentObject[key];
        if (arrData && arrData?.length > 0 && typeof arrData[0] === "string") {
          currentObject[key] = arrData.join(" | ");
        } else {
          delete currentObject[key];
        }
      }
    }
    return flatten(currentObject);
  });
  return flattenedData;
}

function JsonToCSV(jsonData: Array<FlattenData>): string {
  let allKeys = getUniqueValues(
    jsonData.flatMap(item => {
      let keys = Object.keys(item);
      keys = keys.filter((key: string) => {
        const isValidColumn =
          typeof item[key] === "string" || typeof item[key] === "number";
        return isValidColumn;
      });
      return keys;
    })
  );

  // Create CSV content
  const csvContent = [
    allKeys.join(","), // CSV header row
    ...jsonData.map(item =>
      allKeys.map(key => cleanCSV(item[key]) ?? "").join(",")
    ), // Data rows
  ].join("\n");

  return csvContent;
}

const STARTS_WITH_SPECIAL_CHARACTER = ["=", "+", "-", "@", "\t", "\r"];
const INCLUDES_SPECIAL_CHARACTER = [",", ";", "'", '"'];

function cleanCSV(item: string | null | undefined): string {
  if (item === null || item === undefined) {
    return "";
  }

  if (!item.trim) {
    return item;
  }
  const firstChar = item.trim().charAt(0);
  if (STARTS_WITH_SPECIAL_CHARACTER.includes(firstChar)) {
    return `"""${item.replace(/"/g, '""')}"""`;
  }

  if (INCLUDES_SPECIAL_CHARACTER.some(char => item.includes(char))) {
    return `"""${item.replace(/"/g, '""')}"""`;
  }

  return item;
}

function downloadCsv(data: string, download: boolean = false): string {
  const csvBlob = new Blob([data], { type: "text/csv;charset=utf-8;" });
  return downloadBlob(csvBlob, download);
}

export function downloadBlob(data: Blob, download: boolean = false): string {
  const url = URL.createObjectURL(data);
  if (download) {
    // For other browsers
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(data);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  return url;
}
