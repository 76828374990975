import {
  agent_types,
  platforms,
} from "pages/agents/components/agent-download-page/constants";
import { AgentVersionDetails } from "pages/agents/components/agent-download-page/type";
import {
  AgentVersion,
  AgentVersions,
} from "../../agent-downlaod-version/useVersionAPI";

export function getAgentVersions(data: AgentVersions | undefined) {
  let agentVersionMap: AgentVersionDetails = {
    rpm: { versionsInfo: [] },
    rpmLegacy: { versionsInfo: [] },
    suse: { versionsInfo: [] },
    darwin: { versionsInfo: [] },
    windows: { versionsInfo: [] },
    aix: { versionsInfo: [] },
    solaris: { versionsInfo: [] },
    debian: { versionsInfo: [] },
    linux: { versionsInfo: [] },
    powershell: { versionsInfo: [] },
    docker: { versionsInfo: [] },
  };

  let filteredAgentData = data?.versions?.filter(
    (ele: AgentVersion) =>
      ele?.agentType === agent_types.CT_AGENT ||
      ele?.agentType === agent_types.INSTALL_SCRIPT ||
      ele?.agentType === agent_types.CT_USER_AGENT ||
      ele?.agentType === agent_types.CT_REMOTE_CONNECTOR ||
      ele?.agentType === agent_types.CT_CONTAINER_AGENT ||
      ele?.agentType === agent_types.CT_LEGACY_AGENT
  );

  filteredAgentData?.forEach((agentVersion: AgentVersion) => {
    if (
      agentVersion.targetPlatform === platforms.RPM &&
      agentVersion.agentType === agent_types.CT_LEGACY_AGENT
    ) {
      agentVersionMap[platforms.RPM_LEGACY]?.versionsInfo.push({
        version: agentVersion?.version,
        downloadLink: agentVersion?.downloadLink,
        fileName: agentVersion?.fileName,
        agentType: agentVersion?.agentType,
        architecture: agentVersion?.architecture,
        targetPlatform: agentVersion?.targetPlatform,
        recommended: agentVersion?.recommended,
      });
    } else if (
      agentVersion.targetPlatform === platforms.RPM &&
      agentVersion.agentType === agent_types.CT_AGENT
    ) {
      agentVersionMap[platforms.SUSE]?.versionsInfo.push({
        version: agentVersion?.version,
        downloadLink: agentVersion?.downloadLink,
        fileName: agentVersion?.fileName,
        agentType: agentVersion?.agentType,
        architecture: agentVersion?.architecture,
        targetPlatform: agentVersion?.targetPlatform,
        recommended: agentVersion?.recommended,
      });
      agentVersionMap[
        agentVersion?.targetPlatform as keyof AgentVersionDetails
      ]?.versionsInfo.push({
        version: agentVersion?.version,
        downloadLink: agentVersion?.downloadLink,
        fileName: agentVersion?.fileName,
        agentType: agentVersion?.agentType,
        architecture: agentVersion?.architecture,
        targetPlatform: agentVersion?.targetPlatform,
        recommended: agentVersion?.recommended,
      });
    } else {
      agentVersionMap[
        agentVersion?.targetPlatform as keyof AgentVersionDetails
      ]?.versionsInfo.push({
        version: agentVersion?.version,
        downloadLink: agentVersion?.downloadLink,
        fileName: agentVersion?.fileName,
        agentType: agentVersion?.agentType,
        architecture: agentVersion?.architecture,
        targetPlatform: agentVersion?.targetPlatform,
        recommended: agentVersion?.recommended,
      });
    }
  });

  return agentVersionMap;
}
