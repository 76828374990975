import { Paper, Stack, useTheme } from "@mui/material";
import { useUserStore } from "./store";
import { Scope } from "modules/scope-metadata/types";
import { useCore } from "modules/core";
import { UserDataGrid } from "./components/user-data-grid";
import { User } from "pages/users-segmentation/components/users/types";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { FacetControllerGroup } from "modules/facets";
import { FACET_ORDER, FACET_OPTION_MAPPING } from "./constants";
import { useUsersAPI } from "./hooks";
import { useEffect } from "react";
import { useCommonStore } from "common/store";

export const Users = () => {
  const theme = useTheme();

  const coreResponse = useCore<User>({
    useStore: useUserStore,
    facetGroupInfo: FACET_ORDER,
    scope: Scope.SCIM_USER_DEPARTMENT,
    facetOptionsDisplayMapping: FACET_OPTION_MAPPING,
    useApi: useUsersAPI,
    pageSize: 100,
  });
  const updateFacetOption = useCommonStore(state => state.updateFacetOption);
  useEffect(() => {
    return () => {
      updateFacetOption({
        facetName: "",
        optionName: "",
        value: false,
      });
    };
  });
  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction={"row"} alignItems="center">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              config={coreResponse?.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack>

      <Paper
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          flex: 1,
          overflow: "hidden",
        }}
      >
        <UserDataGrid
          rows={coreResponse.rows}
          metadata={coreResponse.metadata}
          rowCount={coreResponse.rowCount}
          mutation={coreResponse.mutation}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={coreResponse.onSortChange}
        />
      </Paper>
    </Stack>
  );
};
