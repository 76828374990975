import { Stack, Typography } from "@mui/material";
import { IDPColumnRenderProps } from "./../types";

export const IDPName = ({ idp, viewOnly = false }: IDPColumnRenderProps) => {
  const typeTitle = idp.idpName;
  return (
    <Stack direction={"row"} spacing={1} flex={1} alignItems={"center"}>
      <Typography variant="body2">{typeTitle} </Typography>
    </Stack>
  );
};
