import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import { TemplateDetail } from "../template-detail/TemplateDetail";
import { Toolbar } from "common/atoms/toolbar";

interface TemplateDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  templateId: string | undefined;
}

export const TemplateViewDrawer = ({
  isOpen,
  onClose,
  templateId,
}: TemplateDrawerProps) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 0,
          width: "70%",
          maxwidth: "1000px",
          minWidth: "600px",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title="Close Drawer">
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={onClose}
            sx={{ position: "absolute", right: "20px", top: "26px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <TemplateDetail templateId={templateId}></TemplateDetail>
    </Drawer>
  );
};
