import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useState } from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAssetStore } from "pages/assets/store";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";
import { useTemplateStore } from "pages/templates/store";
import { Template } from "pages/templates/types";
import { useParams } from "react-router-dom";

export interface TemplateRemoveProps {
  template: Template | undefined;
  handleAllowRemove: Function;
  allowRemove: boolean;
  page?: string;
  policyId?: string;
}

const useRemoveTemplatesAPI = ({
  templateId,
  assetId,
  policyId,
  page,
}: {
  templateId: string | undefined;
  assetId?: string;
  policyId?: string;
  page: string;
}) => {
  let path = `assets/${assetId}/templates/${templateId}`;
  let apiKey = "asset";
  if (page === "tags" && policyId) {
    path = `tagbasedpolicies/${policyId}/templates`;
    apiKey = "tagbasedpolicy";
  }

  return useMutation<any, Error, any>([apiKey, path, "delete"]);
};

export function TemplateRemoveEntity({
  template,
  handleAllowRemove,
  allowRemove,
  page = "assets",
  policyId,
}: TemplateRemoveProps) {
  const { assetId } = useParams();
  const notify = useEventSubscriptionStore(state => state.notify);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const requestAPIRefresh = useTemplateStore(state => state.requestAPIRefresh);
  const requestAssetAPIRefresh = useAssetStore(
    state => state.requestAPIRefresh
  );
  const requestPoliciesAPIRefresh = useTagPolicyStore(
    state => state.requestAPIRefresh
  );

  const removeTemplatesAPI = useRemoveTemplatesAPI({
    templateId: template?.templateId,
    assetId: assetId,
    page: page,
    policyId: policyId,
  });

  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const updateTemplateDetails = () => {
    setLoading(true);

    let body: any = {};

    if (page === "tags") {
      body = {
        templates: [template?.templateId],
      };
    }

    removeTemplatesAPI.mutate(body, {
      onSuccess: response => {
        notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
          label: "RemoveTemplateRequestSubmittedSuccessfully",
        });
        queryClient.invalidateQueries({
          queryKey: ["policy"],
        });
        queryClient.invalidateQueries({
          queryKey: ["asset"],
        });
        queryClient.invalidateQueries({
          queryKey: ["tagbasedpolicy"],
        });
        requestAPIRefresh();
        requestAssetAPIRefresh();
        requestPoliciesAPIRefresh();
        setTimeout(() => {
          handleAllowRemove(false);
          setLoading(false);
        }, 500);
      },
      onError: error => {
        setLoading(false);
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  return (
    <>
      <Dialog
        open={allowRemove}
        onClose={() => handleAllowRemove(false)}
        aria-labelledby="remove-template"
      >
        <DialogTitle id="remove-template">
          {window.getCTTranslatedText(`Remove Template`)}
        </DialogTitle>
        <DialogContent>
          {`${window.getCTTranslatedText(
            "Are you sure you want to remove template"
          )} "${template?.templateName}" ${window.getCTTranslatedText(
            "from this"
          )} ${window.getCTTranslatedText(
            page === "tags" ? "tagBasedPolicy" : "asset"
          )}?`}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => handleAllowRemove(false)}
          >
            {window.getCTTranslatedText("Cancel")}
          </Button>
          <LoadingButton
            loading={removeTemplatesAPI?.isLoading || loading}
            onClick={updateTemplateDetails}
            variant="contained"
            autoFocus
            color={"error"}
            type="submit"
          >
            {window.getCTTranslatedText("Remove")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
