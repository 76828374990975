import { Box, Stack, Tooltip } from "@mui/material";
import { GridGroupNode, useGridApiContext } from "@mui/x-data-grid-pro";
import { ApplianceColumnRenderProps } from "pages/appliances/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AgentName({ agent, viewOnly }: ApplianceColumnRenderProps) {
  const apiRef = useGridApiContext();
  const rowNode = apiRef.current.getRowNode(
    agent?.agentId ?? ""
  ) as GridGroupNode;
  const childrenCount = rowNode?.children?.length ?? 0;

  if (!agent?.agentId) {
    return <Box style={{ minWidth: 120 }} />;
  }
  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <Tooltip title={agent?.agentName ?? ""}>
        <CTDatagridButton
          asText={viewOnly === true}
          isDisabled={viewOnly === true}
          facetName={"agentname"}
          facetOption={agent.agentName ?? ""}
        >
          {agent?.agentName || ""} {childrenCount > 0 && `(${childrenCount})`}
        </CTDatagridButton>
      </Tooltip>
    </Stack>
  );
}
