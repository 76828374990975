import { useState } from "react";
import { TagItemProps } from "pages/tags/types";

export const useTagItem = ({ tag }: TagItemProps) => {
  const [editTag, setEditTag] = useState<boolean>(false);
  const [deleteTag, setDeleteTag] = useState(false);

  const handleEditTags = (visibility: boolean) => {
    setEditTag(visibility);
  };

  const handleDeleteTags = (visibility: boolean) => {
    setDeleteTag(visibility);
  };

  return {
    handleEditTags,
    handleDeleteTags,
    editTag,
    deleteTag,
  };
};
