import { FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "../../modules/facets/types";

export const FACET_GROUP_INFO: FacetGroupInfo = {
  agentname: FacetGroups.Appliances,
  agentlocation: FacetGroups.Appliances,
  agentstatus: FacetGroups.Agents,
  currentversion: FacetGroups.Agents,
};

export const ACTIVATION_STATUS_INTERVAL = 5000;

export const ACTIVATION_API_TIMEOUT = 300000;
