import { useMutation } from "@tanstack/react-query";
import { TrafficConfigStatus } from "../../agent-drawers/helpers/constants";

interface AgentDecommissionPayload {
  criteria: string;
  version: string;
}
interface DebugLogUpdateDetails {
  debugLogEnabled: boolean;
}
interface AgentDebugLogPayload {
  criteria: string;
  debugLogDetails: DebugLogUpdateDetails;
}

interface AgentTrafficConfigPayload {
  criteria: string;
  trafficConfig?: TrafficConfigStatus;
}

// TODO: To be replace with query interface rather than mutation
export function useAgentsAPI() {
  return useMutation<any, Error, any>([
    "agents-list",
    "agents/actions/search?computeTotal=true",
  ]);
}

export function useUpgradeAgents() {
  const path = `agents/actions/upgrade`;
  return useMutation<any, Error, any>(["agents", path, "post"]);
}

export function useRestartAgents() {
  const path = `agents/actions/restart`;
  return useMutation<any, Error, any>(["agents", path, "post"]);
}
export function useAutoUpgradeAgents() {
  const path = `agents`;
  return useMutation<any, Error, any>(["agents", path, "put"]);
}

export function useDiagnosticAgents() {
  const path = `agents/actions/diagnostics`;
  return useMutation<any, Error, any>(["agents", path, "post"]);
}

export function useDecommissionAgents() {
  const path = `agents/actions/decommission`;
  return useMutation<any, Error, AgentDecommissionPayload>([
    "agents",
    path,
    "post",
  ]);
}

export function useDebugLogAgents() {
  const path = `agents/actions/debuglog`;
  return useMutation<any, Error, AgentDebugLogPayload>(["agents", path, "put"]);
}

export function useTrafficConfigAgents() {
  const path = `agents`;
  return useMutation<any, Error, AgentTrafficConfigPayload>([
    "agents",
    path,
    "put",
  ]);
}
