import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { useTagTemplates } from "./hooks";
import { TagAttributesBlock } from "./components/tags-attributes-block";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";

export function TagTemplates({
  criteria,
  multiselect = false,
  onSelectCallback,
  btnTitle,
  canAssign,
}: {
  criteria: string;
  multiselect?: boolean;
  onSelectCallback?: Function;
  btnTitle?: string;
  canAssign?: boolean;
}) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const {
    showAddToTemplateDrawer,
    openAddToTemplateDrawer,
    closeAddToTemplateDrawer,
  } = useTagTemplates();

  return (
    <>
      <TagAttributesBlock
        title="Templates"
        canAssign={Boolean(userPermissions.has("UPDATE_POLICY") && canAssign)}
        assign={openAddToTemplateDrawer}
        btnTitle={btnTitle}
      />
      <AddToTemplateDrawer
        isOpen={showAddToTemplateDrawer}
        page={"tags"}
        title="Assign Template"
        rules={[]}
        onCancel={closeAddToTemplateDrawer}
        onConfirm={closeAddToTemplateDrawer}
        btnTitle={"assign"}
        criteria={criteria}
        multiselect={multiselect}
        onSelectCallback={onSelectCallback}
        hasPermission={userPermissions.has("UPDATE_POLICY")}
        actionType={TemplateActionType.assign}
      />
    </>
  );
}
