import { Box, Chip, Stack, Tooltip } from "@mui/material";
import { AssetColumnRenderProps, NetworkInterface } from "pages/assets/types";

export function AssetIPAddress({ asset }: AssetColumnRenderProps) {
  if (!asset.assetId) {
    return <Box style={{ width: 70 }} />;
  }
  let ips: string[] = [];

  if (asset?.interfaces?.length) {
    asset?.interfaces?.forEach((nwInterface: NetworkInterface) => {
      if (nwInterface?.ipaddresses?.length) {
        ips = ips.concat(nwInterface?.ipaddresses);
      }
    });
  }
  let tooltipText = "";
  ips.forEach(ip => {
    if (tooltipText !== "") {
      tooltipText = tooltipText.concat(", ");
    }
    tooltipText = tooltipText.concat(ip);
  });

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="center">
      <>
        {ips.length ? (
          <Tooltip
            title={tooltipText || ""}
            placement="bottom-start"
            arrow={false}
          >
            <Stack sx={{ maxWidth: "100%", my: 1 }} direction="row" spacing={1}>
              {ips.map(ip => {
                return <Chip key={ip} label={ip} />;
              })}
            </Stack>
          </Tooltip>
        ) : null}
      </>
    </Stack>
  );
}
