import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { Agent } from "pages/agents/types";
import { AssetType } from "pages/assets/types";
import { useMemo, useState } from "react";
import { useTrafficConfigAgents } from "../agent-data-grid/hooks/useAgentsApi";
import { NsTrafficConfigStatus } from "./helpers/constants";
import { useSelectedData } from "./hooks";
import * as semver from "semver";

export interface ConfirmNsTrafficConfigActionProps {
  selectedItems: string[];
  nsTrafficConfigAction: string;
}

export interface AgentDrawerProps {
  isOpen: boolean;
  page: string;
  title?: string;
  data: Agent[];
  rowCount: number;
  selection: GridRowId[];
  columns?: GridColDef[];
  onCancel: () => void;
  onConfirm: ({
    selectedItems,
    nsTrafficConfigAction,
  }: ConfirmNsTrafficConfigActionProps) => void;
  nsTrafficConfigAction?: NsTrafficConfigStatus;
}

export const AgentNsTrafficConfigDrawer = ({
  isOpen,
  page,
  title,
  rowCount,
  selection,
  data,
  columns,
  nsTrafficConfigAction = NsTrafficConfigStatus.Disable,
  onCancel,
  onConfirm,
}: AgentDrawerProps) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const [selectionRowIds, setSelectionRowIds] =
    useState<Array<GridRowId>>(selection);
  const AgentNsTrafficConfigSupportedMinVersion = "24.6.0";
  const updatedData: Array<Agent> = useMemo(() => {
    return data?.filter((row: Agent) => {
      return selectionRowIds.indexOf(row.rowId!) !== -1;
    });
  }, [selectionRowIds, data]);
  const { selectedItems } = useSelectedData(updatedData);
  const nsTrafficConfigAgentsMutation = useTrafficConfigAgents();

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onCancel}
      PaperProps={{
        sx: {
          padding: "0px",
          width: "80%",
          minWidth: "1000px",
          height: "100%",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={onCancel}
            sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 5, mb: 3, mx: 4 }}>
        <Typography variant="h5">
          <b>{window.getCTTranslatedText(title ?? "")}:</b>{" "}
          {window.getCTTranslatedText(nsTrafficConfigAction)}
        </Typography>
      </Stack>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Alert severity="warning" sx={{ mb: 3 }}>
          {window.getCTTranslatedText("AlertNsTrafficConfigWarningText", {
            AgentNsTrafficConfigSupportedMinVersion,
          })}
        </Alert>
        <Box sx={{ flex: 1, overflow: "hidden" }}>
          <DataGrid<Agent>
            checkboxSelection={userPermissions.has("UPGRADE_AGENT")}
            rowSelectionModel={selectionRowIds}
            onRowSelectionModelChange={selectionModel => {
              setSelectionRowIds(selectionModel);
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "agentstatus", sort: "desc" }],
              },
            }}
            rows={data}
            rowCount={rowCount}
            getRowId={({ agentId, asset }: Agent) =>
              `${agentId}-${asset?.assetId}`
            }
            columns={columns}
            isRowSelectable={data =>
              data.row?.asset?.type === AssetType.Server &&
              semver.gt(
                data.row?.currentVersion,
                AgentNsTrafficConfigSupportedMinVersion
              )
            }
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <ToolbarAction
          loading={nsTrafficConfigAgentsMutation.isLoading}
          save={() =>
            onConfirm({
              selectedItems,
              nsTrafficConfigAction: nsTrafficConfigAction ?? 0,
            })
          }
          cancel={onCancel}
          isValid={selectedItems?.length > 0}
          actionBtnText={"Confirm"}
        ></ToolbarAction>
      </DialogActions>
    </Drawer>
  );
};
