import { useQueryClient } from "@tanstack/react-query";
import { parseErrorMessage } from "common/utils";
import { useDeleteSavedQueryAPI } from "modules/saved-query/hooks/useSaveQueryAPI";
import { useSavedQueryStore } from "modules/saved-query/store";
import { SavedQuery } from "modules/saved-query/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";

export const useDeleteQuery = ({ query }: { query: SavedQuery }) => {
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const deleteQueryAPI = useDeleteSavedQueryAPI(query?.id);
  const setSavedQuery = useSavedQueryStore(state => state.setSavedQuery);
  const queryClient = useQueryClient();

  const deleteQuery = () => {
    deleteQueryAPI.mutate(
      {},
      {
        onSuccess: response => {
          setSavedQuery(undefined);
          queryClient.invalidateQueries({
            queryKey: ["savedsearch"],
          });
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            "QueryDeletedSuccessfully"
          );
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      }
    );
  };

  return {
    deleteQuery: deleteQuery,
    isLoading: deleteQueryAPI.isLoading,
  };
};
