import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Toolbar } from "common/atoms/toolbar";
import { NotificationConfig } from "./NotificationConfig";

interface NotificationConfigDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  alertRules: string[];
}

export const NotificationConfigDrawer = ({
  isOpen,
  onClose,
  alertRules,
}: NotificationConfigDrawerProps) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: { width: "70%", overflow: "hidden" },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 1, p: 3 }}
      >
        <Stack direction={"row"} alignItems="center">
          <Typography variant="h6">
            {window.getCTTranslatedText("Configure Notifications")}
          </Typography>
        </Stack>
        <IconButton size="small" aria-label="close drawer" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>

      <NotificationConfig alertRules={alertRules} onClose={onClose} />
    </Drawer>
  );
};
