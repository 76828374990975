import { IconsProps } from "./common";

export const DetailsIcon = ({
  width = "28px",
  height = "28px",
  color,
}: IconsProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.3301 7H13.3301V9H22.3301V7Z" fill={color} />
      <path d="M22.3301 15H13.3301V17H22.3301V15Z" fill={color} />
      <path d="M22.3301 11H16.3301V13H22.3301V11Z" fill={color} />
      <path
        d="M13.3301 12L8.33008 7V11H2.33008V13H8.33008V17L13.3301 12Z"
        fill={color}
      />
    </svg>
  );
};
