import { DataGrid } from "modules/data-grid/components/data-grid";
import { DESTINATION_IPS_COLUMNS } from "pages/paths/components/destination-ip-drawer/constants";
import { Path } from "pages/paths/types";

export interface DestinationIPDataGridProps {
  data: Path;
}

export const DestinationIPDataGrid = ({ data }: DestinationIPDataGridProps) => {
  return (
    <DataGrid
      rows={data?.dstIp ?? []}
      rowCount={data?.dstIp?.length ?? 0}
      columns={DESTINATION_IPS_COLUMNS}
      pagination
      paginationMode="client"
      sortingMode="client"
      getRowId={id => id}
    />
  );
};
