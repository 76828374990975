import { Stack, Grid } from "@mui/material";
import { Appliance } from "pages/appliances/types";
import { ApplianceDevices } from "../widgets/appliance-devices";
import { ApplianceDHCP } from "../widgets/appliance-dhcp";
import { ApplianceGeneral } from "../widgets/appliance-general";
import { ApplianceHA } from "../widgets/appliance-ha";
import { ApplianceInterfaces } from "../widgets/appliance-interfaces";
import { ApplianceStatus } from "../widgets/appliance-status";
import { ApplianceTopAlerts } from "../widgets/appliance-top-alerts";
import { ApplianceHealth } from "../widgets/appliance-health";

interface ApplianceSummaryProps {
  applianceName?: string;
  applianceDetails?: Appliance;
  applianceDetailsLoading?: boolean;
  setDeviceType?: (value: string) => void;
  canUpdate: boolean;
}

export const ApplianceSummary = ({
  applianceName,
  applianceDetails,
  applianceDetailsLoading,
  setDeviceType,
  canUpdate = false,
}: ApplianceSummaryProps) => {
  return (
    <Stack direction={"row"} sx={{ width: "100%", pb: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ApplianceGeneral
            appliance={applianceDetails}
            isLoading={applianceDetailsLoading}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ApplianceInterfaces
            appliance={applianceDetails}
            isLoading={applianceDetailsLoading}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ApplianceDevices
            appliance={applianceDetails}
            applianceName={applianceName}
            setDeviceType={setDeviceType}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ApplianceStatus
            appliance={applianceDetails}
            isLoading={applianceDetailsLoading}
            canUpdate={canUpdate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ApplianceDHCP
            appliance={applianceDetails}
            isLoading={applianceDetailsLoading}
            canUpdate={canUpdate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ApplianceHA
            appliance={applianceDetails}
            isLoading={applianceDetailsLoading}
            canUpdate={canUpdate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <ApplianceHealth
            appliance={applianceDetails}
            isLoading={applianceDetailsLoading}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
          <ApplianceTopAlerts appliance={applianceDetails} />
        </Grid>
      </Grid>
    </Stack>
  );
};
