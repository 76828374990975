import produce from "immer";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import { ScopeMetadata } from "modules/scope-metadata/types";
import { useEffect, useRef } from "react";
import { useQueryParam } from "use-query-params";
import { FacetUtils } from "../FacetUtils";
import {
  COMMON_FACETS,
  OPERATORS_LIST,
  OVERWRITE_FACETS_KEY,
} from "../constants";
import { FacetOptionState, FacetState, Operator } from "../types";

export const decodeThrowable = (
  facetString: string | (string | null)[] | null | undefined
): FacetState | undefined => {
  if (facetString && isArray(facetString)) {
    facetString = facetString.join("");
  }

  facetString = decodeURIComponent(facetString || "");
  const facets = facetString.split("|");
  const facetState: FacetState = new Map();

  facets.forEach(facet => {
    let [name, options] = ["", ""];
    let operator = Operator.EQUAL;

    for (const op of OPERATORS_LIST) {
      let [key, values] = facet.split(op);
      if (key && values) {
        operator = op;
        [name, options] = [key, values];
        break;
      }
    }

    const optionNames = options?.split(",");

    if (optionNames?.length) {
      const optionState: FacetOptionState = new Map();
      optionNames.forEach(option => {
        optionState.set(option, { isSelected: true, operator });
      });
      facetState.set(name, optionState);
    }
  });

  if (facetState.size > 0) return facetState;
  return undefined;
};

export const FacetQueryParam = {
  encode: (facets: FacetState | null | undefined): string | undefined => {
    if (facets) {
      return FacetUtils.getURLQueryFromFacets(facets);
    }
  },

  decode: (
    facetString: string | (string | null)[] | null | undefined
  ): FacetState | undefined => {
    try {
      return decodeThrowable(facetString);
    } catch (e) {
      console.error("Error parsing string", e);
      return;
    }
  },
};

export function useFacetQueryConnector(
  currentFacets: FacetState,
  metaData: ScopeMetadata | undefined,
  setFacets: (facetState: FacetState) => void,
  skipUseFacetQueryConnector?: boolean | undefined,
  urlKey: string = "filters"
) {
  const [queryFacets, setQueryFacets] = useQueryParam(urlKey, FacetQueryParam);
  const [overwriteCurrentFacets] = useQueryParam(OVERWRITE_FACETS_KEY);
  const queryRef = useRef<FacetState | undefined>(queryFacets);
  queryRef.current = queryFacets;

  const facetsAtInit = useRef(currentFacets);
  facetsAtInit.current = currentFacets;

  useEffect(() => {
    // Update query
    if (!currentFacets || skipUseFacetQueryConnector || !metaData) {
      return;
    }
    let cleanedFacets = cleanIfNotInMetadata(currentFacets, metaData);
    if (cleanedFacets !== currentFacets) {
      setFacets(cleanedFacets);
    }
    setQueryFacets((cleanedFacets?.size ?? 0) > 0 ? cleanedFacets : undefined);
  }, [
    currentFacets,
    metaData,
    setFacets,
    setQueryFacets,
    skipUseFacetQueryConnector,
  ]);

  useEffect(() => {
    if (!queryRef.current || skipUseFacetQueryConnector || !metaData) {
      return;
    }

    let facets: FacetState = new Map(queryRef.current);

    if (overwriteCurrentFacets) {
      const pathLastObserved = facetsAtInit.current?.get("pathlastobserved");
      if (pathLastObserved) {
        facets?.set("pathlastobserved", pathLastObserved);
      }
      facets = cleanIfNotInMetadata(facets, metaData);
      setFacets(facets);
      return;
    }

    if (facetsAtInit.current && facetsAtInit.current?.size > 0) {
      facetsAtInit.current?.forEach((value, key) => {
        if (
          !facets?.get(key) &&
          (COMMON_FACETS[key] ||
            key === "assetname" ||
            Boolean(metaData.columns[key]) ||
            Boolean(metaData.namesToColumn[key]))
        ) {
          facets?.set(key, value);
        }
      });
    }
    facets = cleanIfNotInMetadata(facets, metaData);

    if (!isEqual(facets, facetsAtInit.current)) {
      setFacets(facets);
    }
  }, [setFacets, metaData, skipUseFacetQueryConnector, overwriteCurrentFacets]);
}

function cleanIfNotInMetadata(
  facets: FacetState,
  metaData: ScopeMetadata
): FacetState {
  if (!facets) {
    return facets;
  }
  let toDelete: string[] = [];
  facets.forEach((_, key) => {
    if (!metaData.columns[key] && !metaData.namesToColumn?.[key]) {
      toDelete.push(key);
    }
  });
  if (!toDelete?.length) {
    return facets;
  }
  return produce(facets, draft => {
    toDelete.forEach(key => draft.delete(key));
    return draft;
  });
}
