import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";

import { getColor } from "common/constants/colors";
import { reduceMaps } from "common/utils/mapUtils";
import { useAssetAggregateAPI } from "modules/recommendation-workflows/PathRecommendationDrawer";
import { Scope } from "modules/scope-metadata/types";
import numeral from "numeral";
import { PathStatus } from "pages/paths/types";
import { PathStatusMap } from "pages/traffic-visualizer/types";
import { getPathStatusBreakdown } from "pages/traffic-visualizer/visx-utils";
import { useEffect } from "react";

const btnStyle = {
  borderRadius: 0,
  minHeight: "60px",
  align: "left",
};

const DIALOG_WIDGET_CONTENT_WIDTH = "700px";

export interface EdgeCountBreakdownProps {
  title: string;
  open: boolean;
  handleClose: VoidFunction;
  trafficCriteria?: string;
  sourceCriteria?: string;
  destinationCriteria?: string;
  pathReviewState: string;
}

export const EdgeCountBreakdown = ({
  title,
  open,
  handleClose,
  trafficCriteria,
  destinationCriteria,
  sourceCriteria,
  pathReviewState,
}: EdgeCountBreakdownProps) => {
  const mutation = useAssetAggregateAPI();
  const mutate = mutation.mutate;
  const { data: aggData, isLoading } = mutation;

  useEffect(() => {
    if (!trafficCriteria || !sourceCriteria || !destinationCriteria || !open) {
      return;
    }

    mutate({
      criteria: trafficCriteria,
      destinationCriteria,
      groupBy: [pathReviewState],
      scope: Scope.Path,
      sourceCriteria,
      statistics: [`count(${pathReviewState})`],
    });
  }, [
    destinationCriteria,
    mutate,
    pathReviewState,
    sourceCriteria,
    trafficCriteria,
    open,
  ]);

  let map: PathStatusMap = new Map();

  const results = aggData?.items?.[pathReviewState] ?? aggData?.items ?? {};
  Object.keys(results)?.forEach(assetStatus => {
    const value = Number(
      // @ts-ignore
      results[assetStatus].statistics?.[`${pathReviewState}count`] ?? 0,
    );
    const assetStatusMap: PathStatusMap = new Map([
      [assetStatus as PathStatus, value],
    ]);
    map = reduceMaps([map, assetStatusMap]);
  });

  const breakdownData = getPathStatusBreakdown(map);

  const keysToFilter = [
    "total",
    "totalAllowed",
    "totalAllowedByTest",
    "totalUnreviewed",
    "totalDenied",
  ];
  const data = filterObjectKeys(breakdownData, keysToFilter);

  const formattedText = (value: number = 0) => {
    if (data?.total) {
      return (value / data.total) * 100;
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={title}
      sx={{ width: "100%", height: "100%" }}
      maxWidth="lg"
    >
      <DialogTitle sx={{ m: 0, p: 2, px: 4 }} id="dialog-title">
        {window.getCTTranslatedText(title)}
      </DialogTitle>
      <IconButton
        aria-label="close dialog"
        onClick={handleClose}
        size="medium"
        sx={{
          position: "absolute",
          right: "16px",
          top: "8px",
          zIndex: 2,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers={true}
        sx={{
          width: DIALOG_WIDGET_CONTENT_WIDTH,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          justifyItems="center"
          sx={{ width: "100%", height: "100%" }}
        >
          {isLoading ? (
            <Stack
              sx={{ minHeight: 210 }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <Stack
              direction="row"
              alignItems="flex-start"
              sx={{ pb: 3, pt: 0, width: "100%" }}
            >
              <Stack direction="column" sx={{ width: "100%" }} spacing={4}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Stack alignItems="flex-start">
                    <Tooltip
                      title={`${window.getCTTranslatedText(
                        "Total Paths",
                      )}: ${numeral(data?.total).format("0a")}`}
                    >
                      <DataGridButton color="inherit" sx={btnStyle}>
                        <Typography
                          variant={"body2"}
                          sx={{
                            textTransform: "capitalize",
                            textAlign: "left",
                          }}
                        >
                          {window.getCTTranslatedText(
                            (data?.label ?? "Total Paths").toString(),
                          )}
                        </Typography>
                        <Typography variant="h5" sx={{ textAlign: "left" }}>
                          {numeral(data?.total || 0).format("0a")}
                        </Typography>
                      </DataGridButton>
                    </Tooltip>
                  </Stack>
                </Stack>

                {(Object.entries(data) ?? []).map(([key, value], index) => {
                  if (key === "total") {
                    return null;
                  }
                  return (
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="flex-start"
                      key={`-${index}`}
                      sx={{
                        width: "100%",
                        cursor: "pointer",
                        px: 1,
                      }}
                    >
                      <Grid container columnSpacing={4}>
                        <Grid item xs={2.5}>
                          <Stack
                            alignItems="flex-start"
                            justifyContent="flex-start"
                          >
                            <Typography
                              variant="body2"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {window.getCTTranslatedText(key)}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={0.5}>
                          <Stack
                            alignItems="flex-start"
                            justifyContent="flex-start"
                          >
                            <Typography variant="body2">
                              {numeral(value).format("0a")}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={9}>
                          <Stack
                            sx={{ width: "100%", py: 1 }}
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <LinearProgress
                              variant="determinate"
                              value={formattedText(value ?? 0) ?? 0}
                              sx={{
                                width: "100%",
                                height: 9,
                                borderRadius: 5,
                                [`&.${linearProgressClasses.colorPrimary}`]: {
                                  backgroundColor: theme =>
                                    theme.palette.grey[
                                      theme.palette.mode === "dark" ? 800 : 200
                                    ],
                                },
                                [`& .${linearProgressClasses.bar}`]: {
                                  borderRadius: 5,
                                  backgroundColor: getColor(key.toLowerCase()),
                                },
                              }}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

function filterObjectKeys(
  inputObject: { [key: string]: number | boolean },
  keysToFilter: string[],
) {
  const filteredObject: { [key: string]: number } = {};
  keysToFilter.forEach((key: string) => {
    const value = inputObject[key];
    if (inputObject.hasOwnProperty(key) && typeof value === "number") {
      filteredObject[
        key === "total" ? key : key.replaceAll("total", "").toLocaleLowerCase()
      ] = value;
    }
  });
  return filteredObject;
}
