import { Box, Stack, Tooltip } from "@mui/material";
import { DataGridButton } from "common/atoms/data-grid-button";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AssignNetworksDrawer } from "modules/assign-networks-to-assets/components/assign-networks-drawer";
import { VisxAttributesBlock } from "modules/attributes-block";
import { PathRecommendationDrawer } from "modules/recommendation-workflows";
import { PathRecommendationPageType } from "modules/recommendation-workflows/PathRecommendationDrawer";
import numeral from "numeral";
import { useState } from "react";
import { TagPolicy } from "../../types";
import { AssignedNetworksTags } from "../tag-networks/components/assigned-networks-tags";
export function NetworkAssignments({
  policy,
  viewOnly = false,
  asCard = false,
  onUpdate,
}: {
  policy?: TagPolicy;
  viewOnly?: boolean;
  asCard?: boolean;
  onUpdate?: VoidFunction;
}) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const [showRecommendation, setShowRecommendation] = useState(false);
  const [showAssignNew, setShowAssignDrawer] = useState(false);

  const [showAssignedNetworks, setShowAssignedNetworks] =
    useState<boolean>(false);

  if (!asCard && !policy?.tagBasedPolicyId) {
    return <Box style={{ minWidth: 120, minHeight: 45 }} />;
  }

  const open = () => {
    setShowAssignedNetworks(true);
  };

  const onClose = () => {
    setShowAssignedNetworks(false);
  };

  if (asCard) {
    return (
      <>
        <VisxAttributesBlock
          isLoading={false}
          title="Named Networks"
          count={policy?.namednetworksAssigned ?? 0}
          canAssign={Boolean(
            userPermissions.has("UPDATE_ASSET") &&
              userPermissions.has("UPDATE_NAMED_NETWORK")
          )}
          btnTitle="Assign Named Networks"
          showAssigned={open}
          assign={() => setShowAssignDrawer(true)}
          recommend={() => {
            setShowRecommendation(true);
          }}
        />

        <AssignNetworksDrawer
          isOpen={showAssignNew}
          page={"tags"}
          title="Assign Named Network"
          rules={[]}
          onCancel={() => setShowAssignDrawer(false)}
          onConfirm={() => {
            setShowAssignDrawer(false);
            onUpdate?.();
          }}
          btnTitle={"assign"}
          criteria={policy?.criteria}
          multiselect={true}
          policyId={policy?.tagBasedPolicyId}
          hasPermission={userPermissions.has("UPDATE_TAGBASEDPOLICY")}
        />

        {showRecommendation && (
          <PathRecommendationDrawer
            isOpen={true}
            onClose={() => {
              setShowRecommendation(false);
              onUpdate?.();
            }}
            criteria={policy?.criteria || "*"}
            policyId={policy?.tagBasedPolicyId}
            page={PathRecommendationPageType.Tags}
          />
        )}

        {showAssignedNetworks && (
          <AssignedNetworksTags
            criteria={policy?.criteria}
            multiselect={true}
            policyId={policy?.tagBasedPolicyId}
            open={showAssignedNetworks}
            onClose={onClose}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Stack
        sx={{ maxWidth: "100%" }}
        alignItems="left"
        justifyContent="center"
      >
        <Tooltip
          title={window.getCTTranslatedText("View Assigned Named Networks")}
        >
          <DataGridButton
            asText={viewOnly === true}
            disabled={viewOnly === true}
            variant={"text"}
            color={"primary"}
            onClick={open}
          >
            {numeral(policy?.namednetworksAssigned ?? 0).format("0,0")}
          </DataGridButton>
        </Tooltip>
      </Stack>

      {showAssignedNetworks && (
        <AssignedNetworksTags
          criteria={policy?.criteria}
          multiselect={true}
          policyId={policy?.tagBasedPolicyId}
          open={showAssignedNetworks}
          onClose={onClose}
        />
      )}
    </>
  );
}
