import { Box, Stack, SvgIconProps, Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import green from "@mui/material/colors/green";
import { UnmanagedDevicesColumnRenderProps } from "../../appliance-unmanaged-devices/types";
import { DeviceSegmentationLevels } from "pages/appliance-detail/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

const iconStyle = { margin: "3px 0 3px 6px" };

export function UnmanagedDeviceAvailability({
  device,
  viewOnly,
}: UnmanagedDevicesColumnRenderProps) {
  if (!device?.deviceId) {
    return <Box style={{ width: 70 }} />;
  }

  let icon: React.ReactElement<SvgIconProps> | undefined;
  switch (device?.deviceAvailability) {
    case DeviceSegmentationLevels.Active:
      icon = <CircleIcon style={{ ...iconStyle, color: green[600] }} />;
      break;
    case DeviceSegmentationLevels.Connected:
      icon = <CircleIcon style={{ ...iconStyle, color: green[200] }} />;
      break;
    default:
      icon = <CircleIcon color={"disabled"} style={iconStyle} />;
  }

  return (
    <Stack
      sx={{ maxWidth: "100%" }}
      alignItems="center"
      justifyContent="center"
      direction="row"
    >
      <Tooltip
        title={window.getCTTranslatedText(device?.deviceAvailability ?? "")}
      >
        <CTDatagridButton
          startIcon={device?.deviceAvailability ? icon : undefined}
          asText={viewOnly || !device?.deviceAvailability}
          isDisabled={viewOnly || !device?.deviceAvailability}
          facetName={"deviceavailability"}
          facetOption={device?.deviceAvailability ?? ""}
        >
          {window.getCTTranslatedText(device?.deviceAvailability ?? "")}
        </CTDatagridButton>
      </Tooltip>
    </Stack>
  );
}
