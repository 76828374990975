import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { MergedIPsForInboundAndOutBound } from "../../IPsDataGrid";
import { Chip, Stack } from "@mui/material";
import {
  CheckboxSelectionStore,
  CheckboxSelectionType,
} from "modules/recommendation-workflows/components/IPs-datagrid/store/types";
import { useShallow } from "zustand/react/shallow";

interface CIDRProps
  extends GridRenderCellParams<any, MergedIPsForInboundAndOutBound, any> {
  useStore: CheckboxSelectionStore;
}

export const CIDR = (props: CIDRProps) => {
  const rowID = props.id as string;
  const rowSelection = props.useStore(
    useShallow(state => state.selection[rowID])
  );
  const hierarchy = props.row.path;
  const isSubnetRow = rowID.includes("subnet");
  const isSubnetSelected =
    rowSelection?.selectionType === CheckboxSelectionType.SUBNET;

  const isObservedIPs =
    rowSelection?.selectionType === CheckboxSelectionType.ALL_IPS;

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Stack>{hierarchy?.[hierarchy.length - 1]}</Stack>
      {isSubnetRow && isObservedIPs && (
        <Chip
          sx={{ ml: 2 }}
          color="info"
          label={window.getCTTranslatedText("Observed IPs")}
        />
      )}

      {isSubnetRow && isSubnetSelected && (
        <Chip
          sx={{ ml: 2 }}
          color="info"
          label={window.getCTTranslatedText("Entire Subnet")}
        />
      )}
    </Stack>
  );
};
