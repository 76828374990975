import { Suggestion } from "modules/ct-scope-auto-suggest/types";
import { NamedNetwork, PathDirection, PathStatus } from "pages/paths/types";
import { PortStatus } from "pages/ports/types";
import {
  Template,
  TemplateFormProps,
  TemplateType,
} from "pages/templates/types";

// template form

export enum TemplateFieldKeys {
  Name = "templateName",
  Description = "templateDescription",
  TemplateType = "templateType",
}

export const RequiredTemplateFields: { [key: string]: boolean } = {
  [TemplateFieldKeys.Name]: true,
};

export const TemplateFieldDisplayText = {
  [TemplateFieldKeys.Name]: "Name",
  [TemplateFieldKeys.Description]: "Description",
  [TemplateFieldKeys.TemplateType]: "Type",
};

export const TemplateFieldOptions: Array<TemplateFieldKeys> = [
  TemplateFieldKeys.Name,
  TemplateFieldKeys.Description,
];

export enum TemplateFormMode {
  CREATE = "create",
  EDIT = "edit",
  ADD = "add",
}

export interface TemplateCreateFormProps {
  template?: TemplateFormProps | Template | undefined;
  title?: string;
  updateTemplateData: Function;
  onClose: () => void;
  mode: TemplateFormMode;
  btnTitle?: string;
  backButtonEnable?: boolean;
}

// port form

export enum PortFieldKeys {
  Port = "listenPort",
  Protocol = "listenPortProtocol",
  Status = "listenPortReviewed",
  Process = "listenProcessNames",
}

export const PortFieldDisplayText = {
  [PortFieldKeys.Port]: "Port",
  [PortFieldKeys.Protocol]: "Protocol",
  [PortFieldKeys.Status]: "Status",
  [PortFieldKeys.Process]: "Process",
};

export const RequiredPortFields: { [key: string]: boolean } = {
  [PortFieldKeys.Port]: true,
  [PortFieldKeys.Protocol]: true,
  [PortFieldKeys.Status]: true,
  [PortFieldKeys.Process]: false,
};

export const PortOptions: Array<PortFieldKeys> = [PortFieldKeys.Port];

export interface PortFormProps {
  port: PortFormInt;
  templateType?: TemplateType;
  updatePortData: Function;
  showLabel?: boolean;
  hideSource?: boolean;
  hideProcess?: boolean;
}

export interface PortFormInt {
  lpId?: string;
  listenPort: string;
  listenPortProtocol: string;
  listenPortReviewed: PortStatus | string;
  listenProcessNames: string;
}

export interface PortRuleInt extends PortFormInt {}

export const defaultPortObj: PortFormInt = {
  [PortFieldKeys.Port]: "",
  [PortFieldKeys.Protocol]: "",
  [PortFieldKeys.Status]: "",
  [PortFieldKeys.Process]: "",
};

export const defaultBlockPortObj: PortFormInt = {
  [PortFieldKeys.Port]: "",
  [PortFieldKeys.Protocol]: "",
  [PortFieldKeys.Process]: "",
  [PortFieldKeys.Status]: PortStatus.Deny,
};

export enum EphimeralPortList {
  EPHIMERAL = "ephp",
  RPCEPHIMERAL = "rpcephp",
}

export enum PortList {
  ANY = "any",
}

export const EphimeralPorts: { [key: string]: boolean } = {
  [EphimeralPortList.EPHIMERAL]: true,
  [EphimeralPortList.RPCEPHIMERAL]: true,
};

export const AllowedPorts: { [key: string]: boolean } = {
  ...EphimeralPorts,
  [PortList.ANY]: true,
};

// path form

export enum PathFieldKeys {
  Port = "port",
  Protocol = "protocol",
  Direction = "direction",
  Source = "source",
  Destination = "destination",
  TemplateCategory = "templatecategory",
}

export const PathDirectionFieldKeys: { [key: string]: boolean } = {
  [PathFieldKeys.Source]: true,
  [PathFieldKeys.Destination]: true,
};

export const PathFieldDisplayText = {
  [PathFieldKeys.Port]: "Port",
  [PathFieldKeys.Protocol]: "Protocol",
  [PathFieldKeys.Direction]: "Direction",
  [PathFieldKeys.Source]: "Source",
  [PathFieldKeys.Destination]: "Destination",
  [PathFieldKeys.TemplateCategory]: "Category",
};

export interface PathDirectionListProps extends Suggestion {}

export const DefaultPathFields: { [key: string]: boolean } = {
  [PathFieldKeys.Protocol]: true,
  [PathFieldKeys.Port]: true,
};

export const MapPathFields: { [key: string]: { [key: string]: boolean } } = {
  [PathDirection.Inbound]: {
    ...DefaultPathFields,
    [PathFieldKeys.Source]: true,
  },
  [PathDirection.Outbound]: {
    ...DefaultPathFields,
    [PathFieldKeys.Destination]: true,
  },
};

export const PathOptions: Array<PathFieldKeys> = [PathFieldKeys.Port];

export const PathDirectionOptions: Array<PathFieldKeys> = [
  PathFieldKeys.Source,
  PathFieldKeys.Destination,
];

export interface PathBaseInt {
  channelHash?: string;
  port: string;
  protocol: string;
  direction: PathDirection | string;
}

export interface PathFormInt extends PathBaseInt {
  source?: Suggestion | string | undefined;
  destination?: Suggestion | string | undefined;
  templatecategory: string;
}

export interface TagBasedPolicy {
  tagBasedPolicyId: string;
  tagBasedPolicyName: string;
  criteria: string;
  criteriaAsParams: string;
}

export interface PathRuleInt extends PathBaseInt {
  reviewed: PathStatus | string;
  srcIp?: string;
  sourceNamedNetwork?: NamedNetwork;
  sourceAssetId?: string;
  destinationAssetId?: string;
  sourceTagBasedPolicy?: Partial<TagBasedPolicy>;
  destinationTagBasedPolicy?: Partial<TagBasedPolicy>;
  dstIp?: string[];
  domain?: string;
  destinationNamedNetwork?: NamedNetwork;
  namedNetwork?: NamedNetwork;
  ip?: string;
}

export interface PathRuleDirectionInt {
  ip?: string;
  namedNetwork?: NamedNetwork;
  domain?: string;
  srcIp?: string;
  sourceNamedNetwork?: NamedNetwork;
  dstIp?: string[];
  destinationNamedNetwork?: NamedNetwork;
}

export enum PathRuleDirection {
  srcIp = "srcIp",
  sourceNamedNetwork = "sourceNamedNetwork",
  sourceAssetId = "sourceAssetId",
  sourceTagBasedPolicy = "sourceTagBasedPolicy",
  destIp = "dstIp",
  domain = "domain",
  destinationNamedNetwork = "destinationNamedNetwork",
  destinationAssetId = "destinationAssetId",
  destinationTagBasedPolicy = "destinationTagBasedPolicy",
  namedNetwork = "namedNetwork",
  ip = "ip",
}

export interface InboundPathRuleInt extends PathBaseInt {
  reviewed: PathStatus | string;
  srcIp?: string;
  sourceNamedNetwork?: NamedNetwork;
}
export interface OutboundPathRuleInt extends PathBaseInt {
  reviewed: PathStatus | string;
  dstIp?: string[];
  domain?: string;
  destinationNamedNetwork?: NamedNetwork;
}

export interface PathFormProps {
  path: PathFormInt;
  templateType: TemplateType;
  updatePathData: Function;
  showLabel?: boolean;
}

export const defaultPathObj: PathFormInt = {
  [PathFieldKeys.Port]: "",
  [PathFieldKeys.Protocol]: "",
  [PathFieldKeys.Direction]: PathDirection.Inbound,
  [PathFieldKeys.TemplateCategory]: "templatecategory",
};

export const defaultInboundPathObj: PathFormInt = {
  ...defaultPathObj,
  [PathFieldKeys.Source]: undefined,
  [PathFieldKeys.Direction]: PathDirection.Inbound,
};

export const defaultOutboundPathObj: PathFormInt = {
  ...defaultPathObj,
  [PathFieldKeys.Destination]: undefined,
  [PathFieldKeys.Direction]: PathDirection.Outbound,
};

export const FacetableFields: { [key: string]: boolean } = {
  [PortFieldKeys.Port]: true,
  [PortFieldKeys.Protocol]: true,
  [PortFieldKeys.Process]: true,
  [PathFieldKeys.Port]: true,
  [PathFieldKeys.Protocol]: true,
  [PathFieldKeys.Source]: true,
  [PathFieldKeys.Destination]: true,
  [PathFieldKeys.TemplateCategory]: true,
};

export const DropdownFields: { [key: string]: boolean } = {
  // [PortFieldKeys.Protocol]: true,
  // [PathFieldKeys.Protocol]: true,
};

enum ProtocolList {
  // ANY = "any",
  ICMP = "ICMP",
  IGMP = "IGMP",
  IPV6_ICMP = "IPV6-ICMP",
  TCP = "TCP",
  UDP = "UDP",
}

export const ProtocolOptionsList = [
  // ProtocolList.ANY,
  ProtocolList.ICMP,
  ProtocolList.IGMP,
  ProtocolList.IPV6_ICMP,
  ProtocolList.TCP,
  ProtocolList.UDP,
];

export enum PortFormHelperText {
  Process = "processHelperText",
  ProcessError = "processError",
}

export interface TemplateFormData {
  templateName: string;
  templateDescription: string;
  templateCategory: string | undefined;
  templateType: TemplateType;
}
