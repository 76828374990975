import { alpha, useTheme } from "@mui/material";
import { CtSilderLabel } from "modules/ct-slider";

interface SecuritySliderProps<T> {
  securityStatus: T;
  currentSecurityStatus: T;
  label: string;
  isSimulateStatus?: boolean;
  isMinimum?: boolean;
  ml?: number;
  mr?: number;
  px?: number;
  justifyContent: string;
  renderIcon: (color: string) => JSX.Element;
}

export const SecuritySliderLabel = <T,>({
  securityStatus,
  currentSecurityStatus,
  label,
  isSimulateStatus = false,
  isMinimum,
  ml,
  mr,
  px,
  justifyContent,
  renderIcon,
}: SecuritySliderProps<T>) => {
  const theme = useTheme();

  const isActive = securityStatus === currentSecurityStatus;

  const activeColor = `${
    isSimulateStatus ? theme.palette.warning.main : theme.palette.primary.main
  }`;
  const nonActiveColor = alpha(
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black,
    0.4
  );

  const nonActiveTextColor = alpha(
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.common.black,
    0.4
  );

  const activeBackground = `${
    isSimulateStatus ? theme.palette.warning.main : theme.palette.primary.main
  }`;

  return (
    <CtSilderLabel
      justifyContent={justifyContent}
      renderIcon={renderIcon}
      color={isActive ? activeColor : nonActiveColor}
      textColor={
        isActive ? theme.palette.primary.contrastText : nonActiveTextColor
      }
      background={isActive ? activeBackground : "transparent"}
      text={window.getCTTranslatedText(label)}
      ml={ml}
      mr={mr}
      px={isActive ? 2 : 0}
      isMinimum={isMinimum}
    />
  );
};
