import { IconsProps } from "./common";

export const ServiceRestartIcon = ({
  width = "28px",
  height = "28px",
  color,
}: IconsProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3651 8.88708C12.0571 8.35194 12.8292 7.91539 13.6603 7.59846V5.49413C13.6177 5.50705 13.5752 5.52021 13.5328 5.53362C12.2057 5.95378 10.9882 6.6188 9.93311 7.47572L11.3651 8.88708ZM10.3207 9.83363L8.61861 8.73691C8.57645 8.78464 8.5347 8.83274 8.49337 8.8812C7.82347 9.66655 7.26227 10.5476 6.83284 11.501L8.7373 12.1431C9.14355 11.2907 9.6799 10.5124 10.3207 9.83363ZM8.2099 13.5389L6.29488 12.9669C6.01573 13.9369 5.86621 14.9621 5.86621 16.0224H7.86622C7.86621 16.0185 7.86621 16.0146 7.86621 16.0107C7.86621 15.1535 7.98606 14.3243 8.2099 13.5389Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.09961 23.7931C11.0946 25.7868 13.8326 27.0243 16.8732 27.0243C22.9545 27.0243 27.8663 22.1018 27.8663 16.0243C27.8663 11.1442 24.6992 7.00875 20.3027 5.56872C20.1514 5.51915 19.9986 5.47278 19.8444 5.42969V7.51704C23.3514 8.74634 25.8663 12.0858 25.8663 16.0126C25.8663 20.9832 21.8369 25.0126 16.8663 25.0126C14.3848 25.0126 12.1378 24.0083 10.5097 22.3839L9.09961 23.7931Z"
        fill={color}
      />
      <rect x="15.8662" y="3.80078" width="2" height="9.77778" fill={color} />
      <path
        d="M5.97613 18.0298L14.8917 20.439L7.00582 27.2075L5.97613 18.0298Z"
        fill={color}
      />
    </svg>
  );
};
