import { Box, Link, Stack, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import numeral from "numeral";
import { NetworkColumnRenderProps } from "pages/networks/types";
import pluralize from "pluralize";

export function UsedByTagBasedPolicy({
  network,
  viewOnly,
}: NetworkColumnRenderProps) {
  if (!network.namedNetworkId) {
    return <Box style={{ minWidth: 120, minHeight: 45 }} />;
  }

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="flex-start">
      <Stack alignItems="flex-start">
        {network?.namednetworkTagBasedPolicyAssignments === 0 ? (
          <Typography variant="body2">
            {numeral(
              network?.namednetworkTagBasedPolicyAssignments || 0
            ).format("0a")}{" "}
            {`${window.getCTTranslatedText(
              pluralize(
                "policy",
                Number(network?.namednetworkTagBasedPolicyAssignments)
              )
            )}`}
          </Typography>
        ) : (
          <Tooltip title={window.getCTTranslatedText("View policy")}>
            <Link
              typography={"body2"}
              component={RouterLink}
              to={`/tags?tab=policy&filters=${encodeURIComponent(
                `namednetworkname=${network.namedNetworkName}`
              )}`}
              sx={{ color: "primary.main" }}
            >
              {numeral(
                network?.namednetworkTagBasedPolicyAssignments || 0
              ).format("0a")}{" "}
              {`${window.getCTTranslatedText(
                pluralize(
                  "policy",
                  Number(network?.namednetworkTagBasedPolicyAssignments)
                )
              )}`}
            </Link>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
