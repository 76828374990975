import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { getOptionLabel } from "modules/facets/utils";
import { FacetOptionUnit } from "modules/scope-metadata/types";
import { useEffect } from "react";

export const TIME_FILTER_OPTIONS = [
  "0 - 1",
  "0 - 6",
  "0 - 24",
  "0 - 168",
  "0 - 336",
  "0 - 720",
];

export const DEFAULT_TIME_FILTER = "0 - 1";

interface TimeFilterProps {
  options?: string[];
  defaultTime?: string;
  selectedTime: string;
  setSelectedTime: (value: string) => void;
}

export function TimeFilter({
  options = TIME_FILTER_OPTIONS,
  defaultTime = DEFAULT_TIME_FILTER,
  selectedTime,
  setSelectedTime,
}: TimeFilterProps) {
  const onChange = (e: SelectChangeEvent) => {
    const time = e.target.value;
    if (!time || time === "none" || time === "") {
      return;
    }
    setSelectedTime(e.target.value);
  };

  const getValidSelectedTime = () => {
    if (!options.includes(selectedTime)) {
      setSelectedTime(defaultTime);
      return undefined;
    }

    return selectedTime ?? defaultTime;
  };

  useEffect(() => {
    if (!options.includes(selectedTime)) {
      setSelectedTime(defaultTime);
    }
  }, [selectedTime, defaultTime, options, setSelectedTime]);

  return (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems="center"
      flexWrap={"wrap"}
      sx={{ minWidth: 160 }}
    >
      <FormControl
        variant="standard"
        sx={{ width: "fit-content", minWidth: 150, m: 0, mt: 1 }}
      >
        <Select
          size="medium"
          defaultValue={defaultTime ?? options[options.length - 1]}
          value={getValidSelectedTime()}
          placeholder={window.getCTTranslatedText("Select Timeframe")}
          onChange={onChange}
        >
          {(!selectedTime ||
            selectedTime === "none" ||
            selectedTime === "") && (
            <MenuItem value={"none"} disabled>
              {window.getCTTranslatedText("Select Timeframe")}
            </MenuItem>
          )}

          {options.map(option => {
            return (
              <MenuItem key={option} value={option}>
                {window.getCTTranslatedText(
                  getOptionLabel(option, FacetOptionUnit.Hour)
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
}
