import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";
import { useCommonStore } from "common/store";
import { AssetNameInternal } from "pages/assets/components/asset-name-internal";
import { AssetType } from "pages/assets/types";
import { PathColumnRenderProps, PathDirection } from "pages/paths/types";
import { DestinationIPDrawer } from "../destination-ip-drawer/DestinationIPDrawer";
import { PathNnHits } from "../path-nn-hits";
import { PathUserGroup } from "../path-user-groups/PathUserGroup";

export function PathDestination({
  path,
  viewOnly,
  asLink,
  useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
  if (!path.channelHash) {
    return <Box style={{ width: 80, minHeight: 45 }} />;
  }

  // const renderRemainingIpsTooltip = (remainingIps: string[]) => {
  //   return (
  //     <Stack>
  //       {remainingIps.map((ip, index, values) => {
  //         const isIpSelected =
  //           !viewOnly && facetState?.get("dstip")?.get(ip)?.isSelected;

  //         return (
  //           <DataGridButton
  //             key={`ip-${index}`}
  //             disabled={viewOnly === true}
  //             asText={viewOnly === true}
  //             sx={{
  //               minWidth:
  //                 index === values.length - 1 ? undefined : "fit-content",
  //             }}
  //             variant={isIpSelected ? "outlined" : "text"}
  //             color={isIpSelected ? "primary" : "inherit"}
  //             onClick={() => {
  //               updateFacetOption({
  //                 facetName: "dstip",
  //                 optionName: ip,
  //                 value: !isIpSelected,
  //               });
  //             }}
  //           >
  //             {ip}
  //           </DataGridButton>
  //         );
  //       })}
  //     </Stack>
  //   );
  // };

  const ipView = (showHeader: boolean) => {
    if (!path.dstIp?.length) {
      return null;
    }

    const primaryIp = path.dstIp?.[0];
    const remainingIps = path.dstIp?.slice(1);
    // const isAnyRemainingIpSelected = remainingIps.some(
    //   ip => !viewOnly && facetState?.get("dstip")?.get(ip)?.isSelected
    // );

    return (
      <>
        {primaryIp && showHeader && <Typography variant="body2">-</Typography>}

        {primaryIp && (
          <Tooltip title={primaryIp ?? ""} key={`tooltip-ip-${primaryIp}`}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <CTDatagridButton
                isDisabled={viewOnly === true}
                asText={viewOnly === true}
                sx={{
                  minWidth: remainingIps.length ? "fit-content" : undefined,
                }}
                useFacetStore={useFacetStore}
                facetName={"dstip"}
                facetOption={primaryIp ?? " "}
              >
                {primaryIp}
              </CTDatagridButton>
            </span>
          </Tooltip>
        )}
        {path.dstIp.length > 1 && <DestinationIPDrawer path={path} />}

        {/* {remainingIps.length > 0 && (
          <Tooltip
            title={renderRemainingIpsTooltip(remainingIps)}
            placement="bottom-start"
            arrow={false}
          >
            <Chip
              label={`+${remainingIps.length}`}
              color={isAnyRemainingIpSelected ? "primary" : "default"}
              variant="outlined"
            />
          </Tooltip>
        )} */}
      </>
    );
  };

  const renderContent = () => {
    if (
      path.destinationNamedNetwork &&
      path.destinationNamedNetwork.namedNetworkId &&
      path?.destinationNamedNetwork?.namedNetworkName !== ""
    ) {
      return (
        <Stack direction="row" alignItems={"center"}>
          <Tooltip
            title={
              viewOnly
                ? ""
                : `${path?.destinationNamedNetwork?.namedNetworkName}`
            }
          >
            <CTDatagridButton
              linkTo={
                asLink
                  ? `/networks?networkId=${path.destinationNamedNetwork.namedNetworkId}`
                  : undefined
              }
              asText={viewOnly === true}
              isDisabled={viewOnly === true}
              sx={{ width: "fit-content" }}
              useFacetStore={useFacetStore}
              facetName={"namednetworkname"}
              facetOption={
                path?.destinationNamedNetwork?.namedNetworkName || ""
              }
            >
              {path.destinationNamedNetwork?.namedNetworkName}
            </CTDatagridButton>
          </Tooltip>
          {path.sourceAsset && (
            <PathNnHits
              direction={PathDirection.Outbound}
              nn={path.destinationNamedNetwork}
              asset={path.sourceAsset}
              port={path.port}
              protocol={path.protocol}
            />
          )}
        </Stack>
      );
    }

    if (
      path?.destinationAsset &&
      path.destinationAsset?.assetName?.length > 0
    ) {
      return (
        <>
          <Stack direction="row" alignItems={"center"}>
            <Tooltip
              title={
                viewOnly
                  ? ""
                  : window.getCTTranslatedText("TooltipShowOnlyForAssetName", {
                      name: path.destinationAsset.assetName,
                    })
              }
            >
              <CTDatagridButton
                linkTo={
                  asLink
                    ? `/assets/${path.destinationAsset.assetId}`
                    : undefined
                }
                asText={viewOnly === true}
                isDisabled={viewOnly === true}
                sx={{ width: "fit-content" }}
                useFacetStore={useFacetStore}
                facetName="assetname"
                facetOption={path.destinationAsset?.assetName!}
              >
                {AssetNameInternal({ asset: path?.destinationAsset })}
              </CTDatagridButton>
            </Tooltip>

            {ipView(true)}
            {path.destinationAsset.type === AssetType.UserGroup && (
              <PathUserGroup
                title={`${AssetNameInternal({
                  asset: path?.destinationAsset,
                })} usergroup hits`}
                path={path}
                userGroupType={"destination"}
                usergrouphitusergroupid={path.destinationAsset.assetId}
              />
            )}
          </Stack>
        </>
      );
    }

    if (path?.domain?.length > 0) {
      return (
        <Stack direction="row" alignItems={"center"}>
          <Tooltip title={path.domain}>
            <CTDatagridButton
              isDisabled={viewOnly === true}
              asText={viewOnly === true || !path.domain}
              sx={{ width: "fit-content" }}
              useFacetStore={useFacetStore}
              facetName={"domain"}
              facetOption={path.domain ?? ""}
            >
              {path?.domain ?? ""}
            </CTDatagridButton>
          </Tooltip>
          {ipView(true)}
        </Stack>
      );
    }

    return ipView(false);
  };

  return (
    <Stack direction={"row"} sx={{ maxWidth: "100%" }}>
      {renderContent()}
    </Stack>
  );
}
