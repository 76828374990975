import { useState } from "react";

export function useTagNetworks() {
  const [showAssignNetworksDrawer, setShowAssignNetworksDrawer] =
    useState(false);

  const openAssignNetworksDrawer = () => {
    setShowAssignNetworksDrawer(true);
  };

  const closeAssignNetworksDrawer = () => {
    setShowAssignNetworksDrawer(false);
  };

  return {
    showAssignNetworksDrawer,
    openAssignNetworksDrawer,
    closeAssignNetworksDrawer,
  };
}
