import { Link, Stack, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { UserGroupColumnRenderProps } from "../user-group-status/UserGroupStatus";
import numeral from "numeral";

export const UserCount = ({
  userGroup,
  viewOnly,
}: UserGroupColumnRenderProps) => {
  const userCount = numeral(userGroup?.users || 0).format("0a");
  return (
    <Stack direction={"row"} spacing={1} flex={1} alignItems={"center"}>
      {viewOnly || userGroup?.users === 0 ? (
        <Typography variant="body2">{userCount} </Typography>
      ) : (
        <Tooltip title={window.getCTTranslatedText("View users")}>
          <Link
            typography="body2"
            component={RouterLink}
            to={`/users?tab=users&filters=${encodeURIComponent(
              `groupname=${userGroup.groupName}`
            )}`}
            sx={{ color: "primary.main" }}
          >
            {userCount}{" "}
          </Link>
        </Tooltip>
      )}
    </Stack>
  );
};
