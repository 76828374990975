import { Stack, Typography } from "@mui/material";
import { InstructionItemProps, InstructionsRendererProps } from "./type";
import { Terminal } from "pages/agents/components/agent-download-page/Terminal";

function InstructionItem({
  key,
  instruction,
  stepCount,
}: InstructionItemProps) {
  function renderStepCount() {
    return (
      <Typography
        sx={{
          textTransform: "uppercase",
          color: theme => theme.palette.grey[600],
        }}
        variant="caption"
      >
        {window.getCTTranslatedText("stepCount", {
          count: stepCount,
        })}
        {instruction.isOptional
          ? ` (${window.getCTTranslatedText("optional")})`
          : null}
      </Typography>
    );
  }

  function renderTitle() {
    return (
      <Typography variant="subtitle1">
        {window.getCTTranslatedText(instruction.title)}
      </Typography>
    );
  }

  function renderSubtitle() {
    if (!instruction.subtitle) {
      return null;
    }
    return (
      <Typography variant="body2">
        {window.getCTTranslatedText(instruction.subtitle)}
      </Typography>
    );
  }

  function renderTerminal() {
    if (!instruction?.terminalCommands?.length) {
      return null;
    }
    return (
      <Stack pt={1}>
        <Terminal>
          <>
            {instruction?.terminalCommands.map((command, index) => {
              return (
                <Typography key={index} variant="body1">
                  {window.getCTTranslatedText(command)}
                </Typography>
              );
            })}
          </>
        </Terminal>
      </Stack>
    );
  }

  function renderInstructionContent() {
    if (!instruction.instructionContent) {
      return null;
    }
    return instruction.instructionContent();
  }

  return (
    <Stack spacing={2} mb={10} key={key}>
      {renderStepCount()}
      {renderTitle()}
      {renderSubtitle()}
      {renderTerminal()}
      {renderInstructionContent()}
    </Stack>
  );
}

export function InstructionsRenderer({
  instructions,
}: InstructionsRendererProps) {
  return (
    <Stack mt={4}>
      {instructions.map((instruction, index) => {
        return (
          <InstructionItem
            key={instruction?.title}
            instruction={instruction}
            stepCount={index + 1}
          />
        );
      })}
    </Stack>
  );
}
