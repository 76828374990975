import { debounce } from "@mui/material";
import { useTemplatesSuggestions } from "modules/add-to-templates/components/AddToTemplateDialog/helpers";
import { SortOrder } from "modules/core/types";
import { useAssetStore } from "pages/assets/store";
import { useTemplateStore } from "pages/templates/store";
import { useCallback, useEffect, useMemo, useState } from "react";

export const defaultSortOrder: Array<SortOrder> = [
  { field: "oobTemplate", order: "asc" },
  { field: "templateName", order: "asc" },
];

export function useAssetTemplates({
  criteria,
  enable = true,
}: {
  criteria: string;
  enable?: boolean;
}) {
  const [sort, setSort] = useState<Array<SortOrder>>(defaultSortOrder || []);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [showAddToTemplateDrawer, setShowAddToTemplateDrawer] = useState(false);
  const [showAssignedTemplatesDrawer, setShowAssignedTemplatesDrawer] =
    useState(false);
  const templatesSuggestMutation = useTemplatesSuggestions();
  const templateApiRefreshRequest = useTemplateStore(
    state => state.apiRefreshRequest
  );
  const assetApiRefreshRequest = useAssetStore(
    state => state.apiRefreshRequest
  );
  const [searchCriteria, setSearchCriteria] = useState<string | undefined>();

  const templateList = templatesSuggestMutation.data?.items ?? [];
  const total = templatesSuggestMutation.data?.metadata?.total ?? 0;
  const isLoading =
    templatesSuggestMutation.isLoading || templatesSuggestMutation.isIdle;

  const openAddToTemplateDrawer = () => {
    setShowAddToTemplateDrawer(true);
  };

  const closeAddToTemplateDrawer = () => {
    setShowAddToTemplateDrawer(false);
  };

  const openAssignedTemplatesDrawer = () => {
    setShowAssignedTemplatesDrawer(true);
  };

  const closeAssignedTemplatesDrawer = () => {
    setShowAssignedTemplatesDrawer(false);
  };

  const onSortChange = useCallback((sort: Array<SortOrder>) => {
    setSort(sort);
  }, []);

  const onSearchCriteriaChange = (str: string) => {
    if (!str) {
      setSearchCriteria(criteria);
      return;
    }

    setSearchCriteria(`${criteria} AND '${str}'`);
  };

  const templateListMutation = useMemo(
    () => debounce(templatesSuggestMutation.mutate, 300),
    [templatesSuggestMutation.mutate]
  );

  useEffect(() => {
    if (criteria && enable) {
      const body = {
        criteria: searchCriteria ?? criteria,
        pagination: {
          offset: page * pageSize,
          limit: pageSize,
          sort: sort,
        },
      };

      templateListMutation(body);
    }
  }, [
    templateListMutation,
    criteria,
    templateApiRefreshRequest,
    assetApiRefreshRequest,
    sort,
    page,
    pageSize,
    enable,
    searchCriteria,
  ]);

  return {
    showAddToTemplateDrawer,
    openAddToTemplateDrawer,
    closeAddToTemplateDrawer,
    showAssignedTemplatesDrawer,
    openAssignedTemplatesDrawer,
    closeAssignedTemplatesDrawer,
    templateList,
    total,
    isLoading,
    templateListMutation,
    onSortChange,
    sort,
    setPage,
    setPageSize,
    page,
    pageSize,
    totalCount: total,
    onSearchCriteriaChange,
  };
}
