import { useMutation } from "@tanstack/react-query";
import { AnalyticsAPIReq, StatContainer } from "common/types/types";

export interface WithoutGroupByResponse {
  items: StatContainer;
}

export interface WithGroupByResponse {
  items: {
    [key: string]: StatContainer;
  };
}

export function useAggregateAPI() {
  return useMutation<any, Error, AnalyticsAPIReq>(["asset", "aggregate"]);
}
