import {
  DialogActions,
  DialogContent,
  Dialog,
  Button,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { parseErrorMessage } from "common/utils";
import { Invite } from "pages/organization/types";

export interface DeleteInviteProps {
  invite: Invite;
  handleAllowDelete: () => void;
  allowDelete: boolean;
}

const useDeleteInviteAPI = (email: string) => {
  return useMutation<any, Error, any>([
    "policy",
    `auth/admin/invite/user/${email}`,
    "delete",
  ]);
};

export function DeleteInvite({
  invite,
  handleAllowDelete,
  allowDelete,
}: DeleteInviteProps) {
  const deleteInviteAPI = useDeleteInviteAPI(invite?.email);
  const [loading, setLoading] = useState(false);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const queryClient = useQueryClient();

  const updateInviteDetails = () => {
    setLoading(true);

    deleteInviteAPI.mutate(
      {},
      {
        onSuccess: response => {
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            "InviteDeletedSuccessfully"
          );
          queryClient.invalidateQueries({
            queryKey: ["invites"],
          });
          handleAllowDelete();
        },
        onError: error => {
          setLoading(false);
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      }
    );
  };

  return (
    <Dialog
      open={allowDelete}
      onClose={handleAllowDelete}
      aria-labelledby="delete-invite"
    >
      <DialogTitle id="delete-invite">
        {window.getCTTranslatedText(`Delete Invite`)}
      </DialogTitle>
      <DialogContent>
        {window.getCTTranslatedText("inviteConfirmation", {
          inviteeName: invite?.name,
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={handleAllowDelete}>
          {window.getCTTranslatedText("Cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          loading={deleteInviteAPI.isLoading || loading}
          onClick={updateInviteDetails}
          autoFocus
          color={"error"}
          type="submit"
        >
          {window.getCTTranslatedText("Delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
