import { Box, Button, Tooltip } from "@mui/material";
import { dayjsWithPlugins } from "common/utils";
import { API_URL } from "hooks/useNetworking/constants";
import { LogsDrawer } from "modules/logs-drawer/LogsDrawer";
import { FirewallLogs, LogOptions } from "pages/asset/types";
import { Asset } from "pages/assets/types";
import prettyBytes from "pretty-bytes";
import { useMemo, useState } from "react";
import { FirewallLogsFetcher } from "./FirewallLogsFetcher";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";

interface ViewFirewallLogsButtonProps {
  asset?: Asset;
  agentId: string;
  type: FirewallLogType;
}

export enum FirewallLogType {
  ASSET = "asset",
  APPLIANCE = "appliance",
}

export const ViewFirewallLogsButton = ({
  asset,
  agentId,
  type,
}: ViewFirewallLogsButtonProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [optionsData, setOptionsData] = useState<FirewallLogs>();
  const [areOptionsLoading, setAreOptionsLoading] = useState(true);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const id =
    type === FirewallLogType.APPLIANCE ? agentId : (asset?.assetId as string);

  const onViewLogsClick = () => setIsDrawerOpen(true);
  const onDrawerClick = () => setIsDrawerOpen(false);

  const logOptions: LogOptions = useMemo(
    () =>
      optionsData?.items?.map((item, index) => ({
        value: item.lastModified,
        label: `${index === 0 ? "Active (" : ""} ${dayjsWithPlugins(
          item.lastModified
        ).format("DD-MMM-YY HH:mm:ss")} ${index === 0 ? ")" : ""}`,
        id: item.name,
        fileSize: prettyBytes(item.sizeInBytes),
      })),
    [optionsData]
  );

  return (
    <>
      {isDrawerOpen && (
        <FirewallLogsFetcher
          id={id}
          setOptionsData={setOptionsData}
          setAreOptionsLoading={setAreOptionsLoading}
          type={type}
        />
      )}
      <LogsDrawer
        isOpen={isDrawerOpen}
        onClose={onDrawerClick}
        label="Firewall Rules"
        areOptionsLoading={areOptionsLoading}
        logOptions={logOptions}
        downloadPrefix={`${API_URL}/assets/${
          asset?.assetId ?? optionsData?.assetId
        }/firewallrules/`}
        hasPermission={userPermissions.has("READ_FIREWALL_LOG")}
      />
      <Tooltip
        title={window.getCTTranslatedText(
          !agentId ? "Agent Unavailable" : "ViewRules"
        )}
      >
        <Box>
          <Button
            variant={type === FirewallLogType.APPLIANCE ? "text" : "outlined"}
            disabled={!agentId}
            onClick={onViewLogsClick}
          >
            {window.getCTTranslatedText("Rules")}
          </Button>
        </Box>
      </Tooltip>
    </>
  );
};
