import { useMutation } from "@tanstack/react-query";
import { usePrevious } from "common/hooks/usePrevious";
import { AnalyticsAPIReq, AnalyticsResponse } from "common/types/types";
import difference from "lodash/difference";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import uniq from "lodash/uniq";
import { nil } from "modules/facets/FacetUtils";
import { Dimension } from "modules/hierarchy-vis/types";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { CompassDirection, PathStatus } from "pages/paths/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useVisxStore } from "../store";
import {
  Aggregates,
  AssetStatusAggResult,
  StatisticType,
  StatusTrafficStats,
  TrafficData,
  TrafficStats,
  TrafficStatusAgg,
} from "../types";
import {
  MAX_NODES,
  NODE_ID_SEPARATOR,
  OTHERS,
  SUBNET_PREFIX,
  getNodeName,
  isSubnet,
} from "../visx-utils";

export const HUB_PARENT = {
  label: "Hub",
};
export const HUB_TAG = "main-hub";
export const NOT_HUB_TAG = "main-not-hub";

export const DEFAULT_NETWORK_NAME = nil;
export const DEFAULT_DIMENSION_NAME = nil;
export const DEFAULT_SOURCE_NODE_NAME = nil;

export interface ParentNodeName {
  label: string;
}

interface NetworkMapAPIReq extends AnalyticsAPIReq {}

function usePathsAnalyticsAPI(
  onSuccess: (data: AnalyticsResponse, variables: NetworkMapAPIReq) => void
) {
  return useMutation<AnalyticsResponse, Error, NetworkMapAPIReq>(
    ["path-hierarchy", "network-map"],
    {
      onMutate(variables) {
        console.log("Firing query", { variables });
      },
      onSuccess: (data, variables) => {
        onSuccess(data, variables);
      },
    }
  );
}

function useAssetAggregateAPI(
  onSuccess: (data: AnalyticsResponse, variables: AnalyticsAPIReq) => void
) {
  return useMutation<AnalyticsResponse, Error, AnalyticsAPIReq>(
    ["asset-aggregate", "aggregate"],
    {
      onMutate(variables) {
        console.log("Firing query", { variables });
      },
      onSuccess: (data, variables) => {
        onSuccess(data, variables);
      },
    }
  );
}

export function useTrafficData({
  criteria,
  sourceCriteria,
  destinationCriteria,
  selectedSourceDimension,
  selectedDestinationDimension,
  statisticsName,
  selectedDirection,
  tag,
  loadAssets,
  topParent,
  leafParent,
}: {
  criteria: string | undefined;
  sourceCriteria: string | undefined;
  destinationCriteria: string | undefined;
  selectedSourceDimension: Dimension | undefined;
  selectedDestinationDimension: Dimension | undefined;
  statisticsName: StatisticType | undefined;
  selectedDirection: Direction;
  tag: string;
  loadAssets?: boolean;
  topParent?: ParentNodeName;
  leafParent?: ParentNodeName;
}): {
  trafficData: TrafficData | undefined;
  isLoading: boolean;
  reload: Function;
  isEmpty: boolean;
} {
  const [, setLoadTime] = useState<number | undefined>(undefined);
  const mergedTrafficDataRef = useRef<TrafficData | undefined>(undefined);
  const trafficDataRef = useRef<TrafficData | undefined>(undefined);
  const assetTrafficDataRef = useRef<TrafficData | undefined>(undefined);

  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  let sourceDimensionName = selectedSourceDimension?.name;
  if (sourceDimensionName && !sourceDimensionName.startsWith("src")) {
    sourceDimensionName = `source.${sourceDimensionName}`;
  }

  const shouldLoad =
    Boolean(statisticsName) &&
    Boolean(criteria) &&
    Boolean(sourceCriteria) &&
    Boolean(destinationCriteria) &&
    Boolean(selectedDestinationDimension) &&
    Boolean(sourceDimensionName);

  if (tag === HUB_TAG) {
    topParent = HUB_PARENT;
    leafParent = topParent;
  } else if (tag === NOT_HUB_TAG) {
    topParent = HUB_PARENT;
  }

  const pathStatsAPI = usePathsAnalyticsAPI((data, variables) => {
    if (!statisticsName) {
      setLoading(false);
      return;
    }

    console.log(
      `Visx query response ${statisticsName} ${tag}`,
      variables,
      data
    );

    if (!data && !loadAssets) {
      setLoading(false);
      setIsEmpty(true);
      return;
    }

    let items: any = data.items;

    if (selectedSourceDimension && items[selectedSourceDimension?.name]) {
      items = items[selectedSourceDimension?.name];
    }

    if (
      selectedDestinationDimension &&
      items[selectedDestinationDimension?.name]
    ) {
      items = items[selectedDestinationDimension?.name];
    }

    try {
      let processedData = processData(
        items,
        selectedDestinationDimension,
        selectedSourceDimension,
        statisticsName,
        selectedDirection,
        topParent,
        leafParent
      );

      trafficDataRef.current = processedData;
      if (!processedData && !loadAssets) {
        setLoading(false);
        setIsEmpty(true);
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setIsEmpty(true);
      setSnackbar(true, SnackBarSeverity.Error, "SomethingWentWrong");
    }
  });

  const hierarchyAPIMutator = useMemo(
    () => pathStatsAPI.mutateAsync,
    [pathStatsAPI.mutateAsync]
  );

  const [isLoading, setLoading] = useState(shouldLoad);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (pathStatsAPI.error) {
      setLoading(false);
      setIsEmpty(true);
      setSnackbar(true, SnackBarSeverity.Error, "SomethingWentWrong");
    }
  }, [pathStatsAPI.error, setSnackbar]);

  const hubDimension =
    selectedDirection === Direction.Inbound
      ? selectedDestinationDimension
      : selectedSourceDimension;

  const allAssetsAPI = useAssetAggregateAPI(data => {
    console.log("Loaded aggregate result", data);

    let names = Object.keys(data.items);
    let newTrafficData: TrafficData = {};
    names.forEach(originalName => {
      let name = getNodeName(originalName, hubDimension);
      if (topParent) {
        name = `${topParent.label}${NODE_ID_SEPARATOR}${name}`;
      }
      const stat = {
        aggregates: {
          assetAggregate:
            +data?.items?.[originalName]?.statistics.assetnamedistinctcount ||
            0,
        },
      };
      newTrafficData[name] = {
        name,
        data: {
          enforced: stat,
          reviewed: stat,
        },
        sourceDimension:
          selectedDirection === Direction.Inbound
            ? selectedDestinationDimension
            : selectedSourceDimension,
      };
    });

    assetTrafficDataRef.current = newTrafficData;
  });

  const isOthersNodeExpanded = useVisxStore(
    store => store.isOthersNodeExpanded
  );

  useEffect(() => {
    if (!trafficDataRef.current && pathStatsAPI.isLoading) {
      return;
    }

    if (loadAssets && !assetTrafficDataRef.current) {
      return;
    }

    if (!assetTrafficDataRef.current && !trafficDataRef.current) {
      return;
    }

    let assetNames = Object.keys(assetTrafficDataRef.current ?? {});
    let assetsWithPathNames = Object.keys(trafficDataRef.current ?? {});
    let assetsReferencedByPaths: Array<string> = [];
    assetsWithPathNames?.forEach(name => {
      let referenced =
        trafficDataRef.current?.[name]?.data?.[
          StatisticType.Enforced
        ]?.dimensionStats?.keys();
      if (referenced) {
        assetsReferencedByPaths = [
          ...assetsReferencedByPaths,
          ...Array.from(referenced),
        ];
      }
    });
    assetsWithPathNames = uniq([
      ...assetsWithPathNames,
      ...assetsReferencedByPaths,
    ]);

    let diff = difference(assetNames, assetsWithPathNames);

    let assetsList = { ...assetTrafficDataRef.current };

    if (diff?.length && !isOthersNodeExpanded) {
      let showCount = Math.max(assetsWithPathNames?.length, MAX_NODES);
      let hideCount = assetNames?.length - showCount;
      let hiddenNames = diff.slice(0, hideCount);
      let hiddenAssetAggCount = 0;
      hiddenNames?.forEach(hiddenGroup => {
        hiddenAssetAggCount +=
          assetTrafficDataRef?.current?.[hiddenGroup]?.data?.[
            StatisticType.Enforced
          ]?.aggregates?.assetAggregate ?? 0;
        delete assetsList[hiddenGroup];
      });
      if (hiddenAssetAggCount > 0 && hiddenNames?.length && assetsList) {
        let datum: StatusTrafficStats = {
          aggregates: {
            assetAggregate: hiddenAssetAggCount,
          },
        };
        assetsList[OTHERS] = {
          name: OTHERS,
          data: {
            enforced: datum,
            reviewed: datum,
          },
        };
      }
    }

    mergedTrafficDataRef.current = merge(
      {},
      assetsList,
      trafficDataRef.current
    );
    setLoadTime(Date.now());
    setLoading(false);
    if (Object.keys(mergedTrafficDataRef.current)?.length === 0) {
      setIsEmpty(true);
    }
  }, [
    pathStatsAPI.isLoading,
    allAssetsAPI.isLoading,
    loadAssets,
    isOthersNodeExpanded,
  ]);

  const reset = pathStatsAPI.reset;

  const loadData = useCallback(
    (body: NetworkMapAPIReq, resetAssets: boolean = false) => {
      reset();
      setLoading(true);
      trafficDataRef.current = undefined;
      if (resetAssets) {
        assetTrafficDataRef.current = undefined;
      }
      mergedTrafficDataRef.current = undefined;
      console.info(`Issuing ${body.statistics[0]} visx query ${tag}`, body);
      hierarchyAPIMutator(body);
    },
    [hierarchyAPIMutator, reset, tag]
  );

  const createBody = (
    statName: string | undefined
  ): NetworkMapAPIReq | undefined => {
    if (
      !statName ||
      !criteria ||
      !selectedDestinationDimension ||
      !sourceDimensionName ||
      !sourceCriteria ||
      !destinationCriteria
    ) {
      return;
    }

    let groupBy: string[] = [];
    if (selectedDirection === Direction.Inbound) {
      groupBy = [
        selectedDestinationDimension.name,
        "compassdirection",
        "source.namednetworkname",
      ];

      if (sourceDimensionName?.startsWith("srcsubnet")) {
        groupBy = [...groupBy, sourceDimensionName];
      }

      groupBy = [...groupBy, "source.type"];

      if (!groupBy.includes(sourceDimensionName)) {
        groupBy = [...groupBy, sourceDimensionName];
      }
      groupBy.push("assetinboundstatus");
    } else {
      groupBy = [sourceDimensionName, "compassdirection", "namednetworkname"];

      if (selectedDestinationDimension?.name?.startsWith("dstsubnet")) {
        groupBy = [...groupBy, selectedDestinationDimension?.name!];
      }

      groupBy = [...groupBy, "type"];

      if (!groupBy.includes(selectedDestinationDimension.name)) {
        groupBy = [...groupBy, selectedDestinationDimension.name];
      }
      groupBy.push("source.assetoutboundstatus");
    }

    groupBy = [...groupBy, statName];

    const body = {
      criteria: criteria,
      sourceCriteria,
      destinationCriteria,
      groupBy,
      scope: Scope.Path,
      statistics: [
        `count(${statName})`,
        "distinctcount(source.assetid)",
        "distinctcount(destination.assetid)",
      ],
    };

    return body;
  };

  const newRequest = createBody(statisticsName);
  const previousReq = usePrevious(newRequest);

  const loadRequest = useMemo(() => {
    if (isEqual(newRequest, previousReq)) {
      return;
    }
    // reset assets if body changes for anything else other than traffic criteria;
    return {
      request: newRequest,
      resetAssets: isEqual(previousReq?.criteria, newRequest?.criteria),
    };
  }, [newRequest, previousReq]);

  useEffect(() => {
    if (!loadRequest || !loadRequest.request) {
      return;
    }
    loadData(loadRequest.request, loadRequest.resetAssets);
  }, [loadRequest, loadData]);

  useEffect(() => {
    if (allAssetsAPI.isError) {
      setSnackbar(true, SnackBarSeverity.Error, "SomethingWentWrong");
    }
  }, [allAssetsAPI.isError, setSnackbar]);

  const allAssetsAPIMutator = allAssetsAPI.mutate;
  useEffect(() => {
    assetTrafficDataRef.current = undefined;

    if (!loadAssets) {
      return;
    }

    let assetLoadCriteria =
      selectedDirection === Direction.Inbound
        ? destinationCriteria
        : sourceCriteria;

    if (!assetLoadCriteria || !hubDimension) {
      return;
    }

    allAssetsAPIMutator({
      criteria: assetLoadCriteria,
      scope: Scope.Asset,
      statistics: ["distinctcount(assetname)"],
      groupBy: [hubDimension.name],
    });
  }, [
    allAssetsAPIMutator,
    destinationCriteria,
    hubDimension,
    loadAssets,
    selectedDirection,
    sourceCriteria,
  ]);

  mergedTrafficDataRef.current = useMemo(() => {
    if (
      loadRequest &&
      previousReq &&
      !isEqual(loadRequest?.request, previousReq)
    ) {
      return undefined;
    }

    return mergedTrafficDataRef.current;
  }, [loadRequest, previousReq]);

  return {
    trafficData: mergedTrafficDataRef.current,
    reload: () => {
      let body = createBody(statisticsName);
      if (!body) {
        return;
      }
      loadData(body, false);
    },
    isLoading,
    isEmpty,
  };
}

function processData(
  items: any,
  selectedDestinationDimension: Dimension | undefined,
  selectedSourceDimension: Dimension | undefined,
  statisticsName: string,
  selectedDirection: Direction,
  topParent?: ParentNodeName,
  leafParent?: ParentNodeName
): TrafficData | undefined {
  if (!items || !selectedDestinationDimension || !selectedSourceDimension) {
    return;
  }

  let keys = Object.keys(items);
  if (!keys.length) {
    return;
  }
  const trafficData: TrafficData = {};

  let sourceDimension = selectedSourceDimension?.name;
  if (!sourceDimension) {
    const stats = mapDataToStats({
      items,
      statisticsName,
      destinationDimension:
        selectedDirection === Direction.Inbound
          ? selectedDestinationDimension
          : selectedSourceDimension,
      sourceDimension:
        selectedDirection === Direction.Inbound
          ? selectedSourceDimension
          : selectedDestinationDimension,
      allItems: items,
      topParent,
      leafParent,
      selectedDirection,
    });
    trafficData["all"] = {
      name: "*",
      data: {
        [statisticsName]: {
          stats,
          ...computeDataFromStats({ stats }),
        },
      },
      sourceDimension:
        selectedDirection === Direction.Inbound
          ? selectedDestinationDimension
          : selectedSourceDimension,
    };
  } else {
    keys.forEach(firstDimensionName => {
      const stats = mapDataToStats({
        allItems: items,
        items: items?.[firstDimensionName]?.compassdirection,
        statisticsName,
        destinationDimension:
          selectedDirection === Direction.Inbound
            ? selectedDestinationDimension
            : selectedSourceDimension,
        sourceDimension:
          selectedDirection === Direction.Inbound
            ? selectedSourceDimension
            : selectedDestinationDimension,
        topParent,
        leafParent,
        selectedDirection,
      });

      firstDimensionName = getNodeName(
        firstDimensionName,
        selectedDirection === Direction.Inbound
          ? selectedDestinationDimension
          : selectedSourceDimension
      );

      if (topParent) {
        firstDimensionName = `${topParent.label}${NODE_ID_SEPARATOR}${firstDimensionName}`;
      }

      trafficData[firstDimensionName] = {
        name: firstDimensionName,
        data: {
          [statisticsName]: {
            stats,
            ...computeDataFromStats({ stats }),
          },
        },
        sourceDimension:
          selectedDirection === Direction.Inbound
            ? selectedDestinationDimension
            : selectedSourceDimension,
      };
    });
  }

  return trafficData;
}

export function computeDataFromStats({
  stats,
}: {
  stats: TrafficStats | undefined;
}) {
  const dimensionStats = getDimensionStats({ stats });
  return {
    dimensionStats: dimensionStats,
    eastWestNetworkStats: getNamedNetworkStats({
      stats,
      direction: CompassDirection.EastWest,
    }),
    northSouthNetworkStats: getNamedNetworkStats({
      stats,
      direction: CompassDirection.NorthSouth,
    }),
    aggregates: getAggregates(dimensionStats),
  };
}

function mapDataToStats({
  allItems,
  sourceDimension,
  destinationDimension,
  statisticsName,
  items,
  topParent,
  leafParent,
  selectedDirection,
}: {
  items: any;
  allItems: any;
  statisticsName: string | null;
  selectedDirection: Direction;
  destinationDimension: Dimension;
  sourceDimension: Dimension;
  topParent?: ParentNodeName;
  leafParent?: ParentNodeName;
}) {
  let trafficDataSecondDimension = sourceDimension;

  destinationDimension = sourceDimension;

  let eastWestStats = items[CompassDirection.EastWest]?.namednetworkname;
  let northSouthStats = items[CompassDirection.NorthSouth]?.namednetworkname;

  const convertToMap = (stats: any, includeSubnets: boolean) => {
    let trafficStats: TrafficStatusAgg = {};
    if (!stats || !statisticsName) {
      return trafficStats;
    }

    let networkNames = Object.keys(stats);
    networkNames.forEach(networkName => {
      trafficStats[networkName] = {};
      let subnetKey = Object.keys(stats[networkName] ?? {})?.[0];

      const handleTypes = (typeDataContainer: any) => {
        let types = Object.keys(typeDataContainer);

        types.forEach(type => {
          let typeData = typeDataContainer[type];
          let dimensionsData = typeData[trafficDataSecondDimension.name];

          if (!dimensionsData) {
            if (type === trafficDataSecondDimension.name) {
              dimensionsData = typeData;
            }
            if (
              trafficDataSecondDimension.name === "type" ||
              isSubnet(trafficDataSecondDimension.name)
            ) {
              dimensionsData = typeDataContainer;
            }
            if (!dimensionsData) {
              return;
            }
          }

          dimensionsData = { ...dimensionsData };

          let dimensionNames = Object.keys(dimensionsData);
          dimensionNames.forEach(dimensionName => {
            let dimensionKey = dimensionName;
            if (dimensionName === nil) {
              if (
                trafficDataSecondDimension.name !== "assetname" &&
                type !== trafficDataSecondDimension.name &&
                trafficDataSecondDimension.name !== "type" &&
                !isSubnet(trafficDataSecondDimension.name)
              ) {
                dimensionKey = getNodeName(nil, trafficDataSecondDimension);
              }
              if (type === trafficDataSecondDimension.name || type === nil) {
                dimensionKey = nil;
              }
            }

            if (!isSubnet(trafficDataSecondDimension.name)) {
              if (leafParent) {
                dimensionKey = `${leafParent.label}${NODE_ID_SEPARATOR}${dimensionKey}`;
              } else if (
                topParent &&
                allItems[
                  `${topParent.label}${NODE_ID_SEPARATOR}${dimensionKey}`
                ]
              ) {
              }
            }

            let assetStatuses = Object.keys(dimensionsData[dimensionName]);
            dimensionsData[dimensionName] =
              dimensionsData[dimensionName][assetStatuses[0]];
            let assetStatusDistribution = Object.keys(
              dimensionsData[dimensionName]
            );

            assetStatusDistribution.forEach(assetStatus => {
              let storageMap = new Map(
                trafficStats[networkName][dimensionKey]?.statusData?.[
                  assetStatus
                ]?.pathStatusMap
              );

              trafficStats[networkName][dimensionKey] = {
                ...trafficStats[networkName][dimensionKey],
                statusData: {
                  ...trafficStats[networkName][dimensionKey]?.statusData,
                  [assetStatus]: {
                    pathStatusMap: storageMap,
                  },
                },
                dimension: destinationDimension,
              };
              let statuses = Object.keys(
                dimensionsData[dimensionName]?.[assetStatus]?.[statisticsName]
              );

              let assetCount = 0;
              let field = `${
                selectedDirection === Direction.Inbound
                  ? "source"
                  : "destination"
              }assetiddistinctcount`;

              statuses.forEach(pathStatus => {
                const count =
                  dimensionsData[dimensionName]?.[assetStatus]?.[
                    statisticsName
                  ][pathStatus].statistics?.[`${statisticsName}count`];

                let oldCount = storageMap.get(pathStatus as PathStatus) ?? 0;
                storageMap.set(pathStatus as PathStatus, count + oldCount);

                assetCount +=
                  dimensionsData[dimensionName]?.[assetStatus]?.[
                    statisticsName
                  ][pathStatus].statistics?.[field] || 0;
              });

              if (
                trafficStats[networkName][dimensionKey]?.statusData?.[
                  assetStatus
                ]
              ) {
                trafficStats[networkName][dimensionKey].statusData[
                  assetStatus
                ].assetAggregate = assetCount;
              }
            });
          });
        });
      };

      if (subnetKey === "type") {
        includeSubnets = false;
        handleTypes(stats[networkName]?.type);
        return;
      }

      let subnetNames = stats[networkName]?.[subnetKey];
      if (!stats[networkName] || !subnetNames) {
        return;
      }

      let subnets = Object.keys(subnetNames);
      subnets.forEach(subnet => {
        let resetNetworkName = false;
        if (includeSubnets) {
          resetNetworkName = true;
          networkName = `${SUBNET_PREFIX}_${subnetKey}_${subnet}`;
          if (!trafficStats[networkName]) {
            trafficStats[networkName] = {};
          }
        }

        let typeDataContainer = subnetNames?.[subnet]?.type;

        handleTypes(typeDataContainer);

        if (resetNetworkName) {
          networkName = nil;
        }
      });
    });

    return trafficStats;
  };

  let eastWestStatsMap = convertToMap(eastWestStats, true);
  let northSouthStatsMap = convertToMap(northSouthStats, false);

  return {
    [CompassDirection.EastWest]: eastWestStatsMap,
    [CompassDirection.NorthSouth]: northSouthStatsMap,
  };
}

function getDimensionStats({
  stats,
  trafficMetadata,
}: {
  stats: TrafficStats | undefined;
  trafficMetadata?: Partial<AssetStatusAggResult>;
}): Map<string, AssetStatusAggResult> | undefined {
  if (!stats) {
    return;
  }
  let agg = stats[CompassDirection.EastWest];
  let networkAgg = Object.values(agg || {});
  let dimensionStatusMap = new Map<string, AssetStatusAggResult>();

  networkAgg.forEach(dimensionStats => {
    Object.entries(dimensionStats).forEach(
      ([dimensionName, dimensionAssetStatusAggData]) => {
        if (dimensionName === DEFAULT_DIMENSION_NAME) {
          return;
        }
        let assetStatusDimensionData = dimensionAssetStatusAggData;
        if (!assetStatusDimensionData) {
          return;
        }
        let assetStatuses = Object.keys(assetStatusDimensionData.statusData);
        assetStatuses.forEach(assetStatus => {
          if (!assetStatusDimensionData) {
            return;
          }
          let dimensionData =
            assetStatusDimensionData?.statusData?.[assetStatus];

          let oldData = dimensionStatusMap.get(dimensionName);
          let oldMap = oldData?.statusData?.[assetStatus]?.pathStatusMap;
          let mergedMap = new Map(dimensionData.pathStatusMap);

          let keys = new Set(
            Array.from(oldMap?.keys() || []).concat(
              Array.from(dimensionData.pathStatusMap.keys() || [])
            )
          );

          keys.forEach(statusName => {
            mergedMap.set(
              statusName,
              (oldMap?.get(statusName) || 0) +
                (dimensionData.pathStatusMap?.get(statusName) || 0)
            );
          });

          let newStatusData = {
            ...trafficMetadata,
            ...dimensionData,
            pathStatusMap: mergedMap,
          };

          if (!oldData) {
            oldData = {
              statusData: {},
              dimension: dimensionAssetStatusAggData.dimension,
            };
          }
          oldData.statusData[assetStatus] = newStatusData;

          dimensionStatusMap.set(dimensionName, oldData);
        });
      }
    );
  });

  dimensionStatusMap.forEach(assetStatusAggResult => {
    let statuses = Object.keys(assetStatusAggResult.statusData);
    statuses.forEach(status => {
      let aggResult = assetStatusAggResult.statusData[status];
      const empty: number[] = [];
      const totalCount = Array.from(
        aggResult?.pathStatusMap?.values() || empty
      ).reduce(
        (accumulator: number, currentValue: number) =>
          accumulator + currentValue,
        0
      );

      aggResult.pathsAggregate = totalCount;
    });
  });

  return dimensionStatusMap;
}

interface CreateNetworkMapProps {
  direction: CompassDirection;
  stats: TrafficStats | undefined;
}

function getNamedNetworkStats({ direction, stats }: CreateNetworkMapProps) {
  let networkMap = new Map<string, AssetStatusAggResult>();
  if (!stats) {
    return networkMap;
  }
  let networkNames = Object.keys(stats[direction] || []);
  networkNames.forEach(networkName => {
    let appNames = Object.keys(stats[direction][networkName]);
    let assetStatusCounts: { [key: string]: Map<PathStatus, number> } = {};
    let totalCounts = 0;

    appNames.forEach(appName => {
      if (appName !== nil) {
        return;
      }
      let appData = stats[direction][networkName][appName];
      let assetStatuses = Object.keys(appData.statusData);

      assetStatuses.forEach(assetStatus => {
        if (!assetStatusCounts[assetStatus]) {
          assetStatusCounts[assetStatus] = new Map();
        }

        let counts = assetStatusCounts[assetStatus];
        let statsMap = appData.statusData[assetStatus]?.pathStatusMap;
        statsMap.forEach((count, status) => {
          counts.set(status, (counts.get(status) || 0) + count);
          totalCounts += count ?? 0;
        });

        let assetStatusNetworkData = {
          pathStatusMap: counts,
          pathsAggregate: totalCounts,
        };

        let oldData = networkMap.get(networkName) || { statusData: {} };
        oldData.statusData[assetStatus] = assetStatusNetworkData;
        networkMap.set(networkName, oldData);
      });
    });
  });
  return networkMap;
}

function getAggregates(
  dimensionStats: Map<string, AssetStatusAggResult> | undefined
) {
  if (!dimensionStats) {
    return { pathsAggregate: 0 };
  }
  const empty: AssetStatusAggResult[] = [];

  const aggregator = (
    accumulator: Aggregates,
    currentValue: AssetStatusAggResult
  ) => {
    let statuses = Object.keys(currentValue.statusData);
    let total = 0;
    statuses.forEach(status => {
      total += currentValue.statusData[status].pathsAggregate ?? 0;
    });
    return {
      pathsAggregate: (accumulator.pathsAggregate ?? 0) + total,
    };
  };
  return Array.from(dimensionStats?.values() || empty).reduce(aggregator, {
    pathsAggregate: 0,
  });
}
