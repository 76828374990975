import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import { ProgressiveEnforcementLevel } from "pages/ports/types";

export const ProgressiveEnforcementLevelIcon = ({
  status,
  color,
  x = 0,
  y = 0,
}: {
  status: ProgressiveEnforcementLevel;
  color: string;
  x?: number;
  y?: number;
}) => {
  let icon = <></>;

  switch (status) {
    case ProgressiveEnforcementLevel.Any:
      icon = (
        <GppMaybeOutlinedIcon
          sx={{
            color: color,
            width: 24,
            height: 20,
          }}
        />
      );
      break;
    case ProgressiveEnforcementLevel.AllowAll:
      icon = (
        <ShieldOutlinedIcon
          sx={{
            color: color,
            width: 24,
            height: 20,
          }}
        />
      );
      break;
    case ProgressiveEnforcementLevel.AllowWithBandwidth:
      icon = (
        <GppGoodOutlinedIcon
          sx={{
            color: color,
            width: 24,
            height: 20,
          }}
        />
      );
      break;
    case ProgressiveEnforcementLevel.ZeroTrust:
      icon = (
        <LocalPoliceOutlinedIcon
          sx={{
            color: color,
            width: 24,
            height: 20,
          }}
        />
      );
      break;
  }

  return <>{icon}</>;
};
