import { Stack, Typography, useTheme } from "@mui/material";
import { SecurityRiskProgressBar } from "./components/security-risk-progressbar";
import { getColor } from "common/constants/colors";
import { getSecurityLevelText } from "pages/dashboard/helpers/getSecurityLevelText";
import { DataGridButton } from "common/atoms/data-grid-button";
import { useNavigate } from "react-router-dom";
import { SecurityLevels } from "pages/assets/types";

export interface SecurityRiskProps {
  riskConfig: any;
  viewOnly?: boolean;
}

const chartLabelTextStyles = {
  position: "absolute",
  textTransform: "capitalize",
  top: "180px",
};

const riskScoreLabelStyles = {
  color: "primary",
  textTransform: "uppercase",
  letterSpacing: "1",
  textAlign: "center",
};

const riskValueTextStyles = {
  textAlign: "center",
  textTransform: "capitalize",
};

function getBtnLink(text: SecurityLevels, type: string) {
  let link = "";
  let queryParam = encodeURI(`${type}=${text}`);
  link = `/assets?filters=${queryParam}`;

  return link;
}

export const SecurityRisk = ({
  riskConfig,
  viewOnly = false,
}: SecurityRiskProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const processBtnClickCallback = (text: SecurityLevels) => {
    const link = getBtnLink(text, "assetrisk");
    if (link) {
      navigate(link);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "relative",
        width: "320px",
        top: "50px",
        left: "calc(50% - 160px)",
      }}
    >
      <Stack sx={{ position: "absolute", top: "10px" }}>
        <SecurityRiskProgressBar
          value={riskConfig?.value || 0}
          text={""}
          circleRatio={0.5}
          rotation={0.75}
          pathColor={getColor(getSecurityLevelText(riskConfig?.value || 0))}
          trailColor={theme.palette.background.default}
          strokeWidth={5}
        />
      </Stack>
      <Stack sx={{ position: "relative" }}>
        <Typography
          variant="body2"
          style={{ left: "-156px" }}
          sx={chartLabelTextStyles}
        >
          <b>{window.getCTTranslatedText("low")}</b>
        </Typography>
        <Typography
          variant={"body2"}
          style={{ right: "-156px" }}
          sx={chartLabelTextStyles}
        >
          <b>{window.getCTTranslatedText("critical")}</b>
        </Typography>
      </Stack>
      <Stack sx={{ position: "absolute", top: "60px", textAlign: "center" }}>
        <Stack>
          {!viewOnly && (
            <DataGridButton
              disabled={viewOnly}
              onClick={() =>
                processBtnClickCallback(
                  getSecurityLevelText(riskConfig?.value || 0)
                )
              }
              color="inherit"
            >
              <Typography variant={"h2"}>
                <b>{riskConfig?.value || 0}</b>
              </Typography>
            </DataGridButton>
          )}
          {viewOnly && (
            <Typography variant={"h2"}>
              <b>{riskConfig?.value || 0}</b>
            </Typography>
          )}
        </Stack>
        <Typography variant={"caption"} sx={riskScoreLabelStyles}>
          {window.getCTTranslatedText("BreachImpactScore")}
        </Typography>
        <Stack>
          {!viewOnly && (
            <DataGridButton
              onClick={() =>
                processBtnClickCallback(
                  getSecurityLevelText(riskConfig?.value || 0)
                )
              }
              color="inherit"
              sx={{ mt: 5, width: "100%" }}
            >
              <Typography
                variant={"h6"}
                style={{
                  color: getColor(getSecurityLevelText(riskConfig?.value || 0)),
                }}
                sx={riskValueTextStyles}
              >
                {getSecurityLevelText(riskConfig?.value || 0)}
              </Typography>
            </DataGridButton>
          )}
          {viewOnly && (
            <Typography
              variant={"h5"}
              style={{
                color: getColor(getSecurityLevelText(riskConfig?.value || 0)),
              }}
              sx={{ ...riskValueTextStyles, mt: 5, width: "100%" }}
            >
              {window.getCTTranslatedText(
                getSecurityLevelText(riskConfig?.value || 0)
              )}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
