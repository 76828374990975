import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

interface CTConfirmationProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  primaryText?: string;
  secondaryText?: string;
  isLoading: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onSuccess: () => void;
}

export function CTConfirmation({
  open,
  onClose,
  title,
  primaryText,
  secondaryText,
  isLoading,
  primaryButtonText,
  secondaryButtonText = window.getCTTranslatedText("Cancel"),
  onSuccess,
}: Readonly<CTConfirmationProps>) {
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">{primaryText}</Typography>
        {secondaryText ? (
          <>
            <br />
            <Typography variant="body2">{secondaryText}</Typography>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
          }}
        >
          {secondaryButtonText}
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="error"
          onClick={() => {
            onSuccess();
          }}
        >
          {primaryButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
