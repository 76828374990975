import { GridColDef } from "@mui/x-data-grid-pro";
import { FacetGroupInfo } from "modules/core/types";
import { IDPName, TokenExpiryTime } from "./IDP-Info";
import { IDPActions } from "./IDP-actions";

export enum SCIM_STATUS_ENUM {
  "Pending" = "PENDING",
  "Completed" = "COMPLETED",
  "Syncing" = "SYNCING",
}

export const SCIM_STATUS: { [key: string]: string } = {
  [SCIM_STATUS_ENUM.Pending]: "Pending",
  [SCIM_STATUS_ENUM.Completed]: "Completed",
  [SCIM_STATUS_ENUM.Syncing]: "Syncing",
};

export const BASE_IDP_COLUMNS: GridColDef[] = [
  {
    field: "idpName",
    headerName: "IdP",
    width: 140,
    flex: 0.4,
    sortingOrder: ["asc", "desc"],
    sortable: true,
    renderCell: params => {
      return <IDPName idp={params.row} />;
    },
  },
  {
    field: "validthru",
    headerName: "Latest Token Expiry",
    width: 140,
    flex: 0.4,
    sortingOrder: ["asc", "desc"],
    sortable: true,
    renderCell: params => {
      return <TokenExpiryTime idp={params.row} />;
    },
  },
];

export const IDP_COLUMNS: GridColDef[] = [
  ...BASE_IDP_COLUMNS,
  {
    field: "menuCell",
    headerName: "",
    width: 80,
    sortable: false,
    sortingOrder: ["asc", "desc"],
    renderCell: params => {
      return <IDPActions idp={params.row} />;
    },
  },
];

export const FACET_ORDER: FacetGroupInfo = {};
