import { Box, Link, TextField, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { isValidEmail } from "common/utils/isEmailValid";
import { API_URL } from "hooks/useNetworking/constants";
import { AuthForm, HiddenForm } from "modules/auth-form";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

// TODO: cleanup separation of tenant and user invite
export function Signup() {
  const [agreement, setAgreement] = useState(false);

  const [adminEmail, setAdminEmail] = useState("");
  const [adminName, setAdminName] = useState("");

  const [tenantEmail, setTenantEmail] = useState("");
  const [tenantName, setTenantName] = useState("");

  const [website, setWebsite] = useState("");

  const [loading, setLoading] = useState(false);
  const [showRedirect, setShowRedirectMessage] = useState(false);

  const handleSetAgreement = () => setAgreement(!agreement);

  const createAccount = (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
  };

  const visibleForm = () => {
    return (
      <>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6">
            {window.getCTTranslatedText("Hello")}!
          </Typography>

          <Typography variant="subtitle1">
            {window.getCTTranslatedText("LetsSetupTenantAccount", {
              tenantName: "",
            })}
          </Typography>
        </Box>

        <TextField
          type="name"
          label={window.getCTTranslatedText("TenantName")}
          name="tenantName"
          variant="standard"
          fullWidth
          required
          value={tenantName}
          onChange={e => setTenantName(e.target.value)}
        />
        <TextField
          type="email"
          label={window.getCTTranslatedText("TenantEmail")}
          name="tenantEmail"
          variant="standard"
          fullWidth
          required
          value={tenantEmail}
          onChange={e => setTenantEmail(e.target.value)}
        />

        <TextField
          type="name"
          label={window.getCTTranslatedText("AdminName")}
          name="adminName"
          variant="standard"
          fullWidth
          required
          value={adminName}
          onChange={e => setAdminName(e.target.value)}
        />
        <TextField
          type="email"
          label={window.getCTTranslatedText("AdminEmail")}
          name="adminEmail"
          variant="standard"
          fullWidth
          required
          value={adminEmail}
          onChange={e => setAdminEmail(e.target.value)}
        />

        <TextField
          type="website"
          label={window.getCTTranslatedText("Website")}
          name="website"
          variant="standard"
          fullWidth
          value={website}
          onChange={e => setWebsite(e.target.value)}
        />

        <Box display="flex" alignItems="center">
          <Checkbox
            sx={{ p: 0, mr: 2 }}
            required={true}
            checked={agreement}
            onChange={handleSetAgreement}
          />
          <Typography
            onClick={handleSetAgreement}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            {window.getCTTranslatedText("I accept the")}&nbsp;
          </Typography>
          <Link
            target="_blank"
            href={`https://colortokens.com/terms-of-service`}
            underline="hover"
          >
            {window.getCTTranslatedText("Terms and conditions")}
          </Link>
        </Box>
      </>
    );
  };

  const hiddenForm = () => {
    return <HiddenForm email={adminEmail} loading={showRedirect} />;
  };

  return (
    <AuthForm
      buttonAction="Sign Up"
      action={`${API_URL}/auth/invite/tenant`}
      onSubmit={createAccount}
      visibleForm={visibleForm()}
      hiddenForm={hiddenForm()}
      loading={loading}
      setLoading={setLoading}
      setShowRedirectMessage={setShowRedirectMessage}
      validateForm={() => {
        return (
          isValidEmail(tenantEmail) &&
          Boolean(tenantName) &&
          isValidEmail(adminEmail) &&
          Boolean(adminName) &&
          agreement
        );
      }}
      authConfiguration={{
        adminEmail,
        adminName,
        tenantEmail,
        tenantName,
        website,
      }}
    >
      <>
        {
          <Box mt={3} textAlign="center">
            <Typography color="text">
              {window.getCTTranslatedText("existingAccountPrompt")}&nbsp;
              <Link component={RouterLink} to={`/auth/login`} underline="hover">
                {window.getCTTranslatedText("Sign In")}
              </Link>
            </Typography>
          </Box>
        }
      </>
    </AuthForm>
  );
}
