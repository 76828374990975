import { Box, Stack, Tooltip, Link, Typography } from "@mui/material";
import { GridGroupNode, useGridApiContext } from "@mui/x-data-grid-pro";
import { Link as RouterLink } from "react-router-dom";
import { ApplianceColumnRenderProps, HaRole } from "pages/appliances/types";
import RepeatIcon from "@mui/icons-material/Repeat";
import numeral from "numeral";

export function ApplianceName({
  agent,
  viewOnly,
  page = "appliances",
}: Readonly<ApplianceColumnRenderProps>) {
  const apiRef = useGridApiContext();
  const rowNode = apiRef.current.getRowNode(
    agent?.agentId ?? ""
  ) as GridGroupNode;
  const childrenCount = rowNode?.children?.length ?? 0;
  if (!agent?.agentId) {
    return <Box style={{ minWidth: 120 }} />;
  }
  const haIcon =
    agent?.haRole === HaRole.Primary ? (
      <Tooltip
        title={window.getCTTranslatedText("highAvailability")}
        placement="bottom-start"
      >
        <RepeatIcon fontSize="small" color={"disabled"} />
      </Tooltip>
    ) : null;

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <Stack>
        {viewOnly ? (
          <Typography variant="body2" noWrap>
            {agent?.agentName || ""}
          </Typography>
        ) : (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip
              title={window.getCTTranslatedText(
                window.getCTTranslatedText("viewApplianceDetails")
              )}
              placement="bottom-start"
              arrow={false}
            >
              <Link
                typography={"body2"}
                underline="hover"
                color="inherit"
                component={RouterLink}
                to={`/${page}/${agent.agentName}`}
                noWrap
              >
                {agent?.agentName || ""}{" "}
                {childrenCount > 0 &&
                  `(${numeral(childrenCount).format("0,0")})`}
              </Link>
            </Tooltip>
            {haIcon}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
