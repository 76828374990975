import { Stack } from "@mui/material";
import {
  GridToolbar,
  ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { TagRule } from "../../types";

export interface TagRulesToolbarProps extends Omit<ToolbarProps, "children"> {
  selectedData: Array<TagRule>;
}

export default function TagRulesToolbar(props: TagRulesToolbarProps) {
  // const actions = (
  //   <Stack
  //     direction="row"
  //     spacing={2}
  //     alignItems={"center"}
  //     sx={{ height: "100%" }}
  //   ></Stack>
  // );
  return (
    <GridToolbar {...props}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ width: "100%" }}
        justifyItems="flex-end"
        alignItems={"center"}
        justifyContent="flex-end"
      ></Stack>
    </GridToolbar>
  );
}
