import { Invite } from "pages/invite";
import { Login } from "pages/login";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ROUTES } from "./routes";
import { Signup } from "pages/signup";

export const PublicRoutes = () => {
  const location = useLocation();

  if (location.pathname.startsWith("/auth")) {
    return (
      <Routes>
        <Route path={ROUTES.AUTH_SIGNUP.pathname} element={<Signup />} />;
        <Route path={ROUTES.AUTH_INVITE.pathname} element={<Invite />} />;
        <Route path={ROUTES.AUTH_LOGIN.pathname} element={<Login />} />;
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path={ROUTES.AUTH_INVITE.pathname} element={<Invite />} />;
      <Route
        path={ROUTES.ALL.pathname}
        element={<Navigate to={ROUTES.AUTH_LOGIN.pathname} replace={true} />}
      />
      ;
    </Routes>
  );
};
