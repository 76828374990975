import { Stack, Typography } from "@mui/material";

interface AgentDownloadListHeaderProps {
  title: string;
  renderActions?: () => React.ReactNode;
}

export const AgentDownloadListHeader = ({
  title,
  renderActions,
}: AgentDownloadListHeaderProps) => {
  return (
    <Stack direction={"row"} justifyContent="space-between">
      <Typography variant="h6" sx={{ my: 1 }}>
        {window.getCTTranslatedText(title)}
      </Typography>
      {renderActions?.()}
    </Stack>
  );
};
