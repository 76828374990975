import { CoreStoreBuilder } from "modules/core/store";
import { CustomCommonStore } from "pages/asset/components/asset-detail/components/asset-review-drawer/store/common/useAssetDetailCommonStore";
import { create } from "zustand";
import FacetStoreBuilder from "../../facets/store/FacetStoreBuilder";
import { WorkRequestStore } from "./types";

export const useWorkRequestStore = create<WorkRequestStore>()(set => ({
  ...CoreStoreBuilder(set),
  isOpen: false,
  setOpen: isOpen => set({ isOpen }),
}));

export const useWorkRequestFacetStore = create<CustomCommonStore>()(set => ({
  ...FacetStoreBuilder(set),
}));
