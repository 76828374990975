import { KeyboardArrowRight } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import { MenuItemOption } from "common/molecules/more-options-menu/types";
import { useState } from "react";

interface MenuItemWithDropDownProps {
  label: string;
  disabled?: boolean;
  handleOuterMenuClose: Function;
  menuOptions: Array<MenuItemOption>;
}

export const MenuItemWithDropDown = ({
  label,
  disabled,
  handleOuterMenuClose,
  menuOptions,
}: MenuItemWithDropDownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isDropDownMenuOpen = Boolean(anchorEl);

  const handleAnchorElOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropDownMenuClose = () => {
    setAnchorEl?.(null);
    handleOuterMenuClose();
  };

  return (
    <>
      <MenuItem onClick={handleAnchorElOpen} disabled={disabled}>
        {window.getCTTranslatedText(label)}
        <ListItemIcon>
          <KeyboardArrowRight fontSize="small" />
        </ListItemIcon>
      </MenuItem>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={isDropDownMenuOpen}
        onClose={handleDropDownMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            width: "150px",
          },
        }}
      >
        {menuOptions.map(option => {
          return (
            <MenuItem
              key={option.label}
              onClick={() => {
                handleDropDownMenuClose();
                option.handler();
              }}
            >
              {window.getCTTranslatedText(option.label)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
