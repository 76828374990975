import { useQuery } from "@tanstack/react-query";

export interface ConfiguredDataResult {
  configID: string;
  authName: string;
  authType: string;
  clientDetail: string;
}

export function useAuthConfigAPI() {
  return useQuery<Array<ConfiguredDataResult>, Error>([
    "auth",
    "auth/admin/auth-config",
  ]);
}
