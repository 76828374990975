import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { AuditMessage } from "../audit-message";
import { AuditTime } from "../audit-time";
import { AuditUserAndLocation } from "../audit-user-and-Location";
import { AuditCategory } from "../audit-category";
import { AuditAction } from "../audit-action";
import { AuditType } from "../audit-type";
import { AuditAsset } from "../audit-asset";

export const BASE_AUDIT_LOG_COLUMNS: GridColDef[] = [
  {
    field: "eventType",
    headerName: "Type",
    sortable: false,
    width: 80,
    minWidth: 80,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AuditCategory audit={params?.row} />;
    },
  },
  {
    field: "asset",
    headerName: "Asset",
    width: 240,
    minWidth: 160,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AuditAsset audit={params.row} />;
    },
  },
  {
    field: "eventCategory",
    headerName: "Category",
    sortingOrder: ["asc", "desc"],
    width: 120,
    minWidth: 120,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AuditType audit={params?.row} />;
    },
  },

  {
    field: "action",
    headerName: "Log Name",
    sortingOrder: ["asc", "desc"],
    width: 240,
    minWidth: 240,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AuditAction audit={params?.row} />;
    },
  },
  {
    field: "message",
    headerName: "Description",
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AuditMessage audit={params.row} />;
    },
  },
  {
    field: "creationtime_ts",
    headerName: "Triggered Time",
    sortingOrder: ["asc", "desc"],
    width: 240,
    minWidth: 240,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AuditTime audit={params.row} />;
    },
  },
  {
    field: "actionBy",
    headerName: "Triggered By",
    width: 240,
    minWidth: 240,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AuditUserAndLocation audit={params?.row} />;
    },
  },
];

const ColumnVisibilityModel: { [key: string]: boolean } = {
  // Hide columns from the display
  category: false,
  eventType: false,
  sourceIp: false,
  resourceId: false,
};

const PinnedColumns = {
  left: ["creationTime", "assetName"],
  right: ["resource"],
};

export const AuditColumnConfig = {
  ColumnVisibilityModel: ColumnVisibilityModel,
  PinnedColumns: PinnedColumns,
};

export const AUDIT_LOG_PINNED_COLUMNS = {
  left: ["eventType", "asset"],
  right: [],
};
