import CircleIcon from "@mui/icons-material/Circle";
import { Box, Stack, Tooltip } from "@mui/material";
import green from "@mui/material/colors/green";
import {
  AssetAvailabilityEnums,
  AssetColumnRenderProps,
  AssetType,
} from "pages/assets/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

const iconStyle = { margin: "3px 0 3px 6px" };

export function DeviceStatus({ asset, viewOnly }: AssetColumnRenderProps) {
  if (!asset?.assetId) {
    return <Box style={{ width: 120 }} />;
  }

  let icon: React.ReactElement | undefined = (
    <CircleIcon color={"disabled"} style={iconStyle} />
  );

  switch (asset?.assetAvailability) {
    case AssetAvailabilityEnums.Active:
      icon = <CircleIcon style={{ ...iconStyle, color: green[600] }} />;
      break;
    case AssetAvailabilityEnums.Connected:
      icon = <CircleIcon style={{ ...iconStyle, color: green[200] }} />;
      break;
    default:
      icon = undefined;
  }

  return (
    <Stack
      sx={{ maxWidth: "100%" }}
      alignItems="center"
      justifyContent="center"
      direction="row"
    >
      {asset?.type === AssetType.Device && (
        <Tooltip
          title={window.getCTTranslatedText(asset?.assetAvailability ?? "")}
        >
          <CTDatagridButton
            startIcon={icon}
            asText={viewOnly === true || !asset?.assetAvailability}
            isDisabled={viewOnly === true || !asset?.assetAvailability}
            facetName={"assetavailability"}
            facetOption={asset?.assetAvailability ?? ""}
          >
            {window.getCTTranslatedText(asset?.assetAvailability ?? "")}
          </CTDatagridButton>
        </Tooltip>
      )}
    </Stack>
  );
}
