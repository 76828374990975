import { TAG_COLUMNS } from "./constants";
import { useState } from "react";
import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  DataGridStyles,
} from "modules/data-grid/components/data-grid/DataGrid";

export const TagDataGrid = (props: any) => {
  const [pageSize, setPageSize] = useState(100);

  return (
    <DataGrid
      sx={{
        ...DataGridStyles,
        "& .MuiDataGrid-row:hover": {
          background: "transparent",
        },
      }}
      loading={props?.loading || !props?.rows}
      rows={props?.rows || []}
      rowCount={props?.rows?.length}
      rowHeight={64}
      columns={TAG_COLUMNS}
      getRowId={({ key, value }: any) => `${key}='${value}'`}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      pagination
      paginationMode="client"
      disableColumnMenu
      disableRowSelectionOnClick
      slots={{
        ...props.slots,
        loadingOverlay: LinearProgress,
      }}
    />
  );
};
