import { FieldsOption } from "modules/form-fields/type";
import { CrowdStrikeConfigResult } from "./type";

export const INTEGRATION_PROVIDERS = {
  CROWDSTRIKE: "crowdstrike",
  CLAROTY: "claroty",
  MEDIGATE: "medigate",
  RAPID7: "rapid7",
  SPLUNK: "splunk",
  QRADAR: "qradar",
  ARCSIGHT: "arcsight",
  OTHERS: "others",
  OAUTH: "oauth",
  SAML: "saml",
  SCIM: "scim",
  XCLOUD: "xcloud",
  SERVICENOW: "servicenow",
  NESSUS: "nessus",
  VSPHERE: "vsphere",
  MSTEAMS: "msteams",
};

export const INTEGRATION_TYPES = {
  CMDB: "cmdb",
  EDR: "edr",
  VULNERABILITY_SCANNER: "vulnerabilityscanner",
  SIEM: "siem",
  IDENTITY_PROVIDER: "identityprovider",
  USER_PROVISIONING: "userprovisioning",
  CONNECTORS: "connectors",
  COLLABORATION: "collaboration",
};

export enum INTEGRATIONS {
  CLAROTY_CMDB = "CLAROTY_CMDB",
  MEDIGATE_CMDB = "MEDIGATE_CMDB",
  CROWDSTRIKE_CMDB = "CROWDSTRIKE_CMDB",
  CROWDSTRIKE_EDR = "CROWDSTRIKE_EDR",
  SERVICENOW_CMDB = "SERVICENOW_CMDB",
  RAPID7_VULNERABILITYSCANNER = "RAPID7_VULNERABILITYSCANNER",
  NESSUS_VULNERABILITYSCANNER = "NESSUS_VULNERABILITYSCANNER",
  XCLOUD_VULNERABILITYSCANNER = "XCLOUD_VULNERABILITYSCANNER",
  SPLUNK_SIEM = "SPLUNK_SIEM",
  ARCSIGHT_SIEM = "ARCSIGHT_SIEM",
  QRADAR_SIEM = "QRADAR_SIEM",
  OTHERS_SIEM = "OTHERS_SIEM",
  OAUTH_IDENTITYPROVIDER = "OAUTH_IDENTITYPROVIDER",
  SAML_IDENTITYPROVIDER = "SAML_IDENTITYPROVIDER",
  SCIM_USERPROVISIONING = "SCIM_USERPROVISIONING",
  VSPHERE_CONNECTORS = "VSPHERE_CONNECTORS",
  MSTEAMS_COLLABORATION = "MSTEAMS_COLLABORATION",
}

export const getIntegrationEnum = (
  integration: IntegrationConfigType | FieldsOption | CrowdStrikeConfigResult,
) => {
  let key = `${integration.integrationProvider?.toUpperCase()}_${integration.integrationType?.toUpperCase()}`;
  return INTEGRATIONS[key as keyof typeof INTEGRATIONS];
};

export const ALLOWED_INTEGRATIONS: { [key: string]: boolean } = {};

for (let key in INTEGRATIONS) {
  if (INTEGRATIONS.hasOwnProperty(key)) {
    ALLOWED_INTEGRATIONS[key] = true;
  }
}

export const EXPERIMENTAL = [
  INTEGRATIONS.CROWDSTRIKE_CMDB,
  INTEGRATIONS.MSTEAMS_COLLABORATION,
];

for (let experimental of EXPERIMENTAL) {
  ALLOWED_INTEGRATIONS[experimental] = false;
}

export const INTEGRATION_CATEGORY = {
  ALL: "All",
  OT_IOT: "OT/IOT",
  ENDPOINT: "Endpoint",
  CMDB: "CMDB",
  CONNECTORS: "Connectors",
  IDENTITY_PROVIDER: "Identity Provider",
  SIEM: "SIEM",
  USER_PROVISIONING: "User Provisioning",
  VULNERABILITY_ASSESSMENT: "Vulnerability Assessment",
};

export interface IntegrationConfigType {
  name: string;
  description: string;
  status: boolean;
  category: string;
  readonly: boolean;
  integrationProvider: string;
  integrationType: string;
  displayCardName?: string;
  disable?: boolean;
  configData?: any;
  isLoading?: boolean;
  configured?: boolean;
}

export interface Integration {
  integrationProvider: string;
  integrationType: string;
  status?: boolean;
  lastsynctime?: string;
  integrationParameters?: any;
}

export interface Reconciliation {
  reconciliationIdentifier: Array<any>;
  reconciliationAttributes: Array<any>;
}

export interface TeamsChannelData {
  channels: Array<TeamsChannel>;
}

export interface TeamsChannel {
  channelName: string;
  webhookUrl: string;
}

export const TEAMS_CONFIG = {
  CHANNEL_NAME: "channelName",
  WEBHOOK_URL: "webhookUrl",
};

export const INTEGRATION_STATE = {
  CREATE: "create",
  EDIT: "edit",
};

export type IntergationType = any;
