import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import numeral from "numeral";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import * as semver from "semver";
import { AgentActionType } from "../agent-data-grid-toolbar/Toolbar";
import {
  isHigherVersion,
  isHigherorEqualVersions,
} from "../agent-data-grid/helpers/columnHelpers";
import { AgentDrawer } from "./AgentDownloadDrawer";
import { AgentDrawerSwitchControl } from "./AgentDrawerSwitchControl";
import {
  GetAgentInstallationInfoManual,
  GetAgentInstallationInfoScript,
} from "./AgentInstallationInfo";
import { Terminal } from "./Terminal";
import {
  agentTypeValues,
  agent_types,
  agent_types_radio_button,
  architectureType,
  installScript,
  manualInstall,
  platforms,
} from "./constants";
import {
  processPlatformDisplayName,
  renderProcessPlatformIcon,
} from "./helpers";
import { AgentVersion, ArchMap, IAgentTypes, VersionInfo } from "./type";
import { AgentGroupByObj } from "../agent-drawers/hooks/useGroupByAgentData";

function RenderDownloadLink(data: VersionInfo[], agentVersion: string) {
  for (let versionInfo of data) {
    if (versionInfo?.version === agentVersion) {
      return versionInfo.downloadLink;
    }
  }
}

function RenderDownloadFileName(data: VersionInfo[], agentVersion: string) {
  for (let versionInfo of data) {
    if (versionInfo.version === agentVersion) {
      return versionInfo.fileName;
    }
  }

  return;
}

export function DownloadItem(props: AgentVersion) {
  const [architecture, setArchitecture] = useState<string>(
    supportedPlatformArchitectures()[0]
  );

  const defaultAgentType = () => {
    switch (props?.platform) {
      case platforms.AIX:
      case platforms.RPM_LEGACY:
      case platforms.SOLARIS:
        return agent_types.CT_LEGACY_AGENT;
      default:
        return agent_types.CT_AGENT;
    }
  };

  const [selectedOption, setSelectedOption] = useState<agent_types>(() => {
    switch (props.agentType) {
      case agent_types.CT_REMOTE_CONNECTOR:
      case agent_types.CT_CONTAINER_AGENT:
        return props.agentType;
      default:
        return props.agentType ?? defaultAgentType();
    }
  });
  const theme = useTheme();

  const [agentServerVersion, setAgentServerVersion] = useState(() => {
    const latestVersionArr = props?.agentServerVersions.filter(
      arch => architecture === arch.architecture && arch.recommended
    );

    return latestVersionArr.length > 0 ? latestVersionArr[0]?.version : "";
  });

  const [agentUserVersion, setAgentUserVersion] = useState(() => {
    const latestVersionArr = props?.agentUserVersions.filter(
      arch => architecture === arch.architecture && arch.recommended
    );

    return latestVersionArr.length > 0 && latestVersionArr[0]?.version;
  });

  const [agentConnectorVersion, setAgentConnectorVersion] = useState(() => {
    const latestVersionArr = props?.agentConnectorVersions.filter(
      arch => architecture === arch.architecture && arch.recommended
    );

    return latestVersionArr.length > 0 && latestVersionArr[0]?.version;
  });

  const [agentContainerVersion, setAgentContainerVersion] = useState(() => {
    const latestVersionArr = props?.agentContainerVersions.filter(
      arch => arch.recommended
    );

    return latestVersionArr.length > 0 && latestVersionArr[0]?.version;
  });
  const [agentLegacyVersion, setAgentLegacyVersion] = useState(() => {
    const latestVersionArr = props?.agentLegacyVersions.filter(
      arch => arch.recommended
    );

    return latestVersionArr.length > 0 && latestVersionArr[0]?.version;
  });
  const selectedAgent = agentTypeValues[selectedOption as keyof IAgentTypes];
  const linuxScriptDownloadLink =
    props?.latestLinuxScriptDetails?.versionsInfo[0]?.downloadLink;
  const windowsScriptDownloadLink =
    props?.latestWindowsScriptDetails?.versionsInfo[0]?.downloadLink;

  const [drawerISourceOpen, setDrawerISourceOpen] = useState(false);
  const onCloseInstallSourceDrawer = (value?: boolean) => {
    setDrawerISourceOpen(false);
  };

  function getAgentVersionInfo() {
    if (
      selectedOption === agent_types.CT_AGENT ||
      props?.platform === platforms.WINDOWS ||
      props?.platform === platforms.DARWIN
    ) {
      return props?.agentServerVersions.filter(
        arch => architecture === arch.architecture
      );
    } else if (
      selectedOption === agent_types.CT_USER_AGENT &&
      props?.platform !== platforms.WINDOWS &&
      props?.platform !== platforms.DARWIN
    ) {
      return props?.agentUserVersions.filter(
        arch => architecture === arch.architecture
      );
    } else if (selectedOption === agent_types.CT_CONTAINER_AGENT) {
      return props?.agentContainerVersions.filter(
        arch => architecture === arch.architecture
      );
    } else if (selectedOption === agent_types.CT_LEGACY_AGENT) {
      return props?.agentLegacyVersions.filter(
        arch => architecture === arch.architecture
      );
    } else {
      return props?.agentConnectorVersions.filter(
        arch => architecture === arch.architecture
      );
    }
  }

  let agentVersionTypeinfo: VersionInfo[] = getAgentVersionInfo();
  const installScriptDisabled = () => {
    return Boolean(agentVersionTypeinfo?.length === 0);
  };

  const [drawerIScriptOpen, setDrawerIScriptOpen] = useState(false);
  const onCloseIScriptDrawer = (value?: boolean) => {
    setDrawerIScriptOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    if (
      !isHigherorEqualVersions(highestCurrentAgentVersion, event?.target?.value)
    ) {
      switch (selectedOption) {
        case agent_types.CT_AGENT:
          setAgentServerVersion(event?.target?.value);
          break;
        case agent_types.CT_USER_AGENT:
          setAgentUserVersion(event?.target?.value);
          break;
        case agent_types.CT_CONTAINER_AGENT:
          setAgentContainerVersion(event?.target?.value);
          break;
        case agent_types.CT_LEGACY_AGENT:
          setAgentLegacyVersion(event?.target?.value);
          break;
        default:
          setAgentConnectorVersion(event?.target?.value);
      }
      updateGroupByPlaformsData(event);
    }
  };

  let agentGrpByObj: AgentGroupByObj = { ...props.agentsGroupByPlatforms };

  if (agentGrpByObj[props?.platform]) {
    agentGrpByObj[props?.platform].architecture = architecture;
  }
  const upgradeAgentCount =
    props?.agentsGroupByPlatforms?.[props.platform]?.data?.filter(
      arch => arch.architecture === architecture
    )?.length ?? 0;

  const updateGroupByPlaformsData = (event: SelectChangeEvent) => {
    if (
      props?.page === AgentActionType.UPGRADE &&
      props?.agentsGroupByPlatforms &&
      props?.platform &&
      props?.agentsGroupByPlatforms[props?.platform] &&
      event?.target?.value
    ) {
      if (agentGrpByObj[props?.platform].version !== event?.target?.value) {
        agentGrpByObj[props?.platform].version = event?.target?.value;
        if (props?.setAgentsGroupByPlatforms) {
          props?.setAgentsGroupByPlatforms(agentGrpByObj);
        }
      }
    }
  };
  const updateGroupByPlaformsDataArch = (event: SelectChangeEvent) => {
    if (
      props?.page === AgentActionType.UPGRADE &&
      props?.agentsGroupByPlatforms &&
      props?.platform &&
      props?.agentsGroupByPlatforms[props?.platform] &&
      event?.target?.value
    ) {
      if (
        agentGrpByObj[props?.platform].architecture !== event?.target?.value
      ) {
        agentGrpByObj[props?.platform].architecture = event?.target?.value;
        if (props?.setAgentsGroupByPlatforms) {
          props?.setAgentsGroupByPlatforms(agentGrpByObj);
        }
      }
    }
  };

  const handleChangeArch = (event: SelectChangeEvent) => {
    setArchitecture(event?.target?.value);
    updateGroupByPlaformsDataArch(event);
  };

  function setSelectedValue(event: SelectChangeEvent) {
    switch (event?.target?.value) {
      case agent_types.CT_AGENT:
        setSelectedOption(agent_types.CT_AGENT);
        break;
      case agent_types.CT_LEGACY_AGENT:
        setSelectedOption(agent_types.CT_LEGACY_AGENT);
        break;
      case agent_types.CT_USER_AGENT:
        setSelectedOption(agent_types.CT_USER_AGENT);
        break;
      case agent_types.CT_CONTAINER_AGENT:
        setSelectedOption(agent_types.CT_CONTAINER_AGENT);
        break;
    }
  }

  const selectedAgentType = useMemo(() => {
    let filteredVersions = agentVersionTypeinfo.filter(
      (versionInfo: VersionInfo) => {
        let selectedVersion;
        switch (selectedOption) {
          case agent_types.CT_AGENT:
            selectedVersion = agentServerVersion;
            break;
          case agent_types.CT_USER_AGENT:
            selectedVersion = agentUserVersion;
            break;
          case agent_types.CT_CONTAINER_AGENT:
            selectedVersion = agentContainerVersion;
            break;
          case agent_types.CT_LEGACY_AGENT:
            selectedVersion = agentLegacyVersion;
            break;
          default:
            selectedVersion = agentConnectorVersion;
            break;
        }

        return versionInfo.version === selectedVersion;
      }
    );

    let currentVersion: string = "";

    if (
      filteredVersions.length > 0 &&
      filteredVersions[0].architecture === architecture
    ) {
      currentVersion = filteredVersions[0]?.version;
    } else {
      let newLatestVersion = agentVersionTypeinfo.find(
        (versionInfo: VersionInfo) => {
          return (
            versionInfo.architecture === architecture && versionInfo.recommended
          );
        }
      );

      currentVersion = newLatestVersion ? newLatestVersion.version : "";
    }

    return currentVersion;
  }, [
    agentServerVersion,
    agentUserVersion,
    agentConnectorVersion,
    agentContainerVersion,
    agentLegacyVersion,
    agentVersionTypeinfo,
    architecture,
    selectedOption,
  ]);

  const [highestCurrentAgentVersion, setHighestAgentVersion] =
    useState("0.0.0");

  useEffect(() => {
    const highestVersionAgentInSelection = () => {
      if (
        props?.page === AgentActionType.UPGRADE &&
        props?.agentsGroupByPlatforms &&
        props?.platform &&
        props?.agentsGroupByPlatforms[props?.platform]
      ) {
        let agentData = props.agentsGroupByPlatforms[props?.platform];
        if (agentData?.data?.length > 0) {
          let highestVersion = agentData.data[0]?.currentVersion ?? "0.0.0";
          for (let i = 0; i < agentData.data.length; i++) {
            let newVersion = agentData.data[i]?.currentVersion;
            if (isHigherVersion(highestVersion, newVersion)) {
              highestVersion = newVersion;
            }
          }
          if (semver.neq(highestVersion, highestCurrentAgentVersion)) {
            setHighestAgentVersion(highestVersion);
          }
        }
      }
    };

    const setCurrentVersion = (currentVersion?: string) => {
      if (
        props?.page === AgentActionType.UPGRADE &&
        props?.agentsGroupByPlatforms &&
        props?.platform &&
        props?.agentsGroupByPlatforms[props?.platform] &&
        currentVersion
      ) {
        highestVersionAgentInSelection();
        let agentGrpByObj = { ...props.agentsGroupByPlatforms };
        if (agentGrpByObj[props?.platform].version !== currentVersion) {
          agentGrpByObj[props?.platform].version = currentVersion;
          if (props?.setAgentsGroupByPlatforms) {
            props?.setAgentsGroupByPlatforms(agentGrpByObj);
          }
        }
      }
    };

    if (selectedAgentType) {
      setCurrentVersion(selectedAgentType);
    }
  }, [highestCurrentAgentVersion, props, selectedAgentType]);

  function renderArchitectureOption(option: string, index: number) {
    const isCTAgent = option === agent_types.CT_AGENT;
    const isCTUserAgent = option === agent_types.CT_USER_AGENT;
    const isCTContainerAgent = option === agent_types.CT_CONTAINER_AGENT;
    const isCTLegacyAgent = option === agent_types.CT_LEGACY_AGENT;
    const isAix = props?.platform === platforms.AIX;
    const isSolaris = props?.platform === platforms.SOLARIS;
    const isRpmLegacy = props?.platform === platforms.RPM_LEGACY;
    const isDebianOrRpmAixSolaris =
      props?.platform === platforms.DEBIAN ||
      props?.platform === platforms.RPM ||
      props?.platform === platforms.AIX ||
      props?.platform === platforms.RPM_LEGACY ||
      props?.platform === platforms.SUSE ||
      props?.platform === platforms.SOLARIS;

    const isDocker = props?.platform === platforms.DOCKER;

    const shouldRender =
      (isCTContainerAgent && isDocker) ||
      (isCTAgent && !isAix && !isRpmLegacy && !isSolaris) ||
      (!isDebianOrRpmAixSolaris && isCTUserAgent) ||
      ((isAix || isRpmLegacy || isSolaris) && isCTLegacyAgent);

    if (isDocker && !isCTContainerAgent) {
      return null;
    }
    if (!shouldRender) {
      return null;
    }
    const label =
      agent_types_radio_button[option as keyof typeof agent_types_radio_button];
    return (
      <FormControlLabel
        key={index}
        value={option}
        control={<Radio size="small" color="default" />}
        label={window.getCTTranslatedText(label)}
      />
    );
  }

  function supportedPlatformArchitectures() {
    return props?.page === AgentActionType.UPGRADE
      ? supportedPlatformArchitecturesUpgradePage()
      : supportedPlatformArchitecturesDownlaodPage();
  }
  function supportedPlatformArchitecturesDownlaodPage() {
    const platform = props?.platform;
    const { X86_64, ARM64, PPC } = architectureType;

    switch (platform) {
      case platforms.WINDOWS:
      case platforms.SOLARIS:
      case platforms.RPM_LEGACY:
        return [X86_64];
      case platforms.AIX:
        return [PPC];
      case platforms.DARWIN:
        return [ARM64, X86_64];
      default:
        return [X86_64, ARM64];
    }
  }

  function supportedPlatformArchitecturesUpgradePage() {
    let avaialble_arch_platform = supportedPlatformArchitecturesDownlaodPage();
    let agentGroupbyPlatform =
      props?.agentsGroupByPlatforms?.[props.platform]?.data ?? [];
    const archMap: ArchMap = {};
    for (const element of agentGroupbyPlatform) {
      archMap[element.architecture] = true;
    }
    const filteredPlatform = avaialble_arch_platform.filter(
      element => archMap[element]
    );
    return filteredPlatform;
  }

  return (
    <Stack
      sx={{
        px: props?.page !== AgentActionType.UPGRADE ? 5 : 0,
        py: 5,
        height: props?.page !== AgentActionType.UPGRADE ? 100 : 50,
        width: "100%",
        background: theme =>
          props?.page === AgentActionType.UPGRADE
            ? "transparent"
            : theme.palette.mode === "dark"
            ? theme.palette.divider
            : theme.palette.background.paper,
      }}
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      boxShadow={props?.page !== AgentActionType.UPGRADE ? 2 : 0}
      borderRadius={1}
    >
      <Stack direction="row" spacing={1} minWidth={180}>
        <Stack justifyContent={"center"}>
          {renderProcessPlatformIcon(
            props.platform,
            theme.palette.text.primary
          )}
        </Stack>
        <Stack justifyContent={"center"}>
          {processPlatformDisplayName(props.platform)}
        </Stack>
      </Stack>
      <Stack>
        <FormControl>
          {props.platform === platforms.DOCKER ? (
            <Stack
              sx={{
                display: "flex",
                textAlign: "center",
                fontSize: "small",
                minWidth: 100,
              }}
            ></Stack>
          ) : (
            <Select
              displayEmpty
              sx={{
                display: "flex",
                textAlign: "center",
                fontSize: "small",
                minWidth: 100,
              }}
              value={architecture}
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleChangeArch}
            >
              {supportedPlatformArchitectures()?.map(
                (arch: string, index: any) => (
                  <MenuItem
                    sx={{
                      display: "flex",
                      backgroundColor: "initial",
                      fontSize: "small",
                      justifyContent: "center",
                    }}
                    key={index}
                    value={arch}
                  >
                    {window.getCTTranslatedText(arch)}
                  </MenuItem>
                )
              )}
            </Select>
          )}
        </FormControl>
      </Stack>
      {props?.page !== AgentActionType.UPGRADE &&
        props.agentType !== agent_types.CT_REMOTE_CONNECTOR && (
          <Stack>
            <RadioGroup
              sx={{ minWidth: 150 }}
              aria-label="agent-type"
              color="info"
              name="agent-type"
              value={selectedOption}
              onChange={setSelectedValue}
            >
              {Object.keys(agent_types).map((option: any, index: any) => {
                return renderArchitectureOption(option, index);
              })}
            </RadioGroup>
          </Stack>
        )}
      {props?.page === AgentActionType.UPGRADE && (
        <Stack sx={{ minWidth: 120, paddingLeft: 2 }}>
          <Typography variant="body1" noWrap>
            {`${numeral(upgradeAgentCount).format("0,0")}${" "}
              ${window.getCTTranslatedText(
                pluralize("Agent", upgradeAgentCount ?? 0)
              )}`}
          </Typography>
        </Stack>
      )}
      <Stack>
        <FormControl>
          <Select
            displayEmpty
            sx={{
              display: "flex",
              textAlign: "left",
              fontSize: "small",
              minWidth: 220,
            }}
            value={selectedAgentType}
            inputProps={{ "aria-label": "Without label" }}
            onChange={handleChange}
          >
            {agentVersionTypeinfo?.map((vName: any, index: any) => (
              <MenuItem
                sx={{
                  display: "flex",
                  backgroundColor: "initial",
                  fontSize: "small",
                  justifyContent: "flex-start",
                }}
                key={index}
                value={vName?.version}
                disabled={isHigherorEqualVersions(
                  highestCurrentAgentVersion,
                  vName?.version
                )}
              >
                <Tooltip
                  placement="left"
                  title={window.getCTTranslatedText(
                    isHigherorEqualVersions(
                      highestCurrentAgentVersion,
                      vName?.version
                    )
                      ? `This version is lower than the current running version of agents selected`
                      : ""
                  )}
                >
                  <Typography variant="body2" sx={{ pointerEvents: "all" }}>
                    {vName?.version}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {props.platform === platforms.DOCKER ||
      props?.page === AgentActionType.UPGRADE ? (
        <Stack sx={{ minWidth: 200 }} />
      ) : (
        <Stack sx={{ minWidth: 200 }}>
          <Button
            color="primary"
            variant="outlined"
            disabled={installScriptDisabled()}
            onClick={() => setDrawerIScriptOpen(true)}
          >
            {window.getCTTranslatedText(installScript)}
          </Button>

          <AgentDrawer
            drawerOpen={drawerIScriptOpen}
            onClose={onCloseIScriptDrawer}
            platform={props.platform}
            installationType={installScript}
            agentType={props.agentType}
          >
            <AgentDrawerSwitchControl
              platform={props.platform}
              agentType={selectedOption}
            >
              <Terminal>
                <GetAgentInstallationInfoScript
                  pname={props.platform}
                  agentType={selectedOption}
                  selectedAgentTypeValue={selectedAgent}
                  latestDepKey={props.latestDepKey}
                  fileName={RenderDownloadFileName(
                    agentVersionTypeinfo,
                    selectedAgentType
                  )}
                  downloadLink={RenderDownloadLink(
                    agentVersionTypeinfo,
                    selectedAgentType
                  )}
                  agentVersion={selectedAgentType}
                  latestLinuxScriptDownloadLink={linuxScriptDownloadLink}
                  latestWindowsScriptDownloadLink={windowsScriptDownloadLink}
                />
              </Terminal>
            </AgentDrawerSwitchControl>
          </AgentDrawer>
        </Stack>
      )}
      {props?.page !== AgentActionType.UPGRADE && (
        <Stack sx={{ minWidth: 100 }}>
          <Button
            color="primary"
            variant="outlined"
            disabled={agentVersionTypeinfo.length === 0}
            onClick={() => setDrawerISourceOpen(true)}
          >
            {window.getCTTranslatedText(manualInstall)}
          </Button>
          <AgentDrawer
            drawerOpen={drawerISourceOpen}
            onClose={onCloseInstallSourceDrawer}
            platform={props.platform}
            installationType={manualInstall}
            agentType={props.agentType}
          >
            <AgentDrawerSwitchControl
              platform={props.platform}
              agentType={selectedOption}
            >
              <Terminal>
                <GetAgentInstallationInfoManual
                  pname={props.platform}
                  agentType={selectedOption}
                  selectedAgentTypeValue={selectedAgent}
                  latestDepKey={props.latestDepKey}
                  fileName={RenderDownloadFileName(
                    agentVersionTypeinfo,
                    selectedAgentType
                  )}
                  downloadLink={RenderDownloadLink(
                    agentVersionTypeinfo,
                    selectedAgentType
                  )}
                  agentVersion={selectedAgentType}
                />
              </Terminal>
            </AgentDrawerSwitchControl>
          </AgentDrawer>
        </Stack>
      )}
    </Stack>
  );
}
