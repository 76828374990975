import CircleIcon from "@mui/icons-material/Circle";
import { Stack, Tooltip } from "@mui/material";
import { UsersColumnRenderProps } from "../../types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export enum LoggedInStatus {
  LoggedIn = "Logged In",
  LoggedOut = "Logged Out",
}

export const UserSessionStatus = ({
  user,
  viewOnly,
}: UsersColumnRenderProps) => {
  const typeFacetName = "signedin";
  const typeOptionName = user?.signedin?.toString();
  let icon = <CircleIcon color={"disabled"} style={{ marginRight: 8 }} />;

  let typeTitle = "-";
  if (user.signedin !== null) {
    if (user.signedin) {
      icon = <CircleIcon color={"success"} style={{ marginRight: 8 }} />;
      typeTitle = LoggedInStatus.LoggedIn;
    } else {
      typeTitle = LoggedInStatus.LoggedOut;
    }
  }
  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "space-evenly",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Stack direction="row">
        <Tooltip
          title={`${window.getCTTranslatedText(
            user.signedin !== null ? typeTitle : ""
          )}`}
        >
          <Stack>
            <CTDatagridButton
              asText={viewOnly ?? user.signedin === null}
              isDisabled={viewOnly ?? user.signedin === null}
              startIcon={user?.signedin !== null ? icon : <></>}
              facetName={typeFacetName}
              facetOption={typeOptionName}
            >
              {typeTitle}
            </CTDatagridButton>
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
