import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { SignupBg } from "assets/svgs";
import { SingupBgDark } from "assets/svgs/SingupBgDark";
import IllustrationLayout from "common/layouts/illustration";
import { useRef } from "react";

interface HiddenFormProps {
  loading: boolean;
  email?: string;
}

export const HiddenForm = ({ loading, email }: HiddenFormProps) => {
  return (
    <>
      <TextField
        name="SAMLRequest"
        variant="standard"
        fullWidth
        label="SAMLRequest"
        sx={{ display: "none" }}
        id="SAMLRequest"
      />
      <Box>
        <Stack
          direction="row"
          mt={2}
          alignItems={"center"}
          sx={{ visibility: loading ? "visible" : "hidden" }}
        >
          <Typography variant="body1">
            {window.getCTTranslatedText(
              "If you are not redirected soon, please"
            )}{" "}
          </Typography>

          <Link component="button" variant="body1" type="submit" sx={{ ml: 1 }}>
            {window.getCTTranslatedText("click here")}.
          </Link>
        </Stack>
      </Box>
    </>
  );
};

interface SignInFormProps {
  buttonAction: string;
  children: React.ReactElement;
  visibleForm: React.ReactElement;
  hiddenForm: React.ReactElement;
  validateForm?: () => boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setLoading: (e: boolean) => void;
  setShowRedirectMessage: (e: boolean) => void;
  action: string;
  loading: boolean;
  authConfiguration: {
    email?: string;
    tenantID?: string;
    inviteCode?: string;
    region?: string;
    tenantName?: string;
    tenantEmail?: string;
    adminEmail?: string;
    adminName?: string;
    website?: string;
  };
}

export const AuthForm = ({
  buttonAction,
  children,
  onSubmit,
  action,
  visibleForm,
  hiddenForm,
  setLoading,
  loading,
  setShowRedirectMessage,
  authConfiguration,
  validateForm,
}: SignInFormProps) => {
  const isValidated = useRef(false);
  const theme = useTheme();

  const submitForm = async () => {
    const body = authConfiguration;
    setTimeout(() => {
      setShowRedirectMessage(true);
    }, 2000);

    setLoading(true);

    try {
      const response = await fetch(action, {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.status >= 400) {
        const error = await response.json();
        console.error(error);
        isValidated.current = true;
        return;
      }

      if (response.status >= 300) {
        console.log("Redirecting");
        isValidated.current = true;
        return;
      }

      if (response.status === 200) {
        const data = await response.json();
        if (data?.SAMLRequest?.length > 0) {
          const SAMLField = document.getElementById(
            "SAMLRequest"
          ) as HTMLFormElement;
          SAMLField.value = data?.SAMLRequest;
        } else {
          console.error("Status code is 200 but SAMLRequest is missing");
        }
      }

      isValidated.current = true;
      const form = document.getElementById("loginForm") as HTMLFormElement;
      form.submit();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IllustrationLayout
      illustration={{
        image: theme.palette.mode === "dark" ? SingupBgDark : SignupBg,
        description: "Experience Zero Trust Atomic Segmentation",
      }}
    >
      <Stack sx={{ height: "100%", py: 8 }} alignItems="start">
        <Box
          component="img"
          src="/xShieldFull.svg"
          alt="ColorTokens Xshield"
          height="64px"
          style={{ objectFit: "contain" }}
        />
        <Stack
          spacing={3}
          sx={{ flex: 1 }}
          justifyContent="center"
          alignItems={"flex-start"}
          component="form"
          role="form"
          action={action}
          method="post"
          onSubmit={e => {
            if (!isValidated.current) {
              e.preventDefault();
              e.stopPropagation();
              submitForm();
              return;
            }

            onSubmit(e);
          }}
          id="loginForm"
        >
          <>
            {visibleForm}
            <LoadingButton
              loading={loading}
              variant="contained"
              disabled={
                validateForm ? !validateForm() : !authConfiguration?.email
              }
              onClick={() => {
                submitForm();
              }}
            >
              {window.getCTTranslatedText(buttonAction)}
            </LoadingButton>
            {children}
            {hiddenForm}
          </>
        </Stack>
      </Stack>
    </IllustrationLayout>
  );
};
