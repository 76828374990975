import {
  Button,
  DialogContent,
  Divider,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { PendingWorkRequestsIcon } from "modules/AppBar/components/PendingWorkRequestsIcon";
import numeral from "numeral";
import { AssetLoader } from "pages/asset/components/asset-loader";
import { useNetworkStore } from "pages/networks/store";
import {
  IPRange,
  NetworkDetailInt,
  NetworkFormProps,
} from "pages/networks/types";
import { NetworkFormDrawer } from "../../components/network-form-drawer";
import { RemoveNetworkRangeDialog } from "../remove-network-range-dialog";
import { useNetworkAPI } from "./hooks/useNetworkAPI";

export const NetworkDetail = ({
  namedNetworkId,
  viewOnly = false,
  hideMetadata = false,
}: {
  namedNetworkId: string | undefined;
  viewOnly?: boolean;
  hideMetadata?: boolean;
}) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const [editDrawerVisibility, setEditDrawerVisibility] = useState(false);
  const [addDrawerVisibility, setAddDrawerVisibility] = useState(false);
  const requestAPIRefresh = useNetworkStore(state => state.requestAPIRefresh);

  const onCloseAddDrawer = () => {
    setAddDrawerVisibility(false);
  };

  const openAddDrawer = () => {
    if (!data?.isOOBNetwork && !viewOnly) {
      setAddDrawerVisibility(true);
    }
  };

  const onCloseEditDrawer = () => {
    setEditDrawerVisibility(false);
  };

  const openEditDrawer = () => {
    if (!data?.isOOBNetwork) {
      setEditDrawerVisibility(true);
    }
  };

  let { data, isLoading }: any = useNetworkAPI(namedNetworkId);

  const network: NetworkDetailInt = useMemo(() => {
    if (!data) {
      return;
    }

    if (data) {
      if (!data["ipRanges"]) {
        data["ipRanges"] = [];
        data["ipRangesList"] = [];
      } else {
        const ipRangesData = data["ipRanges"].map(
          (item: IPRange, index: number) => {
            return {
              id: item?.rangeID,
              value: item?.ipRange,
              count: item?.ipCount,
            };
          }
        );
        data["ipRangesList"] = ipRangesData;
      }
    }

    return data;
  }, [data]);

  const updateNetworkData = ({
    namedNetworkName,
    namedNetworkDescription,
    domain,
  }: NetworkFormProps) => {
    requestAPIRefresh();
    data["namedNetworkName"] = namedNetworkName
      ? namedNetworkName?.trim()
      : network?.namedNetworkName;
    data["namedNetworkDescription"] = namedNetworkDescription
      ? namedNetworkDescription.trim()
      : network?.namedNetworkDescription;
    data["domain"] = domain ? domain : network?.domain;
  };

  const cellStyle = { borderBottom: "unset !important", py: 1 };
  const cellStyleTop = { borderBottom: "unset !important", pt: 3, pb: 1 };

  if (isLoading || !network) {
    return <AssetLoader></AssetLoader>;
  }

  const isEditDisabled = network?.isOOBNetwork || network?.programAsInternet;

  return (
    <>
      <DialogContent
        sx={{
          padding: hideMetadata
            ? "16px 8px"
            : viewOnly
            ? "8px 0 0"
            : !userPermissions.get("UPDATE_NAMED_NETWORK")
            ? "16px 16px 0 16px"
            : "0 0 0 24px",
          backgroundColor: theme => theme.palette.background.paper,
        }}
      >
        <Stack
          sx={{ height: "100%", width: "100%" }}
          direction={"column"}
          justifyContent="flex-start"
          spacing={2}
          p={2}
        >
          {!hideMetadata && (
            <>
              <Stack
                sx={{
                  padding: 2,
                  width: "100%",
                }}
                spacing={2}
              >
                <Stack direction={"column"}>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                  >
                    <Stack
                      alignItems="flex-start"
                      justifyContent={"flex-start"}
                      justifyItems={"center"}
                      direction="row"
                    >
                      <Stack
                        alignItems="center"
                        justifyContent={"center"}
                        justifyItems={"center"}
                      >
                        {network?.isOOBNetwork && (
                          <Stack
                            alignItems="flex-start"
                            justifyContent={"center"}
                            justifyItems={"center"}
                            sx={{ pr: 2, height: "28px" }}
                          >
                            <Tooltip
                              title={window.getCTTranslatedText(
                                "System defined network"
                              )}
                            >
                              <SettingsIcon />
                            </Tooltip>
                          </Stack>
                        )}
                        {!network?.isOOBNetwork && (
                          <Stack
                            alignItems="center"
                            justifyContent={"center"}
                            justifyItems={"center"}
                            sx={{ pr: 2, height: "32px" }}
                          >
                            <Tooltip
                              title={window.getCTTranslatedText(
                                "User defined network"
                              )}
                            >
                              <PersonIcon />
                            </Tooltip>
                          </Stack>
                        )}
                      </Stack>
                      <Stack
                        alignItems="flex-start"
                        justifyContent={"center"}
                        justifyItems={"flex-start"}
                        sx={{ maxWidth: "80%" }}
                      >
                        <Typography
                          variant="h5"
                          noWrap
                          sx={{
                            textTransform: network.isOOBNetwork
                              ? "capitalize"
                              : undefined,
                          }}
                        >
                          {network?.namedNetworkName}
                        </Typography>
                        <Typography variant="body2" noWrap sx={{ pl: 0.5 }}>
                          {network?.namedNetworkDescription}
                        </Typography>
                      </Stack>
                    </Stack>

                    {userPermissions.has("UPDATE_NAMED_NETWORK") && !viewOnly && (
                      <Stack
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        justifyItems="flex-start"
                        sx={{
                          marginRight: "64px",
                        }}
                      >
                        <IconButton
                          size="large"
                          aria-label="Edit"
                          onClick={() => !isEditDisabled && openEditDrawer()}
                          disableRipple={isEditDisabled ? true : false}
                          sx={{
                            cursor: isEditDisabled ? `not-allowed` : "pointer",
                          }}
                        >
                          <Tooltip
                            title={window.getCTTranslatedText(
                              isEditDisabled
                                ? "System named networks cannot be modified"
                                : "Edit named network"
                            )}
                          >
                            <EditIcon
                              fontSize="medium"
                              color={isEditDisabled ? "disabled" : "action"}
                            />
                          </Tooltip>
                        </IconButton>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </>
          )}

          {userPermissions.has("UPDATE_NAMED_NETWORK") &&
            editDrawerVisibility && (
              <NetworkFormDrawer
                network={network as NetworkDetailInt}
                isOpen={editDrawerVisibility}
                onClose={onCloseEditDrawer}
                updateNetworkData={updateNetworkData}
                title={"Edit named network details"}
                btnTitle={"update"}
                mode={"edit"}
              />
            )}
          {userPermissions.has("UPDATE_NAMED_NETWORK") &&
            addDrawerVisibility && (
              <NetworkFormDrawer
                network={network as NetworkDetailInt}
                isOpen={addDrawerVisibility}
                onClose={onCloseAddDrawer}
                updateNetworkData={updateNetworkData}
                title={"Add IP ranges to named network"}
                btnTitle={"add"}
                mode={"add"}
              />
            )}

          <Stack
            alignItems={"center"}
            sx={{
              padding: 2,
            }}
            spacing={1}
            direction={"row"}
          >
            <Stack
              direction={"row"}
              justifyContent="flex-start"
              alignItems={"center"}
              spacing={2}
            >
              <Typography variant="subtitle1">
                <b>{window.getCTTranslatedText("Domain")}</b>:
              </Typography>
              <Typography variant="subtitle1">
                {network?.domain || ""}
              </Typography>
            </Stack>
            {!network?.isOOBNetwork && !viewOnly && (
              <Stack
                flex={1}
                alignItems="center"
                justifyContent={"flex-end"}
                direction={"row"}
                spacing={2}
              >
                <PendingWorkRequestsIcon resourceID={network.namedNetworkId} />
                <Link
                  typography={"body2"}
                  component={RouterLink}
                  to={`/monitoring?tab=audit_logs&filters=resourceid=${network.namedNetworkId}`}
                >
                  {window.getCTTranslatedText("View History")}
                </Link>
              </Stack>
            )}
          </Stack>
          <Divider />
          <Stack
            alignItems={"flex-start"}
            sx={{
              padding: 2,
              flex: 1,
            }}
            spacing={1}
          >
            <Stack
              direction={"row"}
              justifyContent="flex-start"
              alignItems={"center"}
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Typography variant="subtitle1">
                <b>{window.getCTTranslatedText("IP Ranges")}</b>
              </Typography>
              {network && network?.ipRangesList && (
                <Typography variant="subtitle1">{`(${
                  network?.ipRangesList?.length || 0
                })`}</Typography>
              )}
              {userPermissions.has("UPDATE_NAMED_NETWORK") &&
                !network?.isOOBNetwork &&
                !viewOnly && (
                  <Stack
                    flex={1}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    sx={{ mt: 0.5 }}
                    direction={"row"}
                  >
                    <Stack alignItems="center">
                      <Tooltip
                        title={window.getCTTranslatedText(
                          network?.isOOBNetwork
                            ? "System named networks cannot be modified"
                            : "Add IP ranges to named network"
                        )}
                      >
                        <Button
                          variant="text"
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={() => openAddDrawer()}
                          disableRipple={network?.isOOBNetwork ? true : false}
                          sx={{
                            cursor: network?.isOOBNetwork
                              ? `not-allowed`
                              : "pointer",
                            color: network?.isOOBNetwork
                              ? "disabled"
                              : "primary",
                          }}
                        >
                          {window.getCTTranslatedText("Add IP ranges")}
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Stack>
                )}
            </Stack>
            <Stack
              justifyContent="flex-start"
              alignItems={"flex-start"}
              spacing={0}
              sx={{ width: "100%" }}
            >
              {network && network?.ipRangesList.length > 0 && (
                <TableContainer>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3}>
                          {window.getCTTranslatedText("CIDR")}
                        </TableCell>
                        <TableCell colSpan={3}>
                          {window.getCTTranslatedText("IP Count")}
                        </TableCell>
                        <TableCell colSpan={0.5}> </TableCell>
                      </TableRow>
                    </TableHead>
                    {(network.ipRangesList || []).map((item, index, values) => {
                      return (
                        <TableBody key={`iprange-${index}`}>
                          <TableRow>
                            <TableCell
                              component="td"
                              scope="row"
                              colSpan={3}
                              sx={index === 0 ? cellStyleTop : cellStyle}
                            >
                              {item?.value}
                            </TableCell>
                            <TableCell
                              component="td"
                              scope="row"
                              colSpan={3}
                              sx={index === 0 ? cellStyleTop : cellStyle}
                            >
                              <Typography sx={{ textTransform: "capitalize" }}>
                                {numeral(item?.count || 0).format("0a") || 0}
                              </Typography>
                            </TableCell>
                            <TableCell
                              colSpan={0.5}
                              sx={index === 0 ? cellStyleTop : cellStyle}
                            >
                              {userPermissions.has("UPDATE_NAMED_NETWORK") &&
                              !viewOnly &&
                              !network?.isOOBNetwork &&
                              network?.ipRangesList.length > 1 ? (
                                <Stack
                                  alignItems="flex-end"
                                  justifyContent={"flex-end"}
                                  justifyItems={"flex-end"}
                                >
                                  <RemoveNetworkRangeDialog
                                    ipRange={item}
                                    network={network}
                                  />
                                </Stack>
                              ) : (
                                <> </>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
                  </Table>
                </TableContainer>
              )}

              {network && network?.ipRangesList?.length === 0 && (
                <Stack
                  alignContent={"center"}
                  justifyContent="center"
                  justifyItems={"center"}
                  sx={{ height: "100px", width: "100%" }}
                >
                  <Typography variant="caption" sx={{ textAlign: "center" }}>
                    {window.getCTTranslatedText("No data available")}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </>
  );
};
