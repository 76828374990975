import { NetworkInterface } from "pages/assets/types";

export const getAssetInterfaceIPs = (interfaces?: NetworkInterface[]) => {
  const assetInterfaces = (interfaces ?? []).map(i => i.ipaddresses).flat();
  const assetIps =
    prioritizeIPv4(assetInterfaces ?? [])
      .filter(Boolean)
      .join(", ") ?? "";
  return assetIps;
};

export const getAssetInterfaceMACs = (interfaces?: NetworkInterface[]) => {
  const assetInterfaces = (interfaces ?? []).map(i => i.macaddress);
  const assetMacs = assetInterfaces.join(", ") ?? "";
  return assetMacs;
};

function prioritizeIPv4(ipAddresses: (string | undefined)[]) {
  return ipAddresses.sort((a, b) => {
    if (a?.includes(".")) {
      return -1;
    }
    if (a?.includes(":")) {
      return 1;
    }

    return 0;
  });
}
