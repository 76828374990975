import { Box, Stack, Tooltip } from "@mui/material";
import { AgentColumnRenderProps } from "pages/agents/types";
import CircleIcon from "@mui/icons-material/Circle";
import { ReactElement } from "react";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AgentStatus({ agent, viewOnly }: AgentColumnRenderProps) {
  if (!agent?.agentId) {
    return <Box style={{ width: 120 }} />;
  }

  let icon = <CircleIcon color={"disabled"} style={{ marginRight: 8 }} />;
  let autoUpgradeOnIcon: ReactElement = <></>;
  let text = "Absent";

  switch (agent?.agentStatus) {
    case "active":
      icon = <CircleIcon color={"success"} style={{ marginRight: 8 }} />;
      text = "Active";
      break;
  }
  switch (
    agent?.autoUpgradeEnabled &&
    (agent.agentStatus === "active" || agent.agentStatus === "absent")
  ) {
    case true:
      autoUpgradeOnIcon = (
        <Tooltip
          title={window.getCTTranslatedText("Autoupgrade On")}
          placement="top"
        >
          <AutoModeIcon
            color={agent.agentStatus === "active" ? "success" : "disabled"}
            style={{ marginRight: 8 }}
          />
        </Tooltip>
      );
      break;
  }
  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <CTDatagridButton
        startIcon={icon}
        endIcon={autoUpgradeOnIcon}
        asText={viewOnly === true}
        isDisabled={viewOnly === true}
        facetName={"agentstatus"}
        facetOption={agent?.agentStatus ?? ""}
      >
        {window.getCTTranslatedText(text)}
      </CTDatagridButton>
    </Stack>
  );
}
