import { useMutation } from "@tanstack/react-query";

export function useAlertConfigAPI() {
  return useMutation<any, Error, any>([
    "alert-config",
    "alertrules/actions/search?computeTotal=true",
  ]);
}

export function useAlertConfigStatusAPI() {
  return useMutation<any, Error, any>(["alert-config", "alertrules", "put"]);
}
