import { Paper, Stack } from "@mui/material";
import { Loader } from "common/atoms/loader";
import { BulkAssetStatusControl } from "modules/bulk-asset-status-control";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import {
  Direction,
  SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { AssetRiskChart } from "pages/asset/components/asset-metadata-wrapper/components/asset-risk-chart";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TagMetadata } from "./components/tag-metadata";

export const TagDetail = () => {
  const { tag } = useParams();
  const tagKey = (tag ?? "")?.split("=")[0];
  const tagValue = (tag ?? "")?.split("=")[1].replaceAll("'", "");

  const { data: metaData } = useScopeMetadata({
    scope: Scope.Asset,
  });

  const tagData = useMemo(() => {
    return metaData?.columns[tagKey];
  }, [tagKey, metaData]);

  const [selectedSourceSecurityStatus, setSelectedSourceSecurityStatus] =
    useState<SecurityStatus>(SecurityStatus.None);
  const [selectedSourceNodeStatus, setSelectedSourceNodeStatus] =
    useState<SecurityStatus>(SecurityStatus.None);
  const [
    selectedDestinationSecurityStatus,
    setSelectedDestinationSecurityStatus,
  ] = useState<SecurityStatus>(SecurityStatus.None);
  const [selectedDestinationNodeStatus, setSelectedDestinationNodeStatus] =
    useState<SecurityStatus>(SecurityStatus.None);

  if (!tagKey || !tagData) {
    return <Loader />;
  }

  return (
    <>
      <Stack
        direction={"row"}
        alignItems="flex-start"
        spacing={3}
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            width: "300px",
            height: "100%",
          }}
          spacing={3}
        >
          <TagMetadata tagKey={tagData.displayName} tagValue={tagValue} />
          {/* <AssetTemplates criteria={`${tag}`} page={AttributeLocation.Tags} />
          <AssetNetworks criteria={`${tag}`} page={AttributeLocation.Tags} /> */}
        </Stack>
        <Stack
          flex={1}
          sx={{
            height: "100%",
            display: "flex",
          }}
          spacing={3}
        >
          <Paper sx={{ width: "100%", borderRadius: 1 }} elevation={1}>
            <AssetRiskChart criteria={`${tag}`} border={false} />
          </Paper>
          <Stack sx={{ width: "100%", pb: 3 }} spacing={3} direction="row">
            <Stack sx={{ width: "50%" }}>
              <Paper sx={{ width: "100%", borderRadius: 1 }}>
                {metaData && (
                  <BulkAssetStatusControl
                    assetMetadata={metaData}
                    criteria={`${tag}`}
                    onClose={() => {}}
                    selectedAssetGroupSecurityStatus={selectedSourceNodeStatus}
                    selectedDirection={Direction.Inbound}
                    selectedSecurityStatus={selectedSourceSecurityStatus}
                    setSelectedSecurityStatus={setSelectedSourceSecurityStatus}
                    setSelectedAssetGroupSecurityStatus={
                      setSelectedSourceNodeStatus
                    }
                  />
                )}
              </Paper>
            </Stack>
            <Stack sx={{ width: "50%" }}>
              <Paper sx={{ width: "100%", borderRadius: 1 }}>
                {metaData && (
                  <BulkAssetStatusControl
                    assetMetadata={metaData}
                    criteria={`${tag}`}
                    onClose={() => {}}
                    selectedAssetGroupSecurityStatus={
                      selectedDestinationNodeStatus
                    }
                    selectedDirection={Direction.Outbound}
                    selectedSecurityStatus={selectedDestinationSecurityStatus}
                    setSelectedSecurityStatus={
                      setSelectedDestinationSecurityStatus
                    }
                    setSelectedAssetGroupSecurityStatus={
                      setSelectedDestinationNodeStatus
                    }
                  />
                )}
              </Paper>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
