import { capitalize } from "@mui/material";
import { ScopeMetadata } from "modules/scope-metadata/types";
import { CoreTagsKeys } from "pages/assets/types";

export const getFacetDisplayName = (
  facetName: string,
  metadata?: ScopeMetadata
) => {
  const displayName = metadata?.columns[facetName]?.displayName ?? facetName;
  const columnName = metadata?.columns[facetName] ?? "";

  if (Object.values(CoreTagsKeys).includes(columnName as CoreTagsKeys)) {
    return capitalize(displayName ?? "");
  } else {
    return displayName;
  }
};
