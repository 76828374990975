import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { CONFIG_WIDGET_WIDTH } from "pages/settings/constants";
import { useMemo, useState } from "react";
import { useAgentSecretAPI } from "./hooks/useAgentSecret";
import { useUpdateAgentSecret } from "./hooks/useUpdateAgentSecret";
import { AgentSecretConfig } from "./types";

export const AgentConfig = () => {
  const theme = useTheme();
  const [configStatus, setConfigStatus] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const queryClient = useQueryClient();

  let { data, isLoading }: any = useAgentSecretAPI();
  const updateAgentSecretMutation = useUpdateAgentSecret();

  const agentConfig: AgentSecretConfig = useMemo(() => {
    if (!data) {
      return;
    }
    if (data?.secret) {
      setConfigStatus(true);
      setPassword(data?.secret);
    } else {
      setConfigStatus(false);
      setPassword("");
    }

    return data;
  }, [data]);

  const toggleStatus = (status: boolean) => {
    setConfigStatus(status);
  };

  const updatePassword = (password: string) => {
    setPassword(password);
  };

  const savePassword = () => {
    const body = {
      secret: configStatus ? password : "",
    };

    updateAgentSecretMutation.mutate(body, {
      onSuccess: response => {
        setSnackbar(
          true,
          SnackBarSeverity.Success,
          "UpdatedAgentSecurityPasswordSuccessfully"
        );
        queryClient.invalidateQueries({
          queryKey: ["agent-secret"],
        });
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const getTooltipTitle = () => {
    let title = "";
    if (configStatus) {
      if (!password) {
        title = "Please enter the agent security password";
      } else if (agentConfig?.secret === password) {
        title = "Please update the agent security password";
      }
    }

    return title;
  };

  return (
    <Paper
      elevation={1}
      sx={{
        width: CONFIG_WIDGET_WIDTH,
        height: "auto",
        borderRadius: 1,
        overflow: "hidden",
      }}
    >
      <Stack
        alignItems="flex-start"
        alignContent={"flex-start"}
        justifyContent="flex-start"
        px={3}
        py={2}
        sx={{ width: "100%" }}
      >
        <Typography variant="subtitle1">
          {window.getCTTranslatedText(`Agent Security Password`)}
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: theme.palette.text.secondary }}
        >
          {window.getCTTranslatedText(
            "(Note: Supported agent version 5.9.0 and above)"
          )}
        </Typography>
      </Stack>
      <Divider />
      <Stack
        alignItems="flex-start"
        justifyContent="flex-start"
        p={3}
        sx={{ height: "auto", width: "100%", minHeight: "200px" }}
      >
        {isLoading ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            sx={{ width: "100%", height: "auto", minHeight: "160px" }}
          >
            <CircularProgress size={32} />
          </Stack>
        ) : (
          <Stack>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.text.secondary }}
            >
              {window.getCTTranslatedText(
                "Configure agent security password to protect agent services from unauthorized access"
              )}
            </Typography>
            <AgentConfigSwitch
              configStatus={configStatus}
              toggleStatus={toggleStatus}
              isDisabled={false}
            />
            <AgentSecurityPasswordField
              password={password}
              updatePassword={updatePassword}
              configStatus={configStatus}
            />
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              {userPermissions.has("UPDATE_TENANT_AGENT_SECRET") && (
                <Tooltip title={window.getCTTranslatedText(getTooltipTitle())}>
                  <span>
                    <Button
                      variant="contained"
                      onClick={savePassword}
                      disabled={
                        configStatus &&
                        (!password || agentConfig.secret === password)
                      }
                    >
                      {window.getCTTranslatedText("Save")}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

interface AgentConfigSwitchProps {
  configStatus: boolean;
  toggleStatus: (configStatus: boolean) => void;
  isDisabled: boolean;
}

const AgentConfigSwitch = ({
  configStatus,
  toggleStatus,
  isDisabled = false,
}: AgentConfigSwitchProps) => {
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleStatus(event.target.checked);
  };

  return (
    <Stack alignItems="flex-start" justifyContent="flex-start" py={2} pl={1}>
      <FormGroup>
        <FormControlLabel
          sx={{
            "&.MuiFormControlLabel-root": { marginRight: 1 },
            "& .MuiFormControlLabel-label": {
              fontSize: theme.typography.body2.fontSize,
              color: theme.palette.text.secondary,
            },
          }}
          control={
            <Switch
              checked={configStatus}
              onChange={handleChange}
              name="config"
              size="small"
            />
          }
          disabled={isDisabled}
          label={window.getCTTranslatedText(
            "Require agent security password to access agent services"
          )}
        />
      </FormGroup>
    </Stack>
  );
};

interface AgentSecurityPasswordFieldProps {
  password: string;
  updatePassword: (password: string) => void;
  configStatus: boolean;
}

const AgentSecurityPasswordField = ({
  password,
  updatePassword,
  configStatus,
}: AgentSecurityPasswordFieldProps) => {
  const handlePasswordChange = (config: TextFieldUpdate) => {
    updatePassword(config?.value ?? "");
  };

  return (
    <form>
      <CTInputField
        field="password"
        displayName="Password"
        placeholder={"Enter Password"}
        value={password}
        handleUpdate={handlePasswordChange}
        type="password"
        showLabel={true}
        required={true}
        autoFocus={false}
        showClear={true}
        size="small"
        disabled={!configStatus}
      />
    </form>
  );
};
