import { Stack, Typography } from "@mui/material";
import {
  CORE_OS,
  EMPTY_STRING,
  SUPPORTED_OS_VERSIONS,
  platforms,
} from "../agent-download-page/constants";
import { SupportedVersionsDrawer } from "./SupportedVersionsDrawer";

export interface SupportedPlatformProps {
  isOpen: boolean;
  title?: string;
  onCancel: () => void;
}

export const SupportedPlatforms = ({
  isOpen,
  title,
  onCancel,
}: SupportedPlatformProps) => {
  const filteredPlatforms = Object.entries(CORE_OS)
    .filter(
      ([platform]) =>
        platform !== platforms.DOCKER && platform !== platforms.RPM_LEGACY
    )
    ?.sort()
    ?.reverse();

  const renderOSVersions = (platform: string) => {
    const versions = SUPPORTED_OS_VERSIONS[platform];

    if (Array.isArray(versions)) {
      return (
        <Stack alignItems={"left"} justifyContent={"center"}>
          <Typography variant="body2">{versions.join(", ")}</Typography>
        </Stack>
      );
    } else if (typeof versions === "object") {
      return Object.entries(versions).map(([distro, distroVersions]) => (
        <Stack key={distro} direction={"row"} spacing={1}>
          <Stack>
            <Typography variant="body1">
              {distro}
              {":"}
            </Typography>
          </Stack>
          <Stack justifyContent={"center"}>
            <Typography variant="body2">{distroVersions.join(", ")}</Typography>
          </Stack>
        </Stack>
      ));
    } else {
      return EMPTY_STRING;
    }
  };

  return (
    <SupportedVersionsDrawer
      isOpen={isOpen}
      title={title}
      onCancel={onCancel}
      filteredEntities={filteredPlatforms}
      renderVersions={renderOSVersions}
      rows={filteredPlatforms.length - 1}
    />
  );
};
