import { FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "../../../../modules/facets/types";

export const FACET_ORDER: FacetGroupInfo = {
  devicename: FacetGroups.Devices,
  deviceip: FacetGroups.Devices,
  devicemac: FacetGroups.Devices,
  deviceavailability: FacetGroups.Devices,
  agentname: FacetGroups.Appliances,
  agentlocation: FacetGroups.Appliances,
  agentstatus: FacetGroups.Agents,
  currentversion: FacetGroups.Agents,
};

export const MAX_MANAGE_COUNT = 10;
