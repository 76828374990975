import CircleIcon from "@mui/icons-material/Circle";
import { Stack, Tooltip } from "@mui/material";
import { AlertRuleConfig, AlertRuleStatus } from "../../types";
import { InfoOutlined } from "@mui/icons-material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export interface AlertConfigRuleColumnRenderProps {
  readonly alertRule: AlertRuleConfig;
  readonly viewOnly?: boolean;
}

const SnoozeIcon = () => (
  <Tooltip title={`${window.getCTTranslatedText("SnoozedTooltipText")}`}>
    <InfoOutlined color="action" />
  </Tooltip>
);

const getIconAndText = (alertRule: AlertRuleConfig) => {
  let icon;
  let text = "";

  switch (alertRule?.alertRuleStatus) {
    case AlertRuleStatus.Enabled:
      icon = <CircleIcon color={"success"} style={{ marginRight: 8 }} />;
      text = AlertRuleStatus.Enabled;
      break;
    case AlertRuleStatus.Disabled:
      icon = <CircleIcon color="disabled" style={{ marginRight: 8 }} />;
      text = AlertRuleStatus.Disabled;
      break;
    default:
      icon = <CircleIcon color="warning" style={{ marginRight: 8 }} />;
      text = AlertRuleStatus.Snoozed;
      break;
  }

  return { icon, text };
};

export function AlertConfigRuleStatus({
  alertRule,
  viewOnly,
}: AlertConfigRuleColumnRenderProps) {
  const typeFacetName = "alertrulestatus";
  const typeOptionName = alertRule?.alertRuleStatus;

  const { icon, text } = getIconAndText(alertRule);

  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "space-evenly",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Stack direction="row">
        <Tooltip title={`${window.getCTTranslatedText(text)}`}>
          <CTDatagridButton
            asText={viewOnly}
            isDisabled={viewOnly}
            startIcon={icon}
            facetName={typeFacetName}
            facetOption={typeOptionName}
            endIcon={
              alertRule?.alertRuleStatus === AlertRuleStatus.Snoozed ? (
                <SnoozeIcon />
              ) : undefined
            }
          >
            {window.getCTTranslatedText(text)}
          </CTDatagridButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
