import { CreateTagFieldKeys } from "../../types";

export const RequiredCreateTagFields: { [key: string]: boolean } = {
  [CreateTagFieldKeys.Name]: true,
};

export const CreateTagFieldDisplayText = {
  [CreateTagFieldKeys.Name]: "Tag Name",
};

export const CreateTagFieldOptions: Array<CreateTagFieldKeys> = [
  CreateTagFieldKeys.Name,
];
