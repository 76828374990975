import { CustomCommonStoreType } from "common/types/types";

export enum SaveQueryFieldKeys {
  Name = "name",
  Description = "description",
}

export enum QueryType {
  Saved = "saved",
  Recent = "recent",
}

export interface SaveQueryFormProps {
  name?: string | undefined;
  description?: string | undefined;
  criteria: string;
  criteriaAsParams: string;
}

export enum FilterQuery {
  Saved = 0,
  Recent = 1,
}

export interface SavedQueryListProps {
  useFacetStore?: CustomCommonStoreType;
  activeTab?: FilterQuery;
  showButtonTrigger?: boolean;
  disableSearch?: boolean;
}

export interface SavedQuery {
  id?: string;
  criteria: string;
  criteriaAsParams: string;
  name?: string;
  description?: string;
  date?: Date | string;
}

export interface QueryItemProps {
  query: SavedQuery;
  useFacetStore: CustomCommonStoreType;
  loadQueryCallback: (query: SavedQuery) => void;
  type?: QueryType;
}
