import { IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { CTWidget } from "common/molecules/widget";
import { WidgetListItem } from "common/molecules/widget/components/widget-list-item";
import {
  ApplianceServicesStatus,
  ApplianceWidgetProps,
  RestartServicesTypes,
} from "pages/appliance-detail/types";
import { AgentModes, Appliance, HaRole } from "pages/appliances/types";
import CircleIcon from "@mui/icons-material/Circle";
import { useRestartServiceAPI } from "pages/appliance-detail/hooks";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { parseErrorMessage } from "common/utils";
import { CTConfirmation } from "common/atoms/ct-confirmation";
import { useState } from "react";
import { getPendingActionsTooltip } from "pages/appliances/utils";
import { ServiceRestartIcon } from "assets/svgs";

interface WidgetProps {
  appliance?: Appliance;
}

const iconStyle = {
  fontSize: 18,
};

const DHCPWidgetContent = ({ appliance }: WidgetProps) => {
  const renderDHCPStatus = (status?: string) => {
    const inactiveText =
      status === ApplianceServicesStatus.DOWN ? "Inactive" : "notApplicable";
    return (
      <Stack direction="row" alignItems="center">
        <CircleIcon
          color={status === ApplianceServicesStatus.UP ? "success" : "disabled"}
          fontSize="small"
          sx={{ mr: 1, ...iconStyle }}
        />
        <Typography variant="body2" sx={{ mr: 1 }}>
          {window.getCTTranslatedText(
            status === ApplianceServicesStatus.UP ? "Active" : inactiveText
          )}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack width="100%" spacing={3}>
      <Stack spacing={2}>
        <WidgetListItem
          keyText={window.getCTTranslatedText("DHCP Status")}
          valueElement={renderDHCPStatus(appliance?.dhcpStatus)}
        />
        <WidgetListItem
          keyText={window.getCTTranslatedText("DHCP Mode")}
          valueText={
            appliance?.mode === AgentModes.Static ? "Static" : appliance?.mode
          }
        />
      </Stack>
    </Stack>
  );
};

export const ApplianceDHCP = ({
  appliance,
  isLoading,
  canUpdate,
}: ApplianceWidgetProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const disabledActionsTooltip = getPendingActionsTooltip(appliance);
  const theme = useTheme();
  const restartServiceMutation = useRestartServiceAPI(
    appliance?.agentId,
    RestartServicesTypes.DHCP
  );
  const restartDHCP = async () => {
    await restartServiceMutation.mutateAsync(
      {},
      {
        onSuccess: response => {
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            window.getCTTranslatedText("restartServiceSuccess")
          );
          setShowConfirmation(false);
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      }
    );
  };

  const renderActions = () => {
    const tooltipMessage = Boolean(disabledActionsTooltip)
      ? disabledActionsTooltip
      : window.getCTTranslatedText("restartService", {
          service: "DHCP Service",
        });
    return (
      <>
        <Tooltip title={tooltipMessage}>
          <Stack>
            <IconButton
              onClick={() => setShowConfirmation(true)}
              disabled={Boolean(disabledActionsTooltip)}
            >
              <ServiceRestartIcon
                color={
                  Boolean(disabledActionsTooltip)
                    ? theme.palette.text.disabled
                    : theme.palette.primary.main
                }
              />
            </IconButton>
          </Stack>
        </Tooltip>
        <CTConfirmation
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          title={window.getCTTranslatedText("restartService", {
            service: "DHCP Service",
          })}
          primaryText={window.getCTTranslatedText(
            "applianceServiceRestartConfirmation",
            { service: "DHCP Service" }
          )}
          secondaryText={window.getCTTranslatedText("applianceDHCPRestartNote")}
          primaryButtonText={window.getCTTranslatedText("restartButton")}
          isLoading={restartServiceMutation.isLoading}
          onSuccess={restartDHCP}
        />
      </>
    );
  };

  return (
    <CTWidget
      title={window.getCTTranslatedText("DHCP")}
      isLoading={isLoading}
      actions={
        appliance?.dhcpStatus !== ApplianceServicesStatus.NA &&
        appliance?.haRole !== HaRole.Standby &&
        canUpdate
          ? renderActions()
          : null
      }
      children={<DHCPWidgetContent appliance={appliance} />}
    />
  );
};
