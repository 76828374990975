import styled from "@emotion/styled";
import { Badge } from "@mui/material";

export const CtBadge = styled(Badge, {
  shouldForwardProp: propName => {
    return propName !== "backgroundColor";
  },
})`
  .MuiBadge-badge {
    background-color: ${({ backgroundColor }: { backgroundColor: string }) =>
      backgroundColor ?? backgroundColor};
  }
`;
