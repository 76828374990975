import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  useTheme,
} from "@mui/material";
import { useState } from "react";

export const useInputStyles = makeStyles(theme => ({
  input: {
    "&::placeholder": {
      fontSize: theme.typography.body2.fontSize,
    },
  },
}));

interface InputProps {
  maxLength?: number;
  min?: number;
  max?: number;
}

export interface TextFieldUpdate {
  field: string;
  value: string;
  target: {
    value: string;
  };
}

export type TextUpdateHandler = (e: TextFieldUpdate) => void;
interface InputFieldProps {
  field: string;
  displayName: string;
  placeholder?: string;
  value: string | number | undefined;
  handleUpdate: TextUpdateHandler;
  required?: boolean;
  showLabel?: boolean;
  autoFocus?: boolean;
  error?: boolean;
  onKeyUp?: React.KeyboardEventHandler<HTMLDivElement>;
  type?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  startIcon?: JSX.Element;
  disabled?: boolean;
  showClear?: boolean;
  size?: string;
  maxLength?: number;
  sx?: SxProps;
  helperText?: string;
  inputProps?: InputProps;
}

export function CTInputField({
  field,
  displayName,
  placeholder,
  value,
  handleUpdate,
  required = false,
  showLabel = true,
  autoFocus = false,
  error = false,
  onKeyUp,
  type = "text",
  onFocus,
  startIcon,
  disabled = false,
  showClear,
  size = "medium",
  maxLength,
  sx,
  helperText = "",
  inputProps: defaultInputProps,
}: InputFieldProps) {
  const theme = useTheme();
  function handleFieldValueChange(e: any) {
    if (e?.target?.value !== undefined) {
      let newE = { field, value: e.target.value as string, target: e.target };
      handleUpdate(newE);
    }
  }

  const inputClasses = useInputStyles();

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  let inputProps: InputProps = { ...defaultInputProps };
  if (maxLength) {
    inputProps.maxLength = maxLength;
  }

  const placeholderText = placeholder
    ? window.getCTTranslatedText(placeholder)
    : `${window.getCTTranslatedText(
        type === "search" ? "Search" : "Enter"
      )}${" "}${window.getCTTranslatedText(displayName)}`;
  return (
    <>
      <TextField
        required={required}
        name={field}
        value={value}
        disabled={disabled}
        type={type === "password" && showPassword ? "text" : type}
        placeholder={placeholderText}
        fullWidth
        label={showLabel ? window.getCTTranslatedText(displayName) : null}
        autoFocus={autoFocus}
        onChange={e => {
          e.stopPropagation();
          handleFieldValueChange(e);
        }}
        error={error}
        sx={{
          fontSize:
            size === "small"
              ? theme.typography.body2.fontSize
              : theme.typography.body1.fontSize,
          ...(sx ?? {}),
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          autoComplete: "off",
          classes: { input: inputClasses.input },
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ marginRight: startIcon ? "inherit" : "0px" }}
            >
              {startIcon ? startIcon : null}
            </InputAdornment>
          ),
          endAdornment:
            type === "search" && showClear ? null : (
              <InputAdornment position="end">
                {(type === "search" || showClear) && (
                  <IconButton
                    color="inherit"
                    aria-label="cross icon"
                    onClick={() => {
                      handleUpdate({ field, value: "", target: { value: "" } });
                    }}
                    onMouseDown={() => {}}
                    edge="end"
                    size={"small"}
                  >
                    {value &&
                      typeof value === "string" &&
                      value?.length > 0 && (
                        <ClearIcon fontSize="small" color="inherit" />
                      )}
                  </IconButton>
                )}
                {type === "password" && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                    onMouseDown={e => e.preventDefault()}
                    size={"medium"}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                )}
              </InputAdornment>
            ),
        }}
        inputProps={inputProps}
        variant="standard"
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onKeyDown={e => e.stopPropagation()}
        helperText={window.getCTTranslatedText(helperText)}
      />
    </>
  );
}
