import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Stack, Typography } from "@mui/material";
import { useErrorBoundary } from "react-error-boundary";

// Doc reference https://www.npmjs.com/package/react-error-boundary

export function ErrorFallback(): JSX.Element {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      justifyItems={"center"}
      justifyContent={"center"}
      sx={{ width: "100%", height: "100%" }}
    >
      <Alert
        severity="info"
        action={
          <LoadingButton
            loading={false}
            color="info"
            variant="outlined"
            onClick={resetBoundary}
          >
            Try again
          </LoadingButton>
        }
      >
        <Typography variant="body2">Something went wrong</Typography>
      </Alert>
    </Stack>
  );
}
