import { Box, Link, Stack, Tooltip } from "@mui/material";
import { ApplianceColumnRenderProps } from "pages/appliances/types";
import { Link as RouterLink } from "react-router-dom";
import numeral from "numeral";
import { ApplianceTabs } from "pages/appliance-detail/types";

export function UnmanagedDeviceCount({ agent }: ApplianceColumnRenderProps) {
  if (!agent?.agentId) {
    return <Box style={{ minWidth: 120, minHeight: 45 }} />;
  }

  return (
    <Stack
      sx={{ maxWidth: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      <Stack justifyContent="center" alignItems="center">
        <Tooltip title={window.getCTTranslatedText("viewDevices")}>
          <Link
            typography={"body2"}
            component={RouterLink}
            to={`/appliances/${agent?.agentName}?tab=${
              ApplianceTabs.UnmanagedDevices
            }&filters=${encodeURIComponent(
              `agentname=${agent?.agentName}|type=device`
            )}`}
            sx={{ color: "info.main", ml: 6 }}
          >
            {numeral(agent?.unmanagedDeviceCount || 0).format("0a")}
          </Link>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
