import { Button, ButtonProps, styled } from "@mui/material";

export const FilterActionButton = styled(Button)<ButtonProps>(
  ({ theme, color }) => {
    return {
      padding: 0,
      minWidth: 0,
      opacity: 0.9,
      textTransform: "none",
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      size: "medium",
      color: color || theme.palette.primary.main,
      disableRipple: true,
    };
  }
);
