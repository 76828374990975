import { isIP } from "is-ip";
import { AllowedPorts, PortList } from "../types";

export const isValidDomain = require("is-valid-domain");

export const isValidIp = (value: string) => {
  return isIP(value);
};

export const isValidPortNumber = (value: string) => {
  value = value?.trim();
  if (value !== undefined && value !== "" && !value.includes(".")) {
    const isPortAny = value?.toLocaleLowerCase() === PortList.ANY;
    const portNumber = isPortAny ? value : Number(value);

    if (
      (portNumber !== undefined &&
        typeof portNumber === "number" &&
        portNumber >= 0 &&
        portNumber < 65536) ||
      AllowedPorts[value]
    ) {
      return true;
    }
  }
  return false;
};

export function isValidateIPV4Range(ipRange: string) {
  // Regular expression to match a valid IP address range (IPv4)
  const ipV4RangePattern =
    /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})-(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/;

  // Check if the input matches the IP range pattern
  if (!ipV4RangePattern.test(ipRange)) {
    return false;
  }

  // Split the input into start and end IP addresses
  const [startIP, endIP] = ipRange.split("-");

  // Validate each IP address
  if (!isValidIp(startIP) || !isValidIp(endIP)) {
    return false;
  }

  // Check if the start IP is less than or equal to the end IP
  return isIPv4LessThanOrEqual(startIP, endIP);
}

function isIPv4LessThanOrEqual(startIP: string, endIP: string) {
  // Compare two IP addresses
  const startIPParts = startIP.split(".").map(part => parseInt(part, 10));
  const endIPParts = endIP.split(".").map(part => parseInt(part, 10));

  for (let i = 0; i < 4; i++) {
    if (startIPParts[i] < endIPParts[i]) {
      return true;
    } else if (startIPParts[i] > endIPParts[i]) {
      return false;
    }
  }

  return true; // Both IPs are equal
}

export const isValidateIPV6Range = (ipRange: string) => {
  // Regular expression to match a valid IP address range (IPv6)
  const ipv6RangePattern = /^([0-9a-fA-F:]+)-([0-9a-fA-F:]+)$/;

  // Check if the input matches the IP range pattern
  if (!ipv6RangePattern.test(ipRange)) {
    return false;
  }

  // Split the input into start and end IP addresses
  const [startIP, endIP] = ipRange.split("-");

  // Validate each IP address
  if (!isValidIp(startIP) || !isValidIp(endIP)) {
    return false;
  }

  // Check if the start IP is less than or equal to the end IP
  return isIPv6LessThanOrEqual(startIP, endIP);
};

function isIPv6LessThanOrEqual(startIP: string, endIP: string) {
  // Compare two IP addresses
  try {
    // Split the IPv6 addresses into segments
    const startSegments = startIP.split(":");
    const endSegments = endIP.split(":");

    // Check if there are 8 segments in both start and end IPs
    if (startSegments.length !== 8 || endSegments.length !== 8) {
      return false;
    }

    // Compare each segment to ensure start is less than or equal to end
    for (let i = 0; i < 8; i++) {
      const startSegment = parseInt(startSegments[i], 16);
      const endSegment = parseInt(endSegments[i], 16);

      if (
        isNaN(startSegment) ||
        isNaN(endSegment) ||
        startSegment > endSegment
      ) {
        return false;
      }
    }

    return true;
  } catch (error) {
    return false; // Handle any parsing errors
  }
}
export const pathPattern = /[/:\\]/;

export const isInvalidProcessPath = (path: string) => {
  return !pathPattern.test(path);
};
