import { Stack, useTheme } from "@mui/material";
import { Toolbar } from "common/atoms/toolbar";

export interface ToolbarProps {
  show: boolean;
  hideToolbar: Function;
  children: React.ReactNode;
}

export default function GridToolbar({ show, children }: ToolbarProps) {
  const theme = useTheme();

  if (!show) {
    return null;
  }

  return (
    <Toolbar
      id="grid-toolbar"
      variant="dense"
      sx={{
        zIndex: 2,
        padding: "4px !important",
        justifyContent: "flex-end",
        background: theme.palette.background.default,
        boxShadow: "unset !important",
      }}
    >
      <Stack direction="row" spacing={2}>
        {children}
      </Stack>
    </Toolbar>
  );
}
