import { useTheme, Paper, Stack } from "@mui/material";
import { SecurityTitleBlock } from "pages/dashboard/components/section-title-block";

export const TagMetadata = ({
  tagKey,
  tagValue,
}: {
  tagKey: string;
  tagValue: string;
}) => {
  const theme = useTheme();
  // const [autoDeploy, setAutoDeploy] = useState(true);

  return (
    <>
      <Paper sx={{ width: "100%" }}>
        <Stack
          alignContent={"center"}
          justifyItems={"center"}
          justifyContent="space-between"
          sx={{
            width: "100%",
            background:
              theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, 0.04)"
                : "rgba(0, 0, 0, 0.04)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
          }}
          direction="row"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"flex-start"}
          >
            <SecurityTitleBlock
              title={tagKey}
              description={tagValue}
              addBackground={false}
            />
          </Stack>
        </Stack>
        {/* <Stack sx={{ px: 4, py: 2 }}>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <FormControlLabel
              label={<Typography variant="body2">Auto Deploy</Typography>}
              control={
                <Switch
                  value={autoDeploy}
                  checked={autoDeploy}
                  onChange={(_, c) => setAutoDeploy(c)}
                  inputProps={{ "aria-label": "Auto Deploy" }}
                />
              }
            />
          </Stack>
        </Stack> */}
      </Paper>
    </>
  );
};
