import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { useState } from "react";

const iconStyle = "rgba(0, 0, 0, 0.38)";
const IconStyleDarkMode = "rgba(255, 255, 255, 0.38)";
export interface SearchBarProps {
  id?: string;
  label?: string;
  placeholder?: string;
  selectedValue?: string;
  onChange: Function;
  onFocus: Function;
}

export const SearchBar = ({
  id,
  label,
  placeholder,
  selectedValue,
  onChange,
  onFocus,
}: SearchBarProps) => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState(selectedValue || "");

  const onSearchTextChange = (searchText: string) => {
    const value = searchText ?? "";
    setSearchText(value);
    onChange(value);
  };

  const handleUpdate = ({ field, value }: TextFieldUpdate) => {
    onSearchTextChange(value);
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus();
  };

  return (
    <CTInputField
      field="templates"
      displayName="Templates"
      placeholder={placeholder ? placeholder : "Search ..."}
      value={searchText}
      handleUpdate={handleUpdate}
      onFocus={handleOnFocus}
      type="search"
      showClear
      showLabel={false}
      required={false}
      autoFocus={false}
      startIcon={
        <SearchIcon
          sx={{
            color:
              theme.palette.mode === "dark" ? IconStyleDarkMode : iconStyle,
          }}
        />
      }
    />
  );
};
