import { ALL_TIME } from "modules/facets/constants";
import { FacetOptionUnit } from "modules/scope-metadata/types";
import { getDateLabelFromHourUnit } from "./getDateLabelFromHourUnit";
import { getDateLabelFromMinuteUnit } from "./getDateLabelFromMinuteUnit";

export const convertStringRangeToReadableDates = (
  key: string,
  unit: FacetOptionUnit
) => {
  if (!key) {
    return key;
  }

  if (key === "-" || key === "" || key === "null") {
    return "Never";
  }

  if (key === ALL_TIME) {
    return "All-time";
  }

  const convertor =
    unit === FacetOptionUnit.Hour
      ? getDateLabelFromHourUnit
      : getDateLabelFromMinuteUnit;

  if (key.includes("+")) {
    const start = key.split("+")[0];
    return `${convertor(start)}+`;
  }

  const [start, end] = key.split(" - ");

  if (start === "0") {
    return `Last ${convertor(end)}`;
  } else {
    return `${convertor(start)} - ${convertor(end)}`;
  }
};
