import { Box, Stack } from "@mui/material";
import { useCommonStore } from "common/store";
import { AssetCoreTags } from "modules/asset-core-tags/components";
import { PathColumnRenderProps } from "pages/paths/types";

export function PathSourceTags({
  path,
  viewOnly,
  useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
  if (!path.channelHash) {
    return <Box style={{ width: 160, minHeight: 45 }} />;
  }

  const coreTags = path?.sourceAsset?.coreTags ?? {};

  const renderContent = () => {
    let canDisplayTags = false;

    if (
      path.sourceNamedNetwork &&
      path.sourceNamedNetwork.namedNetworkId &&
      path?.sourceNamedNetwork?.namedNetworkName !== ""
    ) {
      canDisplayTags = false;
    } else {
      canDisplayTags = true;
    }

    return (
      <AssetCoreTags
        viewOnly={viewOnly}
        coreTags={canDisplayTags ? coreTags : {}}
        useAssetCoreTagsStore={useFacetStore}
      />
    );
  };

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      {renderContent()}
    </Stack>
  );
}
