import { useTheme } from "@mui/material";
import { getColor } from "common/constants/colors";
import { PathStatus } from "pages/paths/types";

interface MarkerDefProps {
  status?: PathStatus | string;
  color?: string;
}

export function MarkerDef({ status, color }: MarkerDefProps) {
  const theme = useTheme();

  color = color || theme.palette.info.main;

  return (
    <svg width={"0"} height="0">
      <defs>
        <marker
          id={`markerMid${status ? `-${status}` : ""}`}
          orient="auto"
          viewBox="0 -5 10 10"
          refX="-80"
          style={{
            fill: color,
          }}
          markerWidth="10"
          markerHeight="10"
        >
          <path d="M0,-5L10,0L0,5"></path>
        </marker>
      </defs>
    </svg>
  );
}

export function MarkerDefCombined() {
  const color = getColor("Mixed");
  return <MarkerDef status="Mixed" color={color} />;
}

export function MarkerDefDeny() {
  const color = getColor(PathStatus.Deny);
  return <MarkerDef status={PathStatus.Deny} color={color} />;
}

export function MarkerDefAllow() {
  const color = getColor(PathStatus.Allow);
  return <MarkerDef status={PathStatus.Allow} color={color} />;
}

export function MarkerDefAllowedByTest() {
  const color = getColor(PathStatus.AllowedByTestUIOnly);
  return <MarkerDef status={PathStatus.AllowedByTestUIOnly} color={color} />;
}
