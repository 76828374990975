import { Box, Paper, Stack, useTheme } from "@mui/material";
import { useCommonStore } from "common/store";
import { FacetOptionState, Operator } from "modules/facets/types";
import { ApplianceDevicesFilterKeys } from "pages/appliance-detail/types";
import { useEffect } from "react";
import { UnmanagedDevice } from "./types";
import { useCore } from "modules/core";
import { useUnmanagedDevicesStore } from "pages/appliance-detail/store";
import { Scope } from "modules/scope-metadata/types";
import { FACET_OPTION_MAPPING } from "pages/users-segmentation/components/users/constants";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { FacetControllerGroup } from "modules/facets";
import { useUnmanagedDevicesAPI } from "../unmanaged-device-data-grid/hooks";
import { UnmanagedDevicesDataGrid } from "../unmanaged-device-data-grid";
import { FACET_ORDER } from "./constants";

interface ApplianceDevicesProps {
  applianceName?: string;
  applianceId?: string;
}

const dataMapper = (device: UnmanagedDevice) => {
  return device;
};

export const ApplianceUnmanagedDevices = ({
  applianceName,
  applianceId,
}: ApplianceDevicesProps) => {
  const theme = useTheme();
  const setFacets = useCommonStore(state => state.setFacets);

  useEffect(() => {
    if (applianceName) {
      const applianceFilter: FacetOptionState = new Map([
        [applianceName, { isSelected: true, operator: Operator.EQUAL }],
      ]);

      let facets = new Map([
        [ApplianceDevicesFilterKeys.ApplianceName, applianceFilter],
      ]);
      setFacets(facets);
    }

    return () => setFacets(undefined);
  }, [applianceName, setFacets]);

  const coreResponse = useCore<UnmanagedDevice>({
    useStore: useUnmanagedDevicesStore,
    facetGroupInfo: FACET_ORDER,
    scope: Scope.UnmanagedDevice,
    facetOptionsDisplayMapping: FACET_OPTION_MAPPING,
    defaultSortOrder: [{ field: "devicename", order: "desc" }],
    dataMapper: dataMapper,
    skipSearch: true,
    useApi: useUnmanagedDevicesAPI,
    pageSize: 100,
  });

  const metadata = useCommonStore(state => state.metadata);
  const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction={"row"} alignItems="center">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack>
      <Box
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowY: "auto",
          flex: 1,
          height: "100%",
        }}
      >
        <Paper sx={{ height: "100%" }}>
          <UnmanagedDevicesDataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "assetrisk", sort: "desc" }],
              },
            }}
            checkboxSelection={true}
            metadata={metadata}
            rows={coreResponse.rows}
            rowCount={coreResponse.rowCount}
            mutation={coreResponse.mutation}
            onPageChange={coreResponse.onPageChange}
            page={coreResponse.page}
            pageSize={coreResponse.pageSize}
            onPageSizeChange={coreResponse.onPageSizeChange}
            hasNoData={
              coreResponse.mutation.isSuccess &&
              coreResponse.rowCount === 0 &&
              searchCriteria === "*"
            }
            triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
            getExportStatus={coreResponse?.getExportStatus}
            getUrlToDownload={coreResponse?.getUrlToDownload}
            resetDownloadUrl={coreResponse?.resetDownloadUrl}
            rawData={coreResponse?.rawData}
            applianceId={applianceId}
          />
        </Paper>
      </Box>
    </Stack>
  );
};
