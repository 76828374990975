import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { UserGroup } from "pages/users-segmentation/components/user-groups/types";

const nameStyle = {
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
};

export interface UserGroupColumnRenderProps {
  readonly userGroup: UserGroup;
}

export function UserGroupName({ userGroup }: UserGroupColumnRenderProps) {
  if (!userGroup.groupID) {
    return <Box style={{ minWidth: 160 }}></Box>;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ width: "100%" }}
    >
      <Stack sx={nameStyle}>
        <Tooltip
          title={userGroup.groupName}
          placement="bottom-start"
          arrow={false}
        >
          <Stack>
            <Typography
              variant="body2"
              color="inherit"
              noWrap
              title={userGroup.groupName}
            >
              {userGroup.groupName}
            </Typography>
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
