import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { generateBreadCrumbs } from "./helpers";
import { routeObjInt } from "./types";

export const CTBreadcrumbs = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const tabParam = urlParams.get("tab");
  const routesPaths = location?.pathname
    ?.split("/")
    ?.filter(route => route !== "");
  let breadCrumbs: Array<routeObjInt> = generateBreadCrumbs(routesPaths);

  const breadcrumbsList = (breadCrumbs || []).map((breadCrumb, index) => {
    if (
      breadCrumb?.link &&
      breadCrumb?.route &&
      index < breadCrumbs.length - 1
    ) {
      return (
        <Stack key={`breadcrumb-${index}`}>
          <Link
            typography={"body1"}
            underline="hover"
            color={theme => theme.palette.common.white}
            component={RouterLink}
            to={`${breadCrumb.route}${tabParam ? `?tab=${tabParam}` : ""}`}
          >
            {window.getCTTranslatedText(breadCrumb?.title ?? "")}
          </Link>
        </Stack>
      );
    } else {
      return (
        <Stack key={`breadcrumb-${index}`}>
          <Typography
            variant="body1"
            noWrap
            sx={{
              textTransform: "capitalize",
            }}
            color={theme => theme.palette.common.white}
          >
            {window.getCTTranslatedText(breadCrumb?.title ?? "")}
          </Typography>
        </Stack>
      );
    }
  });

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon
          fontSize="small"
          sx={{
            color: theme => theme.palette.common.white,
          }}
        />
      }
      aria-label="breadcrumb"
    >
      {breadcrumbsList}
    </Breadcrumbs>
  );
};
