import { Stack, Tooltip } from "@mui/material";
import { AuditLogColumnRenderProps } from "pages/monitoring/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export const AuditType = ({ audit, viewOnly }: AuditLogColumnRenderProps) => {
  const typeFacetName = "eventtype";
  const typeOptionName = audit?.eventType?.toString();

  const typeTitle = audit.eventType;

  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "space-evenly",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <Stack direction="row">
        <Tooltip
          title={`${window.getCTTranslatedText(
            "Category"
          )}: ${window.getCTTranslatedText(typeTitle)}`}
        >
          <CTDatagridButton
            asText={viewOnly}
            isDisabled={viewOnly}
            facetName={typeFacetName}
            facetOption={typeOptionName}
            stopPropagation={true}
          >
            {window.getCTTranslatedText(typeTitle)}
          </CTDatagridButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
