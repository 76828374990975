import { Box } from "@mui/material";
import { AnnotationDrawer } from "modules/annotation-drawer";
import { EntityType } from "modules/annotation-drawer/type";
import { AnnotationCount } from "modules/annotation/components/annotation-count";
import { usePortStore } from "pages/ports/store";
import { PortColumnRenderProps } from "pages/ports/types";
import { useState } from "react";

export function PortAnnotations({ port }: PortColumnRenderProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = (event: any) => {
    if (event) {
      event.stopPropagation();
    }
    setIsDrawerOpen(true);
  };

  if (!port.lpId) {
    return <Box style={{ width: 70 }} />;
  }

  return (
    <>
      <AnnotationCount
        count={port.totalComments}
        clickCallback={handleDrawerOpen}
      />
      {isDrawerOpen && (
        <AnnotationDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          entity={EntityType.Ports}
          entityId={port.lpId}
          useStore={usePortStore}
        />
      )}
    </>
  );
}
