import CloseIcon from "@mui/icons-material/Close";
import {
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { CircularCenteredLoader } from "common/atoms/loader";
import { Toolbar } from "common/atoms/toolbar";
import { ListTable } from "modules/add-to-templates/components/list-table/ListTable";
import { SearchBar } from "modules/add-to-templates/components/search-bar";
import { Annotation } from "modules/annotation";
import { SortOrder } from "modules/core/types";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { RemoveTemplatesDialog } from "pages/templates/components/remove-templates-dialog";
import {
  ASSIGNED_TEMPLATES_TAG_COLUMNS,
  BASE_TEMPLATES_LIST_COLUMNS,
  TEMPLATES_LIST_COLUMNS,
} from "pages/templates/components/template-data-grid/constants";
import { Template } from "pages/templates/types";
import { useCallback, useMemo, useState } from "react";

interface AssignedTemplatesViewDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  templateList: Template[] | undefined;
  templatesSuggestMutation: any;
  isLoading?: boolean;
  title?: string;
  AssignTemplatesOption?: () => JSX.Element;
  page?: string;
  policyId?: string;
  onSortChange: (sort: Array<SortOrder>) => void;
  canDelete?: boolean;
  multiselect?: boolean;
  criteria?: string;
  hasPermission?: boolean;
  sort?: Array<SortOrder>;
  setPage?: (pageNumber: number) => void;
  setPageSize?: (pageSize: number) => void;
  pageSize?: number;
  pageCount?: number;
  totalCount?: number;
  showSearch?: boolean;
  onSearchCriteriaChange?: (str: string) => void;
}

export const AssignedTemplatesViewDrawer = ({
  isOpen,
  onClose,
  templateList,
  templatesSuggestMutation,
  title = "Assigned Templates",
  AssignTemplatesOption,
  page = "assets",
  isLoading,
  policyId,
  onSortChange,
  canDelete = true,
  multiselect = false,
  criteria,
  hasPermission,
  sort,
  setPage,
  setPageSize,
  pageCount,
  pageSize,
  totalCount,
  showSearch = true,
  onSearchCriteriaChange,
}: AssignedTemplatesViewDrawerProps) => {
  const [showConfirmation, setShowconfirmation] = useState(false);
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [searchText, setSearchText] = useState("");
  const [selectedTemplateList, setSelectedTemplateList] = useState<
    Array<Template>
  >([]);

  const handleConfirmationVisibility = (value: boolean) => {
    setShowconfirmation(value);
  };

  const columns = useMemo(() => {
    let COLUMNS: GridColDef[] = BASE_TEMPLATES_LIST_COLUMNS;
    if (!canDelete) {
      return COLUMNS;
    }
    if (page === "tags") {
      COLUMNS = ASSIGNED_TEMPLATES_TAG_COLUMNS;
    } else if (page === "assets") {
      COLUMNS = TEMPLATES_LIST_COLUMNS;
    }
    return COLUMNS;
  }, [page, canDelete]);

  const triggerUnassign = () => {
    handleConfirmationVisibility(true);
  };

  const onSelectCallback = useCallback((templates: Array<Template>) => {
    setSelectedTemplateList(templates);
  }, []);

  const isValid = () => {
    if (multiselect) {
      return Boolean(selectedTemplateList?.length > 0);
    }
  };

  const onSearchTextChange = (searchText: string) => {
    onSearchCriteriaChange?.(searchText ?? "");
    setSearchText(searchText ?? "");
  };

  const handlerOnClose = () => {
    onSearchCriteriaChange?.("");
    setSearchText("");
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handlerOnClose}
      PaperProps={{
        sx: {
          width: "70%",
          maxwidth: "1000px",
          minWidth: "600px",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <DialogContent sx={{ width: "100%", height: "100%" }}>
        <Stack
          alignItems="flex-start"
          sx={{ position: "relative", width: "100%" }}
        >
          <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
            <IconButton
              size="large"
              aria-label="close drawer"
              onClick={handlerOnClose}
              sx={{
                position: "absolute",
                right: "0px",
                top: "0px",
                zIndex: 2,
              }}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        </Stack>

        {!isLoading && templatesSuggestMutation?.isLoading && (
          <CircularCenteredLoader />
        )}

        <Stack sx={{ height: "100%", width: "100%" }} alignItems="flex-start">
          <Stack alignItems="flex-start">
            <Typography variant="h6" sx={{ mt: 2, width: "100%" }}>
              {window.getCTTranslatedText(title)}
            </Typography>
          </Stack>
          <Stack
            alignItems="flex-start"
            sx={{ pt: 4, width: "100%", flex: 1, overflow: "hidden" }}
          >
            <Stack
              direction="row"
              sx={{ width: "100%", justifyContent: "flex-end" }}
              spacing={3}
            >
              <SearchBar
                id="template-list-searchbar"
                label={window.getCTTranslatedText("Search Templates")}
                placeholder={"Search Templates"}
                selectedValue={searchText}
                onChange={onSearchTextChange}
                onFocus={() => {}}
              />

              {AssignTemplatesOption && (
                <Stack sx={{ pb: 2 }}>{AssignTemplatesOption()}</Stack>
              )}
            </Stack>
            {!templatesSuggestMutation?.isLoading && (
              <Stack sx={{ width: "100%", flex: 1, overflow: "hidden" }}>
                <ListTable
                  list={templateList ?? []}
                  columns={columns}
                  mutation={templatesSuggestMutation}
                  isLoading={isLoading}
                  rowClickCallback={() => {}}
                  onSortChange={onSortChange}
                  multiselect={multiselect && hasPermission}
                  onSelectCallback={onSelectCallback}
                  canDisableSelection={true}
                  hasPermission={hasPermission}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  page={pageCount}
                  pageSize={pageSize}
                  totalCount={totalCount ?? templateList?.length}
                  paginationMode={page === "tags" ? "client" : "server"}
                  sorting={page === "tags" ? false : true}
                />
                {selectedTemplateList.length > 0 && (
                  <Annotation setComment={setComment} comment={comment} />
                )}
              </Stack>
            )}
          </Stack>
          {showConfirmation && (
            <RemoveTemplatesDialog
              criteria={criteria ?? ""}
              templates={selectedTemplateList}
              allowRemove={showConfirmation}
              handleAllowRemove={handleConfirmationVisibility}
              page={page}
              policyId={policyId}
              onConfirm={handlerOnClose}
              comment={comment}
            />
          )}
        </Stack>
      </DialogContent>
      {multiselect && (
        <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
          <ToolbarAction
            loading={false}
            save={triggerUnassign}
            cancel={handlerOnClose}
            isValid={isValid()}
            actionBtnTooltip={window.getCTTranslatedText(
              "removePushToFirewall"
            )}
            hasPermission={hasPermission}
            actionBtnText={"Remove"}
            secondaryBtnText={"cancel"}
          />
        </DialogActions>
      )}
    </Drawer>
  );
};
