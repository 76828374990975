import { AgentActionStatusInfo } from "pages/agents/components/agent-data-grid/constants";
import { AutoUpgradeStatus } from "pages/agents/components/agent-drawers/helpers/constants";
import { AgentStatusType } from "pages/agents/types";
import { Appliance } from "pages/appliances/types";

export const renderToolTipDrawerUpgradeColumns = (data: Appliance) => {
  let toolTipText: string = "";

  if (!data.isUpgradeEnabled) {
    if (
      data.agentUpgradeStatus === AgentActionStatusInfo.Pending &&
      data.agentStatus === "active"
    ) {
      toolTipText = "Agent upgrade in progress...";
    } else if (
      data.agentUpgradeStatus === AgentActionStatusInfo.Pending &&
      (data.agentStatus === "absent" || data.agentStatus === "unknown")
    ) {
      toolTipText = "Agent upgrade pending...";
    } else if (
      data.agentUpgradeStatus === AgentActionStatusInfo.Synchronized &&
      !data.agentUpgradeAvailable
    ) {
      toolTipText = "Agent is up to date";
    } else {
      toolTipText = "Agent no longer exists";
    }
  }
  return toolTipText;
};

export const renderToolTipDrawerRestartColumns = (data: Appliance) => {
  let toolTipText: string = "";

  if (!data.isRestartEnabled) {
    if (
      data.agentRestartStatus === AgentActionStatusInfo.Pending &&
      data.agentStatus === "active"
    ) {
      toolTipText = "Agent restart in progress...";
    } else if (
      data.agentRestartStatus === AgentActionStatusInfo.Pending &&
      data.agentStatus === "absent"
    ) {
      toolTipText = "Agent restart pending...";
    } else {
      toolTipText = "Agent no longer exists";
    }
  }
  return toolTipText;
};

export const renderToolTipDrawerAutoUpgradeColumns = (
  data: Appliance,
  action: string
) => {
  let toolTipText: string = "";

  if (action === AutoUpgradeStatus.Enable && data.autoUpgradeEnabled) {
    toolTipText = "Agent auto upgrade is already enabled";
  } else if (action === AutoUpgradeStatus.Disable && !data.autoUpgradeEnabled) {
    toolTipText = "Agent auto upgrade is already disabled";
  }
  return toolTipText;
};

export const renderToolTipDrawerDiagnosticsColumns = (data: Appliance) => {
  let toolTipText: string = "";
  if (
    data?.isDiagnosticsSupported &&
    (data.agentStatus === AgentStatusType.Absent ||
      data.agentStatus === AgentStatusType.Unknown)
  ) {
    toolTipText = "Agent is offline";
  } else if (data.isDiagnosticsRunning) {
    if (
      data.agentDiagnosticsStatus === AgentActionStatusInfo.Pending &&
      data.agentStatus === AgentStatusType.Active
    ) {
      toolTipText = "Diagnostics request in progress...";
    }
  } else if (!data.isDiagnosticsSupported) {
    toolTipText = "Diagnostics not supported";
  } else if (!data.agentId) {
    toolTipText = "Agent no longer exists";
  }
  return toolTipText;
};
