import { useMutation, useQuery } from "@tanstack/react-query";
import { ApplianceConfig } from "../components/appliance-config-form/types";

export function useApplianceConfigGetAPI(
  agentId?: string,
  recoveryMode?: boolean
) {
  let path = `agents/${agentId}/config/gatekeeper${
    recoveryMode ? "?recoveryMode=true" : ""
  }`;
  return useQuery<ApplianceConfig, Error, any>(["gatekeeper-config", path]);
}

export function useApplianceConfigPutAPI(agentId?: string) {
  const path = `/agents/${agentId}/config/gatekeeper`;
  return useMutation<any, Error, any>(["gatekeeper", path, "put"]);
}

export function useApplianceRestorePutAPI(
  oldApplianceId?: string,
  newApplianceId?: string
) {
  const path = `agents/${newApplianceId}/recover/${oldApplianceId}`;
  return useMutation<any, Error, any>(["gatekeeper-recover", path, "put"]);
}

export function useApplianceConfigExportAPI(agentId?: string) {
  let path = `agents/${agentId}/config/gatekeeper/export`;
  return useMutation<any, Error, any>([
    "gatekeeper-config-export",
    path,
    "post",
  ]);
}

export const useEditApplianceAPI = (agentId: string | undefined) => {
  const path = `gateways/${agentId}`;
  return useMutation<any, Error, any>(["gateways", path, "put"]);
};
