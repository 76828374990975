import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { getColor } from "common/constants/colors";
import { getLabelFromStatus } from "pages/traffic-visualizer/visx-utils";
import { PathTypes } from "../types";

export function PathLegend({
  status,
  enabled,
  setEnabled,
  disabled = false,
}: {
  status: PathTypes;
  enabled?: boolean;
  setEnabled?: (enabled: boolean) => void;
  disabled?: boolean;
}) {
  return (
    <>
      {setEnabled && (
        <FormControlLabel
          sx={{
            mr: 0,
          }}
          disabled={disabled}
          control={
            <Checkbox
              color="default"
              checked={enabled}
              onChange={(e, checked) => {
                setEnabled(checked);
              }}
              disabled={disabled}
              size="small"
              sx={
                disabled
                  ? {
                      opacity: 0.5,
                    }
                  : undefined
              }
            />
          }
          label={
            <Stack
              sx={{
                textDecoration: "underline",
                textDecorationThickness: 1,
                textDecorationColor: getColor(status),
                textUnderlineOffset: 10,
              }}
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant="body1">
                {window.getCTTranslatedText(getLabelFromStatus(status))}
              </Typography>
            </Stack>
          }
        />
      )}
    </>
  );
}
