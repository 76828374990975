import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Divider,
  FormControlLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext } from "assets/ctTheme/CTThemeProvider";
import { useDemoController } from "hooks/useDemoController";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { BackgroundProcessesMenuItem } from "modules/AppBar/components/BackgroundProcessesMenuItem";
import { SwitchTenantMenu } from "modules/AppBar/components/SwitchTenantMenu";
import { TranslateLanguageMenu } from "modules/AppBar/components/TranslateLanguageMenu";
import { MENU_ID } from "modules/AppBar/constants";
import { PendingWorkRequestsDrawer } from "modules/PendingWorkRequestsDrawer";
import { useWorkRequestStore } from "modules/PendingWorkRequestsDrawer/store";
import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes";

interface UserProfileMenuProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: Function;
  onLogout: Function;
}

export const UserProfileMenu = ({
  anchorEl,
  setAnchorEl,
  onLogout,
}: UserProfileMenuProps) => {
  const { isFeatureEnabled: isWorkRequestEnabled } = useFeatureFlagControl(
    FEATURES.WORK_REQUEST
  );

  const isPendingWorkRequestsDrawerOpen = useWorkRequestStore(
    state => state.isOpen
  );
  const setIsPendingWorkRequestsDrawerOpen = useWorkRequestStore(
    state => state.setOpen
  );

  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isLangMenuOpen = Boolean(anchorElLang);
  const colorMode = useContext(ColorModeContext);

  const handleTranslateLanguageMenuOpen = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { isDemo, handleDemoClick, handleHomeClick } = useDemoController();

  const handleSwitchDemoClick = () => {
    handleMenuClose();
    handleDemoClick();
  };

  const handleSwitchHomeClick = () => {
    handleMenuClose();
    handleHomeClick();
  };

  const handleOrgManage = () => {
    handleMenuClose();
    return navigate(ROUTES.ORG);
  };

  const onBackgroundProcessesDrawerClose = useCallback(
    () => setIsPendingWorkRequestsDrawerOpen(false),
    [setIsPendingWorkRequestsDrawerOpen]
  );

  const onBackgroundProcessesDrawerOpen = () => {
    handleMenuClose();
    setIsPendingWorkRequestsDrawerOpen(true);
  };

  const handleIntegrationManage = () => {
    handleMenuClose();
    return navigate(ROUTES.INTEGRATIONS);
  };

  const handleAPIKeysManage = () => {
    handleMenuClose();
    return navigate(ROUTES.API_KEYS);
  };

  const handleLogout = () => {
    onLogout();
    handleMenuClose();
    try {
      window.localStorage.clear();
    } catch (e) {
      console.error("failed to clear storage storage on logout");
    }
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        id={MENU_ID}
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          sx: { width: "240px", pt: 2 },
        }}
      >
        {!isDemo && <SwitchTenantMenu />}
        <Divider variant="middle" sx={{ px: 3, my: 1 }} />

        {isDemo ? (
          <MenuItem onClick={handleSwitchHomeClick}>
            {window.getCTTranslatedText("Exit demo")}
          </MenuItem>
        ) : (
          <MenuItem onClick={handleSwitchDemoClick}>
            {window.getCTTranslatedText("Switch to demo")}
          </MenuItem>
        )}

        {!isDemo && userPermissions.has("LIST_SUBJECT") && (
          <MenuItem onClick={handleOrgManage}>
            {window.getCTTranslatedText("Manage organization")}
          </MenuItem>
        )}

        {!isDemo && true === userPermissions.get("LIST_USER_API_KEY") && (
          <MenuItem onClick={handleAPIKeysManage}>
            {window.getCTTranslatedText("Manage APIs")}
          </MenuItem>
        )}

        {!isDemo && isWorkRequestEnabled && (
          <MenuItem onClick={onBackgroundProcessesDrawerOpen}>
            {isMenuOpen ? <BackgroundProcessesMenuItem /> : null}
          </MenuItem>
        )}

        {!isDemo && userPermissions.has("LIST_INTEGRATION") && (
          <MenuItem onClick={handleIntegrationManage}>
            {window.getCTTranslatedText("Integrations")}
          </MenuItem>
        )}
        <Divider variant="middle" sx={{ px: 3 }} />
        <MenuItem onClick={colorMode.toggleColorMode}>
          {window.getCTTranslatedText("Dark Mode")}
          <Stack
            alignItems={"flex-end"}
            sx={{
              width: "100%",
            }}
          >
            <FormControlLabel
              label={<Typography variant="body2"></Typography>}
              labelPlacement="start"
              control={
                <Switch
                  value={theme.palette.mode !== "light"}
                  checked={theme.palette.mode !== "light"}
                  inputProps={{ "aria-label": "Dark mode" }}
                />
              }
            />
          </Stack>
        </MenuItem>
        {
          <MenuItem onClick={handleTranslateLanguageMenuOpen}>
            <Typography variant="inherit">
              {window.getCTTranslatedText("Change Language")}
            </Typography>
            <ListItemIcon>
              <KeyboardArrowRight fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        }
        <TranslateLanguageMenu
          anchorElLang={anchorElLang}
          isLangMenuOpen={isLangMenuOpen}
          setAnchorElLang={setAnchorElLang}
          handleMenuClose={handleMenuClose}
        />
        <Divider variant="middle" sx={{ px: 3 }} />
        <MenuItem onClick={handleLogout}>
          {window.getCTTranslatedText("Logout")}
        </MenuItem>
      </Menu>
      {isPendingWorkRequestsDrawerOpen && (
        <PendingWorkRequestsDrawer
          isOpen={isPendingWorkRequestsDrawerOpen}
          onClose={onBackgroundProcessesDrawerClose}
        />
      )}
    </>
  );
};
