import { Box } from "@mui/material";
import { AssetColumnRenderProps } from "pages/assets/types";
import { AssetSecurityLevelIcons } from "../asset-security-level-icons";

import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AssetBusinessRisk({ asset, viewOnly }: AssetColumnRenderProps) {
  if (!asset?.assetId) {
    return <Box style={{ width: 70 }} />;
  }

  return (
    <CTDatagridButton
      isDisabled={viewOnly === true}
      asText={viewOnly === true}
      facetName={"assetrisk"}
      facetOption={asset?.assetRisk ?? ""}
      startIcon={<AssetSecurityLevelIcons securityLevel={asset?.assetRisk} />}
    >
      {window.getCTTranslatedText(asset?.assetRisk)}
    </CTDatagridButton>
  );
}
