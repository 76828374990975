import { platforms } from "../constants";

export function processPlatformDisplayName(platform: string) {
  switch (platform) {
    case platforms.RPM:
      return `Linux${" "}(${platforms.RPM})`;
    case platforms.SUSE:
      return `SUSE`;
    case platforms.DEBIAN:
      return `Linux${" "}(${platforms.DEBIAN})`;
    case platforms.WINDOWS:
      return "Windows";
    case platforms.DARWIN:
      return "Mac";
    case platforms.DOCKER:
      return "Containers";
    case platforms.AIX:
      return "AIX";
    case platforms.RPM_LEGACY:
      return `RHEL 6`;
    case platforms.SOLARIS:
      return `Solaris`;
  }
}
