import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { dayjsWithPlugins } from "common/utils";
import { API_URL } from "hooks/useNetworking/constants";
import {
  Agent,
  AgentStatusType,
  DiagnosticOptions,
  Diagnostics,
} from "pages/agents/types";
import prettyBytes from "pretty-bytes";
import { useMemo, useState } from "react";
import { AgentDiagnosticSupportedMinVersion } from "../agent-data-grid/constants";
import { AgentDiagnosticDetailsDrawer } from "../agent-drawers";
import { DiagnosticFetcher } from "./DiagnosticFetcher";

interface ViewDiagnosticButtonProps {
  agentId: string;
  agent?: Agent;
}

export const ViewDiagnosticButton = ({
  agentId,
  agent,
}: ViewDiagnosticButtonProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [optionsData, setOptionsData] = useState<Diagnostics>();
  const [areOptionsLoading, setAreOptionsLoading] = useState(true);

  let tooltipText = "View Diagnostics";

  if (!agentId) {
    tooltipText = "Agent unavailable";
  } else if (agent?.currentVersion === "") {
    tooltipText = "Agent version unavailable";
  } else if (
    agent?.isDiagnosticsRunning &&
    agent.agentStatus === AgentStatusType.Active
  ) {
    tooltipText = "Diagnostics request in progress...";
  } else if (
    agent?.isDiagnosticsRunning &&
    (agent.agentStatus === AgentStatusType.Absent ||
      agent.agentStatus === AgentStatusType.Unknown)
  ) {
    tooltipText = "Diagnostics request pending...";
  } else if (agent?.currentVersion && !agent?.isDiagnosticsSupported) {
    tooltipText = `Agent version must be ${AgentDiagnosticSupportedMinVersion} or higher`;
  }

  const isDisabled = () =>
    Boolean(!agentId) ||
    agent?.isDiagnosticsRunning ||
    !agent?.isDiagnosticsSupported;

  const onViewDiagnosticClick = () => {
    if (agentId) {
      setIsDrawerOpen(true);
    }
  };
  const onDrawerClick = () => setIsDrawerOpen(false);

  const diagnosticOptions: DiagnosticOptions = useMemo(
    () =>
      optionsData?.items.map((item, index) => ({
        value: item.lastModified,
        label: `${
          index === 0 ? window.getCTTranslatedText("Latest (") : ""
        } ${dayjsWithPlugins(item.lastModified).format("DD-MMM-YY HH:mm:ss")} ${
          index === 0 ? ")" : ""
        }`,
        id: item.name,
        fileSize: prettyBytes(item.sizeInBytes),
      })),
    [optionsData]
  );

  return (
    <>
      {isDrawerOpen && (
        <DiagnosticFetcher
          agentId={agentId}
          setOptionsData={setOptionsData}
          setAreOptionsLoading={setAreOptionsLoading}
        />
      )}
      <AgentDiagnosticDetailsDrawer
        isOpen={isDrawerOpen}
        onClose={onDrawerClick}
        label="Date"
        areOptionsLoading={areOptionsLoading}
        diagnosticOptions={diagnosticOptions}
        downloadPrefix={`${API_URL}/agents/${agentId}/diagnostics/`}
      />
      <Tooltip title={window.getCTTranslatedText(tooltipText)}>
        <Stack>
          <IconButton
            sx={{ p: 0 }}
            color="primary"
            size="medium"
            disabled={isDisabled()}
            onClick={() => onViewDiagnosticClick()}
          >
            <TroubleshootIcon />
          </IconButton>
        </Stack>
      </Tooltip>
    </>
  );
};
