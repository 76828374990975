import { Stack, Grid, Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import { UserGroupsColumnRenderProps } from "../../types";
import { useState } from "react";
import { UserGroupPathsDrawer } from "../user-group-paths-drawer";

export function UserGroupPaths({
  user,
  viewOnly,
}: Readonly<UserGroupsColumnRenderProps>) {
  const [reviewPathDrawerOpen, setReviewPathDrawerOpen] =
    useState<boolean>(false);
  const [unreviewedPath, setUnreviewedPath] = useState<boolean>(false);
  const pathClickHandler = (isUnreviewed: boolean) => {
    setUnreviewedPath(isUnreviewed);
    setReviewPathDrawerOpen(true);
  };
  const handleClose = () => {
    setReviewPathDrawerOpen(false);
    setUnreviewedPath(false);
  };
  const unreviewedPaths = numeral(user?.unreviewedPaths ?? 0).format("0a");
  const totalPaths = numeral(user?.totalPaths ?? 0).format("0a");
  return (
    <Stack
      sx={{ maxWidth: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="center"
      >
        <Tooltip
          title={
            !viewOnly && user?.unreviewedPaths
              ? window.getCTTranslatedText("View unreviewed paths")
              : ""
          }
        >
          {viewOnly || !user?.unreviewedPaths ? (
            <Typography variant="body2" sx={{ color: "warning.main" }}>
              {unreviewedPaths}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              onClick={() => {
                pathClickHandler(true);
              }}
              sx={{ color: "warning.main" }}
            >
              {unreviewedPaths}
            </Typography>
          )}
        </Tooltip>

        <Stack style={{ margin: "0 4px" }}>/</Stack>
        <Tooltip
          title={
            !viewOnly && user?.totalPaths
              ? window.getCTTranslatedText("View all paths")
              : ""
          }
        >
          {viewOnly || !user?.totalPaths ? (
            <Typography variant="body2" sx={{ color: "primary.main" }}>
              {totalPaths}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              onClick={() => {
                pathClickHandler(false);
              }}
              sx={{ color: "primary.main" }}
            >
              {totalPaths}
            </Typography>
          )}
        </Tooltip>
      </Grid>
      <UserGroupPathsDrawer
        isOpen={reviewPathDrawerOpen}
        onClose={handleClose}
        userGroup={user}
        pathReviewState={unreviewedPath}
      />
    </Stack>
  );
}
