import { CssBaseline, ThemeProvider } from "@mui/material";
import { green, grey } from "@mui/material/colors";
// RTL plugins
import { createContext, useEffect, useMemo } from "react";
// import rtlPlugin from "stylis-plugin-rtl";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { useDirection } from "./hooks";
import { createCTTheme } from "./themeOptions";

interface CTThemeProviderProps {
  children: React.ReactNode;
}

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const CTThemeProvider = ({ children }: CTThemeProviderProps) => {
  const direction = useDirection();
  const mode = useUserPreferencesStore(state => state.themeMode);
  const setMode = useUserPreferencesStore(state => state.setThemeMode);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(mode === "light" ? "dark" : "light");
      },
    }),
    [mode, setMode]
  );

  const lightTheme = useMemo(
    () =>
      createCTTheme({
        palette: {
          mode: "light",
          text: {
            primary: "rgba(0,0,0, 0.87)",
            secondary: "rgba(0,0,0, 0.6)",
            disabled: "rgba(0,0,0, 0.38)",
          },
          primary: {
            main: "#0474DC",
            dark: "#02519A",
            light: "#368FE3",
            contrastText: "#fff",
          },
          secondary: {
            main: "#D7EBFE",
            dark: "#9DCFFF",
            light: "#D7EBFE",
            contrastText: "#0474DC",
          },
          error: {
            main: "#D32F2F",
            dark: "#C62828",
            light: "#EF5350",
            contrastText: "#fff",
          },
          warning: {
            main: "#FD8F35",
            dark: "#FF6A1A",
            light: "#FF9800",
            contrastText: "#fff",
          },
          info: {
            main: "#0288D1",
            dark: "#01579B",
            light: "#03A9F4",
            contrastText: "#fff",
          },
          success: {
            main: "#2E7D32",
            dark: "#1B5E20",
            light: "#4CAF50",
            contrastText: "#fff",
          },
          action: {
            active: "rgba(0, 0, 0, 0.54)",
            hover: "rgba(0, 0, 0, 0.04)",
            selected: "rgba(0, 0, 0, 0.08)",
            disabled: "rgba(0, 0, 0, 0.26)",
            disabledBackground: "rgba(0, 0, 0, 0.12)",
            focus: "rgba(0, 0, 0, 0.87)",
          },
          divider: "rgba(0, 0, 0, 0.12)",
          background: {
            default: "#F6F9FC",
          },
          // Add any other custom colors here
          custom: {
            appHeader: "#082A5E",
            appNavbar: "rgba(0, 68, 119, 0.04)",
            appNavbarBdr: "rgba(0, 0, 0, 0.02)",
            appNavbarActive: "rgba(255, 255, 255, 0.50)",
            lightGrey: "#333333",
            rowBackground: "rgba(249, 249, 249, 1)",
            lightGreen: green[300],
            widgetBorder: "1px solid #d9d9d9",
            filterBg: "rgba(255, 255, 255, 0.05)",
          },
        },
      }),
    []
  );

  const darkTheme = useMemo(
    () =>
      createCTTheme({
        palette: {
          mode: "dark",
          text: {
            primary: "#fff",
            secondary: "rgba(255,255,255, 0.7)",
            disabled: "rgba(255,255,255, 0.5)",
          },
          primary: {
            main: "#3AA0FF",
            dark: "#025AAB",
            light: "#56AEFF",
            contrastText: "rgba(0,0,0, 0.87)",
          },
          secondary: {
            main: "#022241",
            dark: "#01172D",
            light: "#344E67",
            contrastText: "rgba(71, 167, 255, 0.87)",
          },
          error: {
            main: "#F44336",
            dark: "#D32F2F",
            light: "#E57373",
            contrastText: "rgba(0, 0, 0, 0.87)",
          },
          warning: {
            main: "#FFA726",
            dark: "#F57C00",
            light: "#FFB74D",
            contrastText: "rgba(0, 0, 0, 0.87)",
          },
          info: {
            main: "#29B6F6",
            dark: "#0288D1",
            light: "#4FC3F7",
            contrastText: "rgba(0, 0, 0, 0.87)",
          },
          success: {
            main: "#66BB6A",
            dark: "#388E3C",
            light: "#81C784",
            contrastText: "rgba(0, 0, 0, 0.87)",
          },
          action: {
            active: "rgba(255, 255, 255, 0.56)",
            hover: "rgba(255, 255, 255, 0.08)",
            selected: "rgba(255, 255, 255, 0.16)",
            disabled: "rgba(255, 255, 255, 0.3)",
            disabledBackground: "rgba(255, 255, 255, 0.12)",
            focus: "rgba(255, 255, 255, 0.12)",
          },
          divider: "rgba(255, 255, 255, 0.12)",
          background: {
            default: "#020608",
            paper: "#121212",
          },
          // Add any other custom colors here
          custom: {
            appHeader: grey[900],
            appNavbar: "rgba(255, 255, 255, 0.04)",
            appNavbarBdr: "rgba(255, 255, 255, 0.08)",
            appNavbarActive: "rgba(255, 255, 255, 0.04)",
            lightGrey: "#333333",
            rowBackground: "rgba(0, 0, 0, 0.87)",
            lightGreen: green[300],
            widgetBorder: "1px solid rgba(255, 255, 255, 0.12)",
            filterBg: "rgba(255, 255, 255, 0.05)",
            tableBdrClrDark: "rgb(81, 81, 81)",
          },
        },
      }),
    []
  );

  const theme = useMemo(() => {
    return mode === "light" ? lightTheme : darkTheme;
  }, [mode, darkTheme, lightTheme]);

  // const [rtlCache, setRtlCache] = useState<EmotionCache | null>(null);

  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });

  //   setRtlCache(cacheRtl);
  // }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  const getThemeProvider = (direction: "ltr" | "rtl" = "ltr") => {
    return (
      <>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </ColorModeContext.Provider>
      </>
    );
  };

  // TODO: RTL
  // if (direction === "rtl" && rtlCache) {
  //   <CacheProvider value={rtlCache}>
  //     {getThemeProvider(direction)}
  //   </CacheProvider>;
  // }

  return getThemeProvider(direction);
};
