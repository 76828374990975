import { Button, Paper, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { UseMutationResult } from "@tanstack/react-query";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { FacetOpenCloseControllerType } from "common/atoms/facet-open-close-controller/FacetOpenCloseController";
import { getSelectedTimeFacetNameFromScope } from "common/atoms/facet-open-close-controller/helpers";
import { useCommonStore } from "common/store";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { CustomCommonStoreType } from "common/types/types";
import { getRelativeLastObserved } from "common/utils";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { Annotation } from "modules/annotation";
import { useCore } from "modules/core";
import { CreateTagBasedPolicyDrawer } from "modules/create-tag-based-policy-drawer";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { FacetControllerGroup } from "modules/facets";
import { mergeFacetStates } from "modules/facets/FacetUtils";
import { FacetOptionState, FacetState, Operator } from "modules/facets/types";
import { Scope } from "modules/scope-metadata/types";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { PathColumnConfig } from "pages/paths/components/path-data-grid/constants";
import { isRowSelectable } from "pages/paths/components/path-data-grid/helpers";
import {
  usePathsAPI,
  useUpdateTotalCount,
} from "pages/paths/components/path-data-grid/hooks";
import { usePathsFacetsOrder } from "pages/paths/constants";
import { usePathStore } from "pages/paths/store";
import { PathStoreType } from "pages/paths/store/types";
import { Path, PathFilters, PathStatus } from "pages/paths/types";
import prettyBytes from "pretty-bytes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PathStatusToolbar } from "./components/path-status-toolbar";
import { PORTS_REVIEW_DATA_GRID_ID, getPathReviewColumns } from "./constants";

function getRowId({ channelHash, direction }: Path) {
  return `${channelHash}-${direction}`;
}

const READ_ONLY_PATH_DIMENSIONS = ["direction"];
interface PathsReviewProps {
  preSelectedPaths?: GridRowId[] | undefined;
  setPathSelection?: (selectionModel: GridRowId[]) => void;
  pathFilter?: PathFilters;
  updatePathstatus?: ((status: PathStatus | undefined) => void) | undefined;
  useFacetStore?: CustomCommonStoreType;
  useStore?: PathStoreType;
  includeCommonFacet?: boolean;
  preSelectedPathStatus?: PathStatus | undefined;
  setComment?: React.Dispatch<React.SetStateAction<string | undefined>>;
  direction?: Direction;
  comment?: string;
  facetState?: FacetState;
  mode: AssetReviewModeType;
  api?: () => UseMutationResult<any, Error, any, unknown>;
  readOnlyDimensions?: string[];
}

export const PathsReview = ({
  preSelectedPaths,
  setPathSelection,
  pathFilter,
  updatePathstatus,
  facetState,
  useFacetStore = useCommonStore,
  useStore = usePathStore,
  includeCommonFacet = false,
  preSelectedPathStatus,
  setComment,
  direction,
  comment,
  mode,
  api,
  readOnlyDimensions,
}: PathsReviewProps) => {
  const selectedTime = useUserPreferencesStore(state => state.timeFilter);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const metadata = useFacetStore(state => state.metadata);
  const pathsAPI = api ?? usePathsAPI;
  const apiRefreshPath = useStore(state => state.apiRefreshRequest);
  const requestPortAPIRefresh = useStore(state => state.requestAPIRefresh);
  const [selection, setSelection] = useState<Array<GridRowId>>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);

  const commonFacetState = useCommonStore(state => state.facets);
  const setFacets = useFacetStore(state => state.setFacets);

  const getPathFacet = useCallback((time: string) => {
    const facet: FacetState = new Map();
    const options: FacetOptionState = new Map();
    options.set(time, { isSelected: true, operator: Operator.EQUAL });
    facet.set(getSelectedTimeFacetNameFromScope(Scope.Path), options);
    return facet;
  }, []);

  const coreResponse = useCore<Path>({
    useStore: useStore,
    facetGroupInfo: usePathsFacetsOrder(),
    scope: Scope.Path,
    dataMapper: path => {
      path.bandwidthInBytes = prettyBytes(Number(path.bandwidthInBytes));
      path.pathLastObserved = getRelativeLastObserved(
        path.pathLastObserved,
        true
      );
      path["isRowSelectable"] = isRowSelectable(path);
      return path;
    },
    defaultSortOrder: [{ field: "pathlastobserved", order: "desc" }],
    useApi: pathsAPI,
    pageSize: 100,
    skipUseFacetQueryConnector: true,
    additionalCriteria: `${pathFilter?.criteria}` || "",
    sourceCriteria: pathFilter?.srcCriteria || "",
    destinationCriteria: pathFilter?.dstCriteria || "",
    useFacetStore: useFacetStore,
  });

  const updateExternalCriteria = useFacetStore(
    state => state.setExternalCriteria
  );
  useEffect(() => {
    if (pathFilter?.criteria) {
      updateExternalCriteria(pathFilter?.criteria);
    }
  }, [pathFilter?.criteria, updateExternalCriteria]);

  useEffect(() => {
    let updatedFacetState = facetState;
    if (
      mode !== AssetReviewModeType.FireWallReview &&
      mode !== AssetReviewModeType.ViewFirewallPendingChanges
    ) {
      updatedFacetState = mergeFacetStates(
        updatedFacetState,
        getPathFacet(selectedTime)
      );
    }
    if (includeCommonFacet && commonFacetState) {
      setFacets(mergeFacetStates(commonFacetState, updatedFacetState));
    } else {
      setFacets(updatedFacetState);
    }
  }, [
    mode,
    selectedTime,
    getPathFacet,
    setFacets,
    includeCommonFacet,
    commonFacetState,
    facetState,
  ]);

  useEffect(() => {
    setSelection([]);
  }, [apiRefreshPath]);

  useEffect(() => {
    if (
      preSelectedPaths &&
      preSelectedPaths.length > 0 &&
      coreResponse?.rows &&
      initialLoad
    ) {
      setSelection(preSelectedPaths);
      if (setPathSelection) {
        setPathSelection(preSelectedPaths);
      }
      setInitialLoad(true);
    }
  }, [preSelectedPaths, setPathSelection, coreResponse?.rows, initialLoad]);

  const updateSelections = (selectedPathIds: GridRowId[]) => {
    if (selectedPathIds && selectedPathIds?.length > 0) {
      setSelection(selectedPathIds);
      if (setPathSelection) {
        setPathSelection(selectedPathIds);
      }
    } else {
      setSelection([]);
      if (setPathSelection) {
        setPathSelection([]);
      }
    }
  };

  const hasService = useMemo(() => {
    return coreResponse.rows?.some((row: Path) => {
      return Boolean(row?.method) || Boolean(row?.uri);
    });
  }, [coreResponse.rows]);

  const handleAddToTemplateDialogOpen = (dialogVisibility: boolean) => {
    setShowAddToTemplateDialog(dialogVisibility);
  };

  const handleClose = () => {
    setShowAddToTemplateDialog(false);
    requestPortAPIRefresh();
  };

  const selectedData: Array<Path> | undefined = useMemo(() => {
    return coreResponse.rows?.filter((row: Path) => {
      return selection.indexOf(getRowId(row)) !== -1;
    });
  }, [selection, coreResponse.rows]);

  const selectedRawData: Array<Path> | undefined = useMemo(() => {
    return (coreResponse?.rawData ?? [])?.filter((row: Path) => {
      return selection.indexOf(getRowId(row)) !== -1;
    });
  }, [selection, coreResponse?.rawData]);

  const showSrcDestOption: boolean = useMemo(() => {
    return (selectedData || []).some((row: Path) => {
      return row?.sourceAsset?.assetId && row?.destinationAsset?.assetId;
    });
  }, [selectedData]);

  const [isCreateTagPolicyDrawerOpen, setIsCreateTagPolicyDrawerOpen] =
    useState(false);

  const isCreateTagPolicyButtonDisable = useMemo(() => {
    if (!selectedData || selectedData.length !== 1) {
      return true;
    }
    return !selectedData.every(
      pathData =>
        Object.keys(pathData.destinationAsset?.coreTags ?? {}).length > 0 &&
        Object.keys(pathData.sourceAsset?.coreTags ?? {}).length > 0
    );
  }, [selectedData]);

  useUpdateTotalCount({
    isLoading: coreResponse.mutation.isLoading,
    maxRowCount: coreResponse.maxRowCount,
    originalRowCount: coreResponse.rowCount,
    id: PORTS_REVIEW_DATA_GRID_ID,
  });

  const rowCount =
    coreResponse.rowCount > 0 &&
    coreResponse.rowCount === coreResponse.maxRowCount
      ? coreResponse.rowCount - 1
      : coreResponse.rowCount;

  const pathReadOnlyDimensions = useMemo(() => {
    if (direction === undefined) {
      return readOnlyDimensions;
    }

    return [...(readOnlyDimensions ?? []), ...READ_ONLY_PATH_DIMENSIONS];
  }, [direction, readOnlyDimensions]);

  const columns = useMemo(
    () => getPathReviewColumns({ useFacetStore, mode, hasService }),
    [useFacetStore, mode, hasService]
  );

  return (
    <Stack
      alignItems="flex-start"
      spacing={0}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction="row" sx={{ width: "100%" }} flexWrap={"wrap"}>
        <Stack
          justifyItems={"flex-start"}
          justifyContent={"flex-start"}
          alignItems="flex-start"
          sx={{ mt: 1, flex: 1 }}
        >
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
            controllerType={FacetOpenCloseControllerType.MENU}
            useFacetStore={useFacetStore}
            disableSavedQuery={true}
            disableSearchChip={true}
            showFacetToggle={
              mode !== AssetReviewModeType.ViewFirewallPendingChanges &&
              mode !== AssetReviewModeType.EdgeReview
            }
            readOnlyDimensions={pathReadOnlyDimensions}
          >
            <FacetControllerGroup
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
              useFacetStore={useFacetStore}
              readOnlyDimensions={pathReadOnlyDimensions}
            />
          </FacetOpenCloseController>
        </Stack>
        <Stack
          justifyContent="flex-end"
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <Stack
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {userPermissions.has("UPDATE_PATH") && (
              <PathStatusToolbar
                updatePathstatus={updatePathstatus}
                useStore={useStore}
                preSelectedPathStatus={preSelectedPathStatus}
                selection={selectedData}
                mode={mode}
              />
            )}
          </Stack>
          {mode !== AssetReviewModeType.Selection && (
            <>
              {userPermissions.has("CREATE_TAGBASEDPOLICY") && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setIsCreateTagPolicyDrawerOpen(true)}
                  disabled={isCreateTagPolicyButtonDisable}
                >
                  {window.getCTTranslatedText("create access policy")}
                </Button>
              )}
              {userPermissions.has("UPDATE_TEMPLATE") && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleAddToTemplateDialogOpen(true)}
                  disabled={selectedData?.length === 0}
                  sx={{ mx: 2 }}
                >
                  {window.getCTTranslatedText("Add to template")}
                </Button>
              )}
            </>
          )}
        </Stack>
      </Stack>

      <Paper
        sx={{
          width: "100%",
          pt: 3,
          flex: 1,
          overflow: "hidden",
        }}
        id={PORTS_REVIEW_DATA_GRID_ID}
      >
        <DataGrid
          columnVisibilityModel={PathColumnConfig.ColumnVisibilityModel}
          defaultPinnedColumns={PathColumnConfig.PinnedColumns}
          isRowSelectable={params =>
            mode !== AssetReviewModeType.Selection
              ? true
              : params.row.isRowSelectable
          }
          checkboxSelection={userPermissions.has("UPDATE_PATH")}
          rowSelectionModel={selection}
          onRowSelectionModelChange={selectionModel => {
            updateSelections(selectionModel);
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "pathlastobserved", sort: "desc" }],
            },
          }}
          exportRowCount={coreResponse.rowCount}
          rowHeight={64}
          columns={columns}
          getRowId={getRowId}
          paginationMode="server"
          sortingMode="server"
          pagination
          isLoading={
            coreResponse?.mutation?.isLoading || !coreResponse.rows || false
          }
          rows={getUniqueListBy(coreResponse.rows || [], "channelHash") || []}
          rowCount={rowCount}
          mutation={coreResponse.mutation}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={sortModel => {
            if (sortModel?.length > 0) {
              sortModel = sortModel.map(item => {
                item.field = item.field.toLowerCase();
                return item;
              });
              coreResponse.onSortChange(sortModel);
            }
          }}
          metadata={metadata}
          triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
          getExportStatus={coreResponse?.getExportStatus}
          getUrlToDownload={coreResponse?.getUrlToDownload}
          resetDownloadUrl={coreResponse?.resetDownloadUrl}
          rawData={coreResponse?.rawData}
          selectedRawData={selectedRawData}
        />
      </Paper>
      <AddToTemplateDrawer
        isOpen={showAddToTemplateDialog}
        page={"paths"}
        title="Add to Template"
        rules={selectedData}
        showSrcDestOption={showSrcDestOption}
        onCancel={handleClose}
        onConfirm={handleClose}
        btnTitle={"add"}
        actionType={TemplateActionType.add}
      />

      {isCreateTagPolicyDrawerOpen && selectedData?.[0] && (
        <CreateTagBasedPolicyDrawer
          isOpen={isCreateTagPolicyDrawerOpen}
          defaultDestinationCoreTags={
            selectedData?.[0]?.destinationAsset?.coreTags
          }
          defaultSourceCoreTags={selectedData?.[0]?.sourceAsset?.coreTags}
          templateRules={[
            {
              listenPort: selectedData?.[0]?.port,
              listenPortProtocol: selectedData?.[0]?.protocol,
              listenPortReviewed: selectedData?.[0]?.reviewed,
              listenProcessNames: selectedData?.[0]?.process,
            },
          ]}
          onClose={() => {
            setIsCreateTagPolicyDrawerOpen(false);
            handleClose();
          }}
        />
      )}
      {selectedData && selectedData?.length > 0 && (
        <Annotation setComment={setComment} comment={comment} />
      )}
    </Stack>
  );
};
