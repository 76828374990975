import { GridRowId } from "@mui/x-data-grid";
import { parseErrorMessage } from "common/utils";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useUserGroupsStatusAPI } from "pages/users-segmentation/components/user-groups/hooks/useUserGroupApi";
import { HostAndUserGroupStatusState } from "pages/users-segmentation/components/user-groups/types";
import {
  DrawerType,
  HostAndUserGroupStatusEnum,
} from "modules/host-and-user-group-change-status-drawer/HostAndUserGroupChangeStatusDrawer";
import { useHostGroupsStatusAPI } from "pages/host-groups/hooks/useHostGroupApi";

interface UserGroupStatusActionBarProps {
  selectedStatus?: HostAndUserGroupStatusEnum;
  selection: GridRowId[];
  cancel: () => void;
  update: () => void;
  drawerType: DrawerType;
}

export const UserGroupStatusActionBar = ({
  selectedStatus,
  drawerType,
  selection,
  cancel,
  update,
}: UserGroupStatusActionBarProps) => {
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const userGroupsStatusAPI = useUserGroupsStatusAPI();
  const hostGroupsStatusAPI = useHostGroupsStatusAPI();

  const updateStatus = async (
    selection: GridRowId[],
    selectedStatus?: HostAndUserGroupStatusEnum
  ) => {
    if (selection?.length > 0 && selectedStatus) {
      const getAllGroupIds = selection.map(obj => `'${obj}'`);

      if (drawerType === DrawerType.UserGroup) {
        const body = {
          criteria: `groupid in (${getAllGroupIds.join(", ")})`,
          toState:
            selectedStatus.toLowerCase() === HostAndUserGroupStatusState.Active
              ? "active"
              : "inactive",
        };

        userGroupsStatusAPI.mutateAsync(body, {
          onSuccess: response => {
            setSnackbar(
              true,
              SnackBarSeverity.Success,
              "UserGroupStatusSetToStatus",
              {
                status: window.getCTTranslatedText(
                  selectedStatus.toLowerCase() ===
                    HostAndUserGroupStatusState.Active
                    ? "active"
                    : "inactive"
                ),
              }
            );
            update();
          },
          onError: error => {
            setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
          },
        });
      }

      if (drawerType === DrawerType.HostGroup) {
        const body = {
          criteria: `hostgroupid in (${getAllGroupIds.join(", ")})`,
          toState:
            selectedStatus.toLowerCase() === HostAndUserGroupStatusState.Active
              ? "active"
              : "inactive",
        };

        hostGroupsStatusAPI.mutateAsync(body, {
          onSuccess: response => {
            setSnackbar(
              true,
              SnackBarSeverity.Success,
              "HostGroupStatusSetToStatus",
              {
                status: window.getCTTranslatedText(
                  selectedStatus.toLowerCase() ===
                    HostAndUserGroupStatusState.Active
                    ? "active"
                    : "inactive"
                ),
              }
            );
            update();
          },
          onError: error => {
            setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
          },
        });
      }
    }
  };

  const confirmAction = () => {
    if (selection?.length > 0) {
      updateStatus(selection, selectedStatus);
    }
  };

  return (
    <>
      {
        <ToolbarAction
          loading={
            userGroupsStatusAPI?.isLoading || hostGroupsStatusAPI?.isLoading
          }
          isValid={selection?.length > 0}
          actionBtnText={"Confirm"}
          save={confirmAction}
          cancel={cancel}
        ></ToolbarAction>
      }
    </>
  );
};
