import { useMutation } from "@tanstack/react-query";
import { AssignTemplateProps } from "../types";

export function useAssignTemplate({
  templateId,
  assetId,
}: AssignTemplateProps) {
  const path = `assets/${assetId}/templates/${templateId}`;
  return useMutation<any, Error, any>(["asset", path, "put"]);
}

export function useAssignTemplateToAssets() {
  const path = `assets/templates`;
  return useMutation<any, Error, any>(["asset", path, "put"]);
}

export function useAssignTemplateToTags({
  policyId,
}: {
  policyId: string | undefined;
}) {
  const path = `tagbasedpolicies/${policyId}/templates`;
  const mutation = useMutation<any, Error, any>([
    "tagbasedpolicy",
    path,
    "put",
  ]);

  if (!policyId) {
    return { mutateAsync: undefined };
  }
  return mutation;
}
