import { Box, Stack, useTheme } from "@mui/material";
// import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
// import { useCore } from "modules/core";
// import { FacetControllerGroup } from "modules/facets";
import { Scope } from "modules/scope-metadata/types";
import { COMMON_FACETS } from "modules/facets/constants";

import { Loader } from "common/atoms/loader";
import { FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
// import { useTagRulesListAPI } from "pages/tags/components/tag-rules-list/TagRulesList";
// import {
//   useTagRuleFacetStore,
//   useTagRuleStore,
// } from "pages/tags/components/tag-rules-list/store";
import { useMemo } from "react";
import { PolicyForm } from "./components/PolicyForm";

export function useCoreTagsFacetOrder() {
  let { data: metadata } = useScopeMetadata({ scope: Scope.TagPolicy });

  const facetOrder = useMemo(() => {
    if (!metadata?.columns) {
      return;
    }
    let columnNames = Object.keys(metadata?.columns || {});
    let otherGroup: any = {};
    columnNames.forEach(name => {
      if (metadata?.columns[name]?.userDefined) {
        otherGroup[name] = FacetGroups.Tags;
      }
    });

    let order = {
      ...otherGroup,
      ...COMMON_FACETS,
    };

    return order;
  }, [metadata?.columns]);

  return facetOrder;
}
export function CreateTagPolicy() {
  const facetOrder = useCoreTagsFacetOrder();

  if (!facetOrder) {
    return <Loader />;
  }

  return <CreateTagPolicyCore facetOrder={facetOrder} />;
}

export function CreateTagPolicyCore({
  facetOrder,
}: {
  facetOrder: FacetGroupInfo;
}) {
  const theme = useTheme();

  // const coreResponse = useCore<Scope.TagPolicy>({
  //   useStore: useTagRuleStore,
  //   facetGroupInfo: facetOrder,
  //   scope: Scope.TagPolicy,
  //   useFacetStore: useTagRuleFacetStore,
  //   useApi: useTagRulesListAPI,
  //   pageSize: 1,
  // });

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      {/* <Stack direction={"row"} alignItems="center">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            useFacetStore={useTagRuleFacetStore}
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
            title="Tag Rule Filters"
            dialogTitle="Apply Tag Rule Filters"
          >
            <FacetControllerGroup
              useFacetStore={useTagRuleFacetStore}
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack> */}

      <Box
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowY: "auto",
          flex: 1,
          height: "100%",
        }}
      >
        <PolicyForm facetOrder={facetOrder} />
      </Box>
    </Stack>
  );
}
