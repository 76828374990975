import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import { useDemoController } from "hooks/useDemoController";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { AssetToolbar } from "pages/assets/components/asset-data-grid-toolbar";
import { Asset, AssetType } from "pages/assets/types";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ASSET_COLUMNS, AssetColumnConfig } from "./constants";
import { useCommonStore } from "common/store";

export const AssetDataGrid = (props: DataGridProps<Asset>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const recommendationId =
    new URLSearchParams(window.location.search).get("recommendation") || "";

  const [selection, setSelection] = useState<Array<GridRowId>>([]);

  useEffect(() => {
    if (recommendationId && userPermissions.has("UPDATE_ASSET")) {
      const tempSelections: GridRowId[] = (props.rows || []).map(
        item => item.assetId
      );
      if (tempSelections?.length > 0) {
        setSelection(tempSelections);
      }
    }
  }, [
    recommendationId,
    props.rows,
    searchParams,
    setSearchParams,
    userPermissions,
  ]);

  const onClickClearSelection = () => {
    setSelection([]);
    if (searchParams.has("recommendation")) {
      searchParams.delete("recommendation");
      setSearchParams(searchParams);
    }
  };

  const facets = useCommonStore(state => state.facetConfig);
  const appliedFacets = useCommonStore(state => state.facets);
  const appliedTypeFacet = useMemo(() => {
    return appliedFacets?.get("type");
  }, [appliedFacets]);

  const hasDeviceFacet = useMemo(() => {
    if (
      appliedTypeFacet &&
      appliedTypeFacet?.size > 0 &&
      !appliedTypeFacet?.has(AssetType.Device)
    ) {
      return false;
    }

    const typeOptions = facets?.[0]?.facets?.find(
      facet => facet.name === "type"
    )?.options;
    if (typeOptions && typeOptions?.length > 0) {
      const index = typeOptions?.findIndex(
        option => option.name === AssetType.Device
      );
      return index !== undefined && index >= 0;
    }

    return appliedTypeFacet?.has(AssetType.Device) ?? false;
  }, [facets, appliedTypeFacet]);

  const hasDevice = useMemo(() => {
    return props.rows?.some((row: Asset) => {
      return row.type === AssetType.Device;
    });
  }, [props.rows]);

  let hasVulnerabilities = useMemo(() => {
    return props.rows?.some((row: Asset) => {
      return row.vulnerabilities !== null;
    });
  }, [props.rows]);

  let hasPatches = useMemo(() => {
    return props.rows?.some((row: Asset) => {
      return row.securityPatches !== null;
    });
  }, [props.rows]);

  const assetColumns: GridColDef[] =
    props.columns ?? ASSET_COLUMNS(props.viewOnly ?? false);

  const columns = useMemo(
    () =>
      assetColumns.filter(column => {
        if (column.field === "assetAvailability") {
          return hasDeviceFacet && hasDevice;
        }

        if (column.field === "vulnerabilities") {
          return hasVulnerabilities;
        }

        if (column.field === "securityPatches") {
          return hasPatches;
        }

        return true;
      }),
    [hasDevice, hasDeviceFacet, hasPatches, hasVulnerabilities, assetColumns]
  );

  const { isDemo, handleDemoClick } = useDemoController();

  const selectedData: Array<Asset> | undefined = useMemo(() => {
    return props.rows?.filter((row: Asset) => {
      return selection.indexOf(row.assetId) !== -1;
    });
  }, [selection, props.rows]);

  const selectedRawData: Array<Asset> | undefined = useMemo(() => {
    return (props?.rawData ?? [])?.filter((row: Asset) => {
      return selection.indexOf(row.assetId) !== -1;
    });
  }, [selection, props?.rawData]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      {selection && selection?.length > 0 && (
        <AssetToolbar
          selectedData={selectedData ?? []}
          hideToolbar={onClickClearSelection}
          show={selection?.length > 0}
          recommendationId={recommendationId}
          showDeviceActions={props.showDeviceActions}
        />
      )}
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <DataGrid
          defaultPinnedColumns={AssetColumnConfig?.PinnedColumns}
          slots={{
            noRowsOverlay: () =>
              AssetsNoRowsOverlayComp({ isDemo, handleDemoClick, props }),
          }}
          checkboxSelection={userPermissions.has("UPDATE_ASSET")}
          rowSelectionModel={selection}
          onRowSelectionModelChange={selectionModel => {
            setSelection(selectionModel);
          }}
          rowHeight={64}
          columns={columns}
          pagination
          getRowId={({ assetId }: Asset) => assetId}
          paginationMode="server"
          sortingMode="server"
          {...props}
          selectedRawData={selectedRawData}
        />
      </Box>
    </Stack>
  );
};

interface AssetsNoRowsOverlayCompProps {
  isDemo?: boolean;
  handleDemoClick: () => Promise<void>;
  props: DataGridProps<Asset>;
}

const AssetsNoRowsOverlayComp = ({
  isDemo,
  handleDemoClick,
  props,
}: AssetsNoRowsOverlayCompProps) => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent="center"
      sx={{
        zIndex: 100,
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      {isDemo && <CircularProgress sx={{ mb: 2 }} size={36} />}
      <Typography variant="body2">
        {window.getCTTranslatedText(
          isDemo ? "Preparing demo data" : "No results"
        )}
      </Typography>
      {!isDemo && props.hasNoData && (
        <Button onClick={handleDemoClick}>
          {window.getCTTranslatedText("Try demo data")}
        </Button>
      )}
    </Stack>
  );
};
