import CloseIcon from "@mui/icons-material/Close";
import {
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Toolbar } from "common/atoms/toolbar";
import { MatchedAssetCount } from "common/molecules/matching-asset-count";
import {
  AnalyticsAPIReq,
  AnalyticsResponse,
  CustomCommonStoreType,
} from "common/types/types";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { useCriteriaBuilder } from "modules/core/Core";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { CustomCommonStore } from "pages/Integration/components/scim-management-config/store/useIDPFacetStore";
import { DisplayAppliedRule } from "pages/create-tag-rule/components/display-applied-rule";
import { Port } from "pages/ports/types";
import { useState } from "react";
import { StoreApi, UseBoundStore } from "zustand";
import { SelectionType } from "./PathRecommendationDrawer";
import { PortRecommendationActions } from "./components/port-recommendation-status-action-bar";
import { PortRecommendations } from "./components/port-recommendations";
import {
  PortAgg,
  usePortRecommendationsWithDataVolumeGroup,
} from "./hooks/usePortRecommendations";

interface PortRecommendationCoreProps {
  onClose: () => void;
  criteria: string;
  useStore?: CustomCommonStoreType;
  hidePreview?: boolean;
  useFacetStore?: UseBoundStore<StoreApi<CustomCommonStore>>;
}

interface PortRecommendationDrawerProps extends PortRecommendationCoreProps {
  isOpen: boolean;
}

export const PortRecommendationDrawer = (
  props: PortRecommendationDrawerProps,
) => {
  return (
    <Drawer
      anchor="right"
      open={props.isOpen}
      onClose={props.onClose}
      PaperProps={{
        sx: {
          padding: "0px",
          width: "80%",
          minWidth: "1000px",
          height: "100%",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title="Close Drawer">
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={props.onClose}
            sx={{ position: "absolute", right: "16px", top: "16px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <PortRecommendationCore {...props} />
    </Drawer>
  );
};

export const PortRecommendationCore = ({
  onClose,
  criteria,
  useStore,
  hidePreview,
  useFacetStore,
}: PortRecommendationCoreProps) => {
  const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);
  const [portsSelection, setPortsSelection] = useState<PortAgg[]>([]);
  const facetState = useFacetStore?.(state => state.facets);
  const handleAddToTemplateDialogOpen = (dialogVisibility: boolean) => {
    setShowAddToTemplateDialog(dialogVisibility);
  };
  let { data: assetMetadata } = useScopeMetadata({ scope: Scope.Asset });

  const handleCloseAddTemplateDrawer = () => {
    setShowAddToTemplateDialog(false);
    onClose();
  };
  const facetAssetCriteria = useCriteriaBuilder("", facetState, assetMetadata);
  const assetCriteria =
    facetAssetCriteria === "*" ? criteria : facetAssetCriteria;

  const {
    aggregates: portAggregates,
    total: totalMatchingPortAssets,
    isLoading: isPortLoading,
  } = usePortRecommendationsWithDataVolumeGroup({
    criteria: assetCriteria,
  });

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ mt: 4, mb: 0, mx: 4 }}>
        <Typography variant="h5">
          <b>{window.getCTTranslatedText("Recommendations")}</b>
        </Typography>

        {!hidePreview && (
          <>
            <DisplayAppliedRule
              useFacetStore={useFacetStore}
              minRequired={1}
              sortByName={true}
              viewOnly
              metadata={assetMetadata}
            />

            <Stack direction={"row"} spacing={1}>
              <MatchedAssetCount
                total={totalMatchingPortAssets}
                isLoading={isPortLoading}
              />
            </Stack>
          </>
        )}
      </Stack>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <PortRecommendations
          setPortSelection={setPortsSelection}
          isLoading={isPortLoading}
          data={portAggregates}
        />

        <AddToTemplateDrawer
          isOpen={showAddToTemplateDialog}
          page={"ports"}
          title="Add to Template"
          rules={portsSelection.map(
            port =>
              ({
                listenPort: port.port,
                listenPortProtocol: port.protocol,
              }) as Port,
          )}
          showPortStatusOption={true}
          onCancel={handleCloseAddTemplateDrawer}
          onConfirm={handleCloseAddTemplateDrawer}
          btnTitle={"add"}
          actionType={TemplateActionType.add}
        />
      </DialogContent>

      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <PortRecommendationActions
          isValid={portsSelection.length > 0}
          isLoading={false}
          update={() => handleAddToTemplateDialogOpen(true)}
          cancel={onClose}
          selection={SelectionType.Template}
        />
      </DialogActions>
    </>
  );
};

export function useAssetAggregateAPI() {
  return useMutation<AnalyticsResponse, Error, AnalyticsAPIReq>([
    "asset-aggregate",
    "aggregate",
  ]);
}
