import { Dimension } from "modules/hierarchy-vis/types";
import { CompassDirection, PathStatus } from "pages/paths/types";
import { XYPosition } from "reactflow";

export enum PathType {
  None = 0,
  Internet = 1,
  Intranet = 2,
}

export interface TrafficVisualizerProps {
  criteria?: string;
  onClose?: Function;
}

export enum PathReviewStatus {
  Enforced = 0,
  WIP = 1,
  DIFF = 2,
}

export type PathStatusMap = Map<PathStatus, number>;

export const TypeDimension = { dataType: "", label: "Type", name: "type" };

export const Dimensions: Array<Dimension> = [
  { dataType: "", label: "Application", name: "application" },
  { dataType: "", label: "Subnet", name: "primarysubnet" },
  { dataType: "", label: "OS", name: "osname" },
  { dataType: "", label: "Location", name: "location" },
  { dataType: "", label: "Role", name: "role" },
  { dataType: "", label: "Owner", name: "owner" },
  { dataType: "", label: "Environment", name: "environment" },
  { dataType: "", label: "Asset", name: "assetname" },
  TypeDimension,
  { dataType: "", label: "Cluster Identifier", name: "clusteridentifier" },
  { dataType: "", label: "Container Namespace", name: "containernamespace" },
  { dataType: "", label: "Model", name: "model" },
  { dataType: "", label: "Category", name: "category" },
  { dataType: "", label: "Sub Category", name: "subcategory" },
  { dataType: "", label: "Manufacturer", name: "manufacturer" },
];

export enum CTNodeType {
  HUB = "hub",
  CONNECTIONS = "connections",
}

export enum TrafficType {
  Unknown = 0,
  Managed = 1,
  NamedNetwork = 2,
}
export interface TrafficInfo {
  compassDirection: CompassDirection;
  type: TrafficType;
}
export interface GroupNodeDataType {
  title: string;
  label: string;
  id: string;
  handle: string;
  trafficData: TrafficDatum | undefined;
  canExpand?: boolean;
  dimension?: Dimension;
  type: CTNodeType;
  childrenExpandedBy?: Dimension | undefined;
}

export interface TrafficData {
  [key: string]: TrafficDatum;
}

export interface TrafficDataVersions {
  originalTrafficData: TrafficData | undefined;
  workingCopy: TrafficData | undefined;
}

export interface AssetStatusAggResult {
  statusData: {
    [assetStatus: string]: AggResult;
  };
  dimension?: Dimension | undefined;
}

export interface StatusTrafficStats {
  stats?: TrafficStats;
  dimensionStats?: Map<string, AssetStatusAggResult> | undefined;
  eastWestNetworkStats?: Map<string, AssetStatusAggResult> | undefined;
  northSouthNetworkStats?: Map<string, AssetStatusAggResult> | undefined;
  aggregates: Aggregates;
}

export enum StatisticType {
  Enforced = "enforced",
  Candidate = "reviewed",
}

export interface StatusTrafficStatsHolder {
  [StatisticType.Candidate]?: StatusTrafficStats;
  [StatisticType.Enforced]?: StatusTrafficStats;
}

export interface StatisticAggregate {
  [StatisticType.Candidate]?: number;
  [StatisticType.Enforced]?: number;
}
export interface TrafficDatum {
  name: string;
  data: StatusTrafficStatsHolder;
  sourceDimension?: Dimension | undefined;
  parent?: TrafficDatum;
  children?: Array<TrafficData>;
}
export interface TrafficStats {
  [CompassDirection.EastWest]: TrafficStatusAgg;
  [CompassDirection.NorthSouth]: TrafficStatusAgg;
}
export interface TrafficStatusAgg {
  [namedNetwork: string]: {
    [dimension: string]: AssetStatusAggResult;
  };
}

export interface Aggregates {
  pathsAggregate?: number;
  assetAggregate?: number;
}
interface AggResult extends Aggregates {
  pathStatusMap: PathStatusMap;
}

export interface SourceNodeControlProps {}

export interface SourceNodeDataProps extends SourceNodeControlProps {
  label: string;
  source: string;
}

export type PathTypes = PathStatus | "Mixed";
export type EnabledStatusMap = Map<PathTypes, boolean>;
export interface ExpansionNode {
  dimension: Dimension;
  parent: {
    name: string;
    dimension: Dimension;
    children?: Array<ExpansionNode>;
  };
}

export type Expansions = Array<ExpansionNode>;

type Position = XYPosition;
type Styles = React.CSSProperties;

export type PositionStore = Map<
  string,
  { position?: Position; styles?: Styles }
>;
