import { IconsProps } from "./common";

export const PortIcon = ({
  width = "24px",
  height = "24px",
  color,
}: IconsProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Port Icon">
        <g id="Vector">
          <rect
            id="Rectangle 6836"
            x="3"
            y="3"
            width="18"
            height="18"
            rx="1"
            stroke={color}
            strokeWidth="2"
          />
          <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.6129 15.4208H15.6935H16.5484C16.7978 15.4208 17 15.19 17 14.9053V13.9208V6.51554C17 6.23081 16.7978 6 16.5484 6H7.45161C7.20219 6 7 6.23081 7 6.51554V13.9208V14.9053C7 15.19 7.20219 15.4208 7.45161 15.4208H8.30645H8.3871V16.2371C8.3871 16.4174 8.46816 16.5761 8.59095 16.6682C8.66209 16.7216 8.74722 16.7526 8.83871 16.7526H9.80645V16.9208V17.4845C9.80645 17.7692 10.0086 18 10.2581 18H13.7419C13.9914 18 14.1935 17.7692 14.1935 17.4845V16.9208V16.7526H15.1613C15.2528 16.7526 15.3379 16.7216 15.409 16.6682C15.5318 16.5761 15.6129 16.4174 15.6129 16.2371V15.4208ZM8.5 13.9208H15.5V7.5H14.5V8V9V10H13.5V9V8V7.5H12.5V8V9V10H11.5V9V8V7.5H10.5V8V9V10H9.5V9V8V7.5H8.5V13.9208Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};
