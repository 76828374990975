import { Agent, AgentStatusType } from "pages/agents/types";
import { AgentActionStatusInfo } from "../../agent-data-grid/constants";
import { AutoUpgradeStatus, DebugLogStatus } from "./constants";
import { Typography } from "@mui/material";

const ToolTipTypography = (toolTipText: string) => {
  if (toolTipText === "") {
    return "";
  }
  return (
    <Typography
      style={{
        maxWidth: 250,
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "inherit",
      }}
    >
      {toolTipText}
    </Typography>
  );
};

export const renderToolTipDrawerUpgradeColumns = (data: Agent) => {
  let toolTipText: string = "";

  if (!data.isUpgradeEnabled) {
    if (
      data.agentUpgradeStatus === AgentActionStatusInfo.Pending &&
      data.agentStatus === "active"
    ) {
      toolTipText = "Agent upgrade in progress...";
    } else if (
      data.agentUpgradeStatus === AgentActionStatusInfo.Pending &&
      (data.agentStatus === "absent" || data.agentStatus === "unknown")
    ) {
      toolTipText = "Agent upgrade pending...";
    } else if (
      data.agentUpgradeStatus === AgentActionStatusInfo.Synchronized &&
      !data.agentUpgradeAvailable
    ) {
      toolTipText = "Agent is up to date";
    } else {
      toolTipText = "Agent no longer exists";
    }
  }
  return ToolTipTypography(toolTipText);
};
export const renderToolTipDrawerDiagnosticsColumns = (data: Agent) => {
  let toolTipText: string = "";

  if (
    data?.isDiagnosticsSupported &&
    (data.agentStatus === AgentStatusType.Absent ||
      data.agentStatus === AgentStatusType.Unknown)
  ) {
    toolTipText = "Agent is offline";
  } else if (data.isDiagnosticsRunning) {
    if (
      data.agentDiagnosticsStatus === AgentActionStatusInfo.Pending &&
      data.agentStatus === AgentStatusType.Active
    ) {
      toolTipText = "Diagnostics request in progress...";
    }
  } else if (!data.isDiagnosticsSupported) {
    toolTipText = "Diagnostics not supported";
  } else if (!data.agentId) {
    toolTipText = "Agent no longer exists";
  }
  return ToolTipTypography(toolTipText);
};

export const renderToolTipDrawerRestartColumns = (data: Agent) => {
  let toolTipText: string = "";

  if (!data.isRestartEnabled) {
    if (
      data.agentRestartStatus === AgentActionStatusInfo.Pending &&
      data.agentStatus === "active"
    ) {
      toolTipText = "Agent restart in progress...";
    } else if (
      data.agentRestartStatus === AgentActionStatusInfo.Pending &&
      data.agentStatus === "absent"
    ) {
      toolTipText = "Agent restart pending...";
    } else {
      toolTipText = "Agent no longer exists";
    }
  }
  return ToolTipTypography(toolTipText);
};

export const renderToolTipDrawerAutoUpgradeColumns = (
  data: Agent,
  action: string
) => {
  let toolTipText: string = "";

  if (action === AutoUpgradeStatus.Enable && data.autoUpgradeEnabled) {
    toolTipText = "Agent auto upgrade is already enabled";
  } else if (action === AutoUpgradeStatus.Disable && !data.autoUpgradeEnabled) {
    toolTipText = "Agent auto upgrade is already disabled";
  }
  return ToolTipTypography(toolTipText);
};

export const renderToolTipDrawerDecommissionColumns = (data: Agent) => {
  let toolTipText: string = "";

  if (!data.isDecommissionSupported) {
    toolTipText = window.getCTTranslatedText("decommissionNotSupported");
  }
  if (!data.isDecommissionEnabled) {
    if (data.agentDecommissionStatus === AgentActionStatusInfo.Pending) {
      toolTipText = window.getCTTranslatedText("agentDecommissionInProgress");
    } else if (!data.agentId) {
      toolTipText = window.getCTTranslatedText("Agent no longer exists");
    }
  }
  return ToolTipTypography(toolTipText);
};

export const renderToolTipDrawerDebugLogColumns = (
  data: Agent,
  action: string
) => {
  let toolTipText: string = "";
  if (!data.isDebugLogSupported) {
    toolTipText = window.getCTTranslatedText("debugLogNotSupported");
  } else if (data.isDebugLogRunning) {
    toolTipText = window.getCTTranslatedText("agentDebugLogInProgress");
  } else if (action === DebugLogStatus.Enable && data.isDebugLogEnabled) {
    toolTipText = window.getCTTranslatedText("agentDebugLogIsAlreadyEnabled");
  } else if (action === DebugLogStatus.Disable && !data.isDebugLogEnabled) {
    toolTipText = window.getCTTranslatedText("agentDebugLogIsAlreadyDisabled");
  }
  return ToolTipTypography(toolTipText);
};

export const renderToolTipDrawerNorthSouthTrafficConfigColumns = (
  data: Agent,
  action: string
) => {
  let toolTipText: string = "";
  if (!data.isNorthSouthTrafficConfigurationSupported) {
    toolTipText = window.getCTTranslatedText("nsConfigNotSupported");
  } else if (action === data.northSouthTrafficConfiguration) {
    toolTipText = window.getCTTranslatedText("nsConfigIsAlreadyInSameState");
  }
  return ToolTipTypography(toolTipText);
};

export const renderToolTipDrawerTrafficConfigColumns = (
  data: Agent,
  action: string
) => {
  let toolTipText: string = "";
  if (!data.isTrafficConfigurationSupported) {
    toolTipText = window.getCTTranslatedText("trafficConfigNotSupported");
  } else if (action === data.currentTrafficConfiguration) {
    toolTipText = window.getCTTranslatedText("trafficConfigAlreadyInSameState");
  }
  return ToolTipTypography(toolTipText);
};
