import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "@mui/material";
import {
  AppliedFacetChipsLocation,
  FacetContainer,
  FacetOpenCloseControllerType,
} from "common/atoms/facet-open-close-controller/FacetOpenCloseController";
import { useCommonStore } from "common/store";
import { CustomCommonStoreType } from "common/types/types";

import { useEffect, useState } from "react";

export interface AddCriteriaProps {
  facetsOpen: boolean;
  setFacetsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: JSX.Element;
  appliedChipsLocation?: AppliedFacetChipsLocation;
  useFacetStore?: CustomCommonStoreType;
  dialogTitle?: string;
  readOnlyDimensions?: Array<string>;
  btnTitle?: string;
  viewOnly?: boolean;
  btnSize?: "small" | "medium" | "large";
}

export const AddCriteria = ({
  facetsOpen,
  setFacetsOpen,
  children,
  appliedChipsLocation = AppliedFacetChipsLocation.CONTROLLER,
  useFacetStore = useCommonStore,
  dialogTitle,
  readOnlyDimensions,
  btnTitle = "add criteria",
  viewOnly,
  btnSize,
}: AddCriteriaProps) => {
  const [appliedCount, setAppliedCount] = useState(0);

  const closeAllFacets = useFacetStore(state => state.closeAllFacets);

  const onClose = () => {
    setFacetsOpen(false);
    if (closeAllFacets) {
      closeAllFacets(true);
    }
  };

  useEffect(() => {
    setFacetsOpen(false);
  }, [setFacetsOpen]);

  const [anchor, setAnchor] = useState<any>(undefined);
  return (
    <>
      {!viewOnly && (
        <Button
          variant="text"
          startIcon={<AddCircleOutlineIcon />}
          color="primary"
          onClick={e => {
            setAnchor(e.currentTarget);
            setFacetsOpen(!facetsOpen);
          }}
          size={btnSize ?? "medium"}
        >
          {window.getCTTranslatedText(btnTitle)}
        </Button>
      )}
      <FacetContainer
        facetsOpen={facetsOpen}
        onClose={onClose}
        dialogTitle={dialogTitle}
        appliedChipsLocation={appliedChipsLocation}
        appliedCount={appliedCount}
        setAppliedCount={setAppliedCount}
        useFacetStore={useFacetStore}
        readOnlyDimensions={readOnlyDimensions}
        children={children}
        controllerType={FacetOpenCloseControllerType.DRAWER}
        anchorEl={anchor}
        align={"right"}
      />
    </>
  );
};
