import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Loader } from "common/atoms/loader";
import { METHOD_TYPE } from "common/constants/methods";
import { parseErrorMessage } from "common/utils";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { useEffect, useMemo, useState } from "react";
import {
  filteredIntegrationData,
  getIntegrationGroupedData,
  getSortedCategories,
} from "./IntegrationUtils";
import { CrowdStrikeDrawer } from "./components/crowdStrike-drawer";
import { IntegrationCard } from "./components/integration-card";
import { IntegrationDetailsDrawer } from "./components/integration-detail-drawer";
import { ScimManagementConfig } from "./components/scim-management-config";
import {
  INTEGRATION_CATEGORY,
  ALLOWED_INTEGRATIONS,
  IntegrationConfigType,
  Integration,
  getIntegrationEnum,
  INTEGRATIONS,
  EXPERIMENTAL,
} from "./constants";
import {
  useIntegrationActionConnection,
  useIntegrationConfigAPI,
  useIntegrationUpdate,
} from "./hooks";
import { useAuthConfigAPI } from "./tabs/authentication/hooks";

export const Integrations = () => {
  const { isFeatureEnabled } = useFeatureFlagControl(
    FEATURES.EXPERIMENTAL_INTEGRATIONS
  );
  const [selectedCategory, setSelectedCategory] = useState(
    INTEGRATION_CATEGORY.ALL
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<
    IntegrationConfigType | undefined
  >(undefined);
  const {
    data: integrationList = [],
    refetch: refetchIntegrationData,
    isLoading,
  } = useIntegrationConfigAPI({ staleTime: 0 });

  const { data: authConfigData } = useAuthConfigAPI();
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  if (typeof authConfigData !== "undefined" && authConfigData) {
    authConfigData.forEach(authConfigEntry => {
      const matchingIntegrationEntry = integrationList.find(
        (integrationEntry: IntegrationConfigType) => {
          return integrationEntry.name === authConfigEntry.authName;
        }
      );

      if (matchingIntegrationEntry) {
        matchingIntegrationEntry.configData = authConfigEntry;
      }
    });
  }

  const integrationConfigData = useMemo(() => {
    if (isFeatureEnabled) {
      EXPERIMENTAL.forEach(i => {
        ALLOWED_INTEGRATIONS[i] = true;
      });
    }

    return integrationList.filter((integration: IntegrationConfigType) => {
      return ALLOWED_INTEGRATIONS[getIntegrationEnum(integration)];
    });
  }, [integrationList, isFeatureEnabled]);

  const sortedCategories = getSortedCategories(integrationConfigData);

  const groupedData = getIntegrationGroupedData(integrationConfigData);

  let filteredData: IntegrationConfigType[] = filteredIntegrationData(
    selectedCategory,
    integrationConfigData
  );

  const categoriesArray = Object.keys(groupedData).sort((a, b) => {
    return a.localeCompare(b); // This sorts alphabetically
  });

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };

  const integrationActionMutation = useIntegrationActionConnection();
  const updateIntegrationstatus = (
    status: boolean,
    integration: IntegrationConfigType
  ) => {
    const body = [
      {
        integrationProvider: integration.integrationProvider,
        integrationType: integration.integrationType,
        status: status,
      },
    ];

    integrationActionMutation.mutateAsync(body, {
      onSuccess: (response: any) => {
        setSnackbar(true, SnackBarSeverity.Success, "ConfigurationActivated", {
          name: capitalizeFirstLetter(integration.name),
        });
        refetchIntegrationData();
      },
      onError: (error: any) => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const submitConfigMutation = useIntegrationUpdate(
    selectedIntegration?.integrationType || "",
    selectedIntegration?.integrationProvider || "",
    selectedIntegration?.configured ? METHOD_TYPE.PUT : METHOD_TYPE.POST
  );

  useEffect(() => {
    setSelectedIntegration(selectedIntegration);
  }, [selectedIntegration]);

  const handleActivateClick = (integration: IntegrationConfigType) => {
    setSelectedIntegration(integration);
    if (
      getIntegrationEnum(integration) ===
      INTEGRATIONS.XCLOUD_VULNERABILITYSCANNER
    ) {
      setTimeout(() => {
        const body: Integration = {
          integrationProvider: integration.integrationProvider,
          integrationType: integration.integrationType,
          integrationParameters: {},
        };
        submitConfigMutation.mutateAsync(body, {
          onSuccess: () => {
            setSnackbar(
              true,
              SnackBarSeverity.Success,
              "IntegrationConfigurationSaved",
              { name: capitalizeFirstLetter(integration.name) }
            );

            setTimeout(() => {
              updateIntegrationstatus(true, integration);
            }, 500);
          },
          onError: (error: any) => {
            setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
          },
        });
      }, 200);
    } else {
      setOpenDrawer(true);
    }
  };

  const handleDeactivateClick = (integration: IntegrationConfigType) => {
    refetchIntegrationData();
  };

  const handleRefreshClick = () => {
    refetchIntegrationData();
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setTimeout(() => {
      refetchIntegrationData();
    }, 500);
  };

  const loaderActive = (value: boolean, item?: IntegrationConfigType) => {
    if (item) {
      filteredData.forEach(function (obj) {
        if (obj.name.toLowerCase() === item.name.toLowerCase()) {
          obj.isLoading = value;
        }
      });
    } else {
      filteredData.forEach(function (obj) {
        obj.isLoading = false;
      });
    }
  };

  return (
    <Stack direction="row" spacing={3}>
      {!integrationConfigData.length && <Loader />}
      {integrationConfigData.length > 0 && (
        <Stack
          direction="column"
          spacing={2}
          width={360}
          p={2}
          sx={{ position: "relative" }}
        >
          <List component="nav" sx={{ position: "fixed", width: "360px" }}>
            <ListItem
              key={INTEGRATION_CATEGORY.ALL}
              button
              selected={selectedCategory === INTEGRATION_CATEGORY.ALL}
              onClick={() => handleCategoryClick(INTEGRATION_CATEGORY.ALL)}
            >
              <ListItemText
                primary={
                  <Typography
                    sx={{ float: "left" }}
                    variant="body2"
                    component="div"
                  >
                    {window.getCTTranslatedText(INTEGRATION_CATEGORY.ALL)}
                  </Typography>
                }
                secondary={
                  <Typography
                    sx={{ float: "right" }}
                    variant="body2"
                    component="div"
                  >
                    {integrationConfigData.length}
                  </Typography>
                }
              />
            </ListItem>
            {sortedCategories.map(category => (
              <ListItem
                key={category}
                button
                selected={selectedCategory === category}
                onClick={() => handleCategoryClick(category)}
              >
                <ListItemText
                  primary={
                    <Typography
                      sx={{ float: "left" }}
                      variant="body2"
                      component="div"
                    >
                      {window.getCTTranslatedText(category)}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{ float: "right" }}
                      variant="body2"
                      component="div"
                    >
                      {
                        integrationConfigData.filter(
                          (item: IntegrationConfigType) =>
                            item.category === category
                        ).length
                      }
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Stack>
      )}
      <Stack flex={1} p={2}>
        {selectedCategory !== INTEGRATION_CATEGORY.ALL && (
          <Stack marginTop={2} marginBottom={1}>
            <Typography variant="subtitle1">
              {window.getCTTranslatedText(selectedCategory)}
            </Typography>
          </Stack>
        )}
        {categoriesArray.map(key => (
          <Stack key={key}>
            {selectedCategory === INTEGRATION_CATEGORY.ALL && (
              <Stack key={`stack-${key}`} marginTop={2} marginBottom={1}>
                <Typography variant="subtitle1">
                  {window.getCTTranslatedText(key)}
                </Typography>
              </Stack>
            )}
            <Grid container spacing={2} marginBottom={2}>
              {filteredData.map(
                (item: IntegrationConfigType) =>
                  item.category === key && (
                    <Grid
                      key={`grid-${item.name}-${item.integrationType}`}
                      item
                      xs={4}
                      marginBottom={2}
                    >
                      <IntegrationCard
                        item={item}
                        onActivateClick={handleActivateClick}
                        onDeactivateClick={handleDeactivateClick}
                        onRefreshData={handleRefreshClick}
                        isLoading={item.isLoading || isLoading}
                      />
                    </Grid>
                  )
              )}
            </Grid>
          </Stack>
        ))}
        {openDrawer &&
          selectedIntegration &&
          (() => {
            switch (getIntegrationEnum(selectedIntegration)) {
              case INTEGRATIONS.SCIM_USERPROVISIONING:
                return (
                  <ScimManagementConfig
                    isOpen={openDrawer}
                    page={"integration"}
                    title={window.getCTTranslatedText("configureSCIM")}
                    onCancel={handleCloseDrawer}
                  />
                );

              case INTEGRATIONS.CROWDSTRIKE_CMDB:
                return (
                  <CrowdStrikeDrawer
                    open={openDrawer}
                    onClose={handleCloseDrawer}
                    integration={selectedIntegration}
                    title={`Configure ${selectedIntegration.name}`}
                  />
                );
              default:
                return (
                  <IntegrationDetailsDrawer
                    open={openDrawer}
                    onClose={handleCloseDrawer}
                    integration={selectedIntegration}
                    loaderActive={loaderActive}
                  />
                );
            }
          })()}
      </Stack>
    </Stack>
  );
};
