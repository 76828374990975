import { useMutation } from "@tanstack/react-query";
import { Scope } from "modules/scope-metadata/types";

export interface CreateReportResponse {
  data: string;
}

export interface CreateReportRequestBody {
  jobType: "executivereport";
  scheduleType: "now";
  data: {
    name: string;
    criterias: {
      criteria: string;
      scope: Scope;
    }[];
  };
}

export function useCreateReportAPI() {
  return useMutation<CreateReportResponse, Error, CreateReportRequestBody>([
    "create-reports",
    "scheduler/schedule/job",
  ]);
}
