import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ActivationStatus, ApplianceCertificate } from "pages/appliances/types";
import { useEffect, useState } from "react";
import { CheckCircleOutline } from "@mui/icons-material";
import { useActivationStatusCheck } from "../hooks";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { parseErrorMessage } from "common/utils";
import { ACTIVATION_API_TIMEOUT } from "pages/appliances/constants";

interface ApplianceActivationStatusProps {
  applianceCertificate?: ApplianceCertificate;
}

interface ActivationStatusItemProps {
  keyName: string;
  value?: string;
  valueItem?: React.ReactElement;
}

function ActivationStatusItem({
  keyName,
  value,
  valueItem,
}: ActivationStatusItemProps) {
  const theme = useTheme();
  return (
    <Stack direction={"row"} width="100%" alignItems={"center"}>
      <Grid minWidth={150}>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.grey[600],
          }}
        >
          {keyName}
        </Typography>
      </Grid>
      <Grid item lg={6} md={6}>
        {value ? <Typography variant="body2">{value}</Typography> : valueItem}
      </Grid>
    </Stack>
  );
}

export function ApplianceActivationStatus({
  applianceCertificate,
}: ApplianceActivationStatusProps) {
  const [status, setStatus] = useState(ActivationStatus.PendingActivation);
  const [fetchEnabled, setFetchEnabled] = useState(false);
  const { data, error } = useActivationStatusCheck(
    fetchEnabled,
    applianceCertificate?.messageName
  );
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const activationStatus = data?.activationCompleted;

  const getStatusIndicator = () => {
    if (status === ActivationStatus.Activated) {
      return (
        <CheckCircleOutline fontSize="small" color="success" sx={{ mr: 1 }} />
      );
    }
    if (status === ActivationStatus.Failed) {
      return <ErrorOutlineIcon fontSize="small" color="error" sx={{ mr: 1 }} />;
    }
    return <CircularProgress size={18} sx={{ mr: 1 }} />;
  };

  const statusItems = [
    {
      keyName: window.getCTTranslatedText("Name"),
      value: applianceCertificate?.hostname,
    },
    {
      keyName: window.getCTTranslatedText("IPAddress"),
      value: applianceCertificate?.ipAddress,
    },
    {
      keyName: window.getCTTranslatedText("Status"),
      valueItem: (
        <Stack direction={"row"} alignItems={"center"}>
          {getStatusIndicator()}
          <Typography variant="body2">
            {window.getCTTranslatedText(status)}
          </Typography>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    let timer = setTimeout(() => {
      if (fetchEnabled) {
        setStatus(ActivationStatus.Failed);
      }
    }, ACTIVATION_API_TIMEOUT);
    return () => {
      clearTimeout(timer);
    };
  }, [setStatus, fetchEnabled, setFetchEnabled]);

  useEffect(() => {
    if (!fetchEnabled && status === ActivationStatus.PendingActivation) {
      setFetchEnabled(true);
    }
    if (
      fetchEnabled &&
      (status === ActivationStatus.Activated ||
        status === ActivationStatus.Failed)
    ) {
      setFetchEnabled(false);
    }
  }, [applianceCertificate, fetchEnabled, setFetchEnabled, status]);

  useEffect(() => {
    if (error) {
      setStatus(ActivationStatus.Failed);
      setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      return;
    }
    if (activationStatus) {
      setStatus(ActivationStatus.Activated);
      setSnackbar(
        true,
        SnackBarSeverity.Success,
        "applianceActivatedSuccessfully"
      );
    }
    if (activationStatus === false) {
      setStatus(ActivationStatus.Activating);
    }
  }, [activationStatus, setSnackbar, error]);

  return (
    <Stack spacing={2} width="100%">
      <Typography variant="subtitle1">
        {window.getCTTranslatedText("applianceDetails")}
      </Typography>
      {statusItems.map((item, index) => {
        return (
          <ActivationStatusItem
            key={index}
            keyName={item.keyName}
            value={item.value}
            valueItem={item.valueItem}
          />
        );
      })}
    </Stack>
  );
}
