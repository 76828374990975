import {
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Stack } from "@mui/system";
import { CTWidget } from "common/molecules/widget";
import { WidgetListItem } from "common/molecules/widget/components/widget-list-item";
import { AgentStatusType, Logs } from "pages/agents/types";
import {
  ApplianceWidgetProps,
  FirewallStatus,
} from "pages/appliance-detail/types";
import { Appliance } from "pages/appliances/types";
import CircleIcon from "@mui/icons-material/Circle";
import { API_URL } from "hooks/useNetworking/constants";
import { LogsDrawer } from "modules/logs-drawer";
import { useMemo, useState } from "react";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { LogOptions } from "pages/asset/types";
import { dayjsWithPlugins, parseErrorMessage } from "common/utils";
import prettyBytes from "pretty-bytes";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useRestartAgents } from "pages/agents/components/agent-data-grid/hooks";
import { useApplianceStore } from "pages/appliances/store";
import { LogsFetcher } from "modules/agent-logs/LogsFetcher";
import { CTConfirmation } from "common/atoms/ct-confirmation";
import { getPendingActionsTooltip } from "pages/appliances/utils";
import { ServiceRestartIcon } from "assets/svgs";
import {
  FirewallLogType,
  ViewFirewallLogsButton,
} from "pages/asset/components/asset-metadata-wrapper/components/asset-firewall-status/ViewFirewallLogsButton";

interface WidgetProps {
  appliance?: Appliance;
}

const iconStyle = {
  fontSize: 18,
};

const StatusWidgetContent = ({ appliance }: WidgetProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [optionsData, setOptionsData] = useState<Logs>();
  const [areOptionsLoading, setAreOptionsLoading] = useState(true);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const onViewLogsClick = () => {
    if (appliance?.agentId) {
      setIsDrawerOpen(true);
    }
  };

  const onDrawerClick = () => setIsDrawerOpen(false);

  const logOptions: LogOptions = useMemo(
    () =>
      optionsData?.items.map((item, index) => ({
        value: item.lastModified,
        label:
          index === 0
            ? window.getCTTranslatedText("latestAgentLogDownload", {
                date: dayjsWithPlugins(item.lastModified).format(
                  "DD-MMM-YY HH:mm:ss"
                ),
              })
            : dayjsWithPlugins(item.lastModified).format("DD-MMM-YY HH:mm:ss"),
        id: item.name,
        fileSize: prettyBytes(item.sizeInBytes),
      })),
    [optionsData]
  );

  const renderAgentStatus = (status?: string) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Stack direction="row" alignItems="center">
          <CircleIcon
            color={status === AgentStatusType.Active ? "success" : "disabled"}
            sx={{ mr: 1, ...iconStyle }}
          />
          <Typography variant="body2" sx={{ mr: 1 }}>
            {window.getCTTranslatedText(
              status === AgentStatusType.Active ? "Active" : "Inactive"
            )}
          </Typography>
        </Stack>
        <Tooltip title={window.getCTTranslatedText("viewLogs")}>
          <Button variant={"text"} onClick={onViewLogsClick}>
            {window.getCTTranslatedText("Logs")}
          </Button>
        </Tooltip>
        <>
          {isDrawerOpen && (
            <LogsFetcher
              agentId={appliance?.agentId ?? ""}
              setOptionsData={setOptionsData}
              setAreOptionsLoading={setAreOptionsLoading}
            />
          )}
          <LogsDrawer
            isOpen={isDrawerOpen}
            onClose={onDrawerClick}
            label="Logs"
            areOptionsLoading={areOptionsLoading}
            logOptions={logOptions}
            downloadPrefix={`${API_URL}/agents/${appliance?.agentId}/logs/`}
            hasPermission={userPermissions.has("READ_AGENT_LOG")}
          />
        </>
      </Stack>
    );
  };

  const renderFirewallStatus = (status?: string) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        flexWrap={"wrap"}
      >
        <Stack direction="row" alignItems="center" mr={2}>
          <CircleIcon
            color={
              status === FirewallStatus.Synchronized ? "success" : "disabled"
            }
            fontSize="small"
            sx={{ mr: 1, ...iconStyle }}
          />
          <Typography variant="body2" sx={{ mr: 1 }}>
            {window.getCTTranslatedText(
              status === FirewallStatus.Synchronized
                ? "Synchronized"
                : "Pending"
            )}
          </Typography>
        </Stack>
        <ViewFirewallLogsButton
          agentId={appliance?.agentId ?? ""}
          type={FirewallLogType.APPLIANCE}
        />
      </Stack>
    );
  };

  return (
    <Stack width="100%" spacing={3}>
      <Stack spacing={1}>
        <WidgetListItem
          keyText={window.getCTTranslatedText("applianceStatus")}
          valueElement={renderAgentStatus(appliance?.agentStatus)}
        />
        <WidgetListItem
          keyText={window.getCTTranslatedText("applianceVersion")}
          valueText={appliance?.currentVersion}
        />
      </Stack>
      <Divider />
      <WidgetListItem
        keyText={window.getCTTranslatedText("firewallStatus")}
        valueElement={renderFirewallStatus(appliance?.policySynced)}
      />
    </Stack>
  );
};

export const ApplianceStatus = ({
  appliance,
  isLoading,
  canUpdate,
}: ApplianceWidgetProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const requestAPIRefresh = useApplianceStore(store => store.requestAPIRefresh);
  const restartMutation = useRestartAgents();
  const disabledActionsTooltip = getPendingActionsTooltip(appliance);
  const theme = useTheme();

  const restartAppliance = async () => {
    if (appliance?.agentId) {
      let selectedAgentIds = [appliance.agentId]
        .map((element: string) => `'${element}'`)
        .join(", ")
        .replace(/"/g, "");
      const body = {
        criteria: `agentId in (${selectedAgentIds})`,
        version: "@latest",
      };
      await restartMutation.mutateAsync(body, {
        onSuccess: response => {
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            "ApplianceRestartRequestSubmittedSuccessfully"
          );
          setShowConfirmation(false);
          requestAPIRefresh();
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  };

  const renderActions = () => {
    const tooltipMessage = Boolean(disabledActionsTooltip)
      ? disabledActionsTooltip
      : window.getCTTranslatedText("restartAppliance");
    return (
      <>
        <Tooltip title={tooltipMessage}>
          <Stack>
            <IconButton
              onClick={() => setShowConfirmation(true)}
              disabled={Boolean(disabledActionsTooltip)}
            >
              <ServiceRestartIcon
                color={
                  Boolean(disabledActionsTooltip)
                    ? theme.palette.text.disabled
                    : theme.palette.primary.main
                }
              />
            </IconButton>
          </Stack>
        </Tooltip>
        <CTConfirmation
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          title={window.getCTTranslatedText("restartAppliance")}
          primaryText={window.getCTTranslatedText(
            "applianceServiceRestartConfirmation",
            { service: "appliance" }
          )}
          secondaryText={window.getCTTranslatedText("applianceRestartNote")}
          primaryButtonText={window.getCTTranslatedText("restartButton")}
          isLoading={restartMutation.isLoading}
          onSuccess={restartAppliance}
        />
      </>
    );
  };

  return (
    <CTWidget
      title={window.getCTTranslatedText("agent&firewall")}
      isLoading={isLoading}
      actions={canUpdate ? renderActions() : null}
      children={<StatusWidgetContent appliance={appliance} />}
    />
  );
};
