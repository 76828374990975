import { debounce } from "@mui/material";
import { useNetworksSuggestions } from "modules/assign-networks-to-assets/components/AssignNetworkDialog/helpers";
import { SortOrder } from "modules/core/types";
import { useNetworkStore } from "pages/networks/store";

import { useCallback, useEffect, useMemo, useState } from "react";

const defaultSortOrder: Array<SortOrder> = [
  { field: "isOOBNetwork", order: "asc" },
  { field: "namedNetworkName", order: "asc" },
];

export function useAssetNetworks({
  criteria,
  enable = true,
}: {
  criteria: string;
  enable?: boolean;
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState<Array<SortOrder>>(defaultSortOrder || []);
  const [searchCriteria, setSearchCriteria] = useState<string | undefined>();

  const [showAssignedNetworksDrawer, setShowAssignedNetworksDrawer] =
    useState(false);
  const [showAssignNetworksDrawer, setShowAssignNetworksDrawer] =
    useState(false);
  const networksSuggestMutation = useNetworksSuggestions();
  const apiRefreshRequest = useNetworkStore(state => state.apiRefreshRequest);

  const networkList = networksSuggestMutation.data?.items || [];
  const total = networksSuggestMutation.data?.metadata?.total ?? 0;
  const isLoading = networksSuggestMutation.isLoading;

  const openAssignedNetworksDrawer = () => {
    setShowAssignedNetworksDrawer(true);
  };

  const closeAssignedNetworksDrawer = () => {
    setShowAssignedNetworksDrawer(false);
  };

  const openAssignNetworksDrawer = () => {
    setShowAssignNetworksDrawer(true);
  };

  const closeAssignNetworksDrawer = () => {
    setShowAssignNetworksDrawer(false);
  };

  const onSortChange = useCallback((sort: Array<SortOrder>) => {
    setSort(sort);
  }, []);

  const onSearchCriteriaChange = (str: string) => {
    if (!str) {
      setSearchCriteria(criteria);
      return;
    }

    setSearchCriteria(`${criteria} AND '${str}'`);
  };

  const networkListMutation = useMemo(
    () => debounce(networksSuggestMutation.mutate, 300),
    [networksSuggestMutation.mutate]
  );

  useEffect(() => {
    if (criteria && enable) {
      const body = {
        criteria: searchCriteria ?? criteria,
        pagination: {
          offset: page * pageSize,
          limit: pageSize,
          sort: sort,
        },
      };

      networkListMutation(body);
    }
  }, [
    networkListMutation,
    criteria,
    apiRefreshRequest,
    sort,
    page,
    pageSize,
    enable,
    searchCriteria,
  ]);

  return {
    showAssignedNetworksDrawer,
    openAssignedNetworksDrawer,
    closeAssignedNetworksDrawer,
    showAssignNetworksDrawer,
    openAssignNetworksDrawer,
    closeAssignNetworksDrawer,
    networkList,
    total,
    isLoading,
    networkListMutation,
    onSortChange,
    sort,
    setPage,
    setPageSize,
    page,
    pageSize,
    totalCount: total,
    onSearchCriteriaChange,
  };
}
