import { DataGrid } from "modules/data-grid/components/data-grid";
import { Box, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useMemo, useState } from "react";
import { HOST_GROUPS_COLUMNS, HostGroupsColumnConfig } from "./constants";
import { HostGroup } from "pages/host-groups/types";
import { HostGroupToolbar } from "../host-group-toolbar";

export const HostGroupsDataGrid = (props: DataGridProps<HostGroup>) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const [selection, setSelection] = useState<Array<GridRowId>>([]);

  const selectedData: Array<HostGroup> | undefined = useMemo(() => {
    return props.rows?.filter((row: HostGroup) => {
      return selection.indexOf(row.id) !== -1;
    });
  }, [selection, props.rows]);

  const onClickClearSelection = () => {
    setSelection([]);
  };

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <HostGroupToolbar
        hideToolbar={onClickClearSelection}
        show={selection.length > 0}
        selectedData={selectedData}
      />
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [
                { field: "hostgroupstatus", sort: "desc" },
                { field: "hostgroupname", sort: "desc" },
              ],
            },
          }}
          defaultPinnedColumns={HostGroupsColumnConfig.PinnedColumns}
          checkboxSelection={userPermissions.has("UPDATE_CROWDSTRIKE")}
          rowSelectionModel={selection}
          onRowSelectionModelChange={selectionModel => {
            setSelection(selectionModel);
          }}
          rowHeight={64}
          columns={HOST_GROUPS_COLUMNS}
          pagination
          getRowId={({ id }: HostGroup) => id!}
          paginationMode="server"
          sortingMode="server"
          {...props}
        />
      </Box>
    </Stack>
  );
};
