import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
  UnmanagedDeviceName,
  UnmanagedDeviceMAC,
  UnmanagedDeviceVendor,
  UnmanagedDeviceAvailability,
  UnmanagedDeviceIP,
} from "./components";

export const DEVICE_COLUMNS = (viewOnly: boolean): GridColDef[] => [
  {
    field: "deviceName",
    headerName: "Device Name",
    width: 180,
    minWidth: 180,
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <UnmanagedDeviceName device={params.row} viewOnly={viewOnly} />;
    },
  },
  {
    field: "deviceIp",
    headerName: "IP Address",
    width: 180,
    minWidth: 180,
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <UnmanagedDeviceIP device={params.row} viewOnly={viewOnly} />;
    },
  },
  {
    field: "deviceAvailability",
    headerName: "Status",
    headerAlign: "left",
    align: "left",
    width: 180,
    minWidth: 180,
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    sortingOrder: ["asc", "desc"],
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <UnmanagedDeviceAvailability device={params.row} viewOnly={viewOnly} />
      );
    },
  },
  {
    field: "deviceVendor",
    headerName: "Vendor",
    width: 180,
    minWidth: 180,
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <UnmanagedDeviceVendor device={params.row} viewOnly={viewOnly} />;
    },
  },
  {
    field: "deviceMac",
    headerName: "MAC Address",
    width: 180,
    minWidth: 180,
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <UnmanagedDeviceMAC device={params.row} viewOnly={viewOnly} />;
    },
  },
];
