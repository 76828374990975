import { Stack, Tooltip } from "@mui/material";
import { AlertRuleConfig } from "../../types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export interface NotificationConfigStatusProps {
  readonly alertRule: AlertRuleConfig;
  readonly viewOnly?: boolean;
}

export function NotificationConfigStatus({
  alertRule,
  viewOnly,
}: NotificationConfigStatusProps) {
  const notificationFacetName = "notificationstatus";
  const notificationOptionName = alertRule?.notificationStatus;
  const configurationFacetName = "recipientConfigurationStatus";
  const configurationOptionName =
    alertRule?.recipientConfigurationStatus?.toString();
  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "space-evenly",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      {alertRule?.notificationStatus && (
        <Stack direction="row">
          <Tooltip
            title={`${window.getCTTranslatedText(
              alertRule?.notificationStatus
            )}`}
          >
            <CTDatagridButton
              asText={viewOnly}
              isDisabled={viewOnly}
              facetName={notificationFacetName}
              facetOption={notificationOptionName}
            >
              {window.getCTTranslatedText(alertRule?.notificationStatus)}
            </CTDatagridButton>
          </Tooltip>{" "}
          |
          <Tooltip
            title={`${window.getCTTranslatedText(
              alertRule?.recipientConfigurationStatus ?? ""
            )}`}
          >
            <CTDatagridButton
              asText={viewOnly}
              aria-disabled
              isDisabled={viewOnly}
              facetName={configurationFacetName}
              facetOption={configurationOptionName}
            >
              {window.getCTTranslatedText(
                alertRule?.recipientConfigurationStatus ?? ""
              )}
            </CTDatagridButton>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
}
