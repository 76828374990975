import { Paper, Stack, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useCore } from "modules/core";
import { Scope } from "modules/scope-metadata/types";
import { TagRulesDataGrid } from "./components/TagRulesDataGrid";
import { useTagRuleFacetStore, useTagRuleStore } from "./store";
import { TagRule } from "./types";
import { useScopeMetadata } from "modules/scope-metadata";
import { useEffect, useMemo } from "react";
import { useAssetsFacetsOrder } from "pages/assets/constants";
import { usePortsFacetsOrder } from "pages/ports/constants";
import { usePathsFacetsOrder } from "pages/paths/constants";
import { FacetGroups } from "modules/facets/types";
import { FacetGroupInfo } from "modules/core/types";
// import { FacetControllerGroup } from "modules/facets";
// import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";

export function useTagRulesListAPI() {
  return useMutation<any, Error, any>([
    "tag-rules",
    "tagrules/actions/search?computeTotal=true",
  ]);
}

export function TagRulesList() {
  const theme = useTheme();

  const { data: metaData } = useScopeMetadata({ scope: Scope.TagRule });
  const updateMetadata = useTagRuleFacetStore(state => state.updateMetadata);
  useEffect(() => {
    updateMetadata(metaData);
  }, [metaData, updateMetadata]);

  const assetsFacetsOrder = useAssetsFacetsOrder();
  const portsFacetsOrder = usePortsFacetsOrder();
  const pathsFacetsOrder = usePathsFacetsOrder();

  const FACET_GROUP_INFO: FacetGroupInfo = useMemo(() => {
    return {
      ...assetsFacetsOrder,
      ...portsFacetsOrder,
      ...pathsFacetsOrder,
      listenportadress: FacetGroups.Ports,
      listenprocessnames: FacetGroups.Ports,
      listenlocalip: FacetGroups.Ports,
    };
  }, [assetsFacetsOrder, portsFacetsOrder, pathsFacetsOrder]);

  const coreResponse = useCore<TagRule>({
    useStore: useTagRuleStore,
    facetGroupInfo: FACET_GROUP_INFO,
    scope: Scope.TagRuleList,
    useApi: useTagRulesListAPI,
    pageSize: 100,
    keepTags: false,
    skipUseFacetQueryConnector: true,
  });

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      {/* <Stack direction="row">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            useFacetStore={useTagRuleFacetStore}
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              useFacetStore={useTagRuleFacetStore}
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack> */}

      <Paper
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          flex: 1,
          height: "100%",
        }}
      >
        <TagRulesDataGrid
          rowCount={coreResponse.rowCount}
          rows={coreResponse.rows}
          metadata={coreResponse.metadata}
          mutation={coreResponse.mutation}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={coreResponse.onSortChange}
          hasNoData={
            coreResponse.mutation.isSuccess && coreResponse.rowCount === 0
          }
          triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
          getExportStatus={coreResponse?.getExportStatus}
          getUrlToDownload={coreResponse?.getUrlToDownload}
          resetDownloadUrl={coreResponse?.resetDownloadUrl}
          rawData={coreResponse?.rawData}
        />
      </Paper>
    </Stack>
  );
}
