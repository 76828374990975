import { useMutation } from "@tanstack/react-query";
import { ApplianceCertificate } from "pages/appliances/types";

export function useApplianceActivate(activationCode: string) {
  const path = `gateways/activate/${activationCode}`;
  return useMutation<ApplianceCertificate, Error>([
    "activate-appliance",
    path,
    "put",
  ]);
}
