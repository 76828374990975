import { FacetGroupInfo } from "modules/core/types";
import { FacetGroups } from "modules/facets/types";
import { ScopeMetadata } from "modules/scope-metadata/types";
import { useMemo } from "react";

export const useCustomFacetGroup = (
  metadata: ScopeMetadata | undefined,
  FACET_ORDER: FacetGroupInfo
) => {
  const facetOrder = useMemo(() => {
    if (!metadata?.columns) {
      return FACET_ORDER;
    }

    const columnNames = Object.keys(metadata.columns);
    const facetGroups: { [key: string]: FacetGroups } = {};

    columnNames.forEach(name => {
      if (metadata.columns[name]?.userDefined) {
        facetGroups[name] = FacetGroups.Tags;
      }
    });

    return {
      ...facetGroups,
      ...FACET_ORDER,
    };
  }, [metadata?.columns, FACET_ORDER]);

  return facetOrder;
};
