import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { jsx } from "@emotion/react";
import PageLayout from "common/layouts/containers/PageLayout";

function IllustrationLayout({ illustration, children }) {
  return (
    <PageLayout background="white">
      <Grid container>
        <Grid item xs={5} md={4} xl={3} sx={{ mx: "auto" }}>
          <Box height="100vh">{children}</Box>
        </Grid>
        <Grid item xs={5} md={6}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            position="relative"
            px={13}
            sx={{ overflow: "hidden" }}
          >
            <Box
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                transform: "scaleX(-1)",
                bottom: 0,
                right: 0,
              }}
            >
              <illustration.image />
            </Box>
            <Box position="relative" sx={{ zIndex: 20 }}>
              {illustration.description && (
                <Box mb={1}>
                  <Typography
                    sx={{
                      width: "55%",
                      color: theme =>
                        theme.palette.mode === "dark"
                          ? "rgba(0, 0, 0, 0.87)"
                          : "#fff",
                    }}
                    variant="h4"
                    fontWeight="bold"
                  >
                    {illustration.description}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  illustration: {},
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  children: PropTypes.any,
  illustration: PropTypes.shape({
    image: jsx.Element,
    description: PropTypes.string,
  }),
};

export default IllustrationLayout;
