import { SecurityStatus } from "../../constants";
import { SliderMarksProps } from "./types";

export const SliderMarks: Array<SliderMarksProps> = [
  {
    key: SecurityStatus.Unsecure,
    value: 0,
  },
  {
    key: SecurityStatus.Internet,
    value: 50,
  },
  {
    key: SecurityStatus.All,
    value: 100,
  },
];
