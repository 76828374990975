import {
  FormControl,
  TextField,
  Autocomplete,
  debounce,
  InputAdornment,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import {
  AssetCoreTagEditProps,
  CoreTagsKeys,
  CoreTagsLabels,
} from "pages/assets/types";

import { useEffect, useMemo, useState } from "react";
import { getUniqueValues } from "common/utils/getUniqueListBy";
import { NO_VALUE } from "pages/asset/constants";
import isEqual from "lodash/isEqual";

function useCoreTagsSuggestions() {
  return useMutation<any, Error, any>(["suggestions", "suggestions"]);
}

export function AssetCoreTagEdit({
  asset,
  handleUpdate,
  coreTagKey,
  getTagIcon,
}: AssetCoreTagEditProps) {
  const coreTagsSuggestMutation = useCoreTagsSuggestions();
  const [selected, setSelected] = useState(asset?.coreTags[coreTagKey] ?? "");
  const [list, setList] = useState<Array<string>>([]);
  const [open, setOpen] = useState(false);
  const [cacheReq, setCatchReq] = useState<string>("initial");

  const mutate = useMemo(
    () => debounce(coreTagsSuggestMutation.mutate, 300),
    [coreTagsSuggestMutation.mutate]
  );

  useEffect(() => {
    if (coreTagKey) {
      const body = {
        key: `${coreTagKey}`,
        value: selected?.trim() ?? "",
        condition: "contains",
        offset: 0,
        limit: 100,
      };
      const reqBody = JSON.stringify(body);
      if (!isEqual(reqBody, cacheReq)) {
        setCatchReq(reqBody);
        mutate(body, {
          onSuccess: response => {
            let suggestions;
            if (response?.data) {
              if (response?.data[coreTagKey]?.data) {
                suggestions = [NO_VALUE].concat(
                  response?.data[coreTagKey]?.data
                );
              }
              if (!suggestions) {
                let checkResponseKeys = Object.keys(response?.data);
                checkResponseKeys.forEach(key => {
                  if (response?.data?.[key]?.publicName === coreTagKey) {
                    if (response?.data?.[key]?.data) {
                      suggestions = [NO_VALUE].concat(
                        response?.data?.[key]?.data
                      );
                    }
                  }
                });
              }
            }
            if (suggestions) {
              suggestions = getUniqueValues(suggestions ?? []);
            } else {
              suggestions = [NO_VALUE];
            }
            setList(suggestions);
          },
        });
      }
    } else {
      setList([]);
    }
  }, [mutate, coreTagKey, cacheReq, selected, list]);

  const onChange = async (event: any) => {
    if (event?.target?.value) {
      const coreTagValue = event.target.value;
      setSelected(coreTagValue);
      handleUpdate({ coreTagKey, coreTagValue });
    }
  };

  const handleTextFieldChange = (event: any, newInputValue: string) => {
    if (newInputValue || newInputValue === "") {
      const coreTagValue = newInputValue?.trim();
      setSelected(coreTagValue);
      handleUpdate({ coreTagKey, coreTagValue });
    }
  };

  const coreTagLabel = CoreTagsLabels[coreTagKey as CoreTagsKeys]
    ? CoreTagsLabels[coreTagKey as CoreTagsKeys]
    : coreTagKey;

  return (
    <FormControl
      sx={{ m: 0, width: "100%", textTransform: "capitalize" }}
      size="small"
    >
      <Autocomplete
        freeSolo
        id={`autocomplete-${coreTagKey}`}
        disableClearable
        openOnFocus
        autoHighlight
        options={list.map(option => option)}
        value={selected}
        onInputChange={handleTextFieldChange}
        onSelect={onChange}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        renderInput={params => (
          <TextField
            variant="standard"
            {...params}
            label={window.getCTTranslatedText(coreTagLabel)}
            placeholder={window.getCTTranslatedText("EnterCoreTag", {
              coreTagKey: window.getCTTranslatedText(coreTagLabel),
            })}
            InputLabelProps={{ shrink: true }}
            InputProps={
              getTagIcon && {
                ...params.InputProps,
                type: "search",
                autoComplete: "new-password",
                startAdornment: (
                  <InputAdornment position="start">
                    {getTagIcon(coreTagKey)}
                  </InputAdornment>
                ),
              }
            }
          />
        )}
      />
    </FormControl>
  );
}
