import { Stack, Tab } from "@mui/material";
import { CtTabs, TabPanel } from "common/atoms/ct-tabs";
import { SyntheticEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AgentConfig } from "./components/agent-config";
import { AlertConfig } from "./components/alert-config";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";

export enum SettingsTabs {
  AgentConfig = 0,
  AlertConfig = 1,
}

export enum SettingTabNames {
  AgentConfig = "agentconfig",
  AlertConfig = "alertconfig",
}

export const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabValue = searchParams.get("tab");
  const [selectedTab, setTab] = useState(SettingsTabs.AgentConfig);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  useEffect(() => {
    let tabName = userPermissions.has("UPDATE_TENANT_AGENT_SECRET") ? 0 : 1;
    switch (tabValue) {
      case SettingTabNames.AgentConfig:
        tabName = SettingsTabs.AgentConfig;
        break;
      case SettingTabNames.AlertConfig:
        tabName = SettingsTabs.AlertConfig;
        break;
    }

    setTab(tabName);
  }, [tabValue, userPermissions]);

  const handleTabChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: SettingsTabs
  ) => {
    setTab(newValue);
    let tabName = "";
    switch (newValue) {
      case SettingsTabs.AgentConfig:
        tabName = SettingTabNames.AgentConfig;
        break;
      case SettingsTabs.AlertConfig:
        tabName = SettingTabNames.AlertConfig;
        break;
    }
    navigate(`/settings?tab=${tabName}`);
  };

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
      overflow="hidden"
    >
      <Stack sx={{ width: "100%" }}>
        <Stack direction="row" sx={{ width: "100%" }}>
          <Stack flex={1}>
            <CtTabs value={selectedTab} onChange={handleTabChange}>
              <Tab
                label={window.getCTTranslatedText("Agent Config")}
                sx={{
                  display: userPermissions.has("UPDATE_TENANT_AGENT_SECRET")
                    ? undefined
                    : "none",
                }}
              />

              <Tab
                label={window.getCTTranslatedText("Alert Config")}
                sx={{
                  display: userPermissions.has("UPDATE_ALERTRULES")
                    ? undefined
                    : "none",
                }}
              />
            </CtTabs>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{ width: "100%", height: "100%" }}
        flex={1}
        overflow="hidden"
        alignItems="flex-start"
      >
        <TabPanel value={selectedTab} index={0}>
          <AgentConfig />
        </TabPanel>
        <TabPanel value={selectedTab} index={1} style={{ height: "100%" }}>
          <AlertConfig />
        </TabPanel>
      </Stack>
    </Stack>
  );
};
