import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import capitalize from "lodash/capitalize";
import { StatusOptionsText } from "pages/ports/constants";
import { usePortStore } from "pages/ports/store";
import { PortStoreType } from "pages/ports/store/types";
import { PortStatus } from "pages/ports/types";
import { useEffect, useRef, useState } from "react";

interface PortStatusDropdownProps {
  onSelect: (status: PortStatus) => void;
  useStore?: PortStoreType;
  preSelectedPortStatus?: PortStatus;
}

export const PortStatusDropdown = ({
  preSelectedPortStatus,
  onSelect,
  useStore = usePortStore,
}: PortStatusDropdownProps) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const apiRefreshPort = useStore(state => state.apiRefreshRequest);
  const [openMenu, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedOperation, setSelectedOperation] = useState<
    PortStatus | undefined
  >(undefined);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const StatusOptionsList = [
    PortStatus.AllowIntranet,
    PortStatus.PathRestricted,
    PortStatus.AllowAny,
    PortStatus.Deny,
  ];

  useEffect(() => {
    setSelectedOperation(undefined);
    setMenuOpen(false);
  }, [apiRefreshPort]);

  useEffect(() => {
    if (preSelectedPortStatus && initialLoad) {
      setSelectedOperation(preSelectedPortStatus);
      setInitialLoad(false);
    }
  }, [
    setSelectedOperation,
    preSelectedPortStatus,
    initialLoad,
    selectedOperation,
  ]);

  const handleMenuToggle = () => {
    setMenuOpen(prevOpen => !prevOpen);
  };

  const handleMenuClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setMenuOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    status: PortStatus
  ) => {
    onSelect(status);
    setSelectedOperation(status);
    setMenuOpen(false);
  };

  return (
    <Stack direction="row">
      {userPermissions.has("UPDATE_PORT") && (
        <ButtonGroup
          variant="outlined"
          ref={anchorRef}
          aria-label="split button"
          sx={{
            minWidth: "260px",
            borderBottom: "1px solid divider",
            borderRadius: "0",
          }}
        >
          <Button
            aria-controls={openMenu ? "split-button-menu" : undefined}
            aria-expanded={openMenu ? "true" : undefined}
            aria-label="select port status"
            aria-haspopup="menu"
            onClick={handleMenuToggle}
            endIcon={<ArrowDropDownIcon />}
            sx={{ width: "260px" }}
          >
            {window.getCTTranslatedText(
              selectedOperation
                ? getDisplayOption(selectedOperation)
                : "Select Port status"
            )}
          </Button>
        </ButtonGroup>
      )}
      <Popper
        sx={{
          zIndex: 1,
          minWidth: "260px",
        }}
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {StatusOptionsList.map((option, index) => (
                    <Stack key={option} sx={{ mx: 2 }}>
                      <MenuItem
                        onClick={event => handleMenuItemClick(event, option)}
                      >
                        <Typography variant="body2">
                          {capitalize(
                            window.getCTTranslatedText(getDisplayOption(option))
                          )}
                        </Typography>
                      </MenuItem>
                      {index < StatusOptionsList.length - 1 && <Divider />}
                    </Stack>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};

const getDisplayOption = (option: PortStatus) => {
  switch (option) {
    case PortStatus.Deny:
      return "deny";
    default:
      return StatusOptionsText[option];
  }
};
