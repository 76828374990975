import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { Toolbar } from "common/atoms/toolbar";
import { getWRAdditionalCriteria } from "modules/AppBar/helpers";
import { usePollPendingWorkRequest } from "modules/AppBar/hooks";
import { WorkRequest } from "modules/AppBar/types";
import { getOptimisticDataGridData } from "modules/PendingWorkRequestsDrawer/helpers";
import { useHandleWRFacetApplication } from "modules/PendingWorkRequestsDrawer/hooks/useHandleWRFacetApplication";
import { PendingWorkRequestData } from "modules/PendingWorkRequestsDrawer/types";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { Scope } from "modules/scope-metadata/types";
import { useEffect, useRef, useState } from "react";
import { PendingWorkRequestDataGrid } from "./components/PendingWorkRequestDataGrid";
import { FACET_ORDER } from "./components/PendingWorkRequestDataGrid/constants";
import { usePendingWorkRequestAPI } from "./hooks";
import { useWorkRequestStore } from "./store";
import { useWorkRequestFacetStore } from "./store/useWorkRequestStore";

const readOnlyDimensions = ["status", "workrequestresourceid"];
const DATA_GRID_UPDATE_DELAY = 5000;

interface PendingWorkRequestsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  resourceID?: string;
}

export const PendingWorkRequestsDrawer = ({
  isOpen,
  onClose,
  resourceID,
}: PendingWorkRequestsDrawerProps) => {
  usePollPendingWorkRequest(resourceID);

  const [tableData, setTableData] = useState<PendingWorkRequestData>({
    rows: [],
    rowCount: 0,
    page: 0,
    pageSize: 100,
  });
  const requestAPIRefresh = useWorkRequestStore(
    state => state.requestAPIRefresh
  );
  const setExternalCriteria = useWorkRequestFacetStore(
    state => state.setExternalCriteria
  );
  const externalCriteria = useWorkRequestFacetStore(
    state => state.externalCriteria
  );
  const additionalCriteria = useRef(externalCriteria);

  useEffect(() => {
    additionalCriteria.current = externalCriteria;
  }, [externalCriteria]);

  const shouldReplaceTableDataWithoutDelay = useHandleWRFacetApplication({
    resourceID,
  });

  const coreResponse = useCore<WorkRequest>({
    useStore: useWorkRequestStore,
    facetGroupInfo: FACET_ORDER,
    scope: Scope.WorkRequest,
    useApi: usePendingWorkRequestAPI,
    pageSize: 100,
    useFacetStore: useWorkRequestFacetStore,
    additionalCriteria: additionalCriteria.current,
    skipUseFacetQueryConnector: true,
    skipSearch: true,
    defaultSortOrder: [{ field: "created", order: "desc" }],
  });

  useEffect(() => {
    const tableData = {
      rows: coreResponse.rows ?? [],
      rowCount: coreResponse.rowCount,
      page: coreResponse.page,
      pageSize: coreResponse.pageSize,
    };
    setTableData(prev =>
      shouldReplaceTableDataWithoutDelay
        ? getOptimisticDataGridData(prev, tableData)
        : tableData
    );

    if (!shouldReplaceTableDataWithoutDelay) {
      return;
    }

    const timerID = setTimeout(() => {
      setTableData(tableData);
    }, DATA_GRID_UPDATE_DELAY);

    return () => {
      if (timerID) {
        clearTimeout(timerID);
      }
    };
  }, [
    coreResponse.rows,
    coreResponse.rowCount,
    coreResponse.page,
    coreResponse.pageSize,
    shouldReplaceTableDataWithoutDelay,
  ]);

  const onRefresh = () => {
    additionalCriteria.current = getWRAdditionalCriteria();
    setExternalCriteria(additionalCriteria.current);
    requestAPIRefresh();
  };

  return (
    <Box onClick={e => e.stopPropagation()}>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            padding: 3,
            width: "70%",
            overflow: "hidden",
          },
          elevation: 1,
        }}
      >
        <Toolbar />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Stack direction={"row"} alignItems="center">
            <Typography variant="h6">
              {window.getCTTranslatedText("Background Processes")}
            </Typography>
          </Stack>
          <IconButton size="small" aria-label="close drawer" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>

        <Stack sx={{ mt: 2, flex: 1, overflow: "hidden" }}>
          <Paper
            elevation={4}
            sx={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{ width: "100%", height: "100%", p: 1, flex: 1 }}
              justifyItems="flex-end"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <FacetOpenCloseController
                facetsOpen={coreResponse.facetsOpen}
                setFacetsOpen={coreResponse.setFacetsOpen}
                disableSavedQuery={true}
                useFacetStore={useWorkRequestFacetStore}
                readOnlyDimensions={readOnlyDimensions}
                disableSearchChip
              >
                <FacetControllerGroup
                  config={coreResponse.facetConfig}
                  value={coreResponse.facetState}
                  onChange={coreResponse.updateFacet}
                  useFacetStore={useWorkRequestFacetStore}
                />
              </FacetOpenCloseController>
              <Stack direction="row" alignItems={"center"}>
                <Stack direction="row">
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={onRefresh}
                    sx={{ marginLeft: 2 }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
          <PendingWorkRequestDataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "createdAt", sort: "desc" }],
              },
            }}
            resourceID={resourceID}
            rowCount={tableData.rowCount}
            rows={tableData.rows}
            page={tableData.page}
            pageSize={tableData.pageSize}
            mutation={coreResponse.mutation}
            onPageChange={coreResponse.onPageChange}
            onPageSizeChange={coreResponse.onPageSizeChange}
          />
        </Stack>
      </Drawer>
    </Box>
  );
};
