import { usePrevious } from "common/hooks/usePrevious";
import isEqual from "lodash/isEqual";
import { WorkRequestStatus } from "modules/AppBar/types";
import { useWorkRequestFacetStore } from "modules/PendingWorkRequestsDrawer/store/useWorkRequestStore";
import { FacetOptionState, Operator } from "modules/facets/types";
import { useEffect } from "react";

interface UseHandleWRFacetApplicationOptions {
  resourceID?: string;
}

export const useHandleWRFacetApplication = ({
  resourceID,
}: UseHandleWRFacetApplicationOptions) => {
  const updateFacetOption = useWorkRequestFacetStore(
    state => state.updateFacetOption
  );
  const facets = useWorkRequestFacetStore(state => state.facets);
  const setFacets = useWorkRequestFacetStore(state => state.setFacets);

  const prevFacets = usePrevious(facets);

  useEffect(() => {
    const facetOption: FacetOptionState = new Map([
      [
        WorkRequestStatus.Pending,
        { isSelected: true, operator: Operator.EQUAL },
      ],
      [
        WorkRequestStatus.InProgress,
        { isSelected: true, operator: Operator.EQUAL },
      ],
      [
        WorkRequestStatus.Cancelled,
        { isSelected: true, operator: Operator.EQUAL },
      ],
    ]);
    const facets = new Map([["status", facetOption]]);

    setFacets(facets);

    return () => {
      setFacets(undefined);
    };
  }, [setFacets]);

  useEffect(() => {
    if (resourceID) {
      updateFacetOption({
        facetName: "workrequestresourceid",
        optionName: resourceID,
        value: true,
      });

      return () => {
        updateFacetOption({
          facetName: "workrequestresourceid",
          optionName: resourceID,
          value: false,
        });
      };
    }
  }, [resourceID, updateFacetOption]);

  return isEqual(facets, prevFacets);
};
