import { FormControlLabel, Stack, Switch } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { PORT_STATUS_COLUMNS } from "modules/add-to-templates/components/AddToTemplateDialog/constants";
import { Rule } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { PORTLESS_PROTOCOLS, Path, PathDirection } from "pages/paths/types";
import { PortStatus } from "pages/ports/types";
import { useCallback, useEffect, useState } from "react";

export function getRowId({ channelHash, direction }: Path) {
  return `${channelHash}-${direction}`;
}

interface PathWithPreviewStatus extends Path {
  previewStatus: "No Change" | PortStatus.PathRestricted;
}

interface RestrictPortToAllowedPathsTableProps {
  rules: Rule[] | undefined;
  shouldShowPortStatusSuggestions: boolean;
  setShouldShowPortStatusSuggestions: (value: boolean) => void;
  portSelection: GridRowId[];
  setPortSelection: (value: GridRowId[]) => void;
}

export const RestrictPortToAllowedPathsTable = ({
  rules,
  shouldShowPortStatusSuggestions,
  setShouldShowPortStatusSuggestions,
  portSelection: selection,
  setPortSelection: setSelection,
}: RestrictPortToAllowedPathsTableProps) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const [rows, setRows] = useState<PathWithPreviewStatus[]>([]);

  const columns = PORT_STATUS_COLUMNS;

  const filterUniqueRows = useCallback(
    (rules: Rule[] | undefined): PathWithPreviewStatus[] => {
      const uniqueSet = new Set();
      const filteredRules =
        rules
          ?.filter((r: Rule | undefined): r is Path => Boolean(r))
          ?.filter((r: Path) => {
            if (
              PORTLESS_PROTOCOLS.includes(r.protocol.toUpperCase()) ||
              r.direction === PathDirection.Outbound
            ) {
              return false;
            }

            const portProtocol = `${r.port}_${r.protocol}`;
            if (uniqueSet.has(portProtocol)) {
              return false;
            }

            uniqueSet.add(portProtocol);
            return true;
          }) ?? [];
      return filteredRules.map((rule: Path) => {
        return {
          ...rule,
          previewStatus: PortStatus.PathRestricted,
        };
      });
    },
    []
  );

  useEffect(() => {
    const rows = filterUniqueRows(rules);
    setSelection(rows.map(getRowId));
    setRows(rows);
  }, [filterUniqueRows, rules, setSelection]);

  const updateSelections = (selectedPortIds: GridRowId[]) => {
    setSelection(selectedPortIds);
    setRows(prev => {
      return prev.map(row => {
        if (selectedPortIds.indexOf(getRowId(row)) !== -1) {
          return { ...row, previewStatus: PortStatus.PathRestricted };
        } else {
          return { ...row, previewStatus: "No Change" };
        }
      });
    });
  };

  if (rows.length === 0) {
    return null;
  }

  return (
    <Stack>
      <FormControlLabel
        sx={{
          p: 2,
        }}
        control={
          <Switch
            checked={shouldShowPortStatusSuggestions}
            onChange={event =>
              setShouldShowPortStatusSuggestions(event.target.checked)
            }
          />
        }
        label={window.getCTTranslatedText("restrictPortsToAllowedPaths")}
      />
      {shouldShowPortStatusSuggestions && (
        <DataGrid
          columns={columns}
          checkboxSelection={userPermissions.has("UPDATE_PORT")}
          rowSelectionModel={selection}
          onRowSelectionModelChange={updateSelections}
          paginationMode="client"
          sortingMode="client"
          pagination
          rows={rows}
          rowCount={rows?.length ?? 0}
          getRowId={getRowId}
        />
      )}
    </Stack>
  );
};
