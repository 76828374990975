import { useTheme } from "@mui/material";

export const CTIconSmall = () => {
  const theme = useTheme();
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9521 24.5715C18.8923 27.9788 21.6133 30.7983 25.022 30.8581C26.9655 30.8581 28.7895 29.9016 29.9058 28.3076L40.0322 29.1943C40.8395 26.2752 40.8794 23.1867 40.1319 20.2476L29.9058 20.8454C28.7895 19.2514 26.9655 18.3049 25.022 18.2949C21.6133 18.3547 18.8923 21.1642 18.9521 24.5815"
        fill="#E20000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66699 21.7517H14.1687C15.2153 16.9097 19.4911 13.4526 24.4446 13.4327C26.3483 13.4327 28.2221 13.9508 29.8567 14.9371L37.2422 14.4589C32.0195 7.39522 22.0526 5.89084 14.986 11.1114C11.5275 13.6618 9.25504 17.4975 8.66699 21.7517ZM8.82646 27.1814C10.5408 35.7993 18.913 41.3984 27.5344 39.6947C31.4215 38.9276 34.89 36.7258 37.2422 33.5377L29.8866 33.0396C24.923 36.0483 18.4645 34.4643 15.4545 29.5127C15.0159 28.7854 14.6571 27.9984 14.4079 27.1814H8.82646Z"
        fill="#E20000"
      />
      <rect
        x="1.16663"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        stroke={theme.palette.text.primary}
        strokeOpacity="0.12"
      />
    </svg>
  );
};
