import uniqBy from "lodash/uniqBy";
import { FacetStoreBuilder } from "modules/facets";
import { FacetStore, Operator } from "modules/facets/types";
import { Dimension } from "modules/hierarchy-vis/types";
import { create } from "zustand";
import {
  Dimensions,
  ExpansionNode,
  Expansions,
  GroupNodeDataType,
  PathReviewStatus,
  PathTypes,
  PositionStore,
  StatisticType,
  TrafficData,
  TrafficDatum,
} from "./types";

import debounce from "lodash/debounce";
import merge from "lodash/merge";
import { Scope } from "modules/scope-metadata/types";
import {
  Direction,
  SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { persist } from "zustand/middleware";
import { computeDataFromStats } from "./hooks/useTrafficData";

import { getStorageKey, stateStorage } from "common/store/helper";
import { PathStatus } from "pages/paths/types";
import { PathStatuses } from "./components/source-node/PathsLegendsGroup";
import { OTHERS, getChildrenFromNode } from "./visx-utils";

const defaultEnabledStatus = new Map<PathTypes, boolean>();
Object.values(PathStatuses).forEach(status => {
  defaultEnabledStatus.set(status as PathTypes, true);
});

export interface TrafficVisualizerStore {
  enabledStatus: Map<PathTypes, boolean>;
  setStatusEnabledMap: (
    fn: (oldState: Map<PathTypes, boolean>) => Map<PathTypes, boolean>
  ) => void;

  positions?: PositionStore | undefined;
  setPositions: (positions: PositionStore | undefined) => void;
  sourceCriteria: string | undefined;
  destinationCriteria: string | undefined;
  trafficCriteria: string | undefined;
  selectedPathStatus: PathReviewStatus;

  setSourceCriteria: (c: string | undefined) => void;
  setDestinationCriteria: (c: string | undefined) => void;
  setTrafficCriteria: (c: string | undefined) => void;
  setSelectedPathStatus: (selectedPathStatus: PathReviewStatus) => void;

  isLoading: boolean;
  trafficData: TrafficData | undefined;

  setIsLoading: (isLoading: boolean) => void;
  setTrafficData: (data: TrafficData | undefined) => void;
  addTrafficData: (
    data: TrafficData,
    parentId?: string,
    removeParent?: boolean,
    setChildren?: boolean
  ) => void;

  selectedSourceNodeStatus: SecurityStatus;
  isDrawerOpen: boolean;
  isToolbarHidden: boolean;
  hoveredId: string | undefined;
  selectedSourceNodeCount: number;
  selectedDirection: Direction;
  selectedSecurityStatus: SecurityStatus;
  selectedDimension?: Dimension;
  selectedNode?: GroupNodeDataType;
  highlightedChildren?: Set<string>;
  isTestMode: boolean;

  setSelectedSourceNodeStatus: (
    selectedSourceNodeStatus: SecurityStatus
  ) => void;

  setTestMode: (isTestMode: boolean) => void;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  setIsToolbarHidden: (isToolbarHidden: boolean) => void;
  setSelectedNode: (node: GroupNodeDataType | undefined) => void;
  setHoveredId: (hoveredId: string | undefined) => void;
  setSelectedSourceNodeCount: (selectedSourceNodeCount: number) => void;
  setSelectedDirection: (selectedDirection: Direction) => void;
  setSelectedSecurityStatus: (selectedSecurityStatus: SecurityStatus) => void;
  setSelectedDimension: (selectedDimension: Dimension) => void;

  parentIds: Map<string, string>;
  childNodeIds: Map<string, Map<string, boolean>>;
  changeParentChildRelationship: (
    nodeId: string[],
    parentId: string,
    breakRelation?: boolean
  ) => void;
  expansions: Expansions | undefined;
  addExpansion: (expansions: ExpansionNode) => void;
  setExpansions: (expansions: Array<ExpansionNode> | undefined) => void;
  resetExpansions: () => void;
  resetHierarchy: () => void;

  isPublicNodeExpanded: boolean;
  isPrivateNodeExpanded: boolean;
  isOthersNodeExpanded: boolean;
  setIsPublicNodeExpanded: (isPublicNodeExpanded: boolean) => void;
  setIsPrivateNodeExpanded: (isPrivateNodeExpanded: boolean) => void;
  setIsOthersNodeExpanded: (isOthersNodeExpanded: boolean) => void;
}

export const useVisxStore = create<TrafficVisualizerStore>()(
  persist(
    set => {
      return {
        enabledStatus: defaultEnabledStatus,
        setStatusEnabledMap: reducer => {
          set(state => {
            return {
              ...state,
              enabledStatus: reducer(state.enabledStatus),
            };
          });
        },
        parentIds: new Map<string, string>(),
        childNodeIds: new Map<string, Map<string, boolean>>(),

        changeParentChildRelationship: (
          nodeIds: string[],
          parentId: string,
          breakRelation = false
        ) => {
          set(state => {
            state.parentIds = new Map(state.parentIds);
            state.childNodeIds = new Map(state.childNodeIds);

            nodeIds.forEach(nodeId => {
              let childMap = state.childNodeIds.get(parentId) || new Map();
              if (breakRelation) {
                state.parentIds.delete(nodeId);
                childMap.delete(nodeId);
              } else {
                state.parentIds.set(nodeId, parentId);
                childMap.set(nodeId, true);
              }
              state.childNodeIds.set(parentId, childMap);
            });
            return state;
          });
        },
        sourceCriteria: undefined,
        destinationCriteria: undefined,
        trafficCriteria: undefined,
        selectedPathStatus: PathReviewStatus.Enforced,

        setSourceCriteria: (c: string | undefined) =>
          set({ sourceCriteria: c, trafficData: undefined }),
        setDestinationCriteria: (c: string | undefined) =>
          set({ destinationCriteria: c, trafficData: undefined }),
        setTrafficCriteria: (c: string | undefined) =>
          set({ trafficCriteria: c, trafficData: undefined }),
        setSelectedPathStatus: (selectedPathStatus: PathReviewStatus) =>
          set({ selectedPathStatus }),

        isLoading: true,
        trafficData: undefined,
        compareWithTrafficData: undefined,

        expansions: undefined,
        addExpansion: (expansionNode: ExpansionNode) =>
          set(state => {
            let newState: Partial<TrafficVisualizerStore> = state;
            if (state.expansions) {
              newState = { expansions: [...state.expansions, expansionNode] };
            } else {
              newState = { expansions: [expansionNode] };
            }
            newState.expansions = uniqBy(
              newState.expansions,
              i => i.dimension.name + i.parent.name
            );

            if (state.positions) {
              newState.positions = undefined;
            }
            return newState;
          }),
        setExpansions: (expansions: Array<ExpansionNode> | undefined) =>
          set({ expansions }),

        setIsLoading: (isLoading: boolean) => set({ isLoading }),
        setTrafficData: (trafficData: TrafficData | undefined) => {
          set({ trafficData, isOthersNodeExpanded: false });
        },

        selectedSourceNodeStatus: SecurityStatus.None,
        isDrawerOpen: false,
        isToolbarHidden: false,
        hoveredId: undefined,
        selectedSourceNodeCount: 0,
        selectedDirection: Direction.Inbound,
        selectedSecurityStatus: SecurityStatus.None,
        selectedDimension: Dimensions[0],

        setSelectedSourceNodeStatus: (
          selectedSourceNodeStatus: SecurityStatus
        ) => set({ selectedSourceNodeStatus }),
        setIsDrawerOpen: (isDrawerOpen: boolean) => set({ isDrawerOpen }),
        setIsToolbarHidden: (isToolbarHidden: boolean) =>
          set({ isToolbarHidden }),
        setSelectedNode: (node: GroupNodeDataType | undefined) =>
          set(state => {
            return {
              ...state,
              selectedNode: node,
              hoveredId: node?.label,
              highlightedChildren: getChildrenFromNode(node, state.trafficData),
            };
          }),
        setSelectedSourceNodeCount: (selectedSourceNodeCount: number) =>
          set({ selectedSourceNodeCount }),
        setSelectedDirection: (selectedDirection: Direction) =>
          set(state => {
            let newExpansions = state.expansions?.filter(
              e => !e.dimension.name.includes("subnet")
            );
            return {
              selectedDirection,
              positions: undefined,
              expansions: newExpansions,
            };
          }),
        setSelectedSecurityStatus: (selectedSecurityStatus: SecurityStatus) =>
          set({ selectedSecurityStatus }),
        setSelectedDimension: (selectedDimension: Dimension) =>
          set({ selectedDimension }),
        setHoveredId: debounce((hoveredId: string | undefined) => {
          set({ hoveredId });
        }, 100),
        addTrafficData: (
          newTrafficData: TrafficData,
          parentId?: string,
          removeParent?: boolean,
          setChildren?: boolean
        ) => {
          set(state => {
            // console.debug("Existing data", state.trafficData);
            // console.debug("New data", newTrafficData);
            let newDimensionKeys = Object.keys(newTrafficData);
            if (
              setChildren &&
              parentId &&
              newDimensionKeys?.length &&
              state.trafficData?.[parentId]
            ) {
              state.trafficData[parentId].children = [
                ...(state.trafficData[parentId].children || []),
                newTrafficData,
              ];
            }

            state.trafficData = merge({}, state.trafficData, newTrafficData);
            for (let key of newDimensionKeys) {
              if (parentId) {
                state.trafficData[key].parent = state.trafficData?.[parentId];
              }
              state.trafficData[key] = {
                ...state.trafficData[key],
                ...computeNodeStats(state.trafficData[key]),
              };
            }
            return { trafficData: { ...state.trafficData } };
          });
        },

        resetExpansions: () => {
          set({
            expansions: undefined,
            positions: undefined,
          });
        },
        resetHierarchy: () => {
          set({
            parentIds: new Map(),
            childNodeIds: new Map(),
          });
        },

        setPositions: (positions: PositionStore | undefined) => {
          set({
            positions,
          });
        },

        isPublicNodeExpanded: false,
        isPrivateNodeExpanded: false,
        isOthersNodeExpanded: false,
        setIsPublicNodeExpanded: (isPublicNodeExpanded: boolean) =>
          set(state => ({
            isPublicNodeExpanded,
            positions: isPublicNodeExpanded ? state.positions : undefined,
          })),
        setIsPrivateNodeExpanded: (isPrivateNodeExpanded: boolean) =>
          set(state => ({
            isPrivateNodeExpanded,
            positions: isPrivateNodeExpanded ? state.positions : undefined,
          })),
        setIsOthersNodeExpanded: (isOthersNodeExpanded: boolean) =>
          set(state => {
            let newTrafficData = { ...state.trafficData };
            delete newTrafficData[OTHERS];
            return {
              isOthersNodeExpanded,
              positions: isOthersNodeExpanded ? state.positions : undefined,
              trafficData: newTrafficData,
            };
          }),
        isTestMode: true,
        setTestMode: (testMode: boolean) => {
          set(state => ({ isTestMode: testMode }));
        },
      };
    },
    {
      name: getStorageKey({ namespace: "visx" }),
      partialize: state => {
        let stateToStore = {
          enabledStatus: state.enabledStatus,
          // positions: state.positions,
          selectedPathStatus: state.selectedPathStatus,
          selectedSourceNodeStatus: state.selectedSourceNodeStatus,
          isDrawerOpen: state.isDrawerOpen,
          isToolbarHidden: state.isToolbarHidden,
          selectedDirection: state.selectedDirection,
          selectedSecurityStatus: state.selectedSecurityStatus,
          selectedDimension: state.selectedDimension,
          // selectedNode: state.selectedNode,
          parentIds: state.parentIds,
          childNodeIds: state.childNodeIds,
          expansions: state.expansions,
          isPublicNodeExpanded: state.isPublicNodeExpanded,
          isPrivateNodeExpanded: state.isPrivateNodeExpanded,
          isOthersNodeExpanded: state.isOthersNodeExpanded,
          isTestMode: state.isTestMode,
        };
        return stateToStore;
      },
      storage: stateStorage,
      onRehydrateStorage: () => {
        console.debug("hydration starts");

        // optional
        return (state, error) => {
          if (state?.enabledStatus.has("under-test" as PathStatus)) {
            state?.enabledStatus.delete("under-test" as PathStatus);
          }
          if (
            !state?.enabledStatus?.has(PathStatus.AllowedByProgressive) &&
            state?.enabledStatus?.has(PathStatus.Allow)
          ) {
            state?.enabledStatus?.set(
              PathStatus.AllowedByProgressive,
              state?.enabledStatus?.get(PathStatus.Allow) ?? false
            );
          }

          if (
            !state?.enabledStatus?.has(PathStatus.AllowedByTestUIOnly) &&
            state?.enabledStatus?.has(PathStatus.AllowTestDenied)
          ) {
            state?.enabledStatus?.set(
              PathStatus.AllowedByTestUIOnly,
              state?.enabledStatus?.get(PathStatus.AllowTestDenied) ?? false
            );
          }

          if (error) {
            console.debug("an error happened during hydration", error);
          } else {
            console.debug("hydration finished");
          }
        };
      },
    }
  )
);

const defaultFilters = new Map();

const defaultDirectionOption = new Map();
defaultDirectionOption.set("inbound", {
  isSelected: true,
  operator: Operator.EQUAL,
});
defaultFilters.set("direction", defaultDirectionOption);

export const DEfAULT_TRAFFIC_FILTERS = defaultFilters;

export const useSourceFacetStore = create<FacetStore>()(
  persist(
    set => ({
      ...FacetStoreBuilder(set),
      name: "visx",
      scope: Scope.Path,
      useQualifierScope: true,
    }),
    {
      name: getStorageKey({ namespace: "visx", keyName: "filters" }),
      storage: stateStorage,
      partialize: state => ({
        facets: state.facets,
      }),
    }
  )
);

export function computeNodeStats(nodeData: TrafficDatum) {
  const computeStats = (type: StatisticType) => {
    if (!nodeData.data[type]) {
      return;
    }

    return merge(
      {},
      nodeData.data[type],
      computeDataFromStats({ stats: nodeData.data[type]?.stats })
    );
  };

  let keys = Object.values(StatisticType);
  keys.forEach((type: StatisticType) => {
    nodeData.data[type] = computeStats(type);
  });

  return nodeData;
}
