import { Box, IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useState } from "react";
import { DeleteNetworkRange } from "pages/networks/types";
import { RemoveNetworkRange } from "../remove-network-range/RemoveNetworkRange";

export function RemoveNetworkRangeDialog({
  ipRange,
  network,
}: DeleteNetworkRange) {
  const [allowRemove, setAllowRemove] = useState(false);

  const handleAllowRemove = (value: boolean) => {
    setAllowRemove(value);
  };

  return (
    <Box>
      <IconButton
        aria-label="Remove IP range"
        title="Remove IP range"
        onClick={() => handleAllowRemove(true)}
        edge="end"
      >
        <DeleteOutlineIcon color="action"></DeleteOutlineIcon>
      </IconButton>
      {allowRemove && (
        <RemoveNetworkRange
          network={network}
          ipRange={ipRange}
          allowRemove={allowRemove}
          handleAllowRemove={handleAllowRemove}
        ></RemoveNetworkRange>
      )}
    </Box>
  );
}
