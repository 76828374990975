import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { HostGroupName } from "../host-group-name";
import { HostGroupStatus } from "../host-group-status";
// import { HostGroupCount } from "../host-group-count";

export const HOST_GROUPS_COLUMNS: GridColDef[] = [
  {
    field: "hostgroupname",
    headerName: "Name",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <HostGroupName hostGroup={params.row} />
    ),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 1.5,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) =>
      params.row.description,
  },
  {
    field: "hostgroupstatus",
    headerName: "Status",
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <HostGroupStatus hostGroup={params.row} />;
    },
  },
  // {
  //   field: "importedhostcount",
  //   headerName: "Imported Hosts",
  //   flex: 1,
  //   renderCell: (params: GridRenderCellParams<any, any, any>) => (
  //     <HostGroupCount hostGroup={params.row} />
  //   ),
  // },
];

const ColumnVisibilityModel: { [key: string]: boolean } = {
  // Hide columns from the display
};

const PinnedColumns = {
  left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
  right: [],
};

export const HostGroupsColumnConfig = {
  ColumnVisibilityModel: ColumnVisibilityModel,
  PinnedColumns: PinnedColumns,
};
