import { Alert, Typography } from "@mui/material";
import { ApplianceCertificate } from "pages/appliances/types";
import { ApplianceActivationStatus } from "./ApplianceActivationStatus";

interface ApplianceDetailProps {
  initMatching: boolean;
  errorMessage: string;
  applianceCertificate?: ApplianceCertificate;
}

export function ApplianceDetails({
  initMatching,
  errorMessage,
  applianceCertificate,
}: ApplianceDetailProps) {
  if (!initMatching && !errorMessage) {
    return null;
  }
  if (errorMessage) {
    return (
      <Alert sx={{ width: "100%" }} severity="error">
        <Typography variant="body2">{errorMessage}</Typography>
      </Alert>
    );
  }
  return (
    <ApplianceActivationStatus applianceCertificate={applianceCertificate} />
  );
}
