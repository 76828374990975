import { DataGrid } from "modules/data-grid/components/data-grid";
import {
  BASE_USER_DEPARTMENTS_COLUMNS,
  USER_DEPARTMENTS_COLUMNS,
} from "./constants";

import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { UserDepartment } from "pages/users-segmentation/components/user-departments/types";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";

export const UserDepartmentDataGrid = (
  props: DataGridProps<UserDepartment>
) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  return (
    <DataGrid
      rowHeight={64}
      columns={
        userPermissions.has("CREATE_TAGBASEDPOLICY")
          ? USER_DEPARTMENTS_COLUMNS
          : BASE_USER_DEPARTMENTS_COLUMNS
      }
      pagination={true}
      getRowId={(user: UserDepartment) => `${user.department}`}
      paginationMode="server"
      sortingMode="server"
      {...props}
    />
  );
};
