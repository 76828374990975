import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { AuditLog } from "pages/monitoring/types";
import { useUpdateTotalCount } from "pages/paths/components/path-data-grid/hooks";
import { useState } from "react";
import { AuditLogDetails } from "./components";
import { AUDIT_LOG_PINNED_COLUMNS, BASE_AUDIT_LOG_COLUMNS } from "./constants";

export const AUDIT_LOGS_DATA_GRID_ID = "audit-logs-data-grid";

export const AuditLogDataGrid = (props: DataGridProps<AuditLog>) => {
  const [selectedLog, setSelectedLog] = useState<AuditLog | undefined>();

  useUpdateTotalCount({
    isLoading: props.isLoading,
    maxRowCount: props.maxRowCount,
    originalRowCount: props.originalRowCount,
    id: AUDIT_LOGS_DATA_GRID_ID,
  });

  return (
    <>
      <DataGrid
        defaultPinnedColumns={AUDIT_LOG_PINNED_COLUMNS}
        rowHeight={64}
        columns={BASE_AUDIT_LOG_COLUMNS}
        pagination
        exportRowCount={props.originalRowCount}
        getRowId={({ id }: AuditLog) => id}
        paginationMode="server"
        sortingMode="server"
        {...props}
        onRowClick={params => {
          setSelectedLog(params.row);
        }}
      />
      {selectedLog && (
        <AuditLogDetails
          auditLog={selectedLog}
          onClose={() => {
            setSelectedLog(undefined);
          }}
        />
      )}
    </>
  );
};
