import { useMutation } from "@tanstack/react-query";
import { AssetUnmanageProps } from "../../asset-unmanage/AssetUnmanage";

export const useAssetUnmanage = () => {
  return useMutation<undefined, Error, AssetUnmanageProps>([
    "asset-unmanage",
    `gateways/managedevices`,
    "delete",
  ]);
};
