import { Chip, Stack, Tooltip } from "@mui/material";
import { CustomCommonStoreType } from "common/types/types";

import { ScopeMetadataColumn } from "modules/scope-metadata/types";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { useMemo } from "react";

export const AssetCoreTags = ({
  coreTags,
  useAssetCoreTagsStore,
  viewOnly,
}: {
  coreTags: { [key: string]: any };
  useAssetCoreTagsStore: CustomCommonStoreType;
  viewOnly?: boolean;
}) => {
  const facetState = useAssetCoreTagsStore(state => state.facets);
  const metadata = useAssetCoreTagsStore(state => state.metadata);

  const displayNameToTagName = useMemo(() => {
    let displayNames: { [key: string]: ScopeMetadataColumn | undefined } = {};
    let keys = Object.keys(metadata?.columns || {});
    keys.forEach(key => {
      displayNames[metadata?.columns?.[key]?.displayName || ""] =
        metadata?.columns?.[key];
    });
    return displayNames;
  }, [metadata?.columns]);

  const updateFacetOption = useAssetCoreTagsStore(
    state => state.updateFacetOption
  );

  const coreTagsKeys = Object.keys(coreTags || {});
  const hasCoreTags =
    coreTagsKeys?.length > 0 &&
    coreTagsKeys.some((key: string) => coreTags[key]);

  let tooltipText = "";
  coreTagsKeys.forEach(key => {
    const value = coreTags[key];
    tooltipText =
      tooltipText !== "" && value !== ""
        ? `${tooltipText}, ${capitalizeFirstLetter(key)}: ${value}`
        : value !== ""
        ? `${capitalizeFirstLetter(key)}: ${value}`
        : tooltipText;
  });

  interface HandleClickProps {
    e: React.MouseEvent<HTMLDivElement, MouseEvent>;
    facetName: string;
    optionName: any;
    value: boolean | undefined;
  }

  const handleClick = ({
    e,
    facetName,
    optionName,
    value,
  }: HandleClickProps) => {
    e.stopPropagation();
    if (!viewOnly) {
      updateFacetOption({
        facetName: facetName,
        optionName: optionName,
        value: Boolean(value),
      });
    }
  };

  return (
    <>
      {hasCoreTags ? (
        <Tooltip
          title={tooltipText || ""}
          placement="bottom-start"
          arrow={false}
        >
          <Stack sx={{ maxWidth: "100%", my: 1 }} direction="row" spacing={1}>
            {coreTagsKeys.map((key, index, values) => {
              let facetKey = (
                displayNameToTagName[key]?.internalName || key
              ).toLowerCase();

              const value = coreTags[key];
              const isSelected =
                !viewOnly && facetState?.get(facetKey)?.get(value)?.isSelected;
              return (
                value !== "" && (
                  <Chip
                    key={key}
                    label={value}
                    color={isSelected ? "secondary" : "default"}
                    onClick={
                      viewOnly === true
                        ? undefined
                        : e =>
                            handleClick({
                              e: e,
                              facetName: facetKey,
                              optionName: value,
                              value: !isSelected,
                            })
                    }
                  />
                )
              );
            })}
          </Stack>
        </Tooltip>
      ) : (
        <Chip
          label={window.getCTTranslatedText("No Tags")}
          color={"default"}
          disabled={true}
        />
      )}
    </>
  );
};
