import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { CTDrawer } from "common/molecules/drawer";
import { AssetAttributesBlock } from "modules/attributes-block";
import { useCore } from "modules/core";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { Scope } from "modules/scope-metadata/types";
import { BASE_USERS_COLUMNS } from "pages/users-segmentation/components/users/components/user-data-grid/constants";
import { UserGroupCount } from "pages/users-segmentation/components/users/components/user-group-count";
import { UserSessionStatus } from "pages/users-segmentation/components/users/components/user-session-status";
import {
  FACET_OPTION_MAPPING,
  FACET_ORDER,
} from "pages/users-segmentation/components/users/constants";
import { useUsersAPI } from "pages/users-segmentation/components/users/hooks";
import {
  useAssetCustomUserStore,
  useAssetUserStore,
} from "pages/users-segmentation/components/users/store/useUserStore";
import { User } from "pages/users-segmentation/types";
import { useState } from "react";

export function AssetUser({ criteria }: { readonly criteria: string }) {
  const [showUsers, setShowUsers] = useState(false);

  const coreResponse = useCore<User>({
    useStore: useAssetUserStore,
    facetGroupInfo: FACET_ORDER,
    scope: Scope.SCIM_USER_DEPARTMENT,
    facetOptionsDisplayMapping: FACET_OPTION_MAPPING,
    additionalCriteria: criteria,
    useApi: useUsersAPI,
    pageSize: 100,
    skipSearch: true,
    useFacetStore: useAssetCustomUserStore,
    skipUseFacetQueryConnector: true,
  });

  let columns: GridColDef[] = BASE_USERS_COLUMNS.map(col => {
    switch (col?.field) {
      case "userGroups":
        return {
          field: "userGroups",
          headerName: "Groups",
          width: 140,
          flex: 0.4,
          sortable: true,
          renderCell: params => {
            return <UserGroupCount user={params.row} viewOnly={true} />;
          },
        };
      case "userAssets":
        return {
          field: "signedin",
          headerName: "Logged-In Status",
          width: 140,
          flex: 0.4,
          sortingOrder: ["asc", "desc"],
          sortable: true,
          renderCell: params => {
            return <UserSessionStatus user={params.row} />;
          },
        };
      default:
        return col;
    }
  });
  columns = columns.filter(
    col => col?.field !== "active" && col?.field !== "lastUpdated"
  );
  return (
    <Stack>
      <AssetAttributesBlock
        assign={() => {}}
        count={coreResponse?.rowCount ?? 0}
        canAssign={false}
        isLoading={coreResponse.mutation.isLoading}
        showAssigned={() => {
          setShowUsers(true);
        }}
        title="User"
      />
      <CTDrawer
        open={showUsers}
        onClose={() => {
          setShowUsers(false);
        }}
        title={window.getCTTranslatedText("Users")}
      >
        <Typography sx={{ mb: 2 }}>
          {window.getCTTranslatedText(
            "List of the Users who have logged in to this endpoint."
          )}
        </Typography>
        <DataGrid
          sx={{
            height: "90%",
            overflow: "auto",
          }}
          rowHeight={64}
          columns={columns}
          pagination={true}
          paginationMode="server"
          sortingMode="server"
          rows={coreResponse.rows}
          rowCount={coreResponse.rowCount}
          mutation={coreResponse.mutation}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          getRowId={(user: User) => `${user.userID}`}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={coreResponse.onSortChange}
        />
      </CTDrawer>
    </Stack>
  );
}
