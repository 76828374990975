import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { Appliance } from "pages/appliances/types";
import CloseIcon from "@mui/icons-material/Close";
import { WidgetListItem } from "common/molecules/widget/components/widget-list-item";
import { getUsageValue } from "../ApplianceHealth";

interface ApplianceHealthDetailsProps {
  appliance?: Appliance;
  open: boolean;
  onClose: () => void;
}

export function ApplianceHealthDetails({
  appliance,
  open,
  onClose,
}: ApplianceHealthDetailsProps) {
  const PropertiesList = [
    {
      name: "temperature",
      value: `${
        appliance?.cpuTemperature && appliance?.cpuTemperature > 0
          ? getUsageValue(appliance?.cpuTemperature)
          : "-"
      } \u00B0C`,
    },
    {
      name: "cpuUsage",
      value: `${getUsageValue(appliance?.cpuUtilization)} %`,
    },
    {
      name: "memoryUsage",
      value: `${getUsageValue(appliance?.memoryUtilization)} %`,
    },
    {
      name: "diskUsage",
      value: `${getUsageValue(appliance?.diskUtilization)} %`,
    },
    {
      name: "lanUsage",
      value: `${getUsageValue(appliance?.lanUtilization)} %`,
    },
    {
      name: "wanUsage",
      value: `${getUsageValue(appliance?.wanUtilization)} %`,
    },
  ];

  function renderContent() {
    return (
      <Stack spacing={3} mt={2} pb={2}>
        {PropertiesList.map(property => {
          return (
            <WidgetListItem
              key={property.name}
              keyText={window.getCTTranslatedText(property.name)}
              valueText={property.value}
            />
          );
        })}
      </Stack>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={window.getCTTranslatedText("applianceHealthDetails")}
      sx={{ width: "100%", height: "100%" }}
      maxWidth="lg"
    >
      <DialogTitle sx={{ m: 0, p: 2, px: 4 }} id="dialog-title">
        {window.getCTTranslatedText("applianceHealthDetails")}
      </DialogTitle>
      <IconButton
        aria-label="close dialog"
        onClick={onClose}
        size="medium"
        sx={{
          position: "absolute",
          right: "16px",
          top: "8px",
          zIndex: 2,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers={true}
        sx={{
          minWidth: 440,
        }}
      >
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
}
