import { Box, Chip, Stack, Tooltip } from "@mui/material";
import { AssetColumnRenderProps, NetworkInterface } from "pages/assets/types";

export function AssetMACAddress({ asset }: AssetColumnRenderProps) {
  if (!asset.assetId) {
    return <Box style={{ width: 70 }} />;
  }
  let macs: string[] = [];

  if (asset?.interfaces?.length) {
    asset?.interfaces?.forEach((nwInterface: NetworkInterface) => {
      if (nwInterface?.macaddress) {
        macs.push(nwInterface?.macaddress);
      }
    });
  }
  let tooltipText = "";
  macs.forEach(ip => {
    if (tooltipText !== "") {
      tooltipText = tooltipText.concat(", ");
    }
    tooltipText = tooltipText.concat(ip);
  });

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="center">
      <>
        {macs.length ? (
          <Tooltip
            title={tooltipText || ""}
            placement="bottom-start"
            arrow={false}
          >
            <Stack sx={{ maxWidth: "100%", my: 1 }} direction="row" spacing={1}>
              {macs.map(mac => {
                return <Chip key={mac} label={mac} />;
              })}
            </Stack>
          </Tooltip>
        ) : null}
      </>
    </Stack>
  );
}
