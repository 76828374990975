import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { useState } from "react";
import { DeleteInvite } from "../delete-invite";
import { Invite } from "pages/organization/types";

interface InviteActionsProps {
  invite: Invite;
}

export function InviteActions({ invite }: InviteActionsProps) {
  const [allowDelete, setAllowDelete] = useState(false);

  const handleAllowDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: boolean
  ) => {
    if (event) {
      event.stopPropagation();
    }
    setAllowDelete(value);
  };

  if (!invite.email) {
    return <Box width="100%" />;
  }

  return (
    <Box>
      <Stack direction="row">
        <IconButton
          aria-label="Delete"
          onClick={event => handleAllowDelete(event, true)}
        >
          <Tooltip title={window.getCTTranslatedText("Delete Invite")}>
            <DeleteOutlineIcon />
          </Tooltip>
        </IconButton>
      </Stack>
      {allowDelete && (
        <DeleteInvite
          invite={invite}
          allowDelete={allowDelete}
          handleAllowDelete={() => setAllowDelete(false)}
        />
      )}
    </Box>
  );
}
