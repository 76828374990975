import { Box, FormControlLabel, FormGroup, Stack, Switch } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import {
  INTEGRATION_TYPES,
  INTEGRATION_PROVIDERS,
} from "pages/Integration/constants";
import { useIntegrationGetAPI } from "pages/Integration/hooks";
import React, { useEffect } from "react";
import { AgentProxyForm } from "./AgentInstallationInfo";
import { agent_types, platforms } from "./constants";

interface SwitchProps {
  children: React.ReactNode;
  platform: string;
  agentType: agent_types | undefined;
}

export function AgentDrawerSwitchControl(props: SwitchProps) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const { children, platform } = props;
  const [proxyChecked, setProxyChecked] = React.useState(false);
  const [authProxyChecked, setAuthProxyChecked] = React.useState(false);
  const [autoUpgrade, setAutoUpgrade] = React.useState(false);
  const [localRegistryChecked, setLocalRegistryChecked] = React.useState(false);
  const [vScan, setVscan] = React.useState(false);
  const { data: xcloudData } = useIntegrationGetAPI(
    INTEGRATION_TYPES.VULNERABILITY_SCANNER,
    INTEGRATION_PROVIDERS.XCLOUD
  );
  const [goldenImageChecked, setGoldenImageChecked] = React.useState(false);

  const shouldRenderAgentAutoUpgradeSwitch =
    platform !== platforms.DOCKER && platform !== platforms.RPM_LEGACY;

  const shouldRenderUseProxySwitch = platform !== platforms.RPM_LEGACY;

  const shouldRenderGoldenImageSwitch = [
    platforms.SOLARIS,
    platforms.RPM_LEGACY,
    platforms.DARWIN,
    platforms.AIX,
    platforms.DOCKER,
  ].every(plat => platform !== plat);

  useEffect(() => {
    if (xcloudData) {
      setVscan(Boolean(xcloudData?.status) ?? false);
    }
  }, [xcloudData]);

  const handleProxyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let receievedEvent = event.target.checked;
    setProxyChecked(receievedEvent);
    if (receievedEvent === false) {
      setAuthProxyChecked(false);
    }
  };

  const handleAutoUpgrade = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoUpgrade(event.target.checked);
  };

  const handleAuthProxyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAuthProxyChecked(event.target.checked);
  };
  const handleDockerRepoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalRegistryChecked(event.target.checked);
  };

  const handleGoldenImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGoldenImageChecked(event.target.checked);
  };

  if (props.agentType !== agent_types.CT_REMOTE_CONNECTOR) {
    return (
      <Box border={1} marginTop={5} padding={5} borderColor="divider">
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Box padding={1}>
            <FormGroup row>
              {shouldRenderUseProxySwitch && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={proxyChecked}
                      onChange={handleProxyChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={window.getCTTranslatedText("Use Proxy")}
                />
              )}
              {proxyChecked && (
                <FormControlLabel
                  disabled={!proxyChecked}
                  control={
                    <Switch
                      checked={authProxyChecked}
                      onChange={handleAuthProxyChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={window.getCTTranslatedText("Use Authentication")}
                />
              )}
            </FormGroup>
          </Box>
          {shouldRenderAgentAutoUpgradeSwitch &&
            userPermissions.has("UPDATE_AGENT") && (
              <Stack padding={1}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={autoUpgrade}
                        onChange={handleAutoUpgrade}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={window.getCTTranslatedText("Agent Autoupgrade")}
                  />
                </FormGroup>
              </Stack>
            )}
          {platform === platforms.DOCKER && (
            <Stack padding={1}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={localRegistryChecked}
                      onChange={handleDockerRepoChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={window.getCTTranslatedText("useLocalRegistry")}
                />
              </FormGroup>
            </Stack>
          )}
          {shouldRenderGoldenImageSwitch &&
            userPermissions.has("UPDATE_AGENT") && (
              <Stack padding={1}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={goldenImageChecked}
                        onChange={handleGoldenImageChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={window.getCTTranslatedText("Golden Image")}
                  />
                </FormGroup>
              </Stack>
            )}
        </Box>

        {
          <AgentProxyForm
            pname={platform}
            authProxy={authProxyChecked}
            proxy={proxyChecked}
            autoUpgrade={autoUpgrade}
            vScan={vScan}
            children={children}
            localRegistry={localRegistryChecked}
            goldenImage={goldenImageChecked}
          />
        }
      </Box>
    );
  } else {
    return (
      <Box border={1} marginTop={5} padding={5} borderColor="divider">
        {
          <AgentProxyForm
            pname={platform}
            authProxy={false}
            proxy={false}
            autoUpgrade={false}
            vScan={false}
            children={children}
            localRegistry={localRegistryChecked}
            goldenImage={false}
          />
        }
      </Box>
    );
  }
}
