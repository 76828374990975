import { alpha, styled } from "@mui/material";

export const SearchContainerCompact = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 30,
  backgroundColor:
    theme.palette.mode === "dark"
      ? alpha(theme.palette.common.black, 0.3)
      : alpha(theme.palette.common.black, 0.05),
  margin: 0,
  width: "100%",
}));
