import { useMutation } from "@tanstack/react-query";
import { AssetStatus } from "pages/assets/types";

export interface ZeroTrustAPIParams {
  criteria: string;
  inboundToState?: AssetStatus;
  outboundToState?: AssetStatus;
  comment?: string | null;
}

export function useZeroTrustAPI() {
  const path = `assets/zerotrust`;
  return useMutation<any, Error, ZeroTrustAPIParams>(["asset", path, "put"]);
}

export function useSynchronizePolicy() {
  const path = `assets/synchronize`;
  return useMutation<any, Error, any>(["asset", path, "put"]);
}
