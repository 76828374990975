import { FacetGroupInfo } from "../core/types";
import { FacetGroups, Operator } from "./types";
export const PLUS = "+";
export const MINUS = "-";
export const ALL_TIME = "alltime";

export const COMMON_FACETS: FacetGroupInfo = {
  environment: FacetGroups.Tags,
  location: FacetGroups.Tags,
  application: FacetGroups.Tags,
  owner: FacetGroups.Tags,
  role: FacetGroups.Tags,
  type: FacetGroups.Tags,
  businessvalue: FacetGroups.Tags,
  usergroup: FacetGroups.Tags,
  department: FacetGroups.Tags,
  primarysubnet: FacetGroups.Tags,
  clusteridentifier: FacetGroups.Tags,
  containernamespace: FacetGroups.Tags,
  osname: FacetGroups.Tags,
  model: FacetGroups.Tags,
  category: FacetGroups.Tags,
  subcategory: FacetGroups.Tags,
  manufacturer: FacetGroups.Tags,
};

export const ADVANCED_OPERATORS_SUPPORT: { [key: string]: boolean } = {
  assetname: true,
  // macaddress: true,
  // addresses: true,
};

export const ADVANCED_OPERATORS_LIST: Array<Operator> = [
  Operator.CONTAINS,
  Operator.STARTS_WITH,
  Operator.ENDS_WITH,
];

export const ADVANCED_OPERATORS: { [key: string]: boolean } = {
  [Operator.CONTAINS]: true,
  [Operator.STARTS_WITH]: true,
  [Operator.ENDS_WITH]: true,
};

export const OPERATORS_LIST: Array<Operator> = [
  Operator.NOT_EQUAL,
  Operator.STARTS_WITH,
  Operator.ENDS_WITH,
  Operator.CONTAINS,
  Operator.EQUAL,
];

export const OVERWRITE_FACETS_KEY = "o";
export const OVERWRITE_FACETS = `${OVERWRITE_FACETS_KEY}=t`;
