export enum platforms {
  WINDOWS = "windows",
  RPM = "rpm",
  DEBIAN = "debian",
  LINUX = "linux",
  POWERSHELL = "powershell",
  DARWIN = "darwin",
  DOCKER = "docker",
  AIX = "aix",
  RPM_LEGACY = "rpmLegacy",
  SUSE = "suse",
  SOLARIS = "solaris",
}

export enum containerSteps {
  CREATE = "create",
  HELM = "helmchart",
  COPY_CONFIGMAPS = "copy_configmaps",
  INTEGRATE_CONFIGMAPS = "integrate_configmaps",
  INTEGRATE_CONFIGMAPS_SCRIPT = "integrate_configmaps_script",
  RESTART_ISTIO = "restart_istio",
  INJECT_ISTIO = "inject_istio",
  RESTART_DEPLOYMENT = "restart_deployment",
  UPGRADE = "upgrade",
  COPY_TELEMETRY = "copy_telemetry",
  CLEANUP = "cleanup",
}
export const installScript = "Installation Script";

export const manualInstall = "Manual Installation";

export enum architectureType {
  X86_64 = "x86_64",
  ARM64 = "arm64",
  PPC = "ppc",
}

export enum agent_types {
  CT_AGENT = "CT_AGENT",
  INSTALL_SCRIPT = "INSTALL_SCRIPT",
  GATEWAY_INSTALL_SCRIPT = "CT_GATEWAY_INSTALL_SCRIPT",
  GATEWAY = "CT_GATEWAY",
  CT_CONTAINER_AGENT = "CT_CONTAINER_AGENT",
  CT_USER_AGENT = "CT_USER_AGENT",
  CT_REMOTE_CONNECTOR = "CT_REMOTE_CONNECTOR",
  CT_LEGACY_AGENT = "CT_LEGACY_AGENT",
}

export enum agent_types_radio_button {
  CT_AGENT = "Server Agent",
  CT_USER_AGENT = "User Agent",
  CT_CONTAINER_AGENT = "Container Security",
  CT_LEGACY_AGENT = "Server Agent",
}

export const agentTypeValues = {
  CT_AGENT: "server",
  CT_USER_AGENT: "user",
  CT_REMOTE_CONNECTOR: "connector",
  CT_CONTAINER_AGENT: "containers",
  CT_LEGACY_AGENT: "server",
};

export type SupportedCoreOS = Exclude<
  platforms,
  platforms.LINUX | platforms.POWERSHELL
>;

export const CORE_OS: Record<SupportedCoreOS, string> = {
  [platforms.WINDOWS]: "Windows",
  [platforms.RPM]: "RPM",
  [platforms.DEBIAN]: "DEBIAN",
  [platforms.DARWIN]: "Mac",
  [platforms.DOCKER]: "Containers",
  [platforms.AIX]: "AIX",
  [platforms.RPM_LEGACY]: "RHEL 6",
  [platforms.SUSE]: "SUSE",
  [platforms.SOLARIS]: "Solaris",
};

export const SUPPORTED_OS_VERSIONS: Record<
  string,
  string[] | Record<string, string[]>
> = {
  [platforms.WINDOWS]: {
    "Server (64-bit)": ["2008 R2", "2012", "2012 R2", "2016", "2019", "2022"],
  },
  [platforms.RPM]: {
    CentOS: ["7 and above"],
    "Oracle Linux": ["7.x"],
    RHEL: ["7.x", "8.x"],
    "Rocky Linux": ["8.x", "9.x"],
  },
  [platforms.DEBIAN]: { Debian: ["9"], Ubuntu: ["18", "20"] },
  [platforms.DARWIN]: ["arm64"],
  [platforms.AIX]: ["7.1", "7.2"],
  [platforms.RPM_LEGACY]: ["6"],
  [platforms.SUSE]: ["15"],
  [platforms.SOLARIS]: ["11"],
};

export enum environments {
  KUBERNETES = "Kubernetes ",
  ISTIO = "istio",
  HELM = "helm",
}
export const ENVIRONMENT: Record<environments, string> = {
  [environments.KUBERNETES]: "Kubernetes",
  [environments.ISTIO]: "Istio",
  [environments.HELM]: "Helm",
};

export const SUPPORTED_ENV_VERSIONS: Record<string, string[]> = {
  [environments.KUBERNETES]: [
    "1.21 and above",
    "(aks, eks, gke, oke & rancher)",
  ],
  [environments.ISTIO]: ["1.18.0 and above"],
  [environments.HELM]: ["3.13.2 and above"],
};

export const EMPTY_STRING = "";
