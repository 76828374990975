import { useMutation } from "@tanstack/react-query";
import { Network } from "pages/networks/types";

interface NetworkResInt {
  items: Array<Network>;
  metadata: {
    total: number;
  };
}

export const useNetworksSuggestions = () => {
  return useMutation<NetworkResInt, Error, any>([
    "namednetworks",
    "namednetworks/actions/search?computeTotal=true",
  ]);
};
