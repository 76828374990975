import { DataGrid } from "modules/data-grid/components/data-grid";
import { USERGROUPS_COLUMNS, BASE_USERGROUPS_COLUMNS } from "./constants";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import {
  UserGroup,
  HostAndUserGroupStatusState,
} from "pages/users-segmentation/components/user-groups/types";
import { useMemo, useState } from "react";
import { GridRowId } from "@mui/x-data-grid-pro";
import { Box, Stack } from "@mui/material";
import { UserGroupToolbar } from "../user-group-data-grid-toolbar/UserGroupToolbar";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";

export const UserGroupDataGrid = (props: DataGridProps<UserGroup>) => {
  const [selection, setSelection] = useState<Array<GridRowId>>([]);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const selectedData: Array<UserGroup> | undefined = useMemo(() => {
    return props.rows?.filter((row: UserGroup) => {
      return selection.indexOf(row.groupID) !== -1;
    });
  }, [selection, props.rows]);

  const selectedRawData: Array<UserGroup> | undefined = useMemo(() => {
    return (props?.rawData ?? [])?.filter((row: UserGroup) => {
      return selection.indexOf(row.groupID) !== -1;
    });
  }, [selection, props?.rawData]);

  const onClickClearSelection = () => {
    setSelection([]);
  };

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <UserGroupToolbar
        hideToolbar={onClickClearSelection}
        show={selection.length > 0}
        selectedData={selectedData}
      />
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <DataGrid
          rowHeight={64}
          columns={
            userPermissions.has("UPDATE_USER_GROUP")
              ? USERGROUPS_COLUMNS
              : BASE_USERGROUPS_COLUMNS
          }
          pagination={true}
          getRowId={(user: UserGroup) => `${user.groupID}`}
          paginationMode="server"
          sortingMode="server"
          checkboxSelection={userPermissions.has("UPDATE_USER_GROUP")}
          isRowSelectable={data =>
            data.row.userGroupStatus === HostAndUserGroupStatusState.Active ||
            data.row.userGroupStatus === HostAndUserGroupStatusState.InActive
          }
          rowSelectionModel={selection}
          onRowSelectionModelChange={selectionModel => {
            setSelection(selectionModel);
          }}
          selectedRawData={selectedRawData}
          {...props}
        />
      </Box>
    </Stack>
  );
};
