import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { ReactElement } from "react";

function SubHeaderRenderer({ subHeader }: { subHeader?: string }) {
  return (
    <Stack direction={"row"}>
      <Typography variant="caption" color="GrayText">
        {subHeader ?? " "}
      </Typography>
    </Stack>
  );
}

export function Widget({
  header,
  subHeader,
  children,
}: {
  header: string;
  subHeader?: string;
  children?: ReactElement;
}) {
  const theme = useTheme();
  return (
    <Stack
      direction={"column"}
      spacing={0}
      borderRadius={1}
      sx={{
        width: "100%",
        border: theme.palette.custom.widgetBorder,
      }}
    >
      <Stack
        alignContent={"center"}
        justifyItems={"center"}
        sx={{
          px: 3,
          py: 2,
          width: "100%",
        }}
        direction="row"
        spacing={2}
      >
        <Stack
          alignContent={"center"}
          justifyItems={"center"}
          justifyContent={"center"}
          sx={{ overflow: "hidden" }}
        >
          <Typography variant="body1" noWrap title={header}>
            {header}
          </Typography>
          {Boolean(subHeader) ? (
            <SubHeaderRenderer subHeader={subHeader} />
          ) : null}
        </Stack>
      </Stack>
      <Divider></Divider>
      <Stack
        sx={{
          flexWrap: "wrap",
          py: 2,
          px: 3,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}
