import {
  Alert,
  Button,
  ButtonGroup,
  ClickAwayListener,
  DialogContent,
  Divider,
  Drawer,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "modules/data-grid/components/data-grid";
import {
  GridToolbar,
  ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useAssetStore } from "pages/assets/store";
import {
  Asset,
  AssetSecurityCategory,
  AssetStatus,
  AssetType,
  MAX_DELETE_COUNT,
} from "pages/assets/types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useCommonStore } from "../../../../common/store/useCommonStore";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import { MoreOptionsMenu } from "common/molecules/more-options-menu";
import { MenuOption } from "common/molecules/more-options-menu/types";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { Annotation } from "modules/annotation";
import { AssetCoreTags } from "modules/asset-core-tags/components";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useDebouncedSnackbar } from "modules/snackbar/store/useDebouncedSnackbar";
import { AssetStatusMap } from "pages/asset/components/asset-detail/constants";
import { useSynchronizePolicy } from "pages/asset/components/asset-detail/hooks/use-update-status";
import { useZeroTrustAPI } from "pages/asset/components/asset-detail/hooks/use-update-status/useUpdateStatus";
import { MetadataEdit } from "pages/asset/components/asset-metadata-wrapper/components/metadata-edit";
import { AssetBusinessRisk } from "pages/assets/components/asset-business-risk";
import { AssetNameAndTags } from "pages/assets/components/asset-name-and-tags";
import { AssetPaths } from "pages/assets/components/asset-paths";
import { AssetPorts } from "pages/assets/components/asset-ports";
import {
  AssetFirewallStatus,
  AttackSurfaceStatusOptionsText,
  BlastRadiusStatusOptionsText,
} from "pages/assets/constants";
import pluralize from "pluralize";
import { useSearchParams } from "react-router-dom";
import { ManageAssetNetworks } from "../manage-asset-networks";
import { ManageAssetTemplates } from "../manage-asset-templates";
import { AssetUnmanage } from "../asset-unmanage";

export const ASSET_STATUS_CHANGE_COLS: GridColDef[] = [
  {
    field: "assetname",
    headerName: "Asset",
    minWidth: 200,
    flex: window.screen.availWidth > 1200 ? 1 : undefined,
    disableReorder: true,
    resizable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AssetNameAndTags asset={params.row} viewOnly={true} />;
    },
  },
  {
    field: "assettags",
    headerName: "Tags",
    minWidth: 180,
    flex: window.screen.availWidth > 1200 ? 0.8 : undefined,
    disableReorder: true,
    resizable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <AssetCoreTags
          viewOnly={true}
          coreTags={params.row.coreTags}
          useAssetCoreTagsStore={useCommonStore}
        />
      );
    },
  },
  {
    field: "assetrisk",
    width: 100,
    headerAlign: "left",
    align: "left",
    disableReorder: true,
    resizable: false,
    sortable: false,
    headerName: "BreachImpact",
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AssetBusinessRisk asset={params.row} viewOnly={true} />;
    },
  },
  {
    field: "ports",
    headerName: "Ports",
    headerAlign: "left",
    align: "left",
    width: 70,
    disableReorder: true,
    resizable: false,
    sortable: false,
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AssetPorts asset={params.row} viewOnly={true} />;
    },
  },
  {
    field: "paths",
    headerName: "Paths",
    headerAlign: "left",
    align: "left",
    width: 80,
    minWidth: 80,
    flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
    disableReorder: true,
    resizable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <AssetPaths asset={params.row} viewOnly={true} />;
    },
  },
];

export const AssetSecurityCategoryText = {
  [AssetSecurityCategory.INBOUND]: "Attack Surface",
  [AssetSecurityCategory.OUTBOUND]: "Blast Radius",
};

export interface AssetToolbarProps extends Omit<ToolbarProps, "children"> {
  selectedData: Array<Asset>;
  recommendationId?: string;
  enableExport?: boolean;
  showDeviceActions?: boolean;
}
export default function AssetToolbar(props: AssetToolbarProps) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);
  const [showAssignNetworkDialog, setShowAssignNetworkDialog] = useState(false);
  const [showAssignTagsDialog, setShowAssignTagsDialog] = useState(false);
  const [showUnmanageDialog, setShowUnmanageDialog] = useState(false);
  const [synchronizePolicy, setSynchronizePolicy] = useState(false);
  const [changeStatus, setChangeStatus] = useState<
    AssetSecurityCategory | undefined
  >(undefined);

  const [changeStatusType, setChangeStatusType] = useState<
    AssetStatus | AssetStatus | undefined
  >(undefined);

  const [searchParams, setSearchParams] = useSearchParams();

  const [openAttackSurfaceMenu, setAttackSurfaceMenuOpen] = useState(false);
  const [openBlastRadiusMenu, setBlastRadiusMenuOpen] = useState(false);
  const attackSurfaceAnchorRef = useRef<HTMLDivElement>(null);
  const blastRadiusAnchorRef = useRef<HTMLDivElement>(null);

  const AttackSurfaceStatusOptionsList = [
    AssetStatus.Unsecured,
    AssetStatus.SimulateSecureInternet,
    AssetStatus.SecureInternet,
    AssetStatus.SimulateSecureAll,
    AssetStatus.SecureAll,
  ];

  const BlastRadiusStatusOptionsList = [
    AssetStatus.Unsecured,
    AssetStatus.SimulateSecureInternet,
    AssetStatus.SecureInternet,
    AssetStatus.SimulateSecureAll,
    AssetStatus.SecureAll,
  ];

  const handleAssetUnmanage = () => {
    setShowUnmanageDialog(true);
  };

  const handleAddToTemplateDialogOpen = (dialogVisibility: boolean) => {
    setShowAddToTemplateDialog(dialogVisibility);
  };

  const handleClose = () => {
    setShowAddToTemplateDialog(false);
    setShowAssignNetworkDialog(false);
    setShowAssignTagsDialog(false);
    setShowUnmanageDialog(false);
    props.hideToolbar();
  };

  const addToTemplate = () => {
    setShowAddToTemplateDialog(false);
    props.hideToolbar();
  };

  const handleAssignNetworkDialogOpen = (dialogVisibility: boolean) => {
    setShowAssignNetworkDialog(dialogVisibility);
  };

  const assignNetwork = () => {
    setShowAssignNetworkDialog(false);
    props.hideToolbar();
  };

  const handleAssignTagsDialogOpen = (dialogVisibility: boolean) => {
    setShowAssignTagsDialog(dialogVisibility);
    if (!dialogVisibility) {
      props.hideToolbar();
    }
  };

  const assignTags = () => {
    setShowAssignTagsDialog(false);
    props.hideToolbar();
  };

  useEffect(() => {
    if (props?.recommendationId) {
      const StatusRecommendationMap: any = {
        secureinternetoutboundcandidates: {
          status: "outbound",
          statusType: AssetStatus.SecureInternet,
        },
        secureintranetoutboundcandidates: {
          status: "outbound",
          statusType: AssetStatus.SecureAll,
        },
      };

      const status = StatusRecommendationMap[props.recommendationId]
        ?.status as AssetSecurityCategory;
      const statusType = StatusRecommendationMap[props.recommendationId]
        ?.statusType as AssetStatus;
      setChangeStatus(status);
      setChangeStatusType(statusType);

      if (searchParams.has("recommendation")) {
        searchParams.delete("recommendation");
        setSearchParams(searchParams);
      }
    }
  }, [props?.recommendationId, setSearchParams, searchParams]);

  const hasPendingAttackSurfaceChanges = useMemo(() => {
    return props.selectedData.some((asset: Asset) => {
      return asset?.pendingAttackSurfaceChanges === true;
    });
  }, [props.selectedData]);

  const hasPendingBlastRadiusChanges = useMemo(() => {
    return props.selectedData.some((asset: Asset) => {
      return asset?.pendingBlastRadiusChanges === true;
    });
  }, [props.selectedData]);

  const hasDeletableDevices = useMemo(() => {
    let nonDeviceAssets = props.selectedData.some((asset: Asset) => {
      return asset?.type !== AssetType.Device;
    });
    if (props.selectedData?.length <= MAX_DELETE_COUNT && !nonDeviceAssets) {
      return true;
    }
    return false;
  }, [props.selectedData]);

  const handleMenuToggle = (
    ref: React.RefObject<HTMLDivElement> | undefined,
    status: AssetSecurityCategory
  ) => {
    if (ref) {
      setChangeStatus(status);
      setSynchronizePolicy(false);
      if (status === AssetSecurityCategory.INBOUND) {
        setBlastRadiusMenuOpen(false);
        setAttackSurfaceMenuOpen(prevOpen => !prevOpen);
      } else {
        setAttackSurfaceMenuOpen(false);
        setBlastRadiusMenuOpen(prevOpen => !prevOpen);
      }
    }
  };

  const handleMenuClose = (event: Event) => {
    if (
      (attackSurfaceAnchorRef.current &&
        attackSurfaceAnchorRef.current.contains(event.target as HTMLElement)) ||
      (blastRadiusAnchorRef.current &&
        blastRadiusAnchorRef.current.contains(event.target as HTMLElement))
    ) {
      return;
    }
    setAttackSurfaceMenuOpen(false);
    setBlastRadiusMenuOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    status: AssetStatus | AssetStatus | undefined,
    type: AssetSecurityCategory | undefined,
    synchronizePolicy?: boolean
  ) => {
    if (synchronizePolicy) {
      setSynchronizePolicy(true);
    } else {
      setSynchronizePolicy(false);
    }
    setChangeStatus(type);
    setChangeStatusType(status);
  };

  const popperMenuContent = (id: string, options: AssetStatus[]) => {
    const showSyncOption =
      changeStatus === AssetSecurityCategory.INBOUND
        ? hasPendingAttackSurfaceChanges
        : hasPendingBlastRadiusChanges;

    return (
      <Paper>
        <ClickAwayListener onClickAway={handleMenuClose}>
          <MenuList id={id} autoFocusItem>
            {showSyncOption && (
              <Stack sx={{ mx: 2 }}>
                <MenuItem
                  onClick={event =>
                    handleMenuItemClick(event, undefined, changeStatus, true)
                  }
                >
                  <Stack
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    justifyItems="flex-start"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {window.getCTTranslatedText(
                        AssetFirewallStatus.Synchronize
                      )}
                    </Typography>
                  </Stack>
                </MenuItem>
                <Divider />
              </Stack>
            )}
            {options.map((option, index, options) => (
              <Stack key={option} sx={{ mx: 2 }}>
                <MenuItem
                  onClick={event =>
                    handleMenuItemClick(event, option, changeStatus)
                  }
                >
                  <Stack
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    justifyItems="flex-start"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {window.getCTTranslatedText(
                        changeStatus === AssetSecurityCategory.INBOUND
                          ? AttackSurfaceStatusOptionsText[option]
                          : BlastRadiusStatusOptionsText[option]
                      )}
                    </Typography>
                  </Stack>
                </MenuItem>
                {index < options.length - 1 && <Divider />}
              </Stack>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    );
  };

  const moreActionMenuOptions: Array<MenuOption> = [
    {
      label: window.getCTTranslatedText("Manage Tags"),
      handler: () => handleAssignTagsDialogOpen(true),
    },
  ];

  if (
    userPermissions.has("UPDATE_ASSET") &&
    userPermissions.has("UPDATE_NAMED_NETWORK")
  ) {
    moreActionMenuOptions.push({
      label: window.getCTTranslatedText("Manage Named Networks"),
      handler: () => handleAssignNetworkDialogOpen(true),
    });
  }
  if (
    userPermissions.has("UPDATE_ASSET") &&
    userPermissions.has("UPDATE_TEMPLATE")
  ) {
    moreActionMenuOptions.push({
      label: window.getCTTranslatedText("Manage Templates"),
      handler: () => handleAddToTemplateDialogOpen(true),
    });
  }
  if (
    userPermissions.has("UPDATE_MANAGED_TO_UNMANAGED") &&
    props.showDeviceActions
  ) {
    moreActionMenuOptions.push({
      label: window.getCTTranslatedText("Delete"),
      handler: () => handleAssetUnmanage(),
      disabled: !hasDeletableDevices,
      tooltipMessage: window.getCTTranslatedText("deleteDevicesTooltipText", {
        count: MAX_DELETE_COUNT,
      }),
    });
  }

  const actions = (
    <Stack
      direction="row"
      spacing={2}
      alignItems={"center"}
      sx={{ height: "100%" }}
    >
      <ButtonGroup
        variant="outlined"
        ref={attackSurfaceAnchorRef}
        aria-label="split button"
      >
        <Button
          aria-controls={
            openAttackSurfaceMenu ? "attack-surface-menu" : undefined
          }
          aria-expanded={openAttackSurfaceMenu ? "true" : undefined}
          aria-label="Reduce Attack Surface"
          aria-haspopup="menu"
          onClick={() =>
            handleMenuToggle(
              attackSurfaceAnchorRef,
              AssetSecurityCategory.INBOUND
            )
          }
          endIcon={<ArrowDropDownIcon />}
        >
          {window.getCTTranslatedText("Attack Surface")}
        </Button>
      </ButtonGroup>
      <ButtonGroup
        variant="outlined"
        ref={blastRadiusAnchorRef}
        aria-label="split button"
      >
        <Button
          aria-controls={openBlastRadiusMenu ? "blast-radius-menu" : undefined}
          aria-expanded={openBlastRadiusMenu ? "true" : undefined}
          aria-label="Reduce Blast Radius"
          aria-haspopup="menu"
          onClick={() =>
            handleMenuToggle(
              blastRadiusAnchorRef,
              AssetSecurityCategory.OUTBOUND
            )
          }
          endIcon={<ArrowDropDownIcon />}
        >
          {window.getCTTranslatedText("Blast Radius")}
        </Button>
      </ButtonGroup>
      <Popper
        key="attack-surface-menu"
        sx={{
          zIndex: 1,
          minWidth: "230px",
        }}
        open={openAttackSurfaceMenu}
        anchorEl={attackSurfaceAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            {popperMenuContent(
              "attack-surface-menu",
              AttackSurfaceStatusOptionsList
            )}
          </Grow>
        )}
      </Popper>
      <Popper
        key="blast-radius-menu"
        sx={{
          zIndex: 1,
          minWidth: "230px",
        }}
        open={openBlastRadiusMenu}
        anchorEl={blastRadiusAnchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            {popperMenuContent(
              "blast-radius-menu",
              BlastRadiusStatusOptionsList
            )}
          </Grow>
        )}
      </Popper>
    </Stack>
  );
  return (
    <GridToolbar {...props}>
      {props.selectedData && props.selectedData.length > 0 && (
        <Stack
          direction="row"
          spacing={2}
          sx={{ width: "100%" }}
          justifyItems="flex-end"
          alignItems={"center"}
          justifyContent="flex-end"
        >
          {showAssignTagsDialog && (
            <MetadataEdit
              updateAssetMetadata={assignTags}
              handleAllowEdit={handleAssignTagsDialogOpen}
              allowEdit={showAssignTagsDialog}
              assetIds={props.selectedData.map(asset => asset.assetId)}
              mode={"bulk"}
            />
          )}

          {showAssignNetworkDialog && (
            <ManageAssetNetworks
              criteria={`assetId in (${(props.selectedData ?? [])
                .map(asset => `'${asset.assetId}'`)
                .join(",")})`}
              multiselect={true}
              open={showAssignNetworkDialog}
              onClose={handleClose}
              onConfirm={assignNetwork}
            />
          )}

          {showAddToTemplateDialog && (
            <ManageAssetTemplates
              criteria={`assetId in (${(props.selectedData ?? [])
                .map(asset => `'${asset.assetId}'`)
                .join(",")})`}
              multiselect={true}
              open={showAddToTemplateDialog}
              onClose={handleClose}
              onConfirm={addToTemplate}
            />
          )}

          {showUnmanageDialog && props.showDeviceActions && (
            <AssetUnmanage
              criteria={(props.selectedData ?? []).map(asset => asset.assetId)}
              onClose={() => {
                handleClose();
              }}
            />
          )}

          {userPermissions.has("UPDATE_ASSET") && actions}

          {userPermissions.has("UPDATE_ASSET") && (
            <MoreOptionsMenu menuOptions={moreActionMenuOptions} />
          )}
        </Stack>
      )}

      {changeStatus && (
        <StatusChangeConfirmation
          isOpen={
            Boolean(changeStatus) &&
            (Boolean(changeStatusType) || Boolean(synchronizePolicy))
          }
          type={changeStatus}
          subType={changeStatusType}
          assets={props.selectedData}
          onClose={() => {
            setChangeStatus(undefined);
            props.hideToolbar();
          }}
          synchronizePolicy={synchronizePolicy}
        />
      )}
    </GridToolbar>
  );
}

interface StatusChangeConfirmationProps {
  isOpen: boolean;
  type: AssetSecurityCategory;
  subType?: AssetStatus;
  assets: Array<Asset>;
  onClose: () => void;
  synchronizePolicy?: boolean;
}

function StatusChangeConfirmation({
  isOpen,
  type,
  subType,
  assets: selectedAssets,
  onClose,
  synchronizePolicy = false,
}: StatusChangeConfirmationProps) {
  const { setDebouncedSnackbar } = useDebouncedSnackbar();
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const [selectedInbound, setSelectedInbound] = useState<
    AssetStatus | undefined
  >(undefined);
  const [selectedOutbound, setSelectedOutbound] = useState<
    AssetStatus | undefined
  >(undefined);

  const [comment, setComment] = useState<string | undefined>(undefined);

  const warnings = useStatusWarning(selectedInbound, selectedOutbound);
  const requestAPIRefresh = useAssetStore(store => store.requestAPIRefresh);
  const updateMutation = useZeroTrustAPI();
  const synchronizePolicyMutation = useSynchronizePolicy();

  const isLoading =
    updateMutation.isLoading || synchronizePolicyMutation.isLoading;

  const [includeUnreviewed, setIncludeUnReviewed] = useState(true);
  const [
    minimumEnforcementLevelAssetsCount,
    setMinimumEnforcementLevelAssetsCount,
  ] = useState(0);
  const [selection, setSelection] = useState<Array<GridRowId>>([]);

  let { unreviewed, reviewed } = useMemo(() => {
    let unreviewed: Array<Asset> = [],
      reviewed: Array<Asset> = [];

    if (!selectedAssets.length) {
      return { unreviewed, reviewed };
    }

    selectedAssets.forEach(asset => {
      let unreviewedCount: number =
        asset.unreviewedPorts + asset.unreviewedPaths;

      if (unreviewedCount !== undefined && unreviewedCount > 0) {
        includeUnreviewed && unreviewed.push(asset);
      } else {
        reviewed.push(asset);
      }
    });

    return { unreviewed, reviewed };
  }, [selectedAssets, includeUnreviewed]);

  const updateSelections = (selectedAssetIds: GridRowId[]) => {
    if (selectedAssetIds && selectedAssetIds?.length) {
      setSelection(selectedAssetIds);
    } else {
      setSelection([]);
    }
  };

  const assets = useMemo(() => {
    return [...unreviewed, ...reviewed];
  }, [unreviewed, reviewed]);

  const getMinimumEnforcementLevelAssetsCount = (
    subType: AssetStatus,
    selectedAssets: Asset[],
    direction: AssetSecurityCategory
  ) => {
    const filterFn = (asset: Asset) => {
      const lowestAssetStatus =
        direction === AssetSecurityCategory.INBOUND
          ? asset.lowestInboundAssetStatus
          : asset.lowestOutboundAssetStatus;

      return (
        (lowestAssetStatus?.length ?? 0) > 0 &&
        AssetStatusMap[lowestAssetStatus as AssetStatus] >
          AssetStatusMap[subType]
      );
    };
    return selectedAssets.filter(filterFn).length;
  };

  useEffect(() => {
    if (type === AssetSecurityCategory.INBOUND && subType) {
      setSelectedInbound(subType as AssetStatus);
      setMinimumEnforcementLevelAssetsCount(
        getMinimumEnforcementLevelAssetsCount(
          subType,
          selectedAssets,
          AssetSecurityCategory.INBOUND
        )
      );
    } else if (type === AssetSecurityCategory.OUTBOUND && subType) {
      setSelectedOutbound(subType as AssetStatus);
      getMinimumEnforcementLevelAssetsCount(
        subType,
        selectedAssets,
        AssetSecurityCategory.OUTBOUND
      );
    }
  }, [type, subType, selectedAssets]);

  useEffect(() => {
    if (selectedAssets && selectedAssets?.length) {
      let selectedAssetIds: GridRowId[] = [];
      if (type === AssetSecurityCategory.INBOUND && subType) {
        selectedAssetIds = selectedAssets
          .filter((asset: Asset) => {
            if (!subType) {
              return true;
            }
            return (asset?.lowestInboundAssetStatus?.length ?? 0) > 0
              ? AssetStatusMap[asset.lowestInboundAssetStatus as AssetStatus] <=
                  AssetStatusMap[subType]
              : true;
          })
          .map(asset => asset.assetId);
      } else if (type === AssetSecurityCategory.OUTBOUND && subType) {
        selectedAssetIds = selectedAssets
          .filter((asset: Asset) => {
            if (!subType) {
              return true;
            }
            return (asset?.lowestOutboundAssetStatus?.length ?? 0) > 0
              ? AssetStatusMap[
                  asset.lowestOutboundAssetStatus as AssetStatus
                ] <= AssetStatusMap[subType]
              : true;
          })
          .map(asset => asset.assetId);
      } else {
        selectedAssetIds = selectedAssets.map((asset: Asset) => asset.assetId);
      }

      setSelection(selectedAssetIds);
    }
  }, [selectedAssets, subType, type]);

  const onConfirm = (
    selectedInbound: AssetStatus | undefined,
    selectedOutbound: AssetStatus | undefined,
    assetIds: GridRowId[]
  ) => {
    assetIds = assetIds.filter((assetId: GridRowId) => {
      return selection.indexOf(assetId) !== -1;
    });

    const criteria = `assetId in (${assetIds.map(id => `'${id}'`).join(",")})`;

    if (synchronizePolicy) {
      // synchronize policy with firewall
      SynchronizeAssetPolicy({ type, criteria });
    } else {
      updateAssetStatus({ type, criteria, selectedInbound, selectedOutbound });
    }
  };

  const updateAssetStatus = async ({
    type,
    criteria,
    selectedInbound,
    selectedOutbound,
  }: {
    type: AssetSecurityCategory;
    criteria: string;
    selectedInbound: AssetStatus | undefined;
    selectedOutbound: AssetStatus | undefined;
  }) => {
    setDebouncedSnackbar(
      true,
      SnackBarSeverity.Info,
      window.getCTTranslatedText("debouncedSnackbarText")
    );
    await updateMutation.mutateAsync(
      {
        inboundToState: selectedInbound,
        outboundToState: selectedOutbound,
        criteria: criteria,
        comment: comment,
      },
      {
        onSuccess: () => {
          setDebouncedSnackbar.cancel();
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            "AssetStatusUpdatedSuccessfully",
            {
              direction: window.getCTTranslatedText(
                AssetSecurityCategoryText[type]
              ),
            }
          );
          requestAPIRefresh();
          onClose();
          setComment(undefined);
        },
        onError: (error: Error) => {
          setDebouncedSnackbar.cancel();
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      }
    );
  };

  interface syncPolicyInt {
    criteria: string;
    attackSurface?: boolean;
    blastRadius?: boolean;
    comment?: string;
  }

  interface SynchronizeAssetPolicyInt {
    type: AssetSecurityCategory;
    criteria: string;
  }

  const SynchronizeAssetPolicy = async ({
    type,
    criteria,
  }: SynchronizeAssetPolicyInt) => {
    const body: syncPolicyInt = {
      criteria: criteria,
      comment: comment,
    };

    if (type === AssetSecurityCategory.INBOUND) {
      body["attackSurface"] = true;
    } else {
      body["blastRadius"] = true;
    }

    setDebouncedSnackbar(
      true,
      SnackBarSeverity.Info,
      window.getCTTranslatedText("debouncedSnackbarText")
    );

    await synchronizePolicyMutation.mutateAsync(body, {
      onSuccess: response => {
        setDebouncedSnackbar.cancel();
        setSnackbar(
          true,
          SnackBarSeverity.Success,
          "AssetStatusSynchronizedSuccessfully",
          {
            direction: window.getCTTranslatedText(
              AssetSecurityCategoryText[type]
            ),
          }
        );
        requestAPIRefresh();
        onClose();
        setComment(undefined);
      },
      onError: error => {
        setDebouncedSnackbar.cancel();
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const removeUnreviewed = () => {
    setIncludeUnReviewed(false);
  };

  function getActionButtonText(
    synchronizePolicy: boolean,
    subType?: AssetStatus
  ) {
    if (synchronizePolicy) {
      return "Synchronize";
    }

    if (subType === AssetStatus.Unsecured) {
      return "Unsecure";
    } else {
      return "Secure";
    }
  }

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: {
          padding: "0px",
          width: "80%",
          minWidth: "1000px",
          height: "100%",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack direction="row" spacing={2} sx={{ mt: 5, mx: 4 }}>
        {synchronizePolicy && (
          <>
            <Typography variant="h5">
              <b>
                {window.getCTTranslatedText("SynchronizeStatusWithFirewall", {
                  direction: window.getCTTranslatedText(
                    type === AssetSecurityCategory.INBOUND
                      ? AssetSecurityCategoryText[AssetSecurityCategory.INBOUND]
                      : AssetSecurityCategoryText[
                          AssetSecurityCategory.OUTBOUND
                        ]
                  ),
                })}
              </b>
            </Typography>
          </>
        )}
        {!synchronizePolicy && (
          <>
            <Typography variant="h5">
              <b>
                {window.getCTTranslatedText(
                  "ChangeAssetSecurityCategoryStatus",
                  {
                    assetSecurityCategory: window.getCTTranslatedText(
                      type === AssetSecurityCategory.INBOUND
                        ? AssetSecurityCategoryText[
                            AssetSecurityCategory.INBOUND
                          ]
                        : AssetSecurityCategoryText[
                            AssetSecurityCategory.OUTBOUND
                          ]
                    ),
                  }
                )}
              </b>
              :
            </Typography>
            {type === AssetSecurityCategory.INBOUND && (
              <Typography variant="h5">
                {!!subType &&
                  window.getCTTranslatedText(
                    AttackSurfaceStatusOptionsText[subType]
                  )}
              </Typography>
            )}
            {
              <Typography variant="h5">
                {!!subType &&
                  type === AssetSecurityCategory.OUTBOUND &&
                  window.getCTTranslatedText(
                    BlastRadiusStatusOptionsText[subType]
                  )}
              </Typography>
            }
          </>
        )}
      </Stack>

      <DialogContent sx={{ minWidth: 1050, flex: 1 }}>
        <Stack sx={{ width: "100%", height: "100%" }}>
          {!synchronizePolicy && (
            <>
              <Stack sx={{ mb: 2, width: "100%" }} spacing={1}>
                {warnings.inboundWarning?.length > 0 && (
                  <Alert severity="warning">
                    {window.getCTTranslatedText(warnings.inboundWarning)}
                  </Alert>
                )}

                {warnings.outboundWarning?.length > 0 && (
                  <Alert severity="warning">
                    {window.getCTTranslatedText(warnings.outboundWarning)}
                  </Alert>
                )}
              </Stack>

              {unreviewed.length > 0 && (
                <Alert
                  sx={{ mb: 2 }}
                  severity="error"
                  action={
                    <Button color="error" onClick={removeUnreviewed}>
                      {window.getCTTranslatedText("Remove Unreviewed")}
                    </Button>
                  }
                >
                  {window.getCTTranslatedText("RemoveUnreviewedAlertText", {
                    count: unreviewed.length,
                    asset: window.getCTTranslatedText(
                      pluralize("asset", minimumEnforcementLevelAssetsCount)
                    ),
                  })}
                </Alert>
              )}
              {minimumEnforcementLevelAssetsCount > 0 && (
                <Alert sx={{ mb: 2 }} severity="error">
                  {window.getCTTranslatedText(
                    "minimumEnforcementLevelAlertText",
                    {
                      minimumEnforcementLevelAssetsCount,
                      asset: window.getCTTranslatedText(
                        pluralize("asset", minimumEnforcementLevelAssetsCount)
                      ),
                      subType: window.getCTTranslatedText(subType ?? ""),
                    }
                  )}
                </Alert>
              )}
            </>
          )}

          {type !== undefined && (
            <Stack
              sx={{
                flex: 1,
                width: "100%",
                overflow: "hidden",
              }}
            >
              <AssetStatusChangeDataGrid
                selection={selection}
                updateSelections={updateSelections}
                changingStatus={type}
                newStatus={selectedInbound || selectedOutbound}
                rows={assets}
                rowCount={assets?.length || 0}
                rowHeight={64}
                getRowId={({ assetId }: Asset) => assetId}
                subType={subType}
              />
            </Stack>
          )}
          <Annotation setComment={setComment} comment={comment} />
        </Stack>
      </DialogContent>
      <ToolbarAction
        loading={isLoading}
        isValid={
          (selectedOutbound !== undefined ||
            selectedInbound !== undefined ||
            synchronizePolicy) &&
          selection?.length > 0
        }
        actionBtnText={getActionButtonText(synchronizePolicy, subType)}
        save={() =>
          onConfirm(
            selectedInbound,
            selectedOutbound,
            assets.map(asset => asset.assetId)
          )
        }
        hasPermission={userPermissions.has("UPDATE_ASSET")}
        cancel={onClose}
      />
    </Drawer>
  );
}

function useStatusWarning(
  selectedInbound?: AssetStatus,
  selectedOutbound?: AssetStatus
) {
  let inboundWarning = "",
    outboundWarning = "";

  // TODO: review string
  if (selectedInbound === AssetStatus.Unsecured) {
    inboundWarning = window.getCTTranslatedText(
      "Inbound internet and intranet traffic will become unrestricted after this change"
    );
  } else if (selectedInbound === AssetStatus.SecureInternet) {
    inboundWarning = window.getCTTranslatedText(
      "All unreviewed and new inbound ports will be restricted to intranet after this change"
    );
  } else if (selectedInbound === AssetStatus.SecureAll) {
    inboundWarning = window.getCTTranslatedText(
      "All unreviewed and new inbound intranet ports will be marked as denied after this change"
    );
  }

  if (selectedOutbound === AssetStatus.Unsecured) {
    outboundWarning = window.getCTTranslatedText(
      "Outbound internet and intranet traffic will become unrestricted after this change"
    );
  } else if (selectedOutbound === AssetStatus.SecureInternet) {
    outboundWarning = window.getCTTranslatedText(
      "All unreviewed and new outbound internet paths will be marked as denied after this change"
    );
  } else if (selectedOutbound === AssetStatus.SecureAll) {
    outboundWarning = window.getCTTranslatedText(
      "All unreviewed paths will be marked as denied after this change"
    );
  }

  return { inboundWarning, outboundWarning };
}

interface AssetStatusChangeDataGridProps extends DataGridProps<Asset> {
  changingStatus: "inbound" | "outbound";
  newStatus: AssetStatus | AssetStatus | undefined;
  selection: GridRowId[];
  updateSelections: (selectionModel: GridRowId[]) => void;
  subType?: AssetStatus;
}

function AssetStatusChangeDataGrid(props: AssetStatusChangeDataGridProps) {
  const metadata = useCommonStore(state => state.metadata);
  const cols = useMemo(() => {
    let newCols = [...ASSET_STATUS_CHANGE_COLS];

    if (props.changingStatus === "inbound") {
      newCols.push({
        field: "inboundStatusBefore",
        headerName: "Attack Surface Status",
        headerAlign: "left",
        align: "left",
        width: 150,
        minWidth: 150,
        disableReorder: true,
        resizable: false,
        sortable: false,
        flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
        renderCell: (params: GridRenderCellParams<any, any, any>) => {
          return (
            <Typography variant="body2">
              {
                AttackSurfaceStatusOptionsText[
                  params.row.inboundAssetStatus as AssetStatus
                ]
              }
            </Typography>
          );
        },
      });
    } else if (props.changingStatus === "outbound") {
      newCols.push({
        field: "outboundStatusBefore",
        headerName: "Blast Radius Status",
        headerAlign: "left",
        align: "left",
        width: 150,
        minWidth: 150,
        disableReorder: true,
        resizable: false,
        sortable: false,
        flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
        renderCell: (params: GridRenderCellParams<any, any, any>) => {
          return (
            <Typography variant="body2">
              {
                BlastRadiusStatusOptionsText[
                  params.row.outboundAssetStatus as AssetStatus
                ]
              }
            </Typography>
          );
        },
      });
    }
    return newCols;
  }, [props.changingStatus]);

  return (
    <DataGrid<Asset>
      checkboxSelection
      rowSelectionModel={props?.selection || []}
      onRowSelectionModelChange={selectionModel => {
        props.updateSelections(selectionModel);
      }}
      slots={{
        footer: () => null,
      }}
      rows={props.rows}
      isRowSelectable={rowParams => {
        if (!props.subType) {
          return true;
        }
        if (
          rowParams.row?.lowestInboundAssetStatus?.length > 0 ||
          rowParams.row?.lowestOutboundAssetStatus?.length > 0
        ) {
          return (
            AssetStatusMap[
              props.changingStatus === AssetSecurityCategory.INBOUND
                ? (rowParams.row.lowestInboundAssetStatus as AssetStatus)
                : (rowParams.row.lowestOutboundAssetStatus as AssetStatus)
            ] < AssetStatusMap[props.subType as AssetStatus]
          );
        } else {
          return true;
        }
      }}
      rowCount={props.rowCount}
      rowHeight={64}
      getRowClassName={params => {
        let asset = params.row as Asset;
        if (asset.unreviewedPorts > 0 || asset.unreviewedPaths > 0) {
          return "unreviewed";
        }
        return "";
      }}
      columns={cols}
      getRowId={({ assetId }: Asset) => assetId}
      metadata={metadata}
    />
  );
}

