import { Agent } from "pages/agents/types";
import { useMemo, useState, useEffect } from "react";
import { agent_types, platforms } from "../../agent-download-page/constants";

export interface AgentGroupByObj {
  [key: string]: {
    data: Array<Agent>;
    version?: string;
    architecture?: string;
  };
}

export function useGroupByAgentData(updatedData: Agent[]) {
  const [agentsGroupByPlatforms, setAgentsGroupByPlatforms] =
    useState<AgentGroupByObj>();

  const agentsGroupByPlatformsData = useMemo(() => {
    let groupByObj: AgentGroupByObj = {};

    updatedData.forEach((agent: Agent) => {
      if (agent.isUpgradeEnabled && agent?.asset?.assetId) {
        let platformKey = agent.platform;

        if (
          agent.agentType === agent_types.CT_LEGACY_AGENT &&
          agent.platform === platforms.RPM
        ) {
          platformKey = platforms.RPM_LEGACY;
        }

        if (!groupByObj[platformKey]) {
          groupByObj[platformKey] = {
            data: [agent],
            version: undefined,
            architecture: undefined,
          };
        } else {
          groupByObj[platformKey].data.push(agent);
        }
      }
    });

    return groupByObj;
  }, [updatedData]);

  useEffect(() => {
    if (agentsGroupByPlatformsData) {
      setAgentsGroupByPlatforms(agentsGroupByPlatformsData);
    }
  }, [agentsGroupByPlatformsData]);

  return { agentsGroupByPlatforms, setAgentsGroupByPlatforms };
}
