import { useTheme } from "@mui/material";

export const CrowdStrikeIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4381_51713)">
        <path
          d="M40.0705 32.4955C39.301 32.4198 37.9377 32.2286 36.2319 33.0893C34.5261 33.95 33.8567 33.9855 33.0183 33.8966C33.2651 34.348 33.7633 34.9641 35.3312 35.0753C36.8991 35.1865 37.6486 35.2332 36.8235 37.1859C36.8435 36.5965 36.7034 35.4556 35.1488 35.6557C33.592 35.8559 33.2295 37.257 34.8975 37.9554C34.3549 38.0643 33.2051 38.131 32.3844 35.9805C31.8151 36.2273 30.9366 36.7233 29.3442 35.4956C29.7149 35.6291 30.1286 35.7002 30.5852 35.7091C29.173 35.0353 27.823 33.7809 26.9601 32.5955C27.6451 33.1071 28.4013 33.6141 29.1663 33.7098C28.2611 32.6089 26.1728 30.4027 23.6152 28.1387C25.2588 29.2129 27.2426 30.9075 30.4896 30.5272C33.7366 30.1447 35.9183 29.4019 40.0705 32.4955Z"
          fill="#FF0000"
        />
        <path
          d="M26.173 31.7305C24.1381 30.8476 23.7022 30.6719 21.0845 30.0069C18.4669 29.3419 15.8915 27.9564 14.1702 25.7969C15.3845 26.6865 17.862 28.4746 20.4084 28.2811C20.0215 27.714 19.3076 27.2736 18.4558 26.8288C19.4188 27.0601 22.33 27.8051 26.173 31.7305Z"
          fill="#FF0000"
        />
        <path
          d="M14.5215 18.7109C7.79167 14.5053 6.45505 11.265 6.15259 10.0195C10.3804 14.6432 13.5918 16.4535 15.778 17.8991C20.2705 20.7636 21.2179 22.6896 21.7427 24.1952C19.5877 21.1528 16.7343 20.221 14.5215 18.7109Z"
          fill="#FF0000"
        />
        <path
          d="M15.3577 22.0442C9.1995 18.7883 7.76948 16.1817 7.48926 14.9941C10.5717 18.2812 13.9989 20.0115 16.0071 21.1346C20.3572 23.4675 21.467 25.1044 22.0186 26.3787C19.9458 23.8634 17.0635 22.9827 15.3577 22.0442Z"
          fill="#FF0000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.2891 26.2611C34.7175 27.6199 35.3758 27.7712 35.6427 27.8735C36.9971 28.3916 40.58 29.0566 40.3553 30.5712C40.7734 30.9893 42.3213 32.655 41.6742 33.8115C41.1182 32.9041 38.5628 30.5489 35.4715 30.0641C32.3801 29.5793 29.5623 30.6245 27.2338 29.0789C25.2567 27.6555 24.9387 27.1173 22.9482 23.8569C22.0541 22.3936 21.8807 21.0614 18.8449 18.5839C15.8092 16.1063 12.9692 14.7853 11.7771 11.0957C14.3858 14.3027 17.7596 17.3651 26.2397 19.7915C36.8614 22.7538 36.0586 24.384 35.2891 26.2611ZM40.024 30.9114C40.0062 30.3243 40.0862 29.984 38.4783 29.7394C39.0521 30.0226 39.5673 30.4133 40.024 30.9114Z"
          fill="#FF0000"
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        stroke={theme.palette.text.primary}
        strokeOpacity="0.12"
      />
      <defs>
        <clipPath id="clip0_4381_51713">
          <rect
            width="35.695"
            height="28"
            fill="white"
            transform="translate(6.15259 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
