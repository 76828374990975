import { useQueryClient } from "@tanstack/react-query";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAssetDevicesStore } from "pages/assets/store";
import { useAssetUnmanage } from "../asset-data-grid-toolbar/hooks";
import { CTConfirmation } from "common/atoms/ct-confirmation";

export interface AssetUnmanageProps {
  assetIDList: string[];
}

interface AssetUnmanageDialogProps {
  criteria: string[];
  onClose: VoidFunction;
}

export function AssetUnmanage({
  criteria,
  onClose,
}: Readonly<AssetUnmanageDialogProps>) {
  const unmanageMutation = useAssetUnmanage();
  const notify = useEventSubscriptionStore(state => state.notify);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const refreshRequest = useAssetDevicesStore(state => state.requestAPIRefresh);
  const queryClient = useQueryClient();

  const unmanageAssets = () => {
    let body: AssetUnmanageProps = {
      assetIDList: criteria,
    };
    unmanageMutation.mutate(body, {
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: ["asset-unmanage"],
        });
        notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
          label: "assetsDeletedSuccessfully",
        });
        onClose();
        refreshRequest();
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        onClose();
      },
    });
  };

  return (
    <CTConfirmation
      open={true}
      onClose={() => onClose()}
      title={window.getCTTranslatedText("deleteAssets")}
      primaryText={window.getCTTranslatedText("assetDeleteConfirmation")}
      primaryButtonText={window.getCTTranslatedText("Delete")}
      isLoading={unmanageMutation.isLoading}
      onSuccess={unmanageAssets}
    />
  );
}
