import { Box, Grid, Link, Stack, Tooltip, Typography } from "@mui/material";
import { AssetColumnRenderProps } from "pages/assets/types";
import { Link as RouterLink } from "react-router-dom";
import { useState } from "react";
import { VulnerabilityDrawer } from "modules/vulnerability-drawer";

export function AssetVulnerabilities({
  asset,
  viewOnly,
}: AssetColumnRenderProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  if (!asset.assetId) {
    return <Box style={{ width: 150 }} />;
  }

  let missingValue = false;
  if (asset.vulnerabilities === null || asset.vulnerabilities === undefined) {
    missingValue = true;
  }
  const isClickable = !viewOnly && asset.vulnerabilities;

  return (
    <>
      <Stack alignItems="center" justifyContent="center">
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          <Tooltip
            title={window.getCTTranslatedText(
              isClickable ? "View vulnerabilities" : ""
            )}
          >
            {!isClickable ? (
              <Typography variant="body2" sx={{ color: "warning.main" }}>
                {missingValue ? "" : asset?.vulnerabilities}
              </Typography>
            ) : (
              <Link
                typography={"body2"}
                component={RouterLink}
                to={"#"}
                onClick={() => setIsDrawerOpen(true)}
                sx={{ color: "warning.main" }}
              >
                {missingValue ? "" : asset?.vulnerabilities}
              </Link>
            )}
          </Tooltip>
        </Grid>
      </Stack>
      {isDrawerOpen && (
        <VulnerabilityDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          assetId={asset.assetId || ""}
          assetName={asset.assetName || ""}
        />
      )}
    </>
  );
}
