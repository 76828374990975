import { Paper, Stack, useTheme } from "@mui/material";
import { FACET_ORDER } from "pages/templates/constants";
import { useUserDepartmentStore } from "./store";
import { Scope } from "modules/scope-metadata/types";
import { useCore } from "modules/core";
import { UserDepartment } from "./types";
import { useUserDepartmentAPI } from "./components/userDepartments-data-grid/hooks";
import { UserDepartmentDataGrid } from "./components/userDepartments-data-grid";

export const UserDepartments = () => {
  const theme = useTheme();

  const coreResponse = useCore<UserDepartment>({
    useStore: useUserDepartmentStore,
    facetGroupInfo: FACET_ORDER,
    scope: Scope.SCIM_USER_DEPARTMENT,
    useApi: useUserDepartmentAPI,
    pageSize: 10,
  });

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      {/* <Stack direction={"row"} alignItems="center">
        <Stack sx={{ flex: 1 }}>
        <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              config={coreResponse?.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack> */}

      <Paper
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          flex: 1,
          overflow: "hidden",
        }}
      >
        <UserDepartmentDataGrid
          rows={coreResponse.rows}
          rowCount={coreResponse.rowCount}
          mutation={coreResponse.mutation}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={coreResponse.onSortChange}
        />
      </Paper>
    </Stack>
  );
};
