import { Link, Stack, Typography } from "@mui/material";
import { CopyButton } from "common/atoms/copy-button";
import { API_URL } from "hooks/useNetworking/constants";
import { FieldsOption } from "modules/form-fields/type";
import { INTEGRATION_PROVIDERS } from "pages/Integration/constants";
import React from "react";

interface FormExtrasAfterProps {
  selectedValue: FieldsOption | null;
}

export const FormExtrasAfter = ({ selectedValue }: FormExtrasAfterProps) => {
  const redirectURLvalue =
    selectedValue?.integrationProvider === INTEGRATION_PROVIDERS.OAUTH
      ? `${window.location.origin}/api/auth/callback-oauth`
      : `${window.location.origin}/api/auth/callback-saml`;

  return (
    <Stack mt={6.5}>
      <Stack>
        <Typography variant="subtitle2">
          {window.getCTTranslatedText("Audience URL (Entity Id)")}
        </Typography>

        <Stack direction={"row"} spacing={1} alignItems="center">
          <Typography
            sx={{
              overflow: "hidden",
              maxHeight: 20,
              lineBreak: "anywhere",
            }}
            variant="body2"
          >
            <code>{window.location.origin}</code>
          </Typography>
          <CopyButton text={window.location.origin} />
        </Stack>
      </Stack>
      <Stack mt={4}>
        <Typography variant="subtitle2">
          {window.getCTTranslatedText("SSO URL/Redirect URL")}
        </Typography>

        <Stack direction={"row"} spacing={1} alignItems="center">
          <Typography
            sx={{
              overflow: "hidden",
              maxHeight: 20,
              lineBreak: "anywhere",
            }}
            variant="body2"
          >
            <code>{redirectURLvalue}</code>
          </Typography>
          <CopyButton text={redirectURLvalue} />
        </Stack>
      </Stack>

      {selectedValue?.integrationProvider === INTEGRATION_PROVIDERS.SAML && (
        <Stack mt={4}>
          <Link
            href={`${API_URL}/auth/saml/metadata`}
            target="_blank"
            rel="noopener noreferrer"
            variant="subtitle2"
          >
            {window.getCTTranslatedText("Download Metadata")}
          </Link>
        </Stack>
      )}
    </Stack>
  );
};
