import { Box } from "@mui/material";
import { AnnotationDrawer } from "modules/annotation-drawer";
import { EntityType } from "modules/annotation-drawer/type";
import { AnnotationCount } from "modules/annotation/components/annotation-count";
import { useNetworkStore } from "pages/networks/store";
import { NetworkColumnRenderProps } from "pages/networks/types";
import { useState } from "react";

export function NetworkAnnotations({
  network,
  viewOnly,
}: NetworkColumnRenderProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = (event: any) => {
    if (event) {
      event.stopPropagation();
    }
    setIsDrawerOpen(true);
  };

  if (!network.namedNetworkId) {
    return <Box style={{ width: 70 }} />;
  }

  return (
    <>
      <AnnotationCount
        count={network.totalComments}
        clickCallback={handleDrawerOpen}
      />
      {isDrawerOpen && (
        <AnnotationDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          entity={EntityType.NamedNetworks}
          entityId={network.namedNetworkId}
          useStore={useNetworkStore}
        />
      )}
    </>
  );
}
