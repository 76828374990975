import { Stack, Tooltip } from "@mui/material";
import { useCommonStore } from "common/store";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { AuditLogColumnRenderProps } from "pages/monitoring/types";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export const AuditCategory = ({
  audit,
  viewOnly,
}: AuditLogColumnRenderProps) => {
  const facetState = useCommonStore(state => state.facets);
  const categoryFacetName = "eventcategory";
  const categoryOptionName = audit?.eventCategory?.toString() || "";
  const isCategorySelected =
    !viewOnly &&
    facetState?.get(categoryFacetName)?.get(categoryOptionName)?.isSelected;

  return (
    <Stack
      sx={{
        height: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <Tooltip
        title={`${window.getCTTranslatedText(
          "Type"
        )}: ${window.getCTTranslatedText(audit.eventCategory)}`}
      >
        <CTDatagridButton
          sx={{
            p: isCategorySelected ? undefined : 0,
            alignItems: "center",
            display: "flex",
          }}
          asText={viewOnly}
          isDisabled={viewOnly}
          facetName={categoryFacetName}
          facetOption={categoryOptionName}
          stopPropagation={true}
        >
          {audit.eventCategory?.toLowerCase() === "event" ? (
            <EventNoteIcon fontSize="small" />
          ) : (
            <AssignmentIndOutlinedIcon fontSize="small" />
          )}
        </CTDatagridButton>
      </Tooltip>
    </Stack>
  );
};
