import { Paper, Stack } from "@mui/material";
import { AssetMetaDataProps } from "pages/assets/types";
import { useState } from "react";
import { MetadataEdit } from "./components/metadata-edit";
import { MetadataReadonly } from "./components/metadata-readonly";
import { MetadataView } from "./components/metadata-view";

export function AssetMetaDataWrapper({
  asset,
  updateAssetMetadata,
}: AssetMetaDataProps) {
  const [allowEdit, setAllowEdit] = useState(false);
  const [viewMore, setViewMore] = useState(false);

  const handleAllowEdit = (value: boolean) => {
    setAllowEdit(value);
  };

  const handleViewMore = (value: boolean) => {
    setViewMore(value);
  };

  return (
    <>
      <Paper sx={{ width: "100%" }}>
        <Stack
          justifyItems={"flex-start"}
          sx={{
            padding: 0,
            width: "100%",
          }}
        >
          <MetadataReadonly
            asset={asset}
            updateAssetMetadata={updateAssetMetadata}
            handleAllowEdit={handleAllowEdit}
            allowEdit={allowEdit}
            handleViewMore={handleViewMore}
          />
          {allowEdit && (
            <MetadataEdit
              asset={asset}
              updateAssetMetadata={updateAssetMetadata}
              handleAllowEdit={handleAllowEdit}
              allowEdit={allowEdit}
            />
          )}
          {viewMore && (
            <MetadataView
              asset={asset}
              updateAssetMetadata={updateAssetMetadata}
              handleViewMore={handleViewMore}
              viewMore={viewMore}
            />
          )}
        </Stack>
      </Paper>
    </>
  );
}
