import { Box, Stack, Tooltip } from "@mui/material";
import { GridGroupNode, useGridApiContext } from "@mui/x-data-grid-pro";
import { ContainerColumnRenderProps } from "pages/containers/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function ClusterIdentifier({
  agent,
  viewOnly,
}: Readonly<ContainerColumnRenderProps>) {
  const apiRef = useGridApiContext();
  const rowNode = apiRef.current.getRowNode(agent.agentId) as GridGroupNode;
  const childrenCount = rowNode?.children?.length ?? 0;

  if (!agent?.agentId) {
    return <Box style={{ minWidth: 120 }} />;
  }

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <Tooltip title={agent?.clusterIdentifier ?? ""}>
        <CTDatagridButton
          asText={viewOnly === true}
          isDisabled={viewOnly === true}
          facetName={"clusteridentifier"}
          facetOption={agent.clusterIdentifier}
        >
          {agent?.clusterIdentifier || ""}
          {childrenCount > 0 && `(${childrenCount})`}
        </CTDatagridButton>
      </Tooltip>
    </Stack>
  );
}
