import { DataGrid } from "modules/data-grid/components/data-grid";
import {
  ACTION_COLUMN,
  ApplianceColumnConfig,
  BASE_APPLIANCE_COLUMNS,
  GROUP_COLUMN,
  LOG_COLUMN,
} from "./constants";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Stack, useTheme } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import {
  DataGridProProps,
  GridGroupNode,
  GridRenderCellParams,
  GridRow,
  GridRowId,
  gridFilteredDescendantCountLookupSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import difference from "lodash/difference";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { Appliance } from "pages/appliances/types";
import { useMemo, useState } from "react";
import { ApplianceToolbar } from "../appliance-data-grid-toolbar";

const CustomRow = (props: React.ComponentProps<typeof GridRow>) => {
  const theme = useTheme();
  const apiRef = useGridApiContext();
  const rowNode = apiRef.current.getRowNode(props?.rowId) as GridGroupNode;
  const childrenCount = rowNode?.children?.length ?? 0;

  const backgroundColorChild = theme.palette.custom.rowBackground;
  const textColorChild = theme.palette.text.secondary;
  const handleRowClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Check if the row has children and toggle the expansion state on click
    if (rowNode && childrenCount > 0) {
      apiRef.current.setRowChildrenExpansion(
        rowNode.id,
        !rowNode.childrenExpanded
      );
      event.stopPropagation();
    }
  };

  return (
    <GridRow
      {...props}
      style={
        (rowNode?.depth ?? 0) > 0
          ? {
              backgroundColor: props?.selected
                ? undefined
                : backgroundColorChild,
              color: textColorChild,
            }
          : undefined
      }
      onClick={handleRowClick}
    />
  );
};

const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
  const { id, field, rowNode: groupNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const rowNode = groupNode as GridGroupNode;
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick: IconButtonProps["onClick"] = event => {
    if (rowNode.children && rowNode.children.length > 0) {
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    }
  };

  return (
    <Box sx={{ ml: rowNode.depth * 4 }}>
      <div>
        {filteredDescendantCount > 0 ? (
          <IconButton onClick={handleClick} tabIndex={-1} size="small">
            {rowNode.childrenExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        ) : (
          <span />
        )}
      </div>
    </Box>
  );
};
export const ApplianceDataGrid = (props: DataGridProps<Appliance>) => {
  const apiRef = useGridApiRef();
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const columns = useMemo(() => {
    let applianceCols = [...BASE_APPLIANCE_COLUMNS];

    if (userPermissions.has("READ_AGENT_LOG")) {
      applianceCols = [...applianceCols, ...LOG_COLUMN];
    }
    if (userPermissions.has("UPDATE_GATEWAY")) {
      applianceCols = [...applianceCols, ...ACTION_COLUMN];
    }
    applianceCols = [...applianceCols, ...GROUP_COLUMN];
    return applianceCols;
  }, [userPermissions]);

  const [selectionModel, setSelectionModel] = useState<Array<GridRowId>>([]);

  const onSelectionModelChange = (newSelectionModel: Array<GridRowId>) => {
    if (newSelectionModel.length === 0) {
      setSelectionModel(newSelectionModel);
    } else if (newSelectionModel.length > selectionModel.length) {
      // when a checkbox is checked
      const newSelectedItem = difference(newSelectionModel, selectionModel);
      const selectedItemChildren = newSelectedItem.flatMap(itemId =>
        apiRef.current.getRowGroupChildren({ groupId: itemId })
      );
      const newSelectionModelSet = new Set([
        ...newSelectionModel,
        ...selectedItemChildren,
      ]);
      setSelectionModel(Array.from(newSelectionModelSet));
    } else if (selectionModel.length > newSelectionModel.length) {
      // when a checkbox is unchecked

      const unselectedItem = difference(selectionModel, newSelectionModel);
      const allUnselectedItems = unselectedItem.flatMap(itemId => [
        ...apiRef.current.getRowGroupChildren({ groupId: itemId }),
        itemId,
      ]);

      const unselectionModel = selectionModel.filter(
        id => id !== unselectedItem[0]
      );
      const newModel = unselectionModel.filter(
        id => !allUnselectedItems.includes(id)
      );

      const newSelectionModelSet = new Set(newModel);
      setSelectionModel(Array.from(newSelectionModelSet));
    }
  };

  let selectedData: Array<Appliance> | undefined = useMemo(() => {
    return props.rows?.filter((row: Appliance) => {
      return selectionModel.indexOf(row?.agentId) !== -1;
    });
  }, [selectionModel, props.rows]);

  let selectedRawData: Array<Appliance> | undefined = useMemo(() => {
    return (props?.rawData ?? [])?.filter((row: Appliance) => {
      return selectionModel.indexOf(row?.agentId) !== -1;
    });
  }, [selectionModel, props.rawData]);

  const onClickClearSelection = () => {
    setSelectionModel([]);
  };

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: "",
    headerClassName: "hideRightSeparator",
    renderCell: params => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = row => {
    return row?.applianceName;
  };

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      <ApplianceToolbar
        hideToolbar={onClickClearSelection}
        show={true}
        selectedData={selectedData}
        selection={selectionModel}
      />
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <DataGrid
          treeData
          defaultPinnedColumns={ApplianceColumnConfig.PinnedColumns}
          groupingColDef={groupingColDef}
          getTreeDataPath={getTreeDataPath}
          slots={{
            row: CustomRow,
          }}
          checkboxSelection={userPermissions.has("UPDATE_AGENT")}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={onSelectionModelChange}
          rowHeight={64}
          columns={columns}
          pagination
          getRowId={({ agentId }: Appliance) => `${agentId}`}
          paginationMode="server"
          sortingMode="client" // Client is Needed for grouping children by MUI  for tree data
          apiRef={apiRef}
          {...props}
          selectedRawData={selectedRawData}
        />
      </Box>
    </Stack>
  );
};
