import { LockOutlined } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  DialogContent,
  Drawer,
  Paper,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { CtTabs } from "common/atoms/ct-tabs";
import { Toolbar } from "common/atoms/toolbar";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { BulkAssetStatusControl } from "modules/bulk-asset-status-control";
import {
  CreateTagBasedPolicyDrawer,
  MAX_ACCESS_POLICY_ASSETS,
} from "modules/create-tag-based-policy-drawer";
import {
  useFindTagBasedPolicyId,
  useTBPCriteriaBuilder,
} from "modules/create-tag-based-policy-drawer/hooks";
import { AppliedFacetsChips } from "modules/facets/components/applied-facets-chips";
import { FacetOptionState, FacetState, Operator } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import {
  Direction,
  SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { useSaveTagPolicyAPI } from "pages/create-tag-policy/components/PolicyForm";
import { ProgressiveEnforcementStatus } from "pages/ports/types";
import { NetworkAssignments } from "pages/tags/components/tag-policy-list/components/network-assignments";
import { TemplateAssignments } from "pages/tags/components/tag-policy-list/components/template-assignments";
import { BASE_TAG_POLICY_COLS } from "pages/tags/components/tag-policy-list/constants";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";
import { DEFAULT_HUB_CRITERIA } from "pages/traffic-visualizer/hooks/useVisualizerCriteriaBuilder";
import { useVisxStore } from "pages/traffic-visualizer/store";
import {
  UNKNOWN,
  UNTAGGED,
  createFacetParentJoinUpdates,
  createParentCriteriaJoins,
  getCriteriaForNode,
  getNodeId,
  joinCriterias,
  withHub,
} from "pages/traffic-visualizer/visx-utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PathReviewStatus } from "../../types";
import { TrafficVisualizerDrawerHeader } from "./TrafficVisxDrawerHeader";

export interface TrafficVisualizerDrawerProps {
  hubCriteria?: string;
  assetMetadata?: ScopeMetadata;
}

enum Tabs {
  ENFORCE = 0,
  POLICY = 1,
}

export function TrafficVisualizerDrawer({
  hubCriteria,
  assetMetadata,
}: TrafficVisualizerDrawerProps) {
  const { isFeatureEnabled: isVisxPolicyFlagControlEnabled } =
    useFeatureFlagControl(FEATURES.VISX_POLICY_CONTROLS);
  const { isFeatureEnabled: isSegmentsEnabled } = useFeatureFlagControl(
    FEATURES.SEGMENTS
  );
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const [policy, onFindPolicy] = useState<TagPolicy | undefined>();

  const hasTagUpdatePermissions = userPermissions.has("UPDATE_TAGBASEDPOLICY");
  const [selectedTab, setTab] = useState(Tabs.ENFORCE);

  const [isCreateTagPolicyDrawerOpen, setCreateTagPolicyDrawerOpen] =
    useState(false);
  const [isRingFence, setIsRingFence] = useState(false);

  const selectedNode = useVisxStore(state => state.selectedNode);
  const selectedSourceNodeStatus = useVisxStore(
    state => state.selectedSourceNodeStatus
  );
  const setSelectedSourceNodeStatus = useVisxStore(
    state => state.setSelectedSourceNodeStatus
  );
  const isTestMode = useVisxStore(state => state.isTestMode);
  const setTestMode = useVisxStore(state => state.setTestMode);
  const isDrawerOpen = useVisxStore(state => state.isDrawerOpen);
  const selectedDirection = useVisxStore(state => state.selectedDirection);
  const selectedSecurityStatus = useVisxStore(
    state => state.selectedSecurityStatus
  );
  const selectedPathStatus = useVisxStore(state => state.selectedPathStatus);
  const setIsDrawerOpen = useVisxStore(state => state.setIsDrawerOpen);
  const setSelectedSecurityStatus = useVisxStore(
    state => state.setSelectedSecurityStatus
  );
  const setSelectedPathStatus = useVisxStore(
    state => state.setSelectedPathStatus
  );

  let criteria = hubCriteria || DEFAULT_HUB_CRITERIA;
  if (selectedNode) {
    criteria = getCriteriaForNode(selectedNode?.id, selectedNode.dimension);

    let parentJoins = createParentCriteriaJoins(selectedNode.trafficData);

    if (parentJoins.length) {
      criteria = joinCriterias([criteria, ...parentJoins]);
    }
    criteria = withHub(criteria, hubCriteria);
  }

  const setSelectedNode = useVisxStore(state => state.setSelectedNode);

  const close = () => {
    setIsDrawerOpen(false);
    setSelectedNode(undefined);
    setSelectedSecurityStatus(SecurityStatus.None);
    setSelectedPathStatus(PathReviewStatus.Enforced);
  };

  const toggleDrawerSize = () => setIsDrawerOpen(!isDrawerOpen);

  const createTagPolicyAPI = useSaveTagPolicyAPI();
  const requestedRefreshForTBP = useTagPolicyStore(
    state => state.apiRefreshRequest
  );

  const facets = useSelectedNodeToFacets();
  const { data: tagPolicyMetadata } = useScopeMetadata({
    scope: Scope.TagPolicy,
  });
  const {
    criteria: tbpCriteria,
    criteriaAsParams: tbpCriteriaAsParams,
    exhaustiveCriteria: exhaustiveTBPCriteria,
  } = useTBPCriteriaBuilder(facets, tagPolicyMetadata);

  let isVisxPolicyControlEnabled =
    isVisxPolicyFlagControlEnabled && tbpCriteria !== "*";
  const findTagPolicyAPI = useFindTagBasedPolicyId({
    criteria: !isVisxPolicyControlEnabled ? undefined : exhaustiveTBPCriteria,
    policyId: policy?.tagBasedPolicyId,
  });

  useEffect(() => {
    onFindPolicy(findTagPolicyAPI?.data?.items?.[0]);
  }, [findTagPolicyAPI.data]);

  const refreshTBP = useCallback(() => {
    onFindPolicy(undefined);
  }, []);

  useEffect(() => {
    if (requestedRefreshForTBP && isVisxPolicyControlEnabled) {
      refreshTBP();
    }
  }, [requestedRefreshForTBP, isVisxPolicyControlEnabled, refreshTBP]);

  useEffect(() => {
    refreshTBP();
  }, [facets, refreshTBP]);

  const createSegment = async () => {
    if (!tbpCriteria) {
      return;
    }
    const newTagBasedPolicy = await createTagBasedPolicy({
      criteria: tbpCriteria,
      mutation: createTagPolicyAPI.mutateAsync,
      criteriaAsParams: tbpCriteriaAsParams,
    });
    onFindPolicy(newTagBasedPolicy);
  };

  if (isDrawerOpen && selectedPathStatus === PathReviewStatus.Enforced) {
    return null;
  }

  const shouldShowDrawerHeader =
    !isDrawerOpen && selectedPathStatus === PathReviewStatus.WIP;

  const Header = (
    <TrafficVisualizerDrawerHeader
      title={window.getCTTranslatedText(
        selectedDirection === Direction.Inbound
          ? "Reduce Attack Surface"
          : "Reduce Blast Radius"
      )}
      isExpanded={isDrawerOpen}
      toggleDrawerSize={toggleDrawerSize}
      onClose={close}
      selectedDirection={selectedDirection}
    />
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const onRingFence = () => {
    setIsRingFence(true);
    setCreateTagPolicyDrawerOpen(true);
  };

  const isAccessPolicyDisabled =
    (policy?.matchingAssets ?? 0) > MAX_ACCESS_POLICY_ASSETS;

  return (
    <Stack
      sx={{
        position: "absolute",
        width: "400px",
        height: isDrawerOpen ? "100%" : 0,
        bottom: shouldShowDrawerHeader ? 0 : undefined,
        left: "unset",
        right: 0,
        p: 0,
        minWidth: "400px",
      }}
    >
      {shouldShowDrawerHeader && (
        <Stack
          sx={{
            position: "absolute",
            bottom: 0,
            width: "400px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          direction="row"
          px={4.75}
          py={2}
          elevation={1}
          borderRadius={2}
          component={Paper}
        >
          {Header}
        </Stack>
      )}
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => close()}
        hideBackdrop={true}
        PaperProps={{
          sx: {
            p: 0,
            width: "450px",
            height: "100%",
            minWidth: "400px",
            left: "unset",
          },
          elevation: 1,
        }}
        sx={{
          position: "static !important",
        }}
      >
        <Toolbar />
        {assetMetadata && (
          <>
            <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
              <Stack
                sx={{
                  pt: 2,
                  pb: 0,
                }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {Header}
              </Stack>

              {Boolean(facets?.size) && (
                <Stack my={2}>
                  <VisxSelectedNodeFacets facets={facets} />
                </Stack>
              )}

              <Stack
                sx={{
                  display: isVisxPolicyControlEnabled ? undefined : "none",
                }}
              >
                <CtTabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  style={{ minHeight: 48 }}
                >
                  <Tab label={window.getCTTranslatedText("enforcement")} />
                  <Tab
                    sx={{
                      display: !hasTagUpdatePermissions ? "none" : undefined,
                    }}
                    label={window.getCTTranslatedText("policy")}
                  />
                </CtTabs>
              </Stack>

              <Stack spacing={2} pt={2} flex={1}>
                {selectedTab === Tabs.POLICY && (
                  <>
                    {findTagPolicyAPI.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        {!policy && (
                          <Alert
                            severity="info"
                            action={
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => createSegment()}
                              >
                                {window.getCTTranslatedText("Create")}
                              </Button>
                            }
                          >
                            {window.getCTTranslatedText("noPolicyVisxMessage")}
                          </Alert>
                        )}
                        <Stack
                          spacing={2}
                          sx={{
                            opacity: !policy ? 0.5 : undefined,
                            pointerEvents: !policy ? "none" : undefined,
                          }}
                        >
                          {isCreateTagPolicyDrawerOpen && (
                            <CreateTagBasedPolicyDrawer
                              isOpen={isCreateTagPolicyDrawerOpen}
                              onClose={() => {
                                setIsRingFence(false);
                                setCreateTagPolicyDrawerOpen(false);
                              }}
                              defaultSourceFacets={facets}
                              defaultDestinationFacets={
                                isRingFence ? facets : undefined
                              }
                              isRingFence={isRingFence}
                            />
                          )}

                          <TemplateAssignments
                            policy={policy}
                            asCard={true}
                            onUpdate={() => {
                              refreshTBP();
                            }}
                          />

                          <NetworkAssignments
                            policy={policy}
                            asCard={true}
                            onUpdate={() => {
                              refreshTBP();
                            }}
                          />

                          {isAccessPolicyDisabled && (
                            <Alert severity="warning">
                              {window.getCTTranslatedText(
                                "accessPolicyWarning",
                                {
                                  count: MAX_ACCESS_POLICY_ASSETS,
                                }
                              )}
                            </Alert>
                          )}

                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            disabled={isAccessPolicyDisabled}
                            onClick={() => onRingFence()}
                            startIcon={<LockOutlined fontSize="small" />}
                          >
                            {window.getCTTranslatedText("ringFence")}
                          </Button>

                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            disabled={isAccessPolicyDisabled}
                            onClick={() => setCreateTagPolicyDrawerOpen(true)}
                            startIcon={<LockOutlined fontSize="small" />}
                          >
                            {window.getCTTranslatedText("Create Access Policy")}
                          </Button>

                          <Stack
                            pt={2}
                            sx={{ display: !policy ? "none" : undefined }}
                          >
                            <Typography variant="subtitle2">
                              {window.getCTTranslatedText(
                                "Configure policy automation"
                              )}
                            </Typography>
                            {BASE_TAG_POLICY_COLS(isSegmentsEnabled).map(c => {
                              if (!policy) {
                                return null;
                              }
                              if (c.field?.includes("policyAutomation")) {
                                return (
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                  >
                                    <Typography variant="overline">
                                      {window.getCTTranslatedText(
                                        c.headerName ?? ""
                                      )}
                                    </Typography>
                                    {/* @ts-expect-error */}
                                    {c?.renderCell?.({
                                      row: policy,
                                      id: policy.tagBasedPolicyId,
                                    })}
                                  </Stack>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </Stack>
                        </Stack>
                      </>
                    )}
                  </>
                )}

                {selectedTab === Tabs.ENFORCE && (
                  <BulkAssetStatusControl
                    assetMetadata={assetMetadata}
                    criteria={criteria}
                    onClose={close}
                    selectedAssetGroupSecurityStatus={selectedSourceNodeStatus}
                    selectedDirection={selectedDirection}
                    selectedSecurityStatus={selectedSecurityStatus}
                    setSelectedSecurityStatus={setSelectedSecurityStatus}
                    setSelectedAssetGroupSecurityStatus={
                      setSelectedSourceNodeStatus
                    }
                    isTestMode={isTestMode}
                    onChangeTestMode={setTestMode}
                  />
                )}
              </Stack>
            </DialogContent>
          </>
        )}
      </Drawer>
    </Stack>
  );
}

function useSelectedNodeToFacets() {
  const selectedNode = useVisxStore(state => state.selectedNode);

  return useMemo(() => {
    const facetState = new Map();

    const trafficData = selectedNode?.trafficData;
    if (!trafficData || !selectedNode?.dimension?.name) {
      return facetState;
    }

    let facets: { facetName: string; options: FacetOptionState }[] = [];
    if (trafficData?.parent) {
      const parentFacets = createFacetParentJoinUpdates(trafficData);
      facets = facets.concat(parentFacets);
    }

    let facetValue = getNodeId(selectedNode?.label ?? "");

    if (facetValue.includes(UNKNOWN) || facetValue.includes(UNTAGGED)) {
      facetValue = `NULL`;
    }

    let options: FacetOptionState = new Map();
    options.set(facetValue, {
      isSelected: true,
      operator: Operator.EQUAL,
    });
    facets.push({
      facetName: selectedNode?.dimension?.name,
      options,
    });

    facets.forEach(f => {
      let old = facetState.get(f.facetName);
      if (!old) {
        old = new Map();
      }
      old = new Map([...old, ...f.options]);
      facetState.set(f.facetName, old);
    });

    return facetState;
  }, [
    selectedNode?.dimension?.name,
    selectedNode?.label,
    selectedNode?.trafficData,
  ]);
}

function VisxSelectedNodeFacets({ facets }: { facets: FacetState }) {
  if (!facets?.size) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <AppliedFacetsChips facetState={facets} viewOnly sortByName />
    </Stack>
  );
}

async function createTagBasedPolicy({
  criteria,
  mutation,
  criteriaAsParams,
}: {
  criteria: string;
  mutation: UseMutateAsyncFunction<
    TagPolicy,
    Error,
    Omit<TagPolicy, "tagBasedPolicyId">,
    any
  >;
  criteriaAsParams: string;
}) {
  const execute = async () => {
    let resp = await mutation({
      criteria,
      criteriaAsParams,
    });
    resp.lowestProgressiveInboundPolicyStatus =
      resp.lowestProgressiveInboundPolicyStatus ??
      ProgressiveEnforcementStatus.ZeroTrust;
    return resp;
  };

  return execute();
}
