import { Alert, Typography } from "@mui/material";

interface Props {
  setShowAssetBanner: (value: boolean) => void;
  text: string;
}

function AssetBanner({ setShowAssetBanner, text }: Props) {
  return (
    <Alert
      severity={"error"}
      sx={{ mb: 2, width: "100%" }}
      onClose={() => setShowAssetBanner(false)}
    >
      <Typography variant="body2">
        {text + window.getCTTranslatedText("assetDetailBannerText")}
      </Typography>
    </Alert>
  );
}

export default AssetBanner;
