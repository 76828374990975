import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { AgentDownloadPage } from "pages/agents/components/agent-download-page/AgentDownloadPage";
import { useAgentStore } from "pages/agents/store";
import { Agent } from "pages/agents/types";
import { useMemo } from "react";
import { AgentActionType } from "../agent-data-grid-toolbar/Toolbar";
import { useUpgradeAgents } from "../agent-data-grid/hooks";
import { useGroupByAgentData, useSelectedData } from "./hooks";

export interface AgentDrawerProps {
  isOpen: boolean;
  page: string;
  title?: string;
  data?: any;
  hideToolbar: Function;
  rowCount: number;
  selection: GridRowId[];
  columns?: GridColDef[];
  onCancel: () => void;
  btnTitle?: string;
}

export const AgentUpgradeDrawer = ({
  isOpen,
  page,
  title,
  rowCount,
  selection,
  hideToolbar,
  data,
  columns,
  onCancel,
}: AgentDrawerProps) => {
  const notify = useEventSubscriptionStore(state => state.notify);
  let updatedData: Array<Agent> = useMemo(() => {
    return data?.filter((row: Agent) => {
      return selection.indexOf(row.rowId!) !== -1;
    });
  }, [selection, data]);

  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  const upgradeMutation = useUpgradeAgents();
  const requestAPIRefresh = useAgentStore(store => store.requestAPIRefresh);

  const { selectedItems } = useSelectedData(updatedData);
  const { agentsGroupByPlatforms, setAgentsGroupByPlatforms } =
    useGroupByAgentData(updatedData);

  const confirmAgentUpgrade = async () => {
    if (agentsGroupByPlatforms) {
      const exportPromises = [];
      for (const [plaform, agentData] of Object.entries(
        agentsGroupByPlatforms
      )) {
        if (plaform && agentData?.data?.length > 0 && agentData?.version) {
          let selAgentIds = (agentData?.data ?? [])
            ?.filter(elem => elem.architecture === agentData.architecture)
            ?.map((agent: Agent) => `'${agent?.agentId}'`)
            ?.join(", ")
            ?.replace(/"/g, "");

          const agentVersion = agentData?.version?.includes("Latest")
            ? "@latest"
            : agentData?.version;

          const body = {
            criteria: `agentId in (${selAgentIds})`,
            version: agentVersion,
          };
          const promise = await upgradeMutation.mutateAsync(body, {
            onSuccess: response => {},
            onError: error => {
              setSnackbar(
                true,
                SnackBarSeverity.Error,
                parseErrorMessage(error)
              );
              onCancel();
            },
          });

          exportPromises.push(promise);
        }
      }

      await Promise.all(exportPromises);

      notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
        label: "AgentUpgradeRequestSubmittedSuccessfully",
      });

      hideToolbar();
      onCancel();
      requestAPIRefresh();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onCancel}
      PaperProps={{
        sx: {
          padding: "0px",
          width: `${"70%"}`,
          minWidth: `${"700px"}`,
          maxWidth: `${"700px"}`,
          height: "100%",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={onCancel}
            sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 5, mb: 3, mx: 4 }}>
        <Typography variant="h5">
          <b>{window.getCTTranslatedText(title ?? "")}</b>
        </Typography>
      </Stack>
      <DialogContent>
        <Stack sx={{ mb: 3, width: "100%" }} spacing={1}>
          <Alert severity="warning">
            {window.getCTTranslatedText(
              `Please review all selected agent platform versions, Latest version is selected by default.`
            )}
          </Alert>
        </Stack>
        <AgentDownloadPage
          page={AgentActionType.UPGRADE}
          agentsGroupByPlatforms={agentsGroupByPlatforms}
          setAgentsGroupByPlatforms={setAgentsGroupByPlatforms}
        />
      </DialogContent>
      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <ToolbarAction
          loading={upgradeMutation.isLoading}
          save={confirmAgentUpgrade}
          cancel={onCancel}
          isValid={selectedItems?.length > 0}
          actionBtnText={AgentActionType.UPGRADE}
        />
      </DialogActions>
    </Drawer>
  );
};
