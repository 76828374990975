import { GridRowId } from "@mui/x-data-grid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAssetStore } from "pages/assets/store";
import { PathStatus } from "pages/paths/types";
import { PortStoreType } from "pages/ports/store/types";
import { usePortStore } from "pages/ports/store/usePortStore";
import { PortStatus } from "pages/ports/types";

interface PortStatusActionbarProps {
  selectedStatus: PortStatus | undefined;
  selectedPathStatus?: PathStatus | undefined;
  selection: GridRowId[];
  update: () => void;
  cancel: () => void;
  useStore?: PortStoreType;
  comment?: string | null;
}

function useUpdatePortStatus() {
  const path = `openports/approvals`;
  return useMutation<any, Error, any>(["openports", path, "put"]);
}

export const PortStatusActionBar = ({
  selectedStatus,
  selectedPathStatus = PathStatus.Allow,
  selection,
  update,
  cancel,
  useStore = usePortStore,
  comment,
}: PortStatusActionbarProps) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const updatePortsMutation = useUpdatePortStatus();
  const queryClient = useQueryClient();
  const requestAssetAPIRefresh = useAssetStore(
    state => state.requestAPIRefresh
  );
  const requestPortAPIRefresh = useStore(state => state.requestAPIRefresh);

  const updateStatus = async (
    selectedPortIds: GridRowId[],
    selectedOperation: PortStatus | undefined
  ) => {
    if (selectedPortIds?.length > 0 && selectedOperation) {
      interface updatePortStatusBodyProps {
        toState: PortStatus;
        criteria: string;
        pathToState?: PathStatus;
        comment?: string | null;
      }

      let body: updatePortStatusBodyProps = {
        toState: selectedOperation,
        criteria: `lpId in (${selectedPortIds.map(id => `'${id}'`).join(",")})`,
        comment: comment,
      };

      if (
        selectedPathStatus &&
        selectedOperation === PortStatus.PathRestricted
      ) {
        body["pathToState"] = selectedPathStatus;
      }

      await updatePortsMutation.mutateAsync(body, {
        onSuccess: response => {
          update();
          // setPortsSelection([]);
          queryClient.invalidateQueries({
            queryKey: ["asset"],
          });
          queryClient.invalidateQueries({
            queryKey: ["ports"],
          });
          requestAssetAPIRefresh();
          requestPortAPIRefresh();
          selectedStatus = undefined;
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            "UpdatedPortStatusSuccessfully"
          );
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  };

  const confirmAction = () => {
    if (selection?.length > 0) {
      updateStatus(selection, selectedStatus);
    }
  };

  return (
    <>
      {userPermissions.has("UPDATE_PORT") && (
        <ToolbarAction
          loading={updatePortsMutation?.isLoading}
          isValid={
            selection?.length > 0 &&
            !!selectedStatus &&
            (selectedStatus !== PortStatus.PathRestricted ||
              (selectedPathStatus &&
                selectedStatus === PortStatus.PathRestricted))
          }
          actionBtnText={"Confirm"}
          save={confirmAction}
          cancel={cancel}
          hasPermission={userPermissions.has("UPDATE_PATH")}
        ></ToolbarAction>
      )}
    </>
  );
};
