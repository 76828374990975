import { Agent } from "pages/agents/types";
import { useMemo } from "react";

export function useSelectedData(updatedData: Agent[] | undefined) {
  const selectedItems = useMemo(
    () => (updatedData ?? []).map((item: Agent) => item.agentId),
    [updatedData]
  );

  return { selectedItems };
}
