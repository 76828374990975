import { CopyAllOutlined } from "@mui/icons-material";
import { Button, IconButton, SxProps } from "@mui/material";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import CopyToClipboard from "react-copy-to-clipboard";

export function CopyButton({
  text,
  textButton,
  sx,
}: {
  text?: string;
  textButton?: boolean;
  sx?: SxProps;
}) {
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  return (
    <CopyToClipboard
      onCopy={() =>
        setSnackbar(true, SnackBarSeverity.Info, "CopiedToClipboard")
      }
      text={text || ""}
    >
      {textButton ? (
        <Button variant="outlined" size={"small"} sx={sx}>
          {window.getCTTranslatedText("copy")}
        </Button>
      ) : (
        <IconButton aria-label="copy" size="small">
          <CopyAllOutlined fontSize="small" sx={sx} />
        </IconButton>
      )}
    </CopyToClipboard>
  );
}
