import { useCriteriaBuilder } from "modules/core/Core";
import { FacetOptionState, Operator } from "modules/facets/types";
import { ScopeMetadata } from "modules/scope-metadata/types";
import {
  AssetStatusReverseMap,
  Direction,
  SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { AssetStatus } from "pages/assets/types";
import { useMemo } from "react";

export interface AssetSecurityStatusCriteriaBuilderProps {
  criteria: string;
  selectedAssetGroupSecurityStatus: SecurityStatus;
  selectedDirection: Direction;
  assetMetadata: ScopeMetadata | undefined;
}

export function useAssetSecurityStatusCriteria({
  selectedAssetGroupSecurityStatus,
  selectedDirection,
  assetMetadata,
  criteria,
}: AssetSecurityStatusCriteriaBuilderProps) {
  const { statusFilter } = useMemo(() => {
    if (selectedAssetGroupSecurityStatus === -1) {
      return {
        statusFilter: undefined,
      };
    }

    const filtersWithStatus = new Map();

    let facetName =
      selectedDirection === Direction.Inbound
        ? "assetinboundstatus"
        : "assetoutboundstatus";

    const status = AssetStatusReverseMap[selectedAssetGroupSecurityStatus];
    let options: FacetOptionState = new Map(filtersWithStatus.get(facetName));
    options.set(status, { isSelected: true, operator: Operator.EQUAL });

    let testStatus: AssetStatus | undefined;

    if (selectedAssetGroupSecurityStatus === SecurityStatus.Internet) {
      testStatus = AssetStatus.SimulateSecureInternet;
    } else if (selectedAssetGroupSecurityStatus === SecurityStatus.All) {
      testStatus = AssetStatus.SimulateSecureAll;
    }

    if (testStatus) {
      options.set(testStatus, { isSelected: true, operator: Operator.EQUAL });
    }

    filtersWithStatus.set(facetName, options);
    return {
      statusFilter: filtersWithStatus,
    };
  }, [selectedDirection, selectedAssetGroupSecurityStatus]);

  const filterCriteria = useCriteriaBuilder(
    "",
    statusFilter,
    assetMetadata,
    criteria
  );

  return filterCriteria;
}
