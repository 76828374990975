import { Box, Typography, useTheme } from "@mui/material";

interface CtSilderLabelProps {
  renderIcon: (color: string) => JSX.Element;
  text: string;
  color: string;
  textColor?: string;
  background?: string;
  justifyContent?: string;
  mr?: number;
  ml?: number;
  px?: number;
  isMinimum?: boolean;
}

export const CtSilderLabel = ({
  ml = 0,
  mr = 0,
  px = 0,
  renderIcon,
  text,
  color,
  textColor = color,
  background = "transparent",
  justifyContent = "center",
  isMinimum = false,
}: CtSilderLabelProps) => {
  const theme = useTheme();

  const labelStyle = {
    pt: 0.5,
    borderRadius: "30px",
    fontWeight: theme.typography.fontWeightBold ?? "unset",
    textTransform: "uppercase",
  };

  return (
    <Box sx={{ mr: mr ?? 0, ml: ml ?? 0, mt: isMinimum ? -1 : 0 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent,
          mb: 1,
        }}
      >
        {renderIcon(color)}
      </Box>
      <Box sx={{ display: "grid", textAlign: justifyContent }}>
        <Typography
          sx={{
            ...labelStyle,
            color: textColor,
            background: background,
            px: px,
          }}
          variant="caption"
        >
          {text}
        </Typography>
        {isMinimum && (
          <Typography
            variant="caption"
            sx={{ color: theme => theme.palette.text.disabled }}
          >
            Minimal
          </Typography>
        )}
      </Box>
    </Box>
  );
};
