import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { TIMELINE_LIST } from "pages/dashboard/constants";
import { DashboardTimeFilterProps, TIMELINES } from "pages/dashboard/types";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";

export const DashboardTimeFilter = ({
  selectedTimeline,
  handleTimeLineUpdate,
}: DashboardTimeFilterProps) => {
  return (
    <CTSelectDropDown
      field={"time"}
      displayName={"Time"}
      selectedValue={selectedTimeline}
      handleUpdate={(e: TextFieldUpdate) =>
        handleTimeLineUpdate(e?.value as TIMELINES)
      }
      data={TIMELINE_LIST}
      required={false}
      showLabel={false}
      size="medium"
      showSymbols={false}
    />
  );
};
