import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import { Network, NetworkFormProps } from "pages/networks/types";
import { NetworkForm } from "./components/network-form";
import { Toolbar } from "common/atoms/toolbar";

interface NetworkFormDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  network?: Network | NetworkFormProps;
  updateNetworkData: Function;
  title?: string;
  mode: "create" | "edit" | "add";
  btnTitle?: string;
  cidrList?: string[];
}

export const NetworkFormDrawer = ({
  isOpen,
  onClose,
  network,
  title,
  updateNetworkData,
  mode = "create",
  btnTitle = "create",
  cidrList,
}: NetworkFormDrawerProps) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 0,
          width: "70%",
          maxwidth: "1000px",
          minWidth: "600px",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={onClose}
            sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <NetworkForm
        title={title ? title : `Create New Named Network`}
        updateNetworkData={updateNetworkData}
        cancel={onClose}
        network={network}
        mode={mode}
        btnTitle={btnTitle}
        cidrList={cidrList}
      ></NetworkForm>
    </Drawer>
  );
};
