import CloseIcon from "@mui/icons-material/Close";
import {
  Drawer,
  Toolbar,
  DialogTitle,
  Stack,
  Typography,
  Box,
  IconButton,
  Tooltip,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { ApplianceDetails } from "./components/ApplianceDetails";
import { useState } from "react";
import { CTInputField } from "common/atoms/ct-input-field";
import { isValidActivationCode } from "pages/appliances/utils";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useApplianceActivate } from "./hooks";
import { useQueryClient } from "@tanstack/react-query";
import { ApplianceCertificate } from "pages/appliances/types";

interface ApplianceActivationProps {
  isOpen: boolean;
  cancel: () => void;
  title: string;
}

interface ActivationCodeInputProps {
  activationCode: string;
  onCodeChange: (code: string) => void;
  keyListener: (event: any) => void;
  isDisabled: boolean;
}

function ActivationCodeInput({
  activationCode,
  onCodeChange,
  keyListener,
  isDisabled,
}: ActivationCodeInputProps) {
  return (
    <CTInputField
      field="activationCode"
      displayName={window.getCTTranslatedText("activationCodeField")}
      value={activationCode}
      handleUpdate={(event: { field: string; value: string }) =>
        onCodeChange(event?.value)
      }
      placeholder={window.getCTTranslatedText("activationCodePlaceholder")}
      onKeyUp={keyListener}
      disabled={isDisabled}
    />
  );
}

export function ApplianceActivationDrawer({
  isOpen,
  cancel,
  title,
}: ApplianceActivationProps) {
  const [activationCode, setActivationCode] = useState("");
  const [initMatching, setInitMatching] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [applianceCertificate, setApplianceCertificate] =
    useState<ApplianceCertificate>();
  const activateApplianceMutation = useApplianceActivate(activationCode);
  const queryClient = useQueryClient();

  const onCancel = () => {
    setInitMatching(false);
    setErrorMessage("");
    setActivationCode("");
    setApplianceCertificate(undefined);
    cancel();
  };

  const keyListener = (event: any) => {
    if (event?.key === "Enter" || event?.keyCode === 13) {
      findMatchingAppliance();
    }
  };

  const onCodeChange = (code: string) => {
    if (initMatching) {
      setInitMatching(false);
    }
    if (errorMessage) {
      setErrorMessage("");
    }
    setActivationCode(code);
  };

  const findMatchingAppliance = async () => {
    if (!isValidActivationCode(activationCode)) {
      setErrorMessage(window.getCTTranslatedText("invalidActivationCodeError"));
      return;
    }
    setInitMatching(true);
    await activateApplianceMutation.mutateAsync(undefined, {
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: ["activate-appliance"],
        });
        setApplianceCertificate(response);
      },
      onError: error => {
        setInitMatching(false);
        setErrorMessage(
          window.getCTTranslatedText("invalidActivationCodeError")
        );
      },
    });
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onCancel}
      PaperProps={{
        sx: {
          p: 0,
          width: "50%",
          maxwidth: "1000px",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <DialogTitle sx={{ pb: 1, pt: 4 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack>
            <Stack direction="row" alignItems="center">
              <Typography variant="h6" mr={2}>
                {title}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row">
            <Box>
              <IconButton
                size="medium"
                aria-label="close drawer"
                onClick={onCancel}
                sx={{
                  zIndex: 2,
                }}
              >
                <Tooltip title="Close Drawer">
                  <CloseIcon fontSize="medium" />
                </Tooltip>
              </IconButton>
            </Box>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4} mt={4}>
          <ActivationCodeInput
            activationCode={activationCode}
            onCodeChange={onCodeChange}
            keyListener={keyListener}
            isDisabled={Boolean(initMatching && !errorMessage)}
          />
          <ApplianceDetails
            initMatching={initMatching}
            errorMessage={errorMessage}
            applianceCertificate={applianceCertificate}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <ToolbarAction
          loading={false}
          save={findMatchingAppliance}
          cancel={onCancel}
          isValid={Boolean(!initMatching)}
          secondaryBtnText={window.getCTTranslatedText("Close")}
          actionBtnText={window.getCTTranslatedText("Activate")}
        />
      </DialogActions>
    </Drawer>
  );
}
