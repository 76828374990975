import { SxProps } from "@mui/material";
import { ReactNode } from "react";

export interface TextFieldsValues {
  [key: string]: any;
}

export interface StaticData {
  subHeading: string;
  linkText?: string[];
  linkUrl?: string[];
  clipboardText?: string;
  content?: string[];
  heading?: string;
}

export interface Field {
  displayName: string;
  key: string;
  type: string;
  defaultValue: any;
  identifier?: string;
  section?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  information?: string;
  children?: Array<Field>;
  possibleValues?: Array<Field>;
  staticData?: Array<StaticData>;
  description?: string;
}

export interface FieldsOption {
  name?: string;
  displayName?: string;
  icon?: ReactNode;
  fields?: Array<Field>;
  integrationProvider?: string;
  integrationType?: string;
}

export interface FormControllerProps {
  setTextFieldsValues: React.Dispatch<React.SetStateAction<TextFieldsValues>>;
  textFieldsValues: TextFieldsValues;
  formExtras?: any;
  isLoading?: boolean;
}
export interface FormProps extends FormControllerProps {
  formConfig?: Array<Field>;
  isDrawer?: boolean;
  onClose?: VoidFunction;
  dialogTitle?: string;
  titleTemplate?: string;
  formExtrasAfter?: any;
  formExtrasBefore?: any;
  drawerToolbar?: any;
  isSortable?: boolean;
}
export interface IdpsFormProps extends FormControllerProps {
  storeToDataBase?: boolean;
  setStoreToDataBase?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface FormFieldChangeEvent {
  target: {
    name: string;
    value: any;
  };
}
export type TextFieldProps = {
  field: Field;
  value: any;
  handleChange: (event: FormFieldChangeEvent) => void;
  showPassword?: { [key: string]: boolean };
  handlePasswordVisibility?: (fieldName: string) => void;
  isDrawer?: boolean;
  isSection?: boolean;
  sx?: SxProps;
};
export type FileFieldProps = {
  field: Field;
  value: any;
  handleChange: (event: FormFieldChangeEvent) => void;
  fileName: string;
  isDrawer?: boolean;
  isSection?: boolean;
  maxFileSizeInBytes?: number;
  allowedExtensions?: string[];
};
export type AttachmentProps = {
  name: string;
  size: string;
};
export enum FieldType {
  SECTION = "section",
  TEXT = "text",
  PASSWORD = "password",
  MAPPING = "mapping",
  DROPDOWN = "dropdown",
  CHECKBOX = "checkbox",
  STATIC = "static",
  FILE = "file",
  INTEGER = "integer",
}
export enum FileType {
  JSON = ".json",
  TEXT = ".txt",
  CERT = ".pem",
}

export enum MimeType {
  JSON = "application/json",
  TEXT = "text/plain",
  CERT = "text/html",
}
