import { Box, Stack } from "@mui/material";
import { useCommonStore } from "common/store";
import { AssetCoreTags } from "modules/asset-core-tags/components";
import { PortColumnRenderProps } from "pages/ports/types";

export function PortAssetTags({
  port,
  viewOnly,
  useFacetStore = useCommonStore,
}: PortColumnRenderProps) {
  if (!port.listenAsset.assetName) {
    return <Box style={{ minWidth: 310, minHeight: 45 }} />;
  }

  return (
    <Stack
      sx={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
      alignItems="left"
      justifyContent="center"
    >
      <AssetCoreTags
        coreTags={port.listenAsset?.coreTags}
        useAssetCoreTagsStore={useFacetStore}
        viewOnly={viewOnly}
      />
    </Stack>
  );
}
