import { Button, Stack, Tooltip } from "@mui/material";
import { useDemoController } from "hooks/useDemoController";
import { useNavigate } from "react-router-dom";

export function ApplianceDownload() {
  const { isDemo } = useDemoController();
  const navigate = useNavigate();

  const navigateToDownloadPage = () => {
    if (!isDemo) {
      navigate(`/appliances/install-appliances`);
    }
  };

  const renderDownloadButton = () => {
    return (
      <Tooltip
        title={
          isDemo
            ? window.getCTTranslatedText(
                "pleaseExitDemoModeToDownloadAppliance"
              )
            : ""
        }
      >
        <span>
          <Button
            variant="contained"
            color="primary"
            disabled={isDemo}
            onClick={() => navigateToDownloadPage()}
          >
            {window.getCTTranslatedText("install")}
          </Button>
        </span>
      </Tooltip>
    );
  };

  return (
    <Stack
      direction={"row-reverse"}
      alignItems="flex-start"
      justifyItems="flex-end"
    >
      {renderDownloadButton()}
    </Stack>
  );
}
