import FilterListIcon from "@mui/icons-material/FilterList";
import { Badge, IconButton, useTheme } from "@mui/material";
import React from "react";

export interface FacetHeaderProps {
  title?: string;
  facetsOpen: boolean;
  setFacetsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  appliedCount: number;
  setAnchorEl: (e: any) => void;
}

const facetsCloseStyles = {
  // transform: "rotate(90deg)",
};

export function FiltersHeader({
  title,
  facetsOpen,
  setFacetsOpen,
  appliedCount,
  setAnchorEl,
}: FacetHeaderProps) {
  const theme = useTheme();

  const buttonStyles = Object.assign(
    {},
    {
      color: theme.palette.primary.main,
      textTransform: "none",
      transform: "rotate(0deg)",
      minWidth: "fit-content",
      transformOrigin: "18px 18px",
      justifyContent: "flex-start",
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    !facetsOpen ? facetsCloseStyles : undefined
  );

  const handlerOnClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setFacetsOpen(!facetsOpen);
  };

  return (
    <>
      {/* <Button
        size="medium"
        color="inherit"
        sx={buttonStyles}
        disableTouchRipple
        onClick={handlerOnClick}
        startIcon={
          <Badge badgeContent={appliedCount} color="primary">
            <FilterListIcon />
          </Badge>
        }
      >
        {title || "Filters"}
      </Button> */}
      <IconButton
        aria-label="filter"
        size="medium"
        sx={buttonStyles}
        disableTouchRipple
        onClick={handlerOnClick}
      >
        <Badge badgeContent={appliedCount} color="primary">
          <FilterListIcon />
        </Badge>
      </IconButton>
    </>
  );
}
