import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { DialogActions, Stack, TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import React, { useState } from "react";
import { useAddAnnotationAPI } from "./hooks";

export const AnnotationSubmission = ({
  entity,
  entityId,
  setHasComments,
}: {
  entity: string;
  entityId: string;
  setHasComments: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [comment, setComment] = useState<string | null>(null);
  const addAnnotationMutation = useAddAnnotationAPI(entity, entityId);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const queryClient = useQueryClient();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const submitAnnotation = async () => {
    const body = { comment: comment };

    await addAnnotationMutation.mutateAsync(body, {
      onSuccess: response => {
        setHasComments(true);
        setSnackbar(true, SnackBarSeverity.Success, "CommentAddedSuccessfully");
        setComment(null);
        queryClient.invalidateQueries({
          predicate: q => {
            let tag: string = q.queryKey[0] as string;
            return tag.startsWith("annotation");
          },
        });
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  return (
    <Stack>
      <DialogActions sx={{ width: "100%", px: 0 }}>
        <TextField
          sx={{ width: "100%" }}
          id="comments"
          placeholder={window.getCTTranslatedText("Enter comment ...")}
          variant="outlined"
          size="small"
          value={comment ?? ""}
          multiline={Boolean(comment)}
          rows={3}
          onChange={handleOnChange}
          inputRef={input => input && input.focus()}
          onFocus={e =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
        />
      </DialogActions>
      <Stack
        width="100%"
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {comment && (
          <LoadingButton
            loading={addAnnotationMutation.isLoading}
            disabled={!comment.length}
            color="primary"
            variant="contained"
            onClick={submitAnnotation}
            sx={{ my: 1 }}
          >
            {window.getCTTranslatedText("Save")}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};
