import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export interface SecurityRiskProgressBarProps {
  value: number;
  text?: string;
  rotation?: number;
  circleRatio?: number;
  pathColor?: string;
  trailColor?: string;
  strokeWidth?: number;
}

export const SecurityRiskProgressBar = ({
  value,
  text,
  circleRatio = 1,
  rotation = 0,
  pathColor,
  trailColor = "#eee",
  strokeWidth = 10,
}: SecurityRiskProgressBarProps) => {
  return (
    <CircularProgressbar
      value={value}
      text={`${text}`}
      circleRatio={circleRatio}
      strokeWidth={strokeWidth}
      styles={buildStyles({
        rotation: rotation,
        pathColor: pathColor,
        trailColor: trailColor,
      })}
    />
  );
};
