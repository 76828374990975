import debounce from "lodash/debounce";
import { useSnackbarStore } from "modules/snackbar/store/useSnackbarStore";
import { useMemo } from "react";

export function useDebouncedSnackbar() {
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  const setDebouncedSnackbar = useMemo(
    () =>
      debounce(setSnackbar, 500, {
        leading: false,
        trailing: true,
      }),
    [setSnackbar]
  );

  return { setDebouncedSnackbar };
}
