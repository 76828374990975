import { routesToScopeMap } from "../constant";
import { Recommendation } from "../types";
import { getRecommendationUrlQuery } from "./getRecommendationUrlQuery";

const getRoutesMap = (scope: string) => {
  return routesToScopeMap[scope];
};

export const getRecommendationRoutes = (
  rec: Recommendation,
  hasPermission = true
) => {
  let link = "";
  if (rec) {
    const id = rec?.name;
    const scope = rec?.attributes?.scope;
    const days: number | undefined = rec?.attributes?.durationInDays;
    const routeMap = getRoutesMap(scope);
    const query = getRecommendationUrlQuery(scope, id, days);

    if (id && hasPermission) {
      link = `/${routeMap}?recommendation=${id}&filters=${query}`;
    } else {
      link = `/${routeMap}?&filters=${query}`;
    }
  }

  return link;
};
