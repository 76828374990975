import {
  Box,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useAssetsDistributionStats } from "modules/bulk-asset-status-control/hooks/useAssetDistributionStats";
import { AssetStatusSlider } from "pages/asset/components/asset-detail/components/asset-status-slider";
import { AssetSliderProps } from "pages/asset/components/asset-detail/components/asset-status-slider/types";
import {
  Direction,
  SecurityStatus,
} from "pages/asset/components/asset-detail/constants";
import { ProgressiveEnforcementLevel } from "pages/ports/types";
import { AssetBadges } from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/components/asset-badges";
import { ProgressiveStatus } from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/components/progressive-status";

interface SecurityLevelCardProps extends AssetSliderProps {
  onChangeTestMode: (checked: boolean) => void;
  criteria?: string;
  initialProgressiveEnforcementLevel?: ProgressiveEnforcementLevel;
  selectedProgressiveEnforcementLevel?: ProgressiveEnforcementLevel;
  setSelectedProgressiveEnforcementLevel?: (
    value: ProgressiveEnforcementLevel
  ) => void;
  isZeroTrustAutomationEditable: boolean;
  policyId?: string;
  policyProgressiveLastRefreshed?: string;
}

export function SecurityLevelCard(props: SecurityLevelCardProps) {
  const { isFeatureEnabled } = useFeatureFlagControl(FEATURES.PROGRESSIVE);
  const isProgressiveEnabled = isFeatureEnabled;

  const assetStats = useAssetsDistributionStats({
    criteria: props.criteria,
    field: `asset${
      props.direction === Direction.Inbound ? "inbound" : "outbound"
    }status`,
    enabled: true,
  });

  const direction =
    props.direction === Direction.Inbound ? "Inbound" : "Outbound";

  const shouldShowProgressiveStatus =
    isProgressiveEnabled &&
    props.direction === Direction.Inbound &&
    props.selectedProgressiveEnforcementLevel !== undefined;

  return (
    <Box component={Paper} elevation={2} p={2}>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography variant="subtitle2" flex={1}>
          {window.getCTTranslatedText(
            props.direction === Direction.Inbound
              ? "Attack Surface"
              : "Blast Radius"
          )}
        </Typography>
        <Typography variant="overline">
          {window.getCTTranslatedText(direction)}
        </Typography>
      </Stack>
      {shouldShowProgressiveStatus && (
        <>
          <Divider sx={{ my: 1 }} />
          <Stack>
            <ProgressiveStatus
              criteria={props?.criteria}
              asset={props.asset}
              initialStatus={props.initialProgressiveEnforcementLevel}
              selectedStatus={props.selectedProgressiveEnforcementLevel}
              setSelectedStatus={props.setSelectedProgressiveEnforcementLevel}
              disabled={props.disabled}
              policyId={props?.policyId}
              policyProgressiveLastRefreshed={
                props.policyProgressiveLastRefreshed
              }
            />
            <Typography
              variant="body2"
              sx={{ color: theme => theme.palette.text.secondary }}
            >
              {window.getCTTranslatedText("enforcement").toUpperCase()}
            </Typography>
          </Stack>
        </>
      )}
      <Stack
        pt={3}
        sx={{ opacity: props.isZeroTrustAutomationEditable ? 1 : 0.3 }}
      >
        <AssetBadges
          assetStats={assetStats}
          selectedStatus={props.selectedStatus}
        />
      </Stack>
      <Box
        sx={{
          mt: 15,
          width: "100%",
          px: 4,
          opacity: props.isZeroTrustAutomationEditable ? 1 : 0.3,
        }}
      >
        <AssetStatusSlider
          {...props}
          disabled={!props.isZeroTrustAutomationEditable || props.disabled}
        />
      </Box>
      <Stack
        alignItems={"flex-end"}
        sx={{ opacity: props.isZeroTrustAutomationEditable ? 1 : 0.3 }}
      >
        {props.selectedStatus !== SecurityStatus.Unsecure && (
          <FormControlLabel
            sx={{ m: 0 }}
            label={window.getCTTranslatedText("testMode")}
            disabled={!props.isZeroTrustAutomationEditable}
            control={
              <Switch
                value=""
                checked={props.simulatedStatus}
                onChange={(e, checked) => props.onChangeTestMode(checked)}
                color="warning"
              />
            }
          />
        )}
      </Stack>
    </Box>
  );
}
