import { useTheme } from "@mui/material";

export const NessusIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.9556 27.4526C39.8666 27.124 39.5449 25.9165 39.2355 24.7706L36.8082 15.7156C36.5015 14.5684 36.1784 13.3622 36.0894 13.0323C36.0076 12.7678 35.8625 12.5273 35.6666 12.3317C35.4707 12.136 35.23 11.9912 34.9654 11.9097L32.2848 11.1923L23.2271 8.76221L20.541 8.04209C20.2722 7.981 19.9925 7.98648 19.7262 8.05806C19.4599 8.12964 19.2152 8.26514 19.0132 8.4528L8.45226 19.0151C8.28188 19.199 8.15444 19.4184 8.07915 19.6575C8.00386 19.8966 7.9826 20.1495 8.0169 20.3978H8.00595L8.07851 20.6908L11.1849 32.2867C11.4916 33.4312 11.816 34.6401 11.9037 34.9687C11.9854 35.233 12.1304 35.4733 12.326 35.6689C12.5216 35.8646 12.762 36.0095 13.0263 36.0913L15.3277 36.7088L24.7742 39.2388L27.0126 39.8371L27.4602 39.9575C27.7293 40.019 28.0093 40.0137 28.2759 39.9421C28.5424 39.8705 28.7874 39.7348 28.9895 39.5468L30.9541 37.5822L37.5831 30.9519C38.4223 30.114 39.3053 29.231 39.5504 28.9845C39.7371 28.7812 39.8716 28.5355 39.9422 28.2686C40.0128 28.0017 40.0174 27.7216 39.9556 27.4526ZM9.17513 19.7325L19.7429 9.16197C19.8986 9.05023 20.0907 9.00134 20.2809 9.02507L34.708 12.8954C34.7985 12.9309 34.8805 12.985 34.9489 13.0541C35.0173 13.1231 35.0704 13.2057 35.1051 13.2965C35.1941 13.6278 35.5158 14.834 35.8238 15.9771L38.6851 26.6462C38.8221 27.15 38.9261 27.5498 38.9768 27.7319C38.9965 27.9227 38.9421 28.1139 38.8248 28.2658L28.2625 38.8349C28.1079 38.949 27.9148 38.9981 27.7245 38.9718L27.3494 38.8719L25.0357 38.2558L13.2891 35.107C13.1986 35.0736 13.1171 35.0197 13.0509 34.9495C12.9801 34.882 12.9257 34.7991 12.8921 34.7072C12.8223 34.4416 12.6005 33.612 12.3582 32.7097L9.74602 22.9621C9.47221 21.949 9.18882 20.8838 9.06149 20.4252H9.07792L9.01084 20.239C9.00486 20.0562 9.06298 19.877 9.17513 19.7325Z"
        fill="#00829B"
      />
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        stroke={theme.palette.text.primary}
        strokeOpacity="0.12"
      />
    </svg>
  );
};
