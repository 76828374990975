import debounce from "lodash/debounce";
import { useEffect, useMemo, useRef, useState } from "react";
import { Facet, FacetOptionState, onFacetChange } from "../types";

export interface SearchHookProps {
  facet: Facet;
  onChange: onFacetChange;
  committedValue: FacetOptionState | undefined;
  onClearStaged: Function | undefined;
  enabled: boolean;
}
export default function useFacetSuggestionInput({
  facet,
  onChange,
  committedValue,
  onClearStaged,
  enabled,
}: SearchHookProps) {
  const [search, setSearch] = useState("");

  const searchKeys = committedValue?.keys();
  const commitedSearch = searchKeys?.next()?.value;

  const clearStageRef = useRef(onClearStaged);
  clearStageRef.current = onClearStaged;

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const sendChange = useMemo(() => {
    return debounce(searchValue => {
      if (!searchValue) {
        return;
      }

      onChangeRef.current({
        facetName: facet.name,
        optionName: searchValue,
        value: true,
      });
    }, 300);
  }, [facet.name]);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    clearStageRef.current && clearStageRef.current();
    sendChange(search);
  }, [enabled, search, sendChange]);

  useEffect(() => {
    if (commitedSearch?.length > 0) {
      setSearch("");
    }
  }, [commitedSearch]);

  return {
    search: search || commitedSearch || "",
    setSearch,
    readOnly: commitedSearch?.length > 0,
  };
}
