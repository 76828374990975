import { Stack, Tooltip, Typography } from "@mui/material";
import { UserGroup } from "pages/users-segmentation/components/user-groups/types";
import numeral from "numeral";
import { UserGroupAssignedTemplatesDrawer } from "../user-group-assigned-templates-drawer";
import { useState } from "react";

export function UserGroupAssignedTemplates({
  userGroup,
  criteria,
  viewOnly = false,
  multiselect = true,
  onSelectCallback,
}: {
  readonly userGroup: UserGroup;
  readonly viewOnly?: boolean;
  criteria: string;
  multiselect?: boolean;
  onSelectCallback?: () => void;
}) {
  const [showAssignedTemplatesDrawer, setShowAssignedTemplatesDrawer] =
    useState<boolean>(false);

  const templatesAssigned = numeral(userGroup?.templatesAssigned || 0).format(
    "0a"
  );

  return (
    <>
      <Stack direction={"row"} spacing={1} flex={1} alignItems={"center"}>
        {viewOnly ? (
          <Typography variant="body2">{templatesAssigned} </Typography>
        ) : (
          <Tooltip
            title={window.getCTTranslatedText("View Assigned Templates")}
          >
            <Typography
              variant="body2"
              onClick={() => {
                setShowAssignedTemplatesDrawer(true);
              }}
              sx={{ color: "primary.main" }}
            >
              {templatesAssigned}{" "}
            </Typography>
          </Tooltip>
        )}
      </Stack>
      {showAssignedTemplatesDrawer && (
        <UserGroupAssignedTemplatesDrawer
          criteria={criteria}
          multiselect={multiselect}
          onSelectCallback={onSelectCallback}
          showNonWindows={false}
          showAssignedTemplatesDrawer
          closeAssignedTemplatesDrawer={() => {
            setShowAssignedTemplatesDrawer(false);
          }}
        />
      )}
    </>
  );
}
