import { Box, Stack } from "@mui/material";
import { ContainerColumnRenderProps } from "pages/containers/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function AgentVersion({
  agent,
  viewOnly,
}: Readonly<ContainerColumnRenderProps>) {
  if (!agent?.agentId) {
    return <Box style={{ minWidth: 120 }} />;
  }

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <CTDatagridButton
        asText={viewOnly}
        isDisabled={viewOnly}
        facetName={"currentversion"}
        facetOption={agent.currentVersion}
      >
        {agent?.currentVersion}
      </CTDatagridButton>
    </Stack>
  );
}
