import { Stack, Typography, useTheme } from "@mui/material";
import { CTDrawer } from "common/molecules/drawer/CTDrawer";
import { getLocalDate } from "common/utils/getDate";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { CTAlert } from "pages/monitoring/types";
import { useMemo } from "react";
import ReactJson from "react-json-view";
import { AlertSeverity } from "../../alert-severity";
import { showAlertFields } from "./constants";

export interface AlertDetailsProps {
  alerts: CTAlert;
  onClose: VoidFunction;
}

const ValidKeys: { [key: string]: boolean } = {
  alertHash: true,
  alertType: true,
  asset: true,
  closedBy: true,
  closeTime: true,
  count: true,
  details: true,
  firstSeen: true,
  lastSeen: true,
  resourceId: false,
  ruleName: true,
  severity: true,
  status: true,
  metadata: true,
};

export function AlertDetails({ alerts, onClose }: Readonly<AlertDetailsProps>) {
  const theme = useTheme();

  const showFields = showAlertFields;

  const alertsMap = useMemo(() => {
    if (!alerts) {
      return;
    }

    let o: { [key: string]: any } = {};
    let entries = Object.entries(alerts);
    entries.forEach(([key, value]) => {
      if (!value || !ValidKeys[key]) {
        return;
      }
      if (key === "asset") {
        value = omitBy(value, isNil);
      }
      if (key === "metadata") {
        value = JSON.parse(value);
      }
      o[key] = value;
    });
    return o;
  }, [alerts]);

  const showValue = (object: any, field: string) => {
    if (
      field === "firstSeen" ||
      field === "closeTime" ||
      field === "lastSeen"
    ) {
      return getLocalDate(object[field]);
    } else if (field === "severity") {
      return <AlertSeverity alert={object} />;
    } else {
      return object[field];
    }
  };

  return (
    <CTDrawer
      PaperProps={{
        sx: {
          p: 0,
          maxwidth: "1000px",
          minWidth: "800px",
        },
        elevation: 1,
      }}
      title={`Alert Details`}
      onClose={onClose}
      open={Boolean(alerts)}
    >
      <Stack
        sx={{
          "& .react-json-view": {
            backgroundColor: "initial !important",
          },
          "& .object-key": {
            color: theme.palette.text.secondary,
            fontSize: theme.typography.body2.fontSize,
          },
          "& .pushed-content.object-container .pushed-content.object-container  .object-key":
            {
              color: theme.palette.info.main,
            },
          "& .string-value, & .variable-value div": {
            color: `${theme.palette.text.primary} !important`,
            fontSize: theme.typography.body2.fontSize,
          },
          height: "100%",
          overflowY: "auto",
        }}
      >
        {alertsMap && (
          <Stack>
            <Stack spacing={1} sx={{ mb: 1 }}>
              {showFields.map((fieldObj, index) => (
                <Stack key={fieldObj.field} sx={{ mt: "0 !important" }}>
                  {alertsMap[fieldObj?.field] && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        "& .name": {
                          flex: "20%",
                          padding: "5px",
                        },
                        "& .field": {
                          flex: "80%",
                          padding: "5px",
                        },
                      }}
                    >
                      <Typography variant="body2" className="name">
                        {fieldObj?.name} :
                      </Typography>
                      <Typography variant="body2" className="field">
                        {showValue(alertsMap, fieldObj?.field)}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>
            <ReactJson
              name={false}
              src={alertsMap}
              enableClipboard={false}
              displayDataTypes={false}
              iconStyle="square"
              displayObjectSize={false}
              theme={
                theme.palette.mode === "dark"
                  ? "grayscale"
                  : "grayscale:inverted"
              }
            />
          </Stack>
        )}
      </Stack>
    </CTDrawer>
  );
}
