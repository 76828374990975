import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { RestrictToAllowedPathsPreviewDrawer } from "modules/add-to-templates/components/AddToTemplateDialog/RestrictToAllowedPathsPreviewDrawer";
import { Rule } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { Path, PathRule } from "pages/paths/types";
import { Port, PortRule } from "pages/ports/types";
import { TemplateType } from "pages/templates/types";
import { useMemo, useState } from "react";

export interface TemplatePortRule extends PortRule {
  oobTemplate?: boolean;
  templateId?: string;
}

export interface TemplatePathRule extends PathRule {
  oobTemplate?: boolean;
  templateId?: string;
}

interface RestrictPortToAllowedPathsProps {
  rule: TemplatePortRule | TemplatePathRule;
  allRules: Rule[];
  ruleType: "PORT" | "PATH";
  templateType?: TemplateType;
  title: string;
}

export function RestrictPortToAllowedPaths({
  rule,
  allRules,
  ruleType,
  templateType,
  title,
}: RestrictPortToAllowedPathsProps) {
  const [
    isRestrictToAllowedPathsDrawerOpen,
    setIsRestrictToAllowedPathsDrawerOpen,
  ] = useState(false);

  const onClick = () => {
    setIsRestrictToAllowedPathsDrawerOpen(true);
  };

  const onRestrictToAllowedPathsDrawerClose = () => {
    setIsRestrictToAllowedPathsDrawerOpen(false);
  };

  const rules = useMemo(() => {
    if (ruleType === "PORT") {
      return [rule as Port];
    } else {
      return [rule as Path];
    }
  }, [rule, ruleType]);

  return (
    <>
      <Tooltip
        title={window.getCTTranslatedText(
          rule?.oobTemplate
            ? "System template cannot be modified"
            : "restrictPortsToAllowedPathsWarningForPorts"
        )}
      >
        <span>
          <IconButton
            disabled={rule?.oobTemplate}
            onClick={onClick}
            disableRipple={rule?.oobTemplate}
            sx={{ cursor: rule?.oobTemplate ? `not-allowed` : "pointer" }}
          >
            <GppGoodOutlinedIcon
              color={rule?.oobTemplate ? "disabled" : "warning"}
            />
          </IconButton>
        </span>
      </Tooltip>
      <RestrictToAllowedPathsPreviewDrawer
        isOpen={isRestrictToAllowedPathsDrawerOpen}
        onClose={onRestrictToAllowedPathsDrawerClose}
        rules={rules}
        allRules={allRules}
        ruleType={ruleType}
        templateID={rule?.templateId}
        templateType={templateType}
        title={title}
      />
    </>
  );
}
