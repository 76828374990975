import { Stack } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { ListTable } from "modules/add-to-templates/components/list-table/ListTable";
import { useAssetTemplates } from "modules/asset-templates/hooks/useAssetTemplates";
import { SortOrder } from "modules/core/types";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { PendingTemplatesProps } from "pages/assets/types";
import { RemoveTemplateDialog } from "pages/templates/components/remove-template-dialog";
import {
  ASSET_TEMPLATES_COLUMNS,
  ASSIGNED_TEMPLATES_TAG_COLUMNS,
  BASE_TEMPLATES_LIST_COLUMNS,
} from "pages/templates/components/template-data-grid/constants";
import { Template } from "pages/templates/types";
import { useMemo } from "react";

interface PendingTemplatesDataGridProps {
  rows?: Array<PendingTemplatesProps> | null;
}

export const PendingTemplatesDataGrid = ({
  rows = [],
}: PendingTemplatesDataGridProps) => {
  const criteria = `templateid in (${rows
    ?.map(row => `'${row.templateId}'`)
    .join(", ")})`;

  const {
    templateList,
    isLoading,
    templateListMutation,
    onSortChange,
    setPageSize,
    setPage,
    pageSize,
    page: pageCount,
    totalCount,
  } = useAssetTemplates({ criteria });

  return (
    <AssignedTemplatesViewDrawer
      templateList={templateList}
      templatesSuggestMutation={templateListMutation}
      page={"assets"}
      onSortChange={onSortChange}
      canDelete={false}
      isLoading={isLoading}
      setPageSize={setPageSize}
      setPage={setPage}
      pageCount={pageCount}
      totalCount={totalCount}
      pageSize={pageSize}
    />
  );
};

interface AssignedTemplatesViewDrawerProps {
  templateList: Template[] | undefined;
  templatesSuggestMutation: any;
  isLoading?: boolean;
  page?: string;
  policyId?: string;
  onSortChange: (sort: Array<SortOrder>) => void;
  canDelete?: boolean;
  sort?: Array<SortOrder>;
  setPage?: (pageNumber: number) => void;
  setPageSize?: (pageSize: number) => void;
  pageSize?: number;
  pageCount?: number;
  totalCount?: number;
}

const AssignedTemplatesViewDrawer = ({
  templateList,
  templatesSuggestMutation,
  page = "assets",
  isLoading,
  policyId,
  onSortChange,
  canDelete = true,
  sort,
  setPage,
  setPageSize,
  pageCount,
  pageSize,
  totalCount,
}: AssignedTemplatesViewDrawerProps) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const columns = useMemo(() => {
    let COLUMNS: GridColDef[] = BASE_TEMPLATES_LIST_COLUMNS;
    if (!canDelete) {
      return COLUMNS;
    }
    if (page === "tags" && userPermissions.has("UPDATE_TAGBASEDPOLICY")) {
      COLUMNS = [
        ...ASSIGNED_TEMPLATES_TAG_COLUMNS,
        {
          field: "action",
          headerName: "Action",
          sortable: false,
          width: 70,
          flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
          renderCell: (params: GridRenderCellParams<any, any, any>) => {
            return (
              <RemoveTemplateDialog
                template={params.row}
                policyId={policyId}
                page="tags"
              />
            );
          },
        },
      ];
    } else if (userPermissions.has("UPDATE_ASSET")) {
      COLUMNS = ASSET_TEMPLATES_COLUMNS;
    }
    return COLUMNS;
  }, [page, policyId, userPermissions, canDelete]);

  return (
    <Stack sx={{ height: "100%", width: "100%" }} alignItems="flex-start">
      <Stack
        alignItems="flex-start"
        sx={{ pt: 4, width: "100%", height: "100%" }}
      >
        <ListTable
          list={templateList ?? []}
          columns={columns}
          mutation={templatesSuggestMutation}
          isLoading={isLoading}
          rowClickCallback={() => {}}
          onSortChange={onSortChange}
          setPage={setPage}
          setPageSize={setPageSize}
          page={pageCount}
          pageSize={pageSize}
          totalCount={totalCount}
        />
      </Stack>
    </Stack>
  );
};
