import { Box, Stack, Tooltip } from "@mui/material";

import { NetworkColumnRenderProps } from "pages/networks/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function Provider({ network, viewOnly }: NetworkColumnRenderProps) {
  if (!network?.namedNetworkId) {
    return <Box style={{ width: 120 }} />;
  }

  return (
    <Stack sx={{ maxWidth: "100%" }} alignItems="left" justifyContent="center">
      <Tooltip title={network?.provider ?? ""}>
        <CTDatagridButton
          asText={viewOnly === true || !network?.provider}
          isDisabled={viewOnly === true || !network?.provider}
          facetName={"provider"}
          facetOption={network.provider ?? ""}
        >
          {network?.provider || ""}
        </CTDatagridButton>
      </Tooltip>
    </Stack>
  );
}
