import { Stack } from "@mui/material";
import { useTagValueList } from "./hooks";
import { CircularCenteredLoader } from "common/atoms/loader";
import { TagDataGrid } from "../tag-data-grid";

export const TagValueList = () => {
  const { isLoading, tagValueList } = useTagValueList();

  if (isLoading || !tagValueList) {
    return (
      <Stack
        direction={"row"}
        alignItems="flex-start"
        spacing={3}
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <CircularCenteredLoader />
      </Stack>
    );
  }

  return (
    <>
      <TagDataGrid rows={tagValueList} loading={isLoading} />
    </>
  );
};
