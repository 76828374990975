import { useTheme } from "@mui/material";

export const ClarotyIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.036 19.0804L38.3726 17.758L38.3761 17.7509C38.3761 17.7509 44.3041 23.6044 38.3407 30.3053L37.0643 29.0927C36.5467 35.7404 31.1363 36.6835 29.5658 36.8147L30.5974 37.8996C28.3425 39.9027 26.1905 40.5622 24.3185 40.5551V32.4716C24.1909 32.4786 24.0668 32.4821 23.9391 32.4821H23.9179C19.3337 32.4716 15.6216 28.7524 15.6216 24.1682C15.6216 19.5839 19.3337 15.8648 23.9179 15.8541H23.9391C24.0668 15.8541 24.1944 15.8577 24.3185 15.8648V7.44439C26.1905 7.4373 28.3461 8.09675 30.5974 10.0964L29.3778 11.3798C30.6932 11.4614 36.4828 12.213 37.036 19.0804ZM32.2461 23.8066L36.7452 19.3641H36.7416L30.8279 19.513C31.6647 20.7468 32.1787 22.2182 32.2461 23.8066ZM29.0765 17.6304L29.1119 11.6634L25.0524 15.9321C26.5592 16.1307 27.9384 16.737 29.0765 17.6304ZM23.9427 20.5731C21.9537 20.5731 20.3441 22.1827 20.3441 24.1717C20.3441 26.1607 21.9537 27.7703 23.9427 27.7703C25.9317 27.7703 27.5413 26.1607 27.5413 24.1717C27.5413 22.1827 25.9317 20.5731 23.9427 20.5731ZM29.1013 30.6953C28.027 31.5427 26.74 32.1347 25.3325 32.3722V32.3758L29.1368 36.375L29.1013 30.6953ZM32.2496 24.5227C32.1858 26.0614 31.7036 27.4902 30.913 28.7028H30.9166L36.8019 28.8517L32.2496 24.5227Z"
        fill="#E20AB7"
      />
      <path
        d="M13.0873 24.0022C13.0873 29.2282 16.9447 33.5677 21.965 34.33V40.492C13.5694 39.6871 6.99976 32.614 6.99976 24.0058C6.99976 15.3975 13.5694 8.32435 21.965 7.51953V13.6744C16.9447 14.4367 13.0873 18.7762 13.0873 24.0022Z"
        fill="#E20AB7"
      />
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="3.5"
        stroke={theme.palette.text.primary}
        strokeOpacity="0.12"
      />
    </svg>
  );
};
