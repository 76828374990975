import { Box, Stack } from "@mui/material";
import { useCommonStore } from "common/store";
import { PortColumnRenderProps } from "pages/ports/types";
import LanguageIcon from "@mui/icons-material/Language";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function PortOpenToInternet({
  port,
  viewOnly,
  useFacetStore = useCommonStore,
}: PortColumnRenderProps) {
  if (!port.lpId) {
    return <Box style={{ width: 32, minHeight: 45 }} />;
  }

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ minHeight: 45 }}>
      <CTDatagridButton
        asText={viewOnly === true}
        isDisabled={viewOnly === true}
        useFacetStore={useFacetStore}
        facetName={"listeningonpublicinterface"}
        facetOption={port.listeningonpublicinterface.toString() ?? ""}
      >
        <LanguageIcon
          sx={{ width: 24, height: 24, mt: 1.25 }}
          color={port.listeningonpublicinterface ? "success" : "disabled"}
        ></LanguageIcon>
      </CTDatagridButton>
    </Stack>
  );
}
