import { Box, Button, Stack, Tooltip } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { PendingWorkRequestsDrawer } from "modules/PendingWorkRequestsDrawer";
import { useWorkRequestStore } from "modules/PendingWorkRequestsDrawer/store";
import { CreateTagBasedPolicyDrawer } from "modules/create-tag-based-policy-drawer";
import { CreateReportDialog } from "pages/reports/components/create-report-dialog";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";
import { useState } from "react";
import { Link } from "react-router-dom";

export const SegmentsHeader = () => {
  const [isCreateTagPolicyDrawerOpen, setIsCreateTagPolicyDrawerOpen] =
    useState(false);
  const [isCreateReportDrawerOpen, setIsCreateReportDrawerOpen] =
    useState(false);
  const selectedCriteria = useTagPolicyStore(state => state.selectedCriteria);
  const setSelectedCriteria = useTagPolicyStore(
    state => state.setSelectedCriteria
  );
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const isPendingWorkRequestsDrawerOpen = useWorkRequestStore(
    state => state.isOpen
  );
  const setIsPendingWorkRequestsDrawerOpen = useWorkRequestStore(
    state => state.setOpen
  );

  const TooltipText =
    selectedCriteria.length > 5
      ? window.getCTTranslatedText("PleaseSelectUpto5SegmentsToCreateAReport")
      : "";

  return (
    <Stack alignItems="center" direction={"row"} justifyContent={"flex-end"}>
      {userPermissions.has("CREATE_TAGBASEDPOLICY") && (
        <>
          {(selectedCriteria?.length ?? 0) > 0 ? (
            <Tooltip title={TooltipText}>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={selectedCriteria.length > 5}
                  onClick={() => {
                    setIsCreateReportDrawerOpen(true);
                  }}
                  sx={{
                    ml: 2,
                  }}
                >
                  {window.getCTTranslatedText("CreateReport")}
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Link to="/reports">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {}}
                sx={{
                  ml: 2,
                }}
              >
                {window.getCTTranslatedText("Reports")}
              </Button>
            </Link>
          )}

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsCreateTagPolicyDrawerOpen(true);
            }}
            sx={{
              ml: 2,
            }}
          >
            {window.getCTTranslatedText("Create Access Policy")}
          </Button>

          {isCreateTagPolicyDrawerOpen && (
            <CreateTagBasedPolicyDrawer
              isOpen={isCreateTagPolicyDrawerOpen}
              onClose={() => {
                setIsCreateTagPolicyDrawerOpen(false);
              }}
            />
          )}

          {isCreateReportDrawerOpen && (
            <CreateReportDialog
              selectedCriteria={selectedCriteria}
              open={isCreateReportDrawerOpen}
              onClose={() => {
                setIsCreateReportDrawerOpen(false);
                setSelectedCriteria([]);
              }}
            />
          )}

          {isPendingWorkRequestsDrawerOpen && (
            <PendingWorkRequestsDrawer
              isOpen={isPendingWorkRequestsDrawerOpen}
              onClose={() => setIsPendingWorkRequestsDrawerOpen(false)}
            />
          )}

          <Link to="/segment/create">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {}}
              sx={{
                ml: 2,
              }}
            >
              {window.getCTTranslatedText("CreateSegment")}
            </Button>
          </Link>
        </>
      )}
    </Stack>
  );
};
