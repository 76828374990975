import { Stack } from "@mui/material";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

import { TemplateColumnRenderProps } from "pages/templates/types";

export function TemplateCategory({
  template,
  viewOnly,
}: TemplateColumnRenderProps) {
  const category = template?.templateCategory;

  const facetName = "templatecategory";
  const optionName = category?.toString() || "";

  return (
    <Stack
      sx={{ maxWidth: "100%" }}
      alignItems="center"
      justifyContent="center"
      justifyItems={"center"}
    >
      <CTDatagridButton
        asText={viewOnly === true}
        isDisabled={viewOnly === true}
        facetName={facetName}
        facetOption={optionName}
        sx={{
          alignItems: "center",
        }}
      >
        {window.getCTTranslatedText(category ?? "")}
      </CTDatagridButton>
    </Stack>
  );
}
