import { Stack, Typography, useTheme } from "@mui/material";
import { CopyButton } from "common/atoms/copy-button";
import { useKnowledgeBaseUrl } from "common/hooks/useKnowledgeBaseUrl";
import { Terminal } from "pages/agents/components/agent-download-page/Terminal";

export const IDPTokenManagement = () => {
  const theme = useTheme();
  const { protocol, host } = window.location;

  const rpmURL = `wget ${protocol}//artifacts-${host}/ct-ldap-to-scim-bridge/ldap-scim-bridge-24.6-1.el9.x86_64.rpm`;
  const debianURL = `wget ${protocol}//artifacts-${host}/ct-ldap-to-scim-bridge/ldap-scim-bridge-v24_6_1.deb`;

  return (
    <Stack spacing={8} alignItems={"flex-start"}>
      <Stack spacing={3}>
        <Typography variant="overline" color="text.secondary" fontSize={14}>
          {window.getCTTranslatedText("Method1Preferred")}
        </Typography>
        <Typography variant="body2">
          {window.getCTTranslatedText("GenerateTokenInfo")}
        </Typography>
        <Typography variant="body2">
          {window.getCTTranslatedText("SCIMMethod2Instruction")}
        </Typography>
      </Stack>

      <Stack spacing={3}>
        <Typography variant="overline" color="text.secondary" fontSize={14}>
          {window.getCTTranslatedText("Method2")}
        </Typography>
        <Stack>
          <ul style={{ width: "100%" }}>
            <li>
              <Typography variant="body2">
                {window.getCTTranslatedText(
                  "GenerateNewTokenForIdentityConnector",
                )}
              </Typography>
            </li>
            <li>
              <Typography variant="body2" pt={3}>
                {window.getCTTranslatedText(
                  "DownloadAndInstallIdentityConnectorPackage",
                )}
              </Typography>
              <Typography variant="body2" pt={3}>
                {window.getCTTranslatedText("DebianPackage")}
              </Typography>

              <Stack mt={2}>
                <Terminal>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    alignItems="center"
                  >
                    <Typography variant="body2">
                      <code>{debianURL}</code>
                    </Typography>
                    <CopyButton
                      text={debianURL}
                      sx={{
                        color:
                          theme.palette.mode === "dark"
                            ? undefined
                            : theme.palette.background.default,
                      }}
                    />
                  </Stack>
                </Terminal>
              </Stack>

              <Typography variant="body2" pt={3}>
                {window.getCTTranslatedText("RPMPackage")}
              </Typography>

              <Stack mt={2}>
                <Terminal>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    alignItems="center"
                  >
                    <Typography variant="body2">
                      <code>{rpmURL}</code>
                    </Typography>
                    <CopyButton
                      text={rpmURL}
                      sx={{
                        color:
                          theme.palette.mode === "dark"
                            ? undefined
                            : theme.palette.background.default,
                      }}
                    />
                  </Stack>
                </Terminal>
              </Stack>
            </li>
          </ul>
          <ConnectorInstallationGuide
            url={
              "https://docs.ng.colortokens.com/en/article/identity-connector"
            }
          />
        </Stack>
      </Stack>

      <Stack spacing={3}>
        <Typography variant="overline" color="text.secondary" fontSize={14}>
          {window.getCTTranslatedText("FinalStep")}
        </Typography>

        <Typography variant="body2">
          {window.getCTTranslatedText(
            "ViewImportedDataUnderUsersOptionInXshield",
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};

const ConnectorInstallationGuide = ({ url }: { url: string }) => {
  const { getUpdatedUrl } = useKnowledgeBaseUrl([url]);

  return (
    <Typography
      component="a"
      href={getUpdatedUrl(url ?? "")}
      target="_blank"
      variant="body2"
      mt={1}
      sx={{ textDecoration: "none", color: "primary.main" }}
    >
      {window.getCTTranslatedText("ReferToIdentityConnectorInstallationGuide")}
    </Typography>
  );
};
