import { AgentActionType } from "pages/agents/components/agent-data-grid-toolbar/Toolbar";
import { AgentVersions } from "pages/agents/components/agent-downlaod-version/useVersionAPI";
import { agent_types } from "pages/agents/components/agent-download-page/constants";
import { AgentDownloadSection } from "pages/agents/components/agent-download-page/type";
import { AgentGroupByObj } from "pages/agents/components/agent-drawers/hooks/useGroupByAgentData";

interface AgentDownloadSectionOptions {
  availableServerUsersPlatforms: string[];
  availableContainerPlatforms: string[];
  renderServerUserAgentActions: () => React.ReactNode;
  shouldShowContainerAgentSection: boolean;
  AgentPlatformData?: AgentVersions;
  page?: string;
  agentsGroupByPlatforms?: AgentGroupByObj;
  setAgentsGroupByPlatforms?: React.Dispatch<
    React.SetStateAction<AgentGroupByObj | undefined>
  >;
  agentType?: agent_types;
}

export function getSectionsData({
  availableServerUsersPlatforms,
  availableContainerPlatforms,
  renderServerUserAgentActions,
  shouldShowContainerAgentSection,
  AgentPlatformData,
  page,
  agentsGroupByPlatforms,
  setAgentsGroupByPlatforms,
  agentType,
}: AgentDownloadSectionOptions): AgentDownloadSection[] {
  const sections: AgentDownloadSection[] = [];

  if (!shouldShowContainerAgentSection) {
    sections.push({
      header: {
        title: "Server/User Agents",
        renderActions: renderServerUserAgentActions,
      },
      list: availableServerUsersPlatforms,
      shouldShowSection: true,
      shouldShowList: AgentPlatformData != null,
      shouldShowListItem: (platform?: string) =>
        Boolean(agentsGroupByPlatforms?.[platform ?? ""]?.data) ||
        page !== AgentActionType.UPGRADE,
      agentType,
      agentsGroupByPlatforms,
      setAgentsGroupByPlatforms,
    });
  }

  if (shouldShowContainerAgentSection) {
    sections.push({
      header: {
        title: "Containers",
        renderActions: renderServerUserAgentActions,
      },
      list: availableContainerPlatforms,
      shouldShowSection: shouldShowContainerAgentSection,
      shouldShowList: AgentPlatformData != null,
      shouldShowListItem: () => shouldShowContainerAgentSection,
      agentType: agent_types.CT_CONTAINER_AGENT,
      agentsGroupByPlatforms,
      setAgentsGroupByPlatforms,
    });
  }

  return sections;
}
