import { Typography, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

export const SECURITY_PATCHES_COLUMNS: GridColDef[] = [
  {
    field: "packageName",
    headerName: "Package",
    minWidth: 200,
    flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Tooltip title={`${params?.row?.packageName}`}>
          <Typography variant="body2">{params?.row?.packageName}</Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "release",
    headerName: "Current Version",
    minWidth: 200,
    flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Tooltip title={`${params?.row?.release} ${params?.row?.version}`}>
          <Typography variant="body2">
            {params?.row?.release} {params?.row?.version}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "newRelease",
    headerName: "Available Version",
    minWidth: 200,
    flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
    sortable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return (
        <Tooltip
          title={`${params?.row?.newRelease} ${params?.row?.newVersion}`}
        >
          <Typography variant="body2">
            {params?.row?.newRelease} {params?.row?.newVersion}
          </Typography>
        </Tooltip>
      );
    },
  },
];
