import {
  NotificationFormKeys,
  TeamsNotificationActionEnum,
  TeamsNotificationActionLabelsEnums,
  TeamsNotificationFormKeysDisplayText,
  TeamsNotificationFormProps,
} from "./types";
import { Autocomplete, Chip, Grid, TextField } from "@mui/material";
import { TeamsChannel } from "pages/Integration/constants";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";

export const TeamsNotificationForm = ({
  selectedChannels,
  setSelectedChannels,
  showLabel = true,
  teamsChannels,
}: TeamsNotificationFormProps) => {
  const onChannelChange = (event: any, value: TeamsChannel[]) => {
    setSelectedChannels(value);
  };
  const actionValues = [
    {
      label: TeamsNotificationActionLabelsEnums.Channels,
      value: TeamsNotificationActionEnum.Channels,
    },
  ];

  return (
    <Grid container columnSpacing={3} sx={{ width: "100%" }}>
      <Grid item xs={3}>
        <CTSelectDropDown
          field={NotificationFormKeys.Action}
          displayName={window.getCTTranslatedText(
            TeamsNotificationFormKeysDisplayText[NotificationFormKeys.Action]
          )}
          selectedValue={window.getCTTranslatedText(
            TeamsNotificationActionLabelsEnums.Channels
          )}
          handleUpdate={() => null}
          showLabel={showLabel}
          data={actionValues}
        />
      </Grid>
      <Grid item xs={9} sx={{ m: 0 }}>
        <Autocomplete
          multiple
          id="teams-channel-picker"
          options={teamsChannels}
          value={selectedChannels}
          getOptionLabel={v => v.channelName}
          isOptionEqualToValue={(option, value) =>
            option.channelName === value.channelName
          }
          onChange={onChannelChange}
          renderTags={(value: readonly TeamsChannel[], getTagProps) =>
            value.map((option: TeamsChannel, index: number) => (
              <Chip
                variant="filled"
                label={option.channelName}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={window.getCTTranslatedText("Channel Name")}
              error={teamsChannels?.length === 0}
              helperText={
                teamsChannels?.length === 0
                  ? window.getCTTranslatedText("teamsErrorMsg")
                  : window.getCTTranslatedText("AlertRuleHelperText")
              }
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
