import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { CopyButton } from "common/atoms/copy-button";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { getRelativeLastObserved, parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { IDPInfo, TokenAPIKResult } from "pages/Integration/type";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { useCallback, useEffect, useState } from "react";
import { useTokenAPI } from "../../token/hooks";
import { useCreateIDPInfo, useEditIDPInfo } from "../hooks";
import { IdP } from "../types";

export enum IDP_MODE {
  "CREATE" = "Add IdP",
  "EDIT" = "Edit IdP",
  "GENERATE_TOKEN" = "Generate Token",
}

interface IDPProps {
  idpInfo?: IDPInfo;
  mode: IDP_MODE;
  cancel: VoidFunction;
}

export const IDPForm = ({ idpInfo, cancel, mode }: IDPProps) => {
  const createIDPInfo = useCreateIDPInfo();
  const editIDPInfo = useEditIDPInfo(idpInfo?.idpID);
  const [idp, setIDP] = useState<IDPInfo>(idpInfo ?? {});
  const tokenMutation = useTokenAPI(idp.idpID);
  const tokenMutate = tokenMutation.mutate;
  const [generatedToken, setGeneratedToken] = useState<TokenAPIKResult>({});

  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const queryClient = useQueryClient();

  const createIDP = (body: IdP) => {
    createIDPInfo.mutate(body, {
      onSuccess: response => {
        setIDP(response);
        setSnackbar(
          true,
          SnackBarSeverity.Success,
          "CreateIDPRequestSubmittedSuccessfully",
        );
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const editIDP = (body: IdP) => {
    editIDPInfo.mutate(body, {
      onSuccess: response => {
        queryClient.invalidateQueries({
          queryKey: ["idplist"],
        });
        setSnackbar(
          true,
          SnackBarSeverity.Success,
          "UpdateIDPRequestSubmittedSuccessfully",
        );
        setTimeout(() => {
          cancel();
        }, 300);
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  };

  const updateIDP = () => {
    const body: IdP = {
      idpName: idp.idpName,
    };
    if (mode === IDP_MODE.CREATE) {
      createIDP(body);
    } else if (mode === IDP_MODE.EDIT) {
      editIDP(body);
    }
  };

  const handleUpdate = (e: TextFieldUpdate) => {
    setIDP(prev => ({ ...prev, idpName: e.value }));
  };

  const tokenGenerate = useCallback(() => {
    tokenMutate(
      {},
      {
        onSuccess: response => {
          setGeneratedToken(response);
          setSnackbar(
            true,
            SnackBarSeverity.Success,
            "BearerTokenGeneratedSuccessfully",
          );
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      },
    );
  }, [setSnackbar, tokenMutate]);

  useEffect(() => {
    if (mode === IDP_MODE.EDIT) {
      return;
    }

    if (idp.idpID) {
      tokenGenerate();
    }
  }, [idp.idpID, tokenGenerate, mode]);

  return (
    <>
      <DialogContent>
        <FormControl sx={{ m: 0, minWidth: "100%", width: "100%" }}>
          <Stack
            alignItems={"flex-start"}
            style={{ position: "relative", height: "100%", width: "100%" }}
          >
            <Stack
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={4}
              width="100%"
            >
              {mode !== IDP_MODE.GENERATE_TOKEN && (
                <>
                  <CTInputField
                    field="idpName"
                    displayName="IdP Name"
                    value={idp.idpName}
                    handleUpdate={(event: TextFieldUpdate) =>
                      handleUpdate(event)
                    }
                    required={true}
                  />

                  <Stack sx={{ width: "100%" }} alignItems={"flex-end"}>
                    <LoadingButton
                      loading={
                        createIDPInfo?.isLoading || editIDPInfo?.isLoading
                      }
                      onClick={updateIDP}
                      variant="contained"
                      disabled={!idp.idpName}
                    >
                      {window.getCTTranslatedText("Save")}
                    </LoadingButton>
                  </Stack>
                </>
              )}

              {(generatedToken?.token?.length ?? 0) > 0 && (
                <>
                  <Alert severity="warning">
                    {window.getCTTranslatedText("CopyBearerTokenWarning")}
                  </Alert>
                  <Stack sx={{ width: "100%" }} spacing={1}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {window.getCTTranslatedText("BearerToken")}
                    </Typography>

                    <Stack
                      component={Paper}
                      direction="row"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      p={2}
                      width="100%"
                    >
                      <Typography
                        variant="body2"
                        sx={{ lineBreak: "anywhere" }}
                      >
                        <code>{generatedToken?.token}</code>
                      </Typography>
                      <CopyButton text={generatedToken?.token} />
                    </Stack>
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {window.getCTTranslatedText("ExpiryDate")}
                    </Typography>
                    <Typography variant="body2">
                      {capitalizeFirstLetter(
                        getRelativeLastObserved(
                          generatedToken.tokenExpiryTime || "",
                        ),
                      )}
                    </Typography>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </FormControl>
      </DialogContent>

      <DialogActions>
        {(generatedToken?.token?.length ?? 0) > 0 && (
          <Button sx={{ m: 1 }} variant="contained" onClick={cancel}>
            {window.getCTTranslatedText("Done")}
          </Button>
        )}
      </DialogActions>
    </>
  );
};
