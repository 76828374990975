import { Box, Stack, Tooltip, Typography } from "@mui/material";

import { PathColumnRenderProps } from "pages/paths/types";

export function PathDataVolume({ path }: PathColumnRenderProps) {
  if (!path.channelHash || !path.bandwidthInBytes) {
    return <Box style={{ width: 100, minHeight: 45 }} />;
  }

  let number, unit;
  if ((path.bandwidthInBytes as string).split != null) {
    [number, unit] = (path.bandwidthInBytes as string).split(" ");
  }
  return (
    <Box style={{ width: 130 }}>
      <Tooltip
        title={window.getCTTranslatedText("Last 30 days")}
        arrow={false}
        placement="bottom-start"
      >
        <Stack alignItems="flex-start" justifyContent="center">
          <Typography variant="body2">
            {number} <Typography variant="caption">{unit}</Typography>
          </Typography>
        </Stack>
      </Tooltip>
    </Box>
  );
}
