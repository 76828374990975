import { DataGrid } from "modules/data-grid/components/data-grid";
import { BASE_TAG_RULES_COLS, TAG_RULES_COLS } from "../constants";
import { Button, Stack, Typography } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { TagRule } from "../types";
import { TagRulesToolbar } from "./tag-rul-data-grid-toolbar";

export const TagRulesDataGrid = (props: DataGridProps<TagRule>) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const [selection, setSelection] = useState<Array<GridRowId>>([]);

  const onClickClearSelection = () => {
    setSelection([]);
  };

  const selectedData: Array<TagRule> | undefined = useMemo(() => {
    return props.rows?.filter((row: TagRule) => {
      return selection.indexOf(row?.ruleId) !== -1;
    });
  }, [selection, props.rows]);

  const selectedRawData: Array<TagRule> | undefined = useMemo(() => {
    return (props?.rawData ?? [])?.filter((row: TagRule) => {
      return selection.indexOf(row?.ruleId) !== -1;
    });
  }, [selection, props?.rawData]);

  const columns = useMemo(() => {
    let columns = userPermissions.has("DELETE_TAGRULE")
      ? TAG_RULES_COLS
      : BASE_TAG_RULES_COLS;
    return columns;
  }, [userPermissions]);

  return (
    <Stack sx={{ width: "100%", height: "100%" }}>
      {selectedData && selectedData?.length > 0 && (
        <TagRulesToolbar
          selectedData={selectedData}
          hideToolbar={onClickClearSelection}
          show={selection.length > 0}
        />
      )}

      <DataGrid
        rowSelectionModel={selection}
        onRowSelectionModelChange={selectionModel => {
          setSelection(selectionModel);
        }}
        slots={{
          noRowsOverlay: () => {
            return (
              <Stack
                alignItems={"center"}
                justifyContent="center"
                sx={{
                  zIndex: 100,
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Typography variant="body2">
                  {window.getCTTranslatedText("No results")}
                </Typography>
                {userPermissions.has("CREATE_TAGRULE") && props.hasNoData && (
                  <Link to="/tag-rules/create">
                    <Button>
                      {window.getCTTranslatedText("Create tag label rule")}
                    </Button>
                  </Link>
                )}
              </Stack>
            );
          },
        }}
        // checkboxSelection
        rowHeight={64}
        columns={columns}
        pagination={true}
        getRowId={({ ruleId: id }: TagRule) => `${id}`}
        paginationMode="server"
        sortingMode="server"
        {...props}
        selectedRawData={selectedRawData}
      />
    </Stack>
  );
};
