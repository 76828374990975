import { Box, Paper, Stack, useTheme } from "@mui/material";
import { useCore } from "modules/core";
import { Scope } from "modules/scope-metadata/types";
import { Asset } from "pages/assets/types";
import { useAssetDevicesStore, useAssetStore } from "pages/assets/store";
import { FACET_OPTION_MAPPING } from "pages/users-segmentation/components/users/constants";
import { useAssetsAPI } from "pages/assets/components/asset-data-grid/hooks";
import { useEffect } from "react";
import { useAssetsFacetsOrder } from "pages/assets/constants";
import { useCommonStore } from "common/store";
import {
  calculateReviewCoverage,
  getFilterNameFromColumnName,
} from "pages/assets/components/asset-data-grid/helpers";
import { getAssetInterfaceIPs } from "pages/asset/components/metadata-item-wrapper/helpers";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { FacetControllerGroup } from "modules/facets";
import {
  ApplianceDevicesFilterKeys,
  ApplianceDevicesFilterValues,
} from "pages/appliance-detail/types";
import { FacetOptionState, Operator } from "modules/facets/types";
import { AssetDataGrid } from "pages/assets/components/asset-data-grid";
import { ASSET_COLUMNS } from "pages/appliance-detail/constants";
import { AssetVisualizeButton } from "pages/assets/components/asset-visualize-button";

interface ApplianceDevicesProps {
  applianceName?: string;
  deviceType?: string;
}

export const ApplianceDevices = ({
  applianceName,
  deviceType,
}: ApplianceDevicesProps) => {
  const theme = useTheme();
  const setFacets = useCommonStore(state => state.setFacets);
  const apiRefreshRequest = useAssetStore(state => state.apiRefreshRequest);

  const requestRefresh = useAssetDevicesStore(state => state.requestAPIRefresh);

  useEffect(() => {
    if (applianceName) {
      const applianceFilter: FacetOptionState = new Map([
        [applianceName, { isSelected: true, operator: Operator.EQUAL }],
      ]);
      const deviceFilter: FacetOptionState = new Map([
        [
          ApplianceDevicesFilterValues.Device,
          { isSelected: true, operator: Operator.EQUAL },
        ],
      ]);
      let facets = new Map([
        [ApplianceDevicesFilterKeys.ApplianceName, applianceFilter],
        [ApplianceDevicesFilterKeys.Type, deviceFilter],
      ]);
      if (deviceType) {
        const deviceTypeFilter = new Map([
          [deviceType, { isSelected: true, operator: Operator.EQUAL }],
        ]);
        facets.set(ApplianceDevicesFilterKeys.Status, deviceTypeFilter);
      }
      setFacets(facets);
    }

    return () => setFacets(undefined);
  }, [applianceName, setFacets, deviceType]);

  useEffect(() => {
    requestRefresh();
  }, [apiRefreshRequest, requestRefresh]);

  const dataMapper = (asset: Asset) => {
    asset.reviewCoverage = calculateReviewCoverage(asset);
    if (asset?.containerNamespace || asset?.clusterIdentifier) {
      if (!asset?.coreTags) {
        asset.coreTags = {};
      }
      if (asset?.containerNamespace) {
        asset.coreTags.containerNamespace = asset?.containerNamespace;
      }
      if (asset?.clusterIdentifier) {
        asset.coreTags.clusterIdentifier = asset?.clusterIdentifier;
      }
    }
    asset.ipAddresses = `"${getAssetInterfaceIPs(asset.interfaces ?? [])}"`;
    return asset;
  };

  const coreResponse = useCore<Asset>({
    useStore: useAssetDevicesStore,
    facetGroupInfo: useAssetsFacetsOrder(),
    scope: Scope.Asset,
    facetOptionsDisplayMapping: FACET_OPTION_MAPPING,
    defaultSortOrder: [{ field: "assetrisk", order: "desc" }],
    dataMapper: dataMapper,
    skipSearch: true,
    useApi: useAssetsAPI,
    pageSize: 100,
  });

  let metadata = useCommonStore(state => state.metadata);
  const searchCriteria = useCommonStore(state => state.currentSearchCriteria);

  if (metadata?.columns?.assetavailability) {
    metadata.columns.assetavailability.displayName = "Status";
  }

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack direction={"row"} alignItems="center">
        <Stack sx={{ flex: 1 }}>
          <FacetOpenCloseController
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
            />
          </FacetOpenCloseController>
        </Stack>
        <AssetVisualizeButton />
      </Stack>
      <Box
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          overflowY: "auto",
          flex: 1,
          height: "100%",
        }}
      >
        <Paper sx={{ height: "100%" }}>
          <AssetDataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: "assetrisk", sort: "desc" }],
              },
            }}
            metadata={metadata}
            rows={coreResponse.rows}
            rowCount={coreResponse.rowCount}
            mutation={coreResponse.mutation}
            onPageChange={coreResponse.onPageChange}
            page={coreResponse.page}
            pageSize={coreResponse.pageSize}
            onPageSizeChange={coreResponse.onPageSizeChange}
            onSortChange={sortModel => {
              if (sortModel?.length > 0) {
                sortModel = sortModel.map(item => {
                  item.field = getFilterNameFromColumnName(item.field);
                  return item;
                });
                coreResponse.onSortChange(sortModel);
              }
            }}
            hasNoData={
              coreResponse.mutation.isSuccess &&
              coreResponse.rowCount === 0 &&
              searchCriteria === "*"
            }
            triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
            getExportStatus={coreResponse?.getExportStatus}
            getUrlToDownload={coreResponse?.getUrlToDownload}
            resetDownloadUrl={coreResponse?.resetDownloadUrl}
            rawData={coreResponse?.rawData}
            columns={ASSET_COLUMNS(false)}
            showDeviceActions={true}
          />
        </Paper>
      </Box>
    </Stack>
  );
};
