import { GridColDef } from "@mui/x-data-grid-pro";
// import { NNHitDetails } from "./components/NNHitDetails";
import { NNHit } from "./types";
import { NNHitLastObserved } from "./components/NNHit-last-observed";

export const NN_HITS_COLS: GridColDef<NNHit>[] = [
  // {
  //   field: "details",
  //   headerName: "Details",
  //   width: 140,
  //   flex: 0.4,
  //   sortingOrder: ["asc", "desc"],
  //   sortable: false,
  //   renderCell: params => <NNHitDetails nnHit={params.row} />,
  // },
  {
    field: "namedNetworkIpAddress",
    headerName: "Matched IP Address",
    width: 140,
    flex: 1,
    sortable: false,
  },
  {
    field: "domain",
    headerName: "Domain",
    flex: 1,
  },
  {
    field: "lastHitObserved",
    headerName: "Last Observed",
    width: 140,
    flex: 0.5,
    renderCell: params => <NNHitLastObserved nnHit={params.row} />,
  },
];
