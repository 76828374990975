import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { SavedQuery } from "modules/saved-query/types";
import { useDeleteQuery } from "./hooks/useDeleteQuery";

const dialogMinWidth = 300;

export interface DeleteQueryProps {
  query: SavedQuery;
  handleClose: () => void;
  allowDelete: boolean;
}

export function DeleteQuery({
  query,
  handleClose,
  allowDelete,
}: DeleteQueryProps) {
  const { deleteQuery, isLoading } = useDeleteQuery({ query });

  return (
    <>
      <Dialog open={allowDelete} onClose={handleClose}>
        <DialogTitle>{window.getCTTranslatedText(`Confirm`)}</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: dialogMinWidth }}>
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="body1">
                {`${window.getCTTranslatedText(
                  "Are you sure you want to delete query"
                )} "${query?.name}"?`}
              </Typography>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            {window.getCTTranslatedText("Cancel")}
          </Button>
          <LoadingButton
            loading={isLoading}
            onClick={deleteQuery}
            autoFocus
            color={"error"}
            type="submit"
            variant="contained"
          >
            {window.getCTTranslatedText("Delete")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
