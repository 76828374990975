import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { UserGroupStatus } from "./UserGroupToolbar";

interface IUpdateActionsProps {
  handleButtonChange: (btnActionType: string) => void;
}

export const UserGroupUpdateAction = ({
  handleButtonChange,
}: IUpdateActionsProps) => {
  const userGroupStatusAnchorRef = useRef<HTMLDivElement>(null);
  const [openUserGroupStatusMenu, setOpenUserGroupStatusMenu] = useState(false);

  const handleMenuToggle = (
    ref: React.RefObject<HTMLDivElement> | undefined
  ) => {
    if (ref) {
      setOpenUserGroupStatusMenu(prevOpen => !prevOpen);
    }
  };

  const handleStatusChange = (btnActionType: string) => {
    setOpenUserGroupStatusMenu(false);
    handleButtonChange(btnActionType);
  };

  const popperMenuContent = (id: string, options: UserGroupStatus[]) => {
    return (
      <MenuList id={id}>
        {options.map(option => (
          <MenuItem key={option} onClick={() => handleStatusChange(option)}>
            <Stack
              alignItems="flex-start"
              justifyContent="flex-start"
              justifyItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Typography
                variant="body2"
                sx={{
                  textAlign: "left",
                }}
              >
                {window.getCTTranslatedText(option)}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </MenuList>
    );
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems={"center"}
      sx={{ height: "100%" }}
    >
      <ButtonGroup
        variant="outlined"
        ref={userGroupStatusAnchorRef}
        aria-label="split button"
      >
        <Button
          aria-controls={
            openUserGroupStatusMenu ? "user-group-status-menu" : undefined
          }
          aria-expanded={openUserGroupStatusMenu ? "true" : undefined}
          aria-label="Change Status"
          aria-haspopup="menu"
          onClick={() => handleMenuToggle(userGroupStatusAnchorRef)}
          endIcon={<ArrowDropDownIcon />}
        >
          {window.getCTTranslatedText("Change Status")}
        </Button>
      </ButtonGroup>

      <Menu
        onClose={() => {
          setOpenUserGroupStatusMenu(false);
        }}
        open={openUserGroupStatusMenu}
        anchorEl={userGroupStatusAnchorRef.current}
      >
        {openUserGroupStatusMenu &&
          popperMenuContent(
            "user-group-status-menu",
            Object.values(UserGroupStatus)
          )}
      </Menu>
    </Stack>
  );
};
