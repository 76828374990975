import { createContext, useContext, useMemo, useReducer } from "react";

const CT = createContext<any>(null);

CT.displayName = "CTContext";

function reducer(state: any, action: any) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "DARK_SIDENAV": {
      return { ...state, darkSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARK_MODE": {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

interface CTControllerProviderProps {
  children: React.ReactNode;
}

function CTControllerProvider({ children }: CTControllerProviderProps) {
  const initialState = {
    miniSidenav: false,
    darkSidenav: false,
    sidenavColor: null,
    transparentNavbar: true,
    fixedNavbar: false,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <CT.Provider value={value}>{children}</CT.Provider>;
}

function useCTController() {
  const context = useContext(CT);

  if (!context) {
    throw new Error(
      "useCTController should be used inside the CTControllerProvider."
    );
  }

  return context;
}

// Context module functions
const setMiniSidenav = (dispatch: Function, value: any) =>
  dispatch({ type: "MINI_SIDENAV", value });
const setDarkSidenav = (dispatch: Function, value: any) =>
  dispatch({ type: "DARK_SIDENAV", value });
const setSidenavColor = (dispatch: Function, value: any) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch: Function, value: any) =>
  dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch: Function, value: any) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch: Function, value: any) =>
  dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch: Function, value: any) =>
  dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch: Function, value: any) =>
  dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch: Function, value: any) =>
  dispatch({ type: "DARK_MODE", value });

export {
  CTControllerProvider,
  setDarkMode,
  setDarkSidenav,
  setDirection,
  setFixedNavbar,
  setLayout,
  setMiniSidenav,
  setOpenConfigurator,
  setSidenavColor,
  setTransparentNavbar,
  useCTController,
};
