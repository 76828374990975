import { Path } from "pages/paths/types";
import { Port } from "pages/ports/types";
import {
  PathRuleInt,
  PortRuleInt,
} from "./components/template-form-drawer/components/template-form/types";

export interface TemplateColumnRenderProps {
  template: Template;
  viewOnly?: boolean;
}

export enum TemplateType {
  ApplicationTemplate = "application-template",
  BlockTemplate = "block-template",
}

export interface TemplatePort extends Port {
  oobTemplate?: boolean;
  templateId?: string;
  paths?: Array<Path>;
}

export interface TemplatePath extends Path {
  oobTemplate?: boolean;
  templateId?: string;
}

export interface Template {
  templateId: string;
  templateName: string;
  templateDescription: string;
  createdAt?: string;
  createdBy?: string;
  templateAssignments?: number;
  usergroupTemplateAssignments?: number;
  templateTagBasedPolicyAssignments?: number;
  templatePorts?: number | Array<TemplatePort>;
  templatePaths?: number | Array<TemplatePath>;
  inboundPaths?: Array<TemplatePath>;
  outboundPaths?: Array<TemplatePath>;
  oobTemplate?: boolean;
  templateType?: TemplateType;
  templateCategory?: string;
  totalComments: number;
  templateUnassignmentsPendingFirewallSynchronize: number;
  assignedByTagBasedPolicy: boolean;
  accessPolicyTemplate?: boolean;
}

export interface TemplateFormProps {
  templateId?: string | undefined;
  templateName?: string | undefined;
  templateDescription?: string | undefined;
  templateType?: TemplateType;
  templatePorts?: Array<PortRuleInt>;
  templatePaths?: Array<PathRuleInt>;
  templateCategory?: string;
  comment?: string | null;
}

export interface AddToTemplateListProps {
  templateId?: string;
  templateName?: string;
  templateDescription?: string;
  templateAssignments?: number;
  templatePorts?: number | Array<any>;
  templatePaths?: number | Array<any>;
  oobTemplate?: boolean;
  templateType?: TemplateType;
}

export enum WARNINGS {
  ProcessTemplateWarning = "ProcessTemplateWarning",
}
