import CloseIcon from "@mui/icons-material/Close";
import {
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import { Toolbar } from "common/atoms/toolbar";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useDiagnosticAgents } from "pages/agents/components/agent-data-grid/hooks";
import { useAgentStore } from "pages/agents/store";
import { Appliance } from "pages/appliances/types";
import { useMemo, useState } from "react";
import { isDiagnosticsAllowed } from "../appliance-data-grid/helpers/columnHelpers";
import { useSelectedApplianceData } from "./hooks";

export interface ApplianceDrawerProps {
  isOpen: boolean;
  page: string;
  title?: string;
  data?: any;
  hideToolbar: Function;
  rowCount: number;
  selection: GridRowId[];
  columns?: GridColDef[];
  onCancel: () => void;
  btnTitle?: string;
}

export const ApplianceDiagnosticDrawer = ({
  isOpen,
  page,
  title,
  rowCount,
  selection,
  hideToolbar,
  data,
  columns,
  onCancel,
}: ApplianceDrawerProps) => {
  const notify = useEventSubscriptionStore(state => state.notify);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const [selectionRowIds, setSelectionRowIds] =
    useState<Array<GridRowId>>(selection);
  let updatedData: Array<Appliance> = useMemo(() => {
    return data?.filter((row: Appliance) => {
      return selectionRowIds.indexOf(row.rowId!) !== -1;
    });
  }, [selectionRowIds, data]);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  const diagnosticMutation = useDiagnosticAgents();
  const requestAPIRefresh = useAgentStore(store => store.requestAPIRefresh);

  const selectedItems = useSelectedApplianceData(updatedData);

  let selectedAgentIds = selectedItems
    ?.map((element: string) => `'${element}'`)
    .join(", ")
    .replace(/"/g, "");

  const confirmAgentDiagnostic = async () => {
    if (selectedItems) {
      const body = {
        criteria: `agentId in (${selectedAgentIds})`,
      };
      await diagnosticMutation.mutateAsync(body, {
        onSuccess: response => {
          notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
            label: "AgentDiagnosticRequestSubmittedSuccessfully",
          });
          hideToolbar();
          onCancel();
          requestAPIRefresh();
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
          onCancel();
        },
      });
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onCancel}
      PaperProps={{
        sx: {
          padding: "0px",
          width: "80%",
          minWidth: "1000px",
          height: "100%",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title="Close Drawer">
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={onCancel}
            sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 5, mb: 3, mx: 4 }}>
        <Typography variant="h5">
          <b>{title}</b>
        </Typography>
      </Stack>
      <DialogContent>
        <DataGrid
          checkboxSelection={userPermissions.has("UPGRADE_AGENT")}
          rowSelectionModel={selectionRowIds}
          onRowSelectionModelChange={selectionModel => {
            setSelectionRowIds(selectionModel);
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "agentstatus", sort: "desc" }],
            },
          }}
          rows={data}
          rowCount={rowCount}
          getRowId={({ agentId }: Appliance) => agentId}
          columns={columns}
          isRowSelectable={data =>
            !data.row.isDiagnosticsRunning && isDiagnosticsAllowed(data?.row)
          }
        />
      </DialogContent>
      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <ToolbarAction
          loading={diagnosticMutation.isLoading}
          save={confirmAgentDiagnostic}
          cancel={onCancel}
          isValid={selectedItems?.length > 0}
          actionBtnText={"Collect diagnostics"}
        ></ToolbarAction>
      </DialogActions>
    </Drawer>
  );
};
