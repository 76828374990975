import { debounce, Grid, Stack, useTheme } from "@mui/material";
import { AnalyticsResponse } from "common/types/types";
import { Scope } from "modules/scope-metadata/types";
import { AssetLoader } from "pages/asset/components/asset-loader";
import { SecurityProgressPredictions } from "pages/dashboard/components/security-progress-predictions";
import {
  avgDataMapper,
  barChartRequestBody,
  getChartDates,
} from "pages/dashboard/components/security-progress/helpers";
import { useTimeSeriesAPI } from "pages/dashboard/components/security-progress/hooks";
import { SecurityRisk } from "pages/dashboard/components/security-risk";
import { useEffect, useMemo, useState } from "react";

export function AssetRiskChart({
  criteria,
  border = true,
}: {
  criteria: string;
  border?: boolean;
}) {
  const theme = useTheme();
  const [avgRiskValue, setAvgRiskValue] = useState<number>(0);
  const [predictionsData, setPredictionsData] = useState<
    AnalyticsResponse | undefined
  >(undefined);

  const keyMetricsAvgMutation = useTimeSeriesAPI();
  const riskTrendMutation = useTimeSeriesAPI();

  const keyMetricsAvgMutationObj = useMemo(
    () => debounce(keyMetricsAvgMutation.mutate, 500),
    [keyMetricsAvgMutation.mutate]
  );

  const riskTrendMutationObj = useMemo(
    () => debounce(riskTrendMutation.mutate, 500),
    [riskTrendMutation.mutate]
  );

  useEffect(() => {
    if (criteria) {
      const requestObj = barChartRequestBody({
        criteria: criteria,
        scope: Scope.Asset,
        groupBy: [],
        statistics: ["mean(assetriskscore)"],
      });

      keyMetricsAvgMutationObj(requestObj, {
        onSuccess(data) {
          if (data) {
            const responseData = data;
            const items = responseData?.items || {};

            const avgRiskVal = avgDataMapper({
              items,
              statisticsId: "assetriskscoremean",
            });

            setAvgRiskValue(avgRiskVal || 0);
          }
        },
        onError: () => {
          // setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  }, [keyMetricsAvgMutationObj, criteria]);

  useEffect(() => {
    if (criteria) {
      const date = getChartDates();

      const requestObj = barChartRequestBody({
        criteria: criteria,
        scope: Scope.Asset,
        groupBy: [],
        statistics: ["predict(avg(metricassetriskscore),30)"],
        timeStart: date.timeStart,
        timeEnd: date.timeEnd,
      });

      riskTrendMutationObj(requestObj, {
        onSuccess(data) {
          if (data) {
            setPredictionsData(data);
          }
        },
        onError: () => {
          // setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  }, [riskTrendMutationObj, criteria]);

  if (!criteria) {
    return <Stack flex={1} />;
  }

  if (
    keyMetricsAvgMutation.isLoading ||
    riskTrendMutation.isLoading ||
    isNaN(avgRiskValue) ||
    !predictionsData ||
    !criteria
  ) {
    return (
      <Stack
        flex={1}
        alignItems={"center"}
        sx={{
          minHeight: 290,
          justifyContent: "center",
        }}
      >
        <AssetLoader />
      </Stack>
    );
  }

  const wrapperStyle = () => {
    let style: any = {
      width: "100%",
      justifyContent: "center",
      pl: 5,
      pr: 3,
      pb: 10,
      pt: 5,
    };
    if (border) {
      style = {
        ...style,
        border:
          theme.palette.mode === "dark"
            ? "1px solid rgba(255, 255, 255, 0.12)"
            : "1px solid #d9d9d9",
      };
    }
    return style;
  };

  return (
    <>
      <Stack
        direction={"column"}
        spacing={0}
        borderRadius={1}
        sx={wrapperStyle()}
      >
        <Grid
          container
          columnSpacing={{ xl: 7, lg: 7, md: 5, sm: 4, xs: 4 }}
          sx={{ width: "100%" }}
        >
          <Grid item xl={3} lg={5} md={5} sm={5} xs={5}>
            <Stack spacing={0} sx={{ transform: "scale(0.7)" }}>
              <SecurityRisk
                riskConfig={{
                  value: Math.round(avgRiskValue || 0),
                }}
                viewOnly={true}
              />
            </Stack>
          </Grid>
          <Grid item xl={9} lg={7} md={7} sm={7} xs={7}>
            {predictionsData && (
              <SecurityProgressPredictions
                predictions={{
                  predictionData: predictionsData,
                  height: 200,
                }}
              />
            )}
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
