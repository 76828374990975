import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { getCurrentTenantID } from "hooks/useComplianceController/helper";
import { Scope } from "modules/scope-metadata/types";
import {
  INTEGRATIONS,
  IntegrationConfigType,
  getIntegrationEnum,
} from "pages/Integration/constants";
import { useIntegrationConfigAPI } from "pages/Integration/hooks";
import { AssetType } from "pages/assets/types";
import { useSummarizeAPI } from "pages/dashboard/components/security-progress/hooks";
import { useEffect } from "react";

const STALE_TIME = 5 * 60 * 1000;

export function useAssetTypeFeature() {
  const mutation = useSummarizeAPI("type");
  const mutate = mutation.mutate;

  const assetTypeFeatures = useUserPreferencesStore(
    state => state.assetTypeFeatures
  );
  const setAssetTypeFeatures = useUserPreferencesStore(
    state => state.setAssetTypeFeatures
  );
  const integrationData = useUserPreferencesStore(
    state => state.integrationData
  );
  const setIntegrationData = useUserPreferencesStore(
    state => state.setIntegrationData
  );

  useEffect(() => {
    const body = {
      criteria: "*",
      facetField: "type",
      facetFieldFilter: "",
      scope: Scope.Asset,
    };

    const currentTime = Date.now();
    const currentTenantID = getCurrentTenantID() ?? "";

    if (
      !assetTypeFeatures.data ||
      currentTime - assetTypeFeatures.loadTime > STALE_TIME ||
      currentTenantID !== assetTypeFeatures.tenantID
    ) {
      mutate(body, {
        onSuccess: response => {
          setAssetTypeFeatures({
            data: response,
            loadTime: currentTime,
            tenantID: currentTenantID,
          });
        },
        onError(error) {
          console.log(error);
        },
      });
    }
  }, [mutate, assetTypeFeatures, setAssetTypeFeatures]);

  useIntegrationConfigAPI({
    staleTime: STALE_TIME,
    enabled: !integrationData.data,
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setIntegrationData({
        data,
        loadTime: Date.now(),
        tenantID: getCurrentTenantID() ?? "",
      });
    },
  });

  let activeKeys: (AssetType | INTEGRATIONS)[] = Object.entries(
    assetTypeFeatures.data?.Facet?.values ?? {}
  )
    .filter(([, value]) => value > 0)
    .map(([key]) => key as AssetType);

  integrationData.data?.forEach((i: any) => {
    if (
      getIntegrationEnum(i as IntegrationConfigType) ===
        INTEGRATIONS.SCIM_USERPROVISIONING &&
      i.status
    ) {
      activeKeys.push(INTEGRATIONS.SCIM_USERPROVISIONING);
    }

    if (
      getIntegrationEnum(i as IntegrationConfigType) ===
        INTEGRATIONS.CROWDSTRIKE_EDR &&
      i.status
    ) {
      activeKeys.push(INTEGRATIONS.CROWDSTRIKE_EDR);
    }
  });

  return activeKeys;
}
