import { Theme, useTheme } from "@mui/material";
import { SecuritySlider } from "modules/security-slider";
import { Asset } from "pages/assets/types";
import { ProgressiveSliderLabel } from "pages/ports/components/progressive-enforcement-slider/ProgressiveSliderLabel";
import {
  ProgressiveEnforcementLevel,
  ProgressiveEnforcementLevelLabels,
  ProgressiveEnforcementStatus,
  ProgressiveEnforcementStatusMap,
  ProgressiveStatusSliderMarks,
} from "pages/ports/types";
import { useCallback } from "react";

export interface ProgressiveEnforcementSliderProps {
  asset?: Asset;
  currentStatus?: ProgressiveEnforcementLevel;
  selectedStatus?: ProgressiveEnforcementLevel;
  setSelectedStatus?: (s: ProgressiveEnforcementLevel) => void;
  disabled?: boolean;
  minValue?: ProgressiveEnforcementLevel;
}

export const ProgressiveEnforcementSlider = ({
  asset,
  currentStatus,
  selectedStatus,
  setSelectedStatus,
  disabled = false,
}: ProgressiveEnforcementSliderProps) => {
  const theme = useTheme();

  let progressivePortsStatus: ProgressiveEnforcementLevel;

  if (currentStatus !== undefined) {
    progressivePortsStatus = currentStatus;
  } else {
    progressivePortsStatus =
      ProgressiveEnforcementStatusMap[
        asset?.lowestProgressiveInboundPolicyStatus as ProgressiveEnforcementStatus
      ];
  }

  const onChange = useCallback(
    (_: Event, v: number | number[]) => {
      let value = v as ProgressiveEnforcementLevel;
      setSelectedStatus?.(value);
    },
    [setSelectedStatus]
  );

  const getMarks = useCallback(() => {
    return ProgressiveStatusSliderMarks.map(mark => {
      return {
        value: mark.value,
        label: (
          <ProgressiveSliderLabel
            progressivePortsStatus={progressivePortsStatus}
            currentStatus={mark.key}
            label={ProgressiveEnforcementLevelLabels[mark.key]}
          />
        ),
      };
    });
  }, [progressivePortsStatus]);

  return (
    <SecuritySlider
      selectedStatus={selectedStatus}
      disabled={disabled}
      labelFormatter={v =>
        ProgressiveEnforcementLevelLabels[v as ProgressiveEnforcementLevel]
      }
      onChange={onChange}
      getMarks={getMarks}
      sliderSx={securitySliderStyles({ theme, minValue: undefined })}
    />
  );
};

const securitySliderStyles = ({
  theme,
  minValue,
}: {
  theme: Theme;
  minValue?: ProgressiveEnforcementLevel;
}) => {
  const DisabledPointerColor = theme.palette.custom.sliderBg;
  let disabledPointer;

  if (minValue === ProgressiveEnforcementLevel.AllowAll) {
    disabledPointer = {
      "&[data-index='0']": {
        background: `${DisabledPointerColor}`,
        border: `5px solid ${DisabledPointerColor}`,
      },
    };
  } else if (minValue === ProgressiveEnforcementLevel.AllowWithBandwidth) {
    disabledPointer = {
      "&[data-index='0']": {
        background: `${DisabledPointerColor}`,
        border: `5px solid ${DisabledPointerColor}`,
      },
      "&[data-index='1']": {
        background: `${DisabledPointerColor}`,
        border: `5px solid ${DisabledPointerColor}`,
      },
    };
  }

  return {
    ".MuiSlider-mark": {
      zIndex: "2 !important",
      ...disabledPointer,
    },
  };
};
