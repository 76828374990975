import { useCallback, useEffect, useRef } from "react";

export const usePolling = (
  fetchDataFunction: () => void,
  defaultPollingInterval: number
) => {
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const stopPolling = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, []);

  const startPolling = useCallback(
    (pollingInterval?: number) => {
      stopPolling();
      fetchDataFunction();
      intervalId.current = setInterval(
        fetchDataFunction,
        pollingInterval ?? defaultPollingInterval
      );
    },
    [fetchDataFunction, defaultPollingInterval, stopPolling]
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        startPolling();
      } else {
        stopPolling();
      }
    };

    startPolling();
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      stopPolling();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [startPolling, stopPolling]);

  return { stopPolling, startPolling };
};
