import { TrendLineChartDatum as ChartDatum } from "@colortokens/ng-ui";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ParentSize } from "@visx/responsive";
import { Line } from "@visx/shape";
import { getColor } from "common/constants/colors";
import { AnalyticsResponse } from "common/types/types";
import { dayjsWithPlugins } from "common/utils";
import { TrendLineChart as SplitLineChart } from "modules/charts";
import { SecurityLevels } from "pages/assets/types";
import { getSecurityLevelText } from "pages/dashboard/helpers/getSecurityLevelText";
import { useMemo } from "react";

const MARGIN = { top: 30, right: 10, bottom: 30, left: 50 };

export function makePredictionData(data: AnalyticsResponse | undefined) {
  let commonData: Array<ChartDatum> = [];

  let targetData: Array<ChartDatum> = [];
  let predictionData: Array<ChartDatum> = [];

  let emptyTargetFiller: Array<ChartDatum> = [];
  let emptyPredictionFiller: Array<ChartDatum> = [];

  let startPredictionData = false;

  if (data?.items) {
    let values = Object.entries(data.items);

    let firstNonZeroData = values.find((item: any) => {
      let stats = item[1];
      let value = stats["statistics"]["metricassetriskscoremean"] as any;

      return value > 0 && value;
    });

    for (let [dateString, stats] of values) {
      let date = new Date(dateString);
      date.setHours(0, 0, 0, 0);

      const today = dayjsWithPlugins();
      const labelDate = dayjsWithPlugins(date);
      const isToday =
        labelDate.year() === today.year() &&
        labelDate.month() === today.month() &&
        labelDate.date() === today.date();

      let value = stats["statistics"]["metricassetriskscoremean"] as any;
      if (!value && firstNonZeroData) {
        value = firstNonZeroData[1]["statistics"][
          "metricassetriskscoremean"
        ] as any;
      }
      let prediction = stats["statistics"][
        "metricassetriskscoremeanpredict"
      ] as any;

      if (!value && (!prediction || startPredictionData)) {
        continue;
      }

      if (!prediction && !startPredictionData && !isToday) {
        commonData.push({
          x: date.getTime(),
          y: value,
        });
      } else if (isToday || startPredictionData) {
        predictionData.push({
          x: date.getTime(),
          y: prediction,
        });
      }
      if (isToday) {
        startPredictionData = isToday;
      }
    }
  }

  return {
    commonData,
    targetData,
    predictionData,
    emptyTargetFiller,
    emptyPredictionFiller,
  };
}

export interface SecurityProgressPredictionsGraphsType {
  predictionData: AnalyticsResponse | undefined;
  height?: number;
}

export function SecurityProgressPredictions({
  predictions,
}: {
  predictions: SecurityProgressPredictionsGraphsType;
}) {
  return (
    <SecurityProgressPredictionsGraphs
      graphData={{
        predictionData: predictions?.predictionData,
        height: predictions?.height || 300,
      }}
    />
  );
}

function SecurityProgressPredictionsGraphs({
  graphData,
}: {
  graphData: SecurityProgressPredictionsGraphsType;
}) {
  return (
    <Stack sx={{ height: graphData.height, width: "100%" }} pb={4}>
      <Chart predictionData={graphData?.predictionData} />
    </Stack>
  );
}

function Chart({
  predictionData: data,
}: SecurityProgressPredictionsGraphsType) {
  const theme = useTheme();

  const strokeStyles = [
    { stroke: getColor(SecurityLevels.Critical), strokeWidth: 3 },
    {
      stroke: getColor(SecurityLevels.Low),
      strokeWidth: 3,
      strokeDasharray: "9,6",
    },
    {
      stroke: getColor(SecurityLevels.Medium),
      strokeWidth: 3,
      strokeDasharray: "8,6",
    },
    {
      stroke: theme.palette.common.black,
      strokeWidth: 0,
    },
  ];

  const linePosition = {
    x1: "0",
    y1: "12",
    x2: "100",
    y2: "12",
  };

  const { commonData, predictionData } = useMemo(
    () => makePredictionData(data),
    [data]
  );

  const getLabel = (date: number, index: number) => {
    let showLabel = false;
    const today = dayjsWithPlugins();
    const labelDate = dayjsWithPlugins(date);
    const isToday =
      labelDate.year() === today.year() &&
      labelDate.month() === today.month() &&
      labelDate.date() === today.date();
    if (
      index === 0 ||
      date === Math.round(commonData[commonData?.length / 2]?.x) ||
      date === Math.round(predictionData[predictionData?.length / 2]?.x) ||
      date === Math.round(predictionData[predictionData?.length - 1]?.x) ||
      isToday
    ) {
      showLabel = true;
    }

    if (showLabel) {
      return dayjsWithPlugins(date).format("MMM DD");
    }

    return "";
  };

  const getDateLabel = (date: number) => {
    return dayjsWithPlugins(date).format("MMM DD");
  };

  const getPointerColor = (isPredictedData: boolean) => {
    return isPredictedData
      ? getColor(SecurityLevels.Critical)
      : getColor(SecurityLevels.Medium);
  };

  const getTooltipYLabel = (value: number) => {
    return window.getCTTranslatedText(getSecurityLevelText(value));
  };

  return (
    <Stack sx={{ minWidth: 0, minHeight: 0, height: "100%", width: "100%" }}>
      <Box
        sx={{
          mt: 4,
          minHeight: 0,
          position: "relative",
          flex: 1,
          height: "100%",
          width: "100%",
        }}
      >
        <ParentSize>
          {parent => (
            <>
              <Box>
                <SplitLineChart
                  width={parent.width}
                  height={parent.height}
                  data={[commonData, predictionData]}
                  strokeStyles={[
                    {
                      stroke: getColor(SecurityLevels.Critical),
                      strokeWidth: 3,
                    },
                    strokeStyles[2],
                  ]}
                  getLabel={(date: number, index: number) =>
                    getLabel(date, index)
                  }
                  theme={theme}
                  margin={MARGIN}
                  getDateLabel={getDateLabel}
                  xAxisLabel={window.getCTTranslatedText("BreachImpactScore")}
                  bisectLabel={window.getCTTranslatedText("Today")}
                  getPointerColor={getPointerColor}
                  getTooltipYLabel={getTooltipYLabel}
                />
              </Box>
            </>
          )}
        </ParentSize>
        <Stack
          direction="row"
          spacing={3}
          justifyContent={"flex-end"}
          alignItems={"center"}
          sx={{ pr: 1.5 }}
        >
          <Stack direction="row" alignItems={"center"}>
            <svg width={40} height={24}>
              <Line {...linePosition} {...strokeStyles[0]} />
            </svg>
            <Typography sx={{ ml: 1 }} variant="caption">
              {window.getCTTranslatedText("BreachImpactTrend")}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems={"center"}>
            <svg width={40} height={24}>
              <Line {...linePosition} {...strokeStyles[2]} />
            </svg>
            <Typography sx={{ ml: 1 }} variant="caption">
              {window.getCTTranslatedText("Prediction")}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
