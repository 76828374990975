import { IconsProps } from "./common";

export const HintIcon = ({
  width = "24px",
  height = "24px",
  color = "#000",
}: IconsProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 10H22V12H19V10ZM0 10H3V12H0V10ZM12 0V3H10V0H12ZM3.92 2.5L6.05 4.64L4.63 6.05L2.5 3.93L3.92 2.5ZM15.95 4.63L18.07 2.5L19.5 3.93L17.37 6.05L15.95 4.63ZM11 5C12.5913 5 14.1174 5.63214 15.2426 6.75736C16.3679 7.88258 17 9.4087 17 11C17 13.22 15.79 15.16 14 16.2V18C14 18.2652 13.8946 18.5196 13.7071 18.7071C13.5196 18.8946 13.2652 19 13 19H9C8.73478 19 8.48043 18.8946 8.29289 18.7071C8.10536 18.5196 8 18.2652 8 18V16.2C6.21 15.16 5 13.22 5 11C5 9.4087 5.63214 7.88258 6.75736 6.75736C7.88258 5.63214 9.4087 5 11 5ZM13 20V21C13 21.2652 12.8946 21.5196 12.7071 21.7071C12.5196 21.8946 12.2652 22 12 22H10C9.73478 22 9.48043 21.8946 9.29289 21.7071C9.10536 21.5196 9 21.2652 9 21V20H13ZM10 17H12V14.87C13.73 14.43 15 12.86 15 11C15 9.93913 14.5786 8.92172 13.8284 8.17157C13.0783 7.42143 12.0609 7 11 7C9.93913 7 8.92172 7.42143 8.17157 8.17157C7.42143 8.92172 7 9.93913 7 11C7 12.86 8.27 14.43 10 14.87V17Z"
        fill={color}
        fillOpacity="0.38"
      />
    </svg>
  );
};
