import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

export const CtSlider = styled(Slider)(({ theme }) => ({
  color: "#C9C9C9",
  height: 2.5,
  "& .MuiSlider-markLabelActive": {
    // color: "0 0 0 7px rgba(58, 160, 255, 0.16)",
  },
  "& .MuiSlider-track": {
    border: "none",
    color: theme.palette.primary.main,
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.main,
    border: `5px solid ${theme.palette.primary.main}`,
    boxShadow: "0 0 0 7px rgba(58, 160, 255, 0.16)",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
    "&:hover": {
      boxShadow: "0 0 0 7px rgba(58, 160, 255, 0.16)",
    },
  },
  "& .MuiSlider-mark": {
    height: 8,
    width: 8,
    backgroundColor: theme.palette.custom.sliderBg,
    border: `5px solid ${theme.palette.custom.sliderBg}`,
    opacity: 1,
    borderRadius: "100px",
    transform: "translate(-50%, -50%)",
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
      border: `5px solid ${theme.palette.primary.main}`,
      "&:hover": {
        boxShadow: "0 0 0 8px rgba(58, 160, 255, 0.16)",
      },
    },
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(201, 201, 201, 0.16)",
    },
  },
  "& .MuiSlider-markLabel": {
    top: -63,
  },
  "& .MuiSlider-valueLabel": {
    display: "none",
  },
}));
