import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Toolbar, Tooltip } from "@mui/material";

import {
  PathRecommendationPageType,
  PathRecommendationsCore,
} from "modules/recommendation-workflows/PathRecommendationDrawer";
import { PortRecommendationCore } from "modules/recommendation-workflows/PortRecommendationDrawer";

export const TagPolicyRecommendationsDrawer = ({
  criteria,
  onClose,
  selectedTab,
  policyId,
}: {
  criteria: string;
  onClose: VoidFunction;
  selectedTab: RecommendationType;
  policyId: string;
}) => {
  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "0px",
          width: "80%",
          minWidth: "1000px",
          height: "100%",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={onClose}
            sx={{ position: "absolute", right: "16px", top: "16px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>

      <TagPolicyRecommendations
        criteria={criteria}
        selectedTab={selectedTab}
        onClose={onClose}
        policyId={policyId}
      />
    </Drawer>
  );
};

export enum RecommendationType {
  Port = 0,
  Path = 1,
}
function TagPolicyRecommendations({
  criteria,
  selectedTab,
  onClose,
  policyId,
}: {
  policyId: string;
  criteria: string;
  selectedTab: RecommendationType;
  onClose: VoidFunction;
}) {
  return (
    <>
      {RecommendationType.Port === selectedTab && (
        <PortRecommendationCore
          hidePreview
          criteria={criteria}
          onClose={() => {}}
        />
      )}
      {RecommendationType.Path === selectedTab && (
        <PathRecommendationsCore
          criteria={criteria}
          onClose={onClose}
          page={PathRecommendationPageType.Paths}
          policyId={policyId}
          hidePreview
        />
      )}
    </>
  );
}
