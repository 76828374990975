import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { UserGroupAssignedNamedNetworks } from "pages/user-groups/components/user-group-assigned-named-networks";
import { UserGroupAssignedTemplates } from "pages/user-groups/components/user-group-assigned-templates";
import { UserGroupName } from "pages/user-groups/components/user-group-name";
import { UserGroupSourceIDP } from "pages/user-groups/components/user-group-source-IDP";
import { UserCount } from "../user-count";
import { UserGroupActions } from "../user-group-actions";
import { UserGroupPaths } from "../user-group-paths";
import { UserGroupStatus } from "../user-group-status";

export const BASE_USERGROUPS_COLUMNS: GridColDef[] = [
  {
    field: "groupName",
    headerName: "Groups",
    width: 140,
    flex: 0.4,
    sortingOrder: ["asc", "desc"],
    sortable: true,
    renderCell: params => {
      return <UserGroupName userGroup={params.row} />;
    },
  },
  {
    field: "idpName",
    headerName: "IdP",
    width: 140,
    flex: 0.4,
    sortingOrder: ["asc", "desc"],
    sortable: true,
    renderCell: params => {
      return <UserGroupSourceIDP userGroup={params.row} />;
    },
  },
  {
    field: "userGroupStatus",
    headerName: "Status",
    width: 140,
    flex: 0.4,
    sortable: true,
    renderCell: params => {
      return <UserGroupStatus userGroup={params.row} />;
    },
  },
  {
    field: "usersCount",
    headerName: "Users",
    width: 140,
    flex: 0.4,
    sortable: true,
    renderCell: params => {
      return <UserCount userGroup={params.row} />;
    },
  },
  {
    field: "namednetworksCount",
    headerName: "Named Networks",
    width: 140,
    flex: 0.4,
    sortable: true,
    renderCell: params => {
      return (
        <UserGroupAssignedNamedNetworks
          userGroup={params.row}
          criteria={`groupId = '${params.row?.groupID}'`}
        />
      );
    },
  },
  {
    field: "templatesCount",
    headerName: "Templates",
    width: 140,
    flex: 0.4,
    sortable: true,
    renderCell: params => {
      return (
        <UserGroupAssignedTemplates
          userGroup={params.row}
          criteria={`groupId = '${params.row?.groupID}'`}
        />
      );
    },
  },
  {
    field: "userGroupUnreviewedPathsCount",
    headerName: "Outbound Paths",
    width: 140,
    flex: 0.4,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return <UserGroupPaths user={params.row} />;
    },
  },
];

export const USERGROUPS_COLUMNS: GridColDef[] = [
  ...BASE_USERGROUPS_COLUMNS,
  {
    field: "actions",
    headerName: "",
    width: 60,
    sortable: false,
    sortingOrder: ["asc", "desc"],
    renderCell: params => {
      return <UserGroupActions userGroup={params.row} />;
    },
  },
];
