import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { CTTooltip } from "common/atoms/ct-tooltip";
import { getCustomMenuIcon } from "modules/NavigationLayout/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "routes";
import { Route } from "routes/routes";

interface NavListItemProps {
  id: string;
  route: Route;
  isMenuExpanded: boolean;
}

export const NavListItem = ({
  id,
  route,
  isMenuExpanded,
}: NavListItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const routesPaths = location?.pathname
    ?.split("/")
    ?.filter(route => route !== "");

  const activeSidebarTab = routesPaths?.[0]?.toLowerCase();
  const subRoute = routesPaths?.[1]?.toLowerCase() ?? undefined;

  const onListItemClick = (route: string) => {
    const pathname = ROUTES[route].pathname + (ROUTES[route].queryParams ?? "");

    const currentRoute = pathname.replace("/", "");
    if (activeSidebarTab !== currentRoute || subRoute) {
      navigate(pathname);
    }
  };

  const activeRoute = route?.pathname?.split("/")?.filter(url => url !== "")[0];

  const active =
    location.pathname === route.pathname ||
    location.pathname === route.pathname + "/" ||
    activeRoute === activeSidebarTab;

  const menuIcon = route.sidebar?.icon ?? null;

  const darkModeColor =
    theme.palette.mode === "dark"
      ? alpha(theme.palette.common.white, 0.6)
      : alpha(theme.palette.common.black, 0.6);

  const color = active ? theme.palette.text.primary : darkModeColor;

  return (
    <CTTooltip
      title={
        isMenuExpanded ? "" : window.getCTTranslatedText(route?.title ?? "")
      }
      placement="right"
    >
      <ListItem
        disablePadding
        onClick={() => {
          onListItemClick(id);
        }}
        sx={{
          backgroundColor: active
            ? theme.palette.custom.appNavbarActive
            : "transparent",
          borderLeft: active
            ? `4px solid ${theme.palette.primary.main}`
            : "unset",
        }}
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            px: 3.5,
            py: 1,
            justifyContent: isMenuExpanded ? "flex-start" : "center",
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <ListItemIcon
              sx={{
                pl: active ? 0 : "4px",
                minWidth: 0,
                justifyContent: "center",
                color: color,
              }}
            >
              {route?.sidebar?.isCustomIcon
                ? getCustomMenuIcon(id, color)
                : menuIcon}
            </ListItemIcon>
            {isMenuExpanded && (
              <ListItemText>
                {window.getCTTranslatedText(route?.title ?? "")}
              </ListItemText>
            )}
          </Stack>
        </ListItemButton>
      </ListItem>
    </CTTooltip>
  );
};
