import { useMutation } from "@tanstack/react-query";
import { Template } from "pages/templates/types";

export interface TemplateResInt {
  items: Array<Template>;
  metadata: {
    total: number;
  };
}

export const useTemplatesSuggestions = () => {
  return useMutation<TemplateResInt, Error, any>([
    "policy",
    "templates/actions/search?computeTotal=true",
  ]);
};
