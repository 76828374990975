import { Link, Stack, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { UsersColumnRenderProps } from "../../types";
import numeral from "numeral";

export const UserGroupCount = ({
  user,
  viewOnly = false,
}: UsersColumnRenderProps) => {
  const groupCount = numeral(user?.groups ?? 0).format("0a");
  return (
    <Stack direction={"row"} spacing={1} flex={1} alignItems={"center"}>
      {viewOnly || user?.groups === 0 ? (
        <Typography variant="body2">{groupCount} </Typography>
      ) : (
        <Tooltip title={window.getCTTranslatedText("View groups")}>
          <Link
            typography="body2"
            component={RouterLink}
            to={`/users?tab=groups&filters=${encodeURIComponent(
              `useremail=${user.userEmail}`
            )}`}
            sx={{ color: "primary.main" }}
          >
            {groupCount}{" "}
          </Link>
        </Tooltip>
      )}
    </Stack>
  );
};
