import CircleIcon from "@mui/icons-material/Circle";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { AssetMetaDataReadonlyProps, PolicyStatus } from "pages/assets/types";
import {
  FirewallLogType,
  ViewFirewallLogsButton,
} from "./ViewFirewallLogsButton";
// import { useQueryClient } from "@tanstack/react-query";
// import { RefreshOutlined } from "@mui/icons-material";
// import { grey } from "@mui/material/colors";
import { ViewFirewallDecisionLogsButton } from "./ViewFirewallDecisionLogsButton";
import { InfoOutlined } from "@mui/icons-material";
import { getDate } from "common/utils";

// const iconStyle = {
//   background: "url('/assets/images/wall-fire.svg')",
//   backgroundSize: "contain",
//   backgroundRepeat: "no-repeat",
//   width: 24,
//   height: 24,
//   flexShrink: 0,
// };

export function AssetFirewallStatus({ asset }: AssetMetaDataReadonlyProps) {
  // const queryClient = useQueryClient();

  if (!asset?.assetId) {
    return <Box style={{ width: 42 }} />;
  }

  const status = asset.policyStatus;

  let statusIcon;
  let text;

  // const icon = <Box sx={iconStyle}></Box>;

  // const refreshData = () => {
  //   queryClient.invalidateQueries(["assets"]);
  // };

  switch (status) {
    case PolicyStatus.Synchronized:
      statusIcon = <CircleIcon color={"success"} sx={{ fontSize: 12 }} />;
      text = "Synchronized";
      break;
    default:
      statusIcon = <CircleIcon color={"disabled"} sx={{ fontSize: 12 }} />;
      text = "Pending";
  }

  return (
    <Stack
      direction={"row"}
      spacing={3}
      alignItems="center"
      justifyContent="flex-start"
      sx={{ width: "100%" }}
    >
      {/* {icon} */}
      <Typography variant="subtitle1">
        <b>{window.getCTTranslatedText("Firewall")}</b>
      </Typography>
      <Stack
        sx={{ mr: 1, flex: 1 }}
        direction="row"
        spacing={1}
        alignItems="center"
      >
        {statusIcon}
        <Typography variant="body2">
          {window.getCTTranslatedText(text)}
        </Typography>

        {status === PolicyStatus.Synchronized && (
          <Tooltip
            title={`${window.getCTTranslatedText("LastSyncedTime")} : ${getDate(
              asset?.lastPolicyDeploymentTriggeredAt ?? ""
            )}`}
          >
            <IconButton>
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {/* {status !== PolicyStatus.Synchronized && (
          <IconButton
            aria-label="Refresh data"
            color="inherit"
            onClick={refreshData}
            sx={{ marginLeft: "auto" }}
          >
            <RefreshOutlined sx={{ color: grey[500] }} fontSize="small" />
          </IconButton>
        )} */}
      </Stack>
      <ViewFirewallLogsButton
        asset={asset}
        agentId={asset.agentId}
        type={FirewallLogType.ASSET}
      />
      <ViewFirewallDecisionLogsButton asset={asset} agentId={asset.agentId} />
    </Stack>
  );
}
