import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Stack, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import { grey } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import {
  AppliedFacetChipsLocation,
  SearchChip,
} from "common/atoms/facet-open-close-controller/FacetOpenCloseController";
import { AppliedFacetsChips } from "modules/facets/components/applied-facets-chips";
import { decodeThrowable } from "modules/facets/hooks/useFacetQueryConnector";
import { useSavedQueryStore } from "modules/saved-query/store";
import { QueryItemProps, QueryType } from "modules/saved-query/types";
import { useSearchStore } from "modules/search/store";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import React, { useState } from "react";
import { DeleteQuery } from "../delete-query";

const titleWidth = 350;
const titleHeight = 32;

export const QueryItem = ({
  query,
  useFacetStore,
  loadQueryCallback,
  type = QueryType.Saved,
}: QueryItemProps) => {
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const setSavedQuery = useSavedQueryStore(state => state.setSavedQuery);
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const onAccordionChange =
    () => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded);
    };

  const urlSearchParams = new URLSearchParams(query?.criteriaAsParams);

  const facetString = urlSearchParams.get("filters");

  const searchString = urlSearchParams.get("search");

  const setFacetState = useFacetStore(state => state.setFacets);
  const setSearch = useSearchStore(state => state.setSearch);
  const facetState = decodeThrowable(facetString || "");

  const loadQuery = (event: any) => {
    if (event) {
      event.stopPropagation();
    }

    setFacetState(facetState);
    setSearch(searchString || "");
    setSavedQuery(query);
    setSnackbar(true, SnackBarSeverity.Success, "QueryAppliedSuccessfully");
    setTimeout(() => {
      loadQueryCallback(query);
    }, 300);
  };

  const [showApplyButton, setShowApplyButton] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onMouseEnter = () => {
    setShowApplyButton(true);
  };

  const onMouseLeave = () => {
    setShowApplyButton(false);
  };

  const triggerDeleteQuery = () => {
    setShowDeleteConfirmation(true);
  };

  const onCloseDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={onAccordionChange()}
        key={query?.name}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Stack direction={"row"} flex={1} alignItems="center">
            {type === QueryType.Saved ? (
              <Typography
                variant="body1"
                sx={{
                  textTransform: "capitalize",
                  lineHeight: `${titleHeight}px`,
                  maxWidth: titleWidth,
                }}
                noWrap
              >
                {query?.name}
              </Typography>
            ) : (
              <Stack
                alignItems="flex-start"
                flexWrap={"wrap"}
                sx={{ maxWidth: titleWidth, maxHeight: titleHeight }}
              >
                <AppliedFacetsChips
                  useFacetStore={useFacetStore}
                  facetState={facetState}
                  appliedChipsLocation={AppliedFacetChipsLocation.SAVED_QUERY}
                  showSearchChip={
                    searchString && searchString?.trim().length > 0 ? (
                      <SearchChip
                        searchString={searchString}
                        hideDeleteIcon={true}
                        onClearSearch={() => {}}
                      />
                    ) : null
                  }
                />
              </Stack>
            )}
            {showApplyButton && (
              <Stack sx={{ position: "absolute", right: "42px" }}>
                <Button
                  variant="outlined"
                  onClick={event => {
                    loadQuery(event);
                  }}
                >
                  {window.getCTTranslatedText("Apply")}
                </Button>
              </Stack>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            <Typography
              variant="body2"
              sx={{
                opacity: 0.6,
                mx: 1,
              }}
            >
              {query?.description}
            </Typography>
            <Stack
              direction="row"
              alignItems="flex-start"
              sx={{ my: 1, mx: 1 }}
            >
              <AppliedFacetsChips
                useFacetStore={useFacetStore}
                facetState={facetState}
                appliedChipsLocation={AppliedFacetChipsLocation.SAVED_QUERY}
                showSearchChip={
                  searchString && searchString?.trim().length > 0 ? (
                    <SearchChip
                      searchString={searchString}
                      hideDeleteIcon={true}
                      onClearSearch={() => {}}
                    />
                  ) : null
                }
              />
            </Stack>
          </Stack>
          {type === QueryType.Saved && (
            <Stack
              justifyContent={"flex-end"}
              flex={1}
              direction={"row"}
              sx={{
                px: 1,
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={event => {
                  triggerDeleteQuery();
                }}
              >
                {window.getCTTranslatedText("Delete")}
              </Button>
            </Stack>
          )}
          {showDeleteConfirmation && (
            <DeleteQuery
              query={query}
              handleClose={onCloseDeleteConfirmation}
              allowDelete={showDeleteConfirmation}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
  backgroundColor: "transparent",
  "&.Mui-expanded": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.common.white, 0.1)
        : grey[100],
    borderBottom: 0,
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowDropDownIcon />} {...props} />
))(({ theme }) => ({
  "&.Mui-expanded": {
    backgroundColor:
      theme.palette.mode === "dark"
        ? alpha(theme.palette.common.black, 0.2)
        : grey[50],
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    overflowX: "hidden",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  background:
    theme.palette.mode === "dark"
      ? `linear-gradient(180deg, ${alpha(
          theme.palette.common.white,
          0.08
        )} 0%, ${alpha(theme.palette.common.white, 0.08)} 100%), ${
          theme.palette.background.paper
        }`
      : "unset",
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.background.paper : grey[200],
}));
