import { Menu, MenuItem } from "@mui/material";

const TRANSLATE_LANGUAGE_MENU_ID = "primary-search-translate-language-menu";

interface TranslateLanguageMenuProps {
  anchorElLang: HTMLElement | null;
  isLangMenuOpen: boolean;
  setAnchorElLang: Function;
  handleMenuClose: () => void;
}

export const TranslateLanguageMenu = ({
  anchorElLang,
  isLangMenuOpen,
  setAnchorElLang,
  handleMenuClose,
}: TranslateLanguageMenuProps) => {
  const handleLangMenuClose = () => {
    setAnchorElLang(null);
    handleMenuClose();
  };

  const handlerLangClick = (lang: string) => {
    window.changeLanguage(lang);
    handleLangMenuClose();
  };

  const selectedLang = window.CTLangCode;

  return (
    <Menu
      anchorEl={anchorElLang}
      keepMounted
      id={TRANSLATE_LANGUAGE_MENU_ID}
      open={isLangMenuOpen}
      onClose={handleLangMenuClose}
      PaperProps={{
        sx: { width: "240px" },
      }}
    >
      <MenuItem
        selected={!selectedLang || selectedLang === "en"}
        onClick={() => handlerLangClick("en")}
      >
        English
      </MenuItem>
      <MenuItem
        selected={!selectedLang || selectedLang?.includes("ja")}
        onClick={() => handlerLangClick("ja")}
      >
        Japanese
      </MenuItem>
    </Menu>
  );
};
