import CloseIcon from "@mui/icons-material/Close";
import { Drawer, IconButton, Stack, Tooltip } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { IPRange } from "pages/networks/types";
import { AssignNetworkDialog } from "../AssignNetworkDialog";
import { Toolbar } from "common/atoms/toolbar";

export interface AssignNetworksDrawerProps {
  isOpen: boolean;
  page: string;
  title?: string;
  rules?: Array<IPRange> | Array<String> | Array<GridRowId> | undefined;
  onCancel: () => void;
  onConfirm: () => void;
  btnTitle?: string;
  criteria?: string;
  multiselect?: boolean;
  onSelectCallback?: Function;
  policyId?: string;
  hasPermission?: boolean;
}

export const AssignNetworksDrawer = ({
  isOpen,
  page,
  title,
  rules,
  onCancel,
  onConfirm,
  btnTitle = "assign",
  criteria,
  multiselect = false,
  onSelectCallback,
  policyId,
  hasPermission,
}: AssignNetworksDrawerProps) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onCancel}
      PaperProps={{
        sx: {
          p: 0,
          width: "70%",
          maxwidth: "1000px",
          minWidth: "600px",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={onCancel}
            sx={{ position: "absolute", right: "16px", top: "8px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <AssignNetworkDialog
        onCancel={onCancel}
        onConfirm={onConfirm}
        page={page}
        open={isOpen}
        rules={rules}
        title={title || "Assign Network"}
        btnTitle={btnTitle}
        criteria={criteria}
        multiselect={multiselect}
        onSelectCallback={onSelectCallback}
        policyId={policyId}
        hasPermission={hasPermission}
      />
    </Drawer>
  );
};
