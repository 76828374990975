import { Box, Grid, Link, Stack, Tooltip, Typography } from "@mui/material";

import numeral from "numeral";
import { PortColumnRenderProps } from "pages/ports/types";
import { Link as RouterLink } from "react-router-dom";

export function PortPathCount({ port, viewOnly }: PortColumnRenderProps) {
  if (!port.lpId) {
    return <Box style={{ minWidth: 70, minHeight: 45 }} />;
  }

  return (
    <Stack
      sx={{ maxWidth: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="center"
      >
        {viewOnly ? (
          <>
            <Typography sx={{ color: "inherit" }} variant={"body2"}>
              {numeral(port?.internetPathCount || 0).format("0a")}
            </Typography>
          </>
        ) : (
          <Tooltip title={window.getCTTranslatedText("View internet paths")}>
            <Link
              component={RouterLink}
              to={`/paths?filters=${encodeURIComponent(
                `port=${port.listenPort}|protocol=${port.listenPortProtocol}|direction=inbound|srcip=0.0.0.0|assetname=${port.listenAsset?.assetName}`
              )}`}
              sx={{ color: "inherit" }}
            >
              {numeral(port?.internetPathCount || 0).format("0a")}
            </Link>
          </Tooltip>
        )}

        <Stack style={{ margin: "0 4px" }}>/</Stack>

        {viewOnly ? (
          <>
            <Typography sx={{ color: "inherit" }} variant={"body2"}>
              {numeral(port?.pathCount || 0).format("0a")}
            </Typography>
          </>
        ) : (
          <Tooltip title="View all paths">
            <Link
              component={RouterLink}
              to={`/paths?filters=${encodeURIComponent(
                `port=${port.listenPort}|protocol=${port.listenPortProtocol}|direction=inbound|assetname=${port.listenAsset?.assetName}`
              )}`}
              sx={{ color: "inherit" }}
            >
              {numeral(port?.pathCount || 0).format("0a")}
            </Link>
          </Tooltip>
        )}
      </Grid>
    </Stack>
  );
}
