import Button, { ButtonProps } from "@mui/material/Button";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";

export function CopyInstructions({
  code,
  buttonProps,
}: {
  code: any;
  buttonProps?: ButtonProps;
}) {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <CopyToClipboard onCopy={onCopy} text={code || ""}>
      <Button startIcon={!copied ? <ContentCopy /> : null} {...buttonProps}>
        {window.getCTTranslatedText(copied ? "Copied" : "Copy Instructions")}
      </Button>
    </CopyToClipboard>
  );
}
