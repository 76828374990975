export enum SnackBarSeverity {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export type snackBarSeverityTypes =
  | SnackBarSeverity.Success
  | SnackBarSeverity.Info
  | SnackBarSeverity.Warning
  | SnackBarSeverity.Error;

export interface SnackbarInterface {
  snackbarOpen: boolean;
  snackbarType: SnackBarSeverity;
  snackbarMessage: string;
  snackbarVariables?: { [key: string]: string | number };
  setSnackbar: (
    snackbarOpen: boolean,
    severity: SnackBarSeverity,
    message: string,
    variables?: { [key: string]: string | number }
  ) => void;
}

export enum SnackBarPosition {
  Top = "top",
  Bottom = "bottom",
  Left = "left",
  Center = "center",
  Right = "right",
}

export type SnackBarVerticalPositionTypes = "top" | "bottom";
export type SnackBarHorizontalPositionTypes = "left" | "center" | "right";

export interface SnackbarConfigInterface {
  autoHideDuration: number;
  vertical: SnackBarVerticalPositionTypes;
  horizontal: SnackBarHorizontalPositionTypes;
}

export const snackbarDefaultConfig: SnackbarConfigInterface = {
  autoHideDuration: 6000,
  vertical: SnackBarPosition.Bottom,
  horizontal: SnackBarPosition.Left,
};
