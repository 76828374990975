import { DataGrid } from "modules/data-grid/components/data-grid";
import { BASE_USERS_COLUMNS } from "./constants";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { User } from "pages/users-segmentation/components/users/types";

export const UserDataGrid = (props: DataGridProps<User>) => {
  return (
    <DataGrid
      rowHeight={64}
      columns={BASE_USERS_COLUMNS}
      pagination={true}
      getRowId={(user: User) => `${user.userID}`}
      paginationMode="server"
      sortingMode="server"
      {...props}
    />
  );
};
