import { Box } from "@mui/material";
import {
  ApplianceColumnRenderProps,
  ApplianceDrawerModes,
} from "pages/appliances/types";
import { ApplianceFormDrawer } from "pages/appliances/components/appliance-form-drawer";
import { useApplianceStore } from "pages/appliances/store";

export function ConfigApplianceDrawer({
  agent,
  isOpen,
  onClose,
}: Readonly<ApplianceColumnRenderProps>) {
  const requestAPIRefresh = useApplianceStore(state => state.requestAPIRefresh);

  const updateApplianceData = () => {
    requestAPIRefresh();
  };

  if (!agent?.agentId) {
    return <Box style={{ width: 100 }} />;
  }
  return (
    <Box>
      {isOpen && (
        <ApplianceFormDrawer
          appliance={agent}
          isOpen={isOpen}
          onClose={onClose}
          title={window.getCTTranslatedText("configureApplianceSettings")}
          btnTitle={window.getCTTranslatedText("update")}
          updateApplianceData={updateApplianceData}
          mode={ApplianceDrawerModes.Config}
        ></ApplianceFormDrawer>
      )}
    </Box>
  );
}
