import { SaveQueryFieldKeys } from "./types";

export const RequiredSaveQueryFields: { [key: string]: boolean } = {
  [SaveQueryFieldKeys.Name]: true,
};

export const SaveQueryFieldDisplayText = {
  [SaveQueryFieldKeys.Name]: "Name",
  [SaveQueryFieldKeys.Description]: "Description",
};

export const SaveQueryFieldOptions: Array<SaveQueryFieldKeys> = [
  SaveQueryFieldKeys.Name,
  SaveQueryFieldKeys.Description,
];
