export const MENU_ID = "primary-search-account-menu";
export const MOBILE_MENU_ID = "primary-search-account-menu-mobile";

export const WORK_REQUEST_POLLING_INTERVAL = 1000 * 20;
export const WORK_REQUEST_POLLING_INTERVAL_FOR_PENDING_REQUESTS = 1000 * 5;

export const WORK_REQUEST_UPDATED_EVENT_DELAY = 1000 * 3;

export interface BadgeConfig {
  count: number;
  shouldFetchMoreFrequently: boolean;
}

export const INITIAL_BADGE_CONFIG: BadgeConfig = {
  count: 0,
  shouldFetchMoreFrequently: false,
};
