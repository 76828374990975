import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { MoreOptionsMenu } from "common/molecules/more-options-menu/MoreOptionsMenu";
import { MenuOption } from "common/molecules/more-options-menu/types";
import {
  NOTIFY_ACTIONS,
  useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useSnackbarStore } from "modules/snackbar/store";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTagRuleStore } from "../../store";
import { TagRule } from "../../types";

export function TagRuleActions({ rule }: { rule: TagRule }) {
  const navigate = useNavigate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const moreActionMenuOptions: Array<MenuOption> = [];

  if (userPermissions.has("UPDATE_TAGRULE")) {
    moreActionMenuOptions.push({
      label: "Edit Rule",
      handler: () => {
        navigate(`/tag-rules/edit-rule/${rule.ruleId}`);
      },
    });
  }

  if (userPermissions.has("DELETE_TAGRULE")) {
    moreActionMenuOptions.push({
      label: "Delete Rule",
      handler: () => {
        setShowDeleteDialog(true);
      },
    });
  }

  return (
    <>
      {(userPermissions.has("UPDATE_TAGRULE") ||
        userPermissions.has("DELETE_TAGRULE")) && (
        <MoreOptionsMenu menuOptions={moreActionMenuOptions} />
      )}
      {showDeleteDialog && (
        <DeleteRuleDialog
          rule={rule}
          onClose={() => {
            setShowDeleteDialog(false);
          }}
        />
      )}
    </>
  );
}

function useDeleteRule(ruleId: string) {
  const path = `tagrules/${ruleId}`;
  return useMutation<any, Error>(["tagrules", path, "delete"]);
}

function DeleteRuleDialog({
  rule,
  onClose,
}: {
  rule: TagRule;
  onClose: VoidFunction;
}) {
  const deleteMutation = useDeleteRule(rule?.ruleId);
  const notify = useEventSubscriptionStore(state => state.notify);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const refreshRequest = useTagRuleStore(state => state.requestAPIRefresh);

  const closeRef = useRef(onClose);
  useEffect(() => {
    if (deleteMutation.isSuccess) {
      notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
        label: "TagLabelRuleDeletedSuccessfully",
      });
      closeRef.current();
      refreshRequest();
    }
  }, [deleteMutation.isSuccess, notify, refreshRequest, setSnackbar]);

  return (
    <Dialog
      open={true}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="delete-tag-rule"
    >
      <DialogTitle id="delete-tag-rule">
        {window.getCTTranslatedText("Delete Tag rule")}
      </DialogTitle>
      <DialogContent>
        {window.getCTTranslatedText("Are you sure you want to delete tag rule")}{" "}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
          }}
        >
          {window.getCTTranslatedText("Cancel")}
        </Button>
        <LoadingButton
          loading={deleteMutation.isLoading}
          variant="contained"
          color="error"
          onClick={() => {
            deleteMutation.mutate();
          }}
        >
          {window.getCTTranslatedText("Delete")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
