import {
  Button,
  debounce,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { CTWidget } from "common/molecules/widget";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useTopAlertsAPI } from "pages/appliance-detail/hooks";
import { ApplianceWidgetProps } from "pages/appliance-detail/types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { CTAlert } from "pages/monitoring/types";
import { useEffect, useMemo, useState } from "react";
import { getLocalDate } from "common/utils/getDate";
import { AlertDetails } from "pages/monitoring/components/alert-data-grid/components";
import { DataGridButton } from "common/atoms/data-grid-button";
import { useNavigate } from "react-router-dom";
import { AlertFilter } from "modules/NavigationLayout/components/notifications/types";

interface WidgetProps {
  activeAlerts?: CTAlert[];
}

const cellStyle = {
  py: 1.5,
  alignItems: "center",
};

const cellStyleTop = {
  py: 1.5,
};

const noDataBlockStyle = {
  width: "100%",
  minHeight: "250px",
  textAlign: "center",
};

const TopAlertsWidgetContent = ({ activeAlerts }: WidgetProps) => {
  const [selectedAlert, setSelectedAlert] = useState<CTAlert>();
  return (
    <Stack width="100%" spacing={2}>
      {!activeAlerts?.length ? (
        <Stack
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
          sx={noDataBlockStyle}
        >
          <Typography variant="body1">
            {window.getCTTranslatedText("noAlertsAvailable")}
          </Typography>
        </Stack>
      ) : (
        <TableContainer sx={{ minHeight: 250 }}>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell colSpan={6}>
                  {`${window.getCTTranslatedText("Alert Name")}`}
                </TableCell>
                <TableCell colSpan={3}>
                  {`${window.getCTTranslatedText("Description")}`}
                </TableCell>
                <TableCell colSpan={2}>
                  {`${window.getCTTranslatedText("Last Seen")}`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(activeAlerts || []).map((alert: CTAlert, index: number) => {
                return (
                  <TableRow key={alert.details}>
                    <TableCell
                      component="td"
                      scope="row"
                      colSpan={6}
                      sx={index === 0 ? cellStyleTop : cellStyle}
                    >
                      <Tooltip
                        title={window.getCTTranslatedText("viewAlertDetails")}
                      >
                        <DataGridButton
                          variant="text"
                          color="inherit"
                          onClick={() => setSelectedAlert(alert)}
                        >
                          {alert.alertRuleName}
                        </DataGridButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      colSpan={3}
                      sx={index === 0 ? cellStyleTop : cellStyle}
                    >
                      {alert.details}
                    </TableCell>
                    <TableCell
                      component="td"
                      scope="row"
                      colSpan={3}
                      sx={index === 0 ? cellStyleTop : cellStyle}
                    >
                      {getLocalDate(alert?.lastSeen)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {selectedAlert && (
        <AlertDetails
          alerts={selectedAlert}
          onClose={() => {
            setSelectedAlert(undefined);
          }}
        />
      )}
    </Stack>
  );
};

export const ApplianceTopAlerts = ({
  appliance,
  isLoading,
}: ApplianceWidgetProps) => {
  const [activeAlerts, setActiveAlerts] = useState<CTAlert[]>();
  const applianceAlertsMutation = useTopAlertsAPI();
  const navigate = useNavigate();
  const applianceAlertsMutationObj = useMemo(
    () => debounce(applianceAlertsMutation.mutate, 100),
    [applianceAlertsMutation.mutate]
  );
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);

  useEffect(() => {
    if (appliance?.agentId) {
      const body = {
        criteria: `'alertstatus' in ('Pending') AND 'alertresourceid' in ('${appliance?.agentId}') AND 'alertseverity' in ('Critical')`,
        pagination: {
          offset: 0,
          limit: 10,
          sort: [
            {
              field: "alertseverity",
              order: "desc",
            },
            {
              field: "alertlastseen",
              order: "desc",
            },
          ],
        },
        facetFields: [],
      };
      applianceAlertsMutationObj(body, {
        onSuccess(response) {
          setActiveAlerts(response?.items);
        },
        onError: error => {
          setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
        },
      });
    }
  }, [appliance, applianceAlertsMutationObj, setSnackbar]);

  const viewAllAlerts = () => {
    const filters = `alertstatus=Pending|alertresourceid=${appliance?.agentId}`;
    navigate(
      `/monitoring?tab=${AlertFilter.tab}&filters=${encodeURIComponent(
        filters
      )}`
    );
  };

  return (
    <CTWidget
      title={window.getCTTranslatedText("topAlerts")}
      isLoading={isLoading}
      children={<TopAlertsWidgetContent activeAlerts={activeAlerts} />}
      actions={
        <Button endIcon={<ChevronRightIcon />} onClick={viewAllAlerts}>
          {window.getCTTranslatedText("viewAll")}
        </Button>
      }
    />
  );
};
