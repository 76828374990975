import { Button, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import {
  getComplianceReqCheckList,
  setCurrentTenantID,
} from "hooks/useComplianceController/helper";
import { useDemoController } from "hooks/useDemoController";
import { Tenant } from "modules/AppBar/types";
import { SystemUseNotification } from "pages/login/components/system-use-notification";
import { useState } from "react";

interface TenantSwitchButtonProps {
  tenant: Tenant;
  active: boolean;
}

export const TenantSwitchButton = ({
  tenant,
  active,
}: TenantSwitchButtonProps) => {
  const { exitDemo } = useDemoController();
  const tenantCompliantList = getComplianceReqCheckList();
  const [complianceCheck, setComplianceCheck] = useState<boolean>(false);

  const useTenantSwitch = useMutation<undefined, Error, undefined>([
    "tenant-switch",
    `auth/token/${tenant.tenantID}`,
  ]);

  const onTenantSwitch = async () => {
    const currentTenantID = tenant?.tenantID;
    if (currentTenantID && setCurrentTenantID) {
      setCurrentTenantID(currentTenantID);
    }
    await useTenantSwitch.mutateAsync(undefined);
    exitDemo();
  };

  const acceptCallback = () => {
    onTenantSwitch();
  };

  const cancelCallback = () => {
    setComplianceCheck(false);
  };

  const validateComplianceCheck = () => {
    if (active) {
      return;
    }
    const currentTenantID = tenant?.tenantID;
    const showComplianceDialog = Boolean(
      tenantCompliantList && tenantCompliantList.get(currentTenantID) === true
    );
    setComplianceCheck(showComplianceDialog);
    if (!showComplianceDialog) {
      onTenantSwitch();
    }
  };

  const theme = useTheme();

  return (
    <>
      {complianceCheck && (
        <SystemUseNotification
          currentTenantID={tenant.tenantID}
          acceptCallback={acceptCallback}
          cancelCallback={cancelCallback}
        />
      )}
      <Button
        fullWidth
        disableTouchRipple={active}
        sx={{
          cursor: active ? "default" : undefined,
          justifyContent: "flex-start",
          textTransform: "none",
          typography: theme.typography.body2,
          fontSize: theme.typography.body2.fontSize,
          borderRadius: "0 !important",
          px: 3,
        }}
        color={active ? "primary" : "inherit"}
        onClick={() => {
          validateComplianceCheck();
        }}
      >
        {tenant.name}
      </Button>
    </>
  );
};
