import { PathsReview } from "modules/ports-paths-review/components/paths-review";
import { UserGroup } from "../../types";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { PathFilters, PathStatus } from "pages/paths/types";
import { useAssetDetailCommonStore as useFacetStore } from "pages/asset/components/asset-detail/components/asset-review-drawer/store/common";
import { PathStatusActionBar } from "modules/ports-paths-review/components/paths-review/components/path-status-actionbar";
import { usePathAssetStore } from "pages/asset/components/asset-detail/components/asset-review-drawer/store/path-store";
import {
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
interface GroupPathReviewDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  pathReviewState?: boolean;
  userGroup: UserGroup;
}
export function UserGroupPathsDrawer({
  isOpen,
  onClose,
  userGroup,
  pathReviewState = false,
}: Readonly<GroupPathReviewDrawerProps>) {
  const pathFilter: PathFilters = {
    criteria: `assetid in ('${
      userGroup.groupID
    }') AND 'direction' in ('outbound') ${
      pathReviewState ? `AND 'reviewed' in ('unreviewed')` : ""
    }`,
  };
  const [selectedPathStatus, setSelectedPathStatus] = useState<
    PathStatus | undefined
  >(undefined);
  const onSelectPathStatus = (status: PathStatus | undefined) => {
    setSelectedPathStatus(status);
  };
  const [pathsSelection, setPathsSelection] = useState<Array<GridRowId>>([]);

  const confirmPathAction = () => {
    setPathsSelection([]);
    setSelectedPathStatus(undefined);
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 0,
          width: "70%",
          minWidth: "600px",
        },
        elevation: 1,
      }}
    >
      <Toolbar />
      <Stack
        alignItems="flex-start"
        sx={{ position: "relative", width: "100%" }}
      >
        <Tooltip title={window.getCTTranslatedText("Close Drawer")}>
          <IconButton
            size="medium"
            aria-label="close drawer"
            onClick={onClose}
            sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mt: 5, mb: 3, mx: 4 }}>
        <Typography variant="h5">
          <b>{window.getCTTranslatedText("Review User Group Paths")}</b>
        </Typography>
      </Stack>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <PathsReview
          includeCommonFacet={true}
          setPathSelection={setPathsSelection}
          pathFilter={pathFilter}
          updatePathstatus={onSelectPathStatus}
          useFacetStore={useFacetStore}
          mode={AssetReviewModeType.Review}
        />
      </DialogContent>
      <DialogActions>
        <PathStatusActionBar
          selectedStatus={selectedPathStatus}
          selection={pathsSelection}
          update={confirmPathAction}
          cancel={onClose}
          useStore={usePathAssetStore}
        />
      </DialogActions>
    </Drawer>
  );
}
