import { ROUTES } from "routes";

export const getValidRoutes = () => {
  let validRoutes: { [key: string]: string } = {};

  Object.keys(ROUTES)?.forEach((key: string) => {
    const routesPaths = ROUTES[key]?.pathname
      ?.split("/")
      ?.filter(route => route !== "" && route !== "*" && !route.includes(":"));
    routesPaths.forEach(route => {
      validRoutes[route] = route;
    });
  });

  return validRoutes;
};

export const PREVENT_BREADCRUMBS: { [key: string]: boolean } = {
  "edit-rule": true,
  "edit-policy": true,
};
