import { IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import { ServiceSVCIcon } from "assets/svgs";
import { useCommonStore } from "common/store";
import { AssetColumnRenderProps, AssetType } from "pages/assets/types";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import { capitalizeFirstLetter } from "pages/asset/components/asset-detail/helpers";
import { LaptopOutlined } from "@mui/icons-material";

const iconSize = "20";

export function AssetTypeIcon({ asset, viewOnly }: AssetColumnRenderProps) {
  const theme = useTheme();
  const facetState = useCommonStore(state => state.facets);
  const updateFacetOption = useCommonStore(state => state.updateFacetOption);

  const isSelected =
    !viewOnly && facetState?.get("type")?.get(asset.type)?.isSelected;

  let typeIcon;

  switch (asset.type) {
    case AssetType.Service:
      typeIcon = (
        <ServiceSVCIcon
          width={iconSize}
          height={iconSize}
          color={theme.palette.text.primary}
        />
      );
      break;
    case AssetType.Device:
      typeIcon = (
        <DevicesOutlinedIcon
          fontSize="small"
          sx={{
            color: theme.palette.text.primary,
          }}
        />
      );
      break;
    case AssetType.Server:
      typeIcon = (
        <StorageOutlinedIcon
          fontSize="small"
          sx={{
            color: theme.palette.text.primary,
          }}
        />
      );
      break;
    case AssetType.UserGroup:
      typeIcon = (
        <GroupsOutlinedIcon
          fontSize="small"
          sx={{
            color: theme.palette.text.primary,
          }}
        />
      );
      break;
    case AssetType.User:
    case AssetType.Endpoint:
      typeIcon = (
        <LaptopOutlined
          fontSize="small"
          sx={{
            color: theme.palette.text.primary,
          }}
        />
      );
      break;
    default:
      typeIcon = null;
  }

  const selectedBorder = `1px solid ${theme.palette.primary.main}`;

  if (!asset?.assetId || !asset?.type) {
    return null;
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      {typeIcon ? (
        <Tooltip
          title={window.getCTTranslatedText(
            capitalizeFirstLetter(asset?.type) ?? ""
          )}
        >
          <IconButton
            size="small"
            sx={{
              border: isSelected ? selectedBorder : undefined,
            }}
            disabled={viewOnly === true}
            color={isSelected ? "primary" : "inherit"}
            onClick={() => {
              if (asset?.type) {
                updateFacetOption({
                  facetName: "type",
                  optionName: asset.type,
                  value: !isSelected,
                });
              }
            }}
          >
            {typeIcon}
          </IconButton>
        </Tooltip>
      ) : null}
    </Stack>
  );
}
