import { Box, Link, Stack, Tooltip } from "@mui/material";
import { ContainerColumnRenderProps } from "pages/containers/types";
import { Link as RouterLink } from "react-router-dom";
import numeral from "numeral";

export function ServiceCount({ agent }: Readonly<ContainerColumnRenderProps>) {
  if (!agent.agentId) {
    return <Box style={{ minWidth: 120, minHeight: 45 }} />;
  }

  return (
    <Stack
      sx={{ maxWidth: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      <Stack justifyContent="center" alignItems="center">
        <Tooltip title={window.getCTTranslatedText("viewServices")}>
          <Link
            typography={"body2"}
            component={RouterLink}
            to={`/assets?filters=${encodeURIComponent(
              `clusteridentifier=${agent?.clusterIdentifier}|type=service`
            )}`}
            sx={{ color: "primary.main", ml: 6 }}
          >
            {numeral(agent?.serviceCount || 0).format("0a")}
          </Link>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
