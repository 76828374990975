import { Paper, Stack, useTheme } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FacetOpenCloseController } from "common/atoms/facet-open-close-controller";
import { getUniqueListBy } from "common/utils/getUniqueListBy";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useCore } from "modules/core";
import { FacetControllerGroup } from "modules/facets";
import { FacetGroups, Operator } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope } from "modules/scope-metadata/types";
import { useCoreTagsFacetOrder } from "pages/create-tag-policy/CreateTagPolicy";
import { SegmentsHeader } from "pages/segments/SegmentsHeader";
import { FACET_ORDER as TEMPLATE_FACET_ORDER } from "pages/templates/constants";
import { useEffect, useMemo } from "react";
import { TagPolicyDataGrid } from "./components/TagPolicyDataGrid";
import { tabBasedPolicyColumnConfig } from "./constants";
import { useTagPolicyFacetStore, useTagPolicyStore } from "./store";
import { TagPolicy } from "./types";

export function useTagPolicyListAPI() {
  return useMutation<any, Error, any>([
    "tag-policy",
    "tagbasedpolicies/actions/search?computeTotal=true",
  ]);
}

export function TagPolicyList() {
  const theme = useTheme();
  const { isFeatureEnabled } = useFeatureFlagControl(FEATURES.PROGRESSIVE);
  const { isFeatureEnabled: isSegmentsFeatureEnabled } = useFeatureFlagControl(
    FEATURES.SEGMENTS
  );
  const facetOrder = useCoreTagsFacetOrder();
  const facetOrderGroup = useMemo(() => {
    const commonFacetOrder = {
      lowestpolicyinboundstatus: FacetGroups.PolicyAutomation,
      lowestpolicyoutboundstatus: FacetGroups.PolicyAutomation,
      policyautomationconfigurable: FacetGroups.PolicyAutomation,
      namednetworkid: FacetGroups.Network,
      namednetworkname: FacetGroups.Network,
      TEMPLATE_FACET_ORDER,
      templateid: FacetGroups.Templates,
      policyautosynchronize: FacetGroups.PolicyAutomation,
    };

    return isFeatureEnabled
      ? {
          ...facetOrder,
          ...commonFacetOrder,
          lowestpolicyprogressiveinboundstatus: FacetGroups.PolicyAutomation, // isProgressiveEnabled
        }
      : {
          ...commonFacetOrder,
          ...facetOrder,
        };
  }, [facetOrder, isFeatureEnabled]);

  const { data: metaData } = useScopeMetadata({
    scope: Scope.TagBasedPolicy,
  });
  const updateMetadata = useTagPolicyFacetStore(state => state.updateMetadata);
  useEffect(() => {
    updateMetadata(metaData);
  }, [metaData, updateMetadata]);

  const coreResponse = useCore<TagPolicy>({
    useStore: useTagPolicyStore,
    useFacetStore: useTagPolicyFacetStore,
    facetGroupInfo: facetOrderGroup,
    scope: Scope.TagBasedPolicy,
    useApi: useTagPolicyListAPI,
    pageSize: 100,
  });

  return (
    <Stack direction={"column"} sx={{ height: "100%", width: "100%" }}>
      {isSegmentsFeatureEnabled && <SegmentsHeader />}
      <Stack direction="row">
        <Stack sx={{ flex: 1, py: 2 }}>
          <FacetOpenCloseController
            useFacetStore={useTagPolicyFacetStore}
            facetsOpen={coreResponse.facetsOpen}
            setFacetsOpen={coreResponse.setFacetsOpen}
          >
            <FacetControllerGroup
              useFacetStore={useTagPolicyFacetStore}
              config={coreResponse.facetConfig}
              value={coreResponse.facetState}
              onChange={coreResponse.updateFacet}
              disabledOperators={[Operator.NOT_EQUAL]}
            />
          </FacetOpenCloseController>
        </Stack>
      </Stack>

      <Paper
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          flex: 1,
          overflow: "hidden",
        }}
      >
        <TagPolicyDataGrid
          defaultPinnedColumns={tabBasedPolicyColumnConfig.pinnedColumns}
          rowCount={coreResponse.rowCount}
          rows={getUniqueListBy(coreResponse.rows ?? [], "tagBasedPolicyId")}
          mutation={coreResponse.mutation}
          onPageChange={coreResponse.onPageChange}
          page={coreResponse.page}
          pageSize={coreResponse.pageSize}
          onPageSizeChange={coreResponse.onPageSizeChange}
          onSortChange={coreResponse.onSortChange}
          hasNoData={
            coreResponse.mutation.isSuccess && coreResponse.rowCount === 0
          }
          metadata={coreResponse.metadata}
          triggerExportAsCsv={coreResponse?.triggerExportAsCsv}
          getExportStatus={coreResponse?.getExportStatus}
          getUrlToDownload={coreResponse?.getUrlToDownload}
          resetDownloadUrl={coreResponse?.resetDownloadUrl}
          rawData={coreResponse?.rawData}
        />
      </Paper>
    </Stack>
  );
}
