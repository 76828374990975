import { Box, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

function useUser() {
  // Tag/Unique ID for query : User
  // Path for query : hello; which transaltes into => baseURL/hello
  return useQuery<any, Error>(["user", "auth/hello"]);
}

export function Profile() {
  const { status, data, error, isFetching } = useUser();

  return (
    <Box>
      <h1>User</h1>
      <Box>
        {status === "loading" ? (
          "Loading..."
        ) : status === "error" ? (
          <Stack>Error: {error.message}</Stack>
        ) : (
          <>
            <Box>{JSON.stringify(data)}</Box>
            <Box>{isFetching ? "Background Updating..." : " "}</Box>
          </>
        )}
      </Box>
    </Box>
  );
}
