import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CustomCommonStoreType } from "common/types/types";
import { AssetReviewModeType } from "pages/asset/components/asset-detail/AssetDetail";
import {
  BASE_PATH_COLUMNS,
  FIREWALL_COUNT_COLUMNS,
} from "pages/paths/components/path-data-grid/constants";
import { ColumnType } from "pages/ports/components/port-data-grid/constants";
import { PathStatusView } from "pages/paths/components/path-status-view";
import { PathAnnotations } from "pages/paths/components/path-annotations";

interface PathReviewColumnsProps {
  useFacetStore?: CustomCommonStoreType;
  mode: AssetReviewModeType;
  hasService?: boolean;
}

export const getPathReviewColumns = ({
  useFacetStore,
  mode,
  hasService,
}: PathReviewColumnsProps) => {
  const ASSET_DETAILS_PATH_COLUMNS: GridColDef[] = [
    ...BASE_PATH_COLUMNS(ColumnType.DRAWER, useFacetStore).filter(col => {
      if (
        mode === AssetReviewModeType.FireWallReview &&
        (col.field === "bandwidthinbytes" || col.field === "connectioncount")
      ) {
        return false;
      }

      if (col.field === "__check__" && mode !== AssetReviewModeType.Selection) {
        return false;
      }

      return true;
    }),
    ...(mode === AssetReviewModeType.FireWallReview
      ? FIREWALL_COUNT_COLUMNS
      : []),
    {
      field: "reviewed",
      headerName: "Status",
      width: 150,
      minWidth: 150,
      sortable: false,
      sortingOrder: ["asc", "desc"],
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return (
          <PathStatusView path={params.row} useFacetStore={useFacetStore} />
        );
      },
    },
    {
      field: "comment",
      headerName: "",
      headerAlign: "left",
      align: "left",
      width: 50,
      minWidth: 50,
      sortingOrder: ["desc", "asc"],
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return <PathAnnotations path={params.row} />;
      },
    },
  ];

  return ASSET_DETAILS_PATH_COLUMNS.filter(column => {
    const removeColumns = [];

    if (!hasService) {
      removeColumns.push("api");
    }

    return !removeColumns.includes(column.field);
  });
};

export const PORTS_REVIEW_DATA_GRID_ID = "ports-review-data-grid";
