import { useQuery } from "@tanstack/react-query";
import { FirewallLogs } from "pages/asset/types";

export function useFirewallLogsAPI({ id }: { id: string }) {
  return useQuery<FirewallLogs, Error>([
    `firewallrules`,
    `assets/${id}/firewallrules`,
  ]);
}

export function useFirewallLogsApplianceAPI({ id }: { id: string }) {
  return useQuery<FirewallLogs, Error>([
    `firewallrules`,
    `gateways/${id}/firewallrules`,
  ]);
}
