import { Button, LinearProgress, Stack, Typography } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  BASE_TAG_POLICY_COLS,
  TAG_POLICY_COLS,
  tabBasedPolicyColumnConfig,
} from "../constants";
import { useTagPolicyStore } from "../store";
import { TagPolicy } from "../types";

export const TagPolicyDataGrid = (props: DataGridProps<TagPolicy>) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const setSelectedCriteria = useTagPolicyStore(
    state => state.setSelectedCriteria
  );
  const selectedCriteria = useTagPolicyStore(state => state.selectedCriteria);
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const { isFeatureEnabled } = useFeatureFlagControl(FEATURES.PROGRESSIVE);
  const { isFeatureEnabled: isSegmentsEnabled } = useFeatureFlagControl(
    FEATURES.SEGMENTS
  );
  const isProgressiveEnabled = isFeatureEnabled;

  useEffect(() => {
    if (selectedCriteria.length === 0) {
      setSelection([]);
    }
  }, [selectedCriteria]);

  const columns = useMemo(() => {
    let columns = userPermissions.has("DELETE_TAGBASEDPOLICY")
      ? TAG_POLICY_COLS(isSegmentsEnabled)
      : BASE_TAG_POLICY_COLS(isSegmentsEnabled);
    return columns.filter(col => {
      let include = !props.hiddenColumns?.includes(col.field);

      if (
        !isProgressiveEnabled &&
        col.field === "policyAutomation-progressivestatus"
      ) {
        include = false;
      }

      if (!isSegmentsEnabled && col.field === "description") {
        include = false;
      }

      return include;
    });
  }, [
    userPermissions,
    props.hiddenColumns,
    isProgressiveEnabled,
    isSegmentsEnabled,
  ]);

  const selectedRawData: Array<TagPolicy> | undefined = useMemo(() => {
    return (props?.rawData ?? [])?.filter((row: TagPolicy) => {
      return selection.indexOf(row?.tagBasedPolicyId) !== -1;
    });
  }, [selection, props?.rawData]);

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        "& .policyAutomationHeader .MuiDataGrid-columnHeaderTitleContainerContent":
          {
            flex: 1,
          },
      }}
    >
      <DataGrid
        checkboxSelection={
          isSegmentsEnabled && userPermissions.has("CREATE_TAGBASEDPOLICY")
        }
        defaultPinnedColumns={tabBasedPolicyColumnConfig.pinnedColumns}
        rowSelectionModel={selection}
        onRowSelectionModelChange={selectionModel => {
          setSelection(selectionModel);
          setSelectedCriteria(
            props?.rows
              ?.filter(row => selectionModel.includes(row.tagBasedPolicyId))
              .map(row => row.criteria ?? "") ?? []
          );
        }}
        slots={{
          noRowsOverlay: NoRowsOverlayComp,
          ...props.slots,
          loadingOverlay: LinearProgress,
        }}
        rowHeight={64}
        columns={columns}
        pagination={true}
        getRowId={({ tagBasedPolicyId: id }: TagPolicy) => `${id}`}
        paginationMode="server"
        sortingMode="server"
        {...props}
        selectedRawData={selectedRawData}
      />
    </Stack>
  );
};

const NoRowsOverlayComp = (props: DataGridProps<TagPolicy>) => {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const { isFeatureEnabled: isSegmentsEnabled } = useFeatureFlagControl(
    FEATURES.SEGMENTS
  );

  return (
    <Stack
      alignItems={"center"}
      justifyContent="center"
      sx={{
        zIndex: 100,
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Typography variant="body2">
        {window.getCTTranslatedText("No results")}
      </Typography>
      {userPermissions.has("CREATE_TAGBASEDPOLICY") && props.hasNoData && (
        <Link to={isSegmentsEnabled ? "/segment/create" : "/tag-policy/create"}>
          <Button>
            {window.getCTTranslatedText(
              isSegmentsEnabled ? "CreateSegment" : "Create tag policy"
            )}
          </Button>
        </Link>
      )}
    </Stack>
  );
};
