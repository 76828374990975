import { debounce, Stack, useTheme } from "@mui/material";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { Appliance } from "pages/appliances/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ApplianceSummary } from "./components/appliance-summary";
import { ApplianceToolbar } from "./components/appliance-toolbar";
import { useApplianceDetailsAPI } from "./hooks";
import { ApplianceTabs, ApplianceTabValues } from "./types";
import { TabPanel } from "common/atoms/ct-tabs/CtTabs";
import { ApplianceDevices } from "./components/appliance-devices";
import { ApplianceUnmanagedDevices } from "./components/appliance-unmanaged-devices";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useApplianceStore } from "pages/appliances/store";

const tabPanelStyles = {
  marginTop: 6,
  minHeight: "100%",
};

export const ApplianceDetail = () => {
  const theme = useTheme();
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );
  const { applianceName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabValue = searchParams.get("tab");
  const [selectedTab, setSelectedTab] = useState(ApplianceTabValues.Summary);
  const setSnackbar = useSnackbarStore(state => state.setSnackbar);
  const [applianceDetails, setApplianceDetails] = useState<Appliance>();
  const [deviceType, setDeviceType] = useState<string>();
  const applianceDetailMutation = useApplianceDetailsAPI();
  const applianceDetailMutationObj = useMemo(
    () => debounce(applianceDetailMutation.mutate, 100),
    [applianceDetailMutation.mutate]
  );
  const appliancesState = useApplianceStore(state => state);

  const canUpdate = userPermissions.has("UPGRADE_AGENT");

  const getApplianceDetails = useCallback(() => {
    const body = {
      criteria: `'agentname' in ('${applianceName}')`,
      pagination: {
        offset: 0,
        limit: 1,
        sort: [],
      },
      facetFields: [],
    };
    applianceDetailMutationObj(body, {
      onSuccess(response) {
        if (response?.items?.length) {
          setApplianceDetails(response?.items[0]);
        }
      },
      onError: error => {
        setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
      },
    });
  }, [applianceDetailMutationObj, applianceName, setSnackbar]);

  useEffect(() => {
    getApplianceDetails();
  }, [getApplianceDetails, appliancesState]);

  useEffect(() => {
    let tabName = 0;
    switch (tabValue) {
      case ApplianceTabs.Summary:
        tabName = ApplianceTabValues.Summary;
        break;
      case ApplianceTabs.Devices:
        tabName = ApplianceTabValues.Devices;
        break;
      case ApplianceTabs.UnmanagedDevices:
        tabName = ApplianceTabValues.UnmanagedDevices;
        break;
    }
    setSelectedTab(tabName);
  }, [tabValue]);

  const handleTabChange = useCallback(
    (newValue: number) => {
      setSelectedTab(newValue);
      let tabName = "";
      switch (newValue) {
        case ApplianceTabValues.Summary:
          tabName = ApplianceTabs.Summary;
          setDeviceType("");
          break;
        case ApplianceTabValues.Devices:
          tabName = ApplianceTabs.Devices;
          break;
        case ApplianceTabValues.UnmanagedDevices:
          tabName = ApplianceTabs.UnmanagedDevices;
          break;
      }
      let path = `/appliances/${applianceName}?tab=${tabName}`;
      navigate(path);
    },
    [setSelectedTab, navigate, applianceName]
  );

  useEffect(() => {
    if (deviceType) {
      handleTabChange(ApplianceTabValues.Devices);
    }
  }, [deviceType, handleTabChange]);

  return (
    <Stack
      direction={"column"}
      spacing={2}
      sx={{ height: "100%", width: "100%" }}
    >
      <Stack sx={{ width: "100%" }}>
        {applianceDetails ? (
          <ApplianceToolbar
            selectedTab={selectedTab}
            handleTabChange={(event, value) => handleTabChange(value)}
            appliance={applianceDetails}
          />
        ) : null}
      </Stack>
      <Stack
        sx={{
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          flex: 1,
          minWidth: 0,
          height: "100%",
          mb: 1,
          pb: 1,
          overflowY: "auto",
        }}
        spacing={3}
      >
        <TabPanel
          value={selectedTab}
          index={ApplianceTabValues.Summary}
          style={tabPanelStyles}
        >
          <ApplianceSummary
            applianceName={applianceName}
            applianceDetails={applianceDetails}
            applianceDetailsLoading={applianceDetailMutation.isLoading}
            setDeviceType={setDeviceType}
            canUpdate={canUpdate}
          />
        </TabPanel>
        <TabPanel
          value={selectedTab}
          index={ApplianceTabValues.Devices}
          style={tabPanelStyles}
        >
          <ApplianceDevices
            applianceName={applianceName}
            deviceType={deviceType}
          />
        </TabPanel>
        <TabPanel
          value={selectedTab}
          index={ApplianceTabValues.UnmanagedDevices}
          style={tabPanelStyles}
        >
          <ApplianceUnmanagedDevices
            applianceName={applianceName}
            applianceId={applianceDetails?.agentId}
          />
        </TabPanel>
      </Stack>
    </Stack>
  );
};
