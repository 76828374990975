import { CoreStoreBuilder } from "modules/core/store";

import { CustomCommonStoreType } from "common/types/types";
import { FacetStoreBuilder } from "modules/facets";
import { FacetStore } from "modules/facets/types";
import { Scope } from "modules/scope-metadata/types";
import { create } from "zustand";
import { NNHitStore } from "./types";

export const useNNHitStore = create<NNHitStore>()(set => ({
  ...CoreStoreBuilder(set),
}));

export const useNNHitsFacetStore: CustomCommonStoreType = create<FacetStore>(
  set => ({
    ...FacetStoreBuilder(set),
    scope: Scope.Network,
  })
);
