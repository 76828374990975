import { Integrations } from "pages/Integration/Integrations";
import { Agents } from "pages/agents";
import { AgentDownloadPage } from "pages/agents/components/agent-download-page/AgentDownloadPage";
import { agent_types } from "pages/agents/components/agent-download-page/constants";
import { APIKeys } from "pages/api-keys";
import { Appliances } from "pages/appliances";
import { ApplianceDownloadPage } from "pages/appliances/components/appliance-download-page";
import { Asset } from "pages/asset";
import { Assets } from "pages/assets";
import { Containers } from "pages/containers";
import { CreateTagPolicy } from "pages/create-tag-policy";
import { CreateTagRule } from "pages/create-tag-rule";
import { Dashboard } from "pages/dashboard/Dashboard";
import { Monitoring } from "pages/monitoring";
import { Networks } from "pages/networks";
import { TeamMembers } from "pages/organization";
import { Paths } from "pages/paths";
import { Ports } from "pages/ports";
import { Profile } from "pages/profile";
import { Settings } from "pages/settings";
import { Tags } from "pages/tags/Tags";
import { TagDetail } from "pages/tags/components/tag-detail";
import { Templates } from "pages/templates";
import { TrafficVisualizer } from "pages/traffic-visualizer";
import { UserSegmentation } from "pages/users-segmentation";
import { UserDepartments } from "pages/users-segmentation/components/user-departments/UserDepartments";
import { UserGroups } from "pages/users-segmentation/components/user-groups";
import { Users } from "pages/users-segmentation/components/users";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "./routes";
import { ApplianceDetail } from "pages/appliance-detail";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { HostGroups } from "pages/host-groups";
import { Segments } from "pages/segments";
import { Reports } from "pages/reports";

export const PrivateRoutes = () => {
  const { isFeatureEnabled: isPanopticMapfeatureEnabled } =
    useFeatureFlagControl(FEATURES.PANOPTIC_MAP);
  const { isFeatureEnabled: isScimFeaturesEnabled } = useFeatureFlagControl(
    FEATURES.USER_SEGMENTATION
  );
  const { isFeatureEnabled: isCrowdStrikeFeatureEnabled } =
    useFeatureFlagControl(FEATURES.CROWDSTRIKE_EDR);

  return (
    <Routes>
      <Route path={ROUTES.DASHBOARD.pathname} element={<Dashboard />} />
      {isPanopticMapfeatureEnabled && (
        <Route
          path={ROUTES.PANOPTIC_MAP.pathname}
          element={<TrafficVisualizer />}
        />
      )}
      <Route path={ROUTES.ASSETS.pathname} element={<Assets />} />
      <Route path={ROUTES.ASSET.pathname} element={<Asset />} />
      <Route path={ROUTES.AGENT.pathname} element={<Asset />} />
      <Route path={ROUTES.PORTS.pathname} element={<Ports />} />
      <Route path={ROUTES.PATHS.pathname} element={<Paths />} />
      <Route path={ROUTES.TEMPLATES.pathname} element={<Templates />} />
      <Route path={ROUTES.MONITORING.pathname} element={<Monitoring />} />
      <Route path={ROUTES.NETWORKS.pathname} element={<Networks />} />
      <Route path={ROUTES.TAGS.pathname} element={<Tags />} />
      <Route path={ROUTES.TAG.pathname} element={<TagDetail />} />
      <Route path={ROUTES.AGENTS.pathname} element={<Agents />} />
      <Route path={ROUTES.PROFILE.pathname} element={<Profile />} />
      <Route path={ROUTES.ORG.pathname} element={<TeamMembers />} />
      <Route
        path={ROUTES.INSTALL_AGENTS.pathname}
        element={<AgentDownloadPage />}
      />
      <Route
        path={ROUTES.INSTALL_APPLIANCES.pathname}
        element={<ApplianceDownloadPage />}
      />
      <Route
        path={ROUTES.INSTALL_COLLECTOR.pathname}
        element={
          <AgentDownloadPage agentType={agent_types.CT_CONTAINER_AGENT} />
        }
      />
      <Route path={ROUTES.USERS.pathname} element={<Users />} />
      <Route path={ROUTES.GROUPS.pathname} element={<UserGroups />} />
      <Route path={ROUTES.DEPARTMENTS.pathname} element={<UserDepartments />} />
      <Route
        path={ROUTES.VISUALIZER.pathname}
        element={<TrafficVisualizer />}
      />
      <Route
        path={ROUTES.CONNECTORDOWNLOAD.pathname}
        element={
          <AgentDownloadPage agentType={agent_types.CT_REMOTE_CONNECTOR} />
        }
      />
      <Route path={ROUTES.CLUSTERS.pathname} element={<Containers />} />
      <Route path={ROUTES.APPLIANCES.pathname} element={<Appliances />} />
      <Route path={ROUTES.APPLIANCE.pathname} element={<ApplianceDetail />} />
      <Route path={ROUTES.SETTINGS.pathname} element={<Settings />} />
      <Route path={ROUTES.API_KEYS.pathname} element={<APIKeys />} />
      <Route path={ROUTES.INTEGRATIONS.pathname} element={<Integrations />} />
      <Route path={ROUTES.TAG_RULE.pathname} element={<CreateTagRule />} />
      <Route path={ROUTES.EDIT_RULE.pathname} element={<CreateTagRule />} />
      <Route path={ROUTES.TAG_POLICY.pathname} element={<CreateTagPolicy />} />
      <Route path={ROUTES.REPORTS.pathname} element={<Reports />} />
      <Route
        path={ROUTES.SEGMENT_CREATION.pathname}
        element={<CreateTagPolicy />}
      />
      <Route path={ROUTES.EDIT_POLICY.pathname} element={<CreateTagPolicy />} />
      <Route
        path={ROUTES.SEGMENT_EDIT.pathname}
        element={<CreateTagPolicy />}
      />
      <Route
        path={ROUTES.SEGMENT_CLONE.pathname}
        element={<CreateTagPolicy />}
      />
      <Route path={ROUTES.SEGMENT.pathname} element={<Segments />} />
      {isCrowdStrikeFeatureEnabled && (
        <Route path={ROUTES.HOST_GROUPS.pathname} element={<HostGroups />} />
      )}
      {isScimFeaturesEnabled && (
        <Route
          path={ROUTES.USER_SEGMENTATION.pathname}
          element={<UserSegmentation />}
        />
      )}
      <Route
        path={ROUTES.CLONE_POLICY.pathname}
        element={<CreateTagPolicy />}
      />

      <Route
        path={ROUTES.ALL.pathname}
        element={<Navigate to={ROUTES.DASHBOARD.pathname} replace={true} />}
      />
    </Routes>
  );
};
