import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AssignNetworksDrawer } from "modules/assign-networks-to-assets/components/assign-networks-drawer";
import { useTagNetworks } from "./hooks";
import { TagAttributesBlock } from "../tag-templates/components/tags-attributes-block";

export function TagNetworks({
  criteria,
  multiselect = false,
  onSelectCallback,
  btnTitle,
  canAssign,
}: {
  criteria: string;
  multiselect?: boolean;
  onSelectCallback?: Function;
  btnTitle?: string;
  canAssign?: boolean;
}) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const {
    showAssignNetworksDrawer,
    openAssignNetworksDrawer,
    closeAssignNetworksDrawer,
  } = useTagNetworks();

  return (
    <>
      <TagAttributesBlock
        title="Named Networks"
        canAssign={Boolean(userPermissions.has("UPDATE_POLICY") && canAssign)}
        assign={openAssignNetworksDrawer}
        btnTitle={btnTitle}
      />
      <AssignNetworksDrawer
        isOpen={showAssignNetworksDrawer}
        page={"tags"}
        title="Assign Named Network"
        rules={[]}
        onCancel={closeAssignNetworksDrawer}
        onConfirm={closeAssignNetworksDrawer}
        btnTitle={"assign"}
        criteria={criteria}
        multiselect={multiselect}
        onSelectCallback={onSelectCallback}
        hasPermission={userPermissions.has("UPDATE_POLICY")}
      />
    </>
  );
}
