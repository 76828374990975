import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useCommonStore } from "common/store";
import { AssetSecurityDetail } from "pages/asset/components/asset-detail/AssetSecurityDetail";
import { Asset, SimulatationStatusList } from "pages/assets/types";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { AssetLoader } from "../asset-loader";
import { AssetMetaDataWrapper } from "../asset-metadata-wrapper";
import AssetBanner from "./AssetBanner";
import { useAssetAPI } from "./hooks";

export enum AssetReviewModeType {
  Review = "review",
  FireWallReview = "firewall_logs",
  ViewFirewallPendingChanges = "firewall_pending_change",
  EdgeReview = "edge_review",
  Selection = "selection",
}

export const AssetDetail = () => {
  const [showAssetBanner, setShowAssetBanner] = useState(true);
  const { assetId } = useParams();
  let { data, isLoading }: any = useAssetAPI({ assetId });

  const queryClient = useQueryClient();

  const asset: Asset = useMemo(() => {
    if (!data) {
      return;
    }

    data["simulateInboundAssetStatus"] = Boolean(
      SimulatationStatusList[data.inboundAssetStatus] || false
    );
    data["simulateOutboundAssetStatus"] = Boolean(
      SimulatationStatusList[data.outboundAssetStatus] || false
    );

    return data;
  }, [data]);

  let metadata = useCommonStore(state => state.metadata);
  if (metadata?.columns?.assetname) {
    metadata.columns.assetname.displayName = "Asset";
  }
  if (metadata?.columns?.assetavailability) {
    metadata.columns.assetavailability.displayName = "Status";
  }

  const updateAssetMetadata = ({ body, id, key }: any) => {
    if (id === "coreTags") {
      data[id][key] = body[id][key];
    } else {
      data[id] = body[id];
    }
    queryClient.invalidateQueries({
      queryKey: ["asset"],
    });
  };

  if (isLoading || !asset) {
    return <AssetLoader />;
  }

  return (
    <>
      <Stack
        sx={{ minHeight: "100%", width: "100%", overflowX: "hidden", pl: 3 }}
        direction={"column"}
        justifyContent="flex-start"
        spacing={3}
      >
        <Stack
          alignItems={"flex-start"}
          direction={"column"}
          sx={{
            width: "100%",
            pr: 3,
          }}
        >
          {asset?.newPathProcessingStopped && showAssetBanner && (
            <AssetBanner
              setShowAssetBanner={setShowAssetBanner}
              text={window.getCTTranslatedText("singleAsset")}
            />
          )}
          <AssetMetaDataWrapper
            asset={asset}
            updateAssetMetadata={updateAssetMetadata}
          />
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <AssetSecurityDetail
            asset={asset}
            data={data}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>
    </>
  );
};
