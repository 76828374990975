import { Stack, Typography, useTheme } from "@mui/material";
import { CTDrawer } from "common/molecules/drawer/CTDrawer";

import { getLocalDate } from "common/utils/getDate";
import isNil from "lodash/isNil";
import merge from "lodash/merge";
import omitBy from "lodash/omitBy";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import { AuditLog } from "pages/monitoring/types";
import { useMemo } from "react";
import ReactJson from "react-json-view";
import { showAuditFields } from "./constants";

export interface AuditLogDetailsProps {
  auditLog: AuditLog;
  onClose: VoidFunction;
}

const LogsValidKeys: { [key: string]: boolean } = {
  eventType: true,
  action: true,
  message: true,
  creationTime: true,
  actionBy: true,
  resourceId: true,
  metadata: true,
  asset: true,
};

export function AuditLogDetails({ auditLog, onClose }: AuditLogDetailsProps) {
  const theme = useTheme();

  const scope = SCOPE_MAP[auditLog?.action || ""];
  const { data: metaData } = useScopeMetadata({ scope });
  const showFields = showAuditFields;

  const auditMap = useMemo(() => {
    if (!auditLog) {
      return;
    }

    if (scope && !metaData) {
      return;
    }

    let o: { [key: string]: any } = {};
    let entries = Object.entries(auditLog);
    entries.forEach(([key, value]) => {
      if (!value || !LogsValidKeys[key]) {
        return;
      }
      if (key === "metadata") {
        try {
          value = JSON.parse(value);
          if (!value) {
            return;
          }
          if (metaData) {
            value = handleMetadata(value, auditLog.action, metaData);
          }
        } catch (e) {}
      }

      if (key === "asset") {
        value = omitBy(value, isNil);
      }
      o[key] = value;
    });
    return o;
  }, [auditLog, scope, metaData]);

  if (auditLog?.action && scope && !metaData) {
    return null;
  }

  return (
    <CTDrawer
      PaperProps={{
        sx: {
          p: 0,
          maxwidth: "1000px",
          minWidth: "800px",
        },
        elevation: 1,
      }}
      title={`${auditLog.eventCategory} Details`}
      onClose={onClose}
      open={Boolean(auditLog)}
    >
      <Stack
        sx={{
          "& .react-json-view": {
            backgroundColor: "initial !important",
          },
          "& .object-key": {
            color: theme.palette.text.secondary,
            fontSize: theme.typography.body2.fontSize,
          },
          "& .pushed-content.object-container .pushed-content.object-container  .object-key":
            {
              color: theme.palette.info.main,
            },
          "& .string-value, & .variable-value div": {
            color: `${theme.palette.text.primary} !important`,
            fontSize: theme.typography.body2.fontSize,
          },
          height: "100%",
          overflowY: "auto",
        }}
      >
        {auditMap && (
          <Stack>
            <Stack spacing={1} sx={{ mb: 3 }}>
              {showFields.map((fieldObj, index) => (
                <Stack
                  key={fieldObj.field}
                  direction="row"
                  spacing={1}
                  sx={{
                    "& .name": {
                      flex: "20%",
                      padding: "5px",
                    },
                    "& .field": {
                      flex: "80%",
                      padding: "5px",
                    },
                  }}
                >
                  <Typography variant="body2" className="name">
                    {fieldObj?.name} :
                  </Typography>
                  <Typography variant="body2" className="field">
                    {fieldObj?.field === "creationTime"
                      ? getLocalDate(auditMap[fieldObj?.field])
                      : auditMap[fieldObj?.field]}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <ReactJson
              name={false}
              src={auditMap}
              enableClipboard={false}
              displayDataTypes={false}
              iconStyle="square"
              displayObjectSize={false}
              theme={
                theme.palette.mode === "dark"
                  ? "grayscale"
                  : "grayscale:inverted"
              }
            />
          </Stack>
        )}
      </Stack>
    </CTDrawer>
  );
}

const AUDIT_LOG_ACTION_CHANGE_ASSET_STATE = "Asset Status Changed";
const AUDIT_LOG_ACTION_CHANGE_ASSET_LOWEST_ENFORCEMENT_LEVEL =
  "Asset Minimal Status Changed";
const AUDIT_LOG_ACTION_CHANGE_POLICY_LOWEST_ENFORCEMENT_LEVEL =
  "Policy Minimal Status Changed";

const AUDIT_LOG_ACTION_PORT_CHANGE = "Port Status Changed";

const AUDIT_LOG_ACTION_PATH_CHANGE = "Path Status Changed";

function handleMetadata(
  metadata: any,
  action: string,
  scopeMetadata: ScopeMetadata
) {
  switch (action) {
    case AUDIT_LOG_ACTION_CHANGE_ASSET_STATE:
      let inbound = mapStatus(metadata, "inboundstatus", scopeMetadata);
      let outbound = mapStatus(metadata, "outboundstatus", scopeMetadata);
      return merge(inbound, outbound);

    case AUDIT_LOG_ACTION_CHANGE_ASSET_LOWEST_ENFORCEMENT_LEVEL:
      let inboundLowest = mapStatus(
        metadata,
        "lowestassetinboundstatus",
        scopeMetadata
      );
      let outboundLowest = mapStatus(
        metadata,
        "lowestassetoutboundstatus",
        scopeMetadata
      );
      let autosynchronize = mapStatus(
        metadata,
        "autosynchronize",
        scopeMetadata
      );
      let lowestAssetProgressiveInboundStatus = mapStatus(
        metadata,
        "lowestassetprogressiveinboundstatus",
        scopeMetadata
      );

      return merge(
        inboundLowest,
        outboundLowest,
        autosynchronize,
        lowestAssetProgressiveInboundStatus
      );

    case AUDIT_LOG_ACTION_CHANGE_POLICY_LOWEST_ENFORCEMENT_LEVEL:
      let inboundPolicyLowest = mapStatus(
        metadata,
        "lowestpolicyinboundstatus",
        scopeMetadata
      );
      let outboundPolicyLowest = mapStatus(
        metadata,
        "lowestpolicyoutboundstatus",
        scopeMetadata
      );
      let policyautosynchronize = mapStatus(
        metadata,
        "policyautosynchronize",
        scopeMetadata
      );
      let lowestPolicyProgressiveInboundStatus = mapStatus(
        metadata,
        "lowestpolicyprogressiveinboundstatus",
        scopeMetadata
      );

      return merge(
        inboundPolicyLowest,
        outboundPolicyLowest,
        policyautosynchronize,
        lowestPolicyProgressiveInboundStatus
      );

    case AUDIT_LOG_ACTION_PORT_CHANGE:
      let portKey = metadata.original.hasOwnProperty("listenportenforced")
        ? "listenportenforced"
        : "listenportreviewed";

      return mapStatus(metadata, portKey, scopeMetadata);

    case AUDIT_LOG_ACTION_PATH_CHANGE:
      let pathKey = metadata.original.hasOwnProperty("enforced")
        ? "enforced"
        : "reviewed";

      return mapStatus(metadata, pathKey, scopeMetadata);
  }

  return metadata;
}

function mapStatus(
  metadata: any,
  key: string,
  scopeMetadata: ScopeMetadata,
  alias?: string
) {
  let original = getDisplayName(
    alias ?? key,
    metadata.original[key],
    scopeMetadata
  );
  let modified = getDisplayName(
    alias ?? key,
    metadata.modified[key],
    scopeMetadata
  );
  if (original === modified) {
    return {};
  }
  return {
    ...metadata,
    original: {
      [key]: original,
    },
    modified: {
      [key]: modified,
    },
  };
}

const SCOPE_MAP: { [key: string]: Scope | undefined } = {
  [AUDIT_LOG_ACTION_CHANGE_ASSET_STATE]: Scope.Asset,
  [AUDIT_LOG_ACTION_CHANGE_ASSET_LOWEST_ENFORCEMENT_LEVEL]: Scope.Asset,
  [AUDIT_LOG_ACTION_CHANGE_POLICY_LOWEST_ENFORCEMENT_LEVEL]:
    Scope.TagBasedPolicy,

  [AUDIT_LOG_ACTION_PORT_CHANGE]: Scope.Port,

  [AUDIT_LOG_ACTION_PATH_CHANGE]: Scope.Path,
};

function getDisplayName(
  colName: string,
  internalValue: any,
  metadata: ScopeMetadata
) {
  let col = metadata?.columns[colName];
  if (!col?.values?.length) {
    return internalValue;
  }
  let value = col.values?.find(v => v.internal === internalValue);
  if (!value) {
    return internalValue;
  }
  return window.getCTTranslatedText(value.display);
}
