import { Stack } from "@mui/material";
import { SecureAllIcon, SecureIcon, UnsecuredIcon } from "assets/svgs";
import { IconsProps } from "assets/svgs/common";
import numeral from "numeral";
import {
  AssetStatusMap,
  AssetStatusReverseMap,
  SecurityStatus,
  SecurityStatusLabels,
} from "pages/asset/components/asset-detail/constants";
import { AssetStatus } from "pages/assets/types";
import React, { useEffect, useRef, useState } from "react";
import { AssetDistributionData } from "../types";
import { AssetsButtonWithBadge } from "./AssetsButtonWithBadge";

const format = "0,0";

interface AssetsGroupButtonDataProps {
  securityStatus: SecurityStatus;
  renderIcon: (props: IconsProps) => JSX.Element;
  badgeContent: string;
}

export const AssetStatusDistributionControls = ({
  data,
  onInitStatus,
  onChangeSecurityStatus,
  onChangeSelectedAssetGroupSecurityStatus,
  selectedAssetGroupSecurityStatus,
  selectedSecurityStatus,
}: {
  data: AssetDistributionData;
  onInitStatus: (assetStatus: AssetStatus) => void;
  onChangeSecurityStatus: (status: SecurityStatus) => void;
  selectedSecurityStatus: SecurityStatus;
  selectedAssetGroupSecurityStatus: SecurityStatus;
  onChangeSelectedAssetGroupSecurityStatus: (status: SecurityStatus) => void;
}) => {
  const [selectedAssetStatusCount, setSelectedAssetStatusCount] = useState(0);

  const onSelectSecurityStatus = ({
    securityStatus,
    count = 0,
  }: {
    securityStatus: SecurityStatus;
    count?: number;
  }) => {
    onChangeSelectedAssetGroupSecurityStatus(securityStatus);
    onChangeSecurityStatus(securityStatus);
    setSelectedAssetStatusCount(count);
  };

  const AssetsGroupButtonData: AssetsGroupButtonDataProps[] = [
    {
      securityStatus: SecurityStatus.Unsecure,
      renderIcon: props => <UnsecuredIcon {...props} />,
      badgeContent:
        selectedSecurityStatus > selectedAssetGroupSecurityStatus
          ? `- ${numeral(selectedAssetStatusCount).format(format)}`
          : `+ ${numeral(selectedAssetStatusCount).format(format)}`,
    },
    {
      securityStatus: SecurityStatus.Internet,
      renderIcon: props => <SecureIcon {...props} />,
      badgeContent:
        selectedAssetGroupSecurityStatus < 50
          ? `+ ${numeral(selectedAssetStatusCount).format(format)}`
          : selectedSecurityStatus === 50 &&
            selectedAssetGroupSecurityStatus === 100
          ? `+ ${numeral(selectedAssetStatusCount).format(format)}`
          : `- ${numeral(selectedAssetStatusCount).format(format)}`,
    },
    {
      securityStatus: SecurityStatus.All,
      renderIcon: props => <SecureAllIcon {...props} />,
      badgeContent:
        selectedAssetGroupSecurityStatus < 100
          ? `+ ${numeral(selectedAssetStatusCount).format(format)}`
          : `- ${numeral(selectedAssetStatusCount).format(format)}`,
    },
  ];

  const initRef = useRef(onInitStatus);
  initRef.current = onInitStatus;

  useEffect(() => {
    if (!data) {
      return;
    }

    let leastSecure: AssetStatus | undefined = undefined;
    let sortedPriority = Object.values(SecurityStatus)
      .filter(a => false === isNaN(+a))
      .sort((a, b) => {
        return +a - +b;
      });

    sortedPriority.forEach(status => {
      if (leastSecure) {
        return;
      }
      let assetStatus = AssetStatusReverseMap[status as SecurityStatus];
      if (assetStatus === AssetStatus.SecureInternet) {
        if (data[AssetStatus.SimulateSecureInternet]) {
          assetStatus = AssetStatus.SimulateSecureInternet;
        }
      }

      if (assetStatus === AssetStatus.SecureAll) {
        if (data[AssetStatus.SimulateSecureAll]) {
          assetStatus = AssetStatus.SimulateSecureAll;
        }
      }

      if (data[assetStatus] && !leastSecure) {
        leastSecure = assetStatus;
      }
    });

    if (leastSecure) {
      onChangeSecurityStatus(AssetStatusMap[leastSecure]);
      onChangeSelectedAssetGroupSecurityStatus(AssetStatusMap[leastSecure]);

      setSelectedAssetStatusCount(data[leastSecure]);

      initRef.current(leastSecure);
    }
  }, [data, onChangeSecurityStatus, onChangeSelectedAssetGroupSecurityStatus]);

  return (
    <Stack sx={{ width: "100%", alignItems: "flex-start" }}>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        mt={1}
        spacing={3}
      >
        {AssetsGroupButtonData.map((assetData, index) => {
          const isButtonActive =
            selectedAssetGroupSecurityStatus === assetData.securityStatus;
          let count =
            data?.[AssetStatusReverseMap[assetData.securityStatus]] ?? 0;

          let testCount = 0;
          let testState: AssetStatus | undefined;

          if (assetData.securityStatus === SecurityStatus.Internet) {
            testState = AssetStatus.SimulateSecureInternet;
          } else if (assetData.securityStatus === SecurityStatus.All) {
            testState = AssetStatus.SimulateSecureAll;
          }

          if (testState) {
            testCount = data?.[testState] ?? 0;
          }

          const hasSecurityStatusOrSourceNodeStatus =
            selectedSecurityStatus === assetData.securityStatus ||
            selectedAssetGroupSecurityStatus === assetData.securityStatus;
          const isSecurityStatusIsNotEqualToSourceNodeStatus =
            selectedSecurityStatus !== selectedAssetGroupSecurityStatus;
          const isSecurityStatusGreaterThanZero = selectedSecurityStatus >= 0;
          const doesCountExist = selectedAssetStatusCount !== 0;

          const isInvisible = !(
            hasSecurityStatusOrSourceNodeStatus &&
            isSecurityStatusIsNotEqualToSourceNodeStatus &&
            isSecurityStatusGreaterThanZero &&
            doesCountExist
          );

          let tooltipText = "";
          if (count) {
            tooltipText += `${count} ${window.getCTTranslatedText(
              SecurityStatusLabels[assetData.securityStatus]
            )}`;
          }

          if (testCount) {
            tooltipText += `${
              count ? " | " : ""
            }${testCount} ${window.getCTTranslatedText(
              "Simulate"
            )} ${window.getCTTranslatedText(
              SecurityStatusLabels[assetData.securityStatus]
            )}`;
          }

          return (
            <React.Fragment key={`button-grp-${assetData.securityStatus}`}>
              <AssetsButtonWithBadge
                isButtonActive={isButtonActive}
                isInvisible={isInvisible}
                badgeContent={assetData.badgeContent}
                renderIcon={assetData.renderIcon}
                counts={{ main: count, test: testCount }}
                format={format}
                onClick={() => {
                  onSelectSecurityStatus({
                    securityStatus: assetData.securityStatus,
                    count: count + testCount,
                  });
                }}
                tooltipText={tooltipText}
              />
            </React.Fragment>
          );
        })}
      </Stack>
    </Stack>
  );
};
