import { Stack, Typography } from "@mui/material";
import { DownloadItem } from "./components/DownloadItem";
import { useState } from "react";
import { RegisterInstructionsDrawer } from "./components/RegisterIntructionsDrawer";

export function ApplianceRegister() {
  const [showInstructions, setShowInstructions] = useState(false);

  return (
    <Stack sx={{ alignItems: "center", pb: 2 }}>
      <Stack spacing={2} width="95%">
        <Typography variant="h6" sx={{ mb: 2 }}>
          {window.getCTTranslatedText("step")} 2:{" "}
          {window.getCTTranslatedText("registerAppliance")}
        </Typography>
        <DownloadItem onInstallInstructions={() => setShowInstructions(true)} />
      </Stack>
      <RegisterInstructionsDrawer
        drawerOpen={showInstructions}
        onClose={() => setShowInstructions(false)}
      />
    </Stack>
  );
}
