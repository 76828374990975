import {
  Alert,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { InstructionsRenderer } from "common/molecules/instructions-renderer";
import { RegisterInstructions } from "../constants";
import { Toolbar } from "common/atoms/toolbar";
import { CopyButton } from "common/atoms/copy-button";
import { useDepKey } from "pages/agents/components/agent-download-page/hooks/useDepKey";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { QUERY_ACTIONS } from "pages/appliances/types";

interface RegisterDrawerProps {
  drawerOpen: boolean;
  onClose: () => void;
}

const activationLink = `/appliances?action=${QUERY_ACTIONS.ACTIVATE}`;
const applianceListLink = `/appliances`;

export function RegistrationInstructionContent() {
  const { depKey } = useDepKey();

  return (
    <Stack spacing={2} mt={2}>
      <Typography variant="subtitle1">
        {window.getCTTranslatedText("registerWithActivationCode")}
      </Typography>
      <Typography variant="body2">
        1. {window.getCTTranslatedText("copyTheActivationCodePrintedOnTheCLI")}
      </Typography>
      <Typography variant="body2">
        2. {window.getCTTranslatedText("goTo")}{" "}
        <Link href={activationLink}>
          {window.getCTTranslatedText("activateAppliance")}
        </Link>
        .
      </Typography>
      <Typography variant="body2">
        3.{" "}
        {window.getCTTranslatedText(
          "pasteTheActivationCodeToCompleteRegisteration"
        )}
      </Typography>
      <Typography variant="subtitle1" pt={2}>
        {window.getCTTranslatedText("registerWithDeploymentKey")}
      </Typography>
      <Alert
        severity="info"
        action={<CopyButton text={depKey} textButton={true} />}
      >
        {window.getCTTranslatedText("deploymentKey")}: {depKey}
      </Alert>
      <Typography variant="body2">
        1. {window.getCTTranslatedText("copyTheDeploymentKey")}
      </Typography>
      <Typography variant="body2">
        2. {window.getCTTranslatedText("initiateActivationAndWait")}
      </Typography>
    </Stack>
  );
}

export function VerifyInstructionContent() {
  return (
    <Typography variant="body2">
      {window.getCTTranslatedText("verifyApplianceSubtitle")}{" "}
      <Link href={applianceListLink}>
        {window.getCTTranslatedText("applianceList")}
      </Link>
      {". "}
      {window.getCTTranslatedText("configureApplianceSubtitle")}
    </Typography>
  );
}

export function RegisterInstructionsDrawer({
  drawerOpen,
  onClose,
}: RegisterDrawerProps) {
  return (
    <Drawer
      anchor={"right"}
      open={drawerOpen}
      onClose={onClose}
      PaperProps={{
        elevation: 1,
        sx: {
          width: "85%",
          minWidth: "1000px",
        },
      }}
    >
      <Toolbar />
      <DialogTitle textAlign={"left"}>
        {window.getCTTranslatedText("registerInstructions")}
      </DialogTitle>
      <DialogContent>
        <InstructionsRenderer instructions={RegisterInstructions} />
      </DialogContent>
      <DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
        <ToolbarAction
          hideSecondaryBtn={true}
          actionBtnText={"Done"}
          save={onClose}
        />
      </DialogActions>
    </Drawer>
  );
}
