import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

export interface DepKeyItem {
  key: string;
  tenant: string;
  lastAccess: Date;
}

export interface Depkeys {
  items: DepKeyItem[];
}

export function useDepKeyQueryAPI() {
  return useQuery<Depkeys, Error>(["depkey", "deployment-keys"]);
}

export function useDepKeyCreateAPI() {
  return useMutation<any, Error, any>(["depkey", "deployment-keys"]);
}
