import { useMutation } from "@tanstack/react-query";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { NamedNetwork } from "pages/paths/types";
import { useEffect, useMemo } from "react";
import { MergedNNRecommendationsForInboundAndOutbound } from "../PathRecommendationDrawer";

const EMPTY: PathNNAgg[] = [];
export interface PathNNAgg {
  id: string;
  namedNetwork: NamedNetwork;
  totalAddressCount: number;
  peerCount: number;
  coverage: number;
}

export interface PathNNRecommendationResp {
  aggregates: PathNNAgg[];
  total: number;
  isLoading?: boolean;
}

export function usePathsNNRecommendations() {
  const path = `paths/recommendations/namednetwork?computeTotal=true`;
  return useMutation<any, Error, any>(["recommendation-NN", path]);
}

function usePathNNRecommendations({
  criteria,
  enabled,
  direction,
}: {
  criteria?: string;
  enabled: boolean;
  direction: Direction;
}) {
  const { aggregates, isLoading, total } = usePathRecommendationsData({
    criteria,
    enabled,
    direction,
  });

  return {
    aggregates,
    total,
    isLoading: isLoading,
  };
}

function usePathRecommendationsData({
  criteria,
  enabled,
  direction,
}: {
  criteria?: string;
  enabled: boolean;
  direction: Direction;
}) {
  const { data, mutate, isLoading } = usePathsNNRecommendations();

  const criteriaDirection =
    direction === Direction.Inbound ? "inbound" : "outbound";

  useEffect(() => {
    if (!criteria || !enabled) {
      return;
    }

    mutate({
      criteria:
        criteria === "*"
          ? `'direction' in ('${criteriaDirection}')`
          : `${criteria} AND 'direction' in ('${criteriaDirection}')`,
      pagination: {
        offset: 0,
        limit: 100,
        sort: [
          {
            field: "pathlastobserved",
            order: "desc",
          },
        ],
      },
      facetFields: [],
    });
  }, [criteria, enabled, mutate, criteriaDirection]);

  let { aggregates, total } = useMemo<{
    aggregates: PathNNAgg[];
    total: number;
  }>(() => {
    if (!data) {
      return { aggregates: EMPTY, total: 0 };
    }
    let agg: PathNNAgg[] = [];
    let total = 0;

    data?.items?.forEach((path: any) => {
      agg.push({
        totalAddressCount: path.totalAddressCount,
        peerCount: path.peerCount,
        coverage:
          path.peerCount && path.totalAddressCount
            ? Number((path.peerCount / path.totalAddressCount).toFixed(4))
            : 0,
        namedNetwork: path.namedNetwork,
        id:
          path.namedNetwork.namedNetworkId + path.namedNetwork.namedNetworkName,
      });
    });
    agg.sort((a, b) => b.coverage - a.coverage);

    return { aggregates: agg, total };
  }, [data]);

  if (!enabled) {
    return { aggregates: EMPTY, total: 0 };
  }

  return { aggregates, total, isLoading: isLoading };
}

export function useNNAssignRecommendation({
  criteria,
  enabled,
  directions,
}: {
  criteria: string;
  enabled: boolean;
  directions: string[];
}) {
  const outboundAPI: PathNNRecommendationResp = usePathNNRecommendations({
    criteria: criteria,
    enabled:
      enabled && !(directions?.length === 1 && directions.includes("inbound")),
    direction: Direction.Outbound,
  });

  const inboundAPI: PathNNRecommendationResp = usePathNNRecommendations({
    criteria: criteria,
    enabled:
      enabled && !(directions?.length === 1 && directions.includes("outbound")),
    direction: Direction.Inbound,
  });

  const mergedArray = useMemo(() => {
    if (inboundAPI?.aggregates?.length || outboundAPI?.aggregates?.length) {
      const mergedMap: {
        [key: string]: MergedNNRecommendationsForInboundAndOutbound;
      } = {};

      inboundAPI?.aggregates?.forEach((item: PathNNAgg) => {
        mergedMap[item.id] = {
          id: item.id,
          inboundCoverage: item.coverage,
          outboundCoverage: 0,
          inboundPeerCount: item.peerCount,
          outboundPeerCount: 0,
          namedNetwork: item.namedNetwork,
          totalAddressCount: item.totalAddressCount,
          coverage: 0,
        };
      });

      outboundAPI?.aggregates?.forEach(item => {
        if (!mergedMap[item.id]) {
          mergedMap[item.id] = {
            id: item.id,
            inboundCoverage: 0,
            outboundCoverage: item.coverage,
            inboundPeerCount: 0,
            outboundPeerCount: item.peerCount,
            namedNetwork: item.namedNetwork,
            totalAddressCount: item.totalAddressCount,
            coverage: 0,
          };
        } else {
          mergedMap[item.id].outboundCoverage = item.coverage;
          mergedMap[item.id].outboundPeerCount = item.peerCount;
        }
      });

      const mergedArray = Object.values(mergedMap);

      return mergedArray
        .map(m => {
          m.coverage = m.inboundCoverage + m.outboundCoverage;
          return m;
        })
        .sort((a, b) => b.coverage - a.coverage);
    }
  }, [inboundAPI?.aggregates, outboundAPI?.aggregates]);

  return {
    data: mergedArray,
    isLoading: inboundAPI?.isLoading || outboundAPI?.isLoading,
  };
}
