import { Appliance } from "pages/appliances/types";
import { useMemo } from "react";

export function useSelectedApplianceData(updatedData: Appliance[]) {
  const selectedItems = useMemo(
    () => updatedData?.map((item: Appliance) => item.agentId),
    [updatedData]
  );

  return selectedItems;
}
