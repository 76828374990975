import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { Template } from "pages/templates/types";
import { SortOrder } from "modules/core/types";

export function useTemplatesAssignedSuggestions({
  policyId,
}: {
  policyId?: string;
}) {
  return useQuery<any, Error>(
    ["tagbasedpolicy", `tagbasedpolicies/${policyId}`],
    { enabled: Boolean(policyId) }
  );
}

export function useAssignedTemplatesTags({
  criteria,
  policyId,
}: {
  criteria?: string;
  policyId?: string;
}) {
  const [search, setSearch] = useState("");

  const { data, isLoading } = useTemplatesAssignedSuggestions({ policyId });

  const onSortChange = useCallback((sort: Array<SortOrder>) => {}, []);

  const onSearchCriteriaChange = useCallback((str: string) => {
    setSearch(str || "");
  }, []);

  const templateList: Array<Template> = useMemo(() => {
    if (!data) {
      return [];
    }

    const templates = data?.templates ?? [];
    const lowerCaseSearch = search?.toLowerCase();

    return templates.filter((template: Template) =>
      template.templateName?.toLowerCase()?.includes(lowerCaseSearch)
    );
  }, [data, search]);

  return {
    templateList,
    isLoading,
    onSortChange,
    onSearchCriteriaChange,
  };
}
