import { Box, Stack } from "@mui/material";
import { useCommonStore } from "common/store";
import { PathColumnRenderProps } from "pages/paths/types";
import { CTDatagridButton } from "common/atoms/ct-data-grid-button";

export function PathDetails({
  path,
  viewOnly,
  useFacetStore = useCommonStore,
}: PathColumnRenderProps) {
  if (!path.channelHash) {
    return <Box />;
  }

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        {path?.protocol && (
          <CTDatagridButton
            isDisabled={viewOnly === true || !path.protocol}
            asText={viewOnly === true}
            useFacetStore={useFacetStore}
            facetName={"protocol"}
            facetOption={path?.protocol ?? ""}
          >
            {path?.protocol}
          </CTDatagridButton>
        )}

        <CTDatagridButton
          isDisabled={viewOnly === true || !path.port}
          asText={viewOnly === true}
          useFacetStore={useFacetStore}
          facetName={"port"}
          facetOption={path.port.toString() ?? ""}
        >
          {path?.port}
        </CTDatagridButton>
      </Stack>
    </Box>
  );
}
