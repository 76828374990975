import { Box } from "@mui/material";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AssignNetworksDrawer } from "modules/assign-networks-to-assets/components/assign-networks-drawer";
import {
  AssetAttributesBlock,
  VisxAttributesBlock,
} from "modules/attributes-block";
import { AttributeLocation } from "modules/attributes-block/types";
import { PathRecommendationDrawer } from "modules/recommendation-workflows";
import { PathRecommendationPageType } from "modules/recommendation-workflows/PathRecommendationDrawer";
import { AssignNetworksOption } from "pages/tags/components/tag-policy-list/components/tag-networks/components/assigned-networks-tags/AssignedNetworksTags";
import { useState } from "react";
import { AssignedNetworksViewDrawer } from "./assigned-networks-view-drawer";
import { useAssetNetworks } from "./hooks";

export function AssetNetworks({
  criteria,
  page = AttributeLocation.Asset,
  multiselect = true,
  onSelectCallback,
}: {
  criteria: string;
  page?: AttributeLocation;
  multiselect?: boolean;
  onSelectCallback?: Function;
}) {
  const userPermissions = useUserPermissionsStore(
    state => state.userPermissions
  );

  const [showNetworkRecommendation, setShowNetworkRecommendation] =
    useState(false);

  const {
    showAssignedNetworksDrawer,
    openAssignedNetworksDrawer,
    closeAssignedNetworksDrawer,
    showAssignNetworksDrawer,
    openAssignNetworksDrawer,
    closeAssignNetworksDrawer,
    networkList,
    total,
    isLoading,
    networkListMutation,
    onSortChange,
    setPageSize,
    setPage,
    pageSize,
    page: pageCount,
    totalCount,
    onSearchCriteriaChange,
  } = useAssetNetworks({ criteria });

  const hasPermission = Boolean(
    userPermissions.has("UPDATE_ASSET") &&
      userPermissions.has("UPDATE_NAMED_NETWORK")
  );

  if (!criteria) {
    return <Box style={{ width: 42 }} />;
  }

  return (
    <>
      {page === AttributeLocation.Visx ? (
        <>
          <VisxAttributesBlock
            isLoading={isLoading}
            title="Named Networks"
            count={total}
            canAssign={Boolean(
              userPermissions.has("UPDATE_ASSET") &&
                userPermissions.has("UPDATE_NAMED_NETWORK")
            )}
            btnTitle="Assign Named Networks"
            showAssigned={openAssignedNetworksDrawer}
            assign={openAssignNetworksDrawer}
            recommend={() => {
              setShowNetworkRecommendation(true);
            }}
          />
        </>
      ) : (
        <AssetAttributesBlock
          isLoading={isLoading}
          title="Named Networks"
          count={total}
          canAssign={hasPermission}
          showAssigned={openAssignedNetworksDrawer}
          assign={openAssignNetworksDrawer}
          criteria={criteria}
        />
      )}

      {showNetworkRecommendation && (
        <PathRecommendationDrawer
          isOpen={true}
          onClose={() => setShowNetworkRecommendation(false)}
          criteria={criteria}
          page={PathRecommendationPageType.Asset}
        />
      )}

      <AssignNetworksDrawer
        isOpen={showAssignNetworksDrawer}
        page={"assets"}
        title="Assign Named Network"
        criteria={criteria}
        rules={[]}
        onCancel={closeAssignNetworksDrawer}
        onConfirm={closeAssignNetworksDrawer}
        btnTitle={"assign"}
        multiselect={multiselect}
        onSelectCallback={onSelectCallback}
        hasPermission={hasPermission}
      />

      <AssignedNetworksViewDrawer
        isOpen={showAssignedNetworksDrawer}
        onClose={closeAssignedNetworksDrawer}
        networkList={networkList}
        networksSuggestMutation={networkListMutation}
        page="assets"
        onSortChange={onSortChange}
        AssignNetworksOption={() =>
          AssignNetworksOption({
            openAssignNetworksDrawer,
            hasPermission,
          })
        }
        multiselect={multiselect}
        criteria={criteria}
        hasPermission={hasPermission}
        isLoading={isLoading}
        setPage={setPage}
        setPageSize={setPageSize}
        pageCount={pageCount}
        pageSize={pageSize}
        totalCount={totalCount}
        onSearchCriteriaChange={onSearchCriteriaChange}
      />
    </>
  );
}
