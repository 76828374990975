import { DisplayTextMap } from "common/constants/displayTextMap";

export const getDisplayText = (key: string) => {
  let text = key;
  if (key && DisplayTextMap[key]) {
    text = DisplayTextMap[key];
  }

  return text;
};
